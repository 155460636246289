import { Props } from './Dashboard.container';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { MasterBody } from '../../components/Layout/MasterBody';
import { MasterContainer } from '../../components/Layout/MasterContainer';
import { NavBarMaster } from '../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../components/UiNavigation/SubNavBar';
import { MasterSideMenu } from '../../components/Layout/MasterSideMenu';
import { MainMenuConnected } from '../../components/UiNavigation/MainMenuMaster/MainMenuConnected';
import { Checkbox } from 'semantic-ui-react';
import { client } from '../../graphql/client';
import { Account, Company, Veterinarian } from '../../types/DbInterface';
import { loader } from 'graphql.macro';
import { appContext } from '../../context/appContext';
import { CustomFlatList } from '../../components/CustomFlatList';
import { i18n } from '../../lib/i18n';

import { ReactComponent as IconChevronRightDisclosure } from '../../theme/icons/IconChevronRightDisclosure.svg';
import { ReactComponent as IconChevronRight24x24 } from '../../theme/icons/Icon-ChevronRight-24x24.svg';
import { HeaderSectionForm } from '../../components/Form/HeaderSectionForm';
import Video from 'twilio-video';
import * as Sentry from '@sentry/browser';
import { theme } from '../../theme';

// const i18nExtract = require('i18n-extract');
// const getVeterinarian = loader('../../graphql/getVeterinarian.graphql');
// should be rename to getAccount ? byFirebasUid ?
// const signInAccount = loader('../../graphql/signInAccount.graphql');
const updateLastActivityAt = loader('../../graphql/updateLastActivityAt.graphql');
const toggleVeterinarianAvailability = loader(
  '../../graphql/toggleVeterinarianAvailability.graphql'
);
const toggleCompanyAvailability = loader('../../graphql/toggleCompanyAvailability.graphql');
// const getStripeOnboardingLink = loader('../../graphql/getStripeOnboardingLink.graphql');
// const getStripeValidationInfo = loader('../../graphql/getStripeValidationInfo.graphql');

const getProfessionalAccountWithRelations = loader(
  '../../graphql/getProfessionalAccountWithRelations.graphql'
);

const ACTIVITYINTERVAL = 30000;

type LinkItemProps = {
  title: () => string;
  href: string;
};

interface State {
  currentVet?: Veterinarian;
  currentAccount: Account;
  currentCompany?: Company;
  companyVeterinarians?: Veterinarian[];
  intervalId?: number;
  isLoading: boolean;
  twilioBrowserIsSupported?: boolean;
  companyCode?: Company,
}

const linkItemsFirstCol: Array<LinkItemProps> = [
  {
    title: () => i18n.t('dashboard.information.links.howItWorks'),
    href: 'https://www.callmyvet.io/veterinaire/guide-pratique',
  },
  {
    title: () => i18n.t('dashboard.information.links.pricing'),
    href: 'https://www.callmyvet.io/tarifs',
  },
  {
    title: () => i18n.t('dashboard.information.links.estimationTools'),
    href: 'https://www.callmyvet.io/veterinaire/outil-estimation-revenu',
  },
];

const linkItemsSecondCol: Array<LinkItemProps> = [
  {
    title: () => i18n.t('dashboard.information.links.satisfactionSurvey'),
    href: 'https://airtable.com/shrNMKDKL46PToIp4',
  },
  {
    title: () => i18n.t('dashboard.information.links.contactUs'),
    href: 'https://www.callmyvet.io/contact',
  },
  {
    title: () => i18n.t('dashboard.information.links.suggestion'),
    href: 'https://airtable.com/shrM9cGj3uHZGAGxV',
  },
];

export class Dashboard extends Component<Props, State> {
  public state: State = {
    isLoading: false,
    intervalId: 0,
    currentVet: undefined,
    companyVeterinarians: undefined,
    currentAccount: {},
    currentCompany: undefined,
    twilioBrowserIsSupported: undefined, 
    companyCode: undefined,
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public async componentDidMount() {

    let updateCurrentCompany = undefined;
    let currentCompanyVeterinarians = undefined;

    try {
      const response = await client.query<{
        getProfessionalAccountWithRelations?: {
          account: Account;
          veterinarian?: Veterinarian;
          company?: Company;
          companyVeterinarians?: Veterinarian[];
        };
      }>({
        query: getProfessionalAccountWithRelations,
        fetchPolicy: 'network-only',
      });
      updateCurrentCompany = response.data.getProfessionalAccountWithRelations?.company;
      currentCompanyVeterinarians = response.data.getProfessionalAccountWithRelations?.companyVeterinarians;
    } catch (error) {
      console.log(error);
    }

    this.context.updateContext();
    const contextValue = this.context;
    const currentAccount = contextValue.currentAccount;
    const currentVet = contextValue.currentVet;
    const currentCompany = updateCurrentCompany;
    //const companyVeterinarians = contextValue.companyVeterinarians;
    const companyVeterinarians = currentCompanyVeterinarians;
    // console.log('accountIsCompanyAdmin = ', contextValue.accountIsCompanyAdmin());
    // console.log('companyVeterinarians = ', contextValue.companyVeterinarians);
    // console.log('context: ', contextValue);
    // console.log(currentAccount);
    const companyCode = contextValue.companyCode;
    if (currentAccount && currentCompany && companyCode) {
      // this.updateLastConnectionAt(); //done once to avoid 30 sec cooldown
      const intervalId = setInterval(this.updateLastConnectionAt, ACTIVITYINTERVAL);
      this.setState({
        currentVet,
        currentAccount,
        currentCompany,
        companyVeterinarians,
        intervalId,
        companyCode,
      });
      // this.updateStripeInformation();
    } else {
      clearTimeout(this.state.intervalId);
      // this.props.history.goBack();
    }

    //check twilio video browser support
    const twilioBrowserIsSupported = Video.isSupported;
    this.setState({ twilioBrowserIsSupported });
  }

  public componentWillUnmount() {
    clearTimeout(this.state.intervalId);
  }

  private updateLastConnectionAt = async () => {
    try {
      await this.props.client.mutate({ mutation: updateLastActivityAt });
    } catch (error) {
      Sentry.captureMessage(error);
    }
  };

  private onToggleVeterinarianAvailability = async () => {
    if (!this.state.currentVet) return;
    try {
      await this.props.client.mutate({
        mutation: toggleVeterinarianAvailability,
      });
    } catch (error) {
      throw new Error(error);
    }
    const currentVet = this.state.currentVet;

    this.setState({
      currentVet: {
        ...currentVet,
        isAvailable: !currentVet.isAvailable,
      },
    });

    // update context to subscribe or unsubscribe to call
    this.context.updateCurrentVet(this.state.currentVet);

    // this.setState({ currentVet });
  };

  private onToggleCompanyAvailability = async () => {
    if (!this.state.currentCompany) return;
    try {
      await this.props.client.mutate({
        mutation: toggleCompanyAvailability,
      });
      const currentCompany = this.state.currentCompany;

      // currentCompany.isAvailable = !currentCompany.isAvailable;
      this.setState({
        currentCompany: { ...currentCompany, isAvailable: !currentCompany.isAvailable },
      });

      // update context to subscribe or unsubscribe to call
      this.context.updateCurrentCompany(this.state.currentCompany);
    } catch (error) {
      throw new Error(error);
    }

    // this.setState({ currentVet });
  };

  public renderDashboardThirdContainerLinkItem(item: LinkItemProps, index: number) {
    return (
      <DashboardLinkItem href={item.href} target={'_blank'} key={index}>
        {item.title()}
        <DashboardLinkIcon />
      </DashboardLinkItem>
    );
  }

  public getCompanyModerationState(moderationStatus?: string) {
    switch (moderationStatus) {
      case 'NOT_COMPLETED':
        return 'WARNING';
      case 'PENDING_VERIFICATION':
        return 'IN_REVIEW';
      case 'VERIFIED':
        return 'VERIFIED';
      default:
        return 'WARNING';
    }
  }

  public getVeterinarianListFormatted() {
    let veterinarianList: any;
    if (this.state.companyVeterinarians && this.state.companyVeterinarians?.length > 0) {
      const verifiedVeterinariansList = this.state.companyVeterinarians.filter(
        (data) => data.moderationStatus === 'VERIFIED'
      );
      verifiedVeterinariansList.map((data) => {
        if (!veterinarianList)
          veterinarianList = [
            {
              onClick: () => this.props.history.push('Profile'),
              leftLabel: `${data.account.firstName} ${data.account.lastName}${
                data.id === this.state.currentVet?.id ? ' (Vous)' : ''
              }`,
              rightIcon: IconChevronRight24x24,
              disabled: data.id === this.state.currentVet?.id ? false : true,
            },
          ];
        else
          veterinarianList.push({
            //TODO onClick: go to profile not form
            onClick: () => this.props.history.push('Profile'),
            leftLabel: `${data.account.firstName} ${data.account.lastName}${
              data.id === this.state.currentVet?.id ? ' (Vous)' : ''
            }`,
            rightIcon: IconChevronRight24x24,
            disabled: data.id === this.state.currentVet?.id ? false : true,
          });
        return veterinarianList;
      });
      // console.log('veterinarianList', veterinarianList);
      return veterinarianList;
    } else return [{ leftLabel: '', rightIcon: IconChevronRight24x24 }];
  }

  public isVeterinarianValid() {
    const veterinarianList = this.state.companyVeterinarians;
    if (!this.context.getIsCompanyAdminByAccountId(this.context.currentAccount?.id)) {
      if (this.context.currentVet?.moderationStatus === 'VERIFIED') return true;
      else return false;
    }
    if (veterinarianList) {
      if (veterinarianList.find((data) => data.moderationStatus === 'VERIFIED')) return true;
      else return false;
    }
    return false;
  }

  public render() {
    return (
      <>
        <NavBarMaster />
        <SubNavBar moderationStatus={this.state.currentCompany?.moderationStatus} />
        <MasterBody>
          <MasterSideMenu>
            <MainMenuConnected />
          </MasterSideMenu>
          <MasterContainer>
            <DashboardContainer>
              <DashboardHeaderContainer>
                <DashboardWelcomeContainer>{i18n.t('dashboard.title')}</DashboardWelcomeContainer>
                <DashboardVetNameContainer>
                  {this.state.currentAccount.firstName + ' ' + this.state.currentAccount.lastName}
                </DashboardVetNameContainer>
              </DashboardHeaderContainer>
              <DashboardUserCodeContainer>
                <DashboardUserCode>{i18n.t('dashboard.codeAlert.codeText')} <b>{this.state.companyCode?.companyCode}</b></DashboardUserCode>
                <DashboardUserCodeText>{i18n.t('dashboard.codeAlert.codeHelpText')}</DashboardUserCodeText>
              </DashboardUserCodeContainer>
              <DashboardSectionContainer>
                <HeaderSectionForm
                  title={i18n.t('dashboard.availabeVets.title')}
                  subtitle={i18n.t('dashboard.availabeVets.subtitle')}
                  status={'VERIFIED'}
                />
                {/* TODO Atleast one vet moderationStatus = VERIFIED instead ? */}
                {this.state.currentCompany?.moderationStatus !== 'VERIFIED' && (
                  <DashboardNoVetAvailableContainer>
                    <span>
                      {i18n.t('dashboard.availabeVets.noVetAvailable.text')}
                      <a href="/teams">{i18n.t('dashboard.availabeVets.noVetAvailable.link')}</a>
                    </span>
                    {/* TODO Add vet team route to href ? */}
                  </DashboardNoVetAvailableContainer>
                )}
                {this.context.getIsCompanyAdminByAccountId(this.context.currentAccount?.id) &&
                  !this.isVeterinarianValid() && (
                    <DashboardNoVetAvailableContainer>
                      <span>
                        {i18n.t('dashboard.availabeVets.companyNotVerified.text')}
                        <a href="/organisation">
                          {i18n.t('dashboard.availabeVets.companyNotVerified.link')}
                        </a>
                        {i18n.t('dashboard.availabeVets.companyNotVerified.textEnd')}
                      </span>
                      {/* TODO Add vet team route to href ? */}
                    </DashboardNoVetAvailableContainer>
                  )}
                {!this.context.getIsCompanyAdminByAccountId(this.context.currentAccount?.id) &&
                  !this.isVeterinarianValid() && (
                    <DashboardNoVetAvailableContainer>
                      <span>
                        {i18n.t('dashboard.availabeVets.currentVetNotVerified.text')}
                        <a href="/profile">
                          {i18n.t('dashboard.availabeVets.currentVetNotVerified.link')}
                        </a>
                        {i18n.t('dashboard.availabeVets.currentVetNotVerified.textEnd')}
                      </span>
                      {/* TODO Add vet team route to href ? */}
                    </DashboardNoVetAvailableContainer>
                  )}
                {this.isVeterinarianValid() &&
                  this.state.currentCompany?.moderationStatus === 'VERIFIED' && (
                    <DashboardVetsAvailable>
                      <SwitchContainer>
                        <SwitchLabel>
                          {this.state.twilioBrowserIsSupported
                            ? 'Pour recevoir des appels, actionnez le bouton ci-contre pour qu’il ait une couleur verte.'
                            : "Votre naviguateur semble ne pas être compatible avec notre service d'appel vidéo. Veuillez essayer avec un autre naviguateur s'il vous plait."}
                        </SwitchLabel>
                        {/* TODO Create disabled switch when account not approuved */}
                        <ToggleMode
                          onChange={
                            this.context.getIsCompanyAdminByAccountId(
                              this.context.currentAccount?.id
                            )
                              ? () => this.onToggleCompanyAvailability()
                              : () => this.onToggleVeterinarianAvailability()
                          }
                          toggle={true}
                          checked={
                            this.state.twilioBrowserIsSupported
                              ? this.context.getIsCompanyAdminByAccountId(
                                  this.context.currentAccount?.id
                                )
                                ? this.state.currentCompany?.isAvailable
                                : this.state.currentVet?.isAvailable
                              : false
                          }
                          disabled={this.state.isLoading || !this.state.twilioBrowserIsSupported}
                        />
                      </SwitchContainer>
                      <CustomFlatList data={this.getVeterinarianListFormatted()} />
                    </DashboardVetsAvailable>
                  )}
              </DashboardSectionContainer>

              <DashboardSectionContainer>
                <HeaderSectionForm
                  title={i18n.t('dashboard.companyStatus.title', {
                    companyName: this.state.currentCompany?.name,
                  })}
                  subtitle={i18n.t('dashboard.companyStatus.subtitle')}
                  status={this.getCompanyModerationState(
                    this.state.currentCompany?.moderationStatus
                  )}
                />
                <CustomFlatListContainer>
                  <CustomFlatList
                    data={[
                      {
                        leftLabel: `${i18n.t('dashboard.companyStatus.legalInformation')}`,
                        rightIcon: IconChevronRight24x24,
                        status: this.state.currentCompany?.legalCompleted
                          ? 'REQUIRED_FIELD_FILLED'
                          : 'WARNING',
                        onClick: () => this.props.history.push('/organisation#legal-information'),
                      },
                      {
                        leftLabel: `${i18n.t('dashboard.companyStatus.vat')}`,
                        rightIcon: IconChevronRight24x24,
                        status: this.state.currentCompany?.vatCompleted
                          ? 'REQUIRED_FIELD_FILLED'
                          : 'WARNING',
                        // onClick: () => this.props.history.push('/organisation?hash=vat'),
                        onClick: () => this.props.history.push('/organisation#vat'),
                      },
                      {
                        leftLabel: `${i18n.t('dashboard.companyStatus.address')}`,
                        rightIcon: IconChevronRight24x24,
                        status: this.state.currentCompany?.addressCompleted
                          ? 'REQUIRED_FIELD_FILLED'
                          : 'WARNING',
                        onClick: () => this.props.history.push('/organisation#address'),
                      },
                      {
                        leftLabel: `${i18n.t('dashboard.companyStatus.contact')}`,
                        rightIcon: IconChevronRight24x24,
                        status: this.state.currentCompany?.contactCompleted
                          ? 'REQUIRED_FIELD_FILLED'
                          : 'WARNING',
                        onClick: () => this.props.history.push('/organisation#contact'),
                      },
                      {
                        leftLabel: `${i18n.t('dashboard.companyStatus.bankingInformation')}`,
                        rightIcon: IconChevronRight24x24,
                        status:
                          this.state.currentCompany?.bankCompleted &&
                          this.state.currentCompany?.stripeCompleted
                            ? 'REQUIRED_FIELD_FILLED'
                            : 'WARNING',
                        leftLabelColor: this.context.getIsCompanyAdminByAccountId(
                          this.context.currentAccount?.id
                        )
                          ? undefined
                          : theme.colors.grey.main,
                        disabled: this.context.getIsCompanyAdminByAccountId(
                          this.context.currentAccount?.id
                        )
                          ? false
                          : true,
                        onClick: () =>
                          this.context.getIsCompanyAdminByAccountId(this.context.currentAccount?.id)
                            ? this.props.history.push('/organisation#banking-information')
                            : null,
                      },
                      {
                        leftLabel: `${i18n.t('dashboard.companyStatus.professionalInsurance')}`,
                        rightIcon: IconChevronRight24x24,
                        status: this.state.currentCompany?.insuranceCompleted
                          ? 'REQUIRED_FIELD_FILLED'
                          : 'WARNING',
                        onClick: () =>
                          this.props.history.push('/organisation#professional-insurance'),
                      },
                      {
                        leftLabel: `${i18n.t('dashboard.companyStatus.teamMembers')}`,
                        rightIcon: IconChevronRight24x24,
                        status:
                          this.state.companyVeterinarians &&
                          this.state.companyVeterinarians.length > 0
                            ? 'REQUIRED_FIELD_FILLED'
                            : 'WARNING',
                        onClick: () => this.props.history.push('/teams'),
                      },
                    ]}
                  />
                </CustomFlatListContainer>
              </DashboardSectionContainer>

              <DashboardSectionContainer>
                <HeaderSectionForm title={i18n.t('dashboard.information.title')} />
                <DashboardLinksContainer>
                  <DashboardLinksRow noGutters>
                    <Col md={5}>
                      {linkItemsFirstCol.map((item, i) =>
                        this.renderDashboardThirdContainerLinkItem(item, i)
                      )}
                    </Col>
                    <Col md={5}>
                      {linkItemsSecondCol.map((item, i) =>
                        this.renderDashboardThirdContainerLinkItem(item, i)
                      )}
                    </Col>
                  </DashboardLinksRow>
                </DashboardLinksContainer>
              </DashboardSectionContainer>
            </DashboardContainer>
          </MasterContainer>
        </MasterBody>
      </>
    );
  }
}

const DashboardContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.pure};
`;

const DashboardHeaderContainer = styled.div`
  padding: 30px 20px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 30px 10px;
  }
`;

const DashboardUserCodeContainer = styled.div`
  padding: 12px 48px;
  margin: 0 20px;
  border: 1px solid #4CA698;
  border-radius: 4px;
  background-color: #D1EFEC;
`;

const DashboardUserCode = styled.div`
`;

const DashboardUserCodeText = styled.div`
`;

const DashboardWelcomeContainer = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.heading.h4.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h4.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h4.fontSize};
  line-height: 32px;
  color: ${(props) => props.theme.colors.black.light};
`;

const DashboardVetNameContainer = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.heading.h1.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
`;

const DashboardSectionContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 20px 10px;
  }
`;

/* Dashboard Vets available section */

const DashboardNoVetAvailableContainer = styled.div`
  width: 100%;
  height: 60px;
  font-size: ${(props) => props.theme.textStyles.typography.lead.tiny.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.tiny.fontWeight};
  line-height: 14px;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.grey.darker};
  border: 1px solid ${(props) => props.theme.colors.white.darker};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  text-align: center;

  & > span > a {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.grey.darker};
  }
`;

/* Dashbaord Switch */

const DashboardVetsAvailable = styled.div``;

const SwitchContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
`;
const SwitchLabel = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.lead.small.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.small.fontWeight};
  line-height: 15px;
  color: ${(props) => props.theme.colors.black.lighter};
  margin-right: 10px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    width: 75%;
  }
`;

const TOGGLE_WIDTH = 55;
const TOGGLE_HEIGHT = 30;
const TOGGLE_PADDING = 4;
const TOGGLE_BUTTON_SIZE = 22;

const ToggleMode = styled(Checkbox)`
  &&&& {
    & {
      width: ${TOGGLE_WIDTH}px;
      height: ${TOGGLE_HEIGHT}px;
    }
    input ~ label:before {
      height: ${TOGGLE_HEIGHT}px;
      width: ${TOGGLE_WIDTH}px;
      background-color: ${(props) => props.theme.colors.red.dark} !important;
    }
    input ~ label:after {
      height: ${TOGGLE_BUTTON_SIZE}px;
      width: ${TOGGLE_BUTTON_SIZE}px;
      margin-top: ${TOGGLE_PADDING}px;
      margin-bottom: ${TOGGLE_PADDING}px;
      left: ${TOGGLE_PADDING}px;
    }
    input:checked ~ label:before {
      background-color: ${(props) => props.theme.colors.green.main} !important;
    }
    input:checked ~ label:after {
      left: ${TOGGLE_WIDTH - TOGGLE_BUTTON_SIZE - TOGGLE_PADDING}px;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    maring-right: 5px;
  }
`;

/* Dashboard row items */

const CustomFlatListContainer = styled.div`
  margin-top: 20px;
`;

/* Dashboard infos section */
const DashboardLinksContainer = styled.div`
  margin-top: 40px;
`;

const DashboardLinksRow = styled(Row)`
  justify-content: space-between;
`;

const DashboardLinkItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${(props) => props.theme.textStyles.typography.body.main.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.body.main.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.body.main.fontSize};
  color: ${(props) => props.theme.colors.primary.darker};
  line-height: 24px;
  padding: 15px 0;
  cursor: pointer;
`;

const DashboardLinkIcon = styled(IconChevronRightDisclosure)`
  & > * {
    fill: ${(props) => props.theme.colors.primary.darker};
  }
`;
