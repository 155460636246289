// const speciesItems = [
//   { label: 'Caméléon', value: 'Cameleon' },
//   { label: 'Chèvre', value: 'Chevre' },
//   { label: 'Cochon', value: 'Cochon' },
//   { label: "Cochon d'Inde", value: 'CochondInde' },
//   { label: 'Dinde', value: 'Dinde' },
//   { label: 'Furet', value: 'Furet' },
//   { label: 'Hamster', value: 'Hamster' },
//   { label: 'Hérisson', value: 'Herisson' },
//   { label: 'Lama', value: 'Lama' },
//   { label: 'Lapin', value: 'Lapin' },
//   { label: 'Lézard', value: 'Lezard' },
//   { label: 'Mouton', value: 'Mouton' },
//   { label: 'Perroquet', value: 'Perroquet' },
//   { label: 'Poisson', value: 'Poisson' },
//   { label: 'Poule', value: 'Poule' },
//   { label: 'Rat', value: 'Rat' },
//   { label: 'Scorpion', value: 'Scorpion' },
//   { label: 'Serpent', value: 'Serpent' },
//   { label: 'Souris', value: 'Souris' },
//   { label: 'Tortue', value: 'Tortue' },
//   { label: 'Autre espèce', value: 'Autre' },
// ];

export const petCategoryItems = [
  { label: 'Chat', value: 'cat', emoji: '🐱' },
  { label: 'Chien', value: 'dog', emoji: '🐶' },
  { label: 'Cheval', value: 'horse', emoji: '🐴' },
];

export const speciesItems = [
  { label: 'Caméléon', value: 'Cameleon', emoji: '🦎' },
  { label: 'Chèvre', value: 'Chevre', emoji: '🐐' },
  { label: 'Cochon', value: 'Cochon', emoji: '🐖' },
  { label: "Cochon d'Inde", value: 'CochondInde', emoji: '🐹' },
  { label: 'Dinde', value: 'Dinde', emoji: '🦃' },
  { label: 'Furet', value: 'Furet', emoji: '🐀' },
  { label: 'Hamster', value: 'Hamster', emoji: '🐹' },
  { label: 'Hérisson', value: 'Herisson', emoji: '🦔' },
  { label: 'Lama', value: 'Lama', emoji: '🦙' },
  { label: 'Lapin', value: 'Lapin', emoji: '🐰' },
  { label: 'Lézard', value: 'Lezard', emoji: '🦎' },
  { label: 'Mouton', value: 'Mouton', emoji: '🐑' },
  { label: 'Perroquet', value: 'Perroquet', emoji: '🦜' },
  { label: 'Poisson', value: 'Poisson', emoji: '🐠' },
  { label: 'Poule', value: 'Poule', emoji: '🐓' },
  { label: 'Rat', value: 'Rat', emoji: '🐀' },
  { label: 'Scorpion', value: 'Scorpion', emoji: '🦂' },
  { label: 'Serpent', value: 'Serpent', emoji: '🐍' },
  { label: 'Souris', value: 'Souris', emoji: '🐭' },
  { label: 'Tortue', value: 'Tortue', emoji: '🐢' },
  { label: 'Autres espèces', value: 'Autre', emoji: '❔' },
];

export const catRaceItems = [
  { label: 'Autre / Croisé', value: 'Autre / Croisé' },
  { label: 'Abyssin', value: 'Abyssin' },
  { label: 'American Bobtail', value: 'American Bobtail' },
  { label: 'American Curl', value: 'American Curl' },
  { label: 'American Shorthair', value: 'American Shorthair' },
  { label: 'American Wirehair', value: 'American Wirehair' },
  { label: 'Anatoli', value: 'Anatoli' },
  { label: 'Angora Turc', value: 'Angora Turc' },
  { label: 'Asian', value: 'Asian' },
  { label: 'Balinais', value: 'Balinais' },
  { label: 'Bengal', value: 'Bengal' },
  { label: 'Birman', value: 'Birman' },
  { label: 'Bleu Russe', value: 'Bleu Russe' },
  { label: 'Bobtail Américain', value: 'Bobtail Américain' },
  { label: 'Bobtail des Kouriles', value: 'Bobtail des Kouriles' },
  { label: 'Bobtail Japonais', value: 'Bobtail Japonais' },
  { label: 'Bombay', value: 'Bombay' },
  { label: 'Brazilian Shorthair', value: 'Brazilian Shorthair' },
  { label: 'British Longhair', value: 'British Longhair' },
  { label: 'British Shorthair', value: 'British Shorthair' },
  { label: 'Brume Australienne', value: 'Brume Australienne' },
  { label: 'Burmese', value: 'Burmese' },
  { label: 'Burmilla', value: 'Burmilla' },
  { label: 'Californian Rex', value: 'Californian Rex' },
  { label: 'Californian Spangled', value: 'Californian Spangled' },
  { label: 'Ceylan', value: 'Ceylan' },
  { label: 'Chantilly', value: 'Chantilly' },
  { label: 'Chartreux', value: 'Chartreux' },
  { label: 'Chausie', value: 'Chausie' },
  { label: 'Chinchilla Persan', value: 'Chinchilla Persan' },
  { label: 'Colorpoint Shorthair', value: 'Colorpoint Shorthair' },
  { label: 'Cornish Rex', value: 'Cornish Rex' },
  { label: 'Cymric', value: 'Cymric' },
  { label: 'Devon Rex', value: 'Devon Rex' },
  { label: 'Donskoy', value: 'Donskoy' },
  { label: 'Européen', value: 'Européen' },
  { label: 'Exotic', value: 'Exotic' },
  { label: 'German Rex', value: 'German Rex' },
  { label: 'Gouttière', value: 'Gouttière' },
  { label: 'Havana Brown', value: 'Havana Brown' },
  { label: 'Highland Straight', value: 'Highland Straight' },
  { label: 'Himalayen', value: 'Himalayen' },
  { label: 'Japanese Bobtail', value: 'Japanese Bobtail' },
  { label: 'Javanais', value: 'Javanais' },
  { label: 'Khao Manee', value: 'Khao Manee' },
  { label: 'Korat', value: 'Korat' },
  { label: 'LaPerm', value: 'LaPerm' },
  { label: 'Maine Coon', value: 'Maine Coon' },
  { label: 'Mandarin', value: 'Mandarin' },
  { label: 'Manx', value: 'Manx' },
  { label: 'Mau Arabe', value: 'Mau Arabe' },
  { label: 'Mau Égyptien', value: 'Mau Égyptien' },
  { label: 'Minskin', value: 'Minskin' },
  { label: 'Munchkin', value: 'Munchkin' },
  { label: 'Nebelung', value: 'Nebelung' },
  { label: 'Norvégien', value: 'Norvégien' },
  { label: 'Skogkatt', value: 'Skogkatt' },
  { label: 'Ocicat', value: 'Ocicat' },
  { label: 'Ojos Azules', value: 'Ojos Azules' },
  { label: 'Oriental', value: 'Oriental' },
  { label: 'Persan', value: 'Persan' },
  { label: 'Peterbald', value: 'Peterbald' },
  { label: 'Pixiebob', value: 'Pixiebob' },
  { label: 'Ragamuffin', value: 'Ragamuffin' },
  { label: 'Ragdoll', value: 'Ragdoll' },
  { label: 'Sacré De Birmanie', value: 'Sacré De Birmanie' },
  { label: 'Safari', value: 'Safari' },
  { label: 'Savannah', value: 'Savannah' },
  { label: 'Scottish Fold', value: 'Scottish Fold' },
  { label: 'Scottish Fold à Poil Long', value: 'Scottish Fold à Poil Long' },
  { label: 'Selkirk Rex', value: 'Selkirk Rex' },
  { label: 'Serengeti', value: 'Serengeti' },
  { label: 'Seychellois', value: 'Seychellois' },
  { label: 'Siamois', value: 'Siamois' },
  { label: 'Sibérien', value: 'Sibérien' },
  { label: 'Singapura', value: 'Singapura' },
  { label: 'Skookum', value: 'Skookum' },
  { label: 'Snowshoe', value: 'Snowshoe' },
  { label: 'Sokoké', value: 'Sokoké' },
  { label: 'Somali', value: 'Somali' },
  { label: 'Sphynx', value: 'Sphynx' },
  { label: 'Thaï', value: 'Thaï' },
  { label: 'Tiffany', value: 'Tiffany' },
  { label: 'Tonkinois', value: 'Tonkinois' },
  { label: 'Toyger', value: 'Toyger' },
  { label: 'Turkish Van', value: 'Turkish Van' },
  { label: 'Ural Rex', value: 'Ural Rex' },
  { label: 'York Chocolat', value: 'York Chocolat' },
];

export const dogRaceItems = [
  { label: 'Autre / Croisé', value: 'Autre / Croisé' },
  { label: 'Affenpinscher', value: 'Affenpinscher' },
  { label: 'Aïdi', value: 'Aïdi' },
  { label: 'Aïnou', value: 'Aïnou' },
  { label: 'Airedale Terrier', value: 'Airedale Terrier' },
  { label: 'Akbash', value: 'Akbash' },
  { label: 'Akita Américain', value: 'Akita Américain' },
  { label: 'Akita Inu', value: 'Akita Inu' },
  { label: 'Alangu Mastiff', value: 'Alangu Mastiff' },
  { label: 'Alano Espagnol', value: 'Alano Espagnol' },
  { label: 'Alaskan Klee Kai', value: 'Alaskan Klee Kai' },
  { label: 'Alaunt', value: 'Alaunt' },
  { label: 'Alopekis', value: 'Alopekis' },
  { label: 'American Staffordshire Terrier', value: 'American Staffordshire Terrier' },
  { label: "Ancien Chien d'Arrêt Danois", value: "Ancien Chien d'Arrêt Danois" },
  { label: 'Anglo-Français', value: 'Anglo-Français' },
  { label: 'Ariégeois', value: 'Ariégeois' },
  { label: 'Armant Dog', value: 'Armant Dog' },
  { label: 'Azawakh', value: 'Azawakh' },
  { label: 'Bangkaew de Thaïlande', value: 'Bangkaew de Thaïlande' },
  { label: 'Barak', value: 'Barak' },
  { label: 'Barbet', value: 'Barbet' },
  { label: 'Barbu Tchèque', value: 'Barbu Tchèque' },
  { label: 'Barzoï', value: 'Barzoï' },
  { label: 'Bas-Rouge', value: 'Bas-Rouge' },
  { label: 'Basenji', value: 'Basenji' },
  { label: 'Basset', value: 'Basset' },
  { label: 'Basset Hound', value: 'Basset Hound' },
  { label: 'Beagle', value: 'Beagle' },
  { label: 'Bearded Collie', value: 'Bearded Collie' },
  { label: 'Beauceron', value: 'Beauceron' },
  { label: 'Bedlington Terrier', value: 'Bedlington Terrier' },
  { label: 'Berger Allemand', value: 'Berger Allemand' },
  { label: 'Berger Australien', value: 'Berger Australien' },
  { label: 'Berger Belge', value: 'Berger Belge' },
  { label: 'Berger Catalan', value: 'Berger Catalan' },
  { label: "Berger d'Anatolie", value: "Berger d'Anatolie" },
  { label: "Berger d'Asie Centrale", value: "Berger d'Asie Centrale" },
  { label: "Berger d'Islande", value: "Berger d'Islande" },
  { label: 'Berger de Bergame', value: 'Berger de Bergame' },
  { label: 'Berger de Brie', value: 'Berger de Brie' },
  { label: 'Berger de Croatie', value: 'Berger de Croatie' },
  { label: "Berger de l'Atlas", value: "Berger de l'Atlas" },
  { label: 'Berger de Majorque', value: 'Berger de Majorque' },
  { label: 'Berger de Maremme et des Abruzzes', value: 'Berger de Maremme et des Abruzzes' },
  { label: 'Berger de Picardie', value: 'Berger de Picardie' },
  { label: 'Berger de Russie', value: 'Berger de Russie' },
  { label: 'Berger de Savoie ou Berger des Alpes', value: 'Berger de Savoie ou Berger des Alpes' },
  { label: 'Berger des Pyrénées', value: 'Berger des Pyrénées' },
  { label: 'Berger des Shetland', value: 'Berger des Shetland' },
  { label: 'Berger du Caucase', value: 'Berger du Caucase' },
  { label: 'Berger du Massif du Karst', value: 'Berger du Massif du Karst' },
  { label: 'Berger Finnois de laponie', value: 'Berger Finnois de laponie' },
  { label: 'Berger Hollandais', value: 'Berger Hollandais' },
  { label: 'Berger Polonais', value: 'Berger Polonais' },
  { label: 'Berger Portugais', value: 'Berger Portugais' },
  { label: 'Berger Roumain', value: 'Berger Roumain' },
  { label: 'Berger Tahitien', value: 'Berger Tahitien' },
  { label: 'Berger Yougoslave', value: 'Berger Yougoslave' },
  { label: 'Bichon', value: 'Bichon' },
  { label: 'Biewer Yorkshire', value: 'Biewer Yorkshire' },
  { label: 'Billy', value: 'Billy' },
  { label: 'Bleu de Gascogne', value: 'Bleu de Gascogne' },
  { label: 'Bobtail', value: 'Bobtail' },
  { label: 'Boerboel', value: 'Boerboel' },
  { label: 'Border Collie', value: 'Border Collie' },
  { label: 'Border Terrier', value: 'Border Terrier' },
  { label: 'Bouledogue Français', value: 'Bouledogue Français' },
  { label: 'Bouvier', value: 'Bouvier' },
  { label: 'Boxer', value: 'Boxer' },
  { label: 'Boykin Spaniel', value: 'Boykin Spaniel' },
  { label: 'Brabançon Petit', value: 'Brabançon Petit' },
  { label: 'Brachet', value: 'Brachet' },
  { label: 'Braque', value: 'Braque' },
  { label: 'Briquet Griffon Vendéen', value: 'Briquet Griffon Vendéen' },
  { label: 'Broholmer', value: 'Broholmer' },
  { label: 'Bruno du Jura', value: 'Bruno du Jura' },
  { label: 'Buhund Norvégien', value: 'Buhund Norvégien' },
  { label: 'Bull Boxer', value: 'Bull Boxer' },
  { label: 'Bull Terrier', value: 'Bull Terrier' },
  { label: 'Bulldog', value: 'Bulldog' },
  { label: 'Bulldog Anglais', value: 'Bulldog Anglais' },
  { label: 'Bullenbeißer', value: 'Bullenbeißer' },
  { label: 'Bullmastiff', value: 'Bullmastiff' },
  { label: 'Cairn Terrier', value: 'Cairn Terrier' },
  { label: 'Cane Corso', value: 'Cane Corso' },
  { label: 'Caniche', value: 'Caniche' },
  { label: 'Caniche Royal', value: 'Caniche Royal' },
  { label: 'Cão de Castro Laboreiro', value: 'Cão de Castro Laboreiro' },
  { label: 'Cão Fila de São Miguel', value: 'Cão Fila de São Miguel' },
  { label: 'Carlin', value: 'Carlin' },
  { label: 'Cavalier King Charles Spaniel', value: 'Cavalier King Charles Spaniel' },
  { label: 'Cavoodle', value: 'Cavoodle' },
  { label: 'Chesapeake Bay Retriever', value: 'Chesapeake Bay Retriever' },
  { label: 'Chien Chanteur', value: 'Chien Chanteur' },
  { label: 'Chien Chinois à Crête', value: 'Chien Chinois à Crête' },
  { label: 'Chien Courant', value: 'Chien Courant' },
  { label: "Chien d'Arrêt", value: "Chien d'Arrêt" },
  { label: "Chien d'Artois", value: "Chien d'Artois" },
  { label: "Chien d'Eau", value: "Chien d'Eau" },
  { label: "Chien d'Élan", value: "Chien d'Élan" },
  { label: "Chien d'Ours de Carélie", value: "Chien d'Ours de Carélie" },
  { label: "Chien d'Oysel", value: "Chien d'Oysel" },
  { label: 'Chien de Bali', value: 'Chien de Bali' },
  { label: 'Chien de Canaan', value: 'Chien de Canaan' },
  { label: 'Chien de Garenne', value: 'Chien de Garenne' },
  { label: 'Chien de Montagne', value: 'Chien de Montagne' },
  { label: 'Chien de Saint-Hubert', value: 'Chien de Saint-Hubert' },
  { label: 'Chien de Taïwan', value: 'Chien de Taïwan' },
  { label: 'Chien de Terre-Neuve', value: 'Chien de Terre-Neuve' },
  { label: 'Chien du Groenland', value: 'Chien du Groenland' },
  { label: 'Chien du Pharaon', value: 'Chien du Pharaon' },
  { label: 'Chien Finnois de Laponie', value: 'Chien Finnois de Laponie' },
  { label: 'Chien Norvégien de Macareux', value: 'Chien Norvégien de Macareux' },
  { label: 'Chien Nu du Pérou', value: 'Chien Nu du Pérou' },
  { label: 'Chien Nu Mexicain', value: 'Chien Nu Mexicain' },
  { label: 'Chien Paria', value: 'Chien Paria' },
  { label: 'Chien Rouge', value: 'Chien Rouge' },
  { label: 'Chien Russe Petit', value: 'Chien Russe Petit' },
  { label: 'Chien Suédois de Laponie', value: 'Chien Suédois de Laponie' },
  { label: 'Chien Thaïlandais', value: 'Chien Thaïlandais' },
  { label: 'Chien-Loup de Saarloos', value: 'Chien-Loup de Saarloos' },
  { label: 'Chien-Loup Tchèque', value: 'Chien-Loup Tchèque' },
  { label: 'Chihuahua', value: 'Chihuahua' },
  { label: 'Chinook', value: 'Chinook' },
  { label: 'Chow-Chow', value: 'Chow-Chow' },
  { label: 'Cimarron Uruguayen', value: 'Cimarron Uruguayen' },
  { label: "Cirneco de l'Étna", value: "Cirneco de l'Étna" },
  { label: 'Clumber Spaniel', value: 'Clumber Spaniel' },
  { label: 'Cockapoo', value: 'Cockapoo' },
  { label: 'Cocker', value: 'Cocker' },
  { label: 'Colley', value: 'Colley' },
  { label: 'Coonhound', value: 'Coonhound' },
  { label: 'Coton de Tuléar', value: 'Coton de Tuléar' },
  { label: 'Curly-Coated Retriever', value: 'Curly-Coated Retriever' },
  { label: 'Cursinu', value: 'Cursinu' },
  { label: 'Dachshund', value: 'Dachshund' },
  { label: 'Dalmatien', value: 'Dalmatien' },
  { label: 'Dandie Dinmont Terrier', value: 'Dandie Dinmont Terrier' },
  { label: 'Deerhound', value: 'Deerhound' },
  { label: 'Dingo', value: 'Dingo' },
  { label: 'Dobermann', value: 'Dobermann' },
  { label: 'Dogo Canario', value: 'Dogo Canario' },
  { label: 'Dogue', value: 'Dogue' },
  { label: 'Drever', value: 'Drever' },
  { label: 'Dunker', value: 'Dunker' },
  { label: 'English Springer Spaniel', value: 'English Springer Spaniel' },
  { label: 'Épagneul', value: 'Épagneul' },
  { label: 'Esquimau', value: 'Esquimau' },
  { label: 'Eurasier', value: 'Eurasier' },
  { label: 'Field Spaniel', value: 'Field Spaniel' },
  { label: 'Fila Brasileiro', value: 'Fila Brasileiro' },
  { label: 'Flat-Coated Retriever', value: 'Flat-Coated Retriever' },
  { label: 'Fox-Terrier', value: 'Fox-Terrier' },
  { label: 'Foxhound Américain', value: 'Foxhound Américain' },
  { label: 'Foxhound Anglais', value: 'Foxhound Anglais' },
  { label: 'Français', value: 'Français' },
  { label: 'Gascon Saintongeois', value: 'Gascon Saintongeois' },
  { label: 'Goldador', value: 'Goldador' },
  { label: 'Golden Retriever', value: 'Golden Retriever' },
  { label: 'Goldendoodle', value: 'Goldendoodle' },
  { label: 'Greyhound', value: 'Greyhound' },
  { label: 'Griffon', value: 'Griffon' },
  { label: 'Hamilton Stövare', value: 'Hamilton Stövare' },
  { label: 'Harrier', value: 'Harrier' },
  { label: 'Hokkaido Ken', value: 'Hokkaido Ken' },
  { label: 'Hovawart', value: 'Hovawart' },
  { label: 'Huntaway', value: 'Huntaway' },
  { label: 'Husky Sibérien', value: 'Husky Sibérien' },
  { label: 'Irish Glen of Imaal Terrier', value: 'Irish Glen of Imaal Terrier' },
  { label: 'Irish Terrier', value: 'Irish Terrier' },
  { label: 'Irish Wolfhound', value: 'Irish Wolfhound' },
  { label: 'Jack Russell Terrier', value: 'Jack Russell Terrier' },
  { label: 'Jagdterrier', value: 'Jagdterrier' },
  { label: 'Jindo Coréen', value: 'Jindo Coréen' },
  { label: 'Jouet Fox Terrier', value: 'Jouet Fox Terrier' },
  { label: 'Jouet Manchester Terrier', value: 'Jouet Manchester Terrier' },
  { label: 'Jug', value: 'Jug' },
  { label: 'Kai', value: 'Kai' },
  { label: 'Kangal Dog', value: 'Kangal Dog' },
  { label: 'Keeshond', value: 'Keeshond' },
  { label: 'Kelpie', value: 'Kelpie' },
  { label: 'Kerry Beagle', value: 'Kerry Beagle' },
  { label: 'Kerry Blue Terrier', value: 'Kerry Blue Terrier' },
  { label: 'King Charles Spaniel', value: 'King Charles Spaniel' },
  { label: 'King Shepherd', value: 'King Shepherd' },
  { label: 'Kishu', value: 'Kishu' },
  { label: 'Komondor', value: 'Komondor' },
  { label: 'Kooikerhondje', value: 'Kooikerhondje' },
  { label: 'Koolie', value: 'Koolie' },
  { label: 'Korea Jindo Dog', value: 'Korea Jindo Dog' },
  { label: 'Kromfohrländer', value: 'Kromfohrländer' },
  { label: 'Kuchi Dog', value: 'Kuchi Dog' },
  { label: 'Kuvasz', value: 'Kuvasz' },
  { label: 'Kyi Apso Tibétain', value: 'Kyi Apso Tibétain' },
  { label: 'Kyi Leo', value: 'Kyi Leo' },
  { label: 'Labernois', value: 'Labernois' },
  { label: 'Labradinger', value: 'Labradinger' },
  { label: 'Labradoodle', value: 'Labradoodle' },
  { label: 'Labrador', value: 'Labrador' },
  { label: 'Laïka', value: 'Laïka' },
  { label: 'Lakeland Terrier', value: 'Lakeland Terrier' },
  { label: 'Lancashire Heeler', value: 'Lancashire Heeler' },
  { label: 'Landseer', value: 'Landseer' },
  { label: 'Leonberger', value: 'Leonberger' },
  { label: 'Lévrier', value: 'Lévrier' },
  { label: 'Lhassa Apso', value: 'Lhassa Apso' },
  { label: 'Longdog', value: 'Longdog' },
  { label: 'Loulou de Poméranie', value: 'Loulou de Poméranie' },
  { label: 'Lurcher', value: 'Lurcher' },
  { label: 'Mackenzie River Husky', value: 'Mackenzie River Husky' },
  { label: 'Mal-Shi', value: 'Mal-Shi' },
  { label: "Malamute de l'Alaska", value: "Malamute de l'Alaska" },
  { label: 'Maltipoo', value: 'Maltipoo' },
  { label: 'Manchester Terrier', value: 'Manchester Terrier' },
  { label: 'Mastiff', value: 'Mastiff' },
  { label: 'Mâtin', value: 'Mâtin' },
  { label: 'Mountain Cur', value: 'Mountain Cur' },
  { label: 'Mudi', value: 'Mudi' },
  { label: 'Münsterländer', value: 'Münsterländer' },
  { label: 'Otterhound', value: 'Otterhound' },
  { label: 'Parson Russell Terrier', value: 'Parson Russell Terrier' },
  { label: 'Patterdale Terrier', value: 'Patterdale Terrier' },
  { label: 'Pékinois', value: 'Pékinois' },
  { label: 'Perro Fino Colombiano', value: 'Perro Fino Colombiano' },
  { label: 'Petit Chien Lion', value: 'Petit Chien Lion' },
  { label: 'Phalène', value: 'Phalène' },
  { label: 'Pinscher', value: 'Pinscher' },
  { label: 'Pit Bull', value: 'Pit Bull' },
  { label: 'Plott Hound', value: 'Plott Hound' },
  { label: 'Podenco', value: 'Podenco' },
  { label: 'Pointer', value: 'Pointer' },
  { label: 'Poitevin', value: 'Poitevin' },
  { label: 'Pomsky', value: 'Pomsky' },
  { label: 'Porcelaine', value: 'Porcelaine' },
  { label: 'Pudelpointer', value: 'Pudelpointer' },
  { label: 'Pug', value: 'Pug' },
  { label: 'Puggle', value: 'Puggle' },
  { label: 'Puli', value: 'Puli' },
  { label: 'Pumi', value: 'Pumi' },
  { label: 'Rafeiro Do Alentejo', value: 'Rafeiro Do Alentejo' },
  { label: 'Ratier de Prague', value: 'Ratier de Prague' },
  { label: 'Ratonero Bodeguero Andaluz', value: 'Ratonero Bodeguero Andaluz' },
  { label: 'Retriever', value: 'Retriever' },
  { label: 'Rhodesian Ridgeback', value: 'Rhodesian Ridgeback' },
  { label: 'Rottweiler', value: 'Rottweiler' },
  { label: 'Russkiy Toy', value: 'Russkiy Toy' },
  { label: 'Sabueso Español', value: 'Sabueso Español' },
  { label: 'Saint-Bernard', value: 'Saint-Bernard' },
  { label: 'Saluki', value: 'Saluki' },
  { label: 'Samoyède', value: 'Samoyède' },
  { label: 'Sapsali', value: 'Sapsali' },
  { label: 'Schapendoes Néerlandais', value: 'Schapendoes Néerlandais' },
  { label: 'Schiller Stövare', value: 'Schiller Stövare' },
  { label: 'Schipperke', value: 'Schipperke' },
  { label: 'Schnauzer', value: 'Schnauzer' },
  { label: 'Schnoodle', value: 'Schnoodle' },
  { label: 'Schweizer laufhund', value: 'Schweizer laufhund' },
  { label: 'Scottish Terrier', value: 'Scottish Terrier' },
  { label: 'Sealyham Terrier', value: 'Sealyham Terrier' },
  { label: 'Segugio Maremmano', value: 'Segugio Maremmano' },
  { label: 'Setter', value: 'Setter' },
  { label: 'Shar Pei', value: 'Shar Pei' },
  { label: 'Sheeoadoodle', value: 'Sheeoadoodle' },
  { label: 'Shiba Inu', value: 'Shiba Inu' },
  { label: 'Shih Tzu', value: 'Shih Tzu' },
  { label: 'Shih-Poo', value: 'Shih-Poo' },
  { label: 'Shikoku', value: 'Shikoku' },
  { label: 'Silky Terrier', value: 'Silky Terrier' },
  { label: 'Skye Terrier', value: 'Skye Terrier' },
  { label: 'Sloughi', value: 'Sloughi' },
  { label: 'Slovensky Cuvac', value: 'Slovensky Cuvac' },
  { label: 'Småland Stövare', value: 'Småland Stövare' },
  { label: 'Smous des Pays-Bas', value: 'Smous des Pays-Bas' },
  { label: 'Spinone', value: 'Spinone' },
  { label: 'Spitz', value: 'Spitz' },
  { label: 'Sporting Lucas Terrier', value: 'Sporting Lucas Terrier' },
  { label: 'Springer Anglais', value: 'Springer Anglais' },
  { label: 'Stabyhoun', value: 'Stabyhoun' },
  { label: 'Staffie', value: 'Staffie' },
  { label: 'Staffordshire Bull Terrier', value: 'Staffordshire Bull Terrier' },
  { label: 'Staffordshire Terrier Américain', value: 'Staffordshire Terrier Américain' },
  { label: 'Stephens Cur', value: 'Stephens Cur' },
  { label: 'Sussex Spaniel', value: 'Sussex Spaniel' },
  { label: 'Talbot', value: 'Talbot' },
  { label: 'Tamaskan', value: 'Tamaskan' },
  { label: 'Tchouvatch Slovaque', value: 'Tchouvatch Slovaque' },
  { label: 'Teckel', value: 'Teckel' },
  { label: 'Teddy Roosevelt Terrier', value: 'Teddy Roosevelt Terrier' },
  { label: 'Tenterfield Terrier', value: 'Tenterfield Terrier' },
  { label: 'Terre-Neuve', value: 'Terre-Neuve' },
  { label: 'Terrier', value: 'Terrier' },
  { label: 'Texas Heeler', value: 'Texas Heeler' },
  { label: 'Tosa', value: 'Tosa' },
  { label: 'Toy Terrier Anglais Noir et Feu', value: 'Toy Terrier Anglais Noir et Feu' },
  { label: 'Treeing', value: 'Treeing' },
  { label: 'Volpino', value: 'Volpino' },
  { label: 'Welsh Corgi', value: 'Welsh Corgi' },
  { label: 'Welsh Springer Spaniel', value: 'Welsh Springer Spaniel' },
  { label: 'Welsh Terrier', value: 'Welsh Terrier' },
  { label: 'West Highland White Terrier', value: 'West Highland White Terrier' },
  { label: 'Whippet', value: 'Whippet' },
  { label: 'Yorkillon', value: 'Yorkillon' },
  { label: 'Yorkipoo', value: 'Yorkipoo' },
  { label: 'Yorkshire Terrier', value: 'Yorkshire Terrier' },
];

export const horseRaceItems = [
  { label: 'Andalou', value: 'Andalou' },
  { label: 'Anglo-Arable', value: 'Anglo-Arable' },
  { label: 'Appaloosa', value: 'Appaloosa' },
  { label: 'AQPS', value: 'AQPS' },
  { label: 'Autre / Croisé', value: 'Autre / Croisé' },
  { label: 'Barbe', value: 'Barbe' },
  { label: 'Boulonais', value: 'Boulonais' },
  { label: 'Bwp', value: 'Bwp' },
  { label: 'Connemara', value: 'Connemara' },
  { label: 'Fjord', value: 'Fjord' },
  { label: 'Frison', value: 'Frison' },
  { label: 'Haflinger', value: 'Haflinger' },
  { label: 'Hanovrien', value: 'Hanovrien' },
  { label: 'Islandais', value: 'Islandais' },
  { label: 'Kwpn', value: 'Kwpn' },
  { label: 'Lusitanien', value: 'Lusitanien' },
  { label: 'Merens', value: 'Merens' },
  { label: 'Oldenburg', value: 'Oldenburg' },
  { label: 'Paint Horse', value: 'Paint Horse' },
  { label: 'Percheron', value: 'Percheron' },
  { label: 'Postier Breton', value: 'Postier Breton' },
  { label: 'Pur Sang Anglais', value: 'Pur Sang Anglais' },
  { label: 'Pur Sang Arabe', value: 'Pur Sang Arabe' },
  { label: 'Quarter Horse', value: 'Quarter Horse' },
  { label: 'Selle Français', value: 'Selle Français' },
  { label: 'Shetland', value: 'Shetland' },
  { label: 'Trotteur Français', value: 'Trotteur Français' },
  { label: 'Welsh', value: 'Welsh' },
];

export const raceIdItems = petCategoryItems.concat(speciesItems);
