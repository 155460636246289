import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './ButtonCmv.container';
import { ThemeComponents } from '../../theme/components';

/* The css defined in ThemeComponent follow this pattern : `Btn${btnSize}${style} */

const ICON_SIZE = {
  giant: '24px',
  large: '24px',
  medium: '16px',
  small: '16px',
  tiny: '16px',
};

export class ButtonCmv extends Component<Props> {
  public render() {
    const { btnSize, btnStyle, content, disabled, leftIcon, rightIcon, type, onClick, spinner } = this.props;
    const btnSizeClassName = btnSize.charAt(0).toUpperCase() + btnSize.slice(1);
    const btnStyleClassName = btnStyle.charAt(0).toUpperCase() + btnStyle.slice(1);
    const btnClassName = 'Btn' + btnSizeClassName + btnStyleClassName;
    const Button = styled(ThemeComponents[btnClassName as keyof typeof ThemeComponents] as any)``;
    const LeftIcon = leftIcon ? styled(leftIcon)`` : styled.span``;
    const RightIcon = rightIcon ? styled(rightIcon)`` : styled.span``;
    return (
      <Button
        onClick={onClick}
        onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
        }}
        disabled={disabled}
        type={type ? type : 'button'}
      >
        <LeftIcon style={{ width: ICON_SIZE[btnSize], height: ICON_SIZE[btnSize] }} />
        <ButtonContent>{spinner ? <Spinner /> : content}</ButtonContent>
        <RightIcon style={{ width: ICON_SIZE[btnSize], height: ICON_SIZE[btnSize] }} />
      </Button>
    );
  }
}

const ButtonContent = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`;

const Spinner = styled.span`
    width: 24px;
    height: 24px;
    border: 4px solid #1BA3C1;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    @keyframes rotation {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
    }
`;