import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../../lib/withApolloClient';
import { OrganisationContact as OrganisationContactComponent } from './OrganisationContact.component';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
import { withAppContext } from '../../../lib/withContext';

export type Props = WithApolloClient<RouteComponentProps>;

export const OrganisationContact = compose<Props, RouteComponentProps>(
  withApollo,
  withAppContext
)(OrganisationContactComponent);
