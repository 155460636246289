import React, { Component } from 'react';
import { Props } from './ProfileName.container';
import styled from 'styled-components';
import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { MasterBody } from '../../../components/Layout/MasterBody';
import { MasterContainer } from '../../../components/Layout/MasterContainer';
import { HeaderSectionForm } from '../../../components/Form/HeaderSectionForm';
import { i18n } from '../../../lib/i18n';
import { Formik, Field, Form } from 'formik';
import { TextInputForm } from '../../../components/Form';
import { profileNameValidationSchema } from './ProfileName.validation';
import { ButtonsForm } from '../../../components/Form/ButtonsForm';
import { appContext } from '../../../context/appContext';
import { loader } from 'graphql.macro';

const updateAccountNames = loader('../../../graphql/updateAccountNames.graphql');

interface ValuesForm {
  firstName: string;
  lastName: string;
}

// export interface State {}

export class ProfileName extends Component<Props> {
  private onSubmit = async (values: ValuesForm) => {
    // console.log(values);

    try {
      await this.props.client.mutate({
        mutation: updateAccountNames,
        variables: {
          firstName: values.firstName,
          lastName: values.lastName,
        },
      });
      this.context.updateContext();
      this.props.history.goBack();
    } catch (error) {
      throw new Error(error);
    }
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;
  render() {
    const account = this.context.currentAccount;
    return (
      <>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <MasterContainer noSideMenu={true}>
            <Container>
              <HeaderContainer>
                <Subtitle>
                  {i18n.t('profile.personalProfile.name.subtitle', {
                    firstName: account?.firstName,
                    lastName: account?.lastName,
                  })}
                </Subtitle>
                <Title>{i18n.t('profile.personalProfile.name.title')}</Title>
              </HeaderContainer>

              <SectionContainer>
                <HeaderSectionForm
                  title={i18n.t('profile.personalProfile.name.form.title')}
                  subtitle={i18n.t('profile.personalProfile.name.form.subtitle')}
                />

                <Formik
                  initialValues={{
                    firstName: account?.firstName ? account.firstName : '',
                    lastName: account?.lastName ? account.lastName : '',
                  }}
                  onSubmit={this.onSubmit}
                  validationSchema={profileNameValidationSchema}
                >
                  {() => (
                    <ProfileNameForm>
                      <ProfileNameField>
                        <Field
                          name={'firstName'}
                          label={i18n.t('profile.personalProfile.name.form.firstName.label')}
                          placeholder={i18n.t(
                            'profile.personalProfile.name.form.firstName.placeholder'
                          )}
                          component={TextInputForm}
                        />
                        <Field
                          name={'lastName'}
                          label={i18n.t('profile.personalProfile.name.form.lastName.label')}
                          placeholder={i18n.t(
                            'profile.personalProfile.name.form.lastName.placeholder'
                          )}
                          component={TextInputForm}
                        />
                      </ProfileNameField>
                      <ButtonsForm
                        cancelContent={i18n.t('profile.form.button.cancel')}
                        validateContent={i18n.t('profile.form.button.save')}
                        onCancel={() => this.props.history.goBack()}
                        type={'submit'}
                      />
                    </ProfileNameForm>
                  )}
                </Formik>
              </SectionContainer>
            </Container>
          </MasterContainer>
        </MasterBody>
      </>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.pure};
  padding-left: 10px;
  padding-right: 10px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 200px;
    padding-right: 200px;
  }
`;

const HeaderContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;

const Subtitle = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.body.small.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.body.small.fontSize};
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileNameForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 35px;
`;

const ProfileNameField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 0;
  }
`;
