import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Props } from './Header.container';

export class Header extends Component<Props> {
  render() {
    const {
      headerTitle,
      headerLabelLeft,
      headerIconLeft,
      headerLabelRight,
      headerIconRight,
      headerOnClickLeft,
      headerOnClickRight,
    } = this.props;

    const hasTitleFullWidth =
      !headerLabelLeft && !headerIconLeft && !headerLabelRight && !headerIconRight;

    const HeaderIconLeftComponent = headerIconLeft;
    const HeaderIconRightComponent = headerIconRight;
    return (
      <Container hidden={hasTitleFullWidth && !headerTitle}>
        <LeftContainer noWidth={hasTitleFullWidth}>
          {HeaderIconLeftComponent && (
            <LeftIconContainer onClick={() => (headerOnClickLeft ? headerOnClickLeft() : null)}>
              <HeaderIconLeftComponent />
            </LeftIconContainer>
          )}
          {headerLabelLeft && <LeftLabel>{headerLabelLeft}</LeftLabel>}
        </LeftContainer>
        <TitleContainer fullWidth={hasTitleFullWidth}>
          <Title>{headerTitle}</Title>
        </TitleContainer>
        <RightContainer noWidth={hasTitleFullWidth}>
          {headerLabelRight && <RightLabel>{headerLabelRight}</RightLabel>}

          {HeaderIconRightComponent && (
            <RightIconContainer onClick={() => (headerOnClickRight ? headerOnClickRight() : null)}>
              <HeaderIconRightComponent />
            </RightIconContainer>
          )}
        </RightContainer>
      </Container>
    );
  }
}

const ellipsisText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Container = styled.div<{ hidden: boolean }>`
  width: 100%;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 0 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.white.darker};
`;

const TitleContainer = styled.div<{ fullWidth: boolean }>`
  width: ${(props) => (props.fullWidth ? '100%' : '60%')};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: yellow; */
  padding: 0 10px;
`;

const Title = styled.div`
  ${ellipsisText}
  font-family: ${(props) => props.theme.textStyles.typography.heading.h6.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h6.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h6.fontSize};
  color: ${(props) => props.theme.colors.black.light};
`;

const LeftContainer = styled.div<{ noWidth: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;
  /* background-color: green; */
  width: ${(props) => (props.noWidth ? '0%' : '20%')};
`;

const RightContainer = styled.div<{ noWidth: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: ${(props) => (props.noWidth ? '0%' : '20%')};
  /* background-color: pink; */
`;

const LeftIconContainer = styled.div`
  cursor: pointer;
`;

const LeftLabel = styled.div`
  ${ellipsisText}
  width: calc(100% - 24px);
  font-family: ${(props) => props.theme.textStyles.typography.lead.main.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.main.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.lead.main.fontSize};
  color: ${(props) => props.theme.colors.black.light};
  line-height: 18px;
  padding-left: 5px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    display: none;
  }
`;

const RightIconContainer = styled.div`
  cursor: pointer;
`;

const RightLabel = styled.div`
  ${ellipsisText}
  width: calc(100% - 24px);
  text-align: right;
  font-family: ${(props) => props.theme.textStyles.typography.lead.main.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.main.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.lead.main.fontSize};
  color: ${(props) => props.theme.colors.black.light};
  line-height: 18px;
  padding-right: 5px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    display: none;
  }
`;
