import React, { Component } from 'react';
import styled from 'styled-components';
// import { FieldProps, FormikProps } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import { ThemeComponents } from '../../../theme/components';
import { CheckBoxForm } from '../CheckBoxForm/CheckBoxForm.component';
import { Props } from './MultipleCheckForm.container';

export class MultipleCheckForm extends Component<Props> {
  public handleChange(boxSelectedIndex: number, field: any) {
    const newCheckValues = field.value.map(
      (e: { label: string; value: string; checked: boolean }, i: number) => {
        if (i === boxSelectedIndex) return { ...e, checked: !e.checked };
        else return e;
      }
    );
    /* Field name must be unique across all app */
    this.props.form.setFieldValue(field.name, newCheckValues);
  }

  public render() {
    //TODO handle required label
    const { form, label, sublabel, data, field } = this.props;
    const error = form.errors[field.name];
    const touched = form.touched[field.name];
    return (
      <MultipleCheckContainer fluid>
        <MultipleCheckRow noGutters>
          {(label || sublabel) && (
            <Col xs={12} sm={4}>
              <MultipleCheckLabelContainer>
                <MultipleCheckLabel>{label}</MultipleCheckLabel>
                <MultipleCheckSubLabel>{sublabel}</MultipleCheckSubLabel>
              </MultipleCheckLabelContainer>
            </Col>
          )}
          <Col xs={12} sm={label || sublabel ? 8 : 12}>
            <Row>
              {data &&
                data.map((e, i) => (
                  <Col key={i} xs={12} sm={label || sublabel ? 6 : 4}>
                    <SimpleCheck
                      error={error}
                      touched={touched}
                      onClick={() => this.handleChange(i, field)}
                      checked={field.value[i].checked}
                    >
                      <SimpleCheckInput
                        checked={field.value[i].checked}
                        onChange={() => this.handleChange(i, field)}
                        label={e.label}
                        width={20}
                        height={20}
                      />
                    </SimpleCheck>
                  </Col>
                ))}
            </Row>
            {touched && error && <MutlipleCheckError>{error}</MutlipleCheckError>}
          </Col>
        </MultipleCheckRow>
      </MultipleCheckContainer>
    );
  }
}

const MultipleCheckContainer = styled(Container)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 16px 0;
  padding: 0;
`;

const MultipleCheckRow = styled(Row)`
  width: 100%;
`;

const MultipleCheckLabelContainer = styled.div`
  margin: 5px 15px 5px 0;
`;

const MultipleCheckLabel = styled(ThemeComponents.FormLabel)`
  /* padding-top: 8px; */
`;

const MultipleCheckSubLabel = styled(ThemeComponents.FormSubLabel)``;

const SimpleCheck = styled(ThemeComponents.FormSimpleCheck)<{ checked: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => props.theme.componentObjects.formMultipleCheck.padding};
  margin-bottom: 10px;
  border: ${(props) =>
    props.checked
      ? props.theme.componentObjects.formMultipleCheck.borderChecked
      : props.theme.componentObjects.formMultipleCheck.borderNotChecked};
  color: ${(props) =>
    props.checked
      ? props.theme.componentObjects.formMultipleCheck.textChecked
      : props.theme.componentObjects.formMultipleCheck.textNotChecked};
  font-weight: ${(props) => props.theme.componentObjects.formMultipleCheck.fontWeight};
  font-size: ${(props) => props.theme.componentObjects.formMultipleCheck.fontSize};
  line-height: ${(props) => props.theme.componentObjects.formMultipleCheck.lineHeight};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SimpleCheckInput = styled(CheckBoxForm)``;

const MutlipleCheckError = styled(ThemeComponents.FormErrorLabel)``;
