import { environment } from '../environment';
// import { SignUpFormValues } from '../types/SignUpForm';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const stripe = window.Stripe(environment.STRIPE_PUBLISHABLE_KEY);

export const createStripeToken = async (
  companyName: string,
  firstName: string,
  lastName: string
): Promise<string> => {
  const accountResult = await stripe.createToken('account', {
    business_type: 'company',
    company: {
      name: companyName,
    },
    tos_shown_and_accepted: true,
  });
  return accountResult.token?.id ? accountResult.token.id : '';
};

// export const createStripePersonToken = async (values: SignUpFormValues): Promise<string> => {
//   const personResult = await stripe.createToken('person', {
//     person: {
//       first_name: values.firstName,
//       last_name: values.lastName,
//       email: values.email,
//       phone: values.mobilePhoneNumber,
//       relationship: {
//         director: true,
//         executive: true,
//         owner: true,
//         representative: true,
//       },
//       address: {
//         country: values.nationality === 'BELGIAN' ? 'BE' : 'FR',
//         line1: values.address,
//         postal_code: values.postalCode,
//         city: values.city,
//       },
//     },
//   });

//   return personResult.token.id;
// };
