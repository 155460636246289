import React, { Component } from 'react';
import styled from 'styled-components';
import { BodyProps, FooterProps } from './AccountEmailSentConfirmation.container';
import { i18n } from '../../lib/i18n';

export class AccountEmailSentConfirmationBody extends Component<BodyProps> {
  render() {
    return (
      <BodyContainer>
        <BodyRecipient>
          <div>{i18n.t('createAccount.confirmation.emailSend')}</div>
          <BodyRecipientEmail>
            {i18n.t('createAccount.confirmation.recipient', {
              email: this.props.email,
            })}
          </BodyRecipientEmail>
        </BodyRecipient>
        <BodyDescritption>{i18n.t('createAccount.confirmation.description')}</BodyDescritption>
      </BodyContainer>
    );
  }
}

export class AccountEmailSentConfirmationFooter extends Component<FooterProps> {
  render() {
    return (
      <FooterContainer>
        <FooterContainerDescription>
          {i18n.t('createAccount.confirmation.emailNotReceived.description')}
        </FooterContainerDescription>
        <FooterContainerLink>
          {i18n.t('createAccount.confirmation.emailNotReceived.resendEmailLink.label')}
          <FooterLink
            href={'#'}
            onClick={() => (this.props.resendEmailLink ? this.props.resendEmailLink() : null)}
          >
            {i18n.t('createAccount.confirmation.emailNotReceived.resendEmailLink.link')}
          </FooterLink>
        </FooterContainerLink>
        <FooterContainerLink>
          {i18n.t('createAccount.confirmation.emailNotReceived.cancelLink.label')}
          <FooterLink
            href={'#'}
            onClick={() => (this.props.cancelSignUp ? this.props.cancelSignUp() : null)}
          >
            {i18n.t('createAccount.confirmation.emailNotReceived.cancelLink.link')}
          </FooterLink>
        </FooterContainerLink>
      </FooterContainer>
    );
  }
}

const BodyContainer = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontFamily};
  font-size: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontWeight};
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 100px;
    padding-right: 100px;
  }
`;

const BodyRecipient = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const BodyDescritption = styled.div`
  margin-top: 30px;
  text-align: center;
`;

const BodyRecipientEmail = styled.div`
  margin-left: 5px;
  font-weight: bold;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${(props) => props.theme.textStyles.typography.lead.small.fontFamily};
  font-size: ${(props) => props.theme.textStyles.typography.lead.small.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.small.fontWeight};
  color: ${(props) => props.theme.colors.grey.darker};
  text-align: center;
`;

const FooterContainerDescription = styled.div`
  margin-bottom: 10px;
`;

const FooterContainerLink = styled.div`
  & > a {
    color: ${(props) => props.theme.colors.primary.mainColor};
    margin-left: 5px;
  }
`;

const FooterLink = styled.a``;
