import { LongTextInputForm as LongTextInputFormComponent } from './LongTextInputForm.component';

export type Props = {
  label?: string;
  sublabel?: string;
  placeholder?: string;
  required?: boolean;
  direction: 'column' | 'auto';
  form: any;
  field: any;
};

export const LongTextInputForm = LongTextInputFormComponent;
