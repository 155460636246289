import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './ProfileMenuNotConnected.container';
import { ButtonCmv } from '../../../ButtonCmv';

export class ProfileMenuNotConnected extends Component<Props> {
  public render() {
    return (
      <ProfileMenuNotConnectedContainer>
        <ButtonConnexion>
          <ButtonCmv
            btnStyle={'primaryHollow'}
            btnSize={'medium'}
            content={'Connexion'}
            onClick={() => this.props.history.push('/SignIn')}
          />
        </ButtonConnexion>
        <ButtonInscription>
          <ButtonCmv
            btnStyle={'primary'}
            btnSize={'medium'}
            content={'Inscription'}
            onClick={() => this.props.history.push('/create-account')}
          />
        </ButtonInscription>
      </ProfileMenuNotConnectedContainer>
    );
  }
}

const ProfileMenuNotConnectedContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const ButtonConnexion = styled.div`
  margin: 30px 10%;
`;

const ButtonInscription = styled.div`
  margin: 30px 10%;
`;
