import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../lib/withApolloClient';
import { Clients as ClientsComponent } from './Clients.component';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
 import { withAppContext } from '../../lib/withContext';
import { withTracker } from '../../lib/withTracker';

type ContainerProps = {
  companyCode: string;
}


export type Props = WithApolloClient<RouteComponentProps & ContainerProps>;

// export const Clients = withApollo(ClientsComponent);
export const Clients = compose<Props, ContainerProps>(
  withApollo,
  withTracker,
  withAppContext
)(ClientsComponent);
