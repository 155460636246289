import React, { Component, Fragment } from 'react';
import { Props } from './CreateAccountValidated.container';
import styled from 'styled-components';
import images from '../../../theme/images';
import { MasterBody } from '../../../components/Layout/MasterBody';
import { MasterContainer } from '../../../components/Layout/MasterContainer';
import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { ButtonCmv } from '../../../components/ButtonCmv';
import { i18n } from '../../../lib/i18n';
import { ImageCmv } from '../../../components/ImageCmv';
import { appContext } from '../../../context/appContext';
import { Firebase } from '../../../lib/firebase';

// interface State {}

export class CreateAccountValidated extends Component<Props> {
  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;
  public render() {
    return (
      <Fragment>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <MasterContainer noSideMenu={true}>
            <AccountCreationConfirmationContainer>
              <ImageCmvContainer>
                <ImageCmv
                  source={images.OnboardingIllustrationAccountVerified}
                  sourceRetina={images.OnboardingIllustrationAccountVerified2x}
                />
              </ImageCmvContainer>

              <AccountCreationConfirmationTitle>
                {i18n.t('createAccountValidated.title')}
              </AccountCreationConfirmationTitle>
              <AccountCreationConfirmationFirstSection>
                {i18n.t('createAccountValidated.firstSection')}
              </AccountCreationConfirmationFirstSection>
              <AccountCreationConfirmationBtnContainer>
                <ButtonCmv
                  type={'submit'}
                  btnSize={'large'}
                  btnStyle={'primary'}
                  content={i18n.t('createAccountValidated.continue')}
                  onClick={() => {
                    if (Firebase.getCurrentUser())
                      Firebase.reloadUser(() => this.context.updateContext());
                    else this.props.history.push('Dashboard');
                  }}
                />
              </AccountCreationConfirmationBtnContainer>
            </AccountCreationConfirmationContainer>
          </MasterContainer>
        </MasterBody>
      </Fragment>
    );
  }
}

const AccountCreationConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white.pure};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.grey.lighter};
  box-sizing: border-box;
  margin-top: 38px;
  padding-left: 10px;
  padding-right: 10px;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
    padding-left: 180px;
    padding-right: 180px;
  }
`;

const ImageCmvContainer = styled.div`
  width: 120px;
  height: 120px;
  margin-top: 40px;
`;

const AccountCreationConfirmationTitle = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.heading.h4.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h4.fontWeight};
  line-height: 32px;
  text-align: center;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 20px;
`;

const AccountCreationConfirmationFirstSection = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.body.main.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.body.main.fontWeight};
  line-height: 24px;
  color: ${(props) => props.theme.colors.black.lighter};
  text-align: center;
  margin-top: 27px;
  white-space: pre-wrap;
`;

const AccountCreationConfirmationBtnContainer = styled.div`
  margin: 74px 0;
`;
