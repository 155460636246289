import { AccountEmailSentConfirmationBody as AccountEmailSentConfirmationComponentBody } from './AccountEmailSentConfirmation.component';
import { AccountEmailSentConfirmationFooter as AccountEmailSentConfirmationComponentFooter } from './AccountEmailSentConfirmation.component';

export type BodyProps = {
  email: string;
};

export type FooterProps = {
  resendEmailLink?: () => void;
  cancelSignUp?: () => void;
};

export const AccountEmailSentConfirmationBody = AccountEmailSentConfirmationComponentBody;
export const AccountEmailSentConfirmationFooter = AccountEmailSentConfirmationComponentFooter;
