import React, { Component } from 'react';
import styled from 'styled-components';
// import { FieldProps, FormikProps } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import { ReactComponent as IconSearch } from '../../../theme/icons/IconSearch.svg';
import { ThemeComponents } from '../../../theme/components';
import { Props } from './SearchBoxForm.container';

export class SearchBoxForm extends Component<Props> {
  public render() {
    const { form, label, sublabel, placeholder, field, required } = this.props;
    const error = form.errors[field.name];
    const touched = form.touched[field.name];
    return (
      <SearchBoxContainer fluid>
        <SearchBoxRow noGutters>
          <Col xs={12} sm={4}>
            <SearchBoxLabelContainer>
              <SearchBoxLabel>{label}</SearchBoxLabel>
              <SearchBoxSubLabel>{sublabel}</SearchBoxSubLabel>
            </SearchBoxLabelContainer>
          </Col>
          <Col xs={12} sm={8}>
            <SearchBox
              {...field}
              placeholder={placeholder && required ? `${placeholder + ' *'}` : placeholder}
              error={error}
              touched={touched}
            ></SearchBox>
            <SearchBoxIcon />
            {touched && error && <SearchBoxError>{error}</SearchBoxError>}
          </Col>
        </SearchBoxRow>
      </SearchBoxContainer>
    );
  }
}

const SearchBoxContainer = styled(Container)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 16px 0;
  padding: 0;
`;

const SearchBoxRow = styled(Row)`
  width: 100%;
`;

const SearchBox = styled(ThemeComponents.FormInput)<{ error: string; touched: boolean }>`
  border-color: ${(props) => {
    const colorType =
      !!props.error && props.touched
        ? props.theme.colors.red.dark
        : props.theme.colors.white.darker;
    return colorType;
  }};
`;

const SearchBoxLabelContainer = styled.div`
  margin: 5px 15px 5px 0;
`;
const SearchBoxLabel = styled(ThemeComponents.FormLabel)``;

const SearchBoxSubLabel = styled(ThemeComponents.FormSubLabel)``;

const SearchBoxError = styled(ThemeComponents.FormErrorLabel)``;

const SearchBoxIcon = styled(IconSearch)`
  position: absolute;
  top: 10px;
  right: 15px;
`;
