import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './CallInfoDetailsBox.container';

interface State {}

export class CallInfoDetailsBox extends Component<Props, State> {
  public state: State = {};

  public render() {
    const { title, data } = this.props;
    return (
      <Container>
        <HeaderContainer>
          <Title>{title}</Title>
        </HeaderContainer>
        <BodyContainer>
          {data.map((item, i) => (
            <RowContainer key={i}>
              <Label>{item.label}</Label>
              <Value>{item.value}</Value>
            </RowContainer>
          ))}
        </BodyContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const HeaderContainer = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.colors.grey.dark};
  padding: 8px 0;
  width: 100%;
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h6.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h6.fontSize};
  color: ${(props) => props.theme.colors.primary.mainColor};
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  width: 100%;
`;
const Label = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.body.small.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.body.small.fontSize};
  color: ${(props) => props.theme.colors.grey.dark};
  width: 40%;
  white-space: nowrap;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Value = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.lead.main.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.lead.main.fontSize};
  width: 60%;
  white-space: nowrap;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
