import React, { Component } from 'react';
import { Props } from './OrganisationConsultationPrices.container';
import styled from 'styled-components';
import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { MasterBody } from '../../../components/Layout/MasterBody';
import { MasterContainer } from '../../../components/Layout/MasterContainer';
import { i18n } from '../../../lib/i18n';
import { Formik, Field, Form } from 'formik';
import { organisationConsultationPricesValidationSchema } from './OrganisationConsultationPrices.validation';
import { Company } from '../../../types/DbInterface';
import { appContext } from '../../../context/appContext';
import { loader } from 'graphql.macro';
import CatIcon from '../../../theme/images/Cat.png';
import DogIcon from '../../../theme/images/Dog.png';
import UnusualIcon from '../../../theme/images/Unusual.png';
import HorseIcon from '../../../theme/images/Horse.png';
import { SimpleCheckForm, TextInputForm } from '../../../components/Form';
import './OrganisationConsultationPrices.styles.css'
import { th } from 'date-fns/locale';
import { ReactComponent as IconCheckboxTickGreen16x16 } from '../../../theme/icons/Icon-CheckboxTickGreen-16x16.svg';
import { ReactComponent as IconWarning16x16 } from '../../../theme/icons/Icon-Warning-16x16.svg';

const getFareByCompany = loader('../../../graphql/getFareByCompany.graphql');
const updateFaresPerCompany = loader('../../../graphql/updateFaresPerCompany.graphql');
const updateCompanyFares = loader('../../../graphql/updateCompanyFares.graphql');

export interface State {
  __test__companyName: string;
  currentCompany?: Company;
  idCompany: String;
  currentFares: Array<any>;
  servicesList: Array<String>;
  loadedFares: boolean; 
}

export class OrganisationConsultationPrices extends Component<Props, State> {
  public state: State = {
    __test__companyName: 'Ventura Corp',
    idCompany: '',
    currentFares: [],
    servicesList: [],
    loadedFares: false,
  };

  private formikRef: any;
  private companyHoursFormikRef: any;
  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public async componentDidMount() {
    const contextValue = this.context;
    const currentCompany = contextValue.currentCompany;
    if (!currentCompany) {
      return;
    }
    this.setState({ currentCompany });
    try {
      const response = await this.props.client.query({
        query: getFareByCompany,
        fetchPolicy: 'network-only',
      });
      await this.setState({ currentFares: response.data.getTareByCompany.fare, servicesList: response.data.getTareByCompany.services });
      this.companyHoursFormikRef.setFieldValue('nightFare', response.data.getTareByCompany.company.nightFare);
      this.companyHoursFormikRef.setFieldValue('eveningFare', response.data.getTareByCompany.company.eveningFare);
      this.companyHoursFormikRef.setFieldValue('weekendFare', response.data.getTareByCompany.company.weekendFare);

      if(response.data.getTareByCompany.fare.length > 0) {
        response.data.getTareByCompany.fare.forEach((fare: any) => {
          let current_fare = `${fare.petCategory}_${fare.service}`;
          this.formikRef.setFieldValue(current_fare, fare.value);
        })
      }
      this.setState({loadedFares: true});
    } catch (error) {
      console.log(error);
    }
  }

  private handleApplyAll = (type: string) => {
    let generalValue;
    switch (type) {
      case 'cat': {
        generalValue = this.formikRef.state.values.cat_GeneralPractitioner;
        break;
      }
      case 'dog': {
        generalValue = this.formikRef.state.values.dog_GeneralPractitioner;
        break;
      }
      case 'unusual': {
        generalValue = this.formikRef.state.values.unusual_GeneralPractitioner;
        break;
      }
      case 'horse': {
        generalValue = this.formikRef.state.values.horse_GeneralPractitioner;
        break;
      }
      default:
        break;
    }
    for (let index = 0; index < this.state.servicesList.length; index++) {
      this.formikRef.setFieldValue(type + '_' + this.state.servicesList[index], generalValue);
    }
  }

  private handleSubmitData = async (values: any) => {
    const now = Date.now();
    let fares: Array<any> = [];
    const user = this.context.currentAccount;
    const role = this.context.getIsCompanyAdminByAccountId(user?.id);

    for (const key in values) {
      let key_name = key.split('_');
      let fare_id = '';
      this.state.currentFares.forEach((fare) => {
        if (fare.petCategory === key_name[0] && fare.service === key_name[1]) {
          fare_id = fare.id;
        }
      });
      
      let obj = {
        id: fare_id !== '' ? fare_id : null,
        petCategory: key_name[0],
        service: key_name[1],
        applySince: now,
        value: values[key],
        company: this.state.currentCompany?.id,
      }
      fares.push(obj);
    }

    try {
      const response = await this.props.client.mutate({
        mutation: updateFaresPerCompany,
        variables: {
          fares
        },
      });
      this.props.history.goBack();
    } catch (error) {
      console.log('error ', error)
    }

  }

  private handleSubmitHoursFares = async (values: any) => {
    try {
      const response = await this.props.client.mutate({
        mutation: updateCompanyFares,
        variables: {
          eveningFare: values.eveningFare,
          nightFare: values.nightFare,
          weekendFare: values.weekendFare,
        },
      });
      this.props.history.goBack();
    } catch (error) {
      console.log('error ', error)

    }
  }

  services = [
    'GeneralPractitioner',
    'Behaviourist',
    'Osteopath',
    'Ophthalmologist',
    'Dentist',
    'Nutritionist',
    'Nursery',
    'Ethologist',
    'Parasitologist',
    'Dermatologist',
    'Gastrologue',
    'Virologist',
    'Urologist',
    'Traumatologist',
    'Orthopedist',
    'Surgeon'
  ];

  pets = [
    'cat',
    'dog',
    'unusual',
    'horse'
  ];


  initialValues = {
    'cat_GeneralPractitioner': Number((0).toFixed(2)),
    'cat_Behaviourist': Number((0).toFixed(2)),
    'cat_Osteopath': Number((0).toFixed(2)),
    'cat_Ophthalmologist': Number((0).toFixed(2)),
    'cat_Dentist': Number((0).toFixed(2)),
    'cat_Nutritionist': Number((0).toFixed(2)),
    'cat_Nursery': Number((0).toFixed(2)),
    'cat_Ethologist': Number((0).toFixed(2)),
    'cat_Parasitologist': Number((0).toFixed(2)),
    'cat_Dermatologist': Number((0).toFixed(2)),
    'cat_Gastrologue': Number((0).toFixed(2)),
    'cat_Virologist': Number((0).toFixed(2)),
    'cat_Urologist': Number((0).toFixed(2)),
    'cat_Traumatologist': Number((0).toFixed(2)),
    'cat_Orthopedist': Number((0).toFixed(2)),
    'cat_Surgeon': Number((0).toFixed(2)),
    'dog_GeneralPractitioner': Number((0).toFixed(2)),
    'dog_Behaviourist': Number((0).toFixed(2)),
    'dog_Osteopath': Number((0).toFixed(2)),
    'dog_Ophthalmologist': Number((0).toFixed(2)),
    'dog_Dentist': Number((0).toFixed(2)),
    'dog_Nutritionist': Number((0).toFixed(2)),
    'dog_Nursery': Number((0).toFixed(2)),
    'dog_Ethologist': Number((0).toFixed(2)),
    'dog_Parasitologist': Number((0).toFixed(2)),
    'dog_Dermatologist': Number((0).toFixed(2)),
    'dog_Gastrologue': Number((0).toFixed(2)),
    'dog_Virologist': Number((0).toFixed(2)),
    'dog_Urologist': Number((0).toFixed(2)),
    'dog_Traumatologist': Number((0).toFixed(2)),
    'dog_Orthopedist': Number((0).toFixed(2)),
    'dog_Surgeon': Number((0).toFixed(2)),
    'unusual_GeneralPractitioner': Number((0).toFixed(2)),
    'unusual_Behaviourist': Number((0).toFixed(2)),
    'unusual_Osteopath': Number((0).toFixed(2)),
    'unusual_Ophthalmologist': Number((0).toFixed(2)),
    'unusual_Dentist': Number((0).toFixed(2)),
    'unusual_Nutritionist': Number((0).toFixed(2)),
    'unusual_Nursery': Number((0).toFixed(2)),
    'unusual_Ethologist': Number((0).toFixed(2)),
    'unusual_Parasitologist': Number((0).toFixed(2)),
    'unusual_Dermatologist': Number((0).toFixed(2)),
    'unusual_Gastrologue': Number((0).toFixed(2)),
    'unusual_Virologist': Number((0).toFixed(2)),
    'unusual_Urologist': Number((0).toFixed(2)),
    'unusual_Traumatologist': Number((0).toFixed(2)),
    'unusual_Orthopedist': Number((0).toFixed(2)),
    'unusual_Surgeon': Number((0).toFixed(2)),
    'horse_GeneralPractitioner': Number((0).toFixed(2)),
    'horse_Behaviourist': Number((0).toFixed(2)),
    'horse_Osteopath': Number((0).toFixed(2)),
    'horse_Ophthalmologist': Number((0).toFixed(2)),
    'horse_Dentist': Number((0).toFixed(2)),
    'horse_Nutritionist': Number((0).toFixed(2)),
    'horse_Nursery': Number((0).toFixed(2)),
    'horse_Ethologist': Number((0).toFixed(2)),
    'horse_Parasitologist': Number((0).toFixed(2)),
    'horse_Dermatologist': Number((0).toFixed(2)),
    'horse_Gastrologue': Number((0).toFixed(2)),
    'horse_Virologist': Number((0).toFixed(2)),
    'horse_Urologist': Number((0).toFixed(2)),
    'horse_Traumatologist': Number((0).toFixed(2)),
    'horse_Orthopedist': Number((0).toFixed(2)),
    'horse_Surgeon': Number((0).toFixed(2))
  };

  public handleFieldStatus = (field: any) => {
    if(field.value === 0) {
      const mainString = field.name;
      const substring = "horse";
      const regex = new RegExp(substring, "g");
      const matches = mainString.match(regex);
      if(matches) {
        this.formikRef.setFieldValue(field.name, 38);
      } else {
        this.formikRef.setFieldValue(field.name, 28);
      }
    } 
    if(this.state.currentFares.length > 0) {
      return <IconCheckboxTickGreen16x16 />
    } else {
      return <div><IconWarning16x16 /><p className='organisation-consultation-prices__tooltip-warning'>{i18n.t('organisationConsultationPrices.defaultPricesTooltip')}</p></div>
    } 
  }

  public inputDisable = (id: String, name: String, className: String, type: String, disable: boolean) => {
    if (disable) {
      return <Field
        id={id}
        name={name}
        className={className}
        type={type}
        disabled
      />
    } else {
      return (
        <React.Fragment>
          <Field
            id={id}
            name={name}
            className={className}
            type={type}
          >
            {({ field }: { field: { value: any } }) => (
              <InputFieldContainer>
                <input
                  {...field}
                  type="number"
                  className="organisation-consultation-prices__table__input-field"
                />
                <span className='organisation-consultation-prices__warning'>{ this.handleFieldStatus(field) }</span>
              </InputFieldContainer>
            )}
          </Field>
        </React.Fragment>
      )
    }
  }

  public inputs = (role: boolean) => {
    var inputs = [];
    for (let index = 0; index < this.services.length; index++) {
      inputs.push(<div className='organisation-consultation-prices__table-body__row' key={index}>
        <div className='organisation-consultation-prices__table-body__col type-col'>{i18n.t('videoCallHandler.videoCallRequest.petTrouble.' + this.services[index])}</div>
        <div className='organisation-consultation-prices__table-body__col'>
          <div className='organisation-consultation-prices__table-body__col-container'>
            {this.inputDisable('cat_ophtalmo1', 'cat_' + this.services[index], 'organisation-consultation-prices__table__input-field', 'number', !this.state.servicesList.includes(this.services[index]) || !role)}
            <span>&euro;</span>
          </div>
        </div>
        <div className='organisation-consultation-prices__table-body__col'>
          <div className='organisation-consultation-prices__table-body__col-container'>
            {this.inputDisable('dog_ophtalmo1', 'dog_' + this.services[index], 'organisation-consultation-prices__table__input-field', 'number', !this.state.servicesList.includes(this.services[index]) || !role)}
            <span>&euro;</span>
          </div>
        </div>
        <div className='organisation-consultation-prices__table-body__col'>
          <div className='organisation-consultation-prices__table-body__col-container'>
            {this.inputDisable('unusual_ophtalmo1', 'unusual_' + this.services[index], 'organisation-consultation-prices__table__input-field', 'number', !this.state.servicesList.includes(this.services[index]) || !role)}
            <span>&euro;</span>
          </div>
        </div>
        <div className='organisation-consultation-prices__table-body__col'>
          <div className='organisation-consultation-prices__table-body__col-container'>
            {this.inputDisable('horse_ophtalmo1', 'horse_' + this.services[index], 'organisation-consultation-prices__table__input-field', 'number', !this.state.servicesList.includes(this.services[index]) || !role)}
            <span>&euro;</span>
          </div>
        </div>
      </div>
      );
      if (index == 0) {
        inputs.push(<div className='organisation-consultation-prices__table-body__row apply-all' key={index + 'component'}>
          <div className='organisation-consultation-prices__table-body__col' />
          <div className='organisation-consultation-prices__table-body__col'>
            <div className='organisation-consultation-prices__table-body__apply-all-btn' onClick={() => { this.handleApplyAll('cat') }}>{i18n.t('organisationConsultationPrices.applyAll')}</div>
          </div>
          <div className='organisation-consultation-prices__table-body__col'>
            <div className='organisation-consultation-prices__table-body__apply-all-btn' onClick={() => { this.handleApplyAll('dog') }}>{i18n.t('organisationConsultationPrices.applyAll')}</div>
          </div>
          <div className='organisation-consultation-prices__table-body__col'>
            <div className='organisation-consultation-prices__table-body__apply-all-btn' onClick={() => { this.handleApplyAll('unusual') }}>{i18n.t('organisationConsultationPrices.applyAll')}</div>
          </div>
          <div className='organisation-consultation-prices__table-body__col'>
            <div className='organisation-consultation-prices__table-body__apply-all-btn' onClick={() => { this.handleApplyAll('horse') }}>{i18n.t('organisationConsultationPrices.applyAll')}</div>
          </div>
        </div>)
      }
    }
    return inputs;
  };



  render() {
    const company = this.context.currentCompany;
    const user = this.context.currentAccount;
    const role = this.context.getIsCompanyAdminByAccountId(user?.id);
    return (
      <>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <MasterContainer noSideMenu={true}>
            <Container>
              <HeaderContainer>
                <Path>{i18n.t('organisationConsultationPrices.route')}</Path>
                <Title>{i18n.t('organisationConsultationPrices.title')}</Title>
              </HeaderContainer>
              <SectionContainer>
                {
                  role == true ?
                    <ConsultationPricesVideoTutorialLink>{i18n.t('organisationConsultationPrices.needHelpWatch')} <a href="#" >{i18n.t('organisationConsultationPrices.needHelpLink')}</a> {i18n.t('organisationConsultationPrices.needHelpText')}</ConsultationPricesVideoTutorialLink>
                    :
                    <ConsultationPricesVideoTutorialLink>{i18n.t('organisationConsultationPrices.needHelpNotAdmin')}</ConsultationPricesVideoTutorialLink>
                }
                { this.state.currentFares.length === 0 && this.state.loadedFares &&
                  <ConsultationPricesWarningLabel><IconWarning16x16 className='organisation-consultation-prices__icon-warning'/>{i18n.t('organisationConsultationPrices.defaultPricesWarning')}</ConsultationPricesWarningLabel>
                }
                <h4>{i18n.t('organisationConsultationPrices.tableTitle')}</h4>
                <div className='organisation-consultation-prices__table-container'>
                  <div className='organisation-consultation-prices__table-header'>
                    <div className='organisation-consultation-prices__table-header__col'></div>
                    <div className='organisation-consultation-prices__table-header__col'>
                      <img src={CatIcon} alt='cat-icon' />
                      <div className='organisation-consultation-prices__table-header__category'>{i18n.t('shared.raceItem.cat')}</div>
                    </div>
                    <div className='organisation-consultation-prices__table-header__col'>
                      <img src={DogIcon} alt='dog-icon' />
                      <div className='organisation-consultation-prices__table-header__category'>{i18n.t('shared.raceItem.dog')}</div>
                    </div>
                    <div className='organisation-consultation-prices__table-header__col'>
                      <img src={UnusualIcon} alt='unusual-icon' />
                      <div className='organisation-consultation-prices__table-header__category'>{i18n.t('shared.raceItem.NAC')}</div>
                    </div>
                    <div className='organisation-consultation-prices__table-header__col'>
                      <img src={HorseIcon} alt='horse-icon' />
                      <div className='organisation-consultation-prices__table-header__category'>{i18n.t('shared.raceItem.horse')}</div>
                    </div>
                  </div>
                  <div className='organisation-consultation-prices__table-body'>
                    <Formik
                      ref={(formikComponent) => (this.formikRef = formikComponent)}
                      initialValues={this.initialValues}
                      onSubmit={this.handleSubmitData}
                      validationSchema={organisationConsultationPricesValidationSchema}
                    >
                      {({ values, touched, errors, setFieldValue }) => (
                        <Form>
                          {/* inicio de fila */}
                          {this.inputs(role)}
                          {/* fin de la fila */}
                          {
                            Object.keys(errors).length > 0 ?
                              <ErrorMessage><TextError>{i18n.t('organisationConsultationPrices.errorTextSave')}</TextError></ErrorMessage>
                              :
                              null
                          }
                          <div className='organisation-consultation-prices__table__actions-btns'>
                            <button className='organisation-consultation-prices__table__actions-btns__cancel' type={'button'} onClick={() => this.props.history.goBack()} >{i18n.t('organisationConsultationPrices.cancelBtn')}</button>
                            <button className='organisation-consultation-prices__table__actions-btns__save' type="submit" >{i18n.t('organisationConsultationPrices.saveBtn')}</button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <div className='organisation-consultation-prices__specific-hours'>
                    <h6>{i18n.t('organisationConsultationPrices.hoursTitle')}</h6>
                    <Formik
                      ref={(formikComponent) => (this.companyHoursFormikRef = formikComponent)}
                      initialValues={{
                        eveningFare: false,
                        nightFare: false,
                        weekendFare: false,
                      }}
                      onSubmit={this.handleSubmitHoursFares}
                    >
                      <Form>
                        <Field
                          name={'eveningFare'}
                          component={SimpleCheckForm}
                          placeholderComponent={i18n.t('organisationConsultationPrices.eveningText')}

                        />
                        <Field
                          name={'nightFare'}
                          component={SimpleCheckForm}
                          placeholderComponent={i18n.t('organisationConsultationPrices.nightText')}

                        />
                        <Field
                          name={'weekendFare'}
                          component={SimpleCheckForm}
                          placeholderComponent={i18n.t('organisationConsultationPrices.weekendText')}
                        />
                        <div className='organisation-consultation-prices__table__actions-btns'>
                          <button className='organisation-consultation-prices__table__actions-btns__cancel' type={'button'} onClick={() => this.props.history.goBack()} >{i18n.t('organisationConsultationPrices.cancelBtn')}</button>
                          <button className='organisation-consultation-prices__table__actions-btns__save' type="submit" >{i18n.t('organisationConsultationPrices.saveBtn')}</button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </SectionContainer>
            </Container>
          </MasterContainer>
        </MasterBody>
      </>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.pure};
  padding-left: 10px;
  padding-right: 10px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 100px;
    padding-right: 100px;
  }
`;

const HeaderContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;

const Path = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.body.small.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.body.small.fontSize};
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
  /* word-wrap: break-word; */
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConsultationPricesVideoTutorialLink = styled.div`
  padding: 30px 36px;
  border: 1px solid #3488D9;
  border-radius: 3px;
  background-color: #D5E7F7;
  font-weight: 700;
  margin-bottom: 24px;
`;

const ConsultationPricesWarningLabel = styled.div`
  padding: 30px 36px;
  border: 1px solid #000;
  border-radius: 3px;
  background-color: #ff9901;
  font-weight: 700;
  margin-bottom: 32px;
  text-align: center;
`;

const ErrorMessage = styled.div`
  padding: 10px 16px;
  border: 1px solid #3488D9;
  border-radius: 3px;
  background-color: ${(props) => props.theme.componentObjects.formErrorLabel.color};
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 30px;
`;

const TextError = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.body.small.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.body.small.fontSize};
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  color: #FFFFFF;
`;

const InputFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;