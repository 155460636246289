import React, { Component } from 'react';
import { Props } from './ProfileMedical.container';
import styled from 'styled-components';
import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { MasterBody } from '../../../components/Layout/MasterBody';
import { MasterContainer } from '../../../components/Layout/MasterContainer';
import { HeaderSectionForm } from '../../../components/Form/HeaderSectionForm';
import { i18n } from '../../../lib/i18n';
import { Formik, Field, Form } from 'formik';
import { TextInputForm, DropdownForm, SimpleCheckForm } from '../../../components/Form';
import { profileMedicalValidationSchema } from './ProfileMedical.validation';
import { ButtonsForm } from '../../../components/Form/ButtonsForm';
import { counties } from '../../../constants/counties';
import { loader } from 'graphql.macro';
import { VeterinarySchool } from '../../../types/DbInterface';
import { Loader } from '../../../components/Loader';
import { appContext } from '../../../context/appContext';

import { client } from '../../../graphql/client';
import { Account, Company, Veterinarian } from '../../../types/DbInterface';

const getProfessionalAccountWithRelations = loader('../../../graphql/getProfessionalAccountWithRelations.graphql');

const findAllVerifiedVeterinarySchool = loader(
  '../../../graphql/findAllVerifiedVeterinarySchool.graphql'
);

const updateVeterinarianMedicalProfile = loader(
  '../../../graphql/updateVeterinarianMedicalProfile.graphql'
);

const countiesList = counties.map((e) => {
  return { label: e.name, value: e.name };
});

interface ValuesForm {
  ordinalNumber: string;
  registrationDepartment: string;
  school: string;
  otherSchool?: string;
  diplomaGraduationYear: string;
  diplomaIsValidInFrance: boolean;
}

interface State {
  veterinarySchoolList?: { label: string; value: string }[];
  currentVet?: Veterinarian;
  loading?: boolean;
  isOtherSchool: boolean;
}

export class ProfileMedical extends Component<Props, State> {
  public state: State = {
    veterinarySchoolList: undefined,
    currentVet: undefined,
    loading: true,
    isOtherSchool: false,
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public async componentDidMount() {
    
    try {
      const response = await client.query<{
        getProfessionalAccountWithRelations?: {
          account: Account;
          veterinarian?: Veterinarian;
          company?: Company;
          companyVeterinarians?: Veterinarian[];
        };
      }>({
        query: getProfessionalAccountWithRelations,
        fetchPolicy: 'network-only',
      });
      this.setState({
        currentVet: response.data.getProfessionalAccountWithRelations?.veterinarian, 
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
    this.getVeterinarySchoolList();
  }

  private async getVeterinarySchoolList() {
    try {
      const response = await this.props.client.query({
        query: findAllVerifiedVeterinarySchool,
      });
      let veterinarySchoolList = [
        { label: i18n.t('profileMedical.alfort'), value: i18n.t('profileMedical.alfort')},
        { label: i18n.t('profileMedical.toulouse'), value: i18n.t('profileMedical.toulouse')},
        { label: i18n.t('profileMedical.atlantique'), value: i18n.t('profileMedical.atlantique')},
        { label: i18n.t('profileMedical.lyon'), value: i18n.t('profileMedical.lyon')},
        { label: i18n.t('profileMedical.rouen'), value: i18n.t('profileMedical.rouen')},
        { label: i18n.t('profileMedical.other'), value: i18n.t('profileMedical.other')},
      ];
      const veterinarySchools: VeterinarySchool[] = response.data.findAllVerifiedVeterinarySchool;
      
      if (veterinarySchools) {
        veterinarySchools.forEach((data) => {
          veterinarySchoolList = veterinarySchoolList.concat([
            { label: data.name, value: data.id },
          ]);
        });
        veterinarySchoolList = veterinarySchoolList.reverse();
        if(this.state.currentVet?.veterinarySchool) {
          let verifyOtherSchool = veterinarySchoolList.some(option => option.value === this.state.currentVet?.veterinarySchool?.name)
          if(!verifyOtherSchool) {
            this.setState({isOtherSchool: true});
          }
        }
      }
      // if (veterinarySchoolList) console.log(veterinarySchoolList);
      this.setState({ veterinarySchoolList });
    } catch (error) {
      console.log('error ', error)
      //throw new Error(error);
    }
  }

  private onSubmit = async (values: ValuesForm) => {
    try {
      await this.props.client.mutate({
        mutation: updateVeterinarianMedicalProfile,
        variables: {
          orderNumber: values.ordinalNumber,
          orderCounty: values.registrationDepartment,
          graduationYear: values.diplomaGraduationYear,
          //schoolId: values.school === 'other' ? null : values.school,
          schoolId: null, // This should work different, when findAllVerifiedVeterinarySchool graphql get fixed
          otherSchool: values.school === 'other' ? values.otherSchool : values.school,
          diplomaIsValidInFrance: values.diplomaIsValidInFrance
        },
      });
      this.context.updateContext();
      this.props.history.goBack();
    } catch (error) {
      throw new Error(error);
    }
    /* For otherSchool, Check if  school === "other" and send otherSchool to backend */
  };

  render() {
    const account = this.context.currentAccount;
    const veterinarian = this.state.currentVet;
    if (!this.state.veterinarySchoolList) {
      return <LoaderContainer><Loader inline="centered" active={true} size={'big'} /></LoaderContainer>;
    }  

    if (this.state.loading) {
      return <LoaderContainer><Loader inline="centered" active={true} size={'big'} /></LoaderContainer>;
    }

    return (
      <>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <MasterContainer noSideMenu={true}>
            <Container>
              <HeaderContainer>
                <Subtitle>
                  {i18n.t('profile.veterinarianProfile.profileMedical.subtitle', {
                    firstName: account?.firstName ? account.firstName : '',
                    lastName: account?.lastName ? account.lastName : '',
                  })}
                </Subtitle>
                <Title>{i18n.t('profile.veterinarianProfile.profileMedical.title')}</Title>
              </HeaderContainer>

              <SectionContainer>
                <HeaderSectionForm
                  title={i18n.t('profile.veterinarianProfile.profileMedical.form.title')}
                  subtitle={i18n.t('profile.veterinarianProfile.profileMedical.form.subtitle')}
                />

                <Formik
                  initialValues={{
                    ordinalNumber:
                      veterinarian && veterinarian.orderNumber ? veterinarian.orderNumber : '',
                    registrationDepartment:
                      veterinarian && veterinarian.orderCounty ? veterinarian.orderCounty : '',
                    //CHECK v
                    school:
                      veterinarian                      
                        ? veterinarian.veterinarySchool?.name
                        : '',
                    otherSchool: 
                      veterinarian &&
                      veterinarian.veterinarySchool?.name
                        ? veterinarian.veterinarySchool?.name
                        : '',
                    diplomaGraduationYear:
                      veterinarian && veterinarian.graduationYear
                        ? veterinarian.graduationYear
                        : '',
                    diplomaIsValidInFrance: 
                      veterinarian && veterinarian.diplomaIsValidInFrance
                        ? veterinarian.diplomaIsValidInFrance
                        : false,
                  }}
                  onSubmit={this.onSubmit}
                  validationSchema={profileMedicalValidationSchema}
                >
                  {({ values, setFieldValue }) => (
                    <ProfileMedicalForm>
                      <ProfileMedicalField>
                        <Field
                          name={'ordinalNumber'}
                          label={i18n.t(
                            'profile.veterinarianProfile.profileMedical.form.ordinalNumber.label'
                          )}
                          placeholder={i18n.t(
                            'profile.veterinarianProfile.profileMedical.form.ordinalNumber.placeholder'
                          )}
                          component={TextInputForm}
                        />
                        <Field
                          name={'registrationDepartment'}
                          label={i18n.t(
                            'profile.veterinarianProfile.profileMedical.form.registrationDepartment.label'
                          )}
                          placeholder={i18n.t(
                            'profile.veterinarianProfile.profileMedical.form.registrationDepartment.placeholder'
                          )}
                          component={DropdownForm}
                          data={countiesList}
                          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            // Do the logic needed for each case
                            setFieldValue('registrationDepartment', event.target.value);
                          }}
                          value={veterinarian?.orderCounty ? veterinarian?.orderCounty : ''}
                        />
                        <Field
                          name={'school'}
                          label={i18n.t(
                            'profile.veterinarianProfile.profileMedical.form.school.label'
                          )}
                          placeholder={i18n.t(
                            'profile.veterinarianProfile.profileMedical.form.school.placeholder'
                          )}
                          component={DropdownForm}
                          data={
                            this.state.veterinarySchoolList
                            //[{ label: 'Autre', value: 'other' }]
                          }
                          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            // Do the logic needed for each case
                            setFieldValue('school', event.target.value);
                            this.setState({isOtherSchool: false});
                          }}
                          value={veterinarian?.veterinarySchool ? veterinarian?.veterinarySchool.name : ''}
                        />
                        {(values.school === 'other' || this.state.isOtherSchool) && (
                          <Field
                            name={'otherSchool'}
                            component={TextInputForm}
                            placeholder={i18n.t(
                              'profile.veterinarianProfile.profileMedical.form.otherSchool.placeholder'
                            )}
                          />
                        )}
                        <Field
                          name={'diplomaGraduationYear'}
                          label={i18n.t(
                            'profile.veterinarianProfile.profileMedical.form.diplomaGraduationYear.label'
                          )}
                          placeholder={i18n.t(
                            'profile.veterinarianProfile.profileMedical.form.diplomaGraduationYear.placeholder'
                          )}
                          component={TextInputForm} // TODO could be check to be xxxx format in yup
                        />
                        <Field
                          name={'diplomaIsValidInFrance'}
                          label={i18n.t(
                            'profile.veterinarianProfile.profileMedical.form.diplomaIsValidInFrance.label'
                          )}
                          component={SimpleCheckForm}
                          placeholderComponent={
                            <>
                              {i18n.t(
                                'profile.veterinarianProfile.profileMedical.form.diplomaIsValidInFrance.placeholder'
                              )}
                            </>
                          }
                        />
                      </ProfileMedicalField>
                      <ButtonsForm
                        cancelContent={i18n.t('profile.form.button.cancel')}
                        validateContent={i18n.t('profile.form.button.save')}
                        onCancel={() => this.props.history.goBack()}
                        type={'submit'}
                      />
                    </ProfileMedicalForm>
                  )}
                </Formik>
              </SectionContainer>
            </Container>
          </MasterContainer>
        </MasterBody>
      </>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.pure};
  padding-left: 10px;
  padding-right: 10px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 200px;
    padding-right: 200px;
  }
`;

const HeaderContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;

const Subtitle = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.body.small.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.body.small.fontSize};
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileMedicalForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 35px;
`;

const ProfileMedicalField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 0;
  }
`;

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;
