import React, { Component } from 'react';
import { Props } from './AnnualCallsView.container';
import styled from 'styled-components';
import { CustomFlatList } from '../../../components/CustomFlatList';
import { HeaderSectionForm } from '../../../components/Form/HeaderSectionForm';
import { i18n } from '../../../lib/i18n';
import { loader } from 'graphql.macro';
import { VideoCallListItem } from '../VideoCalls.container';
import { ReactComponent as IconChevronRight24x24 } from '../../../theme/icons/Icon-ChevronRight-24x24.svg';
import { Loader } from '../../../components/Loader';

const getAccountVideoCallByYear = loader('../../../graphql/getAccountVideoCallByYear.graphql');

interface LocationState {
  monthSelected?: string;
}

const months = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

interface State {
  annualVideoCalls: Array<{ month: string; monthlyVideoCalls: VideoCallListItem[] }>;
  monthSelected: string | undefined;
  isLoading: boolean;
}

export class AnnualCallsView extends Component<Props, State> {
  public state: State = {
    annualVideoCalls: [],
    monthSelected: undefined,
    isLoading: true,
  };

  public async componentDidMount() {
    // we didn't exist before 2019, we don't want to check before this date
    if (parseInt(this.props.year) < 2019) {
      this.props.history.push('/video-calls');
      return;
    }
    try {
      const response = await this.props.client.query({
        query: getAccountVideoCallByYear,
        variables: {
          year: parseInt(this.props.year),
        },
        fetchPolicy: 'network-only',
      });
      const annualVideoCalls = response.data.getAccountVideoCallByYear.annualVideoCalls;
      if (annualVideoCalls) {
        this.setState({
          annualVideoCalls: annualVideoCalls,
          isLoading: false,
        });
      }

      this.setState({
        monthSelected: (this.props.location.state as LocationState)?.monthSelected,
      });
    } catch (error) {
      console.log(error);
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        monthSelected: (this.props.location.state as LocationState)?.monthSelected,
      });
    }
  }

  public render() {
    const { annualVideoCalls, monthSelected, isLoading } = this.state;

    if (isLoading) {
      return (
        <LoaderContainer>
          <Loader inline="centered" active={true} size={'big'} />;
        </LoaderContainer>
      );
    }

    if (monthSelected !== undefined) {
      const monthlyVideoCalls = annualVideoCalls[parseInt(monthSelected)].monthlyVideoCalls;
      return (
        <>
          <HeaderContainer>
            <Subtitle>{i18n.t('videoCalls.annualCallsView.subtitle')}</Subtitle>
            <Title>{`${months[parseInt(monthSelected)]} ${this.props.year}`}</Title>
          </HeaderContainer>

          <SectionContainer>
            <MonthCallsContainer>
              <HeaderSectionForm
                title={i18n.t('videoCalls.annualCallsView.monthlyCallsListTitle')}
              />
              {monthlyVideoCalls.length > 0 && (
                <CustomFlatListContainer>
                  <CustomFlatList data={this.props.getCallsListFormated(monthlyVideoCalls)} />
                </CustomFlatListContainer>
              )}
              {monthlyVideoCalls.length === 0 && (
                <NoCallContainer>Pas d’appels reçus ce mois ci</NoCallContainer>
              )}
            </MonthCallsContainer>
          </SectionContainer>
        </>
      );
    } else {
      return (
        <>
          <HeaderContainer>
            <Subtitle>{i18n.t('videoCalls.annualCallsView.subtitle')}</Subtitle>
            <Title>{i18n.t('videoCalls.annualCallsView.title', { year: this.props.year })}</Title>
          </HeaderContainer>

          <SectionContainer>
            <YearCallsContainer>
              <HeaderSectionForm
                title={i18n.t('videoCalls.annualCallsView.annualCallsListTitle')}
              />
              <CustomFlatListContainer>
                <CustomFlatList
                  data={annualVideoCalls.map((e, i) => {
                    const callsNumber = e.monthlyVideoCalls.length;
                    return {
                      leftLabel: months[i],
                      rightLabel: `${callsNumber} Appel${callsNumber > 0 ? 's' : ''}`,
                      rightIcon: IconChevronRight24x24,
                      onClick: () =>
                        this.props.history.push({
                          pathname: '/video-calls',
                          search: `year=${this.props.year}`,
                          state: {
                            monthSelected: e.month,
                          },
                        }),
                    };
                  })}
                />
              </CustomFlatListContainer>
            </YearCallsContainer>
          </SectionContainer>
        </>
      );
    }
  }
}

const HeaderContainer = styled.div`
  padding: 50px 20px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 50px 10px;
  }
`;

const Subtitle = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontSize};
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: ${(props) =>
    props.theme.textStyles.typography.lead.capitalize.regular.letterSpacing};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.heading.h1.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
`;

const SectionContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 30px 10px;
  }
`;

const YearCallsContainer = styled.div``;

const CustomFlatListContainer = styled.div`
  margin-top: 20px;
`;

const MonthCallsContainer = styled.div`
  margin: 30px 0;
`;

const NoCallContainer = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.lead.small.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.small.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.lead.small.fontSize};
  color: ${(props) => props.theme.colors.grey.darker};
  text-align: center;
  margin: 30px 0;
`;

const LoaderContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
