import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../../lib/withApolloClient';
import { ProfileSkills as ProfileSkillsComponent } from './ProfileSkills.component';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
import { withAppContext } from '../../../lib/withContext';

export type Props = WithApolloClient<RouteComponentProps>;

export const ProfileSkills = compose<Props, RouteComponentProps>(
  withApollo,
  withAppContext
)(ProfileSkillsComponent);
