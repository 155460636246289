import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { loader } from 'graphql.macro';
import { client } from '../../../graphql/client';
import { Props } from './VeterinarianModeration.container';
import styled from 'styled-components';
import { Veterinarian } from '../../../types/DbInterface';
import { Formik, Field, Form } from 'formik';
import {
  SimpleCheckForm,
  LongTextInputForm,
  CheckBoxForm,
  TextInputForm,
} from '../../../components/Form';
import { ButtonsForm } from '../../../components/Form/ButtonsForm';
import { i18n } from '../../../lib/i18n';
import { Loader } from '../../../components/Loader';
import { ThemeComponents } from '../../../theme/components';

const findAllVeterinarians = loader('../../../graphql/findAllVeterinarians.graphql');
const validateVeterinarianModerationStatus = loader(
  '../../../graphql/validateVeterinarianModerationStatus.graphql'
);

interface ValuesForm {
  id: string;
  identity: {
    birthDate: boolean;
    idCardFront: boolean;
    idCardBack: boolean;
  };
  medicalProfile: {
    orderNumber: boolean;
    orderCounty: boolean;
    graduationYear: boolean;
    school: boolean;
  };
  animalsInCare: {
    petCategories: boolean;
    petSpecies: boolean;
  };
  medicalExpertiseAreas: boolean;
  insurance: {
    insurerName: boolean;
    insurerContractNumber: boolean;
    insurerClientNumber: boolean;
  };
  message: string;
}

interface State {
  veterinarianVerifiedModerationList: Veterinarian[];
  veterinarianNotCompletedModerationList: Veterinarian[];
  veterinarianPendingModerationList: Veterinarian[];
  selectedVeterinarian?: Veterinarian;
  loading: boolean;
  identityCheckbox: boolean;
  medicalProfileCheckbox: boolean;
  medicalExpertiseAreasCheckbox: boolean;
  insuranceCheckbox: boolean;
  animalsInCareCheckbox: boolean;
  showVeterinarianPendingList: boolean;
  showVeterinarianVerifiedList: boolean;
  showVeterinarianNotCompletedList: boolean;
}

export class VeterinarianModeration extends Component<Props, State> {
  public state: State = {
    veterinarianVerifiedModerationList: [],
    veterinarianNotCompletedModerationList: [],
    veterinarianPendingModerationList: [],
    selectedVeterinarian: undefined,
    loading: false,
    identityCheckbox: false,
    medicalProfileCheckbox: false,
    medicalExpertiseAreasCheckbox: false,
    insuranceCheckbox: false,
    animalsInCareCheckbox: false,
    showVeterinarianPendingList: true,
    showVeterinarianVerifiedList: true,
    showVeterinarianNotCompletedList: true,
  };

  private formikRef: any;

  async componentDidMount() {
    await this.refreshVeterinarianModerationList();
  }

  private storeVeterinarian = (veterinarian: Veterinarian) => {
    const newVeterinarianPendingModerationList = this.state.veterinarianPendingModerationList;
    const newVeterinarianVerifiedModerationList = this.state.veterinarianVerifiedModerationList;
    const newVeterinarianNotCompletedModerationList = this.state
      .veterinarianNotCompletedModerationList;
    switch (veterinarian.moderationStatus) {
      case 'PENDING_VERIFICATION':
        newVeterinarianPendingModerationList.push(veterinarian);
        this.setState({ veterinarianPendingModerationList: newVeterinarianPendingModerationList });
        break;
      case 'VERIFIED':
        newVeterinarianVerifiedModerationList.push(veterinarian);
        this.setState({
          veterinarianVerifiedModerationList: newVeterinarianVerifiedModerationList,
        });
        break;
      case 'NOT_COMPLETED':
        newVeterinarianNotCompletedModerationList.push(veterinarian);
        this.setState({
          veterinarianNotCompletedModerationList: newVeterinarianNotCompletedModerationList,
        });
        break;
    }
  };

  public refreshVeterinarianModerationList = async () => {
    let result;
    try {
      result = await client.query({
        query: findAllVeterinarians,
        fetchPolicy: 'network-only',
      });
      if (result && result.data && result.data.findAllVeterinarians)
        result.data.findAllVeterinarians.forEach((element: Veterinarian) =>
          this.storeVeterinarian(element)
        );
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  public renderVeterinarianRowItem(data: Veterinarian, i: number) {
    return (
      <ConfigContainer key={data.id}>
        <ItemLeftLabel>{data.account.firstName + ' ' + data.account.lastName}</ItemLeftLabel>
        <button onClick={() => this.setState({ selectedVeterinarian: data })}>Go To</button>
      </ConfigContainer>
    );
  }
  private onSubmit = async (values: ValuesForm) => {
    try {
      const response = await this.props.client.mutate({
        mutation: validateVeterinarianModerationStatus,
        variables: { veterinarianModerationInput: values },
      });
      if (response.data) {
        this.setState({
          loading: false,
          selectedVeterinarian: undefined,
          veterinarianVerifiedModerationList: [],
          veterinarianNotCompletedModerationList: [],
          veterinarianPendingModerationList: [],
        });
        this.refreshVeterinarianModerationList();
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  private onChangeIdentityCheckBox() {
    const value = !this.state.identityCheckbox;
    this.setState({ identityCheckbox: value });
    this.formikRef.setFieldValue('identity.birthDate', value);
    this.formikRef.setFieldValue('identity.idCardFront', value);
    this.formikRef.setFieldValue('identity.idCardBack', value);
  }

  private onChangeAnimalsInCareCheckbox() {
    const value = !this.state.animalsInCareCheckbox;
    this.setState({ animalsInCareCheckbox: value });
    this.formikRef.setFieldValue('animalsInCare.petCategories', value);
    this.formikRef.setFieldValue('animalsInCare.petSpecies', value);
  }

  private onChangeInsuranceCheckbox() {
    const value = !this.state.insuranceCheckbox;
    this.setState({ insuranceCheckbox: value });
    this.formikRef.setFieldValue('insurance.insurerName', value);
    this.formikRef.setFieldValue('insurance.insurerContractNumber', value);
    this.formikRef.setFieldValue('insurance.insurerClientNumber', value);
  }

  private onChangeMedicalExpertiseAreasCheckBox() {
    const value = !this.state.medicalExpertiseAreasCheckbox;
    this.setState({ medicalExpertiseAreasCheckbox: value });
    this.formikRef.setFieldValue('medicalExpertiseAreas', value);
  }

  private onChangeMedicalProfileCheckbox() {
    const value = !this.state.medicalProfileCheckbox;
    this.setState({ medicalProfileCheckbox: value });
    this.formikRef.setFieldValue('medicalProfile.orderNumber', value);
    this.formikRef.setFieldValue('medicalProfile.orderCounty', value);
    this.formikRef.setFieldValue('medicalProfile.school', value);
    this.formikRef.setFieldValue('medicalProfile.graduationYear', value);
  }

  public renderVeterinarian(veterinarian: Veterinarian) {
    return (
      <>
        <button onClick={() => this.setState({ selectedVeterinarian: undefined })}>Go back</button>
        <Formik
          ref={(formikComponent) => (this.formikRef = formikComponent)}
          initialValues={{
            id: veterinarian.id,
            identity: {
              birthDate: false,
              idCardFront: false,
              idCardBack: false,
            },
            medicalProfile: {
              orderNumber: false,
              orderCounty: false,
              graduationYear: false,
              school: false,
            },
            animalsInCare: {
              petCategories: false,
              petSpecies: false,
            },
            medicalExpertiseAreas: false,
            insurance: {
              insurerName: false,
              insurerContractNumber: false,
              insurerClientNumber: false,
            },
            message: '',
          }}
          onSubmit={this.onSubmit}
          // validationSchema={profileSkillsValidationSchema}
        >
          {(values) => (
            <ModerationForm>
              <CategoryContainer>
                <SimpleCheck onClick={() => this.onChangeIdentityCheckBox()}>
                  <SimpleCheckInput
                    checked={this.state.identityCheckbox}
                    onChange={() => this.onChangeIdentityCheckBox()}
                    // label={placeholder && required ? `${placeholder + ' *'}` : placeholder}
                    // labelComponent={placeholderComponent}
                    width={24}
                    height={24}
                  />
                </SimpleCheck>

                <CategoryTitle>{'Identity'}</CategoryTitle>
              </CategoryContainer>
              <ModerationField>
                <Field
                  label={'id'}
                  disabled
                  name={'id'}
                  component={TextInputForm}
                  // data={}
                  placeholder={'id'}
                />{' '}
                <Field
                  label={i18n.t('profile.veterinarianProfile.proofOfIdentity.form.birthDate.label')}
                  name={'identity.birthDate'}
                  component={SimpleCheckForm}
                  // data={}
                  placeholderComponent={
                    <Value>{new Date(veterinarian.birthDate).toLocaleDateString()}</Value>
                  }
                />
                <Field
                  label={i18n.t(
                    'profile.veterinarianProfile.proofOfIdentity.form.idCardFront.label'
                  )}
                  name={'identity.idCardFront'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={<Image src={veterinarian.idCardFront?.url} />}
                />
                <Field
                  label={i18n.t(
                    'profile.veterinarianProfile.proofOfIdentity.form.idCardBack.label'
                  )}
                  name={'identity.idCardBack'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={<Image src={veterinarian.idCardBack?.url} />}
                />
              </ModerationField>
              <CategoryContainer>
                <SimpleCheck onClick={() => this.onChangeMedicalProfileCheckbox()}>
                  <SimpleCheckInput
                    checked={this.state.medicalProfileCheckbox}
                    onChange={() => this.onChangeMedicalProfileCheckbox()}
                    // label={placeholder && required ? `${placeholder + ' *'}` : placeholder}
                    // labelComponent={placeholderComponent}
                    width={24}
                    height={24}
                  />
                </SimpleCheck>
                <CategoryTitle>{'Profile médicale'}</CategoryTitle>
              </CategoryContainer>
              <ModerationField>
                <Field
                  label={i18n.t(
                    'profile.veterinarianProfile.profileMedical.form.ordinalNumber.label'
                  )}
                  name={'medicalProfile.orderNumber'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>{veterinarian.orderNumber ? veterinarian.orderNumber : 'N.C.'}</Value>
                  }
                />
                <Field
                  label={i18n.t(
                    'profile.veterinarianProfile.profileMedical.form.registrationDepartment.label'
                  )}
                  name={'medicalProfile.orderCounty'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>{veterinarian.orderCounty ? veterinarian.orderCounty : 'N.C.'}</Value>
                  }
                />
                <Field
                  label={i18n.t('profile.veterinarianProfile.profileMedical.form.school.label')}
                  name={'medicalProfile.school'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>
                      {veterinarian.veterinarySchool
                        ? veterinarian.veterinarySchool.name +
                          ' [' +
                          veterinarian.veterinarySchool.moderationStatus +
                          ']'
                        : 'N.C.'}
                    </Value>
                  }
                />
                <Field
                  label={i18n.t(
                    'profile.veterinarianProfile.profileMedical.form.diplomaGraduationYear.label'
                  )}
                  name={'medicalProfile.graduationYear'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>
                      {veterinarian.graduationYear ? veterinarian.graduationYear : 'N.C.'}
                    </Value>
                  }
                />
              </ModerationField>
              <CategoryContainer>
                <SimpleCheck onClick={() => this.onChangeAnimalsInCareCheckbox()}>
                  <SimpleCheckInput
                    checked={this.state.animalsInCareCheckbox}
                    onChange={() => this.onChangeAnimalsInCareCheckbox()}
                    // label={placeholder && required ? `${placeholder + ' *'}` : placeholder}
                    // labelComponent={placeholderComponent}
                    width={24}
                    height={24}
                  />
                </SimpleCheck>
                <CategoryTitle>{'Animaux pris en charge'}</CategoryTitle>
              </CategoryContainer>
              <ModerationField>
                <Field
                  label={'petCategories'}
                  name={'animalsInCare.petCategories'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <>
                      {veterinarian.petCategories?.map((data) => {
                        return <Value key={data}>{data}</Value>;
                      })}
                    </>
                  }
                />
                <Field
                  label={'petSpecies'}
                  name={'animalsInCare.petSpecies'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <>
                      {veterinarian.petSpecies ? (
                        veterinarian.petSpecies?.map((data) => {
                          return <Value key={data}>{data}</Value>;
                        })
                      ) : (
                        <Value>{'N.C.'}</Value>
                      )}
                    </>
                  }
                />
              </ModerationField>
              <CategoryContainer>
                <SimpleCheck onClick={() => this.onChangeMedicalExpertiseAreasCheckBox()}>
                  <SimpleCheckInput
                    checked={this.state.medicalExpertiseAreasCheckbox}
                    onChange={() => this.onChangeMedicalExpertiseAreasCheckBox()}
                    // label={placeholder && required ? `${placeholder + ' *'}` : placeholder}
                    // labelComponent={placeholderComponent}
                    width={24}
                    height={24}
                  />
                </SimpleCheck>
                <CategoryTitle>{'compétences medicales'}</CategoryTitle>
              </CategoryContainer>
              <ModerationField>
                <Field
                  label={'medicalExpertiseAreas'}
                  name={'medicalExpertiseAreas'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <>
                      {veterinarian.medicalExpertiseAreas?.map((data) => {
                        return <Value key={data}>{data}</Value>;
                      })}
                    </>
                  }
                />
              </ModerationField>
              <CategoryContainer>
                <SimpleCheck onClick={() => this.onChangeInsuranceCheckbox()}>
                  <SimpleCheckInput
                    checked={this.state.insuranceCheckbox}
                    onChange={() => this.onChangeInsuranceCheckbox()}
                    // label={placeholder && required ? `${placeholder + ' *'}` : placeholder}
                    // labelComponent={placeholderComponent}
                    width={24}
                    height={24}
                  />
                </SimpleCheck>
                <CategoryTitle>{'Assurance'}</CategoryTitle>
              </CategoryContainer>

              <ModerationField>
                <Field
                  label={'insurerName'}
                  name={'insurance.insurerName'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>{veterinarian.insurerName ? veterinarian.insurerName : 'N.C.'}</Value>
                  }
                />
                <Field
                  label={'insurerContractNumber'}
                  name={'insurance.insurerContractNumber'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>
                      {veterinarian.insurerContractNumber
                        ? veterinarian.insurerContractNumber
                        : 'N.C.'}
                    </Value>
                  }
                />
                <Field
                  label={'insurerClientNumber'}
                  name={'insurance.insurerClientNumber'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>
                      {veterinarian.insurerClientNumber ? veterinarian.insurerClientNumber : 'N.C.'}
                    </Value>
                  }
                />
              </ModerationField>
              <CategoryTitle>{'Message'}</CategoryTitle>
              <ModerationField>
                <Field
                  name={'message'}
                  label={'Message au vétérinaire'}
                  placeholder={'message...'}
                  component={LongTextInputForm}
                  required={true}
                />
              </ModerationField>
              <ButtonsForm
                cancelContent={i18n.t('profile.form.button.cancel')}
                validateContent={i18n.t('profile.form.button.save')}
                onCancel={() => this.setState({ selectedVeterinarian: undefined })}
                type={'submit'}
              />
            </ModerationForm>
          )}
        </Formik>
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader inline="centered" active={true} size={'big'} />;
    return (
      <>
        <Card>
          <CardHeader title="Veterinarian Moderation" />
        </Card>
        {this.state.selectedVeterinarian &&
          this.renderVeterinarian(this.state.selectedVeterinarian)}
        {!this.state.selectedVeterinarian && (
          <>
            <StyledCard>
              <CardHeader
                title={
                  (this.state.showVeterinarianPendingList ? '▾ ' : '▸ ') +
                  `🟡 Veterinarians Pending Verification : ${
                    this.state.veterinarianPendingModerationList &&
                    this.state.veterinarianPendingModerationList.length
                  }`
                }
                onClick={() =>
                  this.setState({
                    showVeterinarianPendingList: !this.state.showVeterinarianPendingList,
                  })
                }
              />
              {this.state.showVeterinarianPendingList &&
                this.state.veterinarianPendingModerationList.map((data, i) =>
                  this.renderVeterinarianRowItem(data, i + 1)
                )}
            </StyledCard>
            <StyledCard>
              <CardHeader
                title={
                  (this.state.showVeterinarianNotCompletedList ? '▾ ' : '▸ ') +
                  `🔴 Veterinarians Not Completed : ${
                    this.state.veterinarianNotCompletedModerationList &&
                    this.state.veterinarianNotCompletedModerationList.length
                  }`
                }
                onClick={() =>
                  this.setState({
                    showVeterinarianNotCompletedList: !this.state.showVeterinarianNotCompletedList,
                  })
                }
              />
              {this.state.showVeterinarianNotCompletedList &&
                this.state.veterinarianNotCompletedModerationList.map((data, i) =>
                  this.renderVeterinarianRowItem(data, i + 1)
                )}
            </StyledCard>
            <StyledCard>
              <CardHeader
                title={
                  (this.state.showVeterinarianVerifiedList ? '▾ ' : '▸ ') +
                  `🟢 Veterinarians Verified : ${
                    this.state.veterinarianVerifiedModerationList &&
                    this.state.veterinarianVerifiedModerationList.length
                  }`
                }
                onClick={() =>
                  this.setState({
                    showVeterinarianVerifiedList: !this.state.showVeterinarianVerifiedList,
                  })
                }
              />
              {this.state.showVeterinarianVerifiedList &&
                this.state.veterinarianVerifiedModerationList.map((data, i) =>
                  this.renderVeterinarianRowItem(data, i + 1)
                )}
            </StyledCard>
          </>
        )}
      </>
    );
  }
}

const StyledCard = styled(Card)`
  && {
    margin-top: 25px;
  }
`;

const SimpleCheck = styled(ThemeComponents.FormSimpleCheck)`
  display: flex;
  flex-direction: row;
  color: ${(props) => props.theme.colors.black.lighter};
  border-color: ${(props) => {
    const colorType =
      !!props.error && props.touched
        ? props.theme.colors.red.dark
        : props.theme.colors.white.darker;
    return colorType;
  }};
  width: 8%;
  font-weight: ${(props) => props.theme.componentObjects.formSimpleCheck.fontWeight};
  font-size: ${(props) => props.theme.componentObjects.formSimpleCheck.fontSize};
  line-height: ${(props) => props.theme.componentObjects.formSimpleCheck.lineHeight};
`;

const SimpleCheckInput = styled(CheckBoxForm)``;

const Image = styled.img`
  height: 50%;
  width: 50%;

  @media screen and (max-width: 479px) {
    max-width: 320px;
  }
`;

const ModerationField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
`;

const ModerationForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  margin-top: 35px;
`;

const Value = styled.div``;

const CategoryContainer = styled.div`
  flex-direction: row;
  display: flex;
`;

const CategoryTitle = styled.div`
  padding-left: 10px;
  font-family: ${(props) => props.theme.textStyles.typography.heading.h1.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 22px;
  color: ${(props) =>
    props.leftLabelColor ? props.leftLabelColor : props.theme.colors.black.light};
  padding-top: 24px;
  padding-bottom: 24px;
`;

const ItemLeftLabel = styled.div<{ leftLabelColor?: string }>`
  font-family: ${(props) => props.theme.textStyles.typography.heading.h6.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h6.fontWeight};
  line-height: 22px;
  color: ${(props) =>
    props.leftLabelColor ? props.leftLabelColor : props.theme.colors.black.light};
  padding-left: 18px;
  width: calc(100% - 400px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ConfigContainer = styled.div`
  flex-direction: row;
  width: 100%;
  height: 60px;
  background-color: ${(props) => props.theme.colors.white.pure};
  border: 1px solid ${(props) => props.theme.colors.white.darker};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 22px;
  cursor: pointer;
`;
