import React, { Component } from 'react';
import { Props } from './ProfileProofOfIdentity.container';
import styled from 'styled-components';
import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { MasterBody } from '../../../components/Layout/MasterBody';
import { MasterContainer } from '../../../components/Layout/MasterContainer';
import { HeaderSectionForm } from '../../../components/Form/HeaderSectionForm';
import { i18n } from '../../../lib/i18n';
import { Formik, Field, Form } from 'formik';
import { DatePickerForm, ImportFileForm } from '../../../components/Form';
import { profileProofOfIdentityValidationSchema } from './ProfileProofOfIdentity.validation';
import { ButtonsForm } from '../../../components/Form/ButtonsForm';
import { loader } from 'graphql.macro';
import { appContext } from '../../../context/appContext';

import { Loader } from '../../../components/Loader';
import { client } from '../../../graphql/client';
import { Account, Company, Veterinarian } from '../../../types/DbInterface';
import { ReactComponent as IconCheckboxTickGreen16x16 } from '../../../theme/icons/Icon-CheckboxTickGreen-16x16.svg';

const getProfessionalAccountWithRelations = loader('../../../graphql/getProfessionalAccountWithRelations.graphql');

const updateVeterinarianIdentity = loader('../../../graphql/updateVeterinarianIdentity.graphql');

interface ValuesForm {
  // nationality: string;
  birthDate: Date | string;
  idCardFront: any;
  idCardBack: any;
}

interface State {
  currentVet?: Veterinarian;
  loading?: boolean;
}

export class ProfileProofOfIdentity extends Component<Props> {
  public state: State = {
    currentVet: undefined,
    loading: true,
  };
  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public async componentDidMount() {
    try {
      const response = await client.query<{
        getProfessionalAccountWithRelations?: {
          account: Account;
          veterinarian?: Veterinarian;
          company?: Company;
          companyVeterinarians?: Veterinarian[];
        };
      }>({
        query: getProfessionalAccountWithRelations,
        fetchPolicy: 'network-only',
      });
      this.setState({
        currentVet: response.data.getProfessionalAccountWithRelations?.veterinarian, 
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  private onSubmit = async (values: ValuesForm) => {
    // console.log(values);

    // console.log('IDCARDFRONT = ', values.idCardFront[0].file);
    const birthDate = values.birthDate;
    // console.log('birthDate = ', birthDate);
    // console.log('localstring date = ', this.formattedDate(birthDate));
    // const file = new ReactNativeFile({
    //   uri: values.idCardFront.file.path,
    //   name: 'tempFileName',
    //   type: values.idCardFront.file.mime,
    // });

    try {
      await this.props.client.mutate({
        mutation: updateVeterinarianIdentity,
        variables: {
          birthDate: birthDate,
          idCardFront: values.idCardFront[0].file,
          idCardBack: values.idCardBack ? values.idCardBack[0].file : null,
        },
      });
      this.context.updateContext();
      this.props.history.goBack();
    } catch (error) {
      //throw new Error(error);
    }
  };

  render() {
    const account = this.context.currentAccount;
    const veterinarian = this.state.currentVet;
    const formInitialValues: ValuesForm = {
      birthDate: '',
      idCardFront: '',
      idCardBack: '',

    };
    if (this.state.loading) {
      return <LoaderContainer><Loader inline="centered" active={true} size={'big'} /></LoaderContainer>;
    }
    // Update the formInitialValues based on veterinarian values
    formInitialValues.birthDate =
      veterinarian && veterinarian.birthDate
        ? new Date(veterinarian.birthDate)
        : ''
    formInitialValues.idCardFront = 
      veterinarian && veterinarian.idCardFront
        ? veterinarian.idCardFront?.url
        : ''
    formInitialValues.idCardBack = 
      veterinarian && veterinarian.idCardBack
        ? veterinarian.idCardBack?.url
        : ''

    return (
      <>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <MasterContainer noSideMenu={true}>
            <Container>
              <HeaderContainer>
                <Subtitle>
                  {i18n.t('profile.veterinarianProfile.proofOfIdentity.subtitle', {
                    firstName: account?.firstName ? account.firstName : '',
                    lastName: account?.lastName ? account.lastName : '',
                  })}
                </Subtitle>
                <Title>{i18n.t('profile.veterinarianProfile.proofOfIdentity.title')}</Title>
              </HeaderContainer>

              <SectionContainer>
                <HeaderSectionForm
                  title={i18n.t('profile.veterinarianProfile.proofOfIdentity.form.title')}
                  subtitle={i18n.t('profile.veterinarianProfile.proofOfIdentity.form.subtitle')}
                />

                <Formik
                  initialValues={formInitialValues}
                  onSubmit={this.onSubmit}
                  validationSchema={profileProofOfIdentityValidationSchema}
                >
                  {() => (
                    <ProfileProofOfIdentityForm>
                      <ProfileProofOfIdentityField>
                        {/* <Field
                          name={'nationality'}
                          label={i18n.t(
                            'profile.veterinarianProfile.proofOfIdentity.form.nationality.label'
                          )}
                          placeholder={i18n.t(
                            'profile.veterinarianProfile.proofOfIdentity.form.nationality.placeholder'
                          )}
                          component={DropdownForm}
                          data={[
                            { label: 'Française', value: 'fr' },
                          ]} 
                        /> */}
                        <Field
                          name={'birthDate'}
                          label={i18n.t(
                            'profile.veterinarianProfile.proofOfIdentity.form.birthDate.label'
                          )}
                          placeholder={i18n.t(
                            'profile.veterinarianProfile.proofOfIdentity.form.birthDate.placeholder'
                          )}
                          component={DatePickerForm}
                        />
                        { veterinarian?.idCardFront &&
                          <IdCardFrontContainer>
                            <IdCardFrontContainerLabel>{i18n.t('profile.veterinarianProfile.proofOfIdentity.form.idCardFront.label')}</IdCardFrontContainerLabel>
                            <IdCardFrontContainerImg target="_blank" href={`${veterinarian.idCardFront?.url}`} ><IconCheckboxTickGreen16x16 /> {i18n.t('profile.veterinarianProfile.proofOfIdentity.form.idCardFront.label')}</IdCardFrontContainerImg>
                          </IdCardFrontContainer>
                        }
                        <Field
                          name={'idCardFront'}
                          label={veterinarian?.idCardFront ? '' : i18n.t('profile.veterinarianProfile.proofOfIdentity.form.idCardFront.label')}
                          placeholder={i18n.t('profile.veterinarianProfile.proofOfIdentity.form.idCardFront.placeholder')}
                          component={ImportFileForm}
                          id="profile-proofOfIdentity-recto"
                        />
                        { veterinarian?.idCardBack &&
                          <IdCardFrontContainer>
                            <IdCardFrontContainerLabel>{i18n.t('profile.veterinarianProfile.proofOfIdentity.form.idCardBack.label')}</IdCardFrontContainerLabel>
                            <IdCardFrontContainerImg target="_blank" href={`${veterinarian.idCardBack?.url}`} ><IconCheckboxTickGreen16x16 /> {i18n.t('profile.veterinarianProfile.proofOfIdentity.form.idCardBack.label')}</IdCardFrontContainerImg>
                          </IdCardFrontContainer>
                        }
                        <Field
                          name={'idCardBack'}
                          label={veterinarian?.idCardBack ? '' : i18n.t('profile.veterinarianProfile.proofOfIdentity.form.idCardBack.label')}
                          placeholder={i18n.t('profile.veterinarianProfile.proofOfIdentity.form.idCardBack.placeholder')}
                          component={ImportFileForm}
                          id="profile-proofOfIdentity-verso"
                        />
                      </ProfileProofOfIdentityField>
                      <ButtonsForm
                        cancelContent={i18n.t('profile.form.button.cancel')}
                        validateContent={i18n.t('profile.form.button.save')}
                        onCancel={() => this.props.history.goBack()}
                        type={'submit'}
                      />
                    </ProfileProofOfIdentityForm>
                  )}
                </Formik>
              </SectionContainer>
            </Container>
          </MasterContainer>
        </MasterBody>
      </>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.pure};
  padding-left: 10px;
  padding-right: 10px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 200px;
    padding-right: 200px;
  }
`;

const HeaderContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;

const Subtitle = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.body.small.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.body.small.fontSize};
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileProofOfIdentityForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 35px;
`;

const ProfileProofOfIdentityField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 0;
  }
`;

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;

const IdCardFrontContainer = styled.div`
  display: flex;
  justify-content: space-between
`;

const IdCardFrontContainerLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #262626;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 30%;
`;

const IdCardFrontContainerImg = styled.a`
  width: 100%;
  height: 44px;
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px 45px 12px 15px;
  font-weight: 600;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  text-align: left;
  position: relative;
  border-color: #F0F0F0;
  width: 67%;
`;