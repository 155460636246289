export default {
  Logo: require('./Logo.png'),
  LineLogo: require('./LineLogo.png'),
  VetAccount: require('./VetAccount.png'),
  CmvProLogo: require('./CmvProLogo.png'),
  CmvProLogo2x: require('./CmvProLogo@2x.png'),
  IllustrationDogInFrontOfDoghouse: require('./IllustrationDogInFrontOfDoghouse.png'),
  IllustrationDogInFrontOfDoghouse2x: require('./IllustrationDogInFrontOfDoghouse@2x.png'),
  CmvProLogoBlueTransparent: require('./CmvProLogoBlueTransparent.png'),
  CmvProLogoBlueTransparent2x: require('./CmvProLogoBlueTransparent@2x.png'),
  HugoNoMessage: require('./HugoNoMessages.png'),
  HugoNoMessage2x: require('./HugoNoMessages@2x.png'),
  OnboardingIllustrationAccountError: require('./OnboardingIllustrationAccountError.png'),
  OnboardingIllustrationAccountError2x: require('./OnboardingIllustrationAccountError@2x.png'),
  OnboardingIllustrationAccountVerification: require('./OnboardingIllustrationAccountVerification.png'),
  OnboardingIllustrationAccountVerification2x: require('./OnboardingIllustrationAccountVerification@2x.png'),
  OnboardingIllustrationAccountVerified: require('./OnboardingIllustrationAccountVerified.png'),
  OnboardingIllustrationAccountVerified2x: require('./OnboardingIllustrationAccountVerified@2x.png'),
  AllianzBusinessLogo: require('./AllianzBusinessLogo.png'),
  AllianzBusinessLogo2x: require('./AllianzBusinessLogo@2x.png'),
  HugoSecurityService: require('./HugoSecurityService.png'),
  HugoSecurityService2x: require('./HugoSecurityService@2x.png'),
  HugoSecurityService2: require('./HugoSecurityService2.png'),
  HugoSecurityService22x: require('./HugoSecurityService2@2x.png'),
  HugoSearching: require('./HugoSearching.png'),
  HugoSearching2x: require('./HugoSearching@2x.png'),
  HugoPageNotFound: require('./HugoPageNotFound.png'),
  HugoPageNotFound2x: require('./HugoPageNotFound@2x.png'),
  HugoAnimalCare: require('./HugoAnimalCare.png'),
  HugoAnimalCare2x: require('./HugoAnimalCare@2x.png'),
  HugoCustomerSupport: require('./HugoCustomerSupport.png'),
  HugoCustomerSupport2x: require('./HugoCustomerSupport@2x.png'),
  HugoNoMessages: require('./HugoNoMessages.png'),
  HugoNoMessages2x: require('./HugoNoMessages@2x.png'),
  HugoUnsubscribed: require('./HugoUnsubscribed.png'),
  HugoUnsubscribed2x: require('./HugoUnsubscribed@2x.png'),
  HugoUpgrade: require('./HugoUpgrade.png'),
  HugoUpgrade2x: require('./HugoUpgrade@2x.png'),
  HugoLoggedOut: require('./HugoLoggedOut.png'),
  HugoLoggedOut2x: require('./HugoLoggedOut@2x.png'),
  ModaleIllustrationEmailSent: require('./ModaleIllustrationEmailSent.png'),
  ModaleIllustrationEmailSent2x: require('./ModaleIllustrationEmailSent@2x.png'),
  ModaleIllustrationVideoCallDecline: require('./ModaleIllustrationVideoCallDecline.png'),
  ModaleIllustrationVideoCallDecline2x: require('./ModaleIllustrationVideoCallDecline@2x.png'),
  ModaleIllustrationVideoCallTimeout: require('./ModaleIllustrationVideoCallTimeout.png'),
  ModaleIllustrationVideoCallTimeout2x: require('./ModaleIllustrationVideoCallTimeout@2x.png'),
  ModaleIllustrationVideoCallCancelByUser: require('./ModaleIllustrationVideoCallCancelByUser.png'),
  ModaleIllustrationVideoCallCancelByUser2x: require('./ModaleIllustrationVideoCallCancelByUser@2x.png'),
  ChromeLogo: require('./Icon-Browser-Chrome.png'),
  ChromeLogo2x: require('./Icon-Browser-Chrome@2x.png'),
  SafariLogo: require('./Icon-Browser-Safari.png'),
  SafariLogo2x: require('./Icon-Browser-Safari@2x.png'),
  FirefoxLogo: require('./Icon-Browser-Firefox.png'),
  FirefoxLogo2x: require('./Icon-Browser-Firefox@2x.png'),
  // BackgroundPatternWhite: require('./BackgroundPatternWhite.png'),
  // BackgroundPatternWhite2x: require('./BackgroundPatternWhite@2x.png'),
};
