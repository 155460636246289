import { HeaderSectionForm as HeaderSectionFormComponent } from './HeaderSectionForm.component';

export type StatusProps =
  | 'IN_REVIEW'
  | 'REQUIRED_FIELD_FILLED'
  | 'VERIFIED'
  | 'VALIDATED'
  | 'WARNING'
  | 'CRITICAL_WARNING';

export type Props = {
  title?: string;
  subtitle?: string;
  status?: StatusProps;
};

export const HeaderSectionForm = HeaderSectionFormComponent;
