// export interface Speciality {
//   name: string;
//   // add value ?
// }

export const specialities: Array<string> = [
  'GeneralPractitioner',
  'Behaviourist',
  'Osteopath',
  'Ophthalmologist',
  'Dentist',
  'Nutritionist',
  'Nursery',
  'Ethologist',
  'Parasitologist',
  'Dermatologist',
  'Gastrologue',
  'Virologist',
  'Urologist',
  'Traumatologist',
  'Orthopedist',
  'Surgeon',
];
