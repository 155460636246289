import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../lib/withApolloClient';
import { Dashboard as DashboardComponent } from './Dashboard.component';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
// import { withAppContext } from '../../lib/withContext';
import { withTracker } from '../../lib/withTracker';

export type Props = WithApolloClient<RouteComponentProps>;

// export const Dashboard = withApollo(DashboardComponent);
export const Dashboard = compose<Props, RouteComponentProps>(
  withApollo,
  withTracker
)(DashboardComponent);
