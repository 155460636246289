import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './MasterProfileMenuDesktop.container';
// import { ReactComponent as IconCrossDelete } from '../../../theme/icons/IconCrossDelete.svg';

export class MasterProfileMenuDesktop extends Component<Props> {
  public render() {
    const { show /*, handleHeaderClick*/ } = this.props;
    return (
      <MainContainer show={show}>
        <ProfileMenuHeaderContainer>
          <ProfileMenuHeaderTitle>Profil Menu</ProfileMenuHeaderTitle>
          {/* <ProfileMenuHeaderSubtitle onClick={(e: any) => handleHeaderClick(e)}>
            Fermer la fenêtre
            <IconCrossDelete />
          </ProfileMenuHeaderSubtitle> */}
        </ProfileMenuHeaderContainer>
        {this.props.children}
      </MainContainer>
    );
  }
}

const MainContainer = styled.div<{ show: boolean }>`
  width: ${(props) => props.theme.breakpoints.mobilePortraitMin};
  position: absolute;
  top: 60px;
  right: 0;
  border-radius: 3px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  display: ${(props) => (props.show ? 'block' : 'none')};
  background-color: ${(props) => props.theme.colors.white.pure};
  z-index: 10000;
`;

const ProfileMenuHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 20px;
  background: ${(props) => props.theme.colors.white.dark};
  box-shadow: inset 0px -1px 0px rgba(23, 23, 23, 0.15);
`;

const ProfileMenuHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.textStyles.typography.lead.main.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.main.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.lead.main.fontSize};
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.pure};
`;

// const ProfileMenuHeaderSubtitle = styled.div`
//   display: flex;
//   align-items: center;
//   font-family: ${(props) => props.theme.textStyles.typography.lead.small.fontFamily};
//   font-weight: ${(props) => props.theme.textStyles.typography.lead.small.fontWeight};
//   font-size: ${(props) => props.theme.textStyles.typography.lead.small.fontSize};
//   line-height: 16px;
//   display: flex;
//   align-items: center;
//   letter-spacing: 0.2px;
//   color: ${(props) => props.theme.colors.grey.dark};
// `;
