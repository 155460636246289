import React, { Component } from 'react';
import styled from 'styled-components';
import { ReactComponent as CallIcon } from '../../../theme/icons/Call.svg';
import { ReactComponent as HangUpIcon } from '../../../theme/icons/HangUp.svg';
// import { ReactComponent as Behaviourist } from '../../../theme/icons/Behaviourist.svg';
// import { ReactComponent as Dentist } from '../../../theme/icons/Dentist.svg';
// import { ReactComponent as Dermatologist } from '../../../theme/icons/Dermatologist.svg';
// import { ReactComponent as Ethologist } from '../../../theme/icons/Ethologist.svg';
// import { ReactComponent as Gastrologue } from '../../../theme/icons/Gastrologue.svg';
// import { ReactComponent as GeneralPractitioner } from '../../../theme/icons/GeneralPractitioner.svg';
// import { ReactComponent as Nursery } from '../../../theme/icons/Nursery.svg';
// import { ReactComponent as Nutritionist } from '../../../theme/icons/Nutritionist.svg';
// import { ReactComponent as Ophthalmologist } from '../../../theme/icons/Ophthalmologist.svg';
// import { ReactComponent as Osteopath } from '../../../theme/icons/Osteopath.svg';
// import { ReactComponent as Parasitologist } from '../../../theme/icons/Parasitologist.svg';
// import { ReactComponent as Virologist } from '../../../theme/icons/Virologist.svg';
// import { ReactComponent as Urologist } from '../../../theme/icons/Urologist.svg';
// import { ReactComponent as Traumatologist } from '../../../theme/icons/Traumatologist.svg';
// import { ReactComponent as Orthopedist } from '../../../theme/icons/Orthopedist.svg';
// import { ReactComponent as Surgeon } from '../../../theme/icons/Surgeon.svg';
import { RaceEnum } from '../../../types/Race';
import { TroubleEnum } from '../../../types/PetTrouble';
import { i18n } from '../../../lib/i18n';
import { theme } from '../../../theme';
import { raceIdItems } from '../../../constants/petRaceAndSpecies';

interface Props {
  raceId: RaceEnum;
  species?: string;
  petName: string;
  petTrouble: TroubleEnum;
  petProfilePicture: string | null;
  customerFirstName: string;
  customerGender: string;
}

interface CallButtonsProps {
  onAcceptVideoCall: () => void;
  onDeclineVideoCall: () => void;
}

// const TROUBLE_ICONS = {
//   Behaviourist: Behaviourist,
//   Dentist: Dentist,
//   Dermatologist: Dermatologist,
//   Gastrologue: Gastrologue,
//   GeneralPractitioner: GeneralPractitioner,
//   Nursery: Nursery,
//   Nutritionist: Nutritionist,
//   Ophthalmologist: Ophthalmologist,
//   Osteopath: Osteopath,
//   Parasitologist: Parasitologist,
//   Virologist: Virologist,
//   Ethologist: Ethologist,
//   Urologist: Urologist,
//   Traumatologist: Traumatologist,
//   Orthopedist: Orthopedist,
//   Surgeon: Surgeon,
// };

export class VideoCallRequest extends Component<Props> {
  private getPetSpecie(petCategory?: string, specie?: string): string {
    if (petCategory && petCategory !== 'NAC') {
      const res = raceIdItems.filter((e) => e.value === petCategory);
      if (!res.length) return '';
      return res[0].emoji;
    }
    if (!specie) return '';
    const res = raceIdItems.filter((e) => e.value === specie);
    if (!res.length) return '';
    return res[0].emoji;
  }

  public render() {
    // const TroubleIcon = TROUBLE_ICONS[this.props.petTrouble];
    const { petProfilePicture, petTrouble, raceId, species } = this.props;
    return (
      <VideoCallRequestContainer>
        {petProfilePicture && (
          <ProfilePictureContainer>
            <ProfilePicture src={petProfilePicture} />
          </ProfilePictureContainer>
        )}
        {!petProfilePicture && (
          <EmojiContainer>
            <Emoji>{this.getPetSpecie(raceId, species)}</Emoji>
          </EmojiContainer>
        )}
        <PetTroubleContainer>
          <PetTroubleTitle>
            {i18n.t('videoCallHandler.videoCallRequest.informations')}
          </PetTroubleTitle>
          <PetTroubleLabel>
            {i18n.t('videoCallHandler.videoCallRequest.petTrouble.' + petTrouble)}
          </PetTroubleLabel>
        </PetTroubleContainer>
      </VideoCallRequestContainer>
    );
  }
}

export class VideoCallRequestButtons extends Component<CallButtonsProps> {
  public render() {
    return (
      <ButtonsContainer>
        <CallButtonContainer
          onClick={() => this.props.onDeclineVideoCall()}
          color={theme.colors.red.dark}
          hoverColor={theme.colors.red.darker}
        >
          <HangUpIcon />
        </CallButtonContainer>
        <CallButtonContainer
          onClick={() => this.props.onAcceptVideoCall()}
          color={theme.colors.green.main}
          hoverColor={theme.colors.green.darker}
        >
          <CallIcon />
        </CallButtonContainer>
      </ButtonsContainer>
    );
  }
}

const VideoCallRequestContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    justify-content: space-between;
  }
`;

const CallButtonContainer = styled.div<{ color: string; hoverColor: string }>`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.hoverColor};
  }
  margin: 0 40px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    margin: 0;
  }
`;

const ProfilePictureContainer = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  margin: 40px 0 50px 0;
`;
const ProfilePicture = styled.img`
  height: 100%;
  width: 100%;
`;

const PetTroubleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-family: ${(props) => props.theme.textStyles.typography.heading.h6.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h6.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h6.fontSize};
  color: ${(props) => props.theme.colors.black.light};
  text-align: center;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    flex-direction: column;
  }
`;

const PetTroubleTitle = styled.div`
  margin: 5px 0;
`;
const PetTroubleLabel = styled.div`
  margin: 5px 0px 5px 5px;
`;

const EmojiContainer = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin: 40px 0 50px 0;
  border: 2px solid ${(props) => props.theme.colors.white.darker};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Emoji = styled.div`
  font-size: 6em;
`;
