import * as firebaseJS from 'firebase/app';
import 'firebase/auth';
import { environment } from '../environment';

const firebaseConfig = environment.FIREBASE_CONFIG;

firebaseJS.initializeApp(firebaseConfig);

class FirebaseClass {
  public createUserWithEmailAndPassword = async (email: string, password: string) =>
    firebaseJS
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .catch((error) => {
        console.log('create Firebase User error: ', error);
      });

  public reloadUser = (hook: () => void) => {
    firebaseJS
      .auth()
      .currentUser?.reload()
      .then(() => hook());
  };

  public signIn = (email: string, password: string) =>
    firebaseJS
      .auth()
      .setPersistence(firebaseJS.auth.Auth.Persistence.LOCAL)
      .then(() => firebaseJS.auth().signInWithEmailAndPassword(email, password));

  public signOut = () => firebaseJS.auth().signOut();

  public getCurrentUser = () => firebaseJS.auth().currentUser;

  public onAuthStateChanged = (
    nextOrObserver: firebase.Observer<any> | ((a: firebase.User | null) => any),
    error?: (a: firebase.auth.Error) => any,
    completed?: firebase.Unsubscribe
  ) => firebaseJS.auth().onAuthStateChanged(nextOrObserver, error, completed);

  public sendPasswordResetEmail = (emailAddress: string) =>
    firebaseJS
      .auth()
      .sendPasswordResetEmail(emailAddress)
      .then(() => {
        // console.log('email sent');
        // Email sent.
      })
      .catch((error) => {
        console.log(error);
        // An error happened.
      });

  public isSignInWithEmailLink = (url: string) => {
    return firebaseJS.auth().isSignInWithEmailLink(url);
  };

  public applyActionCode = (actionCode: any) => {
    return firebaseJS.auth().applyActionCode(actionCode);
  };

  public signInWithEmailLink = async (email: string, url: string) => {
    try {
      return await firebaseJS.auth().signInWithEmailLink(email, url);
    } catch (error) {
      console.log('ERROR: ', error);
      throw new Error(error);
    }
  };

  public verifyPasswordResetCode = (code: string) =>
    firebaseJS.auth().verifyPasswordResetCode(code);

  public confirmPasswordReset = (code: string, newPassword: string) =>
    firebaseJS.auth().confirmPasswordReset(code, newPassword);
}

export const Firebase = new FirebaseClass();
