import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './NavBarDesktop.container';
import { ReactComponent as IconProfileEnabled } from '../../../../theme/icons/IconTabBar-Profile-Enabled-30x26.svg';
import { ReactComponent as CmvLogo } from '../../../../theme/icons/CmvProLogoBlueTransparent.svg';
import { ButtonCmv } from '../../../ButtonCmv';
import { MasterProfileMenuDesktop } from '../../../Layout/MasterProfileMenuDesktop';
import { ProfileMenuConnected } from '../../ProfileMenuMaster/ProfileMenuConnected';
import { appContext } from '../../../../context/appContext';
export class NavBarDesktop extends Component<Props> {
  public state = {
    showProfileMenu: false,
    firstName: '',
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public componentDidMount() {
    window.addEventListener('resize', this.closeProfileMenu);
    document.addEventListener('mousedown', this.handleClickOutside);

    this.context.updateContext();
    const contextValue = this.context;
    this.setState({ firstName: contextValue?.currentAccount?.firstName });
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.closeProfileMenu);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  myRef: any = React.createRef();

  public handleClickOutside = (e: any) => {
    if (this.myRef && this.myRef.current && !this.myRef.current.contains(e.target)) {
      this.setState({ showProfileMenu: false });
    }
  };

  public closeProfileMenu = () => {
    if (window.innerWidth < 992) {
      this.setState({ showProfileMenu: false });
    }
  };

  public render() {
    const { isConnected } = this.props;
    return (
      <NavBarDesktopContainer>
        <NavBarDesktopContainerFluid>
          {isConnected && (
            <NavBarDesktopConnectedContainer>
              <CmvLogoContainer onClick={() => this.props.history.push('/Dashboard')}>
                <CmvLogo width={180} height={'100%'} />
              </CmvLogoContainer>
              {/* This div is required to handle the outside click. */}
              <div ref={this.myRef as any} style={{ height: '100%' }}>
                <ProfileContainer
                  onClick={() => this.setState({ showProfileMenu: !this.state.showProfileMenu })}
                >
                  <ProfileName>{this.state.firstName}</ProfileName>
                  <IconProfileEnabled />
                  <MasterProfileMenuDesktop
                    show={this.state.showProfileMenu}
                    handleHeaderClick={(e: any) => {
                      this.setState({ showProfileMenu: false });
                      e.stopPropagation();
                    }}
                  >
                    <ProfileMenuConnected />
                  </MasterProfileMenuDesktop>
                </ProfileContainer>
              </div>
            </NavBarDesktopConnectedContainer>
          )}
          {!isConnected && (
            <NavBarDesktopNotConnectedContainer>
              <CmvLogo width={180} height={'100%'} />
              <ButtonsNotConnectedContainer>
                {/* <ButtonMainMenuHome>Callmyvet</ButtonMainMenuHome>
                <ButtonMainMenuSecondary>Fonctionnalités</ButtonMainMenuSecondary>
                <ButtonMainMenuSecondary>Sécurité</ButtonMainMenuSecondary>
                <ButtonMainMenuSecondary>À Propos</ButtonMainMenuSecondary>
                <ButtonMainMenuSecondary>Contact</ButtonMainMenuSecondary> */}
                <ButtonConnexion>
                  <ButtonCmv
                    btnStyle={'primaryHollow'}
                    btnSize={'medium'}
                    content={'Connexion'}
                    onClick={() => this.props.history.push('/SignIn')}
                  />
                </ButtonConnexion>
                <ButtonInscription>
                  <ButtonCmv
                    btnStyle={'primary'}
                    btnSize={'medium'}
                    content={'Inscription'}
                    onClick={() => this.props.history.push('/create-account')}
                  />
                </ButtonInscription>
              </ButtonsNotConnectedContainer>
            </NavBarDesktopNotConnectedContainer>
          )}
        </NavBarDesktopContainerFluid>
      </NavBarDesktopContainer>
    );
  }
}

const NavBarDesktopContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tabletMax}) {
    display: none;
  }
`;

const NavBarDesktopContainerFluid = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.laptop15Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.laptop15Max}) {
    width: 1200px;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.desktop21Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.desktop21Max}) {
    width: 1440px;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop27Min}) {
    width: 1680px;
  }
`;

/* Styles NavBarDesktopConnected */

const NavBarDesktopConnectedContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ProfileContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
  user-select: none;
`;

const ProfileName = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h6.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h6.fontSize};
  font-family: ${(props) => props.theme.textStyles.typography.heading.h6.fontFamily};
  color: ${(props) => props.theme.colors.black.lighter};
  line-height: 22px;
  margin-right: 15px;
  white-space: nowrap;
`;

/* Styles NavBarDesktopNotConnected */

// const ButtonMainMenuHome = styled.div`
//   display: flex;
//   align-items: center;
//   text-align: center;
//   background: ${(props) => props.theme.colors.primary.subtle};
//   font-family: ${(props) => props.theme.textStyles.typography.lead.main.fontFamily};
//   font-weight: ${(props) => props.theme.textStyles.typography.lead.main.fontWeight};
//   font-size: ${(props) => props.theme.textStyles.typography.lead.main.fontSize};
//   line-height: 24px;
//   color: ${(props) => props.theme.colors.primary.darker};
//   padding: 3px 10px;
//   border-radius: 10px;
//   white-space: nowrap;
// `;

// const ButtonMainMenuSecondary = styled.div`
//   display: flex;
//   align-items: center;
//   text-align: center;
//   font-family: ${(props) => props.theme.textStyles.typography.lead.main.fontFamily};
//   font-weight: ${(props) => props.theme.textStyles.typography.lead.main.fontWeight};
//   font-size: ${(props) => props.theme.textStyles.typography.lead.main.fontSize};
//   line-height: 24px;
//   color: ${(props) => props.theme.colors.black.lighter};
//   margin: 0 10px;
//   white-space: nowrap;
//   cursor: pointer;
// `;

const NavBarDesktopNotConnectedContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const ButtonsNotConnectedContainer = styled.div`
  display: flex;
  margin: 0 15px;
`;

const ButtonConnexion = styled.div`
  margin-left: 15px;
`;

const ButtonInscription = styled.div`
  margin-left: 15px;
`;

const CmvLogoContainer = styled.div`
  cursor: pointer;
`;
