import React, { Component } from 'react';
import { Props } from './OrganisationContact.container';
import styled from 'styled-components';
import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { MasterBody } from '../../../components/Layout/MasterBody';
import { MasterContainer } from '../../../components/Layout/MasterContainer';
import { HeaderSectionForm } from '../../../components/Form/HeaderSectionForm';
import { i18n } from '../../../lib/i18n';
import { Formik, Field, Form } from 'formik';
import { organisationContactValidationSchema } from './OrganisationContact.validation';
import { TextInputForm } from '../../../components/Form';
import { ButtonsForm } from '../../../components/Form/ButtonsForm';
import { Account, Company, Veterinarian } from '../../../types/DbInterface';
import { appContext } from '../../../context/appContext';
import { loader } from 'graphql.macro';

import { Loader } from '../../../components/Loader';
import { client } from '../../../graphql/client';

const getProfessionalAccountWithRelations = loader('../../../graphql/getProfessionalAccountWithRelations.graphql');
const updateCompanyContact = loader('../../../graphql/updateCompanyContact.graphql');

interface ValuesForm {
  email: string;
  phoneNumber: string;
}

export interface State {
  __test__companyName: string;
  currentCompany?: Company;
  loading?: boolean;
}

export class OrganisationContact extends Component<Props, State> {
  public state: State = {
    __test__companyName: 'Ventura Corp',
    currentCompany: undefined,
    loading: true,
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public async componentDidMount() {
    try {
      const response = await client.query<{
        getProfessionalAccountWithRelations?: {
          account: Account;
          veterinarian?: Veterinarian;
          company?: Company;
          companyVeterinarians?: Veterinarian[];
        };
      }>({
        query: getProfessionalAccountWithRelations,
        fetchPolicy: 'network-only',
      });
      this.setState({
        currentCompany: response.data.getProfessionalAccountWithRelations?.company, 
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  private onSubmit = async (values: ValuesForm) => {
    if (this.state.currentCompany) {
      try {
        await this.props.client.mutate({
          mutation: updateCompanyContact,
          variables: {
            companyId: this.state.currentCompany.id,
            email: values.email,
            phoneNumber: values.phoneNumber,
          },
        });
        this.context.updateContext();
        this.props.history.goBack();
      } catch (error) {
        throw new Error(error);
      }
    } else {
      throw new Error('no company');
    }
  };

  render() {
    const company = this.state.currentCompany;
    if (this.state.loading) {
      return <LoaderContainer><Loader inline="centered" active={true} size={'big'} /></LoaderContainer>;
    }
    return (
      <>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <MasterContainer noSideMenu={true}>
            <Container>
              <HeaderContainer>
                <Path>{i18n.t('organisation.contact.path')}</Path>
                <Title>{i18n.t('organisation.contact.title')}</Title>
              </HeaderContainer>

              <SectionContainer>
                <HeaderSectionForm
                  title={i18n.t('organisation.contact.form.title')}
                  subtitle={i18n.t('organisation.contact.form.subtitle')}
                />

                <Formik
                  initialValues={{
                    email: company?.companyEmail ? company?.companyEmail : '',
                    phoneNumber: company?.companyPhoneNumber ? company?.companyPhoneNumber : '',
                  }}
                  onSubmit={this.onSubmit}
                  validationSchema={organisationContactValidationSchema}
                >
                  {({ values, touched, errors }) => (
                    <OrganisationForm>
                      <OrganisationField>
                        <Field
                          name={'email'}
                          label={i18n.t('organisation.contact.form.email.label')}
                          placeholder={i18n.t('organisation.contact.form.email.placeholder')}
                          component={TextInputForm}
                        />
                        <Field
                          name={'phoneNumber'}
                          label={i18n.t('organisation.contact.form.phoneNumber.label')}
                          placeholder={i18n.t('organisation.contact.form.phoneNumber.placeholder')}
                          component={TextInputForm}
                        />
                      </OrganisationField>
                      <ButtonsForm
                        cancelContent={i18n.t('organisation.form.button.cancel')}
                        validateContent={i18n.t('organisation.form.button.save')}
                        onCancel={() => this.props.history.goBack()}
                        type={'submit'}
                      />
                    </OrganisationForm>
                  )}
                </Formik>
              </SectionContainer>
            </Container>
          </MasterContainer>
        </MasterBody>
      </>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.pure};
  padding-left: 10px;
  padding-right: 10px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 200px;
    padding-right: 200px;
  }
`;

const HeaderContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;

const Path = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.body.small.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.body.small.fontSize};
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrganisationForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 35px;
`;

const OrganisationField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 0;
  }
`;

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;
