import React, { Component } from 'react';
import { Props } from './OnboardingVetCompany.container';
import styled from 'styled-components';
import { MasterBody } from '../../../components/Layout/MasterBody';
import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { i18n } from '../../../lib/i18n';
import { MasterContainer } from '../../../components/Layout/MasterContainer';
// import { ButtonCmv } from '../../../components/ButtonCmv';
// import { Row, Col } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import { TextInputForm, DropdownForm } from '../../../components/Form';
import { onboardingVetCompanyValidationSchema } from './OnboardingVetcompany.validation';
import { loader } from 'graphql.macro';
import { createStripeToken } from '../../../lib/createStripeToken';
import { appContext } from '../../../context/appContext';
import { Account } from '../../../types/DbInterface';
import { CompanyLegalStatusType } from '../../../types/company';
import { ButtonsForm } from '../../../components/Form/ButtonsForm';

const createCompany = loader('../../../graphql/createCompany.graphql');

const companyCountries: Array<{ label: string; value: string }> = [
  { label: 'France', value: 'fr' },
  { label: 'Belgique', value: 'be' },
];

const companyLegalStatus: Array<{ value: CompanyLegalStatusType; label: string }> = [
  { value: 'CL', label: 'Collaborateur libéral' },
  { value: 'EI', label: 'Entreprise individuelle (Officie en nom propre)' },
  { value: 'EIRL', label: 'Entreprise individuelle à responsabilité limitée' },
  { value: 'EURL', label: 'Entreprise unipersonnelle à responsabilité limitée' },
  { value: 'SA', label: 'Société anonyme' },
  { value: 'SARL', label: 'Société anonyme à responsabilité limitée' },
  { value: 'SAS', label: 'Société par actions simplifié' },
  { value: 'SASU', label: 'Société par actions simplifiée unipersonnelle' },
  { value: 'SC', label: 'Société civile' },
  { value: 'SCP', label: 'Société Civile Professionnelle' },
  { value: 'SCRL', label: 'Société coopérative à responsabilité limitée' },
  { value: 'SELARL', label: "Société d'exercice libéral à responsabilité limitée" },
  { value: 'SLP', label: 'Société de libre partenariat' },
  { value: 'SNC', label: 'Société en nom collectif' },
  { value: 'SPFPL', label: 'Société de participations financières de professions libérales' },
  {
    value: 'SPFPL_VRL',
    label:
      'Société de participations financières de professions libérales de vétérinaire à responsabilité limitée',
  },
  {
    value: 'SPFPL_SAS',
    label: 'Société de participations financières de professions libérales à forme de S.A.S.',
  },
  {
    value: 'SPFPL_RL',
    label:
      'Société de participations financières de professions libérales à responsabilité limitée',
  },
  { value: 'SE', label: 'Société européenne' },
  { value: 'SDE', label: 'Société de droit étranger' },
];

//TODO check backend values
const companyTypes: Array<{ label: string; value: string }> = [
  { value: 'I', label: 'Indépendant' },
  { value: 'AS', label: 'Associés en Société' },
  { value: 'GE', label: 'Groupement d’établissements' },
];

interface State {
  currentAccount?: Account;
  veterinarianInvitationToken?: string;
  isLoading?: boolean;
}

export class OnboardingVetCompany extends Component<Props, State> {
  public state: State = {
    currentAccount: undefined,
    isLoading: false,
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public async componentDidMount() {
    const contextValue = this.context;
    const currentAccount = contextValue.currentAccount;
    // console.log('context: ', contextValue);
    if (!currentAccount) {
      return;
    }
    const veterinarianInvitationToken = localStorage.getItem('veterinarianInvitationToken');
    this.setState({
      currentAccount,
      veterinarianInvitationToken: veterinarianInvitationToken
        ? veterinarianInvitationToken
        : undefined,
    });
  }

  private onSubmit = async (values: any) => {
    this.setState({isLoading: true});
    if (
      this.state.currentAccount &&
      this.state.currentAccount.firstName &&
      this.state.currentAccount.lastName
    ) {
      try {
        const stripeAccountToken = await createStripeToken(
          values.companyName,
          this.state.currentAccount.firstName,
          this.state.currentAccount.lastName
        );
        await this.props.client.mutate({
          mutation: createCompany,
          variables: {
            name: values.companyName,
            legalStatus: values.legalStatus,
            nationality: values.country,
            companyType: values.type,
            stripeAccountToken,
            invitationToken: this.state.veterinarianInvitationToken
              ? this.state.veterinarianInvitationToken
              : undefined,
          },
        });
        this.context.updateContext();
        this.setState({isLoading: false});
        this.props.history.push('Dashboard');
      } catch (error) {
        this.setState({isLoading: false});
        throw new Error(error);
      }
    } else {
      throw new Error('no account');
    }
  }; //TODO types values

  public render() {
    return (
      <Page>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <MasterContainer noSideMenu={true}>
            <OnboardingVetCompanyContainer>
              <OnboardingVetCompanyTitle>
                {i18n.t('onboardingVetCompany.title')}
              </OnboardingVetCompanyTitle>
              <OnboardingVetCompanyDescription>
                {i18n.t('onboardingVetCompany.description')}
              </OnboardingVetCompanyDescription>
              <OnboardingVetCompanySectionTitle>
                Informations légales
              </OnboardingVetCompanySectionTitle>

              <Formik
                initialValues={{
                  country: '',
                  legalStatus: '',
                  type: '',
                  companyName: '',
                }}
                onSubmit={this.onSubmit}
                validationSchema={onboardingVetCompanyValidationSchema}
              >
                {({ values, touched, errors, setFieldValue }) => (
                  <OnboardingVetCompanyForm>
                    <OnboardingVetCompanyField>
                      <Field
                        name={'country'}
                        label={i18n.t('onboardingVetCompany.form.fields.country.label')}
                        placeholder={i18n.t('onboardingVetCompany.form.fields.country.placeholder')}
                        component={DropdownForm}
                        data={companyCountries}
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                          // Do the logic needed for each case
                          setFieldValue('country', event.target.value);
                        }}
                        value={''}
                      />
                      <Field
                        name={'legalStatus'}
                        label={i18n.t('onboardingVetCompany.form.fields.legalStatus.label')}
                        placeholder={i18n.t(
                          'onboardingVetCompany.form.fields.legalStatus.placeholder'
                        )}
                        component={DropdownForm}
                        data={companyLegalStatus}
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                          // Do the logic needed for each case
                          setFieldValue('legalStatus', event.target.value);
                        }}
                        value={''}
                      />
                      <Field
                        name={'type'}
                        label={i18n.t('onboardingVetCompany.form.fields.type.label')}
                        placeholder={i18n.t('onboardingVetCompany.form.fields.type.placeholder')}
                        component={DropdownForm}
                        data={companyTypes}
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                          // Do the logic needed for each case
                          setFieldValue('type', event.target.value);
                        }}
                        value={''}
                      />
                      <Field
                        name={'companyName'}
                        label={i18n.t('onboardingVetCompany.form.fields.companyName.label')}
                        placeholder={i18n.t(
                          'onboardingVetCompany.form.fields.companyName.placeholder'
                        )}
                        component={TextInputForm}
                      />
                    </OnboardingVetCompanyField>
                    <ButtonsForm
                      cancelContent={i18n.t('onboardingVetCompany.form.button.goBack')}
                      validateContent={i18n.t('onboardingVetCompany.form.button.continue')}
                      onCancel={() => {
                        this.props.history.push('/onboarding');
                      }}
                      type={'submit'}
                      spinner={this.state.isLoading}
                      disabled={this.state.isLoading ? true : false}
                    />
                  </OnboardingVetCompanyForm>
                )}
              </Formik>
            </OnboardingVetCompanyContainer>
          </MasterContainer>
        </MasterBody>
      </Page>
    );
  }
}

const Page = styled.div`
  width: 100%;
`;
const OnboardingVetCompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
    padding-left: 160px;
    padding-right: 160px;
  }
`;

const OnboardingVetCompanyTitle = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.heading.h4.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h4.fontWeight};
  line-height: 32px;
  text-align: left;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 48px;
`;

const OnboardingVetCompanyDescription = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.body.main.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.body.main.fontWeight};
  line-height: 24px;
  color: ${(props) => props.theme.colors.black.lighter};
  text-align: left;
  margin-top: 21px;
  white-space: pre-wrap;
`;

const OnboardingVetCompanySectionTitle = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.heading.h6.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h6.fontWeight};
  line-height: 22px;
  border-bottom: 2px solid ${(props) => props.theme.colors.blue.dark};
  padding: 9px 0;
  margin-top: 40px;
  color: ${(props) => props.theme.colors.primary.mainColor};
`;

// const OnboardingVetCompanyButtonsRow = styled(Row)`
//   @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
//     margin-top: 92px;
//   }
// `;

// const OnboardingVetCompanyBtnContainer = styled.div`
//   margin: 74px 0;
// `;

/*  */

const OnboardingVetCompanyForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 35px;
`;

const OnboardingVetCompanyField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 0;
  }
`;
