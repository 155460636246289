import * as Yup from 'yup';

export const profileAnimalsInCareValidationSchema = Yup.object().shape({
  petCategories: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
        checked: Yup.boolean(),
      })
    )
    .compact((v) => !v.checked)
    .required('Sélectionnez au moins un animal'),
});
