import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../../lib/withApolloClient';
import { ProfileProofOfIdentity as ProfileProofOfIdentityComponent } from './ProfileProofOfIdentity.component';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
import { withAppContext } from '../../../lib/withContext';

export type Props = WithApolloClient<RouteComponentProps>;

export const ProfileProofOfIdentity = compose<Props, RouteComponentProps>(
  withApollo,
  withAppContext
)(ProfileProofOfIdentityComponent);
