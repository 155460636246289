import React, { Component } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { ReactComponent as IconChevronDown } from '../../../theme/icons/IconChevronDown.svg';
import { ThemeComponents } from '../../../theme/components';
import { Props } from './DropdownForm.container';


interface State {
  optionValue: string;
}
export class DropdownForm extends Component<Props> {
  public state: State = {
    optionValue: '',
  }
  public componentDidMount() {
    this.setState({optionValue: this.props.value})
  }
  
  public render() {
    const { form, label, sublabel, placeholder, field, required, data, onChange, value } = this.props;
    const error = form.errors[field.name];
    const touched = form.touched[field.name];
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      if(onChange) {
        this.setState({optionValue: event.target.value})
        onChange(event);
      }
    };

    return (
      <DropdownContainer fluid>
        <DropdownRow noGutters>
          <Col xs={12} sm={4}>
            <DropdownLabelContainer>
              <DropdownLabel>{label}</DropdownLabel>
              <DropdownSubLabel>{sublabel}</DropdownSubLabel>
            </DropdownLabelContainer>
          </Col>
          <Col xs={12} sm={8}>
            <Dropdown
              {...field}
              placeholder={placeholder && required ? `${placeholder + ' *'}` : placeholder}
              error={error}
              touched={touched}
              onChange={handleChange }
              value={this.state.optionValue}
            >
              <OptionPlaceholder value="">
                {placeholder && required ? `${placeholder + ' *'}` : placeholder}
              </OptionPlaceholder>
              {data.map((option, i) => (
                <OptionItem value={option.value} key={i}>
                  {option.label}
                </OptionItem>
              ))}
              {/* OptGroup : No truncated label on ios mobile */}
              <OptGroup label=""></OptGroup>
            </Dropdown>
            <DropdownIcon />
            {touched && error && <DropdownError>{error}</DropdownError>}
          </Col>
        </DropdownRow>
      </DropdownContainer>
    );
  }
}

const DropdownContainer = styled(Container)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 16px 0;
  padding: 0;
`;

const DropdownRow = styled(Row)`
  width: 100%;
`;

const Dropdown = styled(ThemeComponents.FormDropdown)<{ value: string }>`
  border-color: ${(props) => {
    const colorType =
      !!props.error && props.touched
        ? props.theme.colors.red.dark
        : props.theme.colors.white.darker;
    return colorType;
  }};

  :nth-child(1) {
    color: ${(props) =>
      props.value === '' ? props.theme.colors.grey.main : props.theme.colors.black.light};
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`;

const DropdownLabelContainer = styled.div`
  margin: 5px 15px 5px 0;
`;

const DropdownLabel = styled(ThemeComponents.FormLabel)``;

const DropdownSubLabel = styled(ThemeComponents.FormSubLabel)``;

const DropdownError = styled(ThemeComponents.FormErrorLabel)``;

const DropdownIcon = styled(IconChevronDown)`
  position: absolute;
  top: 10px;
  right: 15px;
`;

const OptionPlaceholder = styled.option``;

const OptionItem = styled.option``;

const OptGroup = styled.optgroup``;
