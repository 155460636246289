/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { environment } from '../environment';

ReactGA.initialize('UA-145256224-2');

export const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    if (environment.APP_ENV === 'production') {
      ReactGA.set({
        page,
        ...options,
      });
      ReactGA.pageview(page);
    }
  };

  const HOC = class extends Component {
    componentDidMount() {
      if (environment.APP_ENV === 'production') {
        const page = this.props.location.pathname;
        trackPage(page);
      }
    }

    componentDidUpdate(nextProps) {
      if (environment.APP_ENV === 'production') {
        const currentPage = this.props.location.pathname;
        const nextPage = nextProps.location.pathname;

        if (currentPage !== nextPage) {
          trackPage(nextPage);
        }
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};
