import React, { Component, Fragment } from 'react';
import { Props } from './ResetPasswordRequest.container';
import styled from 'styled-components';
import images from '../../../theme/images';
import { ButtonCmv } from '../../../components/ButtonCmv';
import { Formik, Field, Form } from 'formik';
import { resetPasswordRequestValidationSchema } from './ResetPasswordRequest.validation';
import { TextInputForm } from '../../../components/Form';
import { MasterBody } from '../../../components/Layout/MasterBody';

import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { i18n } from '../../../lib/i18n';

// import { Firebase } from '../../../lib/firebase';
import { loader } from 'graphql.macro';
import { ModalCmv } from '../../../components/ModalCmv';
// import { ReactComponent as IconCrossDelete } from '../../../theme/icons/IconCrossDelete.svg';
// import { MasterSideMenu } from '../../../components/Layout/MasterSideMenu';
// import { MainMenuConnected } from '../../../components/UiNavigation/MainMenuMaster/MainMenuConnected';
const firebaseAccountPasswordResetRequest = loader(
  '../../../graphql/firebaseAccountPasswordResetRequest.graphql'
);

interface ValuesForm {
  email: string;
}

interface State {
  showConfirmationModal: boolean;
  showFormModal: boolean;
}

export class ResetPasswordRequest extends Component<Props, State> {
  public state: State = {
    showConfirmationModal: false,
    showFormModal: true,
  };

  public onSubmit = async (values: ValuesForm) => {
    let response;
    try {
      response = await this.props.client.query({
        query: firebaseAccountPasswordResetRequest,
        variables: {
          email: values.email,
        },
        fetchPolicy: 'network-only',
      });
      if (response.data.firebaseAccountPasswordResetRequest !== 'valid') {
        this.formikRef.setFieldError(
          'email',
          i18n.t('resetPasswordRequest.form.errors.tooManyRequest')
        );
        return;
      }
      window.localStorage.setItem('emailForReset', values.email);

      this.setState({ showConfirmationModal: true, showFormModal: false });
    } catch (error) {
      if (String(error).indexOf('EMAIL_NOT_FOUND') !== -1)
        this.formikRef.setFieldError(
          'email',
          i18n.t('resetPasswordRequest.form.errors.emailNotFound')
        );
      else if (String(error).indexOf('RESET_PASSWORD_EXCEED_LIMIT') !== -1)
        this.formikRef.setFieldError(
          'email',
          i18n.t('resetPasswordRequest.form.errors.tooManyRequest')
        );
    }
  };

  private formikRef: any;

  public render() {
    return (
      <Fragment>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <Formik
            ref={(formikComponent) => (this.formikRef = formikComponent)}
            initialValues={{
              email: '',
            }}
            onSubmit={this.onSubmit}
            validationSchema={resetPasswordRequestValidationSchema}
          >
            {() => (
              <ModalCmv
                show={this.state.showFormModal}
                onClickOutsideCloseModal={() => null}
                // headerIconLeft={IconCrossDelete}
                headerTitle={i18n.t('resetPasswordRequest.title')}
                footerSubTitle={i18n.t('resetPasswordRequest.form.indication')}
                contentViewImage={images.HugoCustomerSupport}
                contentViewImageRetina={images.HugoCustomerSupport2x}
                contentViewCustomComponent={
                  <ResetPasswordForm>
                    <ResetPasswordField>
                      <Field
                        name={'email'}
                        label={i18n.t('resetPasswordRequest.form.fields.email.label')}
                        placeholder={i18n.t('resetPasswordRequest.form.fields.email.placeholder')}
                        component={TextInputForm}
                        direction={'column'}
                      />
                    </ResetPasswordField>
                    <ButtonCmvContainer>
                      <ButtonCmv
                        type={'submit'}
                        btnSize={'large'}
                        btnStyle={'primary'}
                        content={i18n.t('resetPasswordRequest.form.submit')}
                      />
                    </ButtonCmvContainer>
                  </ResetPasswordForm>
                }
              />
            )}
          </Formik>
          <ModalCmv
            show={this.state.showConfirmationModal}
            onClickOutsideCloseModal={() => null}
            // contentViewPreTitle={i18n.t('resetPasswordRequest.confirmationModal.title')}
            contentViewBodyContent={i18n.t('resetPasswordRequest.confirmationModal.subtitle')}
            footerHidden={true}
            contentViewImage={images.HugoNoMessage}
            contentViewImageRetina={images.HugoNoMessage2x}
            contentViewCustomComponent={
              <ButtonCmvContainer>
                <ButtonCmv
                  btnSize={'giant'}
                  btnStyle={'primary'}
                  content={i18n.t('resetPasswordRequest.confirmationModal.backToLogin')}
                  onClick={() => this.props.history.push('/SignIn')}
                />
              </ButtonCmvContainer>
            }
          />
        </MasterBody>
      </Fragment>
    );
  }
}

const ResetPasswordForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ResetPasswordField = styled.div`
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 60px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    & {
      padding: 0;
    }
  }
`;

const ButtonCmvContainer = styled.div`
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin: 10px 0;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    & {
      padding: 0;
    }
  }
`;
