import { VideoCallHandler as VideoCallHandlerComponent } from './VideoCallHandler.component';
import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../lib/withApolloClient';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { Account, Company, Veterinarian } from '../../types/DbInterface';
import compose from 'recompose/compose';
import { withAppContext } from '../../lib/withContext';

type ContainerProps = {
  currentVet?: Veterinarian;
  currentAccount?: Account;
  currentCompany?: Company;
  companyVeterinarians?: Veterinarian[];
};

export type Props = WithApolloClient<RouteComponentProps & ContainerProps>;

export const VideoCallHandler = compose<Props, ContainerProps>(
  withApollo,
  withRouter,
  withAppContext
)(VideoCallHandlerComponent);
