import React, { Component, Fragment } from 'react';
import { Props } from './FirebaseAccount.container';
import styled from 'styled-components';
import { MasterBody } from '../../components/Layout/MasterBody';
import { parse } from 'query-string';
import { Loader } from '../../components/Loader';

export class FirebaseAccount extends Component<Props> {
  public async componentDidMount() {
    const params: any = this.getUrlParams();
    // console.log(params);

    switch (params.mode) {
      case 'resetPassword':
        this.props.history.push('reset-password-form', {
          oobCode: params.oobCode,
          mode: params.mode,
        });
        break;
      case 'verifyEmail':
        this.props.history.push('create-account-verification', {
          oobCode: params.oobCode,
          mode: params.mode,
        });
        break;
      //   case 'recoverEmail':
      //     break;
      default:
        this.props.history.push('Dashboard');
        break;
    }
  }

  private getUrlParams = () => {
    const url = this.props.location.search;
    const params = parse(url);
    return params;
  };

  public render() {
    return (
      <Fragment>
        <MasterBody>
          <LoaderContainer>
            <Loader inline="centered" active={true} size={'big'} />
          </LoaderContainer>
        </MasterBody>
      </Fragment>
    );
  }
}

const LoaderContainer = styled.div`
  margin: 15px 0;
`;
