import React, { Component } from 'react';
import { Props } from './CreateAccount.container';
import styled from 'styled-components';
import { ButtonCmv } from '../../../components/ButtonCmv';
import { Formik, Field, Form } from 'formik';
import { createAccountValidationSchema } from './CreateAccount.validation';
import { TextInputForm, SimpleCheckForm } from '../../../components/Form';
import { MasterBody } from '../../../components/Layout/MasterBody';
import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { i18n } from '../../../lib/i18n';
import { MasterSideMenu } from '../../../components/Layout/MasterSideMenu';
import { MasterContainer } from '../../../components/Layout/MasterContainer';
import { Row, Col } from 'react-bootstrap';
import { PasswordValidator } from '../../../components/PasswordValidator';
import { CGU_URL } from '../../../constants';
import {
  AccountEmailSentConfirmationBody,
  AccountEmailSentConfirmationFooter,
} from '../../../components/AccountEmailSentConfirmation';
import { ModalCmv } from '../../../components/ModalCmv';
import { OnBoardingSideMenu } from '../../../components/OnBoardingSideMenu';
import { loader } from 'graphql.macro';
import { Firebase } from '../../../lib/firebase';
import { SignUpFormValues } from '../../../types/SignUpForm';
import images from '../../../theme/images';
import { ReactComponent as IconCrossDelete } from '../../../theme/icons/IconCrossDelete.svg';

const createAccount = loader('../../../graphql/createAccount.graphql');
const deleteAccount = loader('../../../graphql/deleteAccount.graphql');
const firebaseAccountEmailVerificationRequest = loader(
  '../../../graphql/firebaseAccountEmailVerificationRequest.graphql'
);

interface State {
  formIsValid: boolean;
  isLoading: boolean;
  email: string;
  showDeleteAccountModal: boolean;
  companyInvitationToken?: string;
  veterinarianInvitationToken?: string;
}

export class CreateAccount extends Component<Props, State> {
  public state: State = {
    formIsValid: false,
    isLoading: false,
    email: '',
    showDeleteAccountModal: false,
  };

  private getParams() {
    const searchParams = new URLSearchParams(this.props.location.search);
    return {
      companytoken: searchParams.get('companytoken') || '',
      veterinariantoken: searchParams.get('veterinariantoken') || '',
    };
  }

  async componentDidMount() {
    const params = this.getParams();
    // console.log(params);
    this.setState({
      companyInvitationToken: decodeURIComponent(params.companytoken),
      veterinarianInvitationToken: decodeURIComponent(params.veterinariantoken),
    });
  }

  private async sendFirebaseAccountEmailVerificationRequest() {
    try {
      await this.props.client.query({
        query: firebaseAccountEmailVerificationRequest,
      });
    } catch (error) {
      console.log(error);
    }
  }

  private async cancelSignUpDeleteAccount() {
    try {
      await this.props.client.mutate({
        mutation: deleteAccount,
      });
      await Firebase.signOut();
      this.setState({ formIsValid: false, showDeleteAccountModal: true });
      // this.props.history.push('SignIn');
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }

  private onSubmit = async (values: SignUpFormValues) => {
    //TODO modify SignInForm , delete old one ?
    this.setState({ formIsValid: true, email: values.email });

    // create firebaseAccount
    try {
      const firebaseUser = await Firebase.createUserWithEmailAndPassword(
        values.email,
        values.password
      );

      if (!firebaseUser) throw new Error('no firebase User');

      // console.log(firebaseUser);

      let invitationToken;
      if (this.state.companyInvitationToken) invitationToken = this.state.companyInvitationToken;
      else if (this.state.veterinarianInvitationToken)
        invitationToken = this.state.veterinarianInvitationToken;

      await this.props.client.mutate({
        mutation: createAccount,
        variables: {
          email: values.email,
          firebaseUid: firebaseUser.user?.uid,
          firstName: values.firstName,
          lastName: values.lastName,
          invitationToken,
        },
      });
      if (this.state.companyInvitationToken && this.state.veterinarianInvitationToken)
        localStorage.setItem('veterinarianInvitationToken', this.state.veterinarianInvitationToken);
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

  public render() {
    return (
      <Page>
        <NavBarMaster />
        <SubNavBar content={i18n.t('createAccount.subnavbar.title')} />
        {!this.state.formIsValid && (
          <OnBoardingSideMenuMobileContainer>
            <OnBoardingSideMenu />
          </OnBoardingSideMenuMobileContainer>
        )}
        <MasterBody>
          {!this.state.formIsValid && (
            <MasterSideMenu>
              <OnBoardingSideMenu />
            </MasterSideMenu>
          )}
          <MasterContainer>
            {!this.state.formIsValid && (
              <CreateAccountContainer>
                <Title>{i18n.t('createAccount.title')}</Title>
                <Description>
                  {i18n.t('createAccount.description')}
                  <LinkLogin href="/SignIn"> {i18n.t('createAccount.linkLogin')}</LinkLogin>
                </Description>
                <Formik
                  initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    cguAccepted: false,
                  }}
                  onSubmit={this.onSubmit}
                  validationSchema={createAccountValidationSchema}
                >
                  {({ values, touched, errors }) => (
                    <CreateAccountForm>
                      <CreateAccountField>
                        <Field
                          name={'firstName'}
                          label={i18n.t('createAccount.form.fields.firstName.label')}
                          placeholder={i18n.t('createAccount.form.fields.firstName.placeholder')}
                          component={TextInputForm}
                        />
                        <Field
                          name={'lastName'}
                          label={i18n.t('createAccount.form.fields.lastName.label')}
                          placeholder={i18n.t('createAccount.form.fields.lastName.placeholder')}
                          component={TextInputForm}
                        />
                        <Field
                          name={'email'}
                          label={i18n.t('createAccount.form.fields.email.label')}
                          placeholder={i18n.t('createAccount.form.fields.email.placeholder')}
                          component={TextInputForm}
                        />
                        <Field
                          name={'password'}
                          label={i18n.t('createAccount.form.fields.password.label')}
                          placeholder={i18n.t('createAccount.form.fields.password.placeholder')}
                          component={TextInputForm}
                          type={'password'}
                          autoComplete={'new-password'}
                        />
                        <Row noGutters>
                          <Col xs={12} sm={{ span: 8, offset: 4 }} style={{ padding: '5px 0px' }}>
                            <PasswordValidator content={values.password} />
                          </Col>
                        </Row>
                        <Field
                          name={'cguAccepted'}
                          label={i18n.t('createAccount.form.fields.cgu.label')}
                          component={SimpleCheckForm}
                          placeholderComponent={
                            <CguContainer>
                              {i18n.t('createAccount.form.fields.cgu.placeholder.firstPart')}
                              <CguLink
                                onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                                href={CGU_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: 'inherit', textDecoration: 'underline' }}
                                name="cguAccepted"
                              >
                                {i18n.t('createAccount.form.fields.cgu.placeholder.firstLink')}
                              </CguLink>
                              {i18n.t('createAccount.form.fields.cgu.placeholder.and')}
                              <CguLink
                                onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                                href="/#"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: 'inherit', textDecoration: 'underline' }}
                                name="cguAccepted"
                              >
                                {i18n.t('createAccount.form.fields.cgu.placeholder.secondLink')}
                              </CguLink>
                              {i18n.t('createAccount.form.fields.cgu.placeholder.secondPart')}
                            </CguContainer>
                          }
                        />
                      </CreateAccountField>
                      <Row noGutters>
                        <Col xs={12} sm={{ span: 8, offset: 4 }}>
                          <BtnContainer>
                            <ButtonCmv
                              type={'submit'}
                              btnSize={'giant'}
                              btnStyle={'primary'}
                              content={i18n.t('createAccount.form.submit')}
                            />
                          </BtnContainer>
                          <InformationsContainer>
                            <InformationSecurity>
                              {i18n.t('createAccount.form.informations.security')}
                            </InformationSecurity>
                            <InformationRequired>
                              {i18n.t('createAccount.form.informations.required')}
                            </InformationRequired>
                          </InformationsContainer>
                        </Col>
                      </Row>
                    </CreateAccountForm>
                  )}
                </Formik>
              </CreateAccountContainer>
            )}
            <ModalCmv
              show={this.state.formIsValid}
              onClickOutsideCloseModal={() => null}
              headerIconLeft={IconCrossDelete}
              headerTitle={i18n.t('createAccount.confirmation.headerTitle')}
              headerOnClickLeft={() => this.props.history.push('/SignIn')}
              contentViewSubTitle={i18n.t('createAccount.confirmation.subtitle')}
              contentViewImage={images.ModaleIllustrationEmailSent}
              contentViewImageRetina={images.ModaleIllustrationEmailSent2x}
              contentViewCustomComponent={
                <AccountEmailSentConfirmationBody email={this.state.email} />
              }
              footerCustomComponent={
                <AccountEmailSentConfirmationFooter
                  resendEmailLink={() => this.sendFirebaseAccountEmailVerificationRequest()}
                  cancelSignUp={() => this.cancelSignUpDeleteAccount()}
                />
              }
            />
            <ModalCmv
              show={this.state.showDeleteAccountModal}
              onClickOutsideCloseModal={() => null}
              contentViewPreTitle={i18n.t('createAccount.deleteAccount.preTitle')}
              contentViewBodyContent={i18n.t('createAccount.deleteAccount.bodyContent')}
              contentViewImage={images.HugoPageNotFound}
              contentViewImageRetina={images.HugoPageNotFound2x}
              contentViewCustomComponent={
                <ButtonCmvContainer>
                  <ButtonCmv
                    btnSize={'giant'}
                    btnStyle={'primary'}
                    content={i18n.t('createAccount.deleteAccount.buttonLabel')}
                    onClick={() => this.props.history.push('/SignIn')}
                  />
                </ButtonCmvContainer>
              }
              footerHidden={true}
            />
          </MasterContainer>
        </MasterBody>
      </Page>
    );
  }
}
const Page = styled.div`
  position: relative;
  width: 100%;
`;

const CreateAccountContainer = styled.div`
  width: 100%;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tabletMax}) {
    margin-top: 150px;
  }
`;

const Title = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.heading.h4.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h4.fontWeight};
  line-height: 32px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 48px;
  margin-bottom: 21px;
`;

const Description = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontWeight};
  letter-spacing: ${(props) =>
    props.theme.textStyles.typography.lead.capitalize.regular.letterSpacing};
  line-height: 17px;
  color: ${(props) => props.theme.colors.black.lighter};
`;

const LinkLogin = styled.a`
  color: ${(props) => props.theme.colors.primary.mainColor};
  text-decoration: underline;
`;

const CreateAccountForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 35px;
`;

const CreateAccountField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 0;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 30px 0 20px 0;
`;

const InformationsContainer = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.lead.tiny.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.small.fontWeight};
  line-height: 14px;
  letter-spacing: 0.2px;
  text-align: center;
  padding: 0px 40px;
  margin-bottom: 20px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 0;
  }
`;

const InformationSecurity = styled.p`
  margin: 0;
  color: ${(props) => props.theme.colors.grey.darker};
`;
const InformationRequired = styled.p`
  margin: 0;
  color: ${(props) => props.theme.colors.red.main};
`;

const OnBoardingSideMenuMobileContainer = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  top: calc(60px + 30px); /* Navbar height + Subnavbar height; */
  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    display: none;
  }
`;

const CguContainer = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontWeight};
  letter-spacing: ${(props) =>
    props.theme.textStyles.typography.lead.capitalize.regular.letterSpacing};
  line-height: 17px;
  color: ${(props) => props.theme.colors.black.lighter};
`;

const CguLink = styled.a``;

const ButtonCmvContainer = styled.div`
  width: 100%;
  margin-top: 20px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 100px;
    padding-right: 100px;
  }
`;
