import React, { Component } from 'react';
import styled from 'styled-components';
import { Props, TarificationProps } from './Twilio.container';
import { CallActionButton } from './CallActionButton/CallActionButton.component';
import { ReactComponent as HangUpIcon } from '../../theme/icons/HangUp.svg';
import { ReactComponent as CameraIcon } from '../../theme/icons/Camera.svg';
import { ReactComponent as CameraOffIcon } from '../../theme/icons/CameraOff.svg';
import { ReactComponent as MicroIcon } from '../../theme/icons/Micro.svg';
import { ReactComponent as MicroOffIcon } from '../../theme/icons/MicroOff.svg';
import { ReactComponent as VisioIconScreenZoomIn } from '../../theme/icons/VisioIcon-ScreenZoomIn.svg';
import { ReactComponent as VisioIconScreenZoomOut } from '../../theme/icons/VisioIcon-ScreenZoomOut.svg';
import { i18n } from '../../lib/i18n';
import { loader } from 'graphql.macro';
import { PicturesList } from './PicturesList';
import { theme } from '../../theme';
import * as Sentry from '@sentry/browser';
import Video, { VP8CodecSettings, LogLevel } from 'twilio-video';
import { Loader } from '../../components/Loader';
// import { NavBarMaster } from '../../components/UiNavigation/NavBar';
// import { SubNavBar } from '../../components/UiNavigation/SubNavBar';
import { MasterBody } from '../../components/Layout/MasterBody';
import { MasterContainer } from '../../components/Layout/MasterContainer';
import { CallInfoDetailsBox } from './CallInfoDetailsBox';
import { Col, Container, Row } from 'react-bootstrap';
import { raceIdItems } from '../../constants/petRaceAndSpecies';
import { differenceInYears, differenceInMonths } from 'date-fns';
import { ModalCmv } from '../../components/ModalCmv';
// import { ReactComponent as IconInfoHollow } from '../../theme/icons/Icon-InfoHollow-24x24.svg';
import { SurveyEndCall } from '../Twilio/SurveyEndCall';

const handleFreecallValidationByVeterinarian = loader(
  '../../graphql/handleFreecallValidationByVeterinarian.graphql'
);
const endCallFromVeterinarian = loader('../../graphql/endCallFromVeterinarian.graphql');
const endCallWithIssueFromVeterinarian = loader(
  '../../graphql/endCallWithIssueFromVeterinarian.graphql'
);

interface State {
  identity: string | null;
  roomName: string;
  roomNameErr: boolean;
  localMediaAvailable: boolean;
  hasJoinedRoom: boolean;
  activeRoom: any | null;
  isLocalVideoEnabled: boolean;
  isLocalAudioEnabled: boolean;
  timer: number;
  startTimer: number;
  timerIsOn: boolean;
  handleFreecall: boolean;
  participantHasConnectedOnce: boolean;
  isRemoteAudioEnabled: boolean;
  isRemoteVideoEnabled: boolean;
  isRemoteParticipantReconnecting: boolean;
  isRemoteParticipantDisconnected: boolean;
  isScreenZoomInActivated: boolean;
  callInfoDetailsIndex: number;
  showEndCallModal: boolean;
  customerFirstName: string;
  customerLastName: string;
  customerPhoneNumber: string;
  isCameraDisabled: boolean;
  cameraErrorMsg: string;
}

const getTracks = (participant: any) => {
  return Array.from(participant.tracks.values())
    .filter(function (publication) {
      // @ts-ignore
      return publication.track;
    })
    .map(function (publication) {
      // @ts-ignore
      return publication.track;
    });
};

const tabItems: Array<string> = [
  'twilio.videoTabItems.call',
  'twilio.videoTabItems.documents',
  // 'twilio.videoTabItems.animalCard',
];

export class Twilio extends Component<Props, State> {
  public state: State = {
    identity: null /* Will hold the fake name assigned to the client. The name is generated by faker on the server */,
    roomName: '' /* Will store the room name */,
    roomNameErr: false /* Track error for room name TextField. This will    enable us to show an error message when this variable is true */,
    localMediaAvailable: false /* Represents the availability of a LocalAudioTrack(microphone) and a LocalVideoTrack(camera) */,
    hasJoinedRoom: false,
    activeRoom: null, // Track the current active room
    isLocalVideoEnabled: true,
    isLocalAudioEnabled: true,
    timerIsOn: false,
    timer: 0,
    startTimer: 0,
    handleFreecall: false,
    participantHasConnectedOnce: false,
    isRemoteAudioEnabled: true,
    isRemoteVideoEnabled: true,
    isRemoteParticipantReconnecting: false,
    isRemoteParticipantDisconnected: false,
    isScreenZoomInActivated: false,
    callInfoDetailsIndex: 0,
    showEndCallModal: false,
    customerFirstName: this.props.data.getVideoCallCredentials?.customerDescription?.firstName
      ? this.props.data.getVideoCallCredentials.customerDescription.firstName
      : '',
    customerLastName: this.props.data.getVideoCallCredentials?.customerDescription?.lastName
      ? this.props.data.getVideoCallCredentials.customerDescription.lastName
      : '',
    customerPhoneNumber: this.props.data.getVideoCallCredentials?.customerDescription?.phoneNumber
      ? this.props.data.getVideoCallCredentials.customerDescription.phoneNumber
      : '',
    isCameraDisabled: false,
    cameraErrorMsg: '',
  };

  handleRoomNameChange = (e: any) => {
    /* Fetch room name from text field and update state */
    let roomName = e.target.value;
    this.setState({ roomName });
  };

  public participantTimeout: any;

  joinRoom = (accessToken: string, roomName: string) => {
    /* 
    Show an error message on room name text field if user tries         joining a room without providing a room name. This is enabled by setting `roomNameErr` to true
      */
    if (!roomName.trim()) {
      this.setState({ roomNameErr: true });
      return;
    }

    console.log("Joining room '" + roomName + "'...");

    //both var needed outside for typescript
    let preferredVideoCodecs: VP8CodecSettings[] = [{ codec: 'VP8', simulcast: true }];
    let logLevel: LogLevel = 'debug';

    let connectOptions = {
      name: roomName,
      logLevel: logLevel,
      audio: true,
      maxAudioBitrate: 16000, //For music remove this line
      video: { height: 720, frameRate: 24, width: 1280 },
      // not used in peer to peer room or if track priority isn't set
      // bandwidthProfile: {
      //   video: {
      //     renderDimensions: {
      //       low: getResolution('low'),
      //       standard: getResolution('960p'),
      //       high: getResolution('wide1080p'),
      //     },
      //     maxTracks: 10,
      //   },
      // },
      preferredVideoCodecs: preferredVideoCodecs,
      //video: { height: 480, frameRate: 24, width: 640 } for mobile browser
    };

    let connectOptionsNoCamera = {
      name: roomName,
      logLevel: logLevel,
      audio: true,
      maxAudioBitrate: 16000, //For music remove this line
      video: false,
      preferredVideoCodecs: preferredVideoCodecs,
    };

    /* 
    Connect to a room by providing the token and connection options that include the room name and tracks. We also show an alert if an error occurs while connecting to the room.    
    */

    navigator.mediaDevices.getUserMedia({ video: true })
    .then(stream => {
      // Camera is available and accessible
      this.setState({ isCameraDisabled: false });
      Video.connect(accessToken, connectOptions).then(this.roomJoined, (error: any) => {
        alert('Could not connect to Twilio: ' + error.message);
        Sentry.captureMessage(error);
      });
    })
    .catch(error => {
      this.setState({ isCameraDisabled: true });
      if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
        this.setState({cameraErrorMsg: i18n.t('twilio.videoStatus.cameraNotFound')});
      } else if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
        this.setState({cameraErrorMsg: i18n.t('twilio.videoStatus.cameraNotAccess')});
      } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
        this.setState({cameraErrorMsg: i18n.t('twilio.videoStatus.cameraFailure')});
      } else {
        this.setState({cameraErrorMsg: i18n.t('twilio.videoStatus.cameraNotFound')});
      }
      Video.connect(accessToken, connectOptionsNoCamera).then(this.roomJoined, (error: any) => {
        alert('Could not connect to Twilio: ' + error.message);
        Sentry.captureMessage(error);
      });
    }); 
  };

  attachTrack = (track: any, container: any) => {
    container.appendChild(track.attach());
  };
  // Attach the Tracks to the DOM.
  attachTracks = (tracks: any, container: any) => {
    tracks.forEach((track: any) => {
      this.attachTrack(track, container);
    });
  };

  // Attach the Participant's Tracks to the DOM.
  attachParticipantTracks = (participant: any, container: any) => {
    var tracks = getTracks(participant);
    this.attachTracks(tracks, container);
  };

  detachTrack = (track: any) => {
    if (track.kind !== 'data')
      // DATA tracks are undefined since appMobile react-native-twilio-video-webrtc update from 1.0.2 to 1.0.2-1
      track.detach().forEach((detachedElement: any) => {
        detachedElement.remove();
      });
  };

  detachTracks = (tracks: any) => {
    tracks.forEach(this.detachTrack);
  };

  detachParticipantTracks = (participant: any) => {
    var tracks = getTracks(participant);
    this.detachTracks(tracks);
  };

  // Successfully connected!
  roomJoined = (room: any) => {
    // Called when a participant joins a room
    console.log("Joined as '" + this.state.identity + "'");
    this.startTimer();
    this.setState({
      activeRoom: room,
      localMediaAvailable: true,
      hasJoinedRoom: true, // Removes ‘Join Room’ button and shows ‘Leave Room’
    });
    localStorage.setItem('callInProgress', 'true');

    // Attach LocalParticipant's tracks to the DOM, if not already attached.
    var previewContainer = this.refs.localMedia;
    // console.log('LOCALMEDIA = ', this.refs.localMedia);
    // console.log('preview container= ', previewContainer);
    //@ts-ignore
    if (!previewContainer.querySelector('video')) {
      this.attachParticipantTracks(room.localParticipant, previewContainer);
    }

    // Attach the Tracks of the Room's Participants.
    var remoteMediaContainer = this.refs.remoteMedia;
    // @ts-ignore
    room.participants.forEach((participant: any) => {
      this.participantConnected(participant, remoteMediaContainer);
    });

    // When a Participant joins the Room, log the event.
    room.on('participantConnected', (participant: any) => {
      this.participantConnected(participant, remoteMediaContainer);
      if (!this.state.participantHasConnectedOnce) {
        localStorage.setItem('participantHasConnectedOnce', 'true');
        this.setState({ participantHasConnectedOnce: true });
      }
    });

    // When a Participant leaves the Room, detach its Tracks.
    room.on('participantDisconnected', async (participant: any) => {
      this.detachParticipantTracks(participant);
      this.setState({
        isRemoteParticipantDisconnected: true,
        isRemoteParticipantReconnecting: false,
      });
      localStorage.setItem('isRemoteParticipantDisconnected', 'true');
      
      // New condition, when Pet Owner ends current call, this ends for both participants
      try {
        await this.props.client.mutate({
          mutation: endCallFromVeterinarian,
          variables: {
            videoCallId: this.props.location.state.videoCallId,
          },
        });
      } catch (error) {
        Sentry.captureMessage(error);
      }
    });

    // Once the local participant leaves the room, detach the Tracks
    // of all other participants, including that of the LocalParticipant.
    room.on('disconnected', () => {
      if (this.participantTimeout) {
        clearTimeout(this.participantTimeout);
        this.participantTimeout = null;
      }
      this.detachParticipantTracks(room.localParticipant);
      room.participants.forEach(this.detachParticipantTracks);
      this.stopTimer();
      if (this.state.participantHasConnectedOnce) {
        // this.props.data.getVideoCallCredentials &&
        //   window.alert(
        //     i18n.t('twilio.deconnexionAlert', {
        //       petName: this.props.location.state.petName,
        //       customerPhoneNumber: this.props.data.getVideoCallCredentials.customerDescription
        //         .phoneNumber,
        //     })
        //   );

        this.setState({
          activeRoom: undefined,
          hasJoinedRoom: false,
          localMediaAvailable: false,
          showEndCallModal: true,
          // handleFreecall: this.props.location.state.isFreecall ? true : false,
        });

        localStorage.setItem('showEndCallModal', 'true');
        // store customer info
        if (this.props.data.getVideoCallCredentials?.customerDescription?.firstName)
          localStorage.setItem(
            'customerFirstName',
            this.props.data.getVideoCallCredentials?.customerDescription?.firstName
          );
        if (this.props.data.getVideoCallCredentials?.customerDescription?.lastName)
          localStorage.setItem(
            'customerLastName',
            this.props.data.getVideoCallCredentials?.customerDescription?.lastName
          );
        if (this.props.data.getVideoCallCredentials?.customerDescription?.phoneNumber)
          localStorage.setItem(
            'customerPhoneNumber',
            this.props.data.getVideoCallCredentials?.customerDescription?.phoneNumber
          );
        // localStorage.removeItem('participantHasConnectedOnce');
        // localStorage.removeItem('isRemoteParticipantDisconnected');

        // this.props.history.goBack();
        //test refresh without goback
      }
    });

    if (!this.state.participantHasConnectedOnce && !this.state.isRemoteParticipantDisconnected)
      this.participantTimeout = setTimeout(async () => {
        try {
          await this.props.client.mutate({
            mutation: endCallWithIssueFromVeterinarian,
            variables: {
              videoCallId: this.props.location.state.videoCallId,
            },
          });
          this.setState({
            activeRoom: undefined,
            hasJoinedRoom: false,
            localMediaAvailable: false,
            // handleFreecall: this.props.location.state.isFreecall ? true : false,
          });
          localStorage.removeItem('participantHasConnectedOnce');
          localStorage.removeItem('isRemoteParticipantDisconnected');
          localStorage.removeItem('callInProgress');
          this.props.history.goBack();
        } catch (error) {
          Sentry.captureMessage(error);
        }
        localStorage.setItem('callInProgress', 'false');
        window.alert(
          "Il semblerait que l'utilisateur ait rencontré un problème technique et ne parvienne pas à se connecter. L'appel a donc été annulé, l'équipe technique va procéder à une investigation."
        );
      }, 20000);
  };

  trackPublished = (publication: any, container: any) => {
    if (publication.isSubscribed) {
      this.attachTrack(publication.track, container);
    }
    publication.on('subscribed', (track: any) => {
      console.log('Subscribed to ' + publication.kind + ' track = ' + track);
      this.attachTrack(track, container);
    });
    publication.on('unsubscribed', this.detachTrack);
  };

  // A RemoteTrack was unpublished from the Room.
  trackUnpublished = (publication: any) => {
    // console.log(publication.kind + ' track was unpublished.');
    if (publication.kind === 'audio') {
      //reset so it won't show on screen when no participant
      this.setState({ isRemoteAudioEnabled: true });
      localStorage.setItem('isRemoteAudioEnabled', 'true');
    } else if (publication.kind === 'video') {
      this.setState({ isRemoteVideoEnabled: true });
      localStorage.setItem('isRemoteVideoEnabled', 'true');
    }
  };

  // A new RemoteParticipant joined the Room
  participantConnected = (participant: any, container: any) => {
    if (!this.state.participantHasConnectedOnce) {
      localStorage.setItem('participantHasConnectedOnce', 'true');
      this.setState({ participantHasConnectedOnce: true });
    }
    if (this.participantTimeout) {
      clearTimeout(this.participantTimeout);
      this.participantTimeout = null;
    }
    participant.tracks.forEach((publication: any) => {
      if (publication.kind !== 'data')
        // DATA tracks are undefined since appMobile react-native-twilio-video-webrtc update from 1.0.2 to 1.0.2-1
        this.trackPublished(publication, container);
    });
    participant.on('trackPublished', (publication: any) => {
      if (publication.kind !== 'data')
        // DATA tracks are undefined since appMobile react-native-twilio-video-webrtc update from 1.0.2 to 1.0.2-1
        this.trackPublished(publication, container);
    });
    participant.on('trackUnpublished', this.trackUnpublished);

    participant.on('trackDisabled', (publication: any) => {
      if (publication.kind === 'audio') {
        this.setState({ isRemoteAudioEnabled: false });
        localStorage.setItem('isRemoteAudioEnabled', 'false');
      } else if (publication.kind === 'video') {
        this.setState({ isRemoteVideoEnabled: false });
        localStorage.setItem('isRemoteVideoEnabled', 'false');
      }
    });

    participant.on('trackEnabled', (publication: any) => {
      if (publication.kind === 'audio') {
        this.setState({ isRemoteAudioEnabled: true });
        localStorage.setItem('isRemoteAudioEnabled', 'true');
      } else if (publication.kind === 'video') {
        this.setState({ isRemoteVideoEnabled: true });
        localStorage.setItem('isRemoteVideoEnabled', 'true');
      }
    });

    participant.on('reconnecting', () => {
      this.setState({ isRemoteParticipantReconnecting: true });
      localStorage.setItem('participantHasConnectedOnce', 'true');
    });

    participant.on('reconnected', () => {
      this.setState({ isRemoteParticipantReconnecting: false });
      localStorage.setItem('participantHasConnectedOnce', 'false');
    });
  };

  // // When we are about to transition away from this page, disconnect
  // // from the room, if joined.
  // window.addEventListener('beforeunload', leaveRoomIfJoined);

  private onEndButtonPress = async () => {
    if (window.confirm(i18n.t('twilio.hangUpModalMessage'))) {
      try {
        await this.props.client.mutate({
          mutation: endCallFromVeterinarian,
          variables: {
            videoCallId: this.props.location.state.videoCallId,
          },
        });
      } catch (error) {
        Sentry.captureMessage(error);
      }
    }
  };

  private toggleLocalVideo = () => {
    if (!this.state.activeRoom) return;
    const localVideoTracks = this.state.activeRoom.localParticipant.videoTracks;
    localVideoTracks.forEach((publication: any) => {
      const track = publication.track;
      if (this.state.isLocalVideoEnabled) {
        track.disable();
      } else {
        track.enable();
      }
    });
    this.setState((state) => ({ isLocalVideoEnabled: !state.isLocalVideoEnabled }));
  };

  private toggleLocalAudio = () => {
    if (!this.state.activeRoom) return;
    const localAudioTracks = this.state.activeRoom.localParticipant.audioTracks;
    localAudioTracks.forEach((publication: any) => {
      const track = publication.track;
      if (this.state.isLocalAudioEnabled) {
        track.disable();
      } else {
        track.enable();
      }
    });
    this.setState((state) => ({ isLocalAudioEnabled: !state.isLocalAudioEnabled }));
  };

  private getCallType(type: boolean) {
    switch (type) {
      case true:
        return i18n.t('twilio.callInfoDetails.freeCall');
      case false:
        return i18n.t('twilio.callInfoDetails.paidCall');
      default:
        return '';
    }
  }

  public timer: number = 0;

  startTimer() {
    this.setState({
      timer: this.state.timer,
      startTimer: Date.now() - this.state.timer,
      timerIsOn: true,
    });
    this.timer = setInterval(
      () =>
        this.setState({
          timer: Date.now() - this.state.startTimer,
        }),
      1
    );
  }

  stopTimer() {
    this.setState({ timerIsOn: false });
    clearInterval(this.timer);
  }

  formatTimer() {
    const min = Math.floor((this.state.timer / (1000 * 60)) % 60);
    const sec = Math.floor((this.state.timer / 1000) % 60);
    if (min < 1) {
      return sec.toFixed().toString() + '\u00a0secondes';
    }
    return min.toFixed().toString() + '\u00a0Min\u00a0' + sec.toString() + '\u00a0secondes';
  }

  private getPetSpecie(
    petCategory?: string,
    specie?: string
  ): { label: string; value: string; emoji: string } | null {
    if (petCategory && petCategory !== 'NAC') {
      const res = raceIdItems.filter((e) => e.value === petCategory);
      if (!res.length) return null;
      return res[0];
    }
    if (!specie) return null;
    const res = raceIdItems.filter((e) => e.value === specie);
    if (!res.length) return null;
    return res[0];
  }

  private genderCustomerEmoji(gender?: string) {
    if (gender === 'Madame') {
      return '👩🏼';
    } else if (gender === 'Monsieur') {
      return '👨🏻';
    } else {
      return '🧑🏼';
    }
  }

  private getPetAge(birthDate: Date) {
    const date = new Date(birthDate);
    const year = differenceInYears(date, Date.now()) * -1;
    if (year < 1) return differenceInMonths(date, Date.now()) * -1 + ' mois';
    return year + (year === 1 ? ' an' : ' ans');
  }

  private getCallTarification(tarification?: TarificationProps) {
    if (tarification === 'regular') {
      return i18n.t('twilio.callInfoDetails.regularTarification');
    } else if (tarification === 'slightlyIncreased') {
      return i18n.t('twilio.callInfoDetails.slightlyIncreasedTarification');
    } else if (tarification === 'increased') {
      return i18n.t('twilio.callInfoDetails.increasedTarification');
    } else {
      return i18n.t('twilio.callInfoDetails.unknown');
    }
  }

  private getCallPrice(price?: string) {
    if (!price) {
      return '';
    }
    return (parseInt(price) / 100).toString().replace('.', ',') + '€';
  }

  renderTwilioVideo() {
    /* 
     Controls showing of the local track
     Only show video track after user has joined a room else show nothing 
    */
    let showLocalTrack = this.state.localMediaAvailable ? (
      // @ts-ignore
      <LocalMedia ref={'localMedia'} id="local-media" />
    ) : (
      ''
    );

    const { firstName, lastName, phoneNumber, email, gender, city, country } =
      this.props.data.getVideoCallCredentials?.customerDescription || {};
    const { name, petCategory, specie, race, birthDate, isFemale, isSterilized } =
      this.props.data.getVideoCallCredentials?.customerPet || {};

    const petSpecie = this.getPetSpecie(petCategory, specie);
    return (
      <>
        {/* <NavBarMaster /> */}
        {/* <SubNavBar /> */}
        <MasterBody noNavBar={true}>
          <MasterContainer noSideMenu={true}>
            <PageContainer>
              <BodyContainer>
                <VideoContainer>
                  <VideoHeaderTextContainer>
                    <VideoHeaderTitle>{i18n.t('twilio.callHeader')}</VideoHeaderTitle>
                    <VideoHeaderTimer>{this.formatTimer()}</VideoHeaderTimer>
                  </VideoHeaderTextContainer>
                  <VideoStatusContainer>
                    {this.state.isRemoteParticipantReconnecting && (
                      <VideoStatusTextContainer>
                        <Loader
                          style={{ marginBottom: 20, alignSelf: 'center' }}
                          inline
                          active
                          size="massive"
                        />
                        <VideoStatusText>
                          {i18n.t('twilio.videoStatus.tryToReconnect')}
                        </VideoStatusText>
                      </VideoStatusTextContainer>
                    )}
                    {/* 
                  // @ts-ignore */}
                    {this.participantTimeout && !this.state.participantHasConnectedOnce && (
                      <VideoStatusTextContainer>
                        <Loader
                          style={{ marginBottom: 10, alignSelf: 'center' }}
                          inline
                          active
                          size="massive"
                        />
                        <VideoStatusText>
                          {i18n.t('twilio.videoStatus.reconnecting')}
                        </VideoStatusText>
                      </VideoStatusTextContainer>
                    )}

                    {(!this.state.isRemoteVideoEnabled || !this.state.isRemoteAudioEnabled) && (
                      <VideoStatusTextContainer>
                        {!this.state.isRemoteVideoEnabled && (
                          <VideoStatusText>
                            {i18n.t('twilio.videoStatus.videoDisabled')}
                          </VideoStatusText>
                        )}
                        {!this.state.isRemoteAudioEnabled && (
                          <VideoStatusText>
                            {i18n.t('twilio.videoStatus.audioDisabled')}
                          </VideoStatusText>
                        )}
                      </VideoStatusTextContainer>
                    )}

                    {this.state.isRemoteParticipantDisconnected &&
                      this.state.participantHasConnectedOnce && (
                        <VideoStatusTextContainer>
                          <VideoStatusText>
                            {i18n.t('twilio.videoStatus.disconnect')}
                          </VideoStatusText>
                        </VideoStatusTextContainer>
                      )}
                  </VideoStatusContainer>

                  <RemoteMedia
                    className="flex-item"
                    ref="remoteMedia"
                    sid="remote-media"
                    fullScreen={this.state.isScreenZoomInActivated}
                  />
                  <LocalMediaContainer>
                    <LocalMediaContainerMobile>{`${this.state.isCameraDisabled ? this.state.cameraErrorMsg : ''}`} {showLocalTrack}</LocalMediaContainerMobile>
                  </LocalMediaContainer>
                  <CallActionButtonsContainer>
                    <CallActionButton
                      color={
                        this.state.isLocalVideoEnabled
                          ? theme.colors.white.pure
                          : theme.colors.primary.mainColor
                      }
                      onClick={this.toggleLocalVideo}
                      icon={this.state.isLocalVideoEnabled ? CameraIcon : CameraOffIcon}
                      iconColor={
                        this.state.isLocalVideoEnabled
                          ? theme.colors.primary.mainColor
                          : theme.colors.white.pure
                      }
                    />
                    <CallActionButton
                      color={
                        this.state.isLocalAudioEnabled
                          ? theme.colors.white.pure
                          : theme.colors.primary.mainColor
                      }
                      onClick={this.toggleLocalAudio}
                      icon={this.state.isLocalAudioEnabled ? MicroIcon : MicroOffIcon}
                      iconColor={
                        this.state.isLocalAudioEnabled
                          ? theme.colors.primary.mainColor
                          : theme.colors.white.pure
                      }
                    />
                    <CallActionButton
                      color={theme.colors.red.dark}
                      onClick={
                        this.state.participantHasConnectedOnce ? this.onEndButtonPress : () => null
                      }
                      icon={HangUpIcon}
                    />
                  </CallActionButtonsContainer>
                  <ResizeVideoButton>
                    <CallActionButton
                      color={
                        this.state.isScreenZoomInActivated
                          ? theme.colors.primary.mainColor
                          : theme.colors.white.pure
                      }
                      onClick={() =>
                        this.setState({
                          isScreenZoomInActivated: !this.state.isScreenZoomInActivated,
                        })
                      }
                      icon={
                        this.state.isScreenZoomInActivated
                          ? VisioIconScreenZoomOut
                          : VisioIconScreenZoomIn
                      }
                      iconColor={
                        this.state.isScreenZoomInActivated
                          ? theme.colors.white.pure
                          : theme.colors.primary.mainColor
                      }
                    />
                  </ResizeVideoButton>
                </VideoContainer>
                <VideoInfoContainer>
                  <VideoInfoTabsContainer>
                    {tabItems.map((item, i) => (
                      <TabItem
                        onClick={() => this.setState({ callInfoDetailsIndex: i })}
                        selected={this.state.callInfoDetailsIndex === i}
                        key={i}
                      >
                        {i18n.t(item)}
                      </TabItem>
                    ))}
                  </VideoInfoTabsContainer>
                  <VideoInfoBodyContainer fluid>
                    {this.state.callInfoDetailsIndex === 0 && (
                      <CallInfoDetailsRow>
                        <CallInfoDetailsBoxContainer md={4}>
                          <CallInfoDetailsBox
                            title={`${petSpecie ? petSpecie.emoji : ''} ${i18n.t(
                              'twilio.callInfoDetails.pet'
                            )}`}
                            data={[
                              {
                                label: i18n.t('twilio.callInfoDetails.petName'),
                                value: `${name}`,
                              },
                              {
                                label: i18n.t('twilio.callInfoDetails.specieAndRace'),
                                value: `${
                                  petSpecie
                                    ? petSpecie.label
                                    : i18n.t('twilio.callInfoDetails.unknown')
                                } / ${race ? race : i18n.t('twilio.callInfoDetails.unknown')}`,
                              },
                              {
                                label: i18n.t('twilio.callInfoDetails.ageAndSex'),
                                value: `${
                                  birthDate
                                    ? this.getPetAge(birthDate)
                                    : i18n.t('twilio.callInfoDetails.unknown')
                                } / ${
                                  isFemale
                                    ? i18n.t('twilio.callInfoDetails.female')
                                    : i18n.t('twilio.callInfoDetails.male')
                                }`,
                              },
                              {
                                label: i18n.t('twilio.callInfoDetails.sterilized'),
                                value: `${
                                  isSterilized
                                    ? i18n.t('twilio.callInfoDetails.yes')
                                    : i18n.t('twilio.callInfoDetails.no')
                                }`,
                              },
                            ]}
                          />
                        </CallInfoDetailsBoxContainer>
                        <CallInfoDetailsBoxContainer md={4}>
                          <CallInfoDetailsBox
                            title={`${this.genderCustomerEmoji(gender)} ${i18n.t(
                              'twilio.callInfoDetails.owner'
                            )}`}
                            data={[
                              {
                                label: i18n.t('twilio.callInfoDetails.firstNameAndLastName'),
                                value: `${firstName} ${lastName}`,
                              },
                              {
                                label: i18n.t('twilio.callInfoDetails.phoneNumber'),
                                value: `${phoneNumber}`,
                              },
                              { label: i18n.t('twilio.callInfoDetails.email'), value: `${email}` },
                              {
                                label: i18n.t('twilio.callInfoDetails.cityAndCountry'),
                                value: `${city} / ${country}`,
                              },
                            ]}
                          />
                        </CallInfoDetailsBoxContainer>
                        <CallInfoDetailsBoxContainer md={4}>
                          <CallInfoDetailsBox
                            title={i18n.t('twilio.callInfoDetails.infos')}
                            data={[
                              {
                                label: i18n.t('twilio.callInfoDetails.callPurpose'),
                                value: i18n.t(
                                  'videoCallHandler.videoCallRequest.petTrouble.' +
                                    this.props.location.state.petTrouble
                                ),
                              },
                              {
                                label: this.getCallTarification(
                                  this.props.data.getVideoCallCredentials?.tarification
                                ),
                                value: this.getCallPrice(
                                  this.props.data.getVideoCallCredentials?.price
                                ),
                              },
                              {
                                label: i18n.t('twilio.callInfoDetails.callType'),
                                value: this.getCallType(this.props.location.state.isFreecall),
                              },
                              {
                                label: i18n.t('twilio.callInfoDetails.callDuration'),
                                value: this.formatTimer(),
                              },
                            ]}
                          />
                        </CallInfoDetailsBoxContainer>
                      </CallInfoDetailsRow>
                    )}
                    <PicturesContainer hidden={this.state.callInfoDetailsIndex !== 1}>
                      <PicturesList
                        videoCallId={this.props.location.state.videoCallId}
                        petName={this.props.location.state.petName}
                      />
                    </PicturesContainer>
                    {/* {this.state.callInfoDetailsIndex === 2 && (
                      <AnimalCardContainer></AnimalCardContainer>
                    )} */}
                  </VideoInfoBodyContainer>
                </VideoInfoContainer>
              </BodyContainer>
            </PageContainer>
            <ModalCmv
              show={this.state.showEndCallModal}
              onClickOutsideCloseModal={() => null}
              headerTitle={i18n.t('surveyEndCall.headerTitle')}
              // headerLabelRight={i18n.t('surveyEndCall.headerLabelRight')}
              // headerIconRight={IconInfoHollow}
              contentViewPreTitle={i18n.t('surveyEndCall.preTitle')}
              contentViewMainTitle={i18n.t('surveyEndCall.mainTitle')}
              contentViewBodyContent={i18n.t('surveyEndCall.body', {
                ownerName: `${firstName}  ${lastName}`,
                phoneNumber: phoneNumber,
              })}
              contentViewCustomComponent={
                <SurveyEndCall
                  {...this.props}
                  videoCallId={this.props.location.state.videoCallId}
                />
              }
            />
          </MasterContainer>
        </MasterBody>
      </>
    );
  }

  componentDidMount() {
    // todo try to use json stringify and parse to handle these ?
    const callInProgress = localStorage.getItem('callInProgress');
    const participantHasConnectedOnce = localStorage.getItem('participantHasConnectedOnce');
    const isRemoteParticipantDisconnected = localStorage.getItem('isRemoteParticipantDisconnected');
    const isRemoteParticipantReconnecting = localStorage.getItem('isRemoteParticipantReconnecting');
    const isRemoteAudioEnabled = localStorage.getItem('isRemoteAudioEnabled');
    const isRemoteVideoEnabled = localStorage.getItem('isRemoteVideoEnabled');
    const showEndCallModal = localStorage.getItem('showEndCallModal');
    const customerFirstName = localStorage.getItem('customerFirstName');
    const customerLastName = localStorage.getItem('customerLastName');
    const customerPhoneNumber = localStorage.getItem('customerPhoneNumber');
    if (callInProgress === 'true') {
      if (participantHasConnectedOnce === 'true') {
        this.setState({ participantHasConnectedOnce: true });
      }
      if (isRemoteParticipantDisconnected === 'true') {
        this.setState({ isRemoteParticipantDisconnected: true });
      }
      if (isRemoteParticipantReconnecting === 'true') {
        this.setState({ isRemoteParticipantReconnecting: true });
      }
      if (isRemoteAudioEnabled === 'false') {
        this.setState({ isRemoteAudioEnabled: false });
      }
      if (isRemoteVideoEnabled === 'false') {
        this.setState({ isRemoteVideoEnabled: false });
      }
      if (showEndCallModal === 'true') {
        this.setState({ showEndCallModal: true });
      }
      if (customerFirstName) {
        this.setState({ customerFirstName });
      }
      if (customerLastName) {
        this.setState({ customerLastName });
      }
      if (customerPhoneNumber) {
        this.setState({ customerPhoneNumber });
      }
    } else {
      localStorage.removeItem('participantHasConnectedOnce');
      localStorage.removeItem('isRemoteParticipantDisconnected');
      localStorage.removeItem('isRemoteParticipantReconnecting');
      localStorage.removeItem('isRemoteAudioEnabled');
      localStorage.removeItem('isRemoteVideoEnabled');
      localStorage.removeItem('showEndCallModal');
      localStorage.removeItem('customerFirstName');
      localStorage.removeItem('customerLastName');
      localStorage.removeItem('customerPhoneNumber');
    }

    // if we update that it will constent refresh the page,
    // we may want to change the way we handle that to rerender only timer
    //if (!showEndCallModal) this.startTimer();

    window.addEventListener('beforeunload', () => {
      this.state.activeRoom.disconnect(true);
    });
  }

  componentWillUnmount() {
    this.stopTimer();
    window.removeEventListener('beforeunload', () => {
      this.state.activeRoom.disconnect(true);
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.data.getVideoCallCredentials && this.props.data.getVideoCallCredentials) {
      this.joinRoom(
        this.props.data.getVideoCallCredentials.twilioAccessToken,
        this.props.data.getVideoCallCredentials.twilioRoomName
      );
    }
  }

  async handleFreecallValidation(isValidated: boolean) {
    try {
      await this.props.client.mutate({
        mutation: handleFreecallValidationByVeterinarian,
        variables: {
          // videoCallId: this.props.location.state.videoCallId,
          isValidated,
        },
      });
      this.props.history.goBack();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    // if (this.state.handleFreecall) {
    //   return (
    //     <div>
    //       <div>HANDLE FREECALL</div>
    //       <ButtonsContainer>
    //         <PrimaryButton
    //           label={'VALIDATE'}
    //           onClick={() => this.handleFreecallValidation(true)}
    //         ></PrimaryButton>
    //         <br />
    //         <PrimaryButton
    //           label={'REFUND'}
    //           onClick={() => this.handleFreecallValidation(false)}
    //         ></PrimaryButton>
    //       </ButtonsContainer>
    //     </div>
    //   );
    // }

    const { customerFirstName, customerLastName, customerPhoneNumber } = this.state;

    if (this.props.data.isLoading) {
      return <div>LOADING</div>;
    }
    if (this.state.showEndCallModal && !this.props.data.getVideoCallCredentials) {
      return (
        <ModalCmv
          show={this.state.showEndCallModal}
          onClickOutsideCloseModal={() => null}
          headerTitle={i18n.t('surveyEndCall.headerTitle')}
          // headerLabelRight={i18n.t('surveyEndCall.headerLabelRight')}
          // headerIconRight={IconInfoHollow}
          contentViewPreTitle={i18n.t('surveyEndCall.preTitle')}
          contentViewMainTitle={i18n.t('surveyEndCall.mainTitle')}
          contentViewBodyContent={i18n.t('surveyEndCall.body', {
            ownerName: `${customerFirstName}  ${customerLastName}`,
            phoneNumber: customerPhoneNumber,
          })}
          contentViewCustomComponent={
            <SurveyEndCall {...this.props} videoCallId={this.props.location.state.videoCallId} onSubmit={() => localStorage.setItem('callInProgress', 'false')} />
          }
        />
      );
    }
    if (!this.props.data.getVideoCallCredentials) {
      return <div>ERROR</div>;
    }

    return this.renderTwilioVideo();
  }
}

// const ButtonsContainer = styled.div`
//   margin-top: ${(props) => props.theme.spacing.unit * 4}px;
//   display: flex;
//   flex-direction: column;
// `;

// const Header = styled.div`
//   background-color: ${(props) => props.theme.colors.white.pure};
// `;

// const Image = styled.img`
//   margin-left: ${(props) => props.theme.spacing.unit * 7}px;
//   margin-top: ${(props) => props.theme.spacing.unit}px;
//   margin-bottom: ${(props) => props.theme.spacing.unit}px;
// `;

const BodyContainer = styled.div`
  height: 100%;
  display: flex;
  background-color: ${(props) => props.theme.colors.white.darker};
  flex-direction: column;
  align-items: center;
`;

const PageContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const VideoContainer = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.black.pure};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    position: relative;
    padding-top: 100%;
    /* padding-top: 75%; 4:3 */
    /* padding-top: 56.25%%; 16:9 */
    height: 0;
    width: 100%;
  }

  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMin}) and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    width: 100%;
    height: 320px;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) and (max-width: ${(
      props
    ) => props.theme.breakpoints.tabletMax}) {
    width: 680px;
    height: 480px;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.laptop13Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: 960px;
    height: 560px;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.laptop15Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.laptop15Max}) {
    width: 1100px;
    height: 640px;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.desktop21Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.desktop21Max}) {
    width: 1280px;
    height: 720px;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop27Min}) {
    width: 1400px;
    height: 840px;
  }
`;

const VideoInfoContainer = styled.div`
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    width: 100%;
  }

  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMin}) and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    width: 100%;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) and (max-width: ${(
      props
    ) => props.theme.breakpoints.tabletMax}) {
    width: 680px;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.laptop13Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: 960px;
  }

  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.laptop15Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.laptop15Max}) {
    width: 1100px;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.desktop21Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.desktop21Max}) {
    width: 1280px;
  }
  @media screen and(min-width: ${(props) => props.theme.breakpoints.desktop27Min}) {
    width: 1400px;
  }
`;

const VideoInfoTabsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white.pure};
  display: flex;
  overflow-x: auto;
  width: 100%;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  && {
    ::-webkit-scrollbar {
      width: 0px !important; /* For Chrome, Safari, and Opera */
      display: none;
    }
  }
`;

const TabItem = styled.div<{ selected: boolean }>`
  font-size: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontWeight};
  letter-spacing: ${(props) =>
    props.theme.textStyles.typography.lead.capitalize.regular.letterSpacing};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.grey.dark};
  padding: 20px 5px;
  margin: 0px 20px;
  white-space: nowrap;
  border-bottom: ${(props) =>
    props.selected ? `2px solid ${props.theme.colors.blue.main}` : `none`};
  cursor: pointer;
  user-select: none;
`;

const VideoStatusContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
`;

const VideoHeaderTextContainer = styled.div`
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 10000;
`;

const VideoHeaderTitle = styled.div`
  color: ${(props) => props.theme.colors.white.pure};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.main.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.lead.main.fontSize};
  text-transform: uppercase;
`;

const VideoHeaderTimer = styled.div`
  color: ${(props) => props.theme.colors.grey.darker};
  padding-top: 5px;
  padding-left: 21px;
`;

const VideoStatusTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const VideoStatusText = styled.span`
  font-weight: ${(props) => props.theme.textStyles.typography.lead.capitalize.bold.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.lead.capitalize.bold.fontSize};
  letter-spacing: ${(props) =>
    props.theme.textStyles.typography.lead.capitalize.bold.letterSpacing};
  color: ${(props) => props.theme.colors.white.pure};
  text-align: center;
  margin: 5px 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    font-size: 2.5vw;
  }
`;

const CallActionButtonsContainer = styled.div`
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    justify-content: flex-start;
    padding-left: 15px;
  }
`;

const LocalMediaContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white.pure};
  border: 5px solid ${(props) => props.theme.colors.white.pure};
  border-radius: 10px;
  position: absolute;
  top: 15px;
  right: 15px;
  height: fit-content;
  width: 20%;
  min-width: 80px;
  overflow: hidden;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    min-width: none !important;
    height: auto;
  }
`;

const LocalMediaContainerMobile = styled.div`
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative; /* If you want text inside of it */
  }
`;

const LocalMedia = styled.div`
  background-color: ${(props) => props.theme.colors.white.pure};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.mobileLandscapeMin}) {
    height: 100%;

    video {
      width: 100%;
      height: auto;
      min-width: 100px;
      display: block;
    }
  }
`;

const RemoteMedia = styled.div<{ fullScreen: boolean }>`
  display: flex;
  height: 100% !important;
  justify-content: center;
  video {
    width: 100%;
    height: 100% !important;
    object-fit: ${(props) => (props.fullScreen ? 'cover' : 'contain')};
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
  }
`;

const ResizeVideoButton = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
`;

const VideoInfoBodyContainer = styled(Container)`
  background-color: ${(props) => props.theme.colors.white.dark};
`;

const CallInfoDetailsRow = styled(Row)`
  padding: 10px 30px 80px 30px;
`;

const CallInfoDetailsBoxContainer = styled(Col)`
  padding: 20px 30px 0px 30px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tabletMax}) {
    padding: 20px 10px 0px 10px;
  }
`;

const PicturesContainer = styled.div<{ hidden: boolean }>`
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  width: 100%;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
`;

// const AnimalCardContainer = styled.div`
//   display: flex;
//   width: 100%;
//   height: 100%;
//   padding-top: 150px;
//   padding-bottom: 150px;
// `;
