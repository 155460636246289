import React, { Component } from 'react';
import { Props } from './ProfileAnimalsInCare.container';
import styled from 'styled-components';
import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { MasterBody } from '../../../components/Layout/MasterBody';
import { MasterContainer } from '../../../components/Layout/MasterContainer';
import { HeaderSectionForm } from '../../../components/Form/HeaderSectionForm';
import { i18n } from '../../../lib/i18n';
import { Formik, Field, Form } from 'formik';
import { MultipleCheckForm } from '../../../components/Form';
import { profileAnimalsInCareValidationSchema } from './ProfileAnimalsInCare.validation';
import { ButtonsForm } from '../../../components/Form/ButtonsForm';
import { petCategoryItems, speciesItems } from '../../../constants/petRaceAndSpecies';
import { loader } from 'graphql.macro';
import { appContext } from '../../../context/appContext';
// import { Veterinarian, Account } from '../../../types/DbInterface';

import { Loader } from '../../../components/Loader';
import { client } from '../../../graphql/client';
import { Account, Company, Veterinarian } from '../../../types/DbInterface';

const getProfessionalAccountWithRelations = loader('../../../graphql/getProfessionalAccountWithRelations.graphql');

const updateVeterinarianPetCategories = loader(
  '../../../graphql/updateVeterinarianPetCategories.graphql'
);

const petCategoryList = petCategoryItems.map((e) => {
  return { label: e.label, value: e.value, checked: false };
});
const speciesList = speciesItems.map((e) => {
  return { label: e.label, value: e.value, checked: false };
});

interface ValuesForm {
  petCategories: Array<{ label: string; value: string; checked: boolean }>;
  species: Array<{ label: string; value: string; checked: boolean }>;
}

export interface State {
  currentVet?: Veterinarian;
  loading?: boolean;
}

export class ProfileAnimalsInCare extends Component<Props> {
  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public state: State = {
    currentVet: undefined,
    loading: true,
  };

  public async componentDidMount() {
    try {
      const response = await client.query<{
        getProfessionalAccountWithRelations?: {
          account: Account;
          veterinarian?: Veterinarian;
          company?: Company;
          companyVeterinarians?: Veterinarian[];
        };
      }>({
        query: getProfessionalAccountWithRelations,
        fetchPolicy: 'network-only',
      });
      this.setState({
        currentVet: response.data.getProfessionalAccountWithRelations?.veterinarian, 
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  private onSubmit = async (values: ValuesForm) => {
    // console.log(values);

    let petCategories: Array<string> = [];
    values.petCategories.forEach((data) => {
      if (data.checked === true) {
        if (!petCategories) petCategories = [data.value];
        else petCategories.push(data.value);
      }
    });

    if (!petCategories) return; //ERROR

    let species: string[] | undefined = undefined;
    values.species.forEach((data) => {
      if (data.checked === true) {
        if (species === undefined) species = [data.value];
        else species.push(data.value);
      }
    });

    if (species) {
      petCategories.push('NAC');
    }

    // console.log(species);
    // console.log(petCategories);
    try {
      await this.props.client.mutate({
        mutation: updateVeterinarianPetCategories,
        variables: {
          petCategories,
          species,
        },
      });
      this.context.updateContext();
      this.props.history.goBack();
    } catch (error) {
      throw new Error(error);
    }
  };

  render() {
    const account = this.context.currentAccount;
    const currentVet = this.state.currentVet;
    let petCategories;
    let species;
    if (this.state.loading) {
      return <LoaderContainer><Loader inline="centered" active={true} size={'big'} /></LoaderContainer>;
    }

    if (currentVet) {
      if (currentVet.petCategories)
        petCategories = [
          ...petCategoryItems.map((e) => {
            return {
              label: e.label,
              value: e.value,
              checked: currentVet?.petCategories?.find((data) => data === e.value) ? true : false,
            };
          }),
        ];
      if (currentVet.petSpecies)
        species = [
          ...speciesItems.map((e) => {
            return {
              label: e.label,
              value: e.value,
              checked: currentVet?.petSpecies?.find((data) => data === e.value) ? true : false,
            };
          }),
        ];
    }
    return (
      <>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <MasterContainer noSideMenu={true}>
            <Container>
              <HeaderContainer>
                <Subtitle>
                  {i18n.t('profile.veterinarianProfile.animalsInCare.subtitle', {
                    firstName: account?.firstName ? account.firstName : '',
                    lastName: account?.lastName ? account.lastName : '',
                  })}
                </Subtitle>
                <Title>{i18n.t('profile.veterinarianProfile.animalsInCare.title')}</Title>
              </HeaderContainer>

              <SectionContainer>
                <HeaderSectionForm
                  title={i18n.t('profile.veterinarianProfile.animalsInCare.form.title')}
                  subtitle={i18n.t('profile.veterinarianProfile.animalsInCare.form.subtitle')}
                />

                <Formik
                  initialValues={{
                    petCategories: petCategories
                      ? petCategories
                      : [
                          ...petCategoryItems.map((e) => {
                            return { label: e.label, value: e.value, checked: false };
                          }),
                        ],
                    species: species
                      ? species
                      : [
                          ...speciesItems.map((e) => {
                            return { label: e.label, value: e.value, checked: false };
                          }),
                        ],
                  }}
                  onSubmit={this.onSubmit}
                  validationSchema={profileAnimalsInCareValidationSchema}
                >
                  {() => (
                    <ProfileAnimalsInCareForm>
                      <ProfileAnimalsInCareField>
                        <Field
                          name={'petCategories'}
                          component={MultipleCheckForm}
                          data={petCategoryList}
                        />
                        <Field name={'species'} component={MultipleCheckForm} data={speciesList} />
                      </ProfileAnimalsInCareField>
                      <ButtonsForm
                        cancelContent={i18n.t('profile.form.button.cancel')}
                        validateContent={i18n.t('profile.form.button.save')}
                        onCancel={() => this.props.history.goBack()}
                        type={'submit'}
                      />
                    </ProfileAnimalsInCareForm>
                  )}
                </Formik>
              </SectionContainer>
            </Container>
          </MasterContainer>
        </MasterBody>
      </>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.pure};
  padding-left: 10px;
  padding-right: 10px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 200px;
    padding-right: 200px;
  }
`;

const HeaderContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;

const Subtitle = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.body.small.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.body.small.fontSize};
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileAnimalsInCareForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 35px;
`;

const ProfileAnimalsInCareField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 0;
  }
`;

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;
