import React, { Component } from 'react';
import { Props } from './Teams.container';
import styled from 'styled-components';
import { NavBarMaster } from '../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../components/UiNavigation/SubNavBar';
import { MasterBody } from '../../components/Layout/MasterBody';
import { MasterContainer } from '../../components/Layout/MasterContainer';
import { MasterSideMenu } from '../../components/Layout/MasterSideMenu';
import { MainMenuConnected } from '../../components/UiNavigation/MainMenuMaster/MainMenuConnected';
import { HeaderSectionForm } from '../../components/Form/HeaderSectionForm';
import { i18n } from '../../lib/i18n';
import { CustomFlatList } from '../../components/CustomFlatList';
import { ReactComponent as IconChevronRight24x24 } from '../../theme/icons/Icon-ChevronRight-24x24.svg';
import { ReactComponent as IconCrossDelete } from '../../theme/icons/IconCrossDelete.svg';
import { ReactComponent as IconThreeDots24x24 } from '../../theme/icons/Icon-ThreeDots-24x24.svg';
import { ReactComponent as IconGreenCheck24x24 } from '../../theme/icons/Icon-GreenCheck-24x24.svg';
import { ReactComponent as IconInReview24x24 } from '../../theme/icons/Icon-InReview-24x24.svg';
import { appContext } from '../../context/appContext';
import { TextInputForm } from '../../components/Form';
import { Field, Form, Formik } from 'formik';
import { teamsValidationSchema } from './Teams.validation';
import { ButtonCmv } from '../../components/ButtonCmv';
import { ModalCmv } from '../../components/ModalCmv';
import images from '../../theme/images';
import { theme } from '../../theme';
import { ItemsProps } from '../../components/CustomFlatList/CustomFlatList.container';
import { CompanyAccounts } from '../../types/DbInterface';
import { loader } from 'graphql.macro';

const sendVeterinarianInvitationToEmail = loader(
  '../../graphql/sendVeterinarianInvitationToEmail.graphql'
);
const acceptCompanyAccountsInvitationById = loader(
  '../../graphql/acceptCompanyAccountsInvitationById.graphql'
);
const refuseCompanyAccountsInvitationById = loader(
  '../../graphql/refuseCompanyAccountsInvitationById.graphql'
);
const cancelCompanyAccountsInvitationById = loader(
  '../../graphql/cancelCompanyAccountsInvitationById.graphql'
);

// interface CompanyAccounts {
//   id: string;
//   account: {
//     id: string;
//     firstName: string;
//     lastName: string;
//   };
//   moderationStatus: string;
//   invitationEmail: string | null;
// }

export interface State {
  showInvitationPendingVeterinarianModal: boolean;
  showInvitationPendingCompanyModal: boolean;
  showDeleteVeterinarianAccountModal: boolean;
  companyAccountSelected: CompanyAccounts | undefined;
}

export class Teams extends Component<Props, State> {
  public state: State = {
    showInvitationPendingVeterinarianModal: false,
    showInvitationPendingCompanyModal: false,
    showDeleteVeterinarianAccountModal: false,
    companyAccountSelected: undefined,
  };

  private formikRef: any;
  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public componentDidMount() {
    this.context.updateContext();
  }

  private async onSubmit(values: { email: string }) {
    if (values.email) {
      try {
        await this.props.client.mutate({
          mutation: sendVeterinarianInvitationToEmail,
          variables: { email: values.email },
        });
        this.formikRef.setFieldValue('email', "");
        this.formikRef.resetForm();
        this.context.updateContext();
      } catch (error) {
        if (String(error)?.indexOf('Invitation already sent'))
          this.formikRef.setFieldError(
            'email',
            'Vous avez déjà envoyé une invitation à cette personne.'
          );
        else {
          this.formikRef.setFieldError(
            'email',
            'Une erreur est survenue, veuillez réessayer plus tard.'
          );
        }
      }
    }
    this.setState({ showInvitationPendingCompanyModal: false });
  }

  private async acceptCompanyAccountsInvitationById(companyAccountsId?: string) {
    if (companyAccountsId) {
      try {
        await this.props.client.mutate({
          mutation: acceptCompanyAccountsInvitationById,
          variables: { companyAccountsId },
        });
        this.context.updateContext();
      } catch (error) {
        throw new Error(error);
      }
    }
    this.setState({ showInvitationPendingCompanyModal: false });
  }

  private async refuseCompanyAccountsInvitationById(companyAccountsId?: string) {
    if (companyAccountsId) {
      try {
        await this.props.client.mutate({
          mutation: refuseCompanyAccountsInvitationById,
          variables: { companyAccountsId },
        });
        this.context.updateContext();
      } catch (error) {
        throw new Error(error);
      }
    }
    this.setState({ showInvitationPendingCompanyModal: false });
  }

  private async cancelCompanyAccountsInvitationById(companyAccountsId?: string) {
    // console.log(companyAccountsId);
    if (companyAccountsId) {
      try {
        await this.props.client.mutate({
          mutation: cancelCompanyAccountsInvitationById,
          variables: { companyAccountsId },
        });
        this.context.updateContext();
      } catch (error) {
        throw new Error(error);
      }
    }
    this.setState({
      showDeleteVeterinarianAccountModal: false,
      showInvitationPendingVeterinarianModal: false,
    });
  }

  private getIsCompanyAdmin = (accountId: string) => {
    const role = this.context.getIsCompanyAdminByAccountId(accountId)
      ? i18n.t('teams.role.admin')
      : i18n.t('teams.role.veterinarian');
    return role;
  };

  private getVeterinarianModerationStatus = (
    moderationStatus?: string
    // 'NOT_COMPLETED' | 'PENDING_VERIFICATION' | 'VERIFIED'
  ) => {
    if (!moderationStatus || moderationStatus === 'NOT_COMPLETED') {
      return {
        moderationStatusLabel: i18n.t('teams.veterinarianModerationStatus.notCompleted'),
        moderationStatusIcon: IconCrossDelete,
      };
    } else if (moderationStatus === 'PENDING_VERIFICATION') {
      return {
        moderationStatusLabel: i18n.t('teams.veterinarianModerationStatus.pendingVerification'),
        moderationStatusIcon: IconInReview24x24,
      };
    } else if (moderationStatus === 'VERIFIED') {
      return {
        moderationStatusLabel: i18n.t('teams.veterinarianModerationStatus.verified'),
        moderationStatusIcon: IconCrossDelete,
      };
    } else {
      return {
        moderationStatusLabel: '',
        moderationStatusIcon: '',
      };
    }
  };

  private getCompanyVeterinariansMembersListFormatted = () => {
    const currentAccountId = this.context.currentAccount?.id;
    const companyAccountsVeterinariansMembersList = this.context.currentCompany?.companyAccounts
      ?.filter(
        (companyAccounts: CompanyAccounts) => companyAccounts.moderationStatus === 'ACCEPTED'
      )
      .map((companyAccountsMembers: CompanyAccounts) => {
        const veterinarian = this.context.getVeterinarianFromListById(
          companyAccountsMembers.account.id
        );

        // console.log(
        //   'this.getIsCompanyAdmin(currentAccountId) = ',
        //   this.context.getIsCompanyAdminByAccountId(currentAccountId)
        // );
        // console.log(
        //   'currentAccountId === companyAccountsMembers.account.id = ',
        //   currentAccountId === companyAccountsMembers.account.id
        // );
        // console.log(
        //   this.context.getIsCompanyAdminByAccountId(currentAccountId) ||
        //     currentAccountId === companyAccountsMembers.account.id
        //     ? false
        //     : true
        // );
        return {
          backgroundColor:
            veterinarian && veterinarian.moderationStatus === 'PENDING_VERIFICATION'
              ? theme.colors.white.dark
              : theme.colors.white.pure,
          leftLabel:
            companyAccountsMembers.account.firstName +
            ' ' +
            companyAccountsMembers.account.lastName,

          leftSubLabel: companyAccountsMembers.account?.id
            ? this.getIsCompanyAdmin(companyAccountsMembers.account.id)
            : i18n.t('teams.role.veterinarian'),

          leftSubLabelColor: theme.colors.grey.darker,
          disabled:
            this.context.getIsCompanyAdminByAccountId(currentAccountId) ||
            currentAccountId === companyAccountsMembers.account.id
              ? false
              : true,
          rightIcon:
            currentAccountId === companyAccountsMembers.account.id
              ? IconChevronRight24x24
              : this.context.getIsCompanyAdminByAccountId(currentAccountId)
              ? this.getVeterinarianModerationStatus(veterinarian?.moderationStatus)
                  .moderationStatusIcon
              : IconChevronRight24x24,
          rightLabel: this.getVeterinarianModerationStatus(veterinarian?.moderationStatus)
            .moderationStatusLabel,
          rightLabelColor: theme.colors.grey.darker,
          onClick:
            currentAccountId === companyAccountsMembers.account.id
              ? () => this.props.history.push('/profile')
              : veterinarian && veterinarian.moderationStatus === 'PENDING_VERIFICATION'
              ? () => null
              : () => {
                  this.setState({
                    companyAccountSelected: companyAccountsMembers,
                    showDeleteVeterinarianAccountModal: true,
                  });
                },
        };
      });

    return companyAccountsVeterinariansMembersList as ItemsProps[];
  };

  private getCompanyVeterinariansInvitationsListFormatted = () => {
    const currentAccountId = this.context.currentAccount?.id;
    const companyAccountsVeterinariansInvitationsList = this.context.currentCompany?.companyAccounts
      ?.filter((companyAccounts: CompanyAccounts) => {
        return (
          companyAccounts.moderationStatus === 'PENDING_VETERINARIAN' ||
          companyAccounts.moderationStatus === 'PENDING_COMPANY'
        );
      })
      .map((companyAccountInvitations: CompanyAccounts) => {
        if (companyAccountInvitations.moderationStatus === 'PENDING_VETERINARIAN') {
          return {
            backgroundColor: theme.colors.white.dark,
            leftLabel: companyAccountInvitations.account
              ? companyAccountInvitations.account.firstName +
                ' ' +
                companyAccountInvitations.account.lastName
              : companyAccountInvitations.invitationEmail
              ? companyAccountInvitations.invitationEmail
              : '',
            leftSubLabel: companyAccountInvitations.account?.id
              ? this.getIsCompanyAdmin(companyAccountInvitations.account.id)
              : i18n.t('teams.role.veterinarian'),
            leftSubLabelColor: theme.colors.grey.darker,
            rightIcon: IconGreenCheck24x24,
            rightLabel: i18n.t('teams.companyAccountsModerationStatus.pendingVeterinarian'),
            rightLabelColor: theme.colors.grey.darker,
            onClick: () =>
              this.setState({
                companyAccountSelected: companyAccountInvitations,
                showInvitationPendingVeterinarianModal: true,
              }),
            disabled:
              this.context.getIsCompanyAdminByAccountId(currentAccountId) ||
              currentAccountId === companyAccountInvitations.account?.id
                ? false
                : true,
          };
        } else {
          return {
            backgroundColor: theme.colors.white.pure,
            leftLabel:
              companyAccountInvitations.account.firstName +
              ' ' +
              companyAccountInvitations.account.lastName,
            leftSubLabel: companyAccountInvitations.account?.id
              ? this.getIsCompanyAdmin(companyAccountInvitations.account.id)
              : i18n.t('teams.role.veterinarian'),
            leftSubLabelColor: theme.colors.grey.darker,
            rightIcon: IconThreeDots24x24,
            rightLabel: i18n.t('teams.companyAccountsModerationStatus.pendingCompany'),
            rightLabelColor: theme.colors.grey.darker,
            onClick: () =>
              this.setState({
                companyAccountSelected: companyAccountInvitations,
                showInvitationPendingCompanyModal: true,
              }),
            disabled:
              this.context.getIsCompanyAdminByAccountId(currentAccountId) ||
              currentAccountId === companyAccountInvitations.account.id
                ? false
                : true,
          };
        }
      });
    return companyAccountsVeterinariansInvitationsList as ItemsProps[];
  };

  public render() {
    const company = this.context.currentCompany;
    return (
      <>
        <NavBarMaster />
        <SubNavBar moderationStatus={company?.moderationStatus}/>
        <MasterBody>
          <MasterSideMenu>
            <MainMenuConnected />
          </MasterSideMenu>
          <MasterContainer>
            <TeamsContainer>
              <TeamsHeaderContainer>
                <TeamsSubtitle>
                  {i18n.t('teams.subtitle', { companyName: this.context.currentCompany?.name })}
                </TeamsSubtitle>
                <TeamsTitle>{i18n.t('teams.title')}</TeamsTitle>
              </TeamsHeaderContainer>

              <TeamsSectionContainer>
                <TeamsMembersContainer>
                  <HeaderSectionForm
                    title={i18n.t('teams.members.title')}
                    subtitle={i18n.t('teams.members.subtitle')}
                  />
                  <CustomFlatListContainer>
                    <CustomFlatList data={this.getCompanyVeterinariansMembersListFormatted()} />
                  </CustomFlatListContainer>
                </TeamsMembersContainer>
                <TeamsInvitationContainer>
                  <HeaderSectionForm
                    title={i18n.t('teams.invitation.title')}
                    subtitle={i18n.t('teams.invitation.subtitle')}
                  />
                  <CustomFlatListContainer>
                    <CustomFlatList data={this.getCompanyVeterinariansInvitationsListFormatted()} />
                  </CustomFlatListContainer>
                  <Formik
                    ref={(formikComponent) => (this.formikRef = formikComponent)}
                    initialValues={{
                      email: '',
                    }}
                    onSubmit={(values) => this.onSubmit(values)}
                    validationSchema={teamsValidationSchema}
                  >
                    {() => (
                      <TeamsInvitationForm>
                        <TeamsInvitationField>
                          <Field
                            name={'email'}
                            placeholder={i18n.t('teams.invitation.form.email.placeholder')}
                            component={TextInputForm}
                          />
                        </TeamsInvitationField>
                        <ButtonCmvContainer>
                          <ButtonCmv
                            btnSize={'large'}
                            btnStyle={'primary'}
                            onClick={() => null}
                            type={'submit'}
                            content={i18n.t('teams.invitation.form.button.submit')}
                          />
                        </ButtonCmvContainer>
                      </TeamsInvitationForm>
                    )}
                  </Formik>
                </TeamsInvitationContainer>
              </TeamsSectionContainer>
            </TeamsContainer>
            <ModalCmv
              show={this.state.showInvitationPendingVeterinarianModal}
              headerTitle={i18n.t('teams.invitation.pendingVetModal.header.title')}
              contentViewBodyContent={i18n.t('teams.invitation.pendingVetModal.content')}
              contentViewImage={images.HugoUnsubscribed}
              contentViewImageRetina={images.HugoUnsubscribed2x}
              onClickOutsideCloseModal={() => null}
              footerCustomComponent={
                <FooterButtonsContainer>
                  <FooterButtonCmvContainer>
                    <ButtonCmv
                      btnSize={'medium'}
                      btnStyle={'primaryHollow'}
                      onClick={() =>
                        this.cancelCompanyAccountsInvitationById(
                          this.state.companyAccountSelected?.id
                        )
                      }
                      content={i18n.t('teams.invitation.pendingVetModal.footer.cancelInvitation')}
                    />
                  </FooterButtonCmvContainer>
                  <FooterButtonCmvContainer>
                    <ButtonCmv
                      btnSize={'medium'}
                      btnStyle={'primary'}
                      onClick={() =>
                        this.setState({ showInvitationPendingVeterinarianModal: false })
                      }
                      content={i18n.t('teams.invitation.pendingVetModal.footer.backButton')}
                    />
                  </FooterButtonCmvContainer>
                </FooterButtonsContainer>
              }
            />
            <ModalCmv
              show={this.state.showInvitationPendingCompanyModal}
              headerTitle={i18n.t('teams.invitation.pendingCompanyModal.header.title')}
              headerIconLeft={IconCrossDelete}
              headerOnClickLeft={() => this.setState({ showInvitationPendingCompanyModal: false })}
              contentViewBodyContent={i18n.t('teams.invitation.pendingCompanyModal.content')}
              contentViewImage={images.HugoUpgrade}
              contentViewImageRetina={images.HugoUpgrade2x}
              onClickOutsideCloseModal={() => null}
              footerCustomComponent={
                <FooterButtonsContainer>
                  <FooterButtonCmvContainer>
                    <ButtonCmv
                      btnSize={'medium'}
                      btnStyle={'primaryHollow'}
                      onClick={() =>
                        this.refuseCompanyAccountsInvitationById(
                          this.state.companyAccountSelected?.id
                        )
                      }
                      content={i18n.t(
                        'teams.invitation.pendingCompanyModal.footer.declineInvitation'
                      )}
                    />
                  </FooterButtonCmvContainer>
                  <FooterButtonCmvContainer>
                    <ButtonCmv
                      btnSize={'medium'}
                      btnStyle={'primary'}
                      onClick={() =>
                        this.acceptCompanyAccountsInvitationById(
                          this.state.companyAccountSelected?.id
                        )
                      }
                      content={i18n.t(
                        'teams.invitation.pendingCompanyModal.footer.acceptInvitation'
                      )}
                    />
                  </FooterButtonCmvContainer>
                </FooterButtonsContainer>
              }
            />
            <ModalCmv
              show={this.state.showDeleteVeterinarianAccountModal}
              headerTitle={i18n.t('teams.invitation.deleteVeterinarianModal.header.title')}
              contentViewBodyContent={i18n.t('teams.invitation.deleteVeterinarianModal.content', {
                firstName: this.state.companyAccountSelected?.account?.firstName,
                lastName: this.state.companyAccountSelected?.account?.lastName,
              })}
              contentViewImage={images.HugoLoggedOut}
              contentViewImageRetina={images.HugoLoggedOut2x}
              onClickOutsideCloseModal={() => null}
              footerCustomComponent={
                <FooterButtonsContainer>
                  <FooterButtonCmvContainer>
                    <ButtonCmv
                      btnSize={'medium'}
                      btnStyle={'primaryHollow'}
                      onClick={() => this.setState({ showDeleteVeterinarianAccountModal: false })}
                      content={i18n.t('teams.invitation.deleteVeterinarianModal.footer.backButton')}
                    />
                  </FooterButtonCmvContainer>
                  <FooterButtonCmvContainer>
                    <ButtonCmv
                      btnSize={'medium'}
                      btnStyle={'alertHollow'}
                      onClick={() =>
                        this.cancelCompanyAccountsInvitationById(
                          this.state.companyAccountSelected?.id
                        )
                      }
                      content={i18n.t('teams.invitation.deleteVeterinarianModal.footer.delete')}
                    />
                  </FooterButtonCmvContainer>
                </FooterButtonsContainer>
              }
            />
          </MasterContainer>
        </MasterBody>
      </>
    );
  }
}

const TeamsContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.pure};
`;

const TeamsHeaderContainer = styled.div`
  padding: 50px 30px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 50px 10px;
  }
`;

const TeamsSubtitle = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontSize};
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: ${(props) =>
    props.theme.textStyles.typography.lead.capitalize.regular.letterSpacing};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const TeamsTitle = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.heading.h1.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
`;

const TeamsSectionContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 30px 10px;
  }
`;

const TeamsMembersContainer = styled.div``;

const TeamsInvitationContainer = styled.div`
  margin-top: 30px;
`;

const CustomFlatListContainer = styled.div`
  margin-top: 20px;
`;

const TeamsInvitationForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 35px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    flex-direction: column;
  }
`;

const TeamsInvitationField = styled.div`
  width: 60%;
  position: relative;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    width: 100%;
  }
`;

const ButtonCmvContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    width: 100%;
    margin: 20px 0;
  }
`;

const FooterButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const FooterButtonCmvContainer = styled.div`
  width: 280px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    width: 100%;
    &:nth-child(2) {
      margin-top: 20px;
    }
  }
`;
