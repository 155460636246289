import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../../lib/withApolloClient';
import { SurveyEndCall as SurveyEndCallComponent } from './SurveyEndCall.component';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
import { withAppContext } from '../../../lib/withContext';

export type ContainerProps = {
  videoCallId: string;
  onSubmit?: () => void;
};

export type Props = WithApolloClient<RouteComponentProps & ContainerProps>;

export const SurveyEndCall = compose<Props, ContainerProps>(
  withApollo,
  withAppContext
)(SurveyEndCallComponent);
