import React, { useState } from 'react';
import styled from 'styled-components';
import { IbanElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loader } from 'graphql.macro';
import { PopUp } from '../PopUp';
import { i18n } from '../../lib/i18n';
import { theme } from '../../theme';
import { SUPPORT_EMAIL_ADDRESS } from '../../constants';
import { Row, Col } from 'react-bootstrap';
import { ButtonCmv } from '../ButtonCmv';
import { ReactComponent as IconCrossDelete } from '../../theme/icons/IconCrossDelete.svg';
import { StripeIbanElement } from '@stripe/stripe-js';

const saveStripeCompanyPaymentMethod = loader(
  '../../graphql/saveStripeCompanyPaymentMethod.graphql'
);

export const StripeIbanForm = (props: any) => {
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [bankName, setBankName] = useState('');
  const [showPopUp, setShowPopUp] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ['SEPA'],
    placeholderCountry: props.nationality === 'BELGIAN' ? 'BE' : 'FR',
    style: {
      base: {
        color: '#32325d',
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
        ':-webkit-autofill': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
        ':-webkit-autofill': {
          color: '#fa755a',
        },
      },
    },
  };

  // Reset the demo.
  // const handleReset = () => {
  //   setName('');
  //   setBankName('');
  //   setError(null);
  //   elements.getElement(IbanElement).clear();
  // }

  const handleChange = (event: any) => {
    // Handle real-time validation errors from the iban Element.
    if (event.error) {
      setError(event.error.message);
    } else {
      setError('');
    }

    // Display bank name corresponding to IBAN, if available.
    if (event.bankName) {
      setBankName(event.bankName);
    } else {
      setBankName('');
    }
  };

  // Handle form submission.
  const handleSubmit = async () => {
    //event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const ibanElement: StripeIbanElement | null = elements.getElement(IbanElement);
    if (!ibanElement) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    let result = undefined;
    try {
        result = await stripe.createToken(ibanElement, {
        currency: 'eur',
        account_holder_name: name,
        account_holder_type: 'individual', // or company ?
      });
      props.handleStripeIbanSuccess();
    } catch (error) {
      console.log('error ', error)
    }
    if (!result) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    // console.log(ibanElement);
    if (result.error && result.error.message) {
      // Inform the user if there was an error.
      setError(result.error.message);
      setShowPopUp(true);
    } else if (result.token) {
      setError('');
      setShowPopUp(true);
      try {
        const result2 = await props.client.mutate({
          mutation: saveStripeCompanyPaymentMethod,
          variables: {
            companyId: props.companyId,
            stripeIbanToken: result.token.id,
          },
        });
        props.onSuccess();
        // console.log('RESULT = ', result2);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const popUpContact = () => {
    const subject = encodeURIComponent('Problème avec mon IBAN');
    const body = encodeURIComponent(
      'VetId = ' + props.currentVet.id + '\n' + props.currentVet.firstName
    );
    window.location.href = `mailto:${SUPPORT_EMAIL_ADDRESS}?subject=${subject}&body=${body}`;
    setShowPopUp(false);
  };

  return (
    <>
      {showPopUp && !error && (
        <PopUp closePopup={() => props.closeForm()} text={i18n.t('ibanForm.popup.valid')}></PopUp>
      )}
      {showPopUp && error && (
        <PopUp
          leftText={i18n.t('ibanForm.popup.errorLeftButton')}
          leftOnClick={() => popUpContact()}
          closePopup={() => setShowPopUp(false)}
          text={i18n.t('ibanForm.popup.error')}
        ></PopUp>
      )}
      <IbanFormContainer id='stripe-iban-form__container'>
        <IbanFormMainHeader>
          {/* <IbanFormTitle>{i18n.t('ibanForm.title')}</IbanFormTitle>
          <IconCloseModal onClick={() => props.closeForm()} /> */}
          <IbanFormTitle>{i18n.t('ibanForm.titleStripe')}</IbanFormTitle>
        </IbanFormMainHeader>
       <IbanFormSubtitle>{i18n.t('ibanForm.subTitleStripe')}</IbanFormSubtitle>
        <form onSubmit={handleSubmit}>
          <IbanContainer className="form-row">
            <NameLabel htmlFor="name">{i18n.t('ibanForm.form.name.label')}</NameLabel>
            <IbanInput
              id="name"
              name="name"
              placeholder={i18n.t('ibanForm.form.name.placeholder')}
              required
              value={name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
            />
          </IbanContainer>
{/*           <IbanContainer className="form-row">
            <NameLabel htmlFor="ibanNumber">{i18n.t('ibanForm.form.ibanNumber.label')}</NameLabel>
            <IbanInput
              id="ibanNumber"
              name="ibanNumber"
              placeholder={i18n.t('ibanForm.form.ibanNumber.placeholder')}
              required
              value={ibanNumber}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setIbanNumber(event.target.value);
                
              }}
            />
          </IbanContainer> */}
          <IbanContainer className="form-row">
            <NameLabel htmlFor="iban-element">{i18n.t('ibanForm.form.ibanNumber.label')}</NameLabel>
            <IbanElement id="iban-element" options={IBAN_ELEMENT_OPTIONS} onChange={handleChange} />
          </IbanContainer>
          {(bankName || error) && (
            <IbanValidation>
              <BankName
                id="bank-name"
                style={{ color: theme.colors.primary.mainColor }}
                className={bankName ? 'visible' : ''}
              >
                {bankName}
              </BankName>
              <BankName
                id="error-message"
                style={{ color: theme.colors.red.lighter }}
                className={error ? 'visible' : ''}
              >
                {error}
              </BankName>
            </IbanValidation>
          )}
          {/* <IbanContainer className="form-row">
            <NameLabel htmlFor="bicNumber">{i18n.t('ibanForm.form.bicNumber.label')}</NameLabel>
            <IbanInput
              id="bicNumber"
              name="bicNumber"
              placeholder={i18n.t('ibanForm.form.bicNumber.placeholder')}
              required
              value={bicNumber}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setBicNumber(event.target.value);
              }}
            />
          </IbanContainer> */}

          {/* TODO try to use ButtonsForm component in modal with right size */}
          {/* <ButtonsForm
            cancelContent={i18n.t('organisation.form.button.cancel')}
            validateContent={i18n.t('organisation.form.button.save')}
            onCancel={() => props.closeForm()}
            type={'submit'}
          /> */}

          <IbanFormButtonsRow noGutters>
            <Col lg={{ span: 2, offset: 1 }} className="d-none d-lg-block">
              <BtnContainer>
                <ButtonCmv
                  btnSize={'large'}
                  btnStyle={'primaryHollow'}
                  content={i18n.t('organisation.form.button.cancel')}
                  onClick={() => props.closeForm()}
                  disabled={props.isIbanUpdated}
                />
              </BtnContainer>
            </Col>
            <Col md={12} lg={{ span: 6, offset: 3 }}>
              <BtnContainer>
                <ButtonCmv
                  type={'button'}
                  btnSize={'large'}
                  btnStyle={'primary'}
                  content={i18n.t('ibanForm.btnSaveStripe')}
                  onClick={() => handleSubmit()}
                  disabled={!props.ibanNumber && !props.isIbanUpdated}
                />
              </BtnContainer>
            </Col>
          </IbanFormButtonsRow>
        </form>
      </IbanFormContainer>
    </>
  );
};

const IbanValidation = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const IbanFormMainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
`;

const IconCloseModal = styled(IconCrossDelete)`
  cursor: pointer;
`;

const NameLabel = styled.label`
  font-size: ${(props) => props.theme.componentObjects.formLabel.fontSize};
  font-weight: ${(props) => props.theme.componentObjects.formLabel.fontWeight};
  line-height: 15px;
  color: ${(props) => props.theme.colors.black.light};
  margin-right: ${(props) => props.theme.spacing.unit * 2}px;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
    width: 20%;
  }
`;

const IbanContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
    flex-direction: row;
    align-items: center;
  }
`;

const BankName = styled.div`
  padding-top: 10px;
  padding-left: 10px;
`;

/* TODO refacto style for coherence with theme */
const IbanFormContainer = styled.div`
  padding: 56px 38px;
  min-width: ${(props) => props.theme.breakpoints.mobilePortraitMin} !important;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    padding: 40px 25px;
  }
`;

const IbanFormTitle = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.heading.h4.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h4.fontWeight};
  line-height: 32px;
  color: ${(props) => props.theme.colors.black.light};
`;

const IbanFormSubtitle = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.lead.capitalize.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.capitalize.fontWeight};
  line-height: 17px;
  color: ${(props) => props.theme.colors.black.lighter};
  margin-bottom: 35px;
`;

const IbanFormButtonsRow = styled(Row)`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    margin-top: 92px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tabletMax}) {
    margin-top: 40px;
    margin-bottom: 10px;
  }
`;

const BtnContainer = styled.div``;

const IbanInput = styled.input`
  box-sizing: border-box;
  flex: 1;
  height: 40px;
  padding: 10px 12px;
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.white.dark};
  box-shadow: ${(props) => `0 1px 3px 0 ${props.theme.colors.white.darker}`};
  transition: box-shadow 150ms ease;
`;
