import styled from 'styled-components';
import { Theme } from '../index';

export const Title = styled.span<Theme>`
  font-size: ${(props) => props.theme.typo.title.fontSize};
  font-weight: ${(props) => props.theme.typo.title.fontWeight};
  line-height: ${(props) => props.theme.typo.title.lineHeight};
`;

export const FieldLabel = styled.span<Theme>`
  font-size: ${(props) => props.theme.typo.fieldLabel.fontSize};
  font-weight: ${(props) => props.theme.typo.fieldLabel.fontWeight};
`;

export const OptionalFieldLabel = styled.span<Theme>`
  font-size: ${(props) => props.theme.typo.optionalFieldLabel.fontSize};
  font-weight: ${(props) => props.theme.typo.optionalFieldLabel.fontWeight};
`;

export const Input = styled.input<Theme>`
  font-size: ${(props) => props.theme.typo.input.fontSize};
  font-weight: ${(props) => props.theme.typo.input.fontWeight};
`;

export const SmallText = styled.span<Theme>`
  font-size: ${(props) => props.theme.typo.smallText.fontSize};
  font-weight: ${(props) => props.theme.typo.smallText.fontWeight};
`;

export const LargeText = styled.span<Theme>`
  font-size: ${(props) => props.theme.typo.largeText.fontSize};
  font-weight: ${(props) => props.theme.typo.largeText.fontWeight};
  line-height: ${(props) => props.theme.typo.largeText.lineHeight};
`;
export const VeryLargeText = styled.span<Theme>`
  font-size: ${(props) => props.theme.typo.veryLargeText.fontSize};
  font-weight: ${(props) => props.theme.typo.veryLargeText.fontWeight};
  line-height: ${(props) => props.theme.typo.veryLargeText.lineHeight};
`;

export const FormSubpartTitle = styled.span<Theme>`
  font-size: ${(props) => props.theme.typo.formSubpartTitle.fontSize};
  font-weight: ${(props) => props.theme.typo.formSubpartTitle.fontWeight};
`;

export const FormSubpartTitleDescription = styled.span<Theme>`
  font-size: ${(props) => props.theme.typo.formSubpartTitleDescription.fontSize};
  font-weight: ${(props) => props.theme.typo.formSubpartTitleDescription.fontWeight};
  line-height: ${(props) => props.theme.typo.formSubpartTitleDescription.lineHeight};
`;

export const CheckboxDescription = styled.span<Theme>`
  font-size: ${(props) => props.theme.typo.checkboxDescription.fontSize};
  font-weight: ${(props) => props.theme.typo.checkboxDescription.fontWeight};
`;

export const FormTitle = styled.span<Theme>`
  font-size: ${(props) => props.theme.typo.formTitle.fontSize};
  font-weight: ${(props) => props.theme.typo.formTitle.fontWeight};
`;

export const FormSubtitle = styled.span<Theme>`
  font-size: ${(props) => props.theme.typo.formSubtitle.fontSize};
  font-weight: ${(props) => props.theme.typo.formSubtitle.fontWeight};
`;

export const ButtonLabel = styled.span<Theme>`
  font-size: ${(props) => props.theme.typo.buttonLabel.fontSize};
  font-weight: ${(props) => props.theme.typo.buttonLabel.fontWeight};
`;
export const ErrorMessage = styled.span<Theme>`
  font-size: ${(props) => props.theme.typo.errorMessage.fontSize};
  font-weight: ${(props) => props.theme.typo.errorMessage.fontWeight};
  color: ${(props) => props.theme.colors.red.dark};
`;

export const FileUploadPlaceholder = styled.span`
  font-size: ${(props) => props.theme.typo.fileUploadPlaceholder.fontSize};
  font-weight: ${(props) => props.theme.typo.fileUploadPlaceholder.fontWeight};
  color: ${(props) => props.theme.colors.white.pure};
`;

export const FileUploadFormatRequirements = styled.span`
  font-size: ${(props) => props.theme.typo.fileUploadFormatRequirements.fontSize};
  font-weight: ${(props) => props.theme.typo.fileUploadFormatRequirements.fontWeight};
  color: ${(props) => props.theme.colors.white.pure};
`;
