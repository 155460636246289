import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../../lib/withApolloClient';
import { OrganisationLegalInformation as OrganisationLegalInformationComponent } from './OrganisationLegalInformation.component';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
import { withAppContext } from '../../../lib/withContext';

export type CompanyLegalStatusType =
  | 'CL'
  | 'EI'
  | 'EIRL'
  | 'EURL'
  | 'SA'
  | 'SARL'
  | 'SAS'
  | 'SASU'
  | 'SC'
  | 'SCP'
  | 'SCRL'
  | 'SELARL'
  | 'SLP'
  | 'SNC'
  | 'SPFPL'
  | 'SPFPL_VRL'
  | 'SPFPL_SAS'
  | 'SPFPL_RL'
  | 'SE'
  | 'SDE';

export type Props = WithApolloClient<RouteComponentProps>;

export const OrganisationLegalInformation = compose<Props, RouteComponentProps>(
  withApollo,
  withAppContext
)(OrganisationLegalInformationComponent);
