import React, { Component } from 'react';
import { Props } from './AccountEmailForm.container';
import styled from 'styled-components';
import { i18n } from '../../../lib/i18n';
import { ButtonCmv } from '../../../components/ButtonCmv';
// import { Row } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import { accountEmailFormValidationSchema } from './AccountEmailForm.validation';
import { TextInputForm } from '../../../components/Form';
// import { ReactComponent as IconCrossDelete } from '../../../theme/icons/IconCrossDelete.svg';

interface ValuesForm {
  oldEmail: string;
  newEmail: string;
}

// interface State {}

export class AccountEmailForm extends Component<Props> {
  public onSubmit = (values: ValuesForm) => {
    this.props.onValidate(values.newEmail);
  };

  render() {
    const oldEmail = this.props.email;
    return (
      <AccountEmailFormContainer>
        <Formik
          initialValues={{
            oldEmail: oldEmail,
            newEmail: '',
          }}
          onSubmit={this.onSubmit}
          validationSchema={accountEmailFormValidationSchema}
        >
          {() => (
            <Form>
              <FieldContainer>
                <Field
                  name={'oldEmail'}
                  label={i18n.t('account.form.emailSection.modal.oldEmail.label')}
                  placeholder={i18n.t('account.form.emailSection.modal.oldEmail.placeholder')}
                  component={TextInputForm}
                  disabled
                />
              </FieldContainer>
              <FieldContainer>
                <Field
                  name={'newEmail'}
                  label={i18n.t('account.form.emailSection.modal.newEmail.label')}
                  placeholder={i18n.t('account.form.emailSection.modal.newEmail.placeholder')}
                  component={TextInputForm}
                />
              </FieldContainer>

              <BtnContainer>
                <ButtonCmv
                  type={'submit'}
                  btnSize={'large'}
                  btnStyle={'primary'}
                  content={i18n.t('account.form.button.save')}
                />
              </BtnContainer>
            </Form>
          )}
        </Formik>
      </AccountEmailFormContainer>
    );
  }
}

const AccountEmailFormContainer = styled.div`
  padding: 40px 38px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 40px 10px;
  }
`;

const FieldContainer = styled.div`
  margin: 32px 0;
`;

// const ButtonsRow = styled(Row)`
//   @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
//     margin-top: 92px;
//     margin-bottom: 10px;
//   }
//   @media screen and (max-width: ${(props) => props.theme.breakpoints.tabletMax}) {
//     margin-top: 40px;
//     margin-bottom: 10px;
//   }
// `;

const BtnContainer = styled.div`
  margin-top: 20px;
`;
