import { FunctionComponent } from 'react';
import { MainMenuConnected as MainMenuConnectedComponent } from './MainMenuConnected.component';
import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../../../lib/withApolloClient';
import { RouteComponentProps } from 'react-router-dom';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';

export type MainMenuProps = {
  title: string;
  iconSelected: FunctionComponent;
  iconNotSelected: FunctionComponent;
  onClick: () => void;
  selected: boolean;
  path: string;
  endOfSection?: boolean;
};

export type ContainerProps = unknown;

export type Props = WithApolloClient<RouteComponentProps & ContainerProps>;

export const MainMenuConnected = compose<Props, ContainerProps>(
  withApollo,
  withRouter
)(MainMenuConnectedComponent);
