import { Twilio as TwilioComponent } from './Twilio.component';
import { withApollo, graphql } from '@apollo/react-hoc';
import { WithApolloClient } from '../../lib/withApolloClient';
import { loader } from 'graphql.macro';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { withTracker } from '../../lib/withTracker';

const getVideoCallCredentials = loader('../../graphql/getVideoCallCredentials.graphql');

export type TarificationProps = 'increased' | 'slightlyIncreased' | 'regular';

type ContainerProps = RouteComponentProps<
  {},
  {},
  {
    videoCallId: any;
    petName: any;
    petTrouble: any;
    raceId: any;
    species: any;
    isFreecall: any;
  }
>;
interface InjectedProps {
  data: {
    getVideoCallCredentials?: {
      twilioRoomName: string;
      twilioAccessToken: string;
      tarification: TarificationProps;
      price: string;
      customerDescription: {
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber: string;
        email: string;
        gender: string;
        city: string;
        country: string;
        profilePicture?: {
          id: string;
          url: string;
        };
      };
      customerPet: {
        id?: string;
        name: string;
        profilePicture?: {
          id: string;
          url: string;
        };
        profileEmoji: string;
        profileColor: string;
        petCategory: string;
        specie?: string;
        race?: string;
        birthDate?: Date;
        tatooNumber?: string;
        idNumber?: string;
        pedigree?: string;
        breederName?: string;
        isFemale: boolean;
        isSterilized: boolean;
        notes?: string;
        passeportNumber?: string;
        customerPetVaccine?: {
          id?: string;
          name: string;
          startDate: Date;
          duration: string;
          endDate?: Date;
          fabricator?: string;
          vaccineNumber?: string;
          vetName?: string;
          vetOrder?: string;
          notes?: string;
        };
        customerPetWeight?: { id?: string; unit: string; date: Date };
        customerPetDewormer?: {
          id?: string;
          name: string;
          startDate: Date;
          duration: string;
          endDate?: Date;
          fabricator?: string;
          dewormerNumber?: string;
          notes?: string;
        };
        customerPetSuppressor?: {
          id?: string;
          name: string;
          type: string;
          startDate: Date;
          duration: string;
          endDate?: Date;
          fabricator?: string;
          suppressorNumber?: string;
          notes?: string;
        };
        customerPetVisit?: {
          id?: string;
          object: string;
          date: Date;
          vetName?: string;
          vetOrder?: string;
        };
      };
    };
    isLoading: boolean;
    error?: any;
  };
}
export type Props = WithApolloClient<RouteComponentProps & ContainerProps & InjectedProps>;
export const Twilio = compose<Props, ContainerProps>(
  graphql<RouteComponentProps<{}, {}, { videoCallId: number | undefined }>, InjectedProps>(
    getVideoCallCredentials,
    {
      options: (props) => {
        return {
          variables: {
            videoCallId: props.location.state.videoCallId,
          },
        };
      },
    }
  ),
  withApollo,
  withRouter,
  withTracker
)(TwilioComponent);
