import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../../lib/withApolloClient';
import { OrganisationBankingInformation as OrganisationBankingInformationComponent } from './OrganisationBankingInformation.component';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
import { withAppContext } from '../../../lib/withContext';

export type Props = WithApolloClient<RouteComponentProps>;

export const OrganisationBankingInformation = compose<Props, RouteComponentProps>(
  withApollo,
  withAppContext
)(OrganisationBankingInformationComponent);
