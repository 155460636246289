import React, { Component, Fragment } from 'react';
import { Props } from './ResetPasswordForm.container';
import styled from 'styled-components';
import images from '../../theme/images';
import { ButtonCmv } from '../../components/ButtonCmv';
import { Formik, Field, Form } from 'formik';
import { resetPasswordFormValidationSchema } from './ResetPasswordForm.validation';
import { TextInputForm } from '../../components/Form';
import { MasterBody } from '../../components/Layout/MasterBody';
import { NavBarMaster } from '../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../components/UiNavigation/SubNavBar';
import { i18n } from '../../lib/i18n';
import { PasswordValidator } from '../../components/PasswordValidator';
// import { parse } from 'query-string';
import { Firebase } from '../../lib/firebase';
import { ModalCmv } from '../../components/ModalCmv';

interface ValuesForm {
  email?: string;
  password: string;
  passwordConfirmation: string;
}

interface State {
  firebaseErrorCode?: string;
  showResetPasswordError: boolean;
  showResetPasswordLoader: boolean;
  showResetPasswordSuccess: boolean;
}

export class ResetPasswordForm extends Component<Props, State> {
  public state: State = {
    firebaseErrorCode: undefined,
    showResetPasswordError: false,
    showResetPasswordLoader: true,
    showResetPasswordSuccess: false,
  };

  private formikRef: any;

  public async componentDidMount() {
    // console.log('props = ', this.props.location.state);
    try {
      await Firebase.verifyPasswordResetCode(this.props.location.state?.oobCode);
      this.setState({
        showResetPasswordLoader: false,
        showResetPasswordSuccess: false,
        showResetPasswordError: false,
        // firebaseErrorCode: response.code,
      });
    } catch (error) {
      this.setState({
        showResetPasswordLoader: false,
        showResetPasswordSuccess: false,
        showResetPasswordError: true,
        firebaseErrorCode: error.code,
      });
    }
  }

  private onSubmit = async (values: ValuesForm) => {
    // const params: any = this.getUrlParams();

    const email = values.email ? values.email : window.localStorage.getItem('emailForReset');

    if (
      !this.props.location.state?.oobCode ||
      this.props.location.state?.mode !== 'resetPassword' ||
      !email
    ) {
      this.formikRef.setFieldError(
        'password',
        i18n.t('resetPasswordForm.form.errors.missingParams')
      );
      return;
    }

    this.setState({ showResetPasswordLoader: true });
    try {
      await Firebase.confirmPasswordReset(this.props.location.state?.oobCode, values.password)
        .then(function () {
          // Success
        })
        .catch(function () {
          // Invalid code
        });
      this.setState({ showResetPasswordSuccess: true, showResetPasswordLoader: false });
      setTimeout(async () => {
        await Firebase.signIn(email, values.password);
        this.props.history.push('Dashboard');
      }, '5000');
    } catch {
      this.formikRef.setFieldError(
        'password',
        i18n.t('resetPasswordForm.form.errors.setPasswordFailed')
      );
      this.setState({ showResetPasswordLoader: false });
    }
  };

  // private getUrlParams = () => {
  //   const url = this.props.location.search;
  //   const params = parse(url);
  //   return params;
  // };

  public translateErrorToUser = (errorCode: string) => {
    switch (errorCode) {
      case 'auth/argument-error':
        return 'Vous devez cliquer sur le lien envoyer par mail pour pouvoir effectuer une réinitialisation de mot de passe.';
      case 'auth/invalid-action-code':
        return 'Votre requête est invalide ou a déjà été utilisée, veuillez effectuer une nouvelle demande si vous souhaitez vraiment réinitialiser votre mot de passe.';
      case 'auth/expired-action-code':
        return 'Votre requête a expirée, veuillez effectuer une nouvelle demande si vous souhaitez vraiment réinitialiser votre mot de passe.';
      case 'auth/user-disabled':
        return 'Votre compte utilisateur firebase est désactivé';
      case 'auth/user-not-found':
        return "Votre compte utilisateur firebase n'a pas été trouvé";
      default:
        return "Aucun code d'erreur ne correspond";
    }
  };

  public render() {
    const email = window.localStorage.getItem('emailForReset');
    // console.log('email : ', email);
    return (
      <Fragment>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          {/* {!this.state.showResetPasswordError &&
                !this.state.showResetPasswordLoader &&
                !this.state.showResetPasswordSuccess && ( */}

          <Formik
            ref={this.formikRef}
            initialValues={{
              email: email ? email : '',
              password: '',
              passwordConfirmation: '',
            }}
            onSubmit={this.onSubmit}
            validationSchema={resetPasswordFormValidationSchema}
          >
            {({ values }) => (
              <ModalCmv
                show={
                  !this.state.showResetPasswordError &&
                  !this.state.showResetPasswordLoader &&
                  !this.state.showResetPasswordSuccess
                }
                onClickOutsideCloseModal={() => null}
                headerTitle={i18n.t('resetPasswordForm.title')}
                contentViewImage={images.HugoSecurityService2}
                contentViewImageRetina={images.HugoSecurityService22x}
                contentViewCustomComponent={
                  <ResetPasswordFormContainer>
                    <ResetPasswordField>
                      {!email && (
                        <Field
                          name={'email'}
                          label={i18n.t('resetPasswordForm.form.fields.email.label')}
                          placeholder={i18n.t('resetPasswordForm.form.fields.email.placeholder')}
                          component={TextInputForm}
                          direction={'column'}
                          type={'email'}
                        />
                      )}
                      <Field
                        name={'password'}
                        label={i18n.t('resetPasswordForm.form.fields.password.label')}
                        placeholder={i18n.t('resetPasswordForm.form.fields.password.placeholder')}
                        component={TextInputForm}
                        direction={'column'}
                        type={'password'}
                      />
                      <PasswordValidatorContainer>
                        <PasswordValidator content={values.password} />
                      </PasswordValidatorContainer>
                      <Field
                        name={'passwordConfirmation'}
                        label={i18n.t('resetPasswordForm.form.fields.passwordConfirmation.label')}
                        placeholder={i18n.t(
                          'resetPasswordForm.form.fields.passwordConfirmation.placeholder'
                        )}
                        component={TextInputForm}
                        direction={'column'}
                        type={'password'}
                      />
                    </ResetPasswordField>
                    <ButtonCmvContainerMainFom>
                      <ButtonCmv
                        type={'submit'}
                        btnSize={'large'}
                        btnStyle={'primary'}
                        content={i18n.t('resetPasswordForm.form.submit')}
                      />
                    </ButtonCmvContainerMainFom>
                  </ResetPasswordFormContainer>
                }
              />
            )}
          </Formik>

          <ModalCmv
            show={this.state.showResetPasswordError}
            onClickOutsideCloseModal={() => null}
            contentViewPreTitle={i18n.t('resetPasswordError.title')}
            contentViewBodyContent={i18n.t('resetPasswordError.subtitle')}
            contentViewImage={images.HugoSearching}
            contentViewImageRetina={images.HugoSearching2x}
            contentViewCustomComponent={
              <ButtonCmvContainerErrorForm>
                <ButtonCmv
                  btnSize={'large'}
                  btnStyle={'primary'}
                  content={i18n.t('resetPasswordError.backToLogin')}
                  onClick={() => this.props.history.push('/signIn')}
                />
              </ButtonCmvContainerErrorForm>
            }
            footerHidden={true}
          />
          <ModalCmv
            show={this.state.showResetPasswordLoader}
            onClickOutsideCloseModal={() => null}
            contentViewPreTitle={i18n.t('resetPasswordLoader.title')}
            contentViewBodyContent={i18n.t('resetPasswordLoader.subtitle')}
            contentViewShowLoader={true}
            footerHidden={true}
          />
          <ModalCmv
            show={this.state.showResetPasswordSuccess}
            onClickOutsideCloseModal={() => null}
            contentViewPreTitle={i18n.t('resetPasswordSuccess.title')}
            contentViewBodyContent={i18n.t('resetPasswordSuccess.subtitle')}
            contentViewImage={images.HugoSecurityService2}
            contentViewImageRetina={images.HugoSecurityService2x}
            footerHidden={true}
          />
        </MasterBody>
      </Fragment>
    );
  }
}

const ResetPasswordFormContainer = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ResetPasswordField = styled.div`
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 20px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    & {
      padding: 0;
    }
  }
`;

const ButtonCmvContainerMainFom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    & {
      padding: 0;
    }
  }
`;

const PasswordValidatorContainer = styled.div`
  margin-top: 20px;
`;

const ButtonCmvContainerErrorForm = styled.div`
  width: 100%;
  margin-top: 20px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 100px;
    padding-right: 100px;
  }
`;
