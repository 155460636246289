import React, { Component } from 'react';
import { Props } from './VideoCallHandler.container';
import { RaceEnum } from '../../types/Race';
import { TroubleEnum } from '../../types/PetTrouble';
import { VideoCallStatusCard } from './VideoCallStatusCard';
import { loader } from 'graphql.macro';
import Ringtone from '../../theme/sounds/Ringtone.wav';
import { VideoCallRequest, VideoCallRequestButtons } from './VideoCallRequest';
import styled from 'styled-components';
import Video from 'twilio-video';
// import { platform } from 'os';
import { ModalCmv } from '../ModalCmv/ModalCmv.component';
import { i18n } from '../../lib/i18n';
import { CustomFlatList } from '../CustomFlatList';
import { ReactComponent as IconBack } from '../../theme/icons/Icon-Back-24x24.svg';
import { ReactComponent as IconChevronRightDisclosure } from '../../theme/icons/IconChevronRightDisclosure.svg';
import { appContext } from '../../context/appContext';
import { Veterinarian } from '../../types/DbInterface';

const sound = new Audio(Ringtone);
sound.loop = true;

const newVideoCallRequest = loader('../../graphql/newVideoCallRequest.graphql');
const respondToVideoCallRequest = loader('../../graphql/respondToVideoCallRequest.graphql');

interface State {
  videoCallRequest?: {
    id: string;
    videoCallIntent: {
      customerPet: {
        name: string;
        petCategory: RaceEnum;
        specie: string;
        profilePicture: {
          url: string;
        };
      };
      petTrouble: TroubleEnum;
      isFreeCall: boolean;
      customer: {
        gender: string;
        account: {
          firstName: string;
        };
      };
    };
  };
  askActionToEnableSound: boolean;
  videoCallRequestStatus: string;
  twilioBrowserIsSupported?: boolean;
  tarification: TarificationProps | undefined;
  showCompanyVeterinarianSelect: boolean;
  isCallInProgress: boolean;
}

type TarificationProps = 'increased' | 'slightlyIncreased' | 'regular';

export class VideoCallHandler extends Component<Props> {
  public state: State = {
    videoCallRequestStatus: '',
    askActionToEnableSound: false,
    twilioBrowserIsSupported: undefined,
    tarification: undefined,
    showCompanyVeterinarianSelect: false,
    isCallInProgress: false,
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;
  private videoCallSubscription: ZenObservable.Subscription | null = null;

/*   private async isPathBlacklisted(path: string) {
    const blackList = ['/Twilio', '/reset-password-form', '/Admin'];
    blackList.forEach((blackListedPath) => {
      if (path === blackListedPath) return true;
    });
    return false;
  } */

  private async isAvailable() {
    if (
      this.context.currentVet?.isAvailable ||
      (this.context.getIsCompanyAdminByAccountId(this.context.currentAccount?.id) &&
        this.context.currentCompany?.isAvailable)
    )
      return true;
    return false;
  }

  public async componentDidMount() {
    const currentAccount = this.props.currentAccount;
    const currentVet = this.props.currentVet;
    // const currentCompany = this.props.currentCompany;
    if (
      this.props.location.pathname !== '/Twilio' && 
      this.props.location.pathname !== '/reset-password-form' &&
      this.props.location.pathname !== '/Admin' &&
      currentVet &&
      currentAccount &&
      !this.state.videoCallRequestStatus &&
      !this.state.videoCallRequest &&
      !this.state.isCallInProgress
    ) {
      if (
        (await this.isAvailable()) &&
        currentAccount.id &&
        (!this.videoCallSubscription || this.videoCallSubscription.closed)
      ) {
        this.subscribeToNewVideoCallRequests(currentAccount.id);
      } else if (!this.isAvailable() && this.videoCallSubscription)
        this.unsubscribeToNewVideoCallRequests();
    }

    window.addEventListener('beforeunload', this.unsubscribeToNewVideoCallRequests);
  }

  public async componentDidUpdate() {
    const currentAccount = this.props.currentAccount;
    const currentVet = this.props.currentVet;
    if(localStorage.getItem('callInProgress') === 'false' && this.state.isCallInProgress) {
      localStorage.removeItem('callInProgress');
      this.setState({isCallInProgress: false}); 
    }
    if (
      this.props.location.pathname !== '/Twilio' &&
      currentVet &&
      currentAccount &&
      !this.state.videoCallRequestStatus &&
      !this.state.videoCallRequest &&
      !this.state.isCallInProgress
    ) {
      if (
        (await this.isAvailable()) &&
        currentAccount.id &&
        (!this.videoCallSubscription || this.videoCallSubscription.closed)
      ) {
        this.subscribeToNewVideoCallRequests(currentAccount.id);
      } else if (!this.isAvailable() && this.videoCallSubscription)
        this.unsubscribeToNewVideoCallRequests();
    }
  }

  // private wakeSentinel: WakeLockSentinel | null = null;

  // public async requestWakeLock() {
  //   if (!this.wakeSentinel) {
  //     try {
  //       this.wakeSentinel = await (navigator as NavigatorExtended).wakeLock.request('screen');
  //       this.wakeSentinel.addEventListener('release', () => {
  //         this.wakeSentinel = null;
  //       });
  //     } catch (error) {
  //       console.log('wakeLock error: ', error);
  //     }
  //   }
  // }

  // public async releaseWakeLock() {
  //   if (this.wakeSentinel) {
  //     try {
  //       await this.wakeSentinel.release();
  //       this.wakeSentinel = null;
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // }

  public checkIfSoundIsPlayable() {
    sound.muted = true;
    const playedPromise = sound.play();
    if (playedPromise) {
      playedPromise
        .catch((e) => {
          console.log(e);
          this.setState({ askActionToEnableSound: true });

          // switch (e) {
          //   case 'NotAllowedError':
          //     break;
          //   case 'NotSupportedError':
          //     break;
          // }
        })
        .then(() => {
          sound.pause();
          sound.muted = false;
        });
    }
  }

  public componentWillUnmount() {
    this.unsubscribeToNewVideoCallRequests();
    window.removeEventListener('beforeunload', this.unsubscribeToNewVideoCallRequests);
    document.body.removeEventListener('click', this.enableSound);
  }

  private subscribeToNewVideoCallRequests = (accountId: string) => {
    if (this.videoCallSubscription && !this.videoCallSubscription.closed) {
      this.unsubscribeToNewVideoCallRequests();
    }

    //check twilio video browser support
    const twilioBrowserIsSupported = Video.isSupported;
    if (!twilioBrowserIsSupported) {
      return;
    }

    //localStorage.removeItem('callInProgress');
    //checking sound availability
    this.checkIfSoundIsPlayable();

    this.videoCallSubscription = this.props.client
      .subscribe({
        query: newVideoCallRequest,
        variables: {
          accountId,
        },
      })
      .subscribe({
        next: ({ data }: any) => {
          if (data.newVideoCallRequest.type === 'CREATED') {
            this.setState({ tarification: data.newVideoCallRequest.tarification });
            this.onNewVideoCallRequest(data.newVideoCallRequest.videoCallRequest);
          } else if (data.newVideoCallRequest.type === 'CANCELED') {
            this.cancelVideoCallRequest(
              data.newVideoCallRequest.type,
              data.newVideoCallRequest.videoCallRequest
            );
          } else if (data.newVideoCallRequest.type === 'ACCEPTED_BY_ANOTHER_VET') {
            this.cancelVideoCallRequest(
              data.newVideoCallRequest.type,
              data.newVideoCallRequest.videoCallRequest
            );
          } else if (data.newVideoCallRequest.type === 'TIMED_OUT') {
            this.cancelVideoCallRequest(
              data.newVideoCallRequest.type,
              data.newVideoCallRequest.videoCallRequest
            );
          }
        },
      });
    // this.requestWakeLock();
  };

  private cancelVideoCallRequest = (
    status: string,
    videoCallRequest: State['videoCallRequest']
  ) => {
    sound.pause();

    this.setState({
      videoCallRequest: undefined,
      videoCallRequestStatus:
        videoCallRequest?.id === this.state.videoCallRequest?.id ? status : '',
    });
  };

  private unsubscribeToNewVideoCallRequests = () => {
    this.videoCallSubscription && this.videoCallSubscription.unsubscribe();
    // this.releaseWakeLock();
  };

  public soundFixListener: any;

  private async onNewVideoCallRequest(videoCallRequest: State['videoCallRequest']) {
    const playedPromise = sound.play();
    if (playedPromise) {
      playedPromise.catch((e) => {
        console.log(e);
        this.setState({ askActionToEnableSound: true });

        // switch (e) {
        //   case 'NotAllowedError':
        //     break;
        //   case 'NotSupportedError':
        //     break;
        // }
      });
      // .then(() => {});
    }

    if (!this.state.videoCallRequest) {
      this.setState({
        videoCallRequest,
        videoCallRequestStatus: '',
      });
    }
  }

  private onAcceptVideoCallHandler = () => {
    const verifiedVeterinarianList = this.context.companyVeterinarians?.filter(
      (data) => data.moderationStatus === 'VERIFIED'
    );
    // TODO: SHOULD VERIFY COMPANY_ACCOUNTS MODERATIONSTATUS ?
    // SHOULD REMOVE AVAILABLE VET PROFILE ? CAN CAUSE PB IF VET DOESN'T SET ISAVAILABLE TO FALSE MANUALLY
    if (this.context?.getIsCompanyAdminByAccountId(this.context.currentAccount?.id)) {
      if (
        verifiedVeterinarianList &&
        (verifiedVeterinarianList.length > 1 ||
          (verifiedVeterinarianList.length === 1 &&
            !verifiedVeterinarianList.find((data) => data.id === this.context.currentVet?.id)))
      )
        // render veterinarian select
        this.setState({ showCompanyVeterinarianSelect: true });
      else {
        this.setState({ showCompanyVeterinarianSelect: false });
        this.onAcceptVideoCall();
      }
    } else {
      this.setState({ showCompanyVeterinarianSelect: false });
      this.onAcceptVideoCall();
    }
  };

  // TODO ADD A MODAL TO CHOOSE VETERINARIAN IF IS COMPANY ADMIN ACCOUNT
  private onAcceptVideoCall = async (veterinarianId?: string) => {
    const videoCallId = await this.respondToVideoCallRequest(true, veterinarianId);
    const videoCallRequest = this.state.videoCallRequest;
    if (videoCallId && videoCallRequest) {
      localStorage.setItem('callInProgress', 'true');
      this.setState({isCallInProgress: true});
      setTimeout(() => {
        this.setState({ videoCallRequestStatus: '', videoCallRequest: undefined });
        this.unsubscribeToNewVideoCallRequests();
        this.props.history.push('/Twilio', {
          videoCallId,
          petName: videoCallRequest.videoCallIntent.customerPet.name,
          petTrouble: videoCallRequest.videoCallIntent.petTrouble,
          raceId: videoCallRequest.videoCallIntent.customerPet.petCategory,
          species: videoCallRequest.videoCallIntent.customerPet.specie
            ? videoCallRequest.videoCallIntent.customerPet.specie
            : '',
          isFreecall: videoCallRequest.videoCallIntent.isFreeCall,
      });
      }, 1000);
    }
  };

  private onDeclineVideoCall = async () => {
    await this.respondToVideoCallRequest(false);

    this.setState({
      videoCallRequest: undefined,
    });
  };

  private respondToVideoCallRequest = async (hasAccepted: boolean, veterinarianId?: string) => {
    sound.pause();

    if (!this.state.videoCallRequest) return;

    const { id } = this.state.videoCallRequest;
    const currentVet = this.props.currentVet;

    if (!currentVet) return;
    try {
      const result = await this.props.client.mutate({
        mutation: respondToVideoCallRequest,
        variables: {
          veterinarianId: veterinarianId ? veterinarianId : currentVet.id,
          videoCallRequestId: id,
          hasAccepted,
        },
      });

      this.setState({ showCompanyVeterinarianSelect: false });
      return result.data.respondToVideoCallRequest;
    } catch (error) {
      if (
        error.graphQLErrors &&
        error.graphQLErrors[0] &&
        error.graphQLErrors[0].message &&
        error.graphQLErrors[0].message.message
      ) {
        if (error.graphQLErrors[0].message.message === 'ACCEPTED_BY_ANOTHER_VET')
          this.setState({
            videoCallRequest: undefined,
            videoCallRequestStatus: 'ACCEPTED_BY_ANOTHER_VET',
            showCompanyVeterinarianSelect: false,
          });
        else {
          this.setState({
            videoCallRequest: undefined,
            videoCallRequestStatus: error.graphQLErrors[0].message.message,
            showCompanyVeterinarianSelect: false,
          });
        }
      }
    }
  };

  private resetVideoCallRequestStatus = () => {
    this.setState({ videoCallRequestStatus: '' });
  };

  // these translations can be moved to shared ?
  private getCallTarification(tarification?: TarificationProps) {
    if (tarification === 'regular') {
      return i18n.t('twilio.callInfoDetails.regularTarification');
    } else if (tarification === 'slightlyIncreased') {
      return i18n.t('twilio.callInfoDetails.slightlyIncreasedTarification');
    } else if (tarification === 'increased') {
      return i18n.t('twilio.callInfoDetails.increasedTarification');
    } else {
      return i18n.t('twilio.callInfoDetails.unknown');
    }
  }

  private getCompanyVeterinariansListFormatted = () => {
    if (!this.props.companyVeterinarians) return [];
    const customFlatListDataCompanyVeterinarians = this.props.companyVeterinarians
      ?.filter((data) => data.moderationStatus === 'VERIFIED')
      .map((veterinarian: Veterinarian) => {
        return {
          leftLabel: veterinarian.account.firstName + ' ' + veterinarian.account.lastName,
          rightIcon: IconChevronRightDisclosure,
          onClick: () => this.onAcceptVideoCall(veterinarian.id),
        };
      });
    return customFlatListDataCompanyVeterinarians;
  };

  public renderCard() {
    if (this.state.askActionToEnableSound === true) {
      document.body.addEventListener('click', this.enableSound);
      return <VideoCallStatusCard hide={this.enableSound} type={'enableSound'} />;
    }
    if (this.state.videoCallRequest || this.state.videoCallRequestStatus !== '') {
      switch (this.state.videoCallRequestStatus) {
        case 'ACCEPTED_BY_ANOTHER_VET':
          return (
            <VideoCallStatusCard
              hide={this.resetVideoCallRequestStatus}
              type={this.state.videoCallRequestStatus}
            />
          );
        case 'TIMED_OUT':
          return (
            <VideoCallStatusCard
              hide={this.resetVideoCallRequestStatus}
              type={this.state.videoCallRequestStatus}
            />
          );
        case 'CANCELED':
          return (
            <VideoCallStatusCard
              hide={this.resetVideoCallRequestStatus}
              type={this.state.videoCallRequestStatus}
            />
          );
      }
      if (this.state.videoCallRequest) {
        return (
          <>
            <ModalCmv
              show={!!this.state.videoCallRequest}
              onClickOutsideCloseModal={() => null}
              headerTitle={i18n.t('videoCallHandler.videoCallRequest.title')}
              contentViewPreTitle={this.getCallTarification(this.state.tarification)}
              contentViewSubTitle={i18n.t('videoCallHandler.videoCallRequest.subtitle', {
                customerGender:
                  this.state.videoCallRequest.videoCallIntent.customer.gender === 'Autre'
                    ? ''
                    : this.state.videoCallRequest.videoCallIntent.customer.gender,
                customerFirstName: this.state.videoCallRequest.videoCallIntent.customer.account
                  .firstName,
                petName: this.state.videoCallRequest.videoCallIntent.customerPet.name,
              })}
              contentViewCustomComponent={
                <VideoCallRequest
                  raceId={this.state.videoCallRequest.videoCallIntent.customerPet.petCategory}
                  species={
                    this.state.videoCallRequest.videoCallIntent.customerPet.specie
                      ? this.state.videoCallRequest.videoCallIntent.customerPet.specie
                      : ''
                  }
                  petName={this.state.videoCallRequest.videoCallIntent.customerPet.name}
                  petTrouble={this.state.videoCallRequest.videoCallIntent.petTrouble}
                  petProfilePicture={
                    this.state.videoCallRequest.videoCallIntent.customerPet.profilePicture?.url
                  }
                  customerFirstName={
                    this.state.videoCallRequest.videoCallIntent.customer.account.firstName
                  }
                  customerGender={this.state.videoCallRequest.videoCallIntent.customer.gender}
                />
              }
              footerCustomComponent={
                <VideoCallRequestButtons
                  onAcceptVideoCall={this.onAcceptVideoCallHandler}
                  onDeclineVideoCall={this.onDeclineVideoCall}
                />
              }
            />
            <ModalCmv
              show={!!this.state.videoCallRequest && this.state.showCompanyVeterinarianSelect}
              onClickOutsideCloseModal={() => null}
              headerTitle={i18n.t('videoCallHandler.videoCallRequest.chooseVeterinarian.title')}
              headerIconLeft={IconBack}
              headerOnClickLeft={() => this.onDeclineVideoCall()}
              contentViewCustomComponent={
                <CustomFlatList data={this.getCompanyVeterinariansListFormatted()} />
              }
            />
          </>
        );
      }
    }
  }

  private enableSound = () => {
    this.setState({ askActionToEnableSound: false });
    if (this.state.videoCallRequest) sound.play();
    document.body.removeEventListener('click', this.enableSound);
  };

  public render() {
    if (
      (this.state.videoCallRequest ||
      this.state.videoCallRequestStatus !== '' ||
      this.state.askActionToEnableSound) &&
      !this.state.isCallInProgress
    )
      return <Content>{this.renderCard()}</Content>;
    else return <></>;
  }
}

const Content = styled.div``;
