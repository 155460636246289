import React from 'react';
import { Account, Company, Veterinarian } from '../types/DbInterface';

// note: always use WithContext wrapper on your component to use this context

export interface AppContextInterface {
  currentVet?: Veterinarian;
  updateCurrentVet: (currentVet: Veterinarian) => void;
  currentAccount?: Account;
  updateCurrentAccount: (currentAccount: Account) => void;
  currentCompany?: Company;
  updateCurrentCompany: (currentCompany: Company) => void;
  companyVeterinarians?: Veterinarian[];
  updateContext: () => void;
  // updatecompanyVeterinarians: (companyVeterinarians: Veterinarian[]) => void;
  getIsCompanyAdminByAccountId: (accountId?: string) => boolean;
  getVeterinarianFromListById: (accountId?: string) => Veterinarian | undefined;
  // accountHasAnyVet: () => boolean;
  companyCode?: Company;
  updateCompanyCode: (companyCode: Company) => void;
}

// Context object, use it to retrieve context from Class Components
// (getting context through static - ex Dashboard)
export const appContext = React.createContext<AppContextInterface>({
  updateCurrentVet: () => null,
  updateCurrentAccount: () => null,
  updateCurrentCompany: () => null,
  updateContext: () => null,
  getIsCompanyAdminByAccountId: () => false,
  getVeterinarianFromListById: () => undefined,
  updateCompanyCode: () => null,
});

// Context Provider, used in root App to provide values to context
export const AppContextProvider = appContext.Provider;

// Context Consumer, can be used to retrieve context in render function easily
export const AppContextConsumer = appContext.Consumer;

/*
Example using Consumer in render:

<AppContextConsumer>
  {(appContext) => {
    console.log('test:', appContext);
    return <></>;
  }}
</AppContextConsumer>;
*/
