import React, { Component } from 'react';
import styled from 'styled-components';

export class Separator extends Component {
  public render = () => {
    return <SeparatorHr />;
  };
}

const SeparatorHr = styled.hr`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: ${(props) => props.theme.colors.grey.lighter};
`;
