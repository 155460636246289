import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './SubNavBar.container';
import { i18n } from '../../../lib/i18n';
import { appContext } from '../../../context/appContext';
import { loader } from 'graphql.macro';
import { client } from '../../../graphql/client';
import { Loader } from '../../Loader';

const getFareByCompany = loader('../../../graphql/getFareByCompany.graphql');
export interface State {
  faresByCompany?: boolean;
  loading?: boolean;
}
export class SubNavBar extends Component<Props, State> {
  public state: State = {
    faresByCompany: false,
    loading: true,
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public async componentDidMount() {
    await this.context.updateContext();
    try {
      const response = await client.query({
        query: getFareByCompany,
        fetchPolicy: 'network-only',
      });
      if(response.data.getTareByCompany?.fare.length === 0) {
        this.setState({faresByCompany: false});
      } else {
        this.setState({faresByCompany: true});
      }
    } catch (error) {
      console.log(error);
    }   
    this.setState({loading: false});  
  }

  public render() {
    const { content, moderationStatus } = this.props;
    const company = this.context.currentCompany;
    const vet = this.context.currentVet;
    const is_company_admin = this.context.getIsCompanyAdminByAccountId(this.context.currentAccount?.id);
    if (this.state.loading && (company || vet) && (company?.moderationStatus !== 'VERIFIED' || vet?.moderationStatus !== 'VERIFIED')) {
      return <SubNavBarLoaderContainer>
              <Loader inline="centered" active={true} size={'small'}/> 
            </SubNavBarLoaderContainer>;
    }
    return (
      <React.Fragment>
        
        
        { company && 
          vet && 
          this.state.faresByCompany && 
          company.stripeCompleted && 
          ( (company.moderationStatus === 'PENDING_VERIFICATION' && 
             vet.moderationStatus === 'PENDING_VERIFICATION') || 
             (company.moderationStatus === 'PENDING_VERIFICATION' &&
             vet.moderationStatus === 'VERIFIED' ) ||
             (company.moderationStatus === 'VERIFIED' &&
             vet.moderationStatus === 'PENDING_VERIFICATION' )
          ) &&
            <ModerationStatusPendingContainer>
              <ModerationStatusPendingContent><ModerationStatusPendingContentIcon>!</ModerationStatusPendingContentIcon> {i18n.t('moderationStatusWarning.pendingVerification')}</ModerationStatusPendingContent>
            </ModerationStatusPendingContainer>
        }
        { company && is_company_admin && (company.moderationStatus === 'NOT_COMPLETED' || (vet && vet.moderationStatus === 'NOT_COMPLETED') || !this.state.faresByCompany || !company.stripeCompleted ) &&
          <ModerationStatusMissingContainer>
            <ModerationStatusMissingContent><ModerationStatusMissingContentIcon>!</ModerationStatusMissingContentIcon> {i18n.t('moderationStatusWarning.missingVerification')}</ModerationStatusMissingContent>
          </ModerationStatusMissingContainer>
        }
        { company && !is_company_admin && !vet &&
          <ModerationStatusMissingContainer>
            <ModerationStatusMissingContent><ModerationStatusMissingContentIcon>!</ModerationStatusMissingContentIcon> {i18n.t('moderationStatusWarning.missingVerification')}</ModerationStatusMissingContent>
          </ModerationStatusMissingContainer>
        }
        { company && !is_company_admin && vet && (company.moderationStatus === 'NOT_COMPLETED' || vet.moderationStatus === 'NOT_COMPLETED' || !this.state.faresByCompany || !company.stripeCompleted ) &&
          <ModerationStatusMissingContainer>
            <ModerationStatusMissingContent><ModerationStatusMissingContentIcon>!</ModerationStatusMissingContentIcon> {i18n.t('moderationStatusWarning.missingVerification')}</ModerationStatusMissingContent>
          </ModerationStatusMissingContainer>
        }
         { (content || (!company && !vet) || (company?.moderationStatus === 'VERIFIED' && vet?.moderationStatus === 'VERIFIED')) && 
          <SubNavBarContainer>
            <SubNavBarContent>{content}</SubNavBarContent> 
          </SubNavBarContainer>
        } 
      </React.Fragment>
    );
  }
}

const SubNavBarContainer = styled.div`
  width: 100%;
  height: 43px;
  background-color: ${(props) => props.theme.colors.primary.mainColor};
  display: flex;
  justify-content: center;
  position: fixed;
  top: 60px; /* Navbar height */
  z-index: 1000000;
  min-width: ${(props) => props.theme.breakpoints.mobilePortraitMin};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    display: none;
  }
`;

const SubNavBarLoaderContainer = styled.div`
  width: 100%;

  background-color: ${(props) => props.theme.colors.primary.mainColor};
  display: flex;
  justify-content: center;
  position: fixed;
  top: 60px; /* Navbar height */
  z-index: 1000000;
  min-width: ${(props) => props.theme.breakpoints.mobilePortraitMin};
  padding: 10px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    display: none;
  }
`;

const SubNavBarContent = styled.p`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.white.pure};
  font-family: ${(props) => props.theme.textStyles.typography.lead.small.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.small.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.lead.small.fontSize};
  line-height: 16px;
  margin-left: 15px;

  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.laptop15Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.laptop15Max}) {
    width: 1200px;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.desktop21Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.desktop21Max}) {
    width: 1440px;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop27Min}) {
    width: 1680px;
  }
`;

const ModerationStatusPendingContainer = styled.div`
  width: 100%;
  
  background-color: #009d0f;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 60px; /* Navbar height */
  z-index: 1000000;
  min-width: ${(props) => props.theme.breakpoints.mobilePortraitMin};
`;

const ModerationStatusMissingContainer = styled.div`
  width: 100%;
    
  background-color: #ff9901;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 60px; /* Navbar height */
  z-index: 1000000;
  min-width: ${(props) => props.theme.breakpoints.mobilePortraitMin};
`;

const ModerationStatusPendingContent = styled.p`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.white.pure};
  font-family: ${(props) => props.theme.textStyles.typography.lead.small.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.small.fontWeight};
  font-size: 1rem;
  line-height: 16px;
  padding: 1rem;
  gap: 1rem;

  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.laptop15Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.laptop15Max}) {
    width: 1200px;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.desktop21Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.desktop21Max}) {
    width: 1440px;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop27Min}) {
    width: 1680px;
  }
`;

const ModerationStatusMissingContent = styled.p`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.white.pure};
  font-family: ${(props) => props.theme.textStyles.typography.lead.small.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.small.fontWeight};
  font-size: 1rem;
  line-height: 16px;
  padding: 1rem;
  gap: 1rem;

  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.laptop15Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.laptop15Max}) {
    width: 1200px;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.desktop21Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.desktop21Max}) {
    width: 1440px;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop27Min}) {
    width: 1680px;
  }

`;

const ModerationStatusPendingContentIcon = styled.span`
  border-radius: 50%;
  background-color: white;
  width: 16px;
  height: 16px;
  color: #009d0f;
  text-align: center;
`;

const ModerationStatusMissingContentIcon = styled.span`
  border-radius: 50%;
  background-color: white;
  width: 16px;
  height: 16px;
  color: #ff9901;
  text-align: center;
`;
