import React, { Component } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { ThemeComponents } from '../../../theme/components';
import { Props } from './TextInputForm.container';

export class TextInputForm extends Component<Props> {
  public render() {
    const {
      form,
      label,
      sublabel,
      placeholder,
      field,
      required,
      direction,
      type,
      disabled,
      autoComplete,
    } = this.props;
    const error = form.errors[field.name];
    const touched = form.touched[field.name];

    if (direction === 'column') {
      return (
        <TextInputColumnContainer>
          <TextInputLabelContainer>
            <TextInputLabel>{label}</TextInputLabel>
            <TextInputSubLabel>{sublabel}</TextInputSubLabel>
          </TextInputLabelContainer>
          <TextInput
            {...field}
            type={type}
            autoComplete={autoComplete}
            placeholder={placeholder && required ? `${placeholder + ' *'}` : placeholder}
            error={error}
            touched={touched}
            disabled={disabled}
          />
          {touched && error && <TextInputError>{error}</TextInputError>}
        </TextInputColumnContainer>
      );
    } else {
      return (
        <TextInputAutoContainer fluid>
          <TextInputRow noGutters>
            {(label || sublabel) && (
              <Col xs={12} sm={4}>
                <TextInputLabelContainer>
                  <TextInputLabel>{label}</TextInputLabel>
                  <TextInputSubLabel>{sublabel}</TextInputSubLabel>
                </TextInputLabelContainer>
              </Col>
            )}
            <Col xs={12} sm={label || sublabel ? 8 : 12}>
              <TextInput
                {...field}
                type={type}
                autoComplete={autoComplete}
                placeholder={placeholder && required ? `${placeholder + ' *'}` : placeholder}
                error={error}
                touched={touched}
                disabled={disabled}
              />
              {touched && error && <TextInputError>{error}</TextInputError>}
            </Col>
          </TextInputRow>
        </TextInputAutoContainer>
      );
    }
  }
}

const TextInputAutoContainer = styled(Container)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 16px 0;
  padding: 0;
`;

const TextInputRow = styled(Row)`
  width: 100%;
`;

const TextInput = styled(ThemeComponents.FormInput)<{
  error: string;
  touched: boolean;
  disabled: boolean;
}>`
  border-color: ${(props) => {
    const colorType =
      !!props.error && props.touched
        ? props.theme.colors.red.dark
        : props.theme.colors.white.darker;
    return colorType;
  }};
  background: ${(props) => (props.disabled ? props.theme.colors.white.dark : '')};
  color: ${(props) => (props.disabled ? props.theme.colors.grey.darker : '')};
`;

const TextInputLabel = styled(ThemeComponents.FormLabel)``;

const TextInputSubLabel = styled(ThemeComponents.FormSubLabel)``;

const TextInputError = styled(ThemeComponents.FormErrorLabel)``;

const TextInputLabelContainer = styled.div`
  margin: 5px 15px 5px 0;
`;

const TextInputColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  position: relative;
`;
