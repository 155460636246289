import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './ProfileMenuConnected.container';
import { ReactComponent as IconChevronRightDisclosure } from '../../../../theme/icons/IconChevronRightDisclosure.svg';
import { Firebase } from '../../../../lib/firebase';

export class ProfileMenuConnected extends Component<Props> {
  private profileMenuItems = [
    {
      title: 'Déconnexion',
      endOfSection: true,
      onClick: () => this.signOut(),
    },
  ];

  private signOut = async () => {
    await Firebase.signOut();
  };

  public render() {
    return (
      <ProfileMenuConnectedContainer>
        {this.profileMenuItems.map((item, i) => (
          <ProfileMenuItem key={i} endOfSection={item.endOfSection} onClick={item.onClick}>
            <SideMenuNotConnectedItemlLeft>
              <ProfileMenuItemTitle>{item.title}</ProfileMenuItemTitle>
            </SideMenuNotConnectedItemlLeft>
            <ProfileMenuItemRight>
              <IconChevronRightDisclosure />
            </ProfileMenuItemRight>
          </ProfileMenuItem>
        ))}
      </ProfileMenuConnectedContainer>
    );
  }
}

const ProfileMenuConnectedContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.darker};
`;

const ProfileMenuItem = styled.button<{ endOfSection: boolean | undefined }>`
  width: 100%;
  padding: 10px 20px;
  border: none;
  background: ${(props) => props.theme.colors.white.dark};
  box-shadow: inset 0px -1px 0px rgba(23, 23, 23, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: ${(props) => (props.endOfSection ? '20px' : 0)};

  :focus {
    outline: none;
    background: ${(props) => props.theme.colors.blue.main};
  }

  :active {
    background: ${(props) => props.theme.colors.blue.main};
  }
`;

const SideMenuNotConnectedItemlLeft = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileMenuItemTitle = styled.div`
  margin-left: 7px;
`;

const ProfileMenuItemRight = styled.div``;
