import { ProfileMenu as ProfileMenuComponent } from './ProfileMenu.component';
import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../../lib/withApolloClient';
import { RouteComponentProps } from 'react-router-dom';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';

export type ContainerProps = {
  isConnected: boolean;
};

export type Props = WithApolloClient<RouteComponentProps & ContainerProps>;

export const ProfileMenu = compose<Props, ContainerProps>(
  withApollo,
  withRouter
)(ProfileMenuComponent);
