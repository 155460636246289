import { TextInputForm as TextInputFormComponent } from './TextInputForm.component';

export type Props = {
  label?: string;
  sublabel?: string;
  placeholder?: string;
  required?: boolean;
  direction?: 'column' | 'auto';
  disabled?: boolean;
  form: any;
  field: any;
  type?: string;
  autoComplete?: string;
};

export const TextInputForm = TextInputFormComponent;
