import React, { Component } from 'react';
// import styled from 'styled-components';
// import { ReactComponent as VideoCallDecline } from '../../../theme/icons/VideoCallDecline.svg';
// import { ReactComponent as VideoCallTimeout } from '../../../theme/icons/VideoCallTimeout.svg';
// import { ReactComponent as VideoCancelByUser } from '../../../theme/icons/VideoCancelByUser.svg';
import { i18n } from '../../../lib/i18n';
// import { Typo } from '../../../theme/typo';
import { ModalCmv } from '../../ModalCmv';
import images from '../../../theme/images';
import { ReactComponent as IconCrossDelete } from '../../../theme/icons/IconCrossDelete.svg';
import { detect } from 'detect-browser';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

interface Props {
  hide: () => void;
  type: string;
}

export class VideoCallStatusCard extends Component<Props> {
  /* TODO delete all comments if stagging tests are OK */

  // private renderCardByType(type: string) {
  //   switch (type) {
  //     case 'ACCEPTED_BY_ANOTHER_VET':
  //       return this.renderAcceptedByAnotherVet();
  //     case 'TIMED_OUT':
  //       return this.renderTimedOut();
  //     case 'CANCELED':
  //       return this.renderCancelled();
  //     case 'enableSound':
  //       return this.renderEnableGesture();
  //   }
  // }

  // public renderAcceptedByAnotherVet() {
  //   return (
  //     <Container>
  //       <VideoCallDecline style={{ marginTop: -50 }} />
  //       <TextsContainer>
  //         <Subtitle>
  //           {i18n.t('videoCallHandler.videoCallStatusCard.alreadyAcceptedCall.subtitle')}
  //         </Subtitle>
  //         <Subtitle>
  //           {i18n.t('videoCallHandler.videoCallStatusCard.alreadyAcceptedCall.subtitle2')}
  //         </Subtitle>
  //       </TextsContainer>
  //     </Container>
  //   );
  // }

  // public renderTimedOut() {
  //   return (
  //     <Container>
  //       <VideoCallTimeout style={{ marginTop: -50 }} />
  //       <TextsContainer>
  //         <Subtitle>{i18n.t('videoCallHandler.videoCallStatusCard.timedOutCall.subtitle')}</Subtitle>
  //         <Subtitle>{i18n.t('videoCallHandler.videoCallStatusCard.timedOutCall.subtitle2')}</Subtitle>
  //       </TextsContainer>
  //     </Container>
  //   );
  // }

  // public renderCancelled() {
  //   return (
  //     <Container>
  //       <VideoCancelByUser style={{ marginTop: -50 }} />
  //       <TextsContainer>
  //         {/* <Title>{i18n.t('videoCallHandler.alreadyAcceptedVideoCallRequest.title')}</Title> */}
  //         <Subtitle>{i18n.t('videoCallHandler.videoCallStatusCard.cancelledCall.subtitle')}</Subtitle>
  //         <Subtitle>{i18n.t('videoCallHandler.videoCallStatusCard.cancelledCall.subtitle2')}</Subtitle>
  //       </TextsContainer>
  //     </Container>
  //   );
  // }

  // public renderEnableGesture() {
  //   return (
  //     <div style={{ flexDirection: 'column' }}>
  //       <Container>
  //         <VideoCallTimeout style={{ marginTop: -50 }} />
  //         <TextsContainer>
  //           <Subtitle>
  //             {i18n.t('videoCallHandler.videoCallStatusCard.enableGestureForSound.subtitle')}
  //           </Subtitle>
  //         </TextsContainer>
  //       </Container>
  //     </div>
  //   );
  // }

  public getEnableSoundProcedureByBrowser(name?: string) {
    switch (name) {
      case 'firefox':
        return (
          <>
            <SoundProcedureFirstStep>
              <Trans
                components={{
                  italic: <i />,
                  bold: <CustomStrongText />,
                  leftAlignText: <LeftAlignText />,
                  title: <CustomTitle />,
                }}
                i18nKey="videoCallHandler.videoCallStatusCard.enableGestureForSound.procedure.firefox"
              ></Trans>
            </SoundProcedureFirstStep>
          </>
        );
      case 'chrome':
        return (
          <>
            <SoundProcedureFirstStep>
              <Trans
                components={{
                  italic: <i />,
                  bold: <CustomStrongText />,
                  leftAlignText: <LeftAlignText />,
                  title: <CustomTitle />,
                }}
                i18nKey="videoCallHandler.videoCallStatusCard.enableGestureForSound.procedure.chrome"
              ></Trans>
            </SoundProcedureFirstStep>
          </>
        );
      case 'safari':
        return (
          <>
            <SoundProcedureFirstStep>
              <Trans
                components={{
                  italic: <i />,
                  bold: <CustomStrongText />,
                  leftAlignText: <LeftAlignText />,
                  title: <CustomTitle />,
                }}
                i18nKey="videoCallHandler.videoCallStatusCard.enableGestureForSound.procedure.safari"
              ></Trans>
            </SoundProcedureFirstStep>
          </>
        );
      default:
        return '';
    }
  }

  public getEnableSoundImageByBrowser(isRetina: boolean, name?: string) {
    switch (name) {
      case 'firefox':
        return isRetina ? images.FirefoxLogo : images.FirefoxLogo2x;
      case 'chrome':
        return isRetina ? images.ChromeLogo : images.ChromeLogo2x;
      case 'safari':
        return isRetina ? images.SafariLogo : images.SafariLogo2x;
      default:
        return isRetina ? images.HugoPageNotFound2x : images.HugoPageNotFound;
    }
  }

  public render() {
    const { hide, type } = this.props;
    const browser = detect();
    return (
      <>
        <ModalCmv
          show={type === 'enableSound'}
          onClickOutsideCloseModal={() => hide()}
          headerTitle={i18n.t('videoCallHandler.videoCallStatusCard.enableGestureForSound.title')}
          headerIconLeft={IconCrossDelete}
          headerOnClickLeft={() => hide()}
          contentViewSubTitle={i18n.t(
            'videoCallHandler.videoCallStatusCard.enableGestureForSound.subtitle'
          )}
          contentViewCustomComponent={this.getEnableSoundProcedureByBrowser(browser?.name)}
          contentViewImage={this.getEnableSoundImageByBrowser(false, browser?.name)}
          contentViewImageRetina={this.getEnableSoundImageByBrowser(true, browser?.name)}
        />
        <ModalCmv
          show={type === 'ACCEPTED_BY_ANOTHER_VET'}
          onClickOutsideCloseModal={() => hide()}
          headerTitle={i18n.t('videoCallHandler.videoCallStatusCard.alreadyAcceptedCall.title')}
          headerIconLeft={IconCrossDelete}
          headerOnClickLeft={() => hide()}
          contentViewBodyContent={i18n.t(
            'videoCallHandler.videoCallStatusCard.alreadyAcceptedCall.subtitle'
          )}
          contentViewImage={images.ModaleIllustrationVideoCallDecline}
          contentViewImageRetina={images.ModaleIllustrationVideoCallDecline2x}
        />
        <ModalCmv
          show={type === 'TIMED_OUT'}
          onClickOutsideCloseModal={() => hide()}
          headerTitle={i18n.t('videoCallHandler.videoCallStatusCard.timedOutCall.title')}
          headerIconLeft={IconCrossDelete}
          headerOnClickLeft={() => hide()}
          contentViewBodyContent={i18n.t(
            'videoCallHandler.videoCallStatusCard.timedOutCall.subtitle'
          )}
          contentViewImage={images.ModaleIllustrationVideoCallTimeout}
          contentViewImageRetina={images.ModaleIllustrationVideoCallTimeout2x}
        />
        <ModalCmv
          show={type === 'CANCELED'}
          onClickOutsideCloseModal={() => hide()}
          headerTitle={i18n.t('videoCallHandler.videoCallStatusCard.cancelledCall.title')}
          headerIconLeft={IconCrossDelete}
          headerOnClickLeft={() => hide()}
          contentViewBodyContent={i18n.t(
            'videoCallHandler.videoCallStatusCard.cancelledCall.subtitle'
          )}
          contentViewImage={images.ModaleIllustrationVideoCallCancelByUser}
          contentViewImageRetina={images.ModaleIllustrationVideoCallCancelByUser2x}
        />
        {/* {this.renderCardByType(type)} */}
      </>
    );
  }
}

// const Container = styled.div`
//   padding: ${(props) => props.theme.spacing.unit * 15}px
//     ${(props) => props.theme.spacing.unit * 4}px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

// const TextsContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
// `;

// const Subtitle = styled(Typo.ButtonLabel)`
//   display: flex;
//   align-items: center;
//   text-align: center;
//   font-family: ${(props) => props.theme.textStyles.typography.body.main.fontFamily};
//   font-weight: ${(props) => props.theme.textStyles.typography.body.main.fontWeight};
//   font-size: ${(props) => props.theme.textStyles.typography.body.main.fontSize};
//   color: ${(props) => props.theme.colors.primary.darker};
//   line-height: 24px;
// `;

const SoundProcedureFirstStep = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-family: ${(props) => props.theme.textStyles.typography.body.tiny.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.body.tiny.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.body.tiny.fontSize};
  color: ${(props) => props.theme.colors.black.light};
  line-height: 24px;
`;

const CustomTitle = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.body.small.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.body.small.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.body.small.fontSize};
  color: ${(props) => props.theme.colors.black.light};
  line-height: 17px;
  letter-spacing: 1px;
`;

const CustomStrongText = styled.strong`
  display: contents;
`;

const LeftAlignText = styled.div`
  text-align: left;
`;
