import React, { Component } from 'react';
import { loader } from 'graphql.macro';
import { Props } from './Admin.container';
import styled from 'styled-components';
import { Dashboard } from './Dashboard';
import { SideMenu } from './SideMenu';
import { NavBarAdmin } from './NavBarAdmin';
import { SubNavBar } from '../../components/UiNavigation/SubNavBar';
import { MasterContainer } from '../../components/Layout/MasterContainer';
import { MasterBody } from '../../components/Layout/MasterBody';
import { VeterinarianModeration } from './VeterinarianModeration';
import { CompanyModeration } from './CompanyModeration';
import { VeterinarySchoolModeration } from './VeterinarySchoolModeration';

const isAdmin = loader('../../graphql/isAdmin.graphql');
// const getActiveVeterinariansCount = loader('../../graphql/getActiveVeterinariansCount.graphql');
// const getActiveCustomersCount = loader('../../graphql/getActiveCustomersCount.graphql');

interface State {
  // dataProvider?: DataProvider;
  hash: string;
}

export class AdminPage extends Component<Props, State> {
  public state: State = {
    // dataProvider: undefined,
    hash: '',
  };

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.location !== prevProps.location)
      this.setState({ hash: this.props.location.hash });
  }

  public async componentDidMount() {
    try {
      const response = await this.props.client.query<{ isAdmin?: boolean }>({ query: isAdmin });

      if (!response.data.isAdmin) return this.props.history.goBack();
    } catch (error) {
      return this.props.history.goBack();
    }
    this.setState({ hash: this.props.location.hash });
    // buildGraphQLProvider({
    //   client,
    // })
    //   .then((dataProvider) => this.setState({ dataProvider }))
    //   .catch(console.warn);

    // const veterinarianCount = await this.props.client.query({
    //   query: getActiveVeterinariansCount,
    //   fetchPolicy: 'network-only',
    // });
    // this.setState({ veterinarianCount: veterinarianCount.data.getActiveVeterinariansCount });

    // const customerCount = await this.props.client.query({
    //   query: getActiveCustomersCount,
    //   fetchPolicy: 'network-only',
    // });
    // this.setState({ customerCount: customerCount.data.getActiveCustomersCount });
  }
  public renderContent() {
    switch (this.state.hash) {
      case '#veterinarian-moderation':
        return <VeterinarianModeration {...this.props} />;
      case '#company-moderation':
        return <CompanyModeration {...this.props} />;
      case '#veterinary-school':
        return <VeterinarySchoolModeration {...this.props} />;
      // case '#identity':
      //   return <ProfileProofOfIdentity {...this.props} />;
      // case '#medical-expertise':
      //   return <ProfileSkills {...this.props} />;
      default:
        return <Dashboard {...this.props} />;
    }
  }

  public render() {
    // if (!this.state.dataProvider) return null;
    // const veterinarianCount = this.state.veterinarianCount;
    // const customerCount = this.state.customerCount;

    return (
      <React.Fragment>
        <NavBarAdmin />
        <SubNavBar />

        <MasterBody>
          <MainContainer>
            <SideMenu />
          </MainContainer>
          <MasterContainer>{this.renderContent()}</MasterContainer>
        </MasterBody>
      </React.Fragment>
    );
  }
}

const MainContainer = styled.div`
  width: 240px;
  height: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tabletMax}) {
    display: none;
  }
`;

// const StatContainer = styled.div
// `;
// //   position: fixed;
// //   bottom: 0;
// //   right: 0;
// //   left: 0;
// //   padding: 10;
// //   zIndex: 100;
// //   text-align: center;
// //   background-color: ${(props) => props.theme.mainColor};
// // `;
