export default {
  // signUp: {
  //   title: 'Tú también formas parte del equipo Callmyvet',
  //   navToLandingConfirmWarning:
  //     "¿Estás seguro de que quieres dejar el registro? Se perderán todos los datos introducidos",
  //   form: {
  //     title: 'Etapa {{currentStep}}/3',
  //     previousButton: 'Anterior ',
  //     fields: {
  //       firstName: {
  //         label: 'Nombre',
  //         placeholder: 'Nombre',
  //       },
  //       lastName: {
  //         label: 'Apellido',
  //         placeholder: 'Apellido',
  //       },
  //       address: {
  //         label: 'Direccion postal',
  //         placeholder: 'Direccion postal',
  //       },
  //       establishment: {
  //         label: 'Establecmiento ',
  //         placeholder: 'Establecmiento ',
  //       },
  //       orderNumber: {
  //         label: "Numero de orden",
  //         placeholder: "Numero de orden",
  //       },
  //       insurerName: {
  //         label: 'Nombre de su aseguradora',
  //         placeholder: 'Nombre de su aseguradora',
  //       },
  //       insuredNumber: {
  //         label: "Nombre de asegurado",
  //         placeholder: "Nombre de asegurado",
  //       },
  //       legalStatus: {
  //         label: 'Estatuto juridico ',
  //         placeholder: 'SARL',
  //       },
  //       orderCounty: {
  //         label: "Departamento de registro a la orden",
  //         placeholder: "Departamento de registro ",
  //       },
  //       speciality: {
  //         label: 'Su especialidad ',
  //         placeholder: 'Ex: oftalmología, cardiología, ortopedia o dermatología.',
  //       },
  //       siret: {
  //         label: 'SIRET',
  //         placeholder: 'Indique su numero de siret',
  //       },
  //       schoolName: {
  //         label: "Nombre de la escuela",
  //         placeholder: 'Nombre de tu escuela ',
  //       },
  //       graduationYear: {
  //         label: 'Graduacion',
  //         placeholder: 'Año',
  //       },
  //       uploadFileInput: {
  //         backSideLabel: "(Si documento de identidad)",
  //         placeholder: 'Elija un archivo',
  //         placeholderDescription: '.jpg, .png, .pdf',
  //       },
  //       idCardFrontSide: {
  //         label: 'RECTO',
  //       },
  //       idCardBackSide: {
  //         label: 'VERSO',
  //       },
  //       phoneNumber: {
  //         label: 'Telefono fijo',
  //         placeholder: '00 00 00 00 00',
  //       },
  //       mobilePhoneNumber: {
  //         label: 'Telefono movil',
  //         placeholder: '00 00 00 00 00',
  //       },
  //       email: {
  //         label: 'Direccion de correo electronico ',
  //         placeholder: 'Correo electronico ',
  //       },
  //       bankAccountName: {
  //         label: 'Nombre/razon social',
  //         placeholder: 'Nombre del beneficiario de la cuenta',
  //       },
  //       iban: {
  //         label: 'iban',
  //         placeholder: 'Ex: FR95 3000 2005 0000 1578 4590 P29',
  //       },
  //     },
  //     step1: {
  //       nextButton: 'Siguiente',
  //       subtitle: 'Informacion general',
  //       subpartTitle: {
  //         practicePlace: "Su lugar de trabajo actual",
  //       },
  //     },
  //     step2: {
  //       nextButton: 'Siguiente',
  //       subtitle: 'Informacion profesional ',
  //       subpartTitle: {
  //         expertiseArea: 'Sus areas de especializacion ',
  //         businessInformations: 'Datos de su empresa',
  //         vetDiploma: 'Su titulo de veterinario',
  //       },
  //     },
  //     step3: {
  //       nextButton: 'Finalizar',
  //       subtitle: 'Informacion personal',
  //       cgu: {
  //         preLink: 'Acepto la',
  //         cguLink: 'Condiciones de uso y política de privacidad',
  //         postLink: 'del servivio Callmyvet.',
  //       },
  //       subpartTitle: {
  //         idCard: "Su documento de identidad ",
  //         idCardDescription: "(documento de identidad o pasaporte)",
  //         contactInformation: 'Sus datos de contacto',
  //         bankingInformation: 'Sus datos bancarios',
  //         bankingInformationDescription: '(para la transferencia de las llamadas efectuadas)',
  //       },
  //     },
  //     step4: {
  //       title: 'Gracias, hemos tenido en cuenta su preinscripción.',
  //       content:
  //         'Nuestro equipo comprobará sus datos. Le confirmaremos su inscripción definitiva por correo electrónico en un plazo de 48 horas.',
  //       button: "Volver a la página de inicio",
  //     },
  //     errors: {
  //       required: 'Este campo es obligatorio',
  //       requiredExpertiseArea: 'Seleccione al menos un área',
  //       requiredCgu: 'Acepte las condiciones generales ',
  //       requiredFile: 'Cargue un archivo de 5 MB o menos',
  //       rejectedFile: 'La dirección de correo electrónico introducida ya está en uso',
  //       duplicateEmail: "La dirección de correo electrónico introducida ya está en uso",
  //       invalidEmail: 'Correo electrónico no válido',
  //     },
  //   },
  // },
  signIn: {
    title: 'Veterinarios, conéctense para acceder a su panel de control.',
    form: {
      fields: {
        email: {
          label: 'Direccion de correo electronico ',
          placeholder: 'Introduzca su dirección de correo electrónico',
        },
        password: {
          label: 'Contraseña',
          placeholder: 'Contraseña',
        },
      },
      error: {
        wrongEmailPassword: 'La combinación e-mail/contraseña no coincide',
      },
    },
    forgottenPassword: '¿Ha olvidado su contraseña?',
    noAccount: '¿Aún no tiene cuenta?\nPara crear uno,  ',
    signUpLink: '¡Nos vemos aquí!',
    backLabel: 'Volver',
  },
  // account: {
  //   title: 'Cuenta',
  //   subTitle: 'Sus datos de pago ',
  //   validationText:
  //     'La validación de su cuenta Stripe es necesaria para recibir pagos. No olvide preparar un documento de identidad, un Kbis y un justificante de domicilio. Puede utilizar la cámara de su ordenador o su teléfono para escanear estos documentos.',
  //   ibanText: 'Introduzca/modifique su IBAN.',
  //   infoTips: 'Nota: Recuerde actualizar la página una vez que haya proporcionado toda la información.,
  //   buttons: {
  //     onBoardingInvalide: 'Finalice su cuenta',
  //     onBoardingValide: 'Cuenta validada',
  //     onBoardingPending: 'En proceso de validación',
  //     ibanInvalide: 'Introduzca un IBAN',
  //     ibanValide: 'Editar',
  //   },
  //   ibanForm: {
  //     title: 'IBAN',
  //     subTitle:
  //       'Añada o modifique su IBAN para recibir pagos y realizar transferencias automáticas en nombre de Call My Vet.',
  //     form: {
  //       fields: {
  //         name: {
  //           label: 'Nombre del titular de la cuenta',
  //           placeholder: 'Jenny Rosen',
  //         },
  //         iban: {
  //           label: 'Número IBAN',
  //         },
  //       },
  //     },
  //     stripeText:
  //       'Al facilitar su IBAN y confirmar este método de pago, usted autoriza a Call My Vet SAS y a Stripe, nuestro proveedor de servicios de pago, a enviar instrucciones a su banco para que efectúe el cargo en su cuenta y a que su banco efectúe el cargo en su cuenta de acuerdo con estas instrucciones. Tiene derecho a que su banco le devuelva el dinero según las condiciones de su contrato con el banco. El reembolso debe solicitarse en un plazo de 8 semanas a partir de la fecha de adeudo en su cuenta.',
  //     popup: {
  //       valid: '✅ Su IBAN ha sido registrado por Stripe',
  //       errorLeftButton: 'Contactar con el servicio de atención al cliente',
  //       error:
  //         '⚠️ Su IBAN es incorrecto, por favor compruebe su entrada. Si el problema persiste, póngase en contacto con nuestros equipos.',
  //     },
  //   },
  // },
  admin: {
    vet: {
      moderationStatus: {
        pending: 'A la espera de moderación',
        refused: 'Falta de información o información falsa',
        validated: 'Cuenta validada',
      },
      nationality: {
        french: 'FRANCES',
        belgian: 'BELGA',
      },
      speciality: {
        generalPractitioner: 'MÉDICO_GENERAL',
        behaviourist: 'CONDUCTISTA',
        osteopath: 'OSTEOPATÍA',
        ophthalmologist: 'OFTALMÓLOGO',
        dentist: 'DENTISTA',
        nutritionist: 'CONDUCTISTA',
        nursery: 'GUARDERIA',
        ethologist: 'ETÓLOGO',
        parasitologiste: 'PARASITÓLOGO',
        dermatologist: 'DERMATÓLOGO',
        gastrologue: 'GASTROLOGISTA',
        virologist: 'VIROLOGISTA',
        urologist: 'UROLOGO',
        traumatologist: 'TRAUMATÓLOGO',
        orthopedist: 'ORTOPEDISTA',
        surgeon: 'CIRUJANO',
      },
      expertiseArea: {
        catAndDog: 'gatoyPerro',
        cat: 'gato',
        dog: 'perro',
        NAC: 'NAC',
        horse: 'caballo',
      },
    },
  },
  shared: {
    optionalField: '(opcional)',
    nextButton: 'Seguir',
    raceItem: {
      catAndDog: 'Perros y gatos',
      cat: 'Gatos',
      dog: 'Perros ',
      horse: 'Equino',
      NAC: 'N.A.C.',
    },
    speciesItem: {
      Cameleon: 'Camaleón',
      Chevre: 'Cabra',
      Cochon: 'Cerdo',
      CochondInde: "Conejillo de Indias",
      Dinde: 'Pavo',
      Furet: 'Huron',
      Hamster: 'Hamster',
      Herisson: 'Erizo',
      Lama: 'Llama',
      Lapin: 'Conejo',
      Lezard: 'Lagarto',
      Mouton: 'Ovejas',
      Perroquet: 'Loro',
      Poisson: 'Pescado',
      Poule: 'Gallina',
      Rat: 'Rata',
      Scorpion: 'Escorpio',
      Serpent: 'Serpiente',
      Souris: 'Raton',
      Tortue: 'Tortuga',
      Autre: 'Otras especies',
    },
  },
  navBar: {
    connected: {
      accueil: 'Inicio',
      compte: 'Cuenta',
      deconnexion: 'Desconexión',
    },
    // informationBar: {
    //   document: {
    //     text: 'IMPORTANTE: Debe finalizar su cuenta de Stripe para recibir pagos',
    //     link: 'Validar mi cuenta Stripe',
    //   },
    //   iban: {
    //     text: 'IMPORTANTE: Debe facilitar su IBAN para recibir pagos',
    //     link: 'Añadir mi IBAN',
    //   },
    // },
    modal: {
      mainMenu: {
        title: 'MENU',
      },
      profileMenu: {
        title: 'MENU PERFIL',
      },
    },
  },
  dashboard: {
    // signOutLabel: 'Desconexión',
    // toggleAvailability: {
    //   nameTitle: 'Bienvenido,',
    //   blockContentTitle: 'Su disponibilidad',
    //   blockContentSubtitle:
    //     'Para recibir llamadas, pulse el botón de la derecha para que se ilumine en verde.',
    //   informationBlock: {
    //     title: 'Información práctica',
    //     link: {
    //       guide: 'Guía práctica',
    //       tarif: '¿Precios?',
    //       parainage: 'Apadrine a un colega',
    //       estimation: "Herramientas de evaluación",
    //       satisfaction: 'Encuesta de satisfacción',
    //       contacte: 'Póngase en contacto con nosotros',
    //       suggestion: 'Hacer una sugerencia',
    //       info: 'Quiénes somos',
    //     },
    //   },
    // },
    title: 'Bienvenido',
    availabeVets: {
      title: 'Veterinarios disponibles',
      subtitle: 'Debe tener al menos un perfil veterinario verificado para recibir llamadas.',
      noVetAvailable: {
        text: 'Informar al menos a un veterinario de la ',
        link: 'miembros de su equipo',
      },
      companyNotVerified: {
        text: 'Usted debe',
        link: 'complete el registro de su empresa',
        textEnd: ' para poder asignar veterinarios.',
      },
      currentVetNotVerified: {
        text: 'Usted debe ',
        link: 'complete su perfil veterinario',
        textEnd: 'para poder recibir llamadas.',
      },
    },
    companyStatus: {
      title: 'Estado de la empresa {{companyName}}',
      subtitle:
        'Debe rellenar toda la información que figura a continuación para recibir llamadas.',
      legalInformation: 'Información jurídica',
      vat: 'Siret y IVA".',
      address: 'Dirección',
      contact: 'Póngase en contacto con',
      bankingInformation: 'Información bancaria, Stripe',
      professionalInsurance: 'Seguro profesional',
      teamMembers: 'Miembros del equipo',
      status: {
        verified: '',
        inReview: 'Bajo verificación',
        warning: 'Información que falta',
        criticalWarning: 'Falta información crítica',
      },
    },
    information: {
      title: 'Información práctica',
      subtitle:
        'Para recibir llamadas, pulse el botón de la derecha para que se ponga verde.',
      links: {
        howItWorks: '¿Cómo funciona?',
        pricing: 'Precios',
        estimationTools: "Herramientas de evaluación",
        satisfactionSurvey: 'Encuesta de satisfacción',
        contactUs: 'Póngase en contacto con nosotros',
        suggestion: 'Hacer una sugerencia',
      },
    },
    cardText: {
      available: 'Estás activo',
      unavailable: 'Estás inactivo',
    },
    codeAlert: {
      codeText: 'El código de su clínica es',
      codeHelpText: 'Este es el código que debes indicar a sus clientes para que puedan encontrarte en la app Callmyvet'
    },
  },
  videoCallHandler: {
    videoCallRequest: {
      title: 'El código de su clínica es',
      subtitle: '{{customerGender}} {{customerFirstName}},\n Para:{{petName}}',
      informations: "Motivo del recurso: ",
      buttons: {
        acceptCall: "Aceptar la videollamada",
        declineCall: "Rechazar la videollamada",
      },
      chooseVeterinarian: {
        title: 'Seleccione el veterinario al que desea responder',
      },
      petTrouble: {
        GeneralPractitioner: 'Medicina general',
        Behaviourist: 'Comportamiento',
        Osteopath: 'Osteopatía',
        Ophthalmologist: 'Oftalmología',
        Dentist: 'Odontología',
        Nutritionist: 'Nutrición',
        Nursery: 'Ginecología',
        Ethologist: 'Etología',
        Parasitologist: 'Parasitología',
        Dermatologist: 'Dermatología',
        Gastrologue: 'Gastrología',
        Virologist: 'Enfermedad contagiosa',
        Urologist: 'Urología',
        Traumatologist: 'Traumatología',
        Orthopedist: 'Ortopedia',
        Surgeon: 'Cirugía',
      },
    },
    videoCallStatusCard: {
      alreadyAcceptedCall: {
        title: 'Solicitud de ayuda',
        subtitle: "Uno de sus colegas se hizo cargo de la solicitud.",
      },
      cancelledCall: {
        title: 'Solicitud anulada',
        subtitle: 'La solicitud ha sido cancelada por el usuario.',
      },
      timedOutCall: {
        title: '¡Llamada perdida!',
        subtitle: 'Ningún veterinario respondió a la solicitud.',
      },
      enableGestureForSound: {
        title: 'Tu navegador ha puesto el tono de llamada en espera',
        subtitle:
          "Si ya no desea ver esta alerta, cambie los privilegios de acceso de pro.callmyvet.io en las preferencias de su navegador.",
        buttonContent: 'Reactivar el sonido del navegador',
        procedure: {
          chrome: `
          <title>CHROME</title><br/>
          <bold>Haz clic en el ícono de los “3 puntos verticales”, luego selecciona “Preferencias”:</bold>
          <br/>
          <br/>
          <leftAlignText>
            / Preferencias<br/>
            / Privacidad y seguridad<br/>
            / Configuración del sitio<br/>
            / Micro<br/>
            / Haga clic en la línea https://pro.callmyvet.io
            </leftAlignText>
            <br/>
          -> Cambiar el <bold>Parámetro SONIDO</bold> : "Automático" para <bold>"Permitir"</bold>`,
          firefox: `
          <title>FIREFOX</title><br/>
          "
          <bold>La página pro.callmyvet.io debe estar abierta en Firefox. Haga clic en el icono ""cadena"" a la izquierda de la url en la barra de navegación:</bold>"
          <br/>
          <br/>
          <leftAlignText>
          / Haga clic en la flecha situada a la derecha de "Conexión segura".<br/>
          / A continuación, haga clic en "Más información" en la parte inferior de la ventana emergente.<br/>
          / Haga clic en "Permiso".<br/>
          / En la línea "Leer automáticamente los soportes<br/>
          </leftAlignText>
          <br/>
          -> Desmarque <bold>"Permiso por defecto"</bold>
          -> Seleccione<bold>"Permitir audio y vídeo".</bold>`,
          safari: `
          <title>SAFARI</title><br/>
          <bold>La página pro.callmyvet.io debe estar abierta en Safari. Haga clic en Safari en el menú superior izquierdo y, a continuación, seleccione "Preferencias".:</bold>
          <br/>
          <br/>
          <leftAlignText>
          / Preferencias<br/>
          / Página web<br/>
          / Reproducción automática<br/>
          / Buscar "pro.callmyvet.io" en la lista de sitios web<br/>
          </leftAlignText>
          <br/>
          -> Cambia el ajuste <bold>"No reproducir contenido de audio"</bold> por <bold>"Permitir siempre la reproducción automática"</bold>`,
        },
      },
    },
  },
  surveyEndCall: {
    headerTitle: 'Compromiso de calidad del servicio',
    headerLabelRight: 'Seguir leyendo',
    preTitle: 'Formulario reglamentario',
    mainTitle: 'Fin de la llamada',
    body:
      '¿Hubo algún problema? Llama a {{ownerName}} al {{phoneNumber}} \Todos los campos marcados con * son obligatorios',
    form: {
      callStatus: {
        label: 'Validación de pagos *',
        sublabel:
          'La calidad del servicio depende de la experiencia de cada veterinario, incluido usted. Confiamos en su criterio para cobrar o no por esta llamada. Tenga en cuenta que nuestra comisión se deducirá al final del mes, independientemente de su decisión. Esta medida tiene como objetivo mantener la calidad del servicio mientras se previenen abusos.',
        validated: 'Validar el pago',
        notValidated: 'Rechazar el pago',
      },
      submit: 'Enviar el formulario',
      error: {
        required: 'Seleccione una opción',
      },
    },
  },
  // choosingPassword: {
  //   errors: {
  //     setPasswordFailed: "Se ha producido un error, su contraseña no ha sido creada",
  //     missingParams: "Se ha producido un error, el enlace no es válido",
  //     passwordTooShort: 'La contraseña debe tener al menos 8 caracteres',
  //   },
  //   password: {
  //     label: 'Contraseña (mín. 8 caracteres)',
  //     placeholder: 'Tu contraseña',
  //   },
  //   content:
  //     "Para acceder a su cuenta y empezar a recibir llamadas, establezca su contraseña personal.",
  //   title: 'Establezca su contraseña',
  // },
  resetPaswordRequestEmail: {
    subject: 'Call My Vet - Veterinario - Olvidó su contraseña',
    body:
      "Hola, les escribo hoy porque he olvidado mi contraseña. ¿Puede enviarme un enlace para restablecerlo? Gracias de antemano.",
  },
  twilio: {
    callHeader: '🟢 Videollamada en curso',
    hangUpModalMessage: "Está a punto de finalizar la llamada. ¿Seguro?",
    picturesList: {
      headerLabel: 'FOTOS DE {{petName}}',
      refreshButtonLabel: 'Actualizar los medios recibidos',
      emptyListLabel: 'No se han recibido medios de comunicación',
    },
    videoStatus: {
      tryToReconnect: "El usuario intenta volver a conectarse",
      reconnecting: "Por favor, espere, el usuario se conecta...",
      videoDisabled: "El usuario ha desactivado su vídeo",
      audioDisabled: "El usuario ha desactivado su micrófono",
      disconnect: "El usuario se ha desconectado",
      cameraNotFound: "Cámara no disponible o no encontrada",
      cameraNotAccess: "Cámara sin permisos o esta siendo usada por otra app",
      cameraFailure: "Cámara fallando",
    },
    videoTabItems: {
      call: 'Llame a',
      documents: 'Documentos',
      animalCard: "Ficha del animal",
    },
    callInfoDetails: {
      pet: 'Animal',
      petName: 'Apellido',
      specieAndRace: 'Especie / Raza',
      ageAndSex: 'Edad / Sexo',
      male: 'Hombre',
      female: 'Mujer',
      sterilized: 'Esterilizado',
      freeCall: 'Llamada gratuita',
      paidCall: 'Llamada de pago',
      owner: 'Propietario',
      firstNameAndLastName: 'Nombre Apellido',
      phoneNumber: 'Teléfono',
      email: 'Correo electrónico',
      cityAndCountry: 'Ciudad / País',
      infos: '📋 Información',
      callPurpose: 'Objeto de la convocatoria',
      tarification: 'Tarifa',
      regularTarification: 'Tarifa básica',
      slightlyIncreasedTarification: 'Tasa de prima',
      increasedTarification: 'Tasa de custodia',
      callType: "Tipo de llamada",
      callDuration: 'Duración de la llamada',
      yes: 'sí',
      no: 'no',
      unknown: 'N.C',
    },
    deconnexionAlert:
      "Esperamos que tu teleconsulta haya ido bien. Si lo desea, puede volver a llamar a {{petName}} al {{número de teléfono del cliente}}. El equipo de Callmyvet",
  },
  login: {
    title: 'Conexión',
    subtitle: 'La solución fiable y legal para el control remoto veterinario.',
    form: {
      fields: {
        email: {
          label: 'Correo electrónico',
          placeholder: 'Correo electrónico',
        },
        password: {
          label: 'Contraseña',
          placeholder: 'Contraseña',
        },
      },
      submit: 'Conexión',
    },
    link: {
      forgotPassword: '¿Ha olvidado su contraseña?',
      noAccount: '¿No tiene cuenta? ',
      noAccountLink: 'Regístrese ahora.',
    },
  },
  resetPasswordRequest: {
    title: 'Restablecer contraseña',
    subtitle: '¿Ha olvidado su contraseña?',
    form: {
      fields: {
        email: {
          label: 'Correo electrónico',
          placeholder: 'Correo electrónico',
        },
      },
      indication:
        'Se le enviará un correo electrónico con un enlace para restablecer su contraseña.',
      submit: 'Enviar',
      errors: {
        emailNotFound: "No hemos encontrado ninguna cuenta con esta dirección de correo electrónico",
        tooManyRequest:
          'Su cuenta ha alcanzado el límite de solicitudes, espere antes de volver a intentarlo',
      },
    },
    link: {
      login: 'Conexión',
    },
    confirmationModal: {
      subtitle: 'Su solicitud ha sido procesada, por favor revise su buzón.',
      backToLogin: 'Volver a la página de inicio',
    },
  },
  resetPasswordRequestEmailSentConfirmation: {
    title: 'Restablecer contraseña',
    subtitle:
      'Su solicitud ha sido procesada, por favor revise su buzón. Puede cerrar esta ventana.',
  },
  createAccount: {
    title: 'Cree su cuenta',
    description:
      'Por favor, utilice su correo electrónico profesional, no será visible para el público y tendremos que transmitirle información importante. Si lo desea, puede proporcionar un correo electrónico de contacto "público" más adelante. ¿Ya tiene una cuenta?',
    linkLogin: 'Conéctese a',
    subnavbar: {
      title: 'La comunidad que respeta a los veterinarios.',
    },
    form: {
      fields: {
        firstName: {
          label: 'Nombre *',
          placeholder: 'Su nombre',
        },
        lastName: {
          label: 'Apellido *',
          placeholder: 'Su apellido',
        },
        email: {
          label: 'Correo electrónico *',
          placeholder: 'Tu correo electrónico',
        },
        password: {
          label: 'Contraseña *',
          placeholder: 'Crear una contraseña',
        },
        cgu: {
          label: 'Condiciones generales *',
          placeholder: {
            firstPart: 'Acepto la ',
            firstLink: 'Condiciones de uso',
            and: 'y el ',
            secondLink: 'Política de privacidad ',
            secondPart: 'el servicio de teleconsulta ofrecido por la empresa Call My Vet SAS. *',
          },
        },
      },
      submit: 'Enviar',
      informations: {
        security:
          'Está usted en buenas manos. Sus datos están protegidos con las normas más estrictas.',
        required: 'Los campos marcados con * son obligatorios.',
      },
    },
    confirmation: {
      headerTitle: 'Acabamos de enviarle un correo electrónico',
      subtitle: 'Ve a tu buzón...',
      emailSend: "El correo electrónico se envió a : ",
      recipient: ' {{email}}',
      description:
        'Este correo electrónico contiene un enlace de activación. Haga clic en este enlace. Necesitamos verificar su cuenta antes de abrirle nuestras puertas. Puede cerrar esta página.',
      emailNotReceived: {
        description:
          '¿El correo electrónico no aparece en su bandeja de entrada? Compruebe su carpeta de correo no deseado. El correo electrónico se envió desde noreply@callmyvet.io',
        resendEmailLink: {
          label: '¿No ha recibido un correo electrónico?',
          link: 'Reenviar un código',
        },
        cancelLink: {
          label: '¿Ya no quieres registrarte?',
          link: 'Cancelar mi inscripción',
        },
      },
    },
    deleteAccount: {
      preTitle: 'Su cuenta ha sido eliminada',
      bodyContent: 'Su cuenta ha sido eliminada',
      buttonLabel: "Volver a la página de inicio",
    },
  },
  createAccountVerification: {
    title: 'Comprobar su cuenta',
    firstSection: 'Nuestro sistema está verificando su cuenta.',
    errors: {
      title: 'Se ha producido un error',
      argumentError: "Se ha producido un error, el enlace no es válido",
      invalidActionCode: 'La solicitud no es válida o ya se ha utilizado.',
      expiredActionCode: 'Su solicitud ha caducado.',
      userDisabled: 'Su cuenta de usuario firebase está desactivada',
      userNotFound: "No se ha encontrado su cuenta de usuario firebase",
      missingParams:
        'Debe hacer clic en el enlace enviado por correo electrónico para verificar su dirección de correo electrónico.',
      default: "Ningún código de error coincide",
      buttonConnected: 'Enviar una nueva solicitud',
      buttonNotConnected: 'Iniciar sesión para enviar una nueva solicitud',
    },
  },
  createAccountValidated: {
    title: 'Su cuenta está activada',
    firstSection:
      'Gracias por confiar en nosotros. La transparencia, el respeto de las normas y los valores de la profesión son la base de nuestro planteamiento. Callmyvet se dirige a veterinarios convencidos de que la telemedicina aporta un verdadero plus en el cuidado de los animales de compañía. Para los demás, intenta dejarte seducir un poco.  ',
    continue: 'Seguir',
  },
  onboardingAccount: {
    title: 'Bienvenido {{accountFirstName}},',
    description:
      'Para poder recibir llamadas, debe estar adscrito a una empresa autorizada a ejercer actividades veterinarias en Francia. Entrará en un proceso que requiere cierta información necesaria para recibir llamadas. Si no tiene toda la información sobre usted, pase a la siguiente pantalla, puede volver más tarde para completarla.',
    firstSection: {
      title: 'Veterinario independiente o asociado',
      row: {
        leftLabel: 'Añadir una empresa a Callmyvet',
        rightLabelCreate: 'Cree',
        rightLabel: 'Seleccione',
      },
    },
    secondSection: {
      title: 'Veterinario de plantilla',
      row: {
        leftLabel: 'Unirse a un equipo',
        rightLabel: 'Únete a',
      },
    },
  },
  onboardingVetCompany: {
    title: 'Crear una empresa',
    description:
      'Toda la información marcada con un * es necesaria para estar al corriente con el Colegio. No obstante, puede omitir este paso y completar su perfil más tarde.',
    form: {
      fields: {
        country: {
          label: 'País de la empresa *',
          placeholder: 'Seleccione un país',
        },
        legalStatus: {
          label: 'Estatuto jurídico de la empresa *',
          placeholder: 'Seleccione un estado',
        },
        type: {
          label: 'Tipo de empresa *',
          placeholder: 'Seleccione un tipo',
        },
        companyName: {
          label: 'Nombre de la empresa *',
          placeholder: 'Nombre de la empresa',
        },
      },
      button: {
        goBack: 'Anterior',
        continue: 'Seguir',
      },
    },
  },
  onboardingVeterinarian: {
    title: 'Soy empleado',
    description:
      'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam id dolor id nibh ultricies vehicula ut id elit. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Aenean lacinia bibendum nulla sed consectetur. Vestibulum id ligula porta felis euismod semper.',
    form: {
      fields: {
        email: {
          label: 'Correo electrónico de su empleador *',
          placeholder: 'Correo electrónico del empleador',
        },
      },
      button: {
        goBack: 'Anterior',
        send: 'Enviar',
      },
    },
  },
  onboardingVeterinarianConfirmation: {
    title: 'Invitación de su empleador',
    subtitle: 'Su solicitud ha sido tenida en cuenta.',
    backToLogin: 'Volver a la página de inicio',
  },
  onboardingSideMenu: {
    items: {
      first: 'Sin compromiso',
      second: 'Perfiles verificados',
      third: 'Datos seguros',
      fourth: 'Aprobado por el Colegio',
    },
  },
  organisation: {
    form: {
      button: {
        cancel: 'Cancelar',
        save: 'Registrar',
      },
    },
    legalInformations: {
      title: 'Información jurídica',
      path: 'Organización / Información jurídica',
      form: {
        title: 'Información sobre la empresa',
        subtitle: '',
        country: {
          label: 'País de la empresa *',
          placeholder: 'País de la empresa',
        },
        legalStatus: {
          label: 'Estatuto jurídico de la empresa *',
          placeholder: 'Estatuto jurídico de la empresa',
        },
        type: {
          label: 'Tipo de empresa *',
          placeholder: 'Tipo de empresa',
        },
        companyName: {
          label: 'Nombre de la empresa *',
          placeholder: 'Nombre de la empresa',
        },
      },
    },
    vat: {
      title: 'Número de identificación fiscal e IVA',
      path: 'Organización / Siret y IVA',
      form: {
        title: 'Información sobre la empresa',
        subtitle:
          'El número SIRET y el número de IVA son necesarios para poder emitir facturas en su nombre a los propietarios de animales.',
        siretNumber: {
          label: 'Número de registro de la empresa *',
          sublabel: '(Número del BCE para las empresas belgas)',
          placeholder: 'Número de registro de la empresa',
        },
        vatNumber: {
          label: 'Número de IVA de la empresa *',
          placeholder: 'Número de IVA de la empresa',
        },
      },
    },
    address: {
      title: 'Dirección',
      path: 'Organización / dirección',
      form: {
        title: 'Dirección de la empresa',
        subtitle: 'Indique la dirección de la sede de la empresa',
        addressMain: {
          label: 'Dirección línea 1 *',
          placeholder: 'Dirección línea 1',
        },
        addressComplement: {
          label: 'Dirección línea 2',
          placeholder: 'Dirección línea 2',
        },
        city: {
          label: 'Ciudad *',
          placeholder: 'Ciudad',
        },
        zipCode: {
          label: 'Código postal *',
          placeholder: 'Código postal',
        },
      },
    },
    contact: {
      title: 'Contacto',
      path: 'Organización / contacto',
      form: {
        title: 'Información de contacto de la empresa',
        subtitle: '',
        email: {
          label: 'Correo electrónico de contacto de la empresa',
          placeholder: 'Correo electrónico de contacto de la empresa',
        },
        phoneNumber: {
          label: 'Teléfono de la empresa',
          placeholder: 'Teléfono de la empresa',
        },
      },
    },
    bankingInformation: {
      title: 'Información bancaria',
      path: 'Organización / Información bancaria',
      form: {
        stripe: {
          title: 'Información de Stripe para llamadas de pago',
          subtitle:
            'Necesitará su K-Bis y responder a algunas preguntas. Para realizar transferencias a su cuenta bancaria, debe facilitarnos un IBAN. Al facilitar su IBAN y confirmar este método de pago, usted autoriza a Call My Vet SAS y a Stripe, nuestro proveedor de servicios de pago, a enviar instrucciones a su banco para que efectúe el cargo en su cuenta y a que su banco efectúe el cargo en su cuenta de acuerdo con estas instrucciones. Tiene derecho a que su banco le devuelva el dinero según las condiciones de su contrato con el banco. El reembolso debe solicitarse en un plazo de 8 semanas a partir de la fecha de adeudo en su cuenta.',
          label: 'Estado de su cuenta',
          status: {
            validated: 'Cuenta validada',
            noValidated: 'Compte limité',
            pending: 'En proceso de validación',
          },
          link: {
            add: 'Completa tu perfil en Stripe',
            modify: 'Editar información de Stripe',
          },
        },
        iban: {
          title: 'Información para las convocatorias ofrecidas',
          subtitle:
            'Para recibir el pago de las llamadas ofrecidas, debe enviarnos sus datos bancarios.',
          label: 'Estado de su IBAN',
          status: {
            noIban: 'No IBAN',
            ibanFilled: 'Investigado',
          },
          link: {
            add: 'Añadir un IBAN',
            modify: 'Cambie su IBAN',
          },
        },
        email: {
          label: 'Correo electrónico de contacto de la empresa',
          placeholder: 'Correo electrónico de contacto de la empresa',
        },
        phoneNumber: {
          label: 'Teléfono de la empresa',
          placeholder: 'Teléfono de la empresa',
        },
      },
    },
    professionalInsurance: {
      title: 'Seguro profesional',
      path: 'Organización / seguro profesional',
      form: {
        title: 'Póliza de seguros',
        subtitle:
          'Usted o su empresa deben estar asegurados para cumplir las obligaciones reglamentarias establecidas por la asociación y el conjunto de la profesión.',
        insuranceCompany: {
          label: 'Compañía de seguros *',
          placeholder: 'Compañía de seguros',
        },
        contractNumber: {
          label: 'Número de contrato *',
          placeholder: 'Número de contrato',
        },
        partnership: {
          title: 'beneficiarse de condiciones especiales',
          subtitle: 'Evaluación personalizada, gratuita y confidencial con Xavier Guisnet',
        },
      },
    },
  },
  ibanForm: {
    title: 'Registro IBAN',
    titleCMV: 'Registro IBAN (1/2 - Callmyvet)',
    titleStripe: 'Registro IBAN (2/2 - Stripe)',
    btnSave: 'Guardar en Callmyvet',
    btnSaveStripe: 'Guardar en Stripe',
    ibanModalLabelCreate: 'Registro número IBAN y número BIC/SWIFT',
    ibanModalLabelEdit: 'Editar número IBAN y número BIC/SWIFT',
    subTitleStripe: 'Ingrese su IBAN nuevamente para validar y confirmar la creación de su cuenta Stripe Connect. Esta acción te permitirá recibir el importe de tus consultas.',
    subtitle:
      'Al proporcionar su IBAN y confirmar este método de pago, autoriza a Call My Vet SAS y Stripe, nuestro proveedor de servicios de pago, a enviar instrucciones a su banco para debitar su cuenta de acuerdo con estas instrucciones. Tiene derecho a un reembolso de su banco de acuerdo con los términos y condiciones de su acuerdo con su banco. Se debe solicitar un reembolso dentro de las 8 semanas posteriores a la fecha en que se debitó su cuenta.',
    form: {
      name: {
        label: 'Nombre del titular *',
        placeholder: 'Nombre del titular de la cuenta',
      },
      ibanNumber: {
        label: 'Número IBAN *',
        valid: 'Ce champ est requis',
      },
      bicNumber: {
        label: 'Número BIC',
        placeholder: 'Número BIC / SWIFT',
      },
    },
    popup: {
      valid: '✅ Su IBAN ha sido registrado por Stripe',
      errorLeftButton: 'Contactar con el servicio de atención al cliente',
      error:
        '⚠️ Su IBAN es incorrecto, por favor compruebe su entrada. Si el problema persiste, póngase en contacto con nuestros equipos.',
    },
  },
  account: {
    title: 'Su cuenta',
    subtitle: 'Información relacionada con su actividad',
    form: {
      button: {
        cancel: 'Cancelar',
        save: 'Registrar',
      },
      nameSection: {
        title: 'Apellido nombre',
        subtitle:
          'Para recibir llamadas, pulse el botón de la derecha para que se ilumine en verde.',
        label: 'Nom prénom',
        LinkModifyName: 'Cambiar nombre y apellidos',
        modal: {
          title: 'Cambiar su nombre y apellidos',
          subtitle:
            'Etiam porta sem malesuada magna mollis euismod. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
          firstName: {
            label: 'Apellido *',
            placeholder: 'Apellido',
          },
          lastName: {
            label: 'Nombre *',
            placeholder: 'Nombre',
          },
        },
      },
      emailSection: {
        title: 'Correo electrónico de la cuenta',
        subtitle: "Tu cuenta está vinculada a tu correo electrónico, no puedes cambiarlo.",
        label: 'Correo electrónico de la cuenta',
        LinkModifyEmail: 'Modificar correo electrónico',
        modal: {
          title: 'Cambiar el correo electrónico de la cuenta',
          subtitle:
            'Etiam porta sem malesuada magna mollis euismod. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
          oldEmail: {
            label: 'Correo electrónico antiguo',
            placeholder: '',
          },
          newEmail: {
            label: 'Nuevo correo electrónico *',
            placeholder: 'Correo electrónico',
          },
          cancel: 'Cancelar',
        },
      },
      passwordSection: {
        title: 'Contraseña de la cuenta',
        subtitle: "Asegure el acceso a su cuenta con una contraseña.",
        label: 'Contraseña de la cuenta',
        LinkModifyPassword: 'Cambiar contraseña',
      },
    },
  },
  resetPasswordForm: {
    title: 'Restablecer contraseña',
    subtitle: 'Crear una nueva contraseña',
    form: {
      fields: {
        email: {
          label: 'Correo electrónico',
          placeholder: 'Correo electrónico',
        },
        password: {
          label: 'Introduzca una contraseña',
          placeholder: 'Contraseña',
        },
        passwordConfirmation: {
          label: 'Confirmar contraseña',
          placeholder: 'Contraseña',
        },
      },
      errors: {
        setPasswordFailed: "Se ha producido un error, su contraseña no ha sido creada",
        missingParams: "Se ha producido un error, el enlace no es válido",
        // passwordTooShort: 'Le mot de passe doit être de 8 caractères minimum',
      },
      indication: 'Para acceder a su cuenta debe restablecer su contraseña.',
      submit: 'Enviar',
    },
    link: {
      login: 'Continuar',
    },
  },
  resetPasswordError: {
    title: 'Su solicitud ha caducado o no es válida',
    subtitle:
      'Para garantizar la seguridad de su cuenta, le invitamos a renovar su solicitud de cambio de contraseña.',
    backToLogin: 'Volver a la página de inicio',
  },
  resetPasswordLoader: {
    title: 'Espere, por favor',
    subtitle: 'Comprobación de la integridad de su aplicación....',
  },
  resetPasswordSuccess: {
    title: 'Restablecer contraseña',
    subtitle:
      "Su contraseña ha sido modificada. Se le redirigirá a la página de inicio. Redirección en 5 segundos...",
  },
  profile: {
    title: 'Perfil: {{firstName}} {{lastName}}',
    subtitle: 'Perfil personal y veterinario',
    form: {
      button: {
        cancel: 'Cancelar',
        save: 'Guardar cambios',
      },
    },
    personalProfile: {
      title: 'Información del perfil personal',
      subtitle:
        "Información relacionada con su perfil personal y sus derechos de acceso dentro de las organizaciones.",
      name: {
        title: 'Apellido, Nombre',
        subtitle: 'Perfil / {{firstName}} {{lastName}} / nombre y apellidos',
        form: {
          title: 'Su nombre y apellidos',
          subtitle: '',
          firstName: {
            label: 'Apellido *',
            placeholder: 'Apellido',
          },
          lastName: {
            label: 'Nombre *',
            placeholder: 'Nombre',
          },
        },
      },
      rights: {
        title: 'Función, acceso y derechos',
      },
    },
    veterinarianProfile: {
      title: 'Información sobre el perfil veterinario',
      subtitle:
        'Debe rellenar toda la información que figura a continuación para recibir llamadas.',
      proofOfIdentity: {
        title: 'Prueba de identidad',
        subtitle: 'Perfil / {{firstName}} {{lastName}} / Prueba de identidad',
        form: {
          title: 'Prueba de identidad',
          subtitle:
            'Para autenticarte con Stripe y el pedido necesitamos un ID. Puede utilizar un documento de identidad (anverso + reverso) o un pasaporte (sólo el anverso). Documento en color. Formatos aceptados: PNG, JPEG. Haz una foto limpia. Restricciones: Ni PDF, ni blanco y negro.',
          nationality: {
            label: 'Nacionalidad *',
            placeholder: 'Nacionalidad',
          },
          birthDate: {
            label: 'Fecha de nacimiento *',
            placeholder: 'DD/MM/AAAA',
          },
          idCardFront: {
            label: 'Documento de identidad / Pasaporte (anverso)*',
            placeholder: 'Importar un archivo',
          },
          idCardBack: {
            label: 'Documento de identidad (reverso)',
            placeholder: 'Importar un archivo',
          },
        },
      },
      profileMedical: {
        title: 'Perfil médico',
        subtitle: 'Perfil / {{firstName}} {{lastName}} / Perfil médico',
        form: {
          title: 'Perfil médico',
          subtitle:
            'Nuestros equipos comprueban periódicamente la validez de los números ordinales facilitados por todos los veterinarios. Para nosotros, es una garantía de seriedad y transparencia.',
          ordinalNumber: {
            label: 'Número ordinal *',
            placeholder: 'Número ordinal',
          },
          registrationDepartment: {
            label: 'Departamento de su registro',
            placeholder: 'Seleccione un departamento',
          },
          school: {
            label: 'Escuela *',
            placeholder: 'Seleccione su escuela',
          },
          otherSchool: {
            placeholder: "Nombre de la escuela",
          },
          diplomaGraduationYear: {
            label: 'Año de graduación *',
            placeholder: 'Año de graduación *',
          },
          diplomaIsValidInFrance: {
            label: 'Título válido en Francia *',
            placeholder: 'Certifico que poseo un título válido en Francia',
          },
        },
      },
      animalsInCare: {
        title: 'Animales en acogida',
        subtitle: 'Perfil / {{firstName}} {{lastName}} / Animales Apoyados',
        form: {
          title: 'Animales en acogida',
          subtitle: 'Seleccione al menos 1 especie animal',
        },
      },
      skills: {
        title: 'Competencias',
        subtitle: 'Perfil / {{firstName}} {{lastName}} / Habilidades',
        form: {
          title: 'Competencias preferidas',
          subtitle: 'Selecciona al menos 1 habilidad.',
        },
      },
      insurance: {
        title: 'Seguros',
        subtitle: 'Perfil / {{firstName}} {{lastName}} / Seguros',
        form: {
          title: "Póliza de seguros",
          subtitle:
            'Para atender llamadas en la plataforma Callmyvet es necesario disponer de un seguro profesional.',
          insuredByCompany: {
            label: '¿Asegurado por la empresa?',
            placeholder: 'El seguro de la empresa cubre al veterinario encargado del registro.',
          },
          insuranceCompany: {
            label: 'Compañía de seguros *',
            placeholder: 'Compañía de seguros',
          },
          contractNumber: {
            label: 'Número de contrato *',
            placeholder: 'Número de contrato',
          },
          customerNumber: {
            label: 'Número de cliente/tomador',
            placeholder: 'Número de cliente/asegurado',
          },
        },
      },
    },
  },
  teams: {
    title: 'Equipo',
    subtitle: 'Organización / {{companyName}} / Equipo',
    role: {
      admin: 'Admin',
      veterinarian: 'Veterinario',
    },
    veterinarianModerationStatus: {
      notCompleted: 'Perfil incompleto',
      pendingVerification: 'Verificación',
      verified: 'Perfil verificado',
    },
    companyAccountsModerationStatus: {
      pendingVeterinarian: 'Invitación enviada',
      pendingCompany: 'Moderar la invitación',
    },
    members: {
      title: 'Miembros del equipo',
      subtitle:
        'Completa tu perfil o invita a un nuevo veterinario a recibir llamadas.',
    },
    invitation: {
      title: 'Invitar a un veterinario',
      subtitle:
        "Introduzca la dirección de correo electrónico del veterinario al que desea invitar a su organización.",
      form: {
        email: {
          placeholder: 'Correo electrónico del veterinario',
        },
        button: {
          submit: 'Enviar',
        },
      },
      pendingVetModal: {
        header: {
          title: 'El veterinario aún no ha respondido a la invitación',
        },
        content:
          'La invitación ha sido enviada, el veterinario aún no la ha aceptado ni ha creado una cuenta.',
        footer: {
          cancelInvitation: 'Cancelar invitación',
          backButton: 'Cerrar la ventana',
        },
      },
      pendingCompanyModal: {
        header: {
          title: 'Invitación solicitada',
        },
        content:
          'Un veterinario al que no ha invitado solicita acceso a su empresa por correo electrónico.',
        footer: {
          declineInvitation: 'Desechos',
          acceptInvitation: 'Acepte',
        },
      },
      deleteVeterinarianModal: {
        header: {
          title: 'Suprimir el miembro',
        },
        content:
          'Cortarás {{firstName}} {{lastName}} del servicio. Sus datos se almacenan en la base de datos para todas las llamadas realizadas. \¿Seguro que quieres borrar la relación?',
        footer: {
          backButton: 'Cancelar',
          delete: 'Borrar',
        },
      },
    },
  },
  videoCalls: {
    title: 'Videollamadas',
    subtitle: 'Información relacionada con su actividad',
    firstCallDate: 'Recibió su primera llamada en {{firstCallDate}}',
    noCall: "Aún no has realizado una videollamada",
    currentWeek: {
      title: 'Videollamadas esta semana',
      see: 'Véase',
    },
    all: {
      title: 'Videollamadas',
      see: 'Ver las convocatorias',
    },
    detailsCallView: {
      subtitle: 'Detalles Videollamada',
      commitmentQuality: {
        title: '❇️ Compromiso de calidad',
      },
      observation: {
        title: '✍🏻 Comentarios',
      },
    },
    annualCallsView: {
      title: 'Año {{year}}',
      subtitle: 'Información relacionada con su actividad',
      annualCallsListTitle: 'Lista de llamadas por mes',
      monthlyCallsListTitle: 'Lista de llamadas recibidas',
    },
  },
  clients: {
    title: 'Lista de clientes',
    subtitle: 'Clientes de su clínica',
    allClientsBtn: 'Todos los clientes',
    pendingClientsBtn: 'Espera de aprobación ({{pending_count}})',
    approveBtn: 'Aprobar',
    rejectBtn: 'Rechazar',
    rejectedState: 'Rechazado',
    approvedState: 'Aceptado',
    totalClientsCounter: 'Total {{counter}} clienns'
  },
  organisationConsultationPrices: {
    mainTitle: 'Configuración de las Consultas',
    route: 'organización#Tarifas-de-las-consultas',
    title: 'TARIFAS DE LAS CONSULTAS',
    needHelpWatch: '¿Necesitas ayuda? Mira el',
    needHelpLink: 'tutorial en video',
    needHelpText: 'para saber cómo configurar los precios de tus consultas',
    needHelpNotAdmin : "Los datos de esta página han sido configurados por el administrador de su empresa. Si desea realizar cambios, comuníquese con él/ella.",
    catLabel: 'Gato',
    dogLabel: 'Perro',
    unusualLabel: 'Inusual',
    horseLabel: 'Caballo',
    generalLabel: 'General',
    behaviorLabel: 'Comportamiento',
    ophtalmoLabel: 'Oftalmología',
    teethLabel: 'Dientes',
    surgeonLabel: 'Cirujano',
    applyAll: 'Aplicar este precio a todos',
    eveningText: 'TARDE: Multiplicar el precio x1.5, en la tarde (20:00 - 22:00)',
    nightText: 'NOCHE: Multiplicar el precio x2, en la noche (22:00 - 08:00)',
    weekendText: 'FIN DE SEMANA: Multiplicar el precio x2, los fines de semana (Vie. 20:00 - Lun. 08:00)',
    saveBtn: 'Guardar',
    cancelBtn: 'Cancelar',
    linkText: 'Tarifas de las consultas',
    hoursTitle: 'HORARIOS/DÍAS ESPECÍFICOS',
    tableTitle: 'GESTIÓN DE TARIFAS',
    errorTextSave : 'Rellene todos los precios que faltan antes de guardar.',
    defaultPricesWarning: 'Los precios mostrados son definidos por defecto por Callmyvet. Si desea cambiar las cantidades, los puede cambiar en cualquier momento',
    defaultPricesTooltip: 'Valor por defecto puesto por CMV'
  },
  mainMenu: {
    overview: 'Descripción general',
    calls: 'Llamadas',
    profile: 'Perfil',
    team: 'Equipo',
    organization: 'Organización',
    account: 'Cuenta'
  },
  organisationAddress: {
    path: 'Organización / Dirección',
    title: 'Dirección',
    headerTitle: 'Dirección de la sociedad',
    headerSubtitle: 'Introduzca la dirección de la sede de la empresa.',
    mainAdress: 'Dirección Línea 1 *',
    mainPlaceholder: 'Dirección Línea 1',
    secondAdress: 'Dirección Línea 2',
    secondPlaceholderAdress: 'Dirección Línea 2',
    city: 'Ciudad *',
    cityPlaceholder: 'Ciudad',
    zipCode: 'Código postal *',
    zipCodePlaceholder: 'Código postal',
  },
  companyLegalStatus: {
    CL: 'Colaborador freelance',
    EI: 'Empresa individual (Opera bajo su propio nombre)',
    EIRL: 'Empresa individual de responsabilidad limitada',
    EURL: 'Empresa unipersonal de responsabilidad limitada',
    SA: 'Sociedad Anónima',
    SARL: 'Sociedad Anónima de Responsabilidad Limitada',
    SAS: 'Sociedad por Acciones Simplificada',
    SASU: 'Sociedad por Acciones Simplificada Unipersonal',
    SC: 'Sociedad Civil',
    SCP: 'Sociedad Civil Profesional',
    SCRL: 'Sociedad Cooperativa de Responsabilidad Limitada',
    SELARL: 'Sociedad de Ejercicio Liberal de Responsabilidad Limitada',
    SLP: 'Sociedad de Libre Asociación',
    SNC: 'Sociedad Colectiva',
    SPFPL: 'Sociedad de Participaciones Financieras de Profesiones Liberales',
    SPFPL_VRL: 'Sociedad de Participaciones Financieras de Profesiones Liberales Veterinarias de Responsabilidad Limitada',
    SPFPL_SAS: 'Sociedad de Participaciones Financieras de Profesiones Liberales con forma de S.A.S.',
    SPFPL_RL: 'Sociedad de Participaciones Financieras de Profesiones Liberales de Responsabilidad Limitada',
    SE: 'Sociedad Europea',
    SDE: 'Sociedad de Derecho Extranjero',
    I: 'Independiente',
    AS: 'Asociados en Sociedad',
    GE: 'Agrupación de Establecimientos',
  },
  profileMedical: {
    alfort: 'Escuela Nacional Veterinaria de Alfort',
    toulouse: 'Escuela Nacional Veterinaria de Toulouse',
    atlantique: 'Escuela Nacional Veterinaria Nantes Atlántico',
    lyon: 'Escuela Nacional Veterinaria de Lyon',
    rouen: 'Escuela Veterinaria UniLaSalle Rouen',
    other: 'Otro'
  },
  moderationStatusWarning : {
    missingVerification: 'Falta información obligatoria para la validación de su cuenta/empresa. Por favor complétalas para poder realizar tus primeras consultas.',
    pendingVerification: 'Su perfil está siendo validado en estos momentos, lo que puede tardar hasta 24 horas. Se le enviará un correo electrónico de confirmación para que pueda realizar su primera consulta.'
  }
};