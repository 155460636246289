import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { loader } from 'graphql.macro';
import { client } from '../../../graphql/client';
import { Props } from './Dashboard.container';
import styled from 'styled-components';
import { Checkbox } from 'semantic-ui-react';

const getTotalActiveVeterinariansCount = loader(
  '../../../graphql/getTotalActiveVeterinariansCount.graphql'
);
const getTotalActiveCompaniesCount = loader(
  '../../../graphql/getTotalActiveCompaniesCount.graphql'
);
const getActiveCustomersCount = loader('../../../graphql/getActiveCustomersCount.graphql');
const getConfig = loader('../../../graphql/getConfig.graphql');
const updateAppConfig = loader('../../../graphql/updateAppConfig.graphql');

interface State {
  totalVeterinarianCount: number;
  paidCallVeterinarianCount: number;
  freeCallVeterinarianCount: number;
  totalCompanyCount: number;
  paidCallCompanyCount: number;
  freeCallCompanyCount: number;
  customerCount: number;
  configList: Config[];
}

interface Config {
  id: string;
  key: string;
  value: boolean;
}

export class Dashboard extends Component<Props, State> {
  public state: State = {
    customerCount: -1,
    totalVeterinarianCount: -1,
    paidCallVeterinarianCount: -1,
    freeCallVeterinarianCount: -1,
    totalCompanyCount: -1,
    paidCallCompanyCount: -1,
    freeCallCompanyCount: -1,
    configList: [],
  };

  private async getActiveEntities() {
    const veterinarianCount = await client.query({
      query: getTotalActiveVeterinariansCount,
      fetchPolicy: 'network-only',
    });

    const companyCount = await client.query({
      query: getTotalActiveCompaniesCount,
      fetchPolicy: 'network-only',
    });

    this.setState({
      totalVeterinarianCount: veterinarianCount.data.getTotalActiveVeterinariansCount.total,
      paidCallVeterinarianCount:
        veterinarianCount.data.getTotalActiveVeterinariansCount.availablePaidCallVetsCount,
      freeCallVeterinarianCount:
        veterinarianCount.data.getTotalActiveVeterinariansCount.availableFreeCallVetsCount,
      totalCompanyCount: companyCount.data.getTotalActiveCompaniesCount.total,
      paidCallCompanyCount:
        companyCount.data.getTotalActiveCompaniesCount.availablePaidCallCompaniesCount,
      freeCallCompanyCount:
        companyCount.data.getTotalActiveCompaniesCount.availableFreeCallCompaniesCount,
    });

    const customerCount = await client.query({
      query: getActiveCustomersCount,
      fetchPolicy: 'network-only',
    });
    this.setState({ customerCount: customerCount.data.getActiveCustomersCount });
  }

  componentDidMount() {
    this.getActiveEntities();
    this.refreshAppConfig();
  }

  private storeConfig = (appConfig: Config) => {
    const newConfigList = this.state.configList;
    newConfigList.push(appConfig);
    this.setState({ configList: newConfigList });
  };

  public refreshAppConfig = async () => {
    let result;
    try {
      result = await client.query({
        query: getConfig,
      });
      if (result && result.data && result.data.getConfig)
        result.data.getConfig.forEach((element: Config) => this.storeConfig(element));
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  private onToggle(config: Config) {
    const configList = this.state.configList;
    // config list is frozen, clone it before trying to make change

    const updatedList = JSON.parse(JSON.stringify(configList));
    // const updatedList = configList.map((data) => {
    //   if (data.key === config.key) {
    //     data.value = !config.value;
    //   }
    // });
    // updatedList[config.key].value = !config.value;
    updatedList[configList.indexOf(config)].value = !config.value;
    // console.log('UPDATED = ', updatedList);

    this.setState({ configList: updatedList as any });
  }

  private updateAppConfig = async (config: Config) => {
    try {
      await this.props.client.mutate({
        mutation: updateAppConfig,
        variables: { id: config.id, key: config.key, value: config.value },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  public renderConfig(data: Config, i: number) {
    return (
      <ConfigContainer key={data.key}>
        <ItemLeftLabel>{data.key}</ItemLeftLabel>
        <ToggleMode
          style={{ paddingRight: 400 }}
          onChange={() => this.onToggle(data)}
          toggle={true}
          checked={data.value}
          disabled={false}
        />
        <button onClick={() => this.updateAppConfig(data)}>Save</button>
      </ConfigContainer>
    );
  }

  render() {
    return (
      <>
        <Card style={{ marginBottom: 25 }}>
          <CardHeader title="Admin Dashboard" />
        </Card>
        <Card style={{ marginBottom: 25 }}>
          <CardHeader title="User Activity" />
          <CardContent>
            {'Currently available vets : ' + this.state.totalVeterinarianCount}
            <CardContent>{'PaidCall: ' + this.state.paidCallVeterinarianCount}</CardContent>
            <CardContent>{'FreeCall: ' + this.state.freeCallVeterinarianCount}</CardContent>
          </CardContent>
          <CardContent>
            {'Currently available company : ' + this.state.totalCompanyCount}
            <CardContent>{'PaidCall: ' + this.state.paidCallCompanyCount}</CardContent>
            <CardContent>{'FreeCall: ' + this.state.freeCallCompanyCount}</CardContent>
          </CardContent>
          <CardContent>{'Last hour active customers : ' + this.state.customerCount}</CardContent>

          <CardContent>
            <button onClick={() => this.getActiveEntities()}>Refresh</button>
          </CardContent>
        </Card>
        <Card style={{ marginBottom: 25 }}>
          <CardHeader title="Config Switch" />
          {this.state.configList.map((data, i) => this.renderConfig(data, i + 1))}
        </Card>
      </>
    );
  }
}

const TOGGLE_WIDTH = 55;
const TOGGLE_HEIGHT = 30;
const TOGGLE_PADDING = 4;
const TOGGLE_BUTTON_SIZE = 22;

const ToggleMode = styled(Checkbox)`
  &&&& {
    & {
      width: ${TOGGLE_WIDTH}px;
      height: ${TOGGLE_HEIGHT}px;
    }
    input ~ label:before {
      height: ${TOGGLE_HEIGHT}px;
      width: ${TOGGLE_WIDTH}px;
      background-color: ${(props) => props.theme.colors.red.dark} !important;
    }
    input ~ label:after {
      height: ${TOGGLE_BUTTON_SIZE}px;
      width: ${TOGGLE_BUTTON_SIZE}px;
      margin-top: ${TOGGLE_PADDING}px;
      margin-bottom: ${TOGGLE_PADDING}px;
      left: ${TOGGLE_PADDING}px;
    }
    input:checked ~ label:before {
      background-color: ${(props) => props.theme.colors.green.main} !important;
    }
    input:checked ~ label:after {
      left: ${TOGGLE_WIDTH - TOGGLE_BUTTON_SIZE - TOGGLE_PADDING}px;
    }
  }
`;

const ItemLeftLabel = styled.div<{ leftLabelColor?: string }>`
  font-family: ${(props) => props.theme.textStyles.typography.heading.h6.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h6.fontWeight};
  line-height: 22px;
  color: ${(props) =>
    props.leftLabelColor ? props.leftLabelColor : props.theme.colors.black.light};
  padding-left: 18px;
  width: calc(100% - 400px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ConfigContainer = styled.div`
  flex-direction: row;
  width: 100%;
  height: 60px;
  background-color: ${(props) => props.theme.colors.white.pure};
  border: 1px solid ${(props) => props.theme.colors.white.darker};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 22px;
  cursor: pointer;
`;
