import React, { Component } from 'react';
import { Props, VideoCallListItem } from './VideoCalls.container';
import styled from 'styled-components';
import { NavBarMaster } from '../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../components/UiNavigation/SubNavBar';
import { MasterBody } from '../../components/Layout/MasterBody';
import { MasterContainer } from '../../components/Layout/MasterContainer';
import { MasterSideMenu } from '../../components/Layout/MasterSideMenu';
import { MainMenuConnected } from '../../components/UiNavigation/MainMenuMaster/MainMenuConnected';
import { HeaderSectionForm } from '../../components/Form/HeaderSectionForm';
import { i18n } from '../../lib/i18n';
import { CustomFlatList } from '../../components/CustomFlatList';
import { ReactComponent as IconChevronRight24x24 } from '../../theme/icons/Icon-ChevronRight-24x24.svg';
import { appContext } from '../../context/appContext';
import { theme } from '../../theme';
import { ItemsProps } from '../../components/CustomFlatList/CustomFlatList.container';
import queryString from 'query-string';
import { loader } from 'graphql.macro';
import { DetailsCallView } from './DetailsCallView';
import { AnnualCallsView } from './AnnualCallsView';
import { raceIdItems } from '../../constants/petRaceAndSpecies';
import { Loader } from '../../components/Loader';

const getAccountVideoCallByWeek = loader('../../graphql/getAccountVideoCallByWeek.graphql');

const firstCallDateOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export interface State {
  firstCallDate: string;
  weeklyVideoCalls: VideoCallListItem[];
  urlParamId: string;
  urlParamYear: string;
  isLoading: boolean;
}

export class VideoCalls extends Component<Props, State> {
  public state: State = {
    firstCallDate: '',
    weeklyVideoCalls: [],
    urlParamId: '',
    urlParamYear: '',
    isLoading: true,
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public getParamsUrl = () => {
    const params = queryString.parse(this.props.location.search);
    const { id, year } = params;
    let urlParamId = '';
    let urlParamYear = '';
    if (id && typeof id === 'string') {
      urlParamId = id;
    } else if (year && typeof year === 'string') {
      urlParamYear = year;
    }
    this.setState({ urlParamId, urlParamYear });
  };

  public async componentDidMount() {
    // this.context.updateContext();
    this.getParamsUrl();
    try {
      const response = await this.props.client.query({
        query: getAccountVideoCallByWeek,
        fetchPolicy: 'network-only',
      });
      // console.log(response);
      this.setState({
        firstCallDate: response.data.getAccountVideoCallByWeek.firstCallDate,
        weeklyVideoCalls: response.data.getAccountVideoCallByWeek.weeklyVideoCalls,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.location !== prevProps.location) {
      this.getParamsUrl();
    }
  }

  private getPetSpecie(
    petCategory?: string,
    specie?: string
  ): { label: string; value: string; emoji: string } | null {
    if (petCategory && petCategory !== 'NAC') {
      const res = raceIdItems.filter((e) => e.value === petCategory);
      if (!res.length) return null;
      return res[0];
    }
    if (!specie) return null;
    const res = raceIdItems.filter((e) => e.value === specie);
    if (!res.length) return null;
    return res[0];
  }

  private getCallType(type?: boolean) {
    switch (type) {
      case true:
        return i18n.t('twilio.callInfoDetails.freeCall');
      case false:
        return i18n.t('twilio.callInfoDetails.paidCall');
      default:
        return '';
    }
  }

  private getCallsListFormated(videoCallListItem: VideoCallListItem[]): ItemsProps[] {
    const res = videoCallListItem.map((videoCall: VideoCallListItem) => {
      return {
        leftLabel: `${videoCall.name} | ${
          this.getPetSpecie(videoCall.petCategory, videoCall.specie)?.label
        } - ${i18n.t(
          'videoCallHandler.videoCallRequest.petTrouble.' + videoCall.petTrouble
        )} | ${this.getCallType(videoCall.isFreecall)}`,
        leftSubLabel: `${new Date(videoCall.createdAt).toLocaleDateString(
          'fr-FR',
          firstCallDateOptions
        )}`,
        leftSubLabelColor: theme.colors.grey.dark,
        rightLabel: i18n.t('videoCalls.currentWeek.see'),
        rightIcon: IconChevronRight24x24,
        onClick: () =>
          this.props.history.push({
            pathname: '/video-calls',
            search: `${this.state.urlParamYear ? `year=${this.state.urlParamYear}&` : ''}id=${
              videoCall.id
            }`,
          }),
      };
    });
    return res;
  }

  private renderMainVideoCallsList = () => {
    return (
      <>
        <HeaderContainer>
          <Subtitle>{i18n.t('videoCalls.subtitle')}</Subtitle>
          <Title>{i18n.t('videoCalls.title')}</Title>
        </HeaderContainer>

        <SectionContainer>
          {this.state.weeklyVideoCalls.length > 0 && (
            <WeekCallsContainer>
              <HeaderSectionForm title={i18n.t('videoCalls.currentWeek.title')} />

              <CustomFlatListContainer>
                <CustomFlatList data={this.getCallsListFormated(this.state.weeklyVideoCalls)} />
              </CustomFlatListContainer>
            </WeekCallsContainer>
          )}
          <YearCallsContainer>
            <HeaderSectionForm title={i18n.t('videoCalls.all.title')} />
            {this.state.firstCallDate && (
              <CustomFlatListContainer>
                <CustomFlatList data={this.getYearsListFormatted()} />
              </CustomFlatListContainer>
            )}
          </YearCallsContainer>
        </SectionContainer>
        <FirstCallText>
          {this.state.firstCallDate
            ? i18n.t('videoCalls.firstCallDate', {
                firstCallDate: new Date(this.state.firstCallDate).toLocaleDateString(
                  'fr-FR',
                  firstCallDateOptions
                ),
              })
            : i18n.t('videoCalls.noCall')}
        </FirstCallText>
      </>
    );
  };

  private getYearsListFormatted(): ItemsProps[] {
    const firstCallYear = new Date(this.state.firstCallDate).getFullYear();
    if (firstCallYear) {
      const currentYear = new Date().getFullYear();
      const yearsListFormatted = [...Array(currentYear - firstCallYear + 1)].map((_, i) => {
        return {
          leftLabel: (firstCallYear + i).toString(),
          leftLabelColor: theme.colors.grey.dark,
          rightLabel: i18n.t('videoCalls.all.see'),
          rightIcon: IconChevronRight24x24,
          onClick: () =>
            this.props.history.push({
              pathname: '/video-calls',
              search: `year=${firstCallYear + i}`,
            }),
        };
      });
      return yearsListFormatted;
    } else {
      return [];
    }
  }

  private renderContent() {
    if (this.state.isLoading) {
      return (
        <LoaderContainer>
          <Loader inline="centered" active={true} size={'big'} />;
        </LoaderContainer>
      );
    } else if (this.state.urlParamId) {
      return <DetailsCallView {...this.props} videoCallId={this.state.urlParamId} />;
    } else if (this.state.urlParamYear) {
      return (
        <AnnualCallsView
          {...this.props}
          year={this.state.urlParamYear}
          getCallsListFormated={(videoCallListItems: VideoCallListItem[]) =>
            this.getCallsListFormated(videoCallListItems)
          }
        />
      );
    } else {
      return this.renderMainVideoCallsList();
    }
  }

  public render() {
    const company = this.context.currentCompany;
    return (
      <>
        <NavBarMaster />
        <SubNavBar moderationStatus={company?.moderationStatus}/>
        <MasterBody>
          <MasterSideMenu>
            <MainMenuConnected />
          </MasterSideMenu>
          <MasterContainer>
            <Container>{this.renderContent()}</Container>
          </MasterContainer>
        </MasterBody>
      </>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.pure};
`;

const HeaderContainer = styled.div`
  padding: 50px 30px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 50px 10px;
  }
`;

const Subtitle = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontSize};
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: ${(props) =>
    props.theme.textStyles.typography.lead.capitalize.regular.letterSpacing};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.heading.h1.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
`;

const SectionContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 30px 10px;
  }
`;

const WeekCallsContainer = styled.div``;

const YearCallsContainer = styled.div`
  margin-top: 30px;
`;

const CustomFlatListContainer = styled.div`
  margin-top: 20px;
`;

const FirstCallText = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.lead.small.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.small.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.lead.small.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.grey.darker};
  text-align: center;
  margin-bottom: 30px;
`;

const LoaderContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
