export const environment = {
  API_URL: 'https://callmyvet-prod-c62de28ac52a.herokuapp.com/graphql',
  STRIPE_CONNECT_URL: 'https://dashboard.stripe.com/connect/accounts/',
  STRIPE_PUBLISHABLE_KEY: 'pk_live_w2KDOQO9WDOsgTMmeP7CoThQ00vLHa0gdp',
  // STRIPE_CONNECT_URL: 'https://dashboard.stripe.com/test/connect/accounts/',
  // STRIPE_PUBLISHABLE_KEY: 'pk_test_POOQFwpnDT8Q6AgnpwXxm8fQ00vkCIlMIG',
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyCEBpEtUZJBjAtv6GoKvpG4p76Ze6G-mcI',
    authDomain: 'callmyvet-47aa1.firebaseapp.com',
    databaseURL: 'https://call-my-vet.firebaseio.com',
    projectId: 'callmyvet-47aa1',
    storageBucket: 'callmyvet-47aa1.appspot.com',
    messagingSenderId: '404449841083',
    appId: '1:404449841083:web:d9047e0fa82c50c9c23e9e',
    measurementId: 'G-FY5LBK725X',
  },
  APP_ENV: 'production',
};
