import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../lib/withApolloClient';
import { Profile as ProfileComponent } from './Profile.component';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
import { withAppContext } from '../../lib/withContext';

export type Props = WithApolloClient<RouteComponentProps>;

export const Profile = compose<Props, RouteComponentProps>(
  withApollo,
  withAppContext
)(ProfileComponent);
