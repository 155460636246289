import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './MasterContainer.container';
import { Container } from 'react-bootstrap';

export class MasterContainer extends Component<Props> {
  public render() {
    const { noSideMenu } = this.props;
    return (
      <MainContainer fluid nosidemenu={noSideMenu?.toString()}>
        {this.props.children}
      </MainContainer>
    );
  }
}

const MainContainer = styled(Container)<{ nosidemenu: string }>`
  height: 100%;
  padding: 0;
  background: ${(props) => props.theme.colors.white.lighter};
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    margin-right: 10px;
    margin-left: 10px;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMin}) and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    margin-right: 15px;
    margin-left: 15px;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) and (max-width: ${(
      props
    ) => props.theme.breakpoints.tabletMax}) {
    margin-right: 20px;
    margin-left: 20px;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.laptop13Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    margin-right: 30px;
    margin-left: 30px;
    width: calc(100% - 60px - ${(props) => (props.nosidemenu ? '0px' : '240px')});
  }

  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.laptop15Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.laptop15Max}) {
    margin-right: 40px;
    margin-left: 40px;
    width: calc(960px - 80px + ${(props) => (props.nosidemenu ? '240px' : '0px')});
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.desktop21Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.desktop21Max}) {
    margin-right: 45px;
    margin-left: 45px;
    width: calc(1200px - 90px + ${(props) => (props.nosidemenu ? '240px' : '0px')});
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop27Min}) {
    margin-right: 60px;
    margin-left: 60px;
    width: calc(1140px - 120px + ${(props) => (props.nosidemenu ? '240px' : '0px')});
  }
`;
