import { MultipleRadioButtonForm as MultipleRadioButtonFormComponent } from './MultipleRadioButtonForm.component';

export type Props = {
  label?: string;
  sublabel?: string;
  required?: boolean;
  form: any;
  field: any;
  data: Array<{ label: string; labelComponent?: string; value: string; checked: boolean }>;
};

export const MultipleRadioButtonForm = MultipleRadioButtonFormComponent;
