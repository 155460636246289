import styled, { css } from 'styled-components';

/***************** GENERICS STYLED COMPONENTS FOR FORMS ******************/

const FormContainer = css`
  width: 100%;
  height: 44px;
  background: ${(props) => props.theme.componentObjects.formContainer.background};
  border: ${(props) => props.theme.componentObjects.formContainer.border};
  box-sizing: ${(props) => props.theme.componentObjects.formContainer.boxSizing};
  border-radius: ${(props) => props.theme.componentObjects.formContainer.borderRadius};
  padding: ${(props) => props.theme.componentObjects.formContainer.padding};
  font-weight: ${(props) => props.theme.componentObjects.formContainer.fontWeight};
  font-size: ${(props) => props.theme.componentObjects.formContainer.fontSize};
  color: ${(props) => props.theme.componentObjects.formContainer.color};
`;

const InputPlaceholder = css`
  ::-webkit-input-placeholder {
    color: ${(props) => props.theme.colors.grey.main};
  }

  ::-moz-placeholder {
    color: ${(props) => props.theme.colors.grey.main};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${(props) => props.theme.colors.grey.main};
  }
`;

const InputFocus = css`
  :focus {
    outline: none !important;
    border: 1px solid ${(props) => props.theme.componentObjects.formContainer.borderFocus};
  }
`;

const TextEllipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`;

export const FormInput = styled.input`
  ${FormContainer}
  ${InputPlaceholder}
  ${InputFocus}
  ${TextEllipsis}
`;

export const FormLongInput = styled.textarea`
  ${FormContainer}
  ${InputPlaceholder}
  ${InputFocus}
  height: auto !important;
  resize: none;
`;

export const FormLabel = styled.div`
  font-weight: ${(props) => props.theme.componentObjects.formLabel.fontWeight};
  font-size: ${(props) => props.theme.componentObjects.formLabel.fontSize};
  line-height: ${(props) => props.theme.componentObjects.formLabel.lineHeight};
  color: ${(props) => props.theme.componentObjects.formLabel.color};
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FormSubLabel = styled.div`
  font-weight: ${(props) => props.theme.componentObjects.formSubLabel.fontWeight};
  font-size: ${(props) => props.theme.componentObjects.formSubLabel.fontSize};
  line-height: ${(props) => props.theme.componentObjects.formSubLabel.lineHeight};
  color: ${(props) => props.theme.componentObjects.formSubLabel.color};
  margin-bottom: 5px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
`;

export const FormErrorLabel = styled.div`
  font-weight: ${(props) => props.theme.componentObjects.formErrorLabel.fontWeight};
  font-size: ${(props) => props.theme.componentObjects.formErrorLabel.fontSize};
  color: ${(props) => props.theme.componentObjects.formErrorLabel.color};
  white-space: nowrap;
  position: absolute;
  bottom: -20px;
  left: 10px;
`;

export const FormDropdown = styled.select`
  ${FormContainer}
  :focus {
    outline: none !important;
    border: 1px solid ${(props) => props.theme.componentObjects.formContainer.borderFocus};
  }
  -moz-appearance: none;
  -webkit-appearance: none;
  ::-ms-expand {
    display: none;
  }
`;

export const FormImport = styled.button.attrs({ type: 'button' })`
  ${FormContainer}
  ${InputFocus}
  ${TextEllipsis}
  text-align: left;
`;

export const FormImportNameFile = styled.div`
  ${FormContainer}
  ${InputFocus}
  ${TextEllipsis}
  text-align: left;
`;

export const FormDatePicker = styled.input.attrs({ type: 'date' })`
  ${FormContainer}
  ${InputFocus}
`;

export const FormSimpleCheck = styled.button.attrs({ type: 'button' })`
  ${FormContainer}
  user-select: none;
  text-align: left;
  height: auto !important;
  :focus-within {
    outline: none !important;
    border: 1px solid ${(props) => props.theme.componentObjects.formContainer.borderFocus} !important;
  }
`;

export const FormSimpleButton = styled.button.attrs({ type: 'button' })`
  ${FormContainer}
  ${TextEllipsis}
  text-align: center;
`;
/* ********************************************************************************** */

/***************** BUTTONS CMV ******************/

const BtnStylePrimary = css`
  background: ${(props) => props.theme.colors.primary.mainColor};
  border: 1px solid ${(props) => props.theme.colors.primary.darker};
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.white.pure};

  &:active,
  &:hover {
    background: ${(props) => props.theme.colors.primary.darker};
    border: 1px solid ${(props) => props.theme.colors.primary.mainColor};
    box-sizing: border-box;
    color: ${(props) => props.theme.colors.white.pure};
  }

  &[disabled] {
    background: ${(props) => props.theme.colors.white.darker};
    border: 1px solid ${(props) => props.theme.colors.grey.main};
    box-sizing: border-box;
    color: ${(props) => props.theme.colors.grey.dark};
  }
`;

const BtnStylePrimaryHollow = css`
  background: ${(props) => props.theme.colors.white.pure};
  border: 1px solid ${(props) => props.theme.colors.grey.lighter};
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.primary.mainColor};

  &:active,
  &:hover {
    background: ${(props) => props.theme.colors.primary.subtle};
    border: 1px solid ${(props) => props.theme.colors.primary.lighter};
    box-sizing: border-box;
    color: ${(props) => props.theme.colors.primary.mainColor};
  }

  &[disabled] {
    background: ${(props) => props.theme.colors.white.dark};
    border: 1px solid ${(props) => props.theme.colors.grey.main};
    box-sizing: border-box;
    color: ${(props) => props.theme.colors.grey.main};
  }
`;

const BtnStyleAlert = css`
  background: ${(props) => props.theme.colors.red.dark};
  border: 1px solid ${(props) => props.theme.colors.red.darker};
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.white.pure};

  &:active,
  &:hover {
    background: ${(props) => props.theme.colors.red.darker};
    border: 1px solid ${(props) => props.theme.colors.red.darker};
    box-sizing: border-box;
    color: ${(props) => props.theme.colors.white.pure};
  }
`;

const BtnStyleAlertHollow = css`
  background: ${(props) => props.theme.colors.red.subtle};
  border: 1px solid ${(props) => props.theme.colors.red.dark};
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.red.dark};

  &:active,
  &:hover {
    background: ${(props) => props.theme.colors.red.dark};
    border: 1px solid ${(props) => props.theme.colors.red.darker};
    box-sizing: border-box;
    color: ${(props) => props.theme.colors.white.pure};
  }
`;

const BtnSizeGiant = css`
  height: 64px;

  padding: 0 25px;
  border-width: 1px;
  border-radius: 15px;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 700;
  width: 100%;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
    min-width: 280px;
  }
`;

const BtnSizeLarge = css`
  height: 40px;
  padding: 0 20px;
  border-width: 1px;
  border-radius: 10px;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 600;
  width: 100%;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
    min-width: 280px;
  }
`;

const BtnSizeMedium = css`
  height: 32px;
  padding: 0 15px;
  border-width: 1px;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 600;
  width: 100%;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
    min-width: 180px;
  }
`;

const BtnSizeSmall = css`
  height: 24px;
  padding: 0 10px;
  border-width: 1px;
  border-radius: 12px;
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-weight: 600;
  width: 100%;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
    min-width: 120px;
  }
`;

const BtnSizeTiny = css`
  height: 20px;
  padding: 0 5px;
  border-width: 1px;
  border-radius: 10px;
  font-family: 'Inter', sans-serif;
  font-size: 9px;
  font-weight: 600;
  width: 100%;
`;

const BtnFlex = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BtnIconColor = css`
  & > svg > * {
    fill: currentColor;
  }
`;

export const BtnGiantPrimary = styled.button`
  ${BtnSizeGiant}
  ${BtnStylePrimary}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnGiantPrimaryHollow = styled.button`
  ${BtnSizeGiant}
  ${BtnStylePrimaryHollow}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnGiantAlert = styled.button`
  ${BtnSizeGiant}
  ${BtnStyleAlert}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnGiantAlertHollow = styled.button`
  ${BtnSizeGiant}
  ${BtnStyleAlertHollow}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnLargePrimary = styled.button`
  ${BtnSizeLarge}
  ${BtnStylePrimary}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnLargePrimaryHollow = styled.button`
  ${BtnSizeLarge}
  ${BtnStylePrimaryHollow}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnLargeAlert = styled.button`
  ${BtnSizeLarge}
  ${BtnStyleAlert}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnLargeAlertHollow = styled.button`
  ${BtnSizeLarge}
  ${BtnStyleAlertHollow}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnMediumPrimary = styled.button`
  ${BtnSizeMedium}
  ${BtnStylePrimary}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnMediumPrimaryHollow = styled.button`
  ${BtnSizeMedium}
  ${BtnStylePrimaryHollow}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnMediumAlert = styled.button`
  ${BtnSizeMedium}
  ${BtnStyleAlert}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnMediumAlertHollow = styled.button`
  ${BtnSizeMedium}
  ${BtnStyleAlertHollow}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnSmallPrimary = styled.button`
  ${BtnSizeSmall}
  ${BtnStylePrimary}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnSmallPrimaryHollow = styled.button`
  ${BtnSizeSmall}
  ${BtnStylePrimaryHollow}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnSmallAlert = styled.button`
  ${BtnSizeSmall}
  ${BtnStyleAlert}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnSmallAlertHollow = styled.button`
  ${BtnSizeSmall}
  ${BtnStyleAlertHollow}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnTinyPrimary = styled.button`
  ${BtnSizeTiny}
  ${BtnStylePrimary}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnTinyPrimaryHollow = styled.button`
  ${BtnSizeTiny}
  ${BtnStylePrimaryHollow}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnTinyAlert = styled.button`
  ${BtnSizeTiny}
  ${BtnStyleAlert}
  ${BtnFlex}
  ${BtnIconColor}
`;

export const BtnTinyAlertHollow = styled.button`
  ${BtnSizeTiny}
  ${BtnStyleAlertHollow}
  ${BtnFlex}
  ${BtnIconColor}
`;
