import { Props } from './Clients.container';
import React, { Component } from 'react';
import styled from 'styled-components';
import { MasterBody } from '../../components/Layout/MasterBody';
import { MasterContainer } from '../../components/Layout/MasterContainer';
import { NavBarMaster } from '../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../components/UiNavigation/SubNavBar';
import { MasterSideMenu } from '../../components/Layout/MasterSideMenu';
import { MainMenuConnected } from '../../components/UiNavigation/MainMenuMaster/MainMenuConnected';
import { Company } from '../../types/DbInterface';
import { loader } from 'graphql.macro';
import { appContext } from '../../context/appContext';
import { i18n } from '../../lib/i18n';
import { ReactComponent as CrossIcon } from '../../theme/icons/IconCrossDeleteRed.svg';
import { ReactComponent as CheckThickIcon } from '../../theme/icons/Icon-CheckboxTickGreen-16x16.svg';
import { Table, Pagination } from "react-bootstrap";
import { format } from 'date-fns';
import './Clients.styles.css';

const getAccountsBycompanyCode = loader('../../graphql/getAccountsBycompanyCode.graphql');
const setCompanyApprove = loader('../../graphql/setCompanyApprove.graphql');

interface ITableData {
  account: {
    companyApprove: boolean,
    companyCode: string,
    email: string,
    firebaseUid: string,
    firstName: string, 
    id: string, 
    lastName: string,
    permissions: boolean,
    phoneNumber: string,
    timeZone: string,
    createdAt: string,
  }
  profilePicture: {
    url: string,
  }
  customerPet: Array<any>,
}

interface AccountClients {
  id: string;
  stripeCustomerId: null;    
  profileEmoji: null;     
  profileColor: null;    
  profilePicture: {    
    id: string;  
    url: string;   
    fileType: null;  
  }
  account: {
    id: string;   
    firebaseUid: string;    
    firstName: string;   
    lastName: string;   
    phoneNumber: string;    
    email: string;   
    permissions: null;    
    timeZone: string;
    companyCode: string;    
    companyApprove: boolean;
    createdAt: string;
  }   
  customerPet: {      
    id: string;      
    name: string;      
    profileColor: null;      
    profileEmoji: null;      
    profilePicture: {        
        id: string;        
        url: string;        
        fileType: null;      
    }      
    petCategory: string;      
    specie: string;      
    race: string;      
    birthDate: string;      
    tatooNumber: string;      
    idNumber: string;      
    pedigree: string;      
    breederName: string;      
    isFemale: boolean;      
    isSterilized: boolean;      
    notes: string;      
    passeportNumber: string;      
    isArchived: boolean;      
    customerPetDewormer: {        
      id: string;        
      name: string;        
      startDate: string;        
      duration: string;        
      endDate: string;        
      notes: string;        
      fabricator: string;        
      dewormerNumber: string;        
      isArchived: boolean;        
      uploadedFiles: {          
        id: string;          
        url: string;          
        fileType: string;        
      }      
    }      
    customerPetSuppressor: {        
      id: string;        
      name: string;        
      type: string;        
      startDate: string;        
      duration: string;        
      endDate: string;        
      notes: string;        
      fabricator: string;        
      suppressorNumber: string;        
      isArchived: boolean;        
      uploadedFiles: {          
        id: string;          
        url: string;          
        fileType: string;        
      }      
    }      
    customerPetVaccine: {        
      id: string;        
      name: string;       
      startDate: string;        
      duration: string;        
      endDate: string;        
      notes: string;        
      fabricator: string;        
      vaccineNumber: string;        
      vetName: string;        
      vetOrder: string;        
      isArchived: boolean;        
      uploadedFiles: {          
        id: string;          
        url: string;          
        fileType: string;        
      }      
    }      
    customerPetVisit: {        
      id: string;        
      object: string;        
      date: string;        
      vetName: string;        
      vetOrder: string;        
      vetCity: string;        
      price: string;        
      isArchived: boolean;        
      uploadedFiles: {          
        id: string;          
        url: string;          
        fileType: string;        
      }      
    }      
    customerPetWeight: {        
      id: string;        
      unit: string;        
      date: string;        
      weight: string;        
      isArchived: boolean;      
    }    
  }
}

interface State {
  accountClients: AccountClients | undefined;
  isLoading: boolean;
  companyCode?: Company,
  filterType: string;
  allClients: Array<any>,
  pendingClients: Array<any>,
  currentPage: number,
  itemsPerPage: number,
  dataTable: ITableData[]; 
}

export class Clients extends Component<Props, State> {
  public state: State = {
    accountClients: undefined,
    isLoading: true,
    companyCode: undefined,
    allClients: [],
    pendingClients:[],
    dataTable: [],
    currentPage: 1,
    itemsPerPage: 5,
    filterType: 'all',
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;
  public async componentDidMount() {
    this.context.updateContext();
    const contextValue = this.context;
    const companyCode = contextValue.companyCode;    
    try {
      const response = await this.props.client.query({
        query: getAccountsBycompanyCode,
        variables: {
          companyCode: companyCode?.companyCode,
        },
        fetchPolicy: 'network-only',
      });
      let pending = [];
      for(let row in response.data.getAccountsBycompanyCode){
        if(response.data.getAccountsBycompanyCode[row].account.companyApprove === null){
          pending.push(response.data.getAccountsBycompanyCode[row])
        }
      }
      this.setState({
        accountClients: response.data.getAccountsBycompanyCode,
        isLoading: false,
        dataTable: response.data.getAccountsBycompanyCode,
        allClients: response.data.getAccountsBycompanyCode,
        pendingClients: pending,

      });
     
    } catch (error) {
      console.log(error);
    }
  }

  public componentWillUnmount() {
    
  }

  handleClientsFilter(filter_type: string) {
    if(filter_type === 'all') {
      this.setState({dataTable: this.state.allClients});
      this.setState({filterType: 'all'});
    } else {
      this.setState({dataTable: this.state.pendingClients});
      this.setState({filterType: 'pending'});
    }
  }

  private async handleOnChangeClientState(accountId: string, state_client: boolean) {
    try {
      const response = await this.props.client.mutate({
        mutation: setCompanyApprove,
        variables: { accountId: accountId, state: state_client},
      });
    } catch (error) {
      throw new Error();
    }
    let code = this.state.allClients.find(client => client.account.companyCode);
    try {
      const response = await this.props.client.query({
        query: getAccountsBycompanyCode,
        variables: {
          companyCode: code.account.companyCode,
        },
        fetchPolicy: 'network-only',
      });

      let pending = [];
      for(let row in response.data.getAccountsBycompanyCode){
        if(response.data.getAccountsBycompanyCode[row].account.companyApprove === null){
          pending.push(response.data.getAccountsBycompanyCode[row])
        }
      }
      this.setState({
        accountClients: response.data.getAccountsBycompanyCode,
        isLoading: false,
        dataTable: response.data.getAccountsBycompanyCode,
        allClients: response.data.getAccountsBycompanyCode,
        pendingClients: pending,
      });
     
    } catch (error) {
      console.log(error);
    }
  }

  public render() {
    const { dataTable, currentPage, itemsPerPage } = this.state;
    // Logic for displaying current items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataTable.slice(indexOfFirstItem, indexOfLastItem);

    // Logic for displaying page numbers
    const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(dataTable.length / itemsPerPage); i++) {
        pageNumbers.push(i);
      }
    return (
      <>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <MasterSideMenu>
            <MainMenuConnected />
          </MasterSideMenu>
          <MasterContainer>
            <ClientsContainer>
              <ClientsHeaderContainer>
                <ClientsHeaderTitle>{i18n.t('clients.subtitle')}</ClientsHeaderTitle>
                <ClientsHeaderSubTitle>{i18n.t('clients.title')}</ClientsHeaderSubTitle>
                <ClientsButtonsContainer>
                  <ClientsButtonAllClients  className={ this.state.filterType === 'all' ? 'clients__filter-all' : 'clients__filter' } onClick={() => {this.handleClientsFilter('all')}}>{i18n.t('clients.allClientsBtn')}</ClientsButtonAllClients>
                  <ClientsButtonPending className={ this.state.filterType === 'pending' ? 'clients__filter-pending' : 'clients__filter' } onClick={() => {this.handleClientsFilter('pending')}}>{i18n.t('clients.pendingClientsBtn', {pending_count: this.state.pendingClients.length})}</ClientsButtonPending>
                </ClientsButtonsContainer>
              </ClientsHeaderContainer>
              <ClientTableContainer>
                <Table>
                  <tbody>
                    {currentItems.map((item) => (
                      <tr key={item.account.id}>
                        <td>
                          <ClientTableFullNameContainer>
                            {item.profilePicture
                              ? <ClientImg src={item.profilePicture.url} />
                              : <ClientAvatar />
                            }
                            <ClientTableFullName>{item.account.firstName} {item.account.lastName}</ClientTableFullName>
                          </ClientTableFullNameContainer>
                        </td>
                        <td style={{width: '30%'}}>
                          <ClientTablePetNameList>
                              { item.customerPet.map((pet, index) => (
                                  <div>
                                    { index <= 1  &&
                                      <ClientTablePetName>{`${pet.name} (${pet.petCategory}), `}</ClientTablePetName>
                                    }
                                  </div>
                                ))
                              }
                              {item.customerPet.length > 0 && <ClientTableMorePets>{`et ${item.customerPet.length - 2} +`}</ClientTableMorePets>}
                            
                          </ClientTablePetNameList>
                        </td>
                        <td><ClientTableDate>{format(new Date(item.account.createdAt), 'dd/mm/yyy')}</ClientTableDate></td>
                        <td>
                          <ClientApprovalContainer>
                            { item.account.companyApprove === null && <ClientApprovalAction><CheckThickIcon style={{ 'cursor': 'pointer'}} onClick={() => {this.handleOnChangeClientState(item.account.id, true)}}/> {i18n.t('clients.approveBtn')} <CrossIcon style={{ 'cursor': 'pointer'}} onClick={() => {this.handleOnChangeClientState(item.account.id, false)}}/>  {i18n.t('clients.rejectBtn')}</ClientApprovalAction>}
                            { item.account.companyApprove !== null && 
                              <div>
                                {item.account.companyApprove ? <ClientApprovalApproved>{i18n.t('clients.approvedState')}</ClientApprovalApproved> : <ClientApprovalRejected>{i18n.t('clients.rejectedState')}</ClientApprovalRejected>}
                              </div> 
                            }
                          </ClientApprovalContainer>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination className='clients__pagination'>
                  <PaginationLabel>{i18n.t('clients.totalClientsCounter', {counter: this.state.allClients.length})}</PaginationLabel>
                  {pageNumbers.map((number) => (
                    <Pagination.Item
                      key={number}
                      active={number === currentPage}
                      onClick={() => this.setState({ currentPage: number })}
                    >
                      {number}
                    </Pagination.Item>
                  ))}
                </Pagination>
              </ClientTableContainer>
            </ClientsContainer>
          </MasterContainer>
        </MasterBody>
      </>
    );
  }
}

const ClientsContainer = styled.div`
  margin: 48px 0;
`;

const ClientsHeaderContainer = styled.div``;

const ClientsHeaderTitle = styled.div`
  margin-bottom: 12px;
`;

const ClientsHeaderSubTitle = styled.div`
  font-size: 24px;
`;

const ClientsButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 24px 0;
`;

const ClientsButtonAllClients = styled.div``;

const ClientsButtonPending = styled.div`
  border-radius: 24px;
  
  padding: 12px 24px;
`;

const ClientTableContainer = styled.div``;

const ClientTableFullNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const ClientTableFullName = styled.div``;

const ClientTablePetName = styled.div`
  text-align: center;
`;

const ClientTableDate = styled.div`
  text-align: center;
`;

const ClientApprovalContainer = styled.div``;

const ClientApprovalAction = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0;
  justify-content: flex-end;
`;

const ClientAvatar = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #788896;

`;

const ClientImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const ClientApprovalApproved = styled.div`
  padding: 8px 16px;
  background-color: #D1EFEC;
  border-radius: 24px;
  border: 2px solid #1AAE9F;
  text-align: center;
  width: 50%;
  float: right
`;

const ClientApprovalRejected = styled.div`
  background-color: #DD6E6E;
  border: 2px solid #F04447;
  padding: 8px 16px;
  border-radius: 24px;
  text-align: center;
  width: 50%;
  float: right
`;

const PaginationLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
`;

const ClientTablePetNameList = styled.div`

`

const ClientTableMorePets = styled.div`
  text-align: center;
`












