import { CompanyLegalStatusType } from '../types/company';
import { i18n } from '../lib/i18n';

//TODO onboardingVetCompany same constants
export const companyCountries: Array<{ label: string; value: string }> = [
  { label: 'France', value: 'fr' },
  { label: 'Belgique', value: 'be' },
];

export const addressCountries: Array<{ label: string; value: string }> = [
  { label: 'France', value: 'France' },
  { label: 'Belgique', value: 'Belgique' },
];

export const companyLegalStatus: Array<{ value: CompanyLegalStatusType; label: string }> = [
  { value: 'CL', label: i18n.t('companyLegalStatus.CL') },
  { value: 'EI', label: i18n.t('companyLegalStatus.EI') },
  { value: 'EIRL', label: i18n.t('companyLegalStatus.EIRL') },
  { value: 'EURL', label: i18n.t('companyLegalStatus.EURL') },
  { value: 'SA', label: i18n.t('companyLegalStatus.SA') },
  { value: 'SARL', label: i18n.t('companyLegalStatus.SARL') },
  { value: 'SAS', label: i18n.t('companyLegalStatus.SAS') },
  { value: 'SASU', label: i18n.t('companyLegalStatus.SASU') },
  { value: 'SC', label: i18n.t('companyLegalStatus.SC') },
  { value: 'SCP', label: i18n.t('companyLegalStatus.SCP') },
  { value: 'SCRL', label: i18n.t('companyLegalStatus.SCRL') },
  { value: 'SELARL', label: i18n.t('companyLegalStatus.SELARL') },
  { value: 'SLP', label: i18n.t('companyLegalStatus.SLP') },
  { value: 'SNC', label: i18n.t('companyLegalStatus.SNC') },
  { value: 'SPFPL', label: i18n.t('companyLegalStatus.SPFPL') },
  { value: 'SPFPL_VRL', label: i18n.t('companyLegalStatus.SPFPL_VRL') },
  { value: 'SPFPL_SAS', label: i18n.t('companyLegalStatus.SPFPL_SAS') },
  { value: 'SPFPL_RL', label: i18n.t('companyLegalStatus.SPFPL_RL') },
  { value: 'SE', label: i18n.t('companyLegalStatus.SE') },
  { value: 'SDE', label: i18n.t('companyLegalStatus.SDE') },
];

//TODO check backend values
export const companyTypes: Array<{ label: string; value: string }> = [
  { value: 'I', label: 'Indépendant' },
  { value: 'AS', label: 'Associés en Société' },
  { value: 'GE', label: 'Groupement d’établissements' },
];
