import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './MainMenuNotConnected.container';
import { ReactComponent as IconChevronRightDisclosure } from '../../../../theme/icons/IconChevronRightDisclosure.svg';

type MainMenuTypes = {
  title: string;
  endOfSection?: boolean;
};

const mainMenuItems: Array<MainMenuTypes> = [
  {
    title: 'Callmyvet',
  },
  { title: 'Fonctionnalités' },
  {
    title: 'Sécurité',
  },
  { title: 'À Propos' },
  { title: 'Contact' },
];

export class MainMenuNotConnected extends Component<Props> {
  public render() {
    return (
      <MainMenuNotConnectedContainer>
        {mainMenuItems.map((item, i) => (
          <MainMenuNotConnectedItem key={i} endOfSection={item.endOfSection}>
            <SideMenuNotConnectedItemlLeft>
              <MainMenuNotConnectedItemTitle>{item.title}</MainMenuNotConnectedItemTitle>
            </SideMenuNotConnectedItemlLeft>
            <MainMenuNotConnectedItemRight>
              <IconChevronRightDisclosure />
            </MainMenuNotConnectedItemRight>
          </MainMenuNotConnectedItem>
        ))}
      </MainMenuNotConnectedContainer>
    );
  }
}

const MainMenuNotConnectedContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.darker};
`;

const MainMenuNotConnectedItem = styled.button<{ endOfSection: boolean | undefined }>`
  width: 100%;
  padding: 10px 20px;
  border: none;
  background: ${(props) => props.theme.colors.white.dark};
  box-shadow: inset 0px -1px 0px rgba(23, 23, 23, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: ${(props) => (props.endOfSection ? '20px' : 0)};

  :focus {
    outline: none;
    background: ${(props) => props.theme.colors.primary.mainColor};
  }

  :active {
    background: ${(props) => props.theme.colors.primary.mainColor};
  }
`;

const SideMenuNotConnectedItemlLeft = styled.div`
  display: flex;
  align-items: center;
`;

const MainMenuNotConnectedItemTitle = styled.div``;

const MainMenuNotConnectedItemRight = styled.div``;
