import React, { Component } from 'react';
import { Props, CompanyLegalStatusType } from './OrganisationLegalInformation.container';
import styled from 'styled-components';
import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { MasterBody } from '../../../components/Layout/MasterBody';
import { MasterContainer } from '../../../components/Layout/MasterContainer';
import { HeaderSectionForm } from '../../../components/Form/HeaderSectionForm';
import { i18n } from '../../../lib/i18n';
import { Formik, Field, Form } from 'formik';
import { organisationLegalInformationValidationSchema } from './OrganisationLegalInformation.validation';
import { TextInputForm, DropdownForm } from '../../../components/Form';
import { ButtonsForm } from '../../../components/Form/ButtonsForm';
import { Account, Company, Veterinarian } from '../../../types/DbInterface';
import { appContext } from '../../../context/appContext';
import { loader } from 'graphql.macro';

import { Loader } from '../../../components/Loader';
import { client } from '../../../graphql/client';

const getProfessionalAccountWithRelations = loader('../../../graphql/getProfessionalAccountWithRelations.graphql');
const updateCompanyLegalInfo = loader('../../../graphql/updateCompanyLegalInfo.graphql');

//TODO onboardingVetCompany same constants
const companyCountries: Array<{ label: string; value: string }> = [
  { label: 'France', value: 'fr' },
  { label: 'Belgique', value: 'be' },
];

const companyLegalStatus: Array<{ value: CompanyLegalStatusType; label: string }> = [
  { value: 'CL', label: i18n.t('companyLegalStatus.CL') },
  { value: 'EI', label: i18n.t('companyLegalStatus.EI') },
  { value: 'EIRL', label: i18n.t('companyLegalStatus.EIRL') },
  { value: 'EURL', label: i18n.t('companyLegalStatus.EURL') },
  { value: 'SA', label: i18n.t('companyLegalStatus.SA') },
  { value: 'SARL', label: i18n.t('companyLegalStatus.SARL') },
  { value: 'SAS', label: i18n.t('companyLegalStatus.SAS') },
  { value: 'SASU', label: i18n.t('companyLegalStatus.SASU') },
  { value: 'SC', label: i18n.t('companyLegalStatus.SC') },
  { value: 'SCP', label: i18n.t('companyLegalStatus.SCP') },
  { value: 'SCRL', label: i18n.t('companyLegalStatus.SCRL') },
  { value: 'SELARL', label: i18n.t('companyLegalStatus.SELARL') },
  { value: 'SLP', label: i18n.t('companyLegalStatus.SLP') },
  { value: 'SNC', label: i18n.t('companyLegalStatus.SNC') },
  { value: 'SPFPL', label: i18n.t('companyLegalStatus.SPFPL') },
  { value: 'SPFPL_VRL', label: i18n.t('companyLegalStatus.SPFPL_VRL') },
  { value: 'SPFPL_SAS', label: i18n.t('companyLegalStatus.SPFPL_SAS') },
  { value: 'SPFPL_RL', label: i18n.t('companyLegalStatus.SPFPL_RL') },
  { value: 'SE', label: i18n.t('companyLegalStatus.SE') },
  { value: 'SDE', label: i18n.t('companyLegalStatus.SDE') },
];

//TODO check backend values
const companyTypes: Array<{ label: string; value: string }> = [
  { value: 'I', label: i18n.t('companyLegalStatus.I') },
  { value: 'AS', label: i18n.t('companyLegalStatus.AS') },
  { value: 'GE', label: i18n.t('companyLegalStatus.GE') },
];

interface ValuesForm {
  country: string;
  legalStatus: string;
  type: string;
  companyName: string;
}

export interface State {
  __test__companyName: string;
  currentCompany?: Company;
  loading?: boolean;
}

export class OrganisationLegalInformation extends Component<Props, State> {
  public state: State = {
    __test__companyName: 'Ventura Corp',
    currentCompany: undefined,
    loading: true,
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public async componentDidMount() {
    try {
      const response = await client.query<{
        getProfessionalAccountWithRelations?: {
          account: Account;
          veterinarian?: Veterinarian;
          company?: Company;
          companyVeterinarians?: Veterinarian[];
        };
      }>({
        query: getProfessionalAccountWithRelations,
        fetchPolicy: 'network-only',
      });
      this.setState({
        currentCompany: response.data.getProfessionalAccountWithRelations?.company, 
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  private onSubmit = async (values: ValuesForm) => {
    if (this.state.currentCompany) {
      try {
        await this.props.client.mutate({
          mutation: updateCompanyLegalInfo,
          variables: {
            companyId: this.state.currentCompany.id,
            name: values.companyName,
            legalStatus: values.legalStatus,
            companyType: values.type,
            nationality: values.country,
          },
        });
        this.context.updateContext();
        this.props.history.goBack();
      } catch (error) {
        throw new Error(error);
      }
    } else {
      throw new Error('no company');
    }
  };

  render() {
    const company = this.state.currentCompany;
    if (this.state.loading) {
      return <LoaderContainer><Loader inline="centered" active={true} size={'big'} /></LoaderContainer>;
    }
    return (
      <>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <MasterContainer noSideMenu={true}>
            <Container>
              <HeaderContainer>
                <Path>{i18n.t('organisation.legalInformations.path')}</Path>
                <Title>{i18n.t('organisation.legalInformations.title')}</Title>
              </HeaderContainer>

              <SectionContainer>
                <HeaderSectionForm
                  title={i18n.t('organisation.legalInformations.form.title')}
                  subtitle={i18n.t('organisation.legalInformations.form.subtitle')}
                />

                <Formik
                  initialValues={{
                    country: company?.nationality ? company?.nationality : '',
                    legalStatus: company?.legalStatus ? company?.legalStatus : '',
                    type: company?.companyType ? company.companyType : '',
                    companyName: company?.name ? company.name : '',
                  }}
                  onSubmit={this.onSubmit}
                  validationSchema={organisationLegalInformationValidationSchema}
                >
                  {({ values, touched, errors, setFieldValue }) => (
                    <OrganisationForm>
                      <OrganisationField>
                        <Field
                          name={'country'}
                          label={i18n.t('organisation.legalInformations.form.country.label')}
                          placeholder={i18n.t(
                            'organisation.legalInformations.form.country.placeholder'
                          )}
                          component={DropdownForm}
                          data={companyCountries}
                          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            // Do the logic needed for each case
                            setFieldValue('country', event.target.value);
                          }}
                          value={company?.nationality ? company?.nationality : ''}
                        />
                        <Field
                          name={'legalStatus'}
                          label={i18n.t('organisation.legalInformations.form.legalStatus.label')}
                          placeholder={i18n.t(
                            'organisation.legalInformations.form.legalStatus.placeholder'
                          )}
                          component={DropdownForm}
                          data={companyLegalStatus}
                          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            // Do the logic needed for each case
                            setFieldValue('legalStatus', event.target.value);
                          }}
                          value={company?.legalStatus ? company?.legalStatus : ''}
                        />
                        <Field
                          name={'type'}
                          label={i18n.t('organisation.legalInformations.form.type.label')}
                          placeholder={i18n.t(
                            'organisation.legalInformations.form.type.placeholder'
                          )}
                          component={DropdownForm}
                          data={companyTypes}
                          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            // Do the logic needed for each case
                            setFieldValue('type', event.target.value);
                          }}
                          value={company?.companyType ? company.companyType : ''}
                        />
                        <Field
                          name={'companyName'}
                          label={i18n.t('organisation.legalInformations.form.companyName.label')}
                          placeholder={i18n.t(
                            'organisation.legalInformations.form.companyName.label'
                          )}
                          component={TextInputForm}
                        />
                      </OrganisationField>
                      <ButtonsForm
                        cancelContent={i18n.t('organisation.form.button.cancel')}
                        validateContent={i18n.t('organisation.form.button.save')}
                        onCancel={() => this.props.history.goBack()}
                        type={'submit'}
                      />
                    </OrganisationForm>
                  )}
                </Formik>
              </SectionContainer>
            </Container>
          </MasterContainer>
        </MasterBody>
      </>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.pure};
  padding-left: 10px;
  padding-right: 10px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 200px;
    padding-right: 200px;
  }
`;

const HeaderContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;

const Path = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.body.small.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.body.small.fontSize};
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrganisationForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 35px;
`;

const OrganisationField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 0;
  }
`;

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;
