import { MultipleCheckForm as MultipleCheckFormComponent } from './MultipleCheckForm.component';

export type Props = {
  label?: string;
  sublabel?: string;
  required?: boolean;
  form: any;
  field: any;
  data: Array<{ label: string; value?: string; checked: boolean }>;
};

export const MultipleCheckForm = MultipleCheckFormComponent;
