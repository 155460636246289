import React, { Component } from 'react';
import { Props } from './Profile.container';
import styled from 'styled-components';
import { NavBarMaster } from '../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../components/UiNavigation/SubNavBar';
import { MasterBody } from '../../components/Layout/MasterBody';
import { MasterContainer } from '../../components/Layout/MasterContainer';
import { MasterSideMenu } from '../../components/Layout/MasterSideMenu';
import { MainMenuConnected } from '../../components/UiNavigation/MainMenuMaster/MainMenuConnected';
import { HeaderSectionForm } from '../../components/Form/HeaderSectionForm';
import { i18n } from '../../lib/i18n';
import { CustomFlatList } from '../../components/CustomFlatList';
import { ReactComponent as IconChevronRight24x24 } from '../../theme/icons/Icon-ChevronRight-24x24.svg';
import { appContext } from '../../context/appContext';
// import { Veterinarian } from '../../types/DbInterface';
import { ProfileAnimalsInCare } from './ProfileAnimalsInCare';
import { ProfileInsurance } from './ProfileInsurance';
import { ProfileMedical } from './ProfileMedical';
import { ProfileName } from './ProfileName';
import { ProfileProofOfIdentity } from './ProfileProofOfIdentity';
import { ProfileSkills } from './ProfileSkills';

import { loader } from 'graphql.macro';

const getProfessionalAccountWithRelations = loader('../../graphql/getProfessionalAccountWithRelations.graphql')

export interface State {
  hash: string;
}

export class Profile extends Component<Props, State> {
  public state: State = {
    hash: '',
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public async componentDidMount() {
    this.setState({ hash: this.props.location.hash });
    this.context.updateContext();
  }

  public async componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.location !== prevProps.location) {
      this.setState({ hash: this.props.location.hash });
      this.context.updateContext()
    }
  }

  public getCompanyModerationState(moderationStatus?: string) {
    switch (moderationStatus) {
      case 'NOT_COMPLETED':
        return 'WARNING';
      case 'PENDING_VERIFICATION':
        return 'IN_REVIEW';
      case 'VERIFIED':
        return 'VERIFIED';
      default:
        return 'WARNING';
    }
  }

  renderDefault() {
    const account = this.context.currentAccount;
    const veterinarian = this.context.currentVet;
    const company = this.context.currentCompany;
    return (
      <>
        <NavBarMaster />
        <SubNavBar moderationStatus={company?.moderationStatus}/>
        <MasterBody>
          <MasterSideMenu>
            <MainMenuConnected />
          </MasterSideMenu>
          <MasterContainer>
            <ProfileContainer>
              <ProfileHeaderContainer>
                <ProfileSubtitle>{i18n.t('profile.subtitle')}</ProfileSubtitle>
                <ProfileTitle>
                  {i18n.t('profile.title', {
                    firstName: account?.firstName,
                    lastName: account?.lastName,
                  })}
                </ProfileTitle>
              </ProfileHeaderContainer>

              <ProfileSectionContainer>
                <PersonalProfileContainer>
                  <HeaderSectionForm
                    title={i18n.t('profile.personalProfile.title')}
                    subtitle={i18n.t('profile.personalProfile.subtitle')}
                  />
                  <CustomFlatListContainer>
                    <CustomFlatList
                      data={[
                        {
                          leftLabel: `${i18n.t('profile.personalProfile.name.title')}`,
                          rightIcon: IconChevronRight24x24,
                          onClick: () => this.props.history.push('/profile#name'),
                        },
                        //   {
                        //     leftLabel: `${i18n.t('profile.personalProfile.vat')}`,
                        //     rightIcon: IconChevronRight24x24,
                        //     status: 'WARNING',
                        //     onClick: () => this.props.history.push('/profile/vat'),
                        //   },
                      ]}
                    />
                  </CustomFlatListContainer>
                </PersonalProfileContainer>
                <VeterinarianProfileContainer>
                  <HeaderSectionForm
                    title={i18n.t('profile.veterinarianProfile.title')}
                    subtitle={i18n.t('profile.veterinarianProfile.subtitle')}
                    status={this.getCompanyModerationState(veterinarian?.moderationStatus)}
                  />
                  <CustomFlatListContainer>
                    <CustomFlatList
                      data={[
                        {
                          leftLabel: `${i18n.t(
                            'profile.veterinarianProfile.proofOfIdentity.title'
                          )}`,
                          rightIcon: IconChevronRight24x24,
                          status: veterinarian?.identityCompleted
                            ? 'REQUIRED_FIELD_FILLED'
                            : 'WARNING',
                          onClick: () => this.props.history.push('/profile#identity'),
                        },
                        {
                          leftLabel: `${i18n.t(
                            'profile.veterinarianProfile.profileMedical.title'
                          )}`,
                          rightIcon: IconChevronRight24x24,
                          status: veterinarian?.medicalProfileCompleted
                            ? 'REQUIRED_FIELD_FILLED'
                            : 'WARNING',
                          onClick: () => this.props.history.push('/profile#medical-profile'),
                        },
                        {
                          leftLabel: `${i18n.t('profile.veterinarianProfile.animalsInCare.title')}`,
                          rightIcon: IconChevronRight24x24,
                          status: veterinarian?.petCategoriesCompleted
                            ? 'REQUIRED_FIELD_FILLED'
                            : 'WARNING',
                          onClick: () => this.props.history.push('/profile#animals-in-care'),
                        },
                        {
                          leftLabel: `${i18n.t('profile.veterinarianProfile.skills.title')}`,
                          rightIcon: IconChevronRight24x24,
                          status: veterinarian?.medicalExpertiseCompleted
                            ? 'REQUIRED_FIELD_FILLED'
                            : 'WARNING',
                          onClick: () => this.props.history.push('/profile#medical-expertise'),
                        },
                        {
                          leftLabel: `${i18n.t('profile.veterinarianProfile.insurance.title')}`,
                          rightIcon: IconChevronRight24x24,
                          status: veterinarian?.insuranceCompleted
                            ? 'REQUIRED_FIELD_FILLED'
                            : 'WARNING',
                          onClick: () => this.props.history.push('/profile#insurance'),
                        },
                        //   {
                        //     leftLabel: `${i18n.t('profile.veterinarianProfile.professionalInsurance')}`,
                        //     rightIcon: IconChevronRight24x24,
                        //     status: 'WARNING',
                        //     onClick: () => this.props.history.push('/profile/professional-insurance'),
                        //   },
                        //   {
                        //     leftLabel: `${i18n.t('profile.veterinarianProfile.professionalInsurance')}`,
                        //     rightIcon: IconChevronRight24x24,
                        //     status: 'WARNING',
                        //     onClick: () => this.props.history.push('/profile/professional-insurance'),
                        //   },
                      ]}
                    />
                  </CustomFlatListContainer>
                </VeterinarianProfileContainer>
              </ProfileSectionContainer>
            </ProfileContainer>
          </MasterContainer>
        </MasterBody>
      </>
    );
  }

  render() {
    switch (this.state.hash) {
      case '#animals-in-care':
        return <ProfileAnimalsInCare {...this.props} />;
      case '#insurance':
        return <ProfileInsurance {...this.props} />;
      case '#medical-profile':
        return <ProfileMedical {...this.props} />;
      case '#name':
        return <ProfileName {...this.props} />;
      case '#identity':
        return <ProfileProofOfIdentity {...this.props} />;
      case '#medical-expertise':
        return <ProfileSkills {...this.props} />;
      default:
        return this.renderDefault();
    }
  }
}

const ProfileContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.pure};
`;

const ProfileHeaderContainer = styled.div`
  padding: 50px 30px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 50px 10px;
  }
`;

const ProfileSubtitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const ProfileTitle = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.heading.h1.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
`;

const ProfileSectionContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 30px 10px;
  }
`;

const PersonalProfileContainer = styled.div``;

const VeterinarianProfileContainer = styled.div`
  margin-top: 30px;
`;

const CustomFlatListContainer = styled.div`
  margin-top: 20px;
`;
