import * as Yup from 'yup';

export const resetPasswordFormValidationSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])^(?=.*[a-z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
      'Ce champ est requis'
    )
    .required('Ce champ est requis'),
  passwordConfirmation: Yup.mixed()
    .required('Ce champ est requis')
    .test('match', 'Mot de passe non valide', function () {
      return this.parent.password === this.parent.passwordConfirmation;
    }),
});
