import React, { Component } from 'react';
import { Props } from './OnboardingVeterinarian.container';
import styled from 'styled-components';
import { MasterBody } from '../../../components/Layout/MasterBody';
import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { i18n } from '../../../lib/i18n';
import { MasterContainer } from '../../../components/Layout/MasterContainer';
import { ButtonCmv } from '../../../components/ButtonCmv';
// import { Row } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import { TextInputForm } from '../../../components/Form';
import { onboardingVeterinarianValidationSchema } from './OnboardingVeterinarian.validation';
import images from '../../../theme/images';
import { ModalCmv } from '../../../components/ModalCmv';
import { loader } from 'graphql.macro';
import { ButtonsForm } from '../../../components/Form/ButtonsForm';

const sendCompanyInvitationToEmail = loader(
  '../../../graphql/sendCompanyInvitationToEmail.graphql'
);

interface State {
  showConfirmationModal: boolean;
  isLoading?: boolean;
}

export class OnboardingVeterinarian extends Component<Props, State> {
  public state: State = {
    showConfirmationModal: false,
    isLoading: false,
  };
  private onSubmit = async (values: any) => {
    this.setState({isLoading: true});
    try {
      await this.props.client.mutate({
        mutation: sendCompanyInvitationToEmail,
        variables: {
          email: values.email,
        },
      });
      this.setState({isLoading: false});
      this.props.history.push('/onboarding/veterinarian-confirmation');
    } catch (error) {
      this.setState({isLoading: false});
      throw new Error(error);
    }
  };

  public render() {
    return (
      <Page>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <MasterContainer noSideMenu={true}>
            <OnboardingVeterinarianContainer>
              <OnboardingVeterinarianTitle>
                {i18n.t('onboardingVeterinarian.title')}
              </OnboardingVeterinarianTitle>
              <OnboardingVeterinarianDescription>
                {i18n.t('onboardingVeterinarian.description')}
              </OnboardingVeterinarianDescription>
              <OnboardingVeterinarianSectionTitle>
                Informations légales
              </OnboardingVeterinarianSectionTitle>

              <Formik
                initialValues={{
                  email: '',
                }}
                onSubmit={this.onSubmit}
                validationSchema={onboardingVeterinarianValidationSchema}
              >
                {({ values, touched, errors }) => (
                  <OnboardingVeterinarianForm>
                    <OnboardingVeterinarianField>
                      <Field
                        name={'email'}
                        label={i18n.t('onboardingVeterinarian.form.fields.email.label')}
                        placeholder={i18n.t('onboardingVeterinarian.form.fields.email.placeholder')}
                        component={TextInputForm}
                      />
                    </OnboardingVeterinarianField>
                    <ButtonsForm
                      cancelContent={i18n.t('onboardingVeterinarian.form.button.goBack')}
                      validateContent={i18n.t('onboardingVeterinarian.form.button.send')}
                      onCancel={() => {
                        this.props.history.goBack();
                      }}
                      type={'submit'}
                      spinner={this.state.isLoading}
                      disabled={this.state.isLoading ? true : false}
                    />
                  </OnboardingVeterinarianForm>
                )}
              </Formik>
            </OnboardingVeterinarianContainer>
            <ModalCmv
              show={this.state.showConfirmationModal}
              onClickOutsideCloseModal={() => null}
              contentViewPreTitle={i18n.t('onboardingVeterinarianConfirmation.title')}
              contentViewBodyContent={i18n.t('onboardingVeterinarianConfirmation.subtitle')}
              footerHidden={true}
              contentViewImage={images.HugoNoMessage}
              contentViewImageRetina={images.HugoNoMessage2x}
              contentViewCustomComponent={
                <ButtonCmvContainer>
                  <ButtonCmv
                    btnSize={'giant'}
                    btnStyle={'primary'}
                    content={i18n.t('onboardingVeterinarianConfirmation.backToLogin')}
                    onClick={() => this.props.history.push('/SignIn')}
                  />
                </ButtonCmvContainer>
              }
            />
          </MasterContainer>
        </MasterBody>
      </Page>
    );
  }
}

const Page = styled.div`
  width: 100%;
`;
const OnboardingVeterinarianContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
    padding-left: 160px;
    padding-right: 160px;
  }
`;

const OnboardingVeterinarianTitle = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.heading.h4.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h4.fontWeight};
  line-height: 32px;
  text-align: left;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 48px;
`;

const OnboardingVeterinarianDescription = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.body.main.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.body.main.fontWeight};
  line-height: 24px;
  color: ${(props) => props.theme.colors.black.lighter};
  text-align: left;
  margin-top: 21px;
  white-space: pre-wrap;
`;

const OnboardingVeterinarianSectionTitle = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.heading.h6.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h6.fontWeight};
  line-height: 22px;
  border-bottom: 2px solid ${(props) => props.theme.colors.blue.dark};
  padding: 9px 0;
  margin-top: 40px;
  color: ${(props) => props.theme.colors.primary.mainColor};
`;

// const OnboardingVeterinarianButtonsRow = styled(Row)`
//   @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
//     margin-top: 92px;
//   }
// `;

// const OnboardingVeterinarianBtnContainer = styled.div`
//   margin: 74px 0;
// `;

const OnboardingVeterinarianForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 35px;
`;

const OnboardingVeterinarianField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 0;
  }
`;

const ButtonCmvContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  /* margin-bottom: 10px; */

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 100px;
    padding-right: 100px;
  }
`;
