import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './NavBarMaster.container';
import { NavBarDesktop } from './NavBarDesktop';
import { NavBarMobile } from './NavBarMobile';
import { appContext } from '../../../context/appContext';

interface State {
  isConnected: boolean;
}

export class NavBarMaster extends Component<Props, State> {
  public state = {
    isConnected: false,
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public componentDidMount() {
    if (this.context.currentAccount) {
      this.setState({ isConnected: true });
    }
  }

  public render() {
    return (
      <NavBarContainer>
        <NavBarDesktop isConnected={this.context.currentAccount ? true : false} />
        <NavBarMobile isConnected={this.context.currentAccount ? true : false} />
      </NavBarContainer>
    );
  }
}

const NavBarContainer = styled.nav`
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  background-color: ${(props) => props.theme.colors.white.pure};
  z-index: 1000001;
  min-width: ${(props) => props.theme.breakpoints.mobilePortraitMin};
  box-shadow: 0px 1px ${(props) => props.theme.colors.black.pure + '33'};
`;
