import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { loader } from 'graphql.macro';
import { client } from '../../../graphql/client';
import { Props } from './CompanyModeration.container';
import styled from 'styled-components';
import { Company } from '../../../types/DbInterface';
import { Formik, Field, Form } from 'formik';
import {
  SimpleCheckForm,
  LongTextInputForm,
  CheckBoxForm,
  TextInputForm,
} from '../../../components/Form';
import { ButtonsForm } from '../../../components/Form/ButtonsForm';
import { i18n } from '../../../lib/i18n';
import { Loader } from '../../../components/Loader';
import { ThemeComponents } from '../../../theme/components';
import { companyLegalStatus, companyTypes } from '../../../constants/company';

const findAllCompanies = loader('../../../graphql/findAllCompanies.graphql');
const validateCompanyModerationStatus = loader(
  '../../../graphql/validateCompanyModerationStatus.graphql'
);

interface ValuesForm {
  id: string;
  legalInfo: {
    name: boolean;
    legalStatus: boolean;
    nationality: boolean;
    companyType: boolean;
  };
  address: {
    addressMain: boolean;
    complement: boolean;
    city: boolean;
    postalCode: boolean;
  };
  vat: {
    tvaIntra: boolean;
    siret: boolean;
  };
  contact: {
    email: boolean;
    phoneNumber: boolean;
  };
  insurance: {
    insurerName: boolean;
    insurerContractNumber: boolean;
  };
  bank: {
    iban: boolean;
    swift: boolean;
  };
  message: string;
}

interface State {
  companyVerifiedModerationList: Company[];
  companyNotCompletedModerationList: Company[];
  companyPendingModerationList: Company[];
  selectedCompany?: Company;
  loading: boolean;
  bankCheckbox: boolean;
  legalInfoCheckbox: boolean;
  addressCheckbox: boolean;
  insuranceCheckbox: boolean;
  vatCheckbox: boolean;
  contactCheckbox: boolean;
  showCompanyPendingList: boolean;
  showCompanyVerifiedList: boolean;
  showCompanyNotCompletedList: boolean;
}

export class CompanyModeration extends Component<Props, State> {
  public state: State = {
    companyVerifiedModerationList: [],
    companyNotCompletedModerationList: [],
    companyPendingModerationList: [],
    selectedCompany: undefined,
    loading: false,
    bankCheckbox: false,
    legalInfoCheckbox: false,
    insuranceCheckbox: false,
    addressCheckbox: false,
    vatCheckbox: false,
    contactCheckbox: false,
    showCompanyPendingList: true,
    showCompanyVerifiedList: true,
    showCompanyNotCompletedList: true,
  };

  private formikRef: any;

  async componentDidMount() {
    await this.refreshCompanyModerationList();
  }

  private storeCompany = (company: Company) => {
    const newCompanyPendingModerationList = this.state.companyPendingModerationList;
    const newCompanyVerifiedModerationList = this.state.companyVerifiedModerationList;
    const newCompanyNotCompletedModerationList = this.state.companyNotCompletedModerationList;
    switch (company.moderationStatus) {
      case 'PENDING_VERIFICATION':
        newCompanyPendingModerationList.push(company);
        this.setState({ companyPendingModerationList: newCompanyPendingModerationList });
        break;
      case 'VERIFIED':
        newCompanyVerifiedModerationList.push(company);
        this.setState({ companyVerifiedModerationList: newCompanyVerifiedModerationList });
        break;
      case 'NOT_COMPLETED':
        newCompanyNotCompletedModerationList.push(company);
        this.setState({ companyNotCompletedModerationList: newCompanyNotCompletedModerationList });
        break;
    }
  };

  public refreshCompanyModerationList = async () => {
    let result;
    try {
      result = await client.query({
        query: findAllCompanies,
        fetchPolicy: 'network-only',
      });
      if (result && result.data && result.data.findAllCompanies) {
        result.data.findAllCompanies.forEach((element: Company) => this.storeCompany(element));
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  public renderCompanyRowItem(data: Company, i: number) {
    return (
      <ConfigContainer key={data.id}>
        <ItemLeftLabel>{data.name}</ItemLeftLabel>
        <button onClick={() => this.setState({ selectedCompany: data })}>Go To</button>
      </ConfigContainer>
    );
  }
  private onSubmit = async (values: ValuesForm) => {
    try {
      const response = await this.props.client.mutate({
        mutation: validateCompanyModerationStatus,
        variables: { companyModerationInput: values },
      });
      if (response.data) {
        this.setState({
          loading: false,
          selectedCompany: undefined,
          companyPendingModerationList: [],
          companyVerifiedModerationList: [],
          companyNotCompletedModerationList: [],
        });
        this.refreshCompanyModerationList();
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  private onChangeAddressCheckBox() {
    const value = !this.state.addressCheckbox;
    this.setState({ addressCheckbox: value });
    this.formikRef.setFieldValue('address.addressMain', value);
    this.formikRef.setFieldValue('address.complement', value);
    this.formikRef.setFieldValue('address.city', value);
    this.formikRef.setFieldValue('address.postalCode', value);
  }

  private onChangeLegalInfoCheckBox() {
    const value = !this.state.legalInfoCheckbox;
    this.setState({ legalInfoCheckbox: value });
    this.formikRef.setFieldValue('legalInfo.name', value);
    this.formikRef.setFieldValue('legalInfo.legalStatus', value);
    this.formikRef.setFieldValue('legalInfo.nationality', value);
    this.formikRef.setFieldValue('legalInfo.companyType', value);
  }

  private onChangeContactCheckbox() {
    const value = !this.state.contactCheckbox;
    this.setState({ contactCheckbox: value });
    this.formikRef.setFieldValue('contact.email', value);
    this.formikRef.setFieldValue('contact.phoneNumber', value);
  }

  private onChangeInsuranceCheckbox() {
    const value = !this.state.insuranceCheckbox;
    this.setState({ insuranceCheckbox: value });
    this.formikRef.setFieldValue('insurance.insurerName', value);
    this.formikRef.setFieldValue('insurance.insurerContractNumber', value);
  }

  private onChangeVatCheckBox() {
    const value = !this.state.vatCheckbox;
    this.setState({ vatCheckbox: value });
    this.formikRef.setFieldValue('vat.siret', value);
    this.formikRef.setFieldValue('vat.tvaIntra', value);
  }

  private onChangeBankCheckbox() {
    const value = !this.state.bankCheckbox;
    this.setState({ bankCheckbox: value });
    this.formikRef.setFieldValue('bank.iban', value);
    this.formikRef.setFieldValue('bank.swift', value);
  }

  public renderCompany(company: Company) {
    return (
      <>
        <button onClick={() => this.setState({ selectedCompany: undefined })}>Go back</button>
        <Formik
          ref={(formikComponent) => (this.formikRef = formikComponent)}
          initialValues={{
            id: company.id,
            legalInfo: {
              name: false,
              legalStatus: false,
              nationality: false,
              companyType: false,
            },
            address: {
              addressMain: false,
              complement: false,
              city: false,
              postalCode: false,
            },
            vat: {
              tvaIntra: false,
              siret: false,
            },
            contact: {
              email: false,
              phoneNumber: false,
            },
            insurance: {
              insurerName: false,
              insurerContractNumber: false,
            },
            bank: {
              iban: false,
              swift: false,
            },
            message: '',
          }}
          onSubmit={this.onSubmit}
          // validationSchema={profileSkillsValidationSchema}
        >
          {(values) => (
            <ModerationForm>
              <CategoryContainer>
                <SimpleCheck onClick={() => this.onChangeLegalInfoCheckBox()}>
                  <SimpleCheckInput
                    checked={this.state.legalInfoCheckbox}
                    onChange={() => this.onChangeLegalInfoCheckBox()}
                    // label={placeholder && required ? `${placeholder + ' *'}` : placeholder}
                    // labelComponent={placeholderComponent}
                    width={24}
                    height={24}
                  />
                </SimpleCheck>
                <CategoryTitle>{'legal Infos'}</CategoryTitle>
              </CategoryContainer>
              <ModerationField>
                <Field
                  label={'id'}
                  disabled
                  name={'id'}
                  component={TextInputForm}
                  // data={}
                  placeholder={'id'}
                />
                <Field
                  label={"Nom de l'organisation"}
                  name={'legalInfo.name'}
                  component={SimpleCheckForm}
                  // data={}
                  placeholderComponent={<Value>{company.name}</Value>}
                />
                <Field
                  label={'status legal'}
                  name={'legalInfo.legalStatus'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>
                      {company.legalStatus
                        ? companyLegalStatus.find((data) => data.value === company.legalStatus)
                            ?.label
                        : 'N.C.'}
                    </Value>
                  }
                />
                <Field
                  label={'type de la company'}
                  name={'legalInfo.companyType'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>
                      {company.legalStatus
                        ? companyTypes.find((data) => data.value === company.companyType)?.label
                        : 'N.C.'}
                    </Value>
                  }
                />
                <Field
                  label={'nationalité'}
                  name={'legalInfo.nationality'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={<Value>{company.nationality}</Value>}
                />
              </ModerationField>
              <CategoryContainer>
                <SimpleCheck onClick={() => this.onChangeAddressCheckBox()}>
                  <SimpleCheckInput
                    checked={this.state.addressCheckbox}
                    onChange={() => this.onChangeAddressCheckBox()}
                    // label={placeholder && required ? `${placeholder + ' *'}` : placeholder}
                    // labelComponent={placeholderComponent}
                    width={24}
                    height={24}
                  />
                </SimpleCheck>
                <CategoryTitle>{'Adresse'}</CategoryTitle>
              </CategoryContainer>
              <ModerationField>
                <Field
                  label={'adresse 1'}
                  name={'address.addressMain'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>
                      {company.companyAddress?.addressMain
                        ? company.companyAddress.addressMain
                        : 'N.C.'}
                    </Value>
                  }
                />
                <Field
                  label={'adresse 2'}
                  name={'address.complement'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>
                      {company.companyAddress?.complement
                        ? company.companyAddress?.complement
                        : 'N.C.'}
                    </Value>
                  }
                />
                <Field
                  label={'ville'}
                  name={'address.city'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>
                      {company.companyAddress?.city ? company.companyAddress?.city : 'N.C.'}
                    </Value>
                  }
                />
                <Field
                  label={'code postal'}
                  name={'address.postalCode'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>
                      {company.companyAddress?.postalCode
                        ? company.companyAddress?.postalCode
                        : 'N.C.'}
                    </Value>
                  }
                />
              </ModerationField>
              <CategoryContainer>
                <SimpleCheck onClick={() => this.onChangeContactCheckbox()}>
                  <SimpleCheckInput
                    checked={this.state.contactCheckbox}
                    onChange={() => this.onChangeContactCheckbox()}
                    // label={placeholder && required ? `${placeholder + ' *'}` : placeholder}
                    // labelComponent={placeholderComponent}
                    width={24}
                    height={24}
                  />
                </SimpleCheck>
                <CategoryTitle>{'Contact'}</CategoryTitle>
              </CategoryContainer>
              <ModerationField>
                <Field
                  label={'email'}
                  name={'contact.email'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>{company.companyEmail ? company.companyEmail : 'N.C.'}</Value>
                  }
                />
                <Field
                  label={'phoneNumber'}
                  name={'contact.phoneNumber'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>
                      {company.companyPhoneNumber ? company.companyPhoneNumber : 'N.C.'}
                    </Value>
                  }
                />
              </ModerationField>
              <CategoryContainer>
                <SimpleCheck onClick={() => this.onChangeVatCheckBox()}>
                  <SimpleCheckInput
                    checked={this.state.vatCheckbox}
                    onChange={() => this.onChangeVatCheckBox()}
                    // label={placeholder && required ? `${placeholder + ' *'}` : placeholder}
                    // labelComponent={placeholderComponent}
                    width={24}
                    height={24}
                  />
                </SimpleCheck>
                <CategoryTitle>{'VAT'}</CategoryTitle>
              </CategoryContainer>
              <ModerationField>
                <Field
                  label={'tvaIntra'}
                  name={'vat.tvaIntra'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>{company.tvaIntra ? company.tvaIntra : 'N.C.'}</Value>
                  }
                />
                <Field
                  label={'siret'}
                  name={'vat.siret'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={<Value>{company.siret ? company.siret : 'N.C.'}</Value>}
                />
              </ModerationField>
              <CategoryContainer>
                <SimpleCheck onClick={() => this.onChangeInsuranceCheckbox()}>
                  <SimpleCheckInput
                    checked={this.state.insuranceCheckbox}
                    onChange={() => this.onChangeInsuranceCheckbox()}
                    // label={placeholder && required ? `${placeholder + ' *'}` : placeholder}
                    // labelComponent={placeholderComponent}
                    width={24}
                    height={24}
                  />
                </SimpleCheck>
                <CategoryTitle>{'Assurance'}</CategoryTitle>
              </CategoryContainer>

              <ModerationField>
                <Field
                  label={'insurerName'}
                  name={'insurance.insurerName'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>{company.insurerName ? company.insurerName : 'N.C.'}</Value>
                  }
                />
                <Field
                  label={'insurerContractNumber'}
                  name={'insurance.insurerContractNumber'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>
                      {company.insurerContractNumber ? company.insurerContractNumber : 'N.C.'}
                    </Value>
                  }
                />
              </ModerationField>
              <CategoryContainer>
                <SimpleCheck onClick={() => this.onChangeBankCheckbox()}>
                  <SimpleCheckInput
                    checked={this.state.bankCheckbox}
                    onChange={() => this.onChangeBankCheckbox()}
                    // label={placeholder && required ? `${placeholder + ' *'}` : placeholder}
                    // labelComponent={placeholderComponent}
                    width={24}
                    height={24}
                  />
                </SimpleCheck>
                <CategoryTitle>{'Bank'}</CategoryTitle>
              </CategoryContainer>
              <ModerationField>
                <Field
                  label={'iban'}
                  name={'bank.iban'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>{company.ibanNumber ? company.ibanNumber : 'N.C.'}</Value>
                  }
                />
                <Field
                  label={'swift'}
                  name={'bank.swift'}
                  component={SimpleCheckForm}
                  // data={false}
                  placeholderComponent={
                    <Value>{company.swiftNumber ? company.swiftNumber : 'N.C.'}</Value>
                  }
                />
              </ModerationField>
              <CategoryTitle>{'Message'}</CategoryTitle>
              <ModerationField>
                <Field
                  name={'message'}
                  label={'Message au vétérinaire'}
                  placeholder={'message...'}
                  component={LongTextInputForm}
                  required={true}
                />
              </ModerationField>
              <ButtonsForm
                cancelContent={i18n.t('profile.form.button.cancel')}
                validateContent={i18n.t('profile.form.button.save')}
                onCancel={() => this.setState({ selectedCompany: undefined })}
                type={'submit'}
              />
            </ModerationForm>
          )}
        </Formik>
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader inline="centered" active={true} size={'big'} />;
    return (
      <>
        <Card>
          <CardHeader title="Company Moderation" />
        </Card>
        {this.state.selectedCompany && this.renderCompany(this.state.selectedCompany)}
        {!this.state.selectedCompany && (
          <>
            <StyledCard>
              <CardHeader
                title={
                  (this.state.showCompanyPendingList ? '▾ ' : '▸ ') +
                  `🟡  Companies Pending Verification : ${
                    this.state.companyPendingModerationList &&
                    this.state.companyPendingModerationList.length
                  }`
                }
                onClick={() =>
                  this.setState({ showCompanyPendingList: !this.state.showCompanyPendingList })
                }
              />
              {this.state.showCompanyPendingList &&
                this.state.companyPendingModerationList.map((data, i) =>
                  this.renderCompanyRowItem(data, i + 1)
                )}
            </StyledCard>
            <StyledCard>
              <CardHeader
                title={
                  (this.state.showCompanyNotCompletedList ? '▾ ' : '▸ ') +
                  `🔴 Companies Not Completed : ${
                    this.state.companyNotCompletedModerationList &&
                    this.state.companyNotCompletedModerationList.length
                  }`
                }
                onClick={() =>
                  this.setState({
                    showCompanyNotCompletedList: !this.state.showCompanyNotCompletedList,
                  })
                }
              />
              {this.state.showCompanyNotCompletedList &&
                this.state.companyNotCompletedModerationList.map((data, i) =>
                  this.renderCompanyRowItem(data, i + 1)
                )}
            </StyledCard>
            <StyledCard>
              <CardHeader
                title={
                  (this.state.showCompanyVerifiedList ? '▾ ' : '▸ ') +
                  `🟢 Companies Verified : ${
                    this.state.companyVerifiedModerationList &&
                    this.state.companyVerifiedModerationList.length
                  }`
                }
                onClick={() =>
                  this.setState({ showCompanyVerifiedList: !this.state.showCompanyVerifiedList })
                }
              />
              {this.state.showCompanyVerifiedList &&
                this.state.companyVerifiedModerationList.map((data, i) =>
                  this.renderCompanyRowItem(data, i + 1)
                )}
            </StyledCard>
          </>
        )}
      </>
    );
  }
}

const StyledCard = styled(Card)`
  && {
    margin-top: 25px;
  }
`;

const SimpleCheck = styled(ThemeComponents.FormSimpleCheck)`
  display: flex;
  flex-direction: row;
  color: ${(props) => props.theme.colors.black.lighter};
  border-color: ${(props) => {
    const colorType =
      !!props.error && props.touched
        ? props.theme.colors.red.dark
        : props.theme.colors.white.darker;
    return colorType;
  }};
  width: 8%;
  font-weight: ${(props) => props.theme.componentObjects.formSimpleCheck.fontWeight};
  font-size: ${(props) => props.theme.componentObjects.formSimpleCheck.fontSize};
  line-height: ${(props) => props.theme.componentObjects.formSimpleCheck.lineHeight};
`;

const SimpleCheckInput = styled(CheckBoxForm)``;

// const Image = styled.img`
//   height: 50%;
//   width: 50%;

//   @media screen and (max-width: 479px) {
//     max-width: 320px;
//   }
// `;

const ModerationField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
`;

const ModerationForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  margin-top: 35px;
`;

const Value = styled.div``;

const CategoryContainer = styled.div`
  flex-direction: row;
  display: flex;
`;

const CategoryTitle = styled.div`
  padding-left: 10px;
  font-family: ${(props) => props.theme.textStyles.typography.heading.h1.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 22px;
  color: ${(props) =>
    props.leftLabelColor ? props.leftLabelColor : props.theme.colors.black.light};
  padding-top: 24px;
  padding-bottom: 24px;
`;

const ItemLeftLabel = styled.div<{ leftLabelColor?: string }>`
  font-family: ${(props) => props.theme.textStyles.typography.heading.h6.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h6.fontWeight};
  line-height: 22px;
  color: ${(props) =>
    props.leftLabelColor ? props.leftLabelColor : props.theme.colors.black.light};
  padding-left: 18px;
  width: calc(100% - 400px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ConfigContainer = styled.div`
  flex-direction: row;
  width: 100%;
  height: 60px;
  background-color: ${(props) => props.theme.colors.white.pure};
  border: 1px solid ${(props) => props.theme.colors.white.darker};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 22px;
  cursor: pointer;
`;
