import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../lib/withApolloClient';
import { Teams as TeamsComponent } from './Teams.component';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
import { withAppContext } from '../../lib/withContext';

export type Props = WithApolloClient<RouteComponentProps>;

export const Teams = compose<Props, RouteComponentProps>(
  withApollo,
  withAppContext
)(TeamsComponent);
