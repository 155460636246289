import { ApolloClient, InMemoryCache, ApolloLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
// import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import { persistCache } from 'apollo3-cache-persist';

import { createUploadLink } from 'apollo-upload-client';
import { SentryLink } from 'apollo-link-sentry';
import { environment } from '../environment';
import { getMainDefinition } from 'apollo-utilities';
import { Firebase } from '../lib/firebase';

const sentryDefaultOptions = {
  /**
   * Set the Sentry `transaction` to the `operationName` of the query / mutation. Note that this
   * only works if the transaction is not overwritten later in your app.
   */
  setTransaction: true,

  /**
   * Narrow Sentry's fingerprint by appending the operation's name to Sentry's {{default}} key.
   * It works in such a way that only the last operation is added, not every operation that's been
   * through the link. Note that if you override this somewhere else in your app, it is possible
   * that the value set by `apollo-link-sentry` is overwritten.
   */
  setFingerprint: true,

  breadcrumb: {
    /**
     * Set to false to disable attaching GraphQL operations as breadcrumbs. If only this breadcrumb
     * option is toggled, the breadcrumb will only show the operation name and it's type.
     */
    enable: true,

    /**
     * Include the query / mutation string in the breadcrumb.
     */
    includeQuery: false,

    /**
     * Include the entire Apollo cache in the breadcrumb. It is not recommended to enable this
     * option in production environment, for several reasons, see "Be careful what you include".
     * This option is specifically useful for debugging purposes, but when applied in combination
     * with `beforeBreadcrumb` can also be used in production.
     */
    includeCache: false,

    /**
     * Include the operation's variables in the breadcrumb. Again, be careful what you include,
     * or apply a filter.
     */
    includeVariables: false,

    /**
     * Include the operation's fetch result in the breadcrumb.
     */
    includeResponse: true,

    /**
     * If an error is received, it can be included in the breadcrumb. Regardless of this option,
     * the breadcrumb's type is set to error to reflect a failed operation in the Sentry UI.
     */
    includeError: true,

    /**
     * Include context keys as extra data in the breadcrumb. Accepts dot notation.
     * The data is stringified and formatted. Can be used to include headers for instance.
     */
    includeContextKeys: [],
  },

  /**
   * Provide a callback function which receives an instance of this package's Operation class
   * Only operations that pass the test are sent to Sentry. Leave undefined if you want all
   * operations to pass. See PR #9 for more details.
   */
  // eslint-disable-next-line
  filter: (operation: any) => true,

  /**
   * Provide a callback function which receives an instance of this package's OperationBreadcrumb class
   * Use it to modify the data that is added to the breadcrumb. Leave undefined if you want all
   * data to be included. Very useful in combination with options like includeVariables and includeContextKeys.
   */
  beforeBreadcrumb: (breadcrumb: any) => breadcrumb,
};

const cache = new InMemoryCache();

persistCache({
  cache,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TODO INVESTIGATE
  storage: window.localStorage,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (environment.APP_ENV !== 'production') {
    //   if (graphQLErrors)
    //     graphQLErrors.map(({ message, locations, path }) => {
    //       Sentry.captureException('from errorLink :' + message);
    //     });
    //   if (networkError) {
    //     Sentry.captureException('from errorLink :' + networkError);
    //   }
    // } else {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        return graphQLErrors;
      });
    if (networkError) {
      //
      console.log(`[Network error]: ${networkError}`);
    }
  }
});

const httpLink = createUploadLink({
  uri: environment.API_URL,
  credentials: 'same-origin',
});

const wsLink = new WebSocketLink({
  uri: environment.API_URL.replace(/http?/, 'ws'),
  options: {
    reconnect: true,
  },
});

const link = errorLink.concat(
  split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
  )
);

const authLink = setContext(async (context: any) => {
  const currentUser = Firebase.getCurrentUser();
  if (!currentUser) {
    return Promise.resolve({ ...context, headers: { Authorization: '' } });
  }

  try {
    const idToken = await currentUser.getIdToken();
    return Promise.resolve({ ...context, headers: { Authorization: `Bearer ${idToken}` } });
  } catch (e) {
    return null;
  }
});

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, new SentryLink(sentryDefaultOptions), link]),
  cache: new InMemoryCache(),
});
