import React, { Component } from 'react';
import styled from 'styled-components';
import { Props, StatusProps } from './HeaderSectionForm.container';
import { ReactComponent as IconWarning24x24 } from '../../../theme/icons/Icon-Warning-24x24.svg';
import { ReactComponent as IconInReview24x24 } from '../../../theme/icons/Icon-InReview-24x24.svg';
import { ReactComponent as IconVerified24x24 } from '../../../theme/icons/Icon-Verified-24x24.svg';
import { ReactComponent as IconCriticalWarning24x24 } from '../../../theme/icons/Icon-CriticalWarning-24x24.svg';
import { i18n } from '../../../lib/i18n';

export class HeaderSectionForm extends Component<Props> {
  public renderStatus(status: StatusProps) {
    let StatusIcon = null;
    let title = '';
    if (status === 'VERIFIED') {
      title = i18n.t('dashboard.companyStatus.status.verified');
      StatusIcon = styled(IconVerified24x24)``;
    } else if (status === 'IN_REVIEW') {
      title = i18n.t('dashboard.companyStatus.status.inReview');
      StatusIcon = styled(IconInReview24x24)``;
    } else if (status === 'WARNING') {
      title = i18n.t('dashboard.companyStatus.status.warning');
      StatusIcon = styled(IconWarning24x24)``;
    } else if (status === 'CRITICAL_WARNING') {
      title = i18n.t('dashboard.companyStatus.status.criticalWarning');
      StatusIcon = styled(IconCriticalWarning24x24)``;
    }

    return (
      <StatusContainer>
        <StatusTitle>{title}</StatusTitle>
        {StatusIcon && <StatusIcon />}
      </StatusContainer>
    );
  }
  public render() {
    const { title, subtitle, status } = this.props;
    return (
      <Container>
        <Title>
          {title}
          {status && this.renderStatus(status)}
        </Title>
        <Subtitle>{subtitle}</Subtitle>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
`;

const Title = styled.div`
  width: 100%;
  font-size: ${(props) => props.theme.textStyles.typography.heading.h6.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h6.fontWeight};
  line-height: 22px;
  border-bottom: 2px solid ${(props) => props.theme.colors.blue.dark};
  padding: 9px 0;
  color: ${(props) => props.theme.colors.primary.mainColor};
  display: flex;
  justify-content: space-between;
`;

const Subtitle = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.lead.tiny.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.tiny.fontWeight};
  line-height: 14px;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.grey.darker};
  margin: 8px 0;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;

const StatusTitle = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.lead.tiny.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.tiny.fontWeight};
  line-height: 12px;
  color: ${(props) => props.theme.colors.black.lighter};
  margin: 0px 10px;
  white-space: nowrap;
`;
