import { CheckBoxForm as CheckBoxFormComponent } from './CheckBoxForm.component';

export type Props = {
  checked: boolean;
  onChange: () => void;
  label?: string;
  labelComponent?: React.ReactNode;
  width?: number;
  height?: number;
  radioBtn?: boolean;
};

export const CheckBoxForm = CheckBoxFormComponent;
