import React from 'react';
import styled from 'styled-components';
import { Loader as SemanticLoader, LoaderProps } from 'semantic-ui-react';

export const Loader = (props: LoaderProps) => <StyledLoader {...props} />;

const StyledLoader = styled(SemanticLoader)`
  &&& {
    :after {
      border-top-color: ${(props) => props.theme.colors.primary.mainColor};
    }
    :before {
      border-color: ${(props) => props.theme.colors.white.darker};
    }
  }
`;
