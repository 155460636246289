import React, { Component } from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckIcon } from '../../../theme/icons/IconCheckboxTickWhite.svg';
import { Props } from './CheckBoxForm.container';

const DEFAULT_SIZE = {
  width: '16px',
  height: '16px',
};

export class CheckBoxForm extends Component<Props> {
  public render() {
    const { checked, onChange, labelComponent, width, height, label, radioBtn } = this.props;
    return (
      <CheckBoxContainer>
        {!radioBtn && <CheckBoxInput hidden onChange={() => onChange()} />}
        {radioBtn && <RadioButtonInput hidden onChange={() => onChange()} />}
        <CheckBoxCheckmark
          className={'checkmark'}
          checked={checked}
          style={{
            width: width ? width + 'px' : DEFAULT_SIZE.width,
            height: height ? height + 'px' : DEFAULT_SIZE.height,
          }}
          radioBtn={radioBtn}
        >
          {radioBtn && <RadioButton checked={checked} />}
          {!radioBtn && <CheckBoxIcon className={'checkicon'} checked={checked} />}
        </CheckBoxCheckmark>
        {label ? label : labelComponent}
      </CheckBoxContainer>
    );
  }
}

const CheckBoxContainer = styled.div`
  width: 100%;
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 16px;
`;

const CheckBoxInput = styled.input.attrs({ type: 'button' })``;
const RadioButtonInput = styled.input.attrs({ type: 'radio' })``;

const CheckBoxCheckmark = styled.span<{ checked: boolean; radioBtn: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(props) =>
    props.radioBtn
      ? props.theme.colors.white.pure
      : props.checked
      ? props.theme.colors.green.main
      : props.theme.colors.white.darker};
  border: ${(props) =>
    props.checked
      ? `${props.radioBtn ? '2px' : '1px'} solid ${props.theme.colors.green.lighter}`
      : `${props.radioBtn ? '2px' : '1px'} solid ${props.theme.colors.grey.lighter}`};
  border-radius: ${(props) => (props.radioBtn ? '50%' : '5px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckBoxIcon = styled(CheckIcon)<{ checked: boolean }>``;

const RadioButton = styled.div<{ checked: boolean }>`
  background-color: ${(props) =>
    props.checked ? props.theme.colors.green.main : props.theme.colors.white.darker};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;
