import { CallInfoDetailsBox as CallInfoDetailsBoxComponent } from './CallInfoDetailsBox.component';

interface DataProps {
  label: string;
  value: string;
}

interface ContainerProps {
  title: string;
  data: Array<DataProps>;
}

export type Props = ContainerProps;

export const CallInfoDetailsBox = CallInfoDetailsBoxComponent;
