import React, { Component } from 'react';
import styled from 'styled-components';
// import { FieldProps, FormikProps } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import { ThemeComponents } from '../../../theme/components';
import { CheckBoxForm } from '../CheckBoxForm/CheckBoxForm.component';
import { Props } from './MultipleRadioButtonForm.container';

export class MultipleRadioButtonForm extends Component<Props> {
  public handleChange(boxSelectedIndex: number, field: any) {
    const newCheckValues = field.value.map(
      (e: { label: string; value: string; checked: boolean }, i: number) => {
        if (i === boxSelectedIndex) return { ...e, checked: !e.checked };
        else return { ...e, checked: false };
      }
    );
    /* Field name must be unique across all app */
    this.props.form.setFieldValue(field.name, newCheckValues);
  }

  public render() {
    //TODO handle required label
    const { form, label, sublabel, data, field } = this.props;
    const error = form.errors[field.name];
    const touched = form.touched[field.name];
    return (
      <MultipleRadioButtonContainer fluid>
        <MultipleRadioButtonRow noGutters>
          {(label || sublabel) && (
            <Col xs={12} sm={4}>
              <MultipleRadioButtonLabelContainer>
                <MultipleRadioButtonLabel>{label}</MultipleRadioButtonLabel>
                <MultipleRadioButtonSubLabel>{sublabel}</MultipleRadioButtonSubLabel>
              </MultipleRadioButtonLabelContainer>
            </Col>
          )}
          <Col xs={12} sm={label || sublabel ? 8 : 12}>
            {data &&
              data.map((e, i) => (
                <SimpleRadioButton
                  error={error}
                  touched={touched}
                  onClick={() => this.handleChange(i, field)}
                  checked={field.value[i].checked}
                  key={i}
                >
                  <CheckBoxForm
                    checked={field.value[i].checked}
                    onChange={() => this.handleChange(i, field)}
                    label={e.label}
                    labelComponent={e.labelComponent}
                    width={22}
                    height={22}
                    radioBtn={true}
                  />
                </SimpleRadioButton>
              ))}
            {touched && error && <MutlipleCheckError>{error}</MutlipleCheckError>}
          </Col>
        </MultipleRadioButtonRow>
      </MultipleRadioButtonContainer>
    );
  }
}

const MultipleRadioButtonContainer = styled(Container)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 16px 0;
  padding: 0;
`;

const MultipleRadioButtonRow = styled(Row)`
  width: 100%;
`;

const MultipleRadioButtonLabelContainer = styled.div`
  margin: 5px 15px 5px 0;
`;

const MultipleRadioButtonLabel = styled(ThemeComponents.FormLabel)`
  /* padding-top: 8px; */
`;

const MultipleRadioButtonSubLabel = styled(ThemeComponents.FormSubLabel)``;

const SimpleRadioButton = styled(ThemeComponents.FormSimpleCheck)<{ checked: boolean }>`
  /* min-height: 64px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => props.theme.componentObjects.formMultipleRadioButton.padding};
  margin-bottom: 10px;
  border: ${(props) =>
    props.checked
      ? props.theme.componentObjects.formMultipleCheck.borderChecked
      : props.theme.componentObjects.formMultipleCheck.borderNotChecked};
  color: ${(props) =>
    props.checked
      ? props.theme.componentObjects.formMultipleCheck.textChecked
      : props.theme.componentObjects.formMultipleCheck.textNotChecked};
  font-family: ${(props) => props.theme.textStyles.typography.heading.h6.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h6.fontWeight};
  /* line-height: 22px; */

  border-radius: 11px;
`;

const MutlipleCheckError = styled(ThemeComponents.FormErrorLabel)``;
