import React, { Component } from 'react';
import { Props, ItemsProps } from './CustomFlatList.container';
import styled from 'styled-components';

import { ReactComponent as IconCheckboxTickGreen16x16 } from '../../theme/icons/Icon-CheckboxTickGreen-16x16.svg';
import { ReactComponent as IconWarning16x16 } from '../../theme/icons/Icon-Warning-16x16.svg';
import { ReactComponent as IconInReview16x16 } from '../../theme/icons/Icon-InReview-16x16.svg';
import { ReactComponent as IconVerified16x16 } from '../../theme/icons/Icon-Verified-16x16.svg';
import { ReactComponent as IconCriticalWarning16x16 } from '../../theme/icons/Icon-CriticalWarning-16x16.svg';

export class CustomFlatList extends Component<Props> {
  public renderItem(item: ItemsProps, index: number) {
    const RightIcon = item.rightIcon ? styled(item.rightIcon)`` : null;
    let ItemStatusIcon = null;
    if (item.status === 'VERIFIED') {
      ItemStatusIcon = styled(IconVerified16x16)``;
    } else if (item.status === 'REQUIRED_FIELD_FILLED') {
      ItemStatusIcon = styled(IconCheckboxTickGreen16x16)``;
    } else if (item.status === 'IN_REVIEW') {
      ItemStatusIcon = styled(IconInReview16x16)``;
    } else if (item.status === 'WARNING') {
      ItemStatusIcon = styled(IconWarning16x16)``;
    } else if (item.status === 'CRITICAL_WARNING') {
      ItemStatusIcon = styled(IconCriticalWarning16x16)``;
    }
    return (
      <ItemContainer
        onClick={item.disabled ? undefined : item.onClick}
        key={index}
        backgroundColor={item.backgroundColor}
      >
        <ItemLeftContainer fullWidth={!item.rightLabel}>
          {/* <ItemRoundCircle /> */}
          <ItemLeftLabelContainer>
            <ItemLeftLabel leftLabelColor={item.leftLabelColor}>{item.leftLabel}</ItemLeftLabel>
            <ItemLeftSubLabel leftSubLabelColor={item.leftSubLabelColor}>
              {item.leftSubLabel}
            </ItemLeftSubLabel>
          </ItemLeftLabelContainer>
        </ItemLeftContainer>
        <ItemRightContainer fullWidth={!item.rightLabel}>
          <ItemRightLabel rightLabelColor={item.rightLabelColor} className="d-none d-sm-block">
            {item.rightLabel}
          </ItemRightLabel>
          {ItemStatusIcon && (
            <StatusIconContainer>
              <ItemStatusIcon />
            </StatusIconContainer>
          )}
          {item.rightIcon && (
            <RightIconContainer disabled={item.disabled}>
              <RightIcon />
            </RightIconContainer>
          )}
        </ItemRightContainer>
      </ItemContainer>
    );
  }

  public render() {
    const { data } = this.props;
    return <>{data?.map((item: ItemsProps, i: number) => this.renderItem(item, i))}</>;
  }
}

const ItemContainer = styled.div<{ backgroundColor: string }>`
  width: 100%;
  height: 60px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.white.pure};
  border: 1px solid ${(props) => props.theme.colors.white.darker};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 22px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding-left: 12px;
    padding-right: 12px;
  }
  cursor: pointer;
`;

const ItemLeftContainer = styled.div<{ fullWidth: boolean }>`
  width: ${(props) => (props.fullWidth ? '80%' : '70%')};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ItemRightContainer = styled.div<{ fullWidth: boolean }>`
  width: ${(props) => (props.fullWidth ? '20%' : '30%')};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

// const ItemRoundCircle = styled.div`
//   min-width: 40px;
//   min-height: 40px;
//   border-radius: 50%;
//   background: ${(props) =>
//     `linear-gradient(360deg, ${props.theme.colors.white.darker} 0%, ${props.theme.colors.white.pure} 100%)`};
// `;

const ItemLeftLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ItemLeftLabel = styled.div<{ leftLabelColor?: string }>`
  font-family: ${(props) => props.theme.textStyles.typography.lead.main.fontFamily};
  font-size: ${(props) => props.theme.textStyles.typography.lead.main.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.main.fontWeight};
  line-height: 22px;
  color: ${(props) =>
    props.leftLabelColor ? props.leftLabelColor : props.theme.colors.black.light};
  /* padding-left: 18px; */
  /* width: calc(100% - 40px); */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ::first-letter {
    text-transform: capitalize;
  }
`;

const ItemLeftSubLabel = styled.div<{ leftLabelColor?: string }>`
  font-family: ${(props) => props.theme.textStyles.typography.lead.small.fontFamily};
  font-size: ${(props) => props.theme.textStyles.typography.lead.small.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.small.fontWeight};
  color: ${(props) =>
    props.leftSubLabelColor ? props.leftSubLabelColor : props.theme.colors.black.light};
  /* padding-left: 18px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ::first-letter {
    text-transform: capitalize;
  }
`;

const ItemRightLabel = styled.div<{ rightLabelColor?: string }>`
  font-size: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontWeight};
  letter-spacing: ${(props) =>
    props.theme.textStyles.typography.lead.capitalize.regular.letterSpacing};
  line-height: 24px;
  padding-right: 10px;
  color: ${(props) =>
    props.rightLabelColor ? props.rightLabelColor : props.theme.colors.primary.mainColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RightIconContainer = styled.div<{ disabled: boolean }>`
  padding-left: 20px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding-left: 5px;
  }
  & path {
    fill: ${(props) => (props.disabled ? props.theme.colors.grey.lighter : '')};
  }
`;

const StatusIconContainer = styled.div`
  /* margin: 0 20px; */
`;
