export default {
    // signUp: {
    //   title: 'You too, be part of the Callmyvet team',
    //   navToLandingConfirmWarning:
    //     "Are you sure you want to leave the registration? All data entered will be lost",
    //   form: {
    //     title: 'Step {{currentStep}}/3',
    //     previousButton: 'Previous',
    //     fields: {
    //       firstName: {
    //         label: 'First name',
    //         placeholder: 'First name',
    //       },
    //       lastName: {
    //         label: 'Last name',
    //         placeholder: 'Last name',
    //       },
    //       address: {
    //         label: 'Mailing address',
    //         placeholder: 'Mailing address',
    //       },
    //       establishment: {
    //         label: 'Institution',
    //         placeholder: 'Institution',
    //       },
    //       orderNumber: {
    //         label: "Order number",
    //         placeholder: "Order number",
    //       },
    //       insurerName: {
    //         label: 'Name of your insurer',
    //         placeholder: 'Name of your insurer',
    //       },
    //       insuredNumber: {
    //         label: "Insurance number",
    //         placeholder: "Insurance number",
    //       },
    //       legalStatus: {
    //         label: 'Legal status',
    //         placeholder: 'Limited ',
    //       },
    //       orderCounty: {
    //         label: "Department of registration of the order",
    //         placeholder: "Department of Registration",
    //       },
    //       speciality: {
    //         label: 'Your specialty',
    //         placeholder: 'Ex: ophthalmology, cardiology, orthopedics, dermatology',
    //       },
    //       siret: {
    //         label: 'SIRET',
    //         placeholder: 'Indicate your siret number',
    //       },
    //       schoolName: {
    //         label: "Name of the school",
    //         placeholder: 'Name of your school',
    //       },
    //       graduationYear: {
    //         label: 'Obtaining of the degree',
    //         placeholder: 'Year',
    //       },
    //       uploadFileInput: {
    //         backSideLabel: "(if identity card)",
    //         placeholder: 'Choose a file',
    //         placeholderDescription: '.jpg, .png, .pdf',
    //       },
    //       idCardFrontSide: {
    //         label: 'RECTO',
    //       },
    //       idCardBackSide: {
    //         label: 'VERSO',
    //       },
    //       phoneNumber: {
    //         label: 'Fixed phone',
    //         placeholder: '00 00 00 00 00',
    //       },
    //       mobilePhoneNumber: {
    //         label: 'Cell phone',
    //         placeholder: '00 00 00 00 00',
    //       },
    //       email: {
    //         label: 'Email address',
    //         placeholder: 'E-mail',
    //       },
    //       bankAccountName: {
    //         label: 'Name/company name',
    //         placeholder: 'Name of the beneficiary of the account',
    //       },
    //       iban: {
    //         label: 'iban',
    //         placeholder: 'Ex: FR95 3000 2005 0000 1578 4590 P29',
    //       },
    //     },
    //     step1: {
    //       nextButton: 'Next',
    //       subtitle: 'General information',
    //       subpartTitle: {
    //         practicePlace: "Your current place of practice",
    //       },
    //     },
    //     step2: {
    //       nextButton: 'Next',
    //       subtitle: 'Professional information',
    //       subpartTitle: {
    //         expertiseArea: 'Your fields of expertise',
    //         businessInformations: 'Your business details',
    //         vetDiploma: 'Your veterinary degree',
    //       },
    //     },
    //     step3: {
    //       nextButton: 'Finalize',
    //       subtitle: 'Personal information',
    //       cgu: {
    //         preLink: 'I agree to the',
    //         cguLink: 'Terms of Use and Privacy Policy',
    //         postLink: 'of the Callmyvet service.',
    //       },
    //       subpartTitle: {
    //         idCard: "Your identity document",
    //         idCardDescription: "(identity card or passport)",
    //         contactInformation: 'Your contact information',
    //         bankingInformation: 'Your bank details',
    //         bankingInformationDescription: '(for the transfer of the taken calls)',
    //       },
    //     },
    //     step4: {
    //       title: 'Thank you ! Your pre-registration has been taken into account.',
    //       content:
    //         'Our team is in charge of checking the information you have provided. We will confirm your final registration by e-mail within the next 48 hours.',
    //       button: "Back to the home page",
    //     },
    //     errors: {
    //       required: 'This field is mandatory',
    //       requiredExpertiseArea: 'Please select at least one area',
    //       requiredCgu: 'Please accept the terms and conditions',
    //       requiredFile: 'Please upload a document to continue',
    //       rejectedFile: 'Please upload a file of 5 MB or less',
    //       duplicateEmail: "The e-mail address you entered is already in use",
    //       invalidEmail: 'Invalid e-mail',
    //     },
    //   },
    // },
    signIn: {
      title: 'Veterinarians, please login to access your dashboard.',
      form: {
        fields: {
          email: {
            label: 'E-mail address',
            placeholder: 'Enter your email address',
          },
          password: {
            label: 'Password',
            placeholder: 'Password',
          },
        },
        error: {
          wrongEmailPassword: 'E-mail/password combination does not match',
        },
      },
      forgottenPassword: 'Forgot your password?',
      noAccount: 'Don’t have an account yet?\nTo create one, ',
      signUpLink: 'go here!',
      backLabel: 'Go back',
    },
    // account: {
    //   title: 'Account',
    //   subTitle: 'Your payment information',
    //   validationText:
    //     'Validation of your Stripe account is required to receive payments. Remember to prepare an ID, a Kbis and a proof of address. You can use the camera of your computer or your phone to make the scans of these documents.',
    //   ibanText: 'Fill in/modify your IBAN.',
    //   infoTips: 'Note: Remember to refresh your page once all the information has been provided.',
    //   buttons: {
    //     onBoardingInvalide: 'Finalize your account',
    //     onBoardingValide: 'Account validated',
    //     onBoardingPending: 'In the process of being validated',
    //     ibanInvalide: 'Enter an IBAN',
    //     ibanValide: 'Edit',
    //   },
    //   ibanForm: {
    //     title: 'IBAN',
    //     subTitle:
    //       'Add or change your IBAN to receive payments and make automatic transfers for the Call My Vet account.',
    //     form: {
    //       fields: {
    //         name: {
    //           label: 'Account holder name',
    //           placeholder: 'Jenny Rosen',
    //         },
    //         iban: {
    //           label: 'IBAN number',
    //         },
    //       },
    //     },
    //     stripeText:
    //       'By providing your IBAN and confirming this payment method, you authorize Call My Vet SAS and Stripe, our payment service provider, to send instructions to your bank to debit your account and for your bank to debit your account in accordance with these instructions. You are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be requested within 8 weeks from the date your account is debited.',
    //     popup: {
    //       valid: '✅ Your IBAN has been registered by Stripe',
    //       errorLeftButton: 'Contact customer service',
    //       error:
    //         '⚠️ Your IBAN is wrong, please check your entry. If the problem persists, please contact our teams.',
    //     },
    //   },
    // },
    admin: {
      vet: {
        moderationStatus: {
          pending: 'Waiting for moderation',
          refused: 'Missing / wrong information',
          validated: 'Account validated',
        },
        nationality: {
          french: 'FRENCH',
          belgian: 'BELGIAN',
        },
        speciality: {
          generalPractitioner: 'GENERAL_PRACTITIONER',
          behaviourist: 'BEHAVIOURIST',
          osteopath: 'OSTEOPATH',
          ophthalmologist: 'OPHTHALMOLOGIST',
          dentist: 'DENTIST',
          nutritionist: 'NUTRITIONIST',
          nursery: 'NURSERY',
          ethologist: 'ETHOLOGIST',
          parasitologiste: 'PARASITOLOGISTE',
          dermatologist: 'DERMATOLOGIST',
          gastrologue: 'GASTROLOGUE',
          virologist: 'VIROLOGIST',
          urologist: 'UROLOGIST',
          traumatologist: 'TRAUMATOLOGIST',
          orthopedist: 'ORTHOPEDIST',
          surgeon: 'SURGEON',
        },
        expertiseArea: {
          catAndDog: 'catAndDog',
          cat: 'cat',
          dog: 'dog',
          NAC: 'NAC',
          horse: 'horse',
        },
      },
    },
    shared: {
      optionalField: '(optional)',
      nextButton: 'Continue',
      raceItem: {
        catAndDog: 'Cats & dogs',
        cat: 'Cats',
        dog: 'Dogs',
        horse: 'Equine',
        NAC: 'N.A.C.',
      },
      speciesItem: {
        Cameleon: 'Chameleon',
        Chevre: 'Goat',
        Cochon: 'Pig',
        CochondInde: "Guinea pig",
        Dinde: 'Turkey',
        Furet: 'Ferret',
        Hamster: 'Hamster',
        Herisson: 'Hedgehog',
        Lama: 'Llama',
        Lapin: 'Rabbit',
        Lezard: 'Lizard',
        Mouton: 'Sheep',
        Perroquet: 'Parrot',
        Poisson: 'Fish',
        Poule: 'Chicken',
        Rat: 'Rat',
        Scorpion: 'Scorpion',
        Serpent: 'Snake',
        Souris: 'Mouse',
        Tortue: 'Turtle',
        Autre: 'Other species',
      },
    },
    navBar: {
      connected: {
        accueil: 'Home',
        compte: 'Account',
        deconnexion: 'Logout',
      },
      // informationBar: {
      //   document: {
      //     text: 'IMPORTANT: You must finalize your Stripe account to receive payments',
      //     link: 'Validate my Stripe account',
      //   },
      //   iban: {
      //     text: 'IMPORTANT: You must enter your IBAN to receive payments',
      //     link: 'Add my IBAN',
      //   },
      // },
      modal: {
        mainMenu: {
          title: 'MENU',
        },
        profileMenu: {
          title: 'MENU PROFILE',
        },
      },
    },
    dashboard: {
      // signOutLabel: 'Logout',
      // toggleAvailability: {
      //   nameTitle: 'Welcome to the site,',
      //   blockContentTitle: 'Your Availability',
      //   blockContentSubtitle:
      //     'To receive calls, press the button opposite so that it has a green color.',
      //   informationBlock: {
      //     title: 'Useful information',
      //     link: {
      //       guide: 'Practical guide',
      //       tarif: 'Pricing ?',
      //       parainage: 'Refer a colleague',
      //       estimation: "Estimation tools",
      //       satisfaction: 'Satisfaction survey',
      //       contacte: 'Contact us',
      //       suggestion: 'Make a suggestion',
      //       info: 'About us',
      //     },
      //   },
      // },
      title: 'Welcome',
      availabeVets: {
        title: 'Available veterinarians',
        subtitle: 'You must have at least one verified veterinarian profile to receive calls.',
        noVetAvailable: {
          text: 'Enter at least one veterinarian in the',
          link: 'members of your team',
        },
        companyNotVerified: {
          text: 'You must',
          link: 'complete your company’s registration',
          textEnd: 'in order to assign veterinarians.',
        },
        currentVetNotVerified: {
          text: 'You must',
          link: 'complete your veterinarian profile',
          textEnd: 'in order to receive calls.',
        },
      },
      companyStatus: {
        title: 'Company status {{companyName}}',
        subtitle:
          'You must fill in all the information below to receive calls.',
        legalInformation: 'Legal information',
        vat: 'Siret and VAT',
        address: 'Address',
        contact: 'Contact',
        bankingInformation: 'Bank information, Stripe',
        professionalInsurance: 'Professional insurance',
        teamMembers: 'Members of the team',
        status: {
          verified: '',
          inReview: 'Currently being verified',
          warning: 'Missing information',
          criticalWarning: 'Missing critical information',
        },
      },
      information: {
        title: 'Useful information',
        subtitle:
          'To receive calls, press the button on the right so that it has a green color.',
        links: {
          howItWorks: 'How does it work ?',
          pricing: 'Pricing',
          estimationTools: "Estimation tools",
          satisfactionSurvey: 'Satisfaction survey',
          contactUs: 'Contact us',
          suggestion: 'Make a suggestion',
        },
      },
      cardText: {
        available: 'You are active',
        unavailable: 'You are inactive',
      },
      codeAlert: {
        codeText: 'Your clinic’s code is: ',
        codeHelpText: 'This is the code that you need to communicate to your clients so that they can find you on the Callmyvet app'
      },
    },
    videoCallHandler: {
      videoCallRequest: {
        title: 'Request in progress...',
        subtitle: '{{customerGender}} {{customerFirstName}},\n For: {{petName}}',
        informations: "Reason for call:",
        buttons: {
          acceptCall: "Accept video call",
          declineCall: "Decline video call",
        },
        chooseVeterinarian: {
          title: 'Select the veterinarian to answer',
        },
        petTrouble: {
          GeneralPractitioner: 'General Medicine',
          Behaviourist: 'Behavior',
          Osteopath: 'Osteopathy',
          Ophthalmologist: 'Ophthalmology',
          Dentist: 'Odontology',
          Nutritionist: 'Nutrition',
          Nursery: 'Gynecology',
          Ethologist: 'Ethology',
          Parasitologist: 'Parasitology',
          Dermatologist: 'Dermatology',
          Gastrologue: 'Gastrology',
          Virologist: 'Contagious Diseases',
          Urologist: 'Urology',
          Traumatologist: 'Traumatology',
          Orthopedist: 'Orthopedics',
          Surgeon: 'Surgery',
        },
      },
      videoCallStatusCard: {
        alreadyAcceptedCall: {
          title: 'Request taken in charge',
          subtitle: "The request has been taken care of by one of your colleagues.",
        },
        cancelledCall: {
          title: 'Missed call !',
          subtitle: 'The call has been cancelled by the user.',
        },
        timedOutCall: {
          title: 'Missed call !',
          subtitle: 'No veterinarian has answered the call.',
        },
        enableGestureForSound: {
          title: 'Your browser has put the ringtone on standby',
          subtitle:
            "To hear the browser sound again, please click anywhere on the page. If you no longer wish to see this alert, please change the access privileges of pro.callmyvet.io in your browser preferences.",
          buttonContent: 'Reactivate the browser sound',
          procedure: {
            chrome: `
            <title>CHROME</title><br/>
             <bold>Click on the "3 vertical dots" icon, then select "Preferences:</bold>
             <br/>
             <br/>
             <leftAlignText>
               / Preferences<br/>
               / Privacy and Security<br/>
               / Sites setting<br/>
               / Microphone<br/>
               / Click on the line https://pro.callmyvet.io
               </leftAlignText>
               <br/>
             -> Change the <bold>"Do not play audio content"</bold> setting to <bold>"Always allow autoplay"</bold>`,
             firefox:`
             <title>FIREFOX</title><br/>
             <bold>The pro.callmyvet.io page must be open in Firefox. Click on the “padlock” icon to the left of the url in the navigation bar:</bold>
             <br/>
             <br/>
             <leftAlignText>
             / Click on the arrow to the right of “Secure connection”<br/>
             / Then click on “More information” at the bottom of the popup.<br/>
             / Click on “Permission”<br/>
             / On the line “Autoplay media”<br/>
             </leftAlignText>
             <br/>
             -> Uncheck <bold>“Default permission”</bold>
             -> Select <bold>“Allow audio and video”</bold>`,
             safari:`
             <title>SAFARI</title><br/>
             <bold>The pro.callmyvet.io page must be open on Safari. Click on Safari in the top left menu, then select “Preferences:</bold>
             <br/>
             <br/>
             <leftAlignText>
             / Preferences<br/>
             / Websites<br/>
             / Autoplay<br/>
             / Find “pro.callmyvet.io” in the list of websites<br/>
             </leftAlignText>
             <br/>
             -> Change the setting <bold>“Do not play sound content”</bold> to <bold>“Always allow autoplay”</bold>`,
           },
        },
      },
    },
    surveyEndCall: {
      headerTitle: 'Quality of service commitment',
      headerLabelRight: 'To know more',
      preTitle: 'Regulatory form',
      mainTitle: 'End of call',
      body:
        'Was there a problem? Call {{ownerName}} at {{phoneNumber}} \All fields with * are required',
      form: {
        callStatus: {
          label: 'Payment validation *',
          sublabel:
            'The quality of the service relies on the expertise of each veterinarian, including you. We trust your judgment to charge or not for this call. Please note that our commission will be deducted at the end of the month, regardless of your decision. This measure aims to maintain the quality of the service while preventing abuse.',
          validated: 'Validate the payment',
          notValidated: 'Decline the payment',
        },
        submit: 'Send the form',
        error: {
          required: 'Select an option',
        },
      },
    },
    // choosingPassword: {
    //   errors: {
    //     setPasswordFailed: "An error has occurred, your password has not been created",
    //     missingParams: "An error occurred, the link is not valid",
    //     passwordTooShort: 'Password must be at least 8 characters long',
    //   },
    //   password: {
    //     label: 'Password (8 characters min.)',
    //     placeholder: 'Your password',
    //   },
    //   content:
    //     "In order to access your account and start receiving calls, please set your personal password.",
    //   title: 'Set your password',
    // },
    resetPaswordRequestEmail: {
      subject: 'Call My Vet - Vet - Forgot your password',
      body:
        "Hello, I am writing to you today because I forgot my password. Can you please send me a reset link? Thank you in advance.",
    },
    twilio: {
      callHeader: '🟢 Video call in progress',
      hangUpModalMessage: "You are about to end the call. Are you sure?",
      picturesList: {
        headerLabel: 'PICTURES OF {{petName}}',
        refreshButtonLabel: 'Refresh media received',
        emptyListLabel: 'No media received',
      },
      videoStatus: {
        tryToReconnect: "User is trying to reconnect",
        reconnecting: "Please wait, the user is connecting...",
        videoDisabled: "The user has disabled his video",
        audioDisabled: "The user has disabled his microphone",
        disconnect: "User has been disconnected",
        cameraNotFound: "Camera not available or not found",
        cameraNotAccess: "Camera access permission denied or being used by another app",
        cameraFailure: "Camera is not readable or damaged",
      },
      videoTabItems: {
        call: 'Call',
        documents: 'Documents',
        animalCard: "Animal's file",
      },
      callInfoDetails: {
        pet: 'Animal',
        petName: 'Name',
        specieAndRace: 'Species / Breed',
        ageAndSex: 'Age / Sex',
        male: 'Male',
        female: 'Female',
        sterilized: 'Sterilized',
        freeCall: 'Free call',
        paidCall: 'Paid call',
        owner: 'Owner',
        firstNameAndLastName: 'First name Last name',
        phoneNumber: 'Phone',
        email: 'Email',
        cityAndCountry: 'City / Country',
        infos: '📋 Infos',
        callPurpose: 'Subject of the call',
        tarification: 'Rate',
        regularTarification: 'Basic rate',
        slightlyIncreasedTarification: 'Premium rate',
        increasedTarification: 'On-call rate',
        callType: "Type of call",
        callDuration: 'Duration of the call',
        yes: 'yes',
        no: 'no',
        unknown: 'N.C',
      },
      deconnexionAlert:
        "We hope your teleconsultation went well! You can call back {{petName}} at {{customerPhoneNumber}} if you wish. The Callmyvet team",
    },
    login: {
      title: 'Connection',
      subtitle: 'The reliable and legal solution for veterinary remote control.',
      form: {
        fields: {
          email: {
            label: 'Email',
            placeholder: 'Email',
          },
          password: {
            label: 'Password',
            placeholder: 'Password',
          },
        },
        submit: 'Login',
      },
      link: {
        forgotPassword: 'Forgot your password?',
        noAccount: 'Don’t have an account? ',
        noAccountLink: 'Sign up now.',
      },
    },
    resetPasswordRequest: {
      title: 'Reset Password',
      subtitle: 'Forgot your password?',
      form: {
        fields: {
          email: {
            label: 'Email',
            placeholder: 'Email',
          },
        },
        indication:
          'An email will be sent to you with a link to reset your password.',
        submit: 'Send',
        errors: {
          emailNotFound: "We did not find an account with this email address",
          tooManyRequest:
            'Your account has reached the application limit, please wait to try again',
        },
      },
      link: {
        login: 'Login',
      },
      confirmationModal: {
        subtitle: 'Your request has been processed, please check your mailbox.',
        backToLogin: 'Back to homepage',
      },
    },
    resetPasswordRequestEmailSentConfirmation: {
      title: 'Reset password',
      subtitle:
        'Your request has been processed, please check your mailbox. You can close this window.',
    },
    createAccount: {
      title: 'Create your account',
      description:
        'Please use your professional email, it will not be visible publicly and we will have to send you important information. You will be able to communicate later a "public" contact email if you wish. Already have an account?',
      linkLogin: 'Log in at',
      subnavbar: {
        title: 'The community that respects vets.',
      },
      form: {
        fields: {
          firstName: {
            label: 'First name *',
            placeholder: 'Your first name',
          },
          lastName: {
            label: 'Last name *',
            placeholder: 'Your last name',
          },
          email: {
            label: 'Email *',
            placeholder: 'Your email *',
          },
          password: {
            label: 'Your Password *',
            placeholder: 'Create a password',
          },
          cgu: {
            label: 'Terms and Conditions *',
            placeholder: {
              firstPart: 'I agree to the',
              firstLink: 'Terms of use ',
              and: 'and the ',
              secondLink: 'Privacy Policy',
              secondPart: 'of the teleconsulting service offered by the company Call My Vet SAS. *',
            },
          },
        },
        submit: 'Send',
        informations: {
          security:
            'You are in good hands. Your data is secured with the highest standards.',
          required: 'Fields marked with * are required.',
        },
      },
      confirmation: {
        headerTitle: 'We have just sent you an email',
        subtitle: 'Go to your mailbox...',
        emailSend: "The email has been sent to :",
        recipient: ' {{email}}',
        description:
          'This email contains an activation link. Please click on this link. We need to verify your account before opening our doors to you. You can close this page.',
        emailNotReceived: {
          description:
            'The email did not appear in your mailbox? Check your spam folder. The email was sent from noreply@callmyvet.io',
          resendEmailLink: {
            label: 'You did not receive an email?',
            link: 'Resend a code',
          },
          cancelLink: {
            label: 'You don’t want to subscribe anymore ?',
            link: 'Cancel my subscription',
          },
        },
      },
      deleteAccount: {
        preTitle: 'Votre compte à été supprimé',
        bodyContent: 'Your account has been deleted',
        buttonLabel: "Back to homepage",
      },
    },
    createAccountVerification: {
      title: 'Verification of your account',
      firstSection: 'Our system is currently verifying your account. Please wait.',
      errors: {
        title: 'An error has occurred',
        argumentError: "An error has occurred, the link is invalid",
        invalidActionCode: 'Your request is invalid or has already been used.',
        expiredActionCode: 'Your request has expired.',
        userDisabled: 'Your firebase user account is disabled',
        userNotFound: "Your firebase user account was not found",
        missingParams:
          'You need to click on the email link to verify your email address.',
        default: "No error code matches",
        buttonConnected: 'Send a new request',
        buttonNotConnected: 'Login to send a new request',
      },
    },
    createAccountValidated: {
      title: 'Your account is activated',
      firstSection:
        'Thank you for trusting us. Transparency, respect for the rules and values of the profession are at the heart of our approach. Callmyvet is for veterinarians who are convinced that telemedicine brings a real plus in the care of pets. For the others, try to let yourself be seduced a little.',
      continue: 'Continue',
    },
    onboardingAccount: {
      title: 'Welcome {{accountFirstName}},',
      description:
        'Before you can receive calls, you must be attached to a company that is authorized to practice veterinary medicine in France. You will enter a process that requires a certain amount of information necessary to receive calls. If you do not have all the information about you, go to the next screen, you can come back later to complete.',
      firstSection: {
        title: 'Independent veterinarian or associate',
        row: {
          leftLabel: 'Add a company on Callmyvet',
          rightLabelCreate: 'Create',
          rightLabel: 'Select',
        },
      },
      secondSection: {
        title: 'Employed veterinarian',
        row: {
          leftLabel: 'Join a team',
          rightLabel: 'Join',
        },
      },
    },
    onboardingVetCompany: {
      title: 'Create a company',
      description:
        'All information marked with an * is required to be in good standing with the College. You can however skip this step and complete your profile later.',
      form: {
        fields: {
          country: {
            label: 'Country of the company *',
            placeholder: 'Select a country',
          },
          legalStatus: {
            label: 'Legal status of the company *',
            placeholder: 'Select a status',
          },
          type: {
            label: 'Company type *',
            placeholder: 'Select a type',
          },
          companyName: {
            label: 'Company name *',
            placeholder: 'Company name',
          },
        },
        button: {
          goBack: 'Previous',
          continue: 'Continue',
        },
      },
    },
    onboardingVeterinarian: {
      title: 'I am an employee',
      description:
        'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam id dolor id nibh ultricies vehicula ut id elit. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Aenean lacinia bibendum nulla sed consectetur. Vestibulum id ligula porta felis euismod semper.',
      form: {
        fields: {
          email: {
            label: 'Email from your employer *',
            placeholder: 'Employer’s email',
          },
        },
        button: {
          goBack: 'Previous',
          send: 'Send',
        },
      },
    },
    onboardingVeterinarianConfirmation: {
      title: 'Invitation from your employer',
      subtitle: 'Your request has been taken into account.',
      backToLogin: 'Back to homepage',
    },
    onboardingSideMenu: {
      items: {
        first: 'No obligation',
        second: 'Verified profiles',
        third: 'Secure data',
        fourth: 'Approved by the College',
      },
    },
    organisation: {
      form: {
        button: {
          cancel: 'Cancel',
          save: 'Register',
        },
      },
      legalInformations: {
        title: 'Legal information',
        path: 'Organization / Legal Information',
        form: {
          title: 'Company information',
          subtitle: '',
          country: {
            label: 'Company country *',
            placeholder: 'Company country ',
          },
          legalStatus: {
            label: 'Legal status of the company *',
            placeholder: 'Legal status of the company',
          },
          type: {
            label: 'Type of company *',
            placeholder: 'Type of company',
          },
          companyName: {
            label: 'Company name *',
            placeholder: 'Company name',
          },
        },
      },
      vat: {
        title: 'Siret number and VAT',
        path: 'Organization / Siret and VAT',
        form: {
          title: 'Company information',
          subtitle:
            'The SIRET number and the VAT number are necessary to be able to issue invoices in your name to pet owners.',
          siretNumber: {
            label: 'Siret number of the company *',
            sublabel: '(BCE number for Belgian companies)',
            placeholder: 'Siret number of the company',
          },
          vatNumber: {
            label: 'VAT number of the company *',
            placeholder: 'VAT number of the company',
          },
        },
      },
      address: {
        title: 'Address',
        path: 'Organization / address',
        form: {
          title: 'Company address',
          subtitle: 'Enter the address of the company’s headquarters',
          addressMain: {
            label: 'Address line 1 *',
            placeholder: 'Address line 1',
          },
          addressComplement: {
            label: 'Address line 2',
            placeholder: 'Address line 2',
          },
          city: {
            label: 'City or Town *',
            placeholder: 'City',
          },
          zipCode: {
            label: 'Zip code *',
            placeholder: 'Zip code',
          },
        },
      },
      contact: {
        title: 'Contact',
        path: 'Organization / Contact',
        form: {
          title: 'Company contact information',
          subtitle: '',
          email: {
            label: 'Company contact email',
            placeholder: 'Company contact email',
          },
          phoneNumber: {
            label: 'Company Phone',
            placeholder: 'Company Phone',
          },
        },
      },
      bankingInformation: {
        title: 'Bank Information',
        path: 'Organization / Bank Information',
        form: {
          stripe: {
            title: 'Stripe information for paid calls',
            subtitle:
              'You will need your K-Bis and to answer some questions. To make transfers to your bank account, you must provide us with an IBAN. By providing your IBAN and confirming this payment method, you authorize Call My Vet SAS and Stripe, our payment service provider, to send instructions to your bank to debit your account and for your bank to debit your account in accordance with these instructions. You are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be requested within 8 weeks from the date your account is debited.',
            label: 'Status of your account',
            status: {
              validated: 'Account validated',
              noValidated: 'Compte limité',
              pending: 'In the process of being validated',
            },
            link: {
              add: 'Complete your profile on Stripe',
              modify: 'Edit Stripe information',
            },
          },
          iban: {
            title: 'Free Call Information',
            subtitle:
              'In order to receive payments for free calls, you must provide us with your bank account information.',
            label: 'Status of your IBAN',
            status: {
              noIban: 'No IBAN',
              ibanFilled: 'filled in',
            },
            link: {
              add: 'Add an IBAN',
              modify: 'Modify your IBAN',
            },
          },
          email: {
            label: 'Company contact email',
            placeholder: 'Company contact email',
          },
          phoneNumber: {
            label: 'Company phone number',
            placeholder: 'Company phone number',
          },
        },
      },
      professionalInsurance: {
        title: 'Professional Insurance',
        path: 'Organization / Professional Insurance',
        form: {
          title: 'Insurance policy',
          subtitle:
            'You or your company must be insured to be in good standing with the regulatory obligations set forth by the Order and the entire profession.',
          insuranceCompany: {
            label: 'Insurance company *',
            placeholder: 'Insurance company ',
          },
          contractNumber: {
            label: 'Contract number *',
            placeholder: 'Contract number',
          },
          partnership: {
            title: 'benefit from special conditions',
            subtitle: 'Personalized, free and confidential assessment with Xavier Guisnet',
          },
        },
      },
    },
    ibanForm: {
      title: 'IBAN Registration',
      titleCMV: 'IBAN Registration (1/2 - Callmyvet)',
      titleStripe: 'IBAN Registration (2/2 - Stripe)',
      btnSave: 'Save to Callmyvet',
      btnSaveStripe: 'Save to Stripe',
      ibanModalLabelCreate: 'Register IBAN number and BIC/SWIFT number',
      ibanModalLabelEdit: 'Edit IBAN number and BIC/SWIFT number',
      subTitleStripe: 'Please enter your IBAN again in order to validate and confirm the creation of your Stripe Connect account. This action will allow you to receive the amount of your consultations.',
      subtitle:
        'By providing your IBAN and confirming this payment method, you authorize Call My Vet SAS and Stripe, our payment service provider, to send instructions to your bank to debit your account in accordance with these instructions. You are entitled to a refund from your bank according to the terms and conditions of your agreement with your bank. A refund must be requested within 8 weeks from the date your account was debited.',
      form: {
        name: {
          label: 'Name of Account Holder *',
          placeholder: 'Name of Account Holder ',
        },
        ibanNumber: {
          label: 'IBAN number *',
          valid: 'Ce champ est requis',
        },
        bicNumber: {
          label: 'BIC number',
          placeholder: 'BIC / SWIFT number',
        },
      },
      popup: {
        valid: '✅ Your IBAN has been registered by Stripe',
        errorLeftButton: 'Contact customer service',
        error:
          '⚠️ Your IBAN is wrong, please check your entry. If the problem persists, please contact our teams.',
      },
    },
    account: {
      title: 'Your account',
      subtitle: 'Information related to your business',
      form: {
        button: {
          cancel: 'Cancel',
          save: 'Enregistrer  les modifications',
        },
        nameSection: {
          title: 'First name',
          subtitle:
            'To receive calls, click the button on the right to turn it green.',
          label: 'First name',
          LinkModifyName: 'Modify your name and surname',
          modal: {
            title: 'Modify your name and surname',
            subtitle:
              'Etiam porta sem malesuada magna mollis euismod. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
            firstName: {
              label: 'Name *',
              placeholder: 'Name',
            },
            lastName: {
              label: 'First name *',
              placeholder: 'First name',
            },
          },
        },
        emailSection: {
          title: 'Account email',
          subtitle: "Your account is linked to your email, you can't change your email.",
          label: 'Account email',
          LinkModifyEmail: 'Change email',
          modal: {
            title: 'Change account email',
            subtitle:
              'Etiam porta sem malesuada magna mollis euismod. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
            oldEmail: {
              label: 'Old email',
              placeholder: '',
            },
            newEmail: {
              label: 'New email *',
              placeholder: 'Email',
            },
            cancel: 'Cancel',
          },
        },
        passwordSection: {
          title: 'Account password',
          subtitle: "Secure access to your account with a password.",
          label: 'Account password',
          LinkModifyPassword: 'Change Password',
        },
      },
    },
    resetPasswordForm: {
      title: 'Reset Password',
      subtitle: 'Create a new password',
      form: {
        fields: {
          email: {
            label: 'Email',
            placeholder: 'Email',
          },
          password: {
            label: 'Enter a password',
            placeholder: 'Enter password',
          },
          passwordConfirmation: {
            label: 'Confirm password',
            placeholder: 'Your password',
          },
        },
        errors: {
          setPasswordFailed: "An error has occurred, your password has not been created",
          missingParams: "An error occurred, the link is not valid",
          // passwordTooShort: 'Password must contain at leat 8 characters',
        },
        indication: 'In order to access your account you must reset your password.',
        submit: 'Send',
      },
      link: {
        login: 'Continue',
      },
    },
    resetPasswordError: {
      title: 'Your application has expired or is invalid',
      subtitle:
        'In order to ensure the security of your account, we invite you to renew your request for a password change.',
      backToLogin: 'Back to homepage',
    },
    resetPasswordLoader: {
      title: 'Please wait',
      subtitle: 'Verify the integrity of your request....',
    },
    resetPasswordSuccess: {
      title: 'Password reset',
      subtitle:
        "Your password has been changed. You will be redirected to the home page. Redirection in 5 seconds...",
    },
    profile: {
      title: 'Profile: {{firstName}} {{lastName}}',
      subtitle: 'Personal and Veterinary Profile',
      form: {
        button: {
          cancel: 'Cancel',
          save: 'Save changes',
        },
      },
      personalProfile: {
        title: 'Personal Profile Information',
        subtitle:
          "Information related to your personal profile and your access rights within organizations.",
        name: {
          title: 'Last Name, First Name',
          subtitle: 'Profile / {{firstName}} {{lastName}} / first and last name',
          form: {
            title: 'Your name and surname',
            subtitle: '',
            firstName: {
              label: 'Last name *',
              placeholder: 'Last name',
            },
            lastName: {
              label: 'First name *',
              placeholder: 'First name',
            },
          },
        },
        rights: {
          title: 'Role, access and rights',
        },
      },
      veterinarianProfile: {
        title: 'Information of the veterinary profile',
        subtitle:
          'You must fill in all the information below to receive calls.',
        proofOfIdentity: {
          title: 'Proof of identity',
          subtitle: 'Profile / {{firstName}} {{lastName}} / Proof of identity',
          form: {
            title: 'Proof of identity',
            subtitle:
              'In order to authenticate yourself with Stripe and the order we need an ID. You can use an ID card (front + back) or a passport (front only). Document in color. Accepted formats: PNG, JPEG. Make a clean photo. Constraints: No PDF, No black and white.',
            nationality: {
              label: 'Nationality *',
              placeholder: 'Nationality',
            },
            birthDate: {
              label: 'Date of birth *',
              placeholder: 'DD/MM/YYYY',
            },
            idCardFront: {
              label: 'Identity card / Passport (Front)*',
              placeholder: 'Import a file',
            },
            idCardBack: {
              label: 'Identity card (Back)',
              placeholder: 'Import a file',
            },
          },
        },
        profileMedical: {
          title: 'Medical Profile',
          subtitle: 'Profile / {{firstName}} {{lastName}} / Medical Profile',
          form: {
            title: 'Medical profile',
            subtitle:
              'Our teams regularly check the validity of the ordinal numbers provided by all veterinarians. It is for us a pledge of seriousness and transparency.',
            ordinalNumber: {
              label: 'Ordinal number *',
              placeholder: 'Ordinal number',
            },
            registrationDepartment: {
              label: 'Department of your registration',
              placeholder: 'Select a department',
            },
            school: {
              label: 'Your school *',
              placeholder: 'Select your school',
            },
            otherSchool: {
              placeholder: "Name of the school",
            },
            diplomaGraduationYear: {
              label: 'Graduation year *',
              placeholder: 'Graduation year *',
            },
            diplomaIsValidInFrance: {
              label: 'Diploma valid in France *',
              placeholder: 'I certify that I have a valid diploma in France',
            },
          },
        },
        animalsInCare: {
          title: 'Animals taken in charge',
          subtitle: 'Profile / {{firstName}} {{lastName}} / Animals taken care of',
          form: {
            title: 'Animals taken in charge',
            subtitle: 'Select at least 1 animal species',
          },
        },
        skills: {
          title: 'Skills',
          subtitle: 'Profile / {{firstName}} {{lastName}} /Skills',
          form: {
            title: 'Preferred skills',
            subtitle: 'Select at least 1 skill.',
          },
        },
        insurance: {
          title: 'Insurance',
          subtitle: 'Profile / {{firstName}} {{lastName}} / Insurance',
          form: {
            title: "Insurance policy",
            subtitle:
              'You must have professional insurance in order to answer calls on the Callmyvet platform.',
            insuredByCompany: {
              label: 'Insured by the company?',
              placeholder: "The company's insurance covers the veterinarian who registers.",
            },
            insuranceCompany: {
              label: 'Insurance company *',
              placeholder: 'Insurance company',
            },
            contractNumber: {
              label: 'Contract Number *',
              placeholder: 'Contract Number',
            },
            customerNumber: {
              label: 'Client/Insured Number',
              placeholder: 'Client/Insured Number',
            },
          },
        },
      },
    },
    teams: {
      title: 'Team',
      subtitle: 'Organization / {{companyName}} / Team',
      role: {
        admin: 'Admin',
        veterinarian: 'Veterinarian',
      },
      veterinarianModerationStatus: {
        notCompleted: 'Incomplete profile',
        pendingVerification: 'Verification',
        verified: 'Profile verified',
      },
      companyAccountsModerationStatus: {
        pendingVeterinarian: 'Invitation sent',
        pendingCompany: 'Moderate the invitation',
      },
      members: {
        title: 'Team members',
        subtitle:
          'Complete your profile or invite a new veterinarian to receive calls.',
      },
      invitation: {
        title: 'Invite a veterinarian',
        subtitle:
          "Enter the email address of the veterinarian you wish to invite to your organization.",
        form: {
          email: {
            placeholder: 'Veterinarian’s email',
          },
          button: {
            submit: 'Send',
          },
        },
        pendingVetModal: {
          header: {
            title: 'The veterinarian has not yet responded to the invitation',
          },
          content:
            'The invitation has been sent, the veterinarian has not yet accepted or has not yet created an account.',
          footer: {
            cancelInvitation: 'Cancel invitation',
            backButton: 'Close window',
          },
        },
        pendingCompanyModal: {
          header: {
            title: 'Invitation requested',
          },
          content:
            'A veterinarian that you have not invited is requesting access to your company via email.',
          footer: {
            declineInvitation: 'Decline',
            acceptInvitation: 'Accept',
          },
        },
        deleteVeterinarianModal: {
          header: {
            title: 'Delete the member',
          },
          content:
            'You will cut off {{firstName}} {{lastName}} from the service. His/her data is saved in the database for all calls made. \Are you sure you want to delete the relationship?',
          footer: {
            backButton: 'Cancel',
            delete: 'Delete',
          },
        },
      },
    },
    videoCalls: {
      title: 'Video calls',
      subtitle: 'Information related to your activity',
      firstCallDate: 'You received your first call on {{firstCallDate}}',
      noCall: "You have not yet made a video call",
      currentWeek: {
        title: 'Video calls this week',
        see: 'See',
      },
      all: {
        title: 'Video calls',
        see: 'View calls',
      },
      detailsCallView: {
        subtitle: 'Details Video calls',
        commitmentQuality: {
          title: '❇️ Quality commitment',
        },
        observation: {
          title: '✍🏻 Observations',
        },
      },
      annualCallsView: {
        title: 'Year {{year}}',
        subtitle: 'Information related to your activity',
        annualCallsListTitle: 'List of calls by month',
        monthlyCallsListTitle: 'List of calls received',
      },
    },
    clients: {
      title: 'Clients list',
      subtitle: 'Clients of your clinic',
      allClientsBtn: 'All clients',
      pendingClientsBtn: 'Awaiting approval ({{pending_count}})',
      approveBtn: 'Approve',
      rejectBtn: 'Reject',
      rejectedState: 'Rejected',
      approvedState: 'Approved',
      totalClientsCounter: 'Total {{counter}} clients'
    },
    organisationConsultationPrices: {
        mainTitle: 'Consultation Setup',
        route: 'organization#Consultation-Rates',
        title: 'CONSULTATION RATES',
        needHelpWatch: 'Need help? Watch the',
        needHelpLink: 'video tutorial',
        needHelpText: 'to learn how to set up your consultation prices',
        needHelpNotAdmin : "The data on this page has been configured by your company administrator. If you want to make changes, please contact him/her.",
        catLabel: 'Cat',
        dogLabel: 'Dog',
        unusualLabel: 'Unusual',
        horseLabel: 'Horse',
        generalLabel: 'General',
        behaviorLabel: 'Behavior',
        ophtalmoLabel: 'Ophthalmology',
        teethLabel: 'Teeth',
        surgeonLabel: 'Surgeon',
        applyAll: 'Apply this price to all',
        eveningText: 'EVENING: Multiply the price by x1.5, in the evening (20:00 - 22:00)',
        nightText: 'NIGHT: Multiply the price by x2, at night (22:00 - 08:00)',
        weekendText: 'WEEKEND: Multiply the price by x2, on weekends (Fri. 20:00 - Mon. 08:00)',
        saveBtn: 'Save',
        cancelBtn: 'Cancel',
        linkText: 'Consultation Rates',
        hoursTitle: 'SPECIFIC HOURS/DAYS',
        tableTitle: 'RATE MANAGEMENT',
        errorTextSave : 'Please fill in all missing prices before saving.',
        defaultPricesWarning: 'The displayed prices are set as defaults by Callmyvet. If you wish to change the amounts, you can customize them with your own prices',
        defaultPricesTooltip: 'This is a default price set by CMV'
      },
    mainMenu: {
      overview: 'General view',
      calls: 'Calls',
      profile: 'Profile',
      team: 'Team',
      organization: 'Organization',
      account: 'Account'
    },
    organisationAddress: {
      path: 'Organization / Address',
      title: 'Address',
      headerTitle: 'Society address',
      headerSubtitle: 'Enter the address of the company headquarters',
      mainAdress: 'Address Line 1 *',
      mainPlaceholder: 'Address Line 1',
      secondAdress: 'Address Line 2',
      secondPlaceholderAdress: 'Address Line 2',
      city: 'City *',
      cityPlaceholder: 'City',
      zipCode: 'Postal code *',
      zipCodePlaceholder: 'Postal code',
    },
    companyLegalStatus: {
      CL: 'Freelance Collaborator',
      EI: 'Sole Proprietorship (Operates under own name)',
      EIRL: 'Individual Limited Liability Company',
      EURL: 'Single-member Limited Liability Company',
      SA: 'Public Limited Company (PLC)',
      SARL: 'Limited Liability Public Limited Company',
      SAS: 'Simplified Joint Stock Company',
      SASU: 'Single-member Simplified Joint Stock Company',
      SC: 'Civil Society',
      SCP: 'Professional Civil Society',
      SCRL: 'Cooperative Limited Liability Company',
      SELARL: 'Professional Limited Liability Company',
      SLP: 'Free Partnership Company',
      SNC: 'General Partnership',
      SPFPL: 'Financial Holdings of Liberal Professions',
      SPFPL_VRL: 'Veterinary Financial Holdings of Liberal Professions with Limited Liability',
      SPFPL_SAS: 'SAS Formed Financial Holdings of Liberal Professions',
      SPFPL_RL: 'Financial Holdings of Liberal Professions with Limited Liability',
      SE: 'European Company',
      SDE: 'Foreign Law Company',
      I: 'Independent',
      AS: 'Associated in Company',
      GE: 'Establishment Grouping',
    },
    profileMedical: {
      alfort: 'National Veterinary School of Alfort',
      toulouse: 'National Veterinary School of Toulouse',
      atlantique: 'National Veterinary School of Nantes Atlantique',
      lyon: 'National Veterinary School of Lyon',
      rouen: 'UniLaSalle Rouen Veterinary School',
      other: 'Other'
    },
    moderationStatusWarning : {
      missingVerification: 'Mandatory information is missing for your profile/company validation. Please complete them in order to be able to carry out your first consultations.',
      pendingVerification: 'Your profile is currently being validated. This may take up to 24 hours. A confirmation email will be sent to you so that you can carry out your first consultation.'
    }
  };