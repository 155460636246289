import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './ModalCmv.container';
import { Modal } from 'react-bootstrap';
import { Header } from './Header';
import { Footer } from './Footer';
import { ImageCmv } from '../../components/ImageCmv';
import { Loader } from '../../components/Loader';
import { ReactComponent as CmvLogoSvg } from '../../theme/icons/CmvProLogoBlueTransparent.svg';
// import images from '../../theme/images';

export class ModalCmv extends Component<Props> {
  render() {
    const headerProps = (({
      headerTitle,
      headerLabelLeft,
      headerLabelRight,
      headerIconLeft,
      headerIconRight,
      headerOnClickLeft,
      headerOnClickRight,
    }) => ({
      headerTitle,
      headerLabelLeft,
      headerLabelRight,
      headerIconLeft,
      headerIconRight,
      headerOnClickLeft,
      headerOnClickRight,
    }))(this.props);

    const {
      contentViewMainTitle,
      contentViewPreTitle,
      contentViewSubTitle,
      contentViewShowCmvLogo,
      contentViewImage,
      contentViewImageRetina,
      contentViewBodyContent,
      contentViewShowLoader,
      contentViewCustomComponent,
    } = this.props;

    const footerProps = (({
      footerMainButton,
      footerSecondaryButton,
      footerSubTitle,
      footerHidden,
      footerCustomComponent,
    }) => ({
      footerMainButton,
      footerSecondaryButton,
      footerSubTitle,
      footerHidden,
      footerCustomComponent,
    }))(this.props);

    return (
      <Container show={this.props.show} onHide={() => this.props.onClickOutsideCloseModal()}>
        <Header {...headerProps} />
        <ContentViewContainer>
          {/* <ImageBackgroundContainer> */}
          {/* <ImageCmv
              source={images.BackgroundPatternWhite}
              sourceRetina={images.BackgroundPatternWhite2x}
            /> */}
          {/* </ImageBackgroundContainer> */}
          <ScrollContainer>
            <ContentView>
              {contentViewPreTitle && <PreTitle>{contentViewPreTitle}</PreTitle>}
              {contentViewShowCmvLogo && (
                <CmvLogoContainer>
                  <CmvLogo />
                </CmvLogoContainer>
              )}
              {contentViewMainTitle && <MainTitle>{contentViewMainTitle}</MainTitle>}
              {contentViewSubTitle && <SubTitle>{contentViewSubTitle}</SubTitle>}
              {contentViewImage && (
                <ImageCmvContainer>
                  <ImageCmv source={contentViewImage} sourceRetina={contentViewImageRetina} />
                </ImageCmvContainer>
              )}
              {contentViewShowLoader && (
                <LoaderContainer>
                  <Loader inline="centered" active={true} size={'big'} />
                </LoaderContainer>
              )}
              {contentViewBodyContent && <BodyContent>{contentViewBodyContent}</BodyContent>}
              <ContentViewBody>{contentViewCustomComponent}</ContentViewBody>
            </ContentView>
          </ScrollContainer>
        </ContentViewContainer>
        <Footer {...footerProps} />
      </Container>
    );
  }
}

const Container = styled(Modal)`
  background-color: ${(props) => `${props.theme.colors.black.lighter}99 !important`};

  .modal-content {
    border-radius: 20px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    .modal-dialog {
      margin: 15px !important;
      min-width: calc(320px - 40px);
    }
  }

  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMin}) and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    .modal-dialog {
      margin: 20px auto !important;
      max-width: 80%;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) and (max-width: ${(
      props
    ) => props.theme.breakpoints.tabletMax}) {
    display: flex !important;
    align-items: center !important;
    .modal-dialog {
      max-width: none !important;
      width: 640px !important;
    }
  }

  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.laptop13Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    display: flex !important;
    align-items: center !important;
    .modal-dialog {
      max-width: none !important;
      width: 720px !important;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop15Min}) {
    display: flex !important;
    align-items: center !important;
    .modal-dialog {
      max-width: none !important;
      width: 800px !important;
    }
  }
`;

const ContentViewContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) and (max-width: ${(
      props
    ) => props.theme.breakpoints.tabletMax}) {
    height: 480px;
    /* overflow: auto; */
  }

  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.laptop13Min}) and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    height: 540px;
    /* overflow: auto; */
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop15Min}) {
    height: 640px;
    /* overflow: auto; */
  }
`;

const ScrollContainer = styled.div`
  margin: auto;
  max-height: 100%;
  overflow: auto;
  width: 100%;

  /* background: url('./BackgroundPatternWhite@2x.png') no-repeat top left;
  background-size: 200px 200px; */
`;

const ContentView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 20px;
  }

  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMin}) and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    padding: 25px;
  }
`;

const PreTitle = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontSize};
  color: ${(props) => props.theme.colors.grey.darker};
  text-transform: uppercase;
  margin: 10px 0;
  text-align: center;
  word-break: break-word;
`;

const MainTitle = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.heading.h1.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  color: ${(props) => props.theme.colors.black.light};
  margin: 10px 0;
  text-align: center;
  word-break: break-word;
`;

const SubTitle = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.heading.h5.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h5.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h5.fontSize};
  color: ${(props) => props.theme.colors.black.light};
  margin: 10px 0;
  text-align: center;
  white-space: pre-wrap;
  line-height: 24px;
  word-break: break-word;
`;

const BodyContent = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.heading.h6.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h6.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h6.fontSize};
  color: ${(props) => props.theme.colors.black.light};
  margin: 10px 0;
  text-align: center;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 60px;
    padding-right: 60px;
  }
  white-space: pre-wrap;
  word-break: break-word;
`;

// const ImageBackgroundContainer = styled.div`
//   /* @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { */
//   /* background: url('./BackgroundPatternWhite@2x.png') no-repeat top left;
//   background-size: 200px 200px; */
//   /* } */
//   /* position: absolute; */
//   /* top: 0; */
//   /* left: 0; */
//   /* width: 100%; */
//   /* img {
//     object-fit: contain;
//   } */
//   /* background-repeat: repeat; */
// `;

const ImageCmvContainer = styled.div`
  width: 250px;
  height: 200px;
  margin: 15px 0;
`;

const CmvLogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

const CmvLogo = styled(CmvLogoSvg)`
  width: 280px;
  height: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    width: 240px;
  }
`;

const LoaderContainer = styled.div`
  margin: 15px 0;
`;

const ContentViewBody = styled.div`
  width: 100%;
`;
