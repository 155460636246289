import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './ImageCmv.container';

export class ImageCmv extends Component<Props> {
  render() {
    const { source, sourceRetina } = this.props;
    return (
      <>
        {sourceRetina && (
          <picture>
            <source srcSet={`${source} 1x, ${sourceRetina} 2x`} />
            <Image src={`${source} 1x`} />
          </picture>
        )}
        {!sourceRetina && <Image src={source} />}
      </>
    );
  }
}

const Image = styled.img`
  height: 100%;
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    max-width: ${(props) => props.theme.breakpoints.mobilePortraitMin};
  }
`;
