import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import { environment } from './environment';

//Init Sentry
Sentry.init({
  environment: environment.APP_ENV,
  dsn: 'https://901262394041412ab114d328b76b35ea@o393368.ingest.sentry.io/5244285',
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
