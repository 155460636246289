import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../../lib/withApolloClient';
import compose from 'recompose/compose';
import { PicturesList as PicturesListComponent } from './PicturesList.component';

interface ContainerProps {
  videoCallId: number;
  petName: string;
}

export type Props = WithApolloClient<ContainerProps>;

export const PicturesList = compose<Props, ContainerProps>(withApollo)(PicturesListComponent);
