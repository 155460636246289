import React, { Component } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { ThemeComponents } from '../../../theme/components';
import { Props } from './DoubleBoxForm.container';

export class DoubleBoxForm extends Component<Props> {
  public render() {
    const {
      label,
      sublabel,
      direction,
      contentFirstBox,
      contentSecondBox,
      onClickSecondBox,
    } = this.props;

    if (direction === 'column') {
      return (
        <DoubleBoxColumnContainer>
          <DoubleBoxLabelContainer>
            <DoubleBoxLabel>{label}</DoubleBoxLabel>
            <DoubleBoxSubLabel>{sublabel}</DoubleBoxSubLabel>
          </DoubleBoxLabelContainer>
          <DoubleBoxInput disabled placeholder={contentFirstBox} />
          {contentSecondBox && (
            <DoubleBoxButton onClick={onClickSecondBox}>{contentSecondBox}</DoubleBoxButton>
          )}
        </DoubleBoxColumnContainer>
      );
    } else {
      return (
        <DoubleBoxAutoContainer fluid>
          <DoubleBoxRow noGutters>
            <Col xs={12} sm={4}>
              <DoubleBoxLabelContainer>
                <DoubleBoxLabel>{label}</DoubleBoxLabel>
                <DoubleBoxSubLabel>{sublabel}</DoubleBoxSubLabel>
              </DoubleBoxLabelContainer>
            </Col>
            <Col xs={12} sm={8}>
              <DoubleBoxInput disabled value={contentFirstBox} />
              {contentSecondBox && (
                <DoubleBoxButton
                  disabled={onClickSecondBox ? false : true}
                  onClick={onClickSecondBox}
                >
                  {contentSecondBox}
                </DoubleBoxButton>
              )}
            </Col>
          </DoubleBoxRow>
        </DoubleBoxAutoContainer>
      );
    }
  }
}

const DoubleBoxAutoContainer = styled(Container)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 16px 0;
  padding: 0;
`;

const DoubleBoxRow = styled(Row)`
  width: 100%;
`;

const DoubleBoxInput = styled(ThemeComponents.FormInput)`
  text-align: center;
  color: ${(props) => props.theme.colors.black.lighter} !important;
`;

const DoubleBoxButton = styled<{ disabled: boolean }>(ThemeComponents.FormSimpleButton)`
  border-color: ${(props) =>
    props.disabled ? props.theme.colors.grey.lighter : props.theme.colors.black.lighter};
  color: ${(props) =>
    props.disabled ? props.theme.colors.grey.lighter : props.theme.colors.black.lighter} !important;
  margin-top: 10px;
  padding: 12px 15px;
`;

const DoubleBoxLabel = styled(ThemeComponents.FormLabel)``;

const DoubleBoxSubLabel = styled(ThemeComponents.FormSubLabel)``;

const DoubleBoxLabelContainer = styled.div`
  margin: 5px 15px 5px 0;
`;

const DoubleBoxColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  position: relative;
`;
