import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../../lib/withApolloClient';
import { CreateAccountVerification as CreateAccountVerificationComponent } from './CreateAccountVerification.component';
import { RouteComponentProps } from 'react-router-dom';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';

type ContainerProps = RouteComponentProps<
  any,
  any,
  {
    oobCode: string;
    mode: string;
  }
>;

export type Props = WithApolloClient<RouteComponentProps & ContainerProps>;

export const CreateAccountVerification = compose<Props, ContainerProps>(
  withApollo,
  withRouter
)(CreateAccountVerificationComponent);
