import React, { Component } from 'react';
import { Props } from './Account.container';
import styled from 'styled-components';
import { NavBarMaster } from '../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../components/UiNavigation/SubNavBar';
import { MasterBody } from '../../components/Layout/MasterBody';
import { MasterContainer } from '../../components/Layout/MasterContainer';
import { MasterSideMenu } from '../../components/Layout/MasterSideMenu';
import { MainMenuConnected } from '../../components/UiNavigation/MainMenuMaster/MainMenuConnected';
import { HeaderSectionForm } from '../../components/Form/HeaderSectionForm';
import { i18n } from '../../lib/i18n';
import { DoubleBoxForm } from '../../components/Form';
import { appContext } from '../../context/appContext';
import { AccountEmailForm } from './AccountEmailForm';
import { ModalCmv } from '../../components/ModalCmv';
import {
  AccountEmailSentConfirmationBody,
  AccountEmailSentConfirmationFooter,
} from '../../components/AccountEmailSentConfirmation';
import { loader } from 'graphql.macro';
import images from '../../theme/images';
import { ReactComponent as IconCrossDelete } from '../../theme/icons/IconCrossDelete.svg';

const firebaseAccountPasswordResetRequest = loader(
  '../../graphql/firebaseAccountPasswordResetRequest.graphql'
);
// const updateAccountConnectionEmail = loader('../../graphql/updateAccountConnectionEmail.graphql');

interface State {
  // firstName: string;
  // lastName: string;
  email: string;
  password: string;
  showAccountEmailFormModal: boolean;
  showAccountNameFormModal: boolean;
  showAccountEmailSentConfirmationModal: boolean;
  showResetPasswordEmailSentConfirmationModal: boolean;
}

export class Account extends Component<Props, State> {
  public state: State = {
    // firstName: '',
    // lastName: '',
    email: '',
    password: '',
    showAccountEmailFormModal: false,
    showAccountNameFormModal: false,
    showAccountEmailSentConfirmationModal: false,
    showResetPasswordEmailSentConfirmationModal: false,
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public componentDidMount() {
    const contextValue = this.context;
    const currentAccount = contextValue.currentAccount;
    // const currentVet = contextValue.currentVet;
    //TODO password in context
    this.setState({
      // firstName: currentAccount.firstName,
      // lastName: currentAccount.lastName,
      email: currentAccount?.email ? currentAccount?.email : '',
    });
  }

  // public onValidateNameForm = (firstName: string, lastName: string) => {
  //   this.setState({
  //     firstName,
  //     lastName,
  //     showAccountNameFormModal: false,
  //   });
  // };

  public onValidateEmailForm = async (email: string) => {
    // // this.setState(
    // //   {
    // //     email,
    // //   },
    // //   () => {
    // //     this.setState({
    // //       showAccountEmailSentConfirmationModal: true,
    // //       showAccountEmailFormModal: false,
    // //     });
    // //   }
    // // );
    // let response;
    // try {
    //   response = await this.props.client.mutate({
    //     mutation: updateAccountConnectionEmail,
    //     variables: {
    //       email: email,
    //     },
    //   });
    //   this.setState({ showAccountEmailSentConfirmationModal: true });
    //   // TEMP alert box so we have a feedback during test
    // } catch (error) {
    //   console.log('query failed: ', error);
    // }
  };

  public sendResetPasswordRequest = async () => {
    const email = this.context.currentAccount?.email ? this.context.currentAccount.email : '';
    let response;
    try {
      response = await this.props.client.query({
        query: firebaseAccountPasswordResetRequest,
        variables: {
          email: email,
        },
        fetchPolicy: 'network-only',
      });
      if (response.data.firebaseAccountPasswordResetRequest !== 'valid') {
        // this.formikRef.setFieldError(
        //   'email',
        //   'Votre compte a atteint la limite de demande, veuillez patienter avant de réessayer'
        // );
        return;
      }
      this.setState({ showResetPasswordEmailSentConfirmationModal: true });
      window.localStorage.setItem('emailForReset', email);
      // TEMP alert box so we have a feedback during test
    } catch (error) {
      console.log('query failed: ', error);
    }
  };

  render() {
    const company = this.context.currentCompany;
    return (
      <>
        <NavBarMaster />
        <SubNavBar moderationStatus={company?.moderationStatus}/>
        <MasterBody>
          <MasterSideMenu>
            <MainMenuConnected />
          </MasterSideMenu>
          <MasterContainer>
            <AccountContainer>
              <AccountHeaderContainer>
                <AccountSubtitle>{i18n.t('account.subtitle')}</AccountSubtitle>
                <AccountTitle>{i18n.t('account.title')}</AccountTitle>
              </AccountHeaderContainer>

              <AccountSectionContainer>
                <HeaderSectionForm
                  title={i18n.t('account.form.emailSection.title')}
                  subtitle={i18n.t('account.form.emailSection.subtitle')}
                />
                <DoubleBoxForm
                  label={i18n.t('account.form.emailSection.label')}
                  contentFirstBox={`${this.state.email}`} // read context
                  // contentSecondBox={i18n.t('account.form.emailSection.LinkModifyEmail')}
                  // onClickSecondBox={undefined}
                />
              </AccountSectionContainer>
              <AccountSectionContainer>
                <HeaderSectionForm
                  title={i18n.t('account.form.passwordSection.title')}
                  subtitle={i18n.t('account.form.passwordSection.subtitle')}
                />
                <DoubleBoxForm
                  label={i18n.t('account.form.passwordSection.label')}
                  contentFirstBox={'********'} // read context
                  contentSecondBox={i18n.t('account.form.passwordSection.LinkModifyPassword')}
                  onClickSecondBox={() => this.sendResetPasswordRequest()}
                />
              </AccountSectionContainer>

              {/* Modal email form */}
              <ModalCmv
                show={this.state.showAccountEmailFormModal}
                onClickOutsideCloseModal={() => null}
                headerTitle={i18n.t('account.form.emailSection.modal.title')}
                headerIconLeft={IconCrossDelete}
                headerOnClickLeft={() => this.setState({ showAccountEmailFormModal: false })}
                headerLabelLeft={i18n.t('account.form.emailSection.modal.cancel')}
                contentViewImage={images.HugoSecurityService2}
                contentViewImageRetina={images.HugoSecurityService22x}
                contentViewCustomComponent={
                  <AccountEmailForm
                    email={this.state.email}
                    onValidate={(email: string) => this.onValidateEmailForm(email)}
                  />
                }
              ></ModalCmv>
              {/* Modal  email sent */}
              <ModalCmv
                show={this.state.showAccountEmailSentConfirmationModal}
                onClickOutsideCloseModal={() => null}
                headerIconLeft={IconCrossDelete}
                headerTitle={i18n.t('createAccount.confirmation.headerTitle')}
                headerOnClickLeft={() =>
                  this.setState({ showAccountEmailSentConfirmationModal: false })
                }
                contentViewSubTitle={i18n.t('createAccount.confirmation.subtitle')}
                contentViewImage={images.ModaleIllustrationEmailSent}
                contentViewImageRetina={images.ModaleIllustrationEmailSent2x}
                contentViewCustomComponent={
                  <AccountEmailSentConfirmationBody email={this.state.email} />
                }
                footerCustomComponent={<AccountEmailSentConfirmationFooter />}
              ></ModalCmv>
              {/* Modal  reset password email sent */}
              <ModalCmv
                show={this.state.showResetPasswordEmailSentConfirmationModal}
                onClickOutsideCloseModal={() => null}
                headerIconLeft={IconCrossDelete}
                headerOnClickLeft={() =>
                  this.setState({ showResetPasswordEmailSentConfirmationModal: false })
                }
                contentViewPreTitle={i18n.t('resetPasswordRequestEmailSentConfirmation.title')}
                contentViewImage={images.HugoNoMessage}
                contentViewImageRetina={images.HugoNoMessage2x}
                contentViewBodyContent={i18n.t(
                  'resetPasswordRequestEmailSentConfirmation.subtitle'
                )}
                footerHidden={true}
              />
            </AccountContainer>
          </MasterContainer>
        </MasterBody>
      </>
    );
  }
}

const AccountContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.pure};
`;

const AccountHeaderContainer = styled.div`
  padding: 50px 30px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 50px 10px;
  }
`;

const AccountSubtitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const AccountTitle = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.heading.h1.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
`;

const AccountSectionContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 30px 10px;
  }
`;
