import { FunctionComponent } from 'react';
import { SideMenu as SideMenuComponent } from './SideMenu.component';
import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../../lib/withApolloClient';
import { RouteComponentProps } from 'react-router-dom';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';

export type MainMenuProps = {
  title: string;
  icon: FunctionComponent;
  onClick: () => void;
  selected: boolean;
  path: string;
  endOfSection?: boolean;
};

export type ContainerProps = unknown;

export type Props = WithApolloClient<RouteComponentProps & ContainerProps>;

export const SideMenu = compose<Props, ContainerProps>(withApollo, withRouter)(SideMenuComponent);
