import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './ProfileMenu.container';
import { ProfileMenuConnected } from './ProfileMenuConnected';
import { ProfileMenuNotConnected } from './ProfileMenuNotConnected';

export class ProfileMenu extends Component<Props> {
  public render() {
    const { isConnected } = this.props;
    return (
      <ProfileMenuContainer>
        {isConnected && <ProfileMenuConnected />}
        {!isConnected && <ProfileMenuNotConnected />}
      </ProfileMenuContainer>
    );
  }
}

const ProfileMenuContainer = styled.div`
  height: 100%;
`;
