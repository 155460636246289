import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './NavBarMobile.container';
import { ReactComponent as IconHamburger } from '../../../../theme/icons/IconHamburger.svg';
import { ReactComponent as IconProfile } from '../../../../theme/icons/IconProfile.svg';
import { ReactComponent as CmvLogo } from '../../../../theme/icons/CmvProLogoBlueTransparent.svg';
import { Modal } from 'react-bootstrap';
import { MainMenu } from '../../MainMenuMaster';
import { ProfileMenu } from '../../ProfileMenuMaster';
import { theme } from '../../../../theme';
import { i18n } from '../../../../lib/i18n';

export class NavBarMobile extends Component<Props> {
  public state = {
    showLeftMenu: false,
    showRightMenu: false,
  };

  public componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  public updateDimensions = () => {
    if (window.innerWidth >= 992) {
      this.setState({ showLeftMenu: false, showRightMenu: false });
    }
  };

  public render() {
    const { isConnected } = this.props;
    return (
      <NavBarMobileContainer>
        <NavBarMobileIconHamburger
          onClick={() => this.setState({ showLeftMenu: true })}
          iconcolor={isConnected ? theme.colors.black.light : theme.colors.white.pure}
          cursor={isConnected ? 'pointer' : 'auto'}
        />
        <NavBarModal
          show={isConnected ? this.state.showLeftMenu : false}
          onHide={() => this.setState({ showLeftMenu: false })}
        >
          <Modal.Header translate={undefined} closeButton style={{ alignItems: 'center' }}>
            <Modal.Title>{i18n.t('navBar.modal.mainMenu.title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MainMenu isConnected={isConnected} />
          </Modal.Body>
        </NavBarModal>
        <CmvLogo width={180} height={'100%'} />

        <NavBarMobileIconProfile onClick={() => this.setState({ showRightMenu: true })} />
        <NavBarModal
          show={this.state.showRightMenu}
          onHide={() => this.setState({ showRightMenu: false })}
        >
          <Modal.Header translate={undefined} closeButton style={{ alignItems: 'center' }}>
            <Modal.Title>{i18n.t('navBar.modal.profileMenu.title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProfileMenu isConnected={isConnected} />
          </Modal.Body>
        </NavBarModal>
      </NavBarMobileContainer>
    );
  }
}

const NavBarMobileContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    display: none;
  }
`;

const NavBarMobileIconHamburger = styled(IconHamburger)<{ iconcolor: string; cursor: string }>`
  cursor: ${(props) => props.cursor};

  & > * {
    stroke: ${(props) => props.iconcolor};
  }
`;

const NavBarMobileIconProfile = styled(IconProfile)`
  cursor: pointer;
`;

const NavBarModal = styled(Modal)`
  & > .modal-dialog {
    width: 100% !important;
    max-width: none !important;
    margin: 0;
  }
`;
