import React, { Component } from 'react';
import { Separator } from '../Separator';
import styled from 'styled-components';
import { Typo } from '../../theme/typo';

interface Props {
  text: string;
  closePopup: () => void;
  leftOnClick?: () => void;
  leftText?: string;
}

export class PopUp extends Component<Props> {
  render() {
    return (
      <div className="popup" style={{ minWidth: '320px' }}>
        <div className="popup_inner">
          <PopUpContainer>
            <StyledText>{this.props.text}</StyledText>
            <BottomContent>
              <Separator />
              <ButtonContainer>
                {this.props.leftOnClick && this.props.leftText && (
                  <LeftButtonContainer>
                    <StyledButton onClick={this.props.leftOnClick}>
                      {this.props.leftText}
                    </StyledButton>
                  </LeftButtonContainer>
                )}
                <RightButtonContainer>
                  <StyledButton onClick={this.props.closePopup}>OK</StyledButton>
                </RightButtonContainer>
              </ButtonContainer>
            </BottomContent>
          </PopUpContainer>
        </div>
      </div>
    );
  }
}

const StyledText = styled(Typo.SmallText)`
  color: ${(props) => props.theme.colors.primary.mainColor};
  text-align: center;
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: 0;
`;

const RightButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LeftButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const PopUpContainer = styled.div`
  border-radius: 20px;
  width: 100%;
  height: 100%;
  padding: 20px 10px;
  overflow-y: auto;

  display: flex;
  align-items: center;
  flex-direction: column;
`;
