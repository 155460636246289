import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../lib/withApolloClient';
import { Account as AccountComponent } from './Account.component';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
import { withAppContext } from '../../lib/withContext';

export type Props = WithApolloClient<RouteComponentProps>;

// export const Account = withApollo(AccountComponent);
export const Account = compose<Props, RouteComponentProps>(
  withApollo,
  withAppContext
)(AccountComponent);
