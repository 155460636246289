import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../../lib/withApolloClient';
import { DetailsCallView as DetailsCallViewComponent } from './DetailsCallView.component';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
import { withAppContext } from '../../../lib/withContext';

type ContainerProps = {
  videoCallId: string;
};

export type Props = WithApolloClient<RouteComponentProps & ContainerProps>;

export const DetailsCallView = compose<Props, ContainerProps>(
  withApollo,
  withAppContext
)(DetailsCallViewComponent);
