import React, { Component } from 'react';
import styled from 'styled-components';
// import { FieldProps, FormikProps } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ThemeComponents } from '../../../theme/components';
import { Props } from './DatePickerForm.container';
import { ReactComponent as IconCalendar } from '../../../theme/icons/IconCalendar.svg';

export class DatePickerForm extends Component<Props> {
  // public handleDatePicker(event: React.MouseEvent<HTMLButtonElement>) {
  //   console.log(this.props.id);
  //   event.preventDefault();
  //   const datePickerInput: HTMLInputElement = document.getElementById(
  //     `${this.props.id}`
  //   ) as HTMLInputElement;
  //   if (datePickerInput) {
  //     datePickerInput.click();
  //   }
  // }

  public render() {
    const { form, label, sublabel, placeholder, field, required } = this.props;
    const error = form.errors[field.name];
    const touched = form.touched[field.name];
    console.log(form);
    console.log(field.value);
    // let date;
    // if (field.value && field.value.length >= 10) {
    //   field.value = new Date(field.value).toLocaleDateString('fr-FR', {
    //     year: 'numeric',
    //     month: '2-digit',
    //     day: '2-digit',
    //   });
    //   date = new Date(field.value);
    // }
    // if (field.value && field.value.length >= 10 && date && !date.getTime()) {
    //   const dateParts = field.value.split('/');

    //   // // month is 0-based, that's why we need dataParts[1] - 1
    //   date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    //   console.log(date);
    // }
    return (
      <DatePickerContainer fluid>
        <DatePickerRow noGutters>
          <Col xs={12} sm={4}>
            <DatePickerLabelContainer>
              <DatePickerLabel>{label}</DatePickerLabel>
              <DatePickerSubLabel>{sublabel}</DatePickerSubLabel>
            </DatePickerLabelContainer>
          </Col>
          <Col xs={12} sm={8}>
            <DatePickerButton
              {...field}
              placeholderText={placeholder && required ? `${placeholder + ' *'}` : placeholder}
              selected={field.value || null}
              onChange={(val: string) => {
                this.props.form.setFieldValue(field.name, val ? val : undefined);
              }}
              error={error}
              touched={touched}
              dateFormat={'dd/MM/yyyy'}
              // scrollableYearDropdown
            />
            <DatePickerIcon />
            {touched && error && <DatePickerError>{error}</DatePickerError>}
          </Col>
        </DatePickerRow>
      </DatePickerContainer>
    );
  }
}

const DatePickerContainer = styled(Container)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 16px 0;
  padding: 0;

  /* Overide DatePicker component style  */
  & {
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      display: block;
    }

    .react-datepicker-wrapper .react-datepicker__input-container input {
      width: 100%;
    }

    input {
      height: 44px;
      padding-left: 15px;
      padding-right: 45px;
      border: ${(props) => props.theme.componentObjects.formContainer.border};
      border-radius: ${(props) => props.theme.componentObjects.formContainer.borderRadius};
      box-sizing: ${(props) => props.theme.componentObjects.formContainer.borderBox};
      font-size: ${(props) => props.theme.componentObjects.formContainer.fontSize};
      font-weight: ${(props) => props.theme.componentObjects.formContainer.fontWeight};
      line-height: ${(props) => props.theme.componentObjects.formContainer.lineHeight};
      ::placeholder {
        color: ${(props) => props.theme.colors.grey.main};
      }
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
    }
  }
`;

const DatePickerRow = styled(Row)`
  width: 100%;
`;

const DatePickerLabelContainer = styled.div`
  margin: 5px 15px 5px 0;
`;

const DatePickerLabel = styled(ThemeComponents.FormLabel)``;

const DatePickerSubLabel = styled(ThemeComponents.FormSubLabel)``;

const DatePickerError = styled(ThemeComponents.FormErrorLabel)``;

const DatePickerButton = styled(DatePicker)<{ error: string; touched: boolean }>`
  :focus-within {
    outline: none !important;
    border: 1px solid ${(props) => props.theme.colors.black.light} !important;
  }
  border-radius: ${(props) => props.theme.componentObjects.formContainer.borderRadius};
  box-sizing: ${(props) => props.theme.componentObjects.formContainer.borderBox};
  border: ${(props) => {
    const colorType =
      !!props.error && props.touched
        ? props.theme.colors.red.dark
        : props.theme.colors.white.darker;
    return `1px solid ${colorType} !important`;
  }};
`;

const DatePickerIcon = styled(IconCalendar)`
  position: absolute;
  top: 10px;
  right: 15px;
`;
