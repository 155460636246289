import * as Yup from 'yup';

export const profileMedicalValidationSchema = Yup.object().shape({
  ordinalNumber: Yup.string().required('Ce champ est requis'),
  school: Yup.string().required('Ce champ est requis'),
  otherSchool: Yup.string().when('school', {
    is: (value) => value === 'other',
    then: Yup.string().required('Ce champ est requis'),
    otherwise: Yup.string(),
  }),
  diplomaGraduationYear: Yup.string().required('Ce champ est requis'),
  diplomaIsValidInFrance: Yup.boolean().oneOf([true], 'Ce champ est requis'),
});
