import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../../lib/withApolloClient';
import { OrganisationConsultationPrices as OrganisationConsultationPricesComponent } from './OrganisationConsultationPrices.component';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
import { withAppContext } from '../../../lib/withContext';

export type Props = WithApolloClient<RouteComponentProps>;

export const OrganisationConsultationPrices = compose<Props, RouteComponentProps>(
  withApollo,
  withAppContext
)(OrganisationConsultationPricesComponent);
