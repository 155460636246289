import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../../lib/withApolloClient';
import { ProfileAnimalsInCare as ProfileAnimalsInCareComponent } from './ProfileAnimalsInCare.component';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
import { withAppContext } from '../../../lib/withContext';

export type Props = WithApolloClient<RouteComponentProps>;

export const ProfileAnimalsInCare = compose<Props, RouteComponentProps>(
  withApollo,
  withAppContext
)(ProfileAnimalsInCareComponent);
