import { CustomFlatList as CustomFlatListComponent } from './CustomFlatList.component';
import { FunctionComponent } from 'react';

export type StatusProps =
  | 'IN_REVIEW'
  | 'REQUIRED_FIELD_FILLED'
  | 'VALIDATED'
  | 'VERIFIED'
  | 'WARNING'
  | 'CRITICAL_WARNING';

export type ItemsProps = {
  leftLabel?: string;
  rightLabel?: string;
  leftLabelColor?: string;
  leftSubLabel?: string;
  leftSubLabelColor?: string;
  rightLabelColor?: string;
  rightIcon?: FunctionComponent;
  status?: StatusProps;
  disabled?: boolean;
  // statusIcon?: FunctionComponent;
  onClick?: () => void;
  backgroundColor?: string;
};

export type Props = {
  data?: Array<ItemsProps>;
  // renderItem: FunctionComponent;
};

export const CustomFlatList = CustomFlatListComponent;
