import React, { Component } from 'react';
import styled from 'styled-components';
import { Props, MainMenuProps } from './MainMenuConnected.container';
import { ReactComponent as IconDisclosureRight } from '../../../../theme/icons/Icon-DisclosureRight-24x24.svg';
import { ReactComponent as IconDashboardEnabled } from '../../../../theme/icons/IconTabBar-Dashboard-Enabled-30x26.svg';
import { ReactComponent as IconDashboardHighlighted } from '../../../../theme/icons/IconTabBar-Dashboard-Highlighted-30x26.svg';
import { ReactComponent as IconSettingsEnabled } from '../../../../theme/icons/IconTabBar-Settings-Enabled-30x26.svg';
import { ReactComponent as IconSettingsHighlighted } from '../../../../theme/icons/IconTabBar-Settings-Highlighted-30x26.svg';
// import { ReactComponent as IconPresciptionsEnabled } from '../../../../theme/icons/IconTabBar-Prescriptions-Enabled-30x26.svg';
// import { ReactComponent as IconPresciptionsHighlighted } from '../../../../theme/icons/IconTabBar-Prescriptions-Highlighted-30x26.svg';
import { ReactComponent as IconProfileEnabled } from '../../../../theme/icons/IconTabBar-Profile-Enabled-30x26.svg';
import { ReactComponent as IconProfileHighlighted } from '../../../../theme/icons/IconTabBar-Profile-Highlighted-30x26.svg';
import { ReactComponent as IconTeamEnabled } from '../../../../theme/icons/IconTabBar-Team-Enabled-30x26.svg';
import { ReactComponent as IconTeamHighlighted } from '../../../../theme/icons/IconTabBar-Team-Highlighted-30x26.svg';
import { ReactComponent as IconOrganisationEnabled } from '../../../../theme/icons/IconTabBar-Organisation-Enabled-30x26.svg';
import { ReactComponent as IconOrganisationHighlighted } from '../../../../theme/icons/IconTabBar-Organisation-Highlighted-30x26.svg';
import { ReactComponent as IconCallsEnabled } from '../../../../theme/icons/IconTabBar-Calls-Enabled-30x26.svg';
import { ReactComponent as IconCallsHighlighted } from '../../../../theme/icons/IconTabBar-Calls-Highlighted-30x26.svg';
import { i18n } from '../../../../lib/i18n';
import { appContext } from '../../../../context/appContext';
import './MainMenuConnected.styles.css';
import { Loader } from '../../../../components/Loader';
import { loader } from 'graphql.macro';
const getFareByCompany = loader('../../../../graphql/getFareByCompany.graphql');

interface State {
  indexSelected: number | null;
  mainMenuItems: Array<MainMenuProps>;
  organization: {
    addressCompleted: boolean,
    bankCompleted: boolean,
    contactCompleted: boolean,
    insuranceCompleted: boolean,
    legalCompleted: boolean,
    vatCompleted: boolean,
    stripeCompleted: boolean,
    faresCompleted: boolean,
  };
  profile: {
    identityCompleted: boolean,
    insuranceCompleted: boolean,
    medicalExpertiseCompleted: boolean,
    petCategoriesCompleted: boolean,
    medicalProfileCompleted: boolean,
  };
  hash: string,
  organizationModerationStatus: boolean,
  profileModerationStatus: boolean,
  isCurrentVet: boolean,
  //stripeCompleted: boolean,
  //faresByCompany: boolean,
  loading?: boolean;
}

export class MainMenuConnected extends Component<Props, State> {
  public state: State = {
    indexSelected: null,
    mainMenuItems: [
      {
        title: i18n.t('mainMenu.overview'),
        endOfSection: true,
        onClick: () => this.props.history.push('/dashboard'),
        iconSelected: IconDashboardHighlighted,
        iconNotSelected: IconDashboardEnabled,
        selected: false,
        path: '/dashboard',
      },
      {
        title: i18n.t('mainMenu.calls'),
        onClick: () => this.props.history.push('/video-calls'),
        iconSelected: IconCallsHighlighted,
        iconNotSelected: IconCallsEnabled,
        selected: false,
        path: '/video-calls',
      },
      // {
      //   title: 'Clients',
      //   onClick: () => this.props.history.push('/clients'),
      //   iconSelected: IconClients,
      //   iconNotSelected: IconClients,
      //   selected: false,
      //   path: '/clients',
      // },
      // {
      //   title: 'Facturation',
      //   onClick: () => null,
      //   iconSelected: IconPresciptionsHighlighted,
      //   iconNotSelected: IconPresciptionsEnabled,
      //   selected: false,
      //   path: '/facturation',
      // },
      {
        title: i18n.t('mainMenu.profile'),
        onClick: () => this.props.history.push('/profile'),
        iconSelected: IconProfileHighlighted,
        iconNotSelected: IconProfileEnabled,
        selected: false,
        path: '/profile',
      },
      {
        title: i18n.t('mainMenu.team'),
        onClick: () => this.props.history.push('/teams'),
        iconSelected: IconTeamHighlighted,
        iconNotSelected: IconTeamEnabled,
        selected: false,
        path: '/teams',
      },
      {
        title: i18n.t('mainMenu.organization'),
        onClick: () => this.props.history.push('/organisation'), //TODO replace with dynamic companyName
        iconSelected: IconOrganisationHighlighted,
        iconNotSelected: IconOrganisationEnabled,
        selected: false,
        path: '/organisation',
      },
      {
        title: i18n.t('mainMenu.account'),
        onClick: () => this.props.history.push('/account'),
        iconSelected: IconSettingsHighlighted,
        iconNotSelected: IconSettingsEnabled,
        selected: false,
        path: '/account',
      },
    ],
    organization: {
      addressCompleted: false,
      bankCompleted: false,
      contactCompleted: false,
      insuranceCompleted: false,
      legalCompleted: false,
      vatCompleted: false,
      stripeCompleted: false,
      faresCompleted: false,
    },
    profile: {
      identityCompleted: false,
      insuranceCompleted: false,
      medicalExpertiseCompleted: false,
      petCategoriesCompleted: false,
      medicalProfileCompleted: false,
    },
    hash: '',
    organizationModerationStatus: false,
    profileModerationStatus: false,
    isCurrentVet: false,

    loading: true,
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public async componentDidMount  () {
    this.setState({ hash: this.props.location.hash });
    await this.context.updateContext();
    let faresByCompanyLength = 0;
    try {
      const response = await this.props.client.query({
        query: getFareByCompany,
        fetchPolicy: 'network-only',
      });
      /* if(response.data.getTareByCompany?.fare.length === 0) {
        this.setState({faresByCompany: false});
        } else {
          this.setState({faresByCompany: true});
        } */
        faresByCompanyLength = response.data.getTareByCompany?.fare.length
    } catch (error) {
      console.log(error);
    }
    const contextValue = this.context;
    const currentCompany = contextValue.currentCompany;
    const currentVet =  contextValue.currentVet;
    const faresByCompany = faresByCompanyLength > 0 ? true : false;

    if(currentVet) {
      this.setState({isCurrentVet: true});
    }
    /* if(currentCompany?.stripeCompleted) {
      this.setState({stripeCompleted: true});
    } else {
      this.setState({stripeCompleted: false});
    } */
    if(currentCompany?.moderationStatus === 'NOT_COMPLETED' || currentCompany?.moderationStatus === 'PENDING_VERIFICATION') {
      this.setState({organizationModerationStatus: false});
    } else {
      this.setState({organizationModerationStatus: true});
    }

    if(currentVet?.moderationStatus === 'PENDING_VERIFICATION' || currentVet?.moderationStatus === 'NOT_COMPLETED' || !currentVet) {
      this.setState({profileModerationStatus: false});
    } else {
      this.setState({profileModerationStatus: true});
    }
    
    this.setState((prevState) => ({
      organization: {
        ...prevState.organization,
        addressCompleted: currentCompany?.addressCompleted ?? false,
        bankCompleted: currentCompany?.bankCompleted ?? false,
        contactCompleted: currentCompany?.contactCompleted ?? false,
        insuranceCompleted: currentCompany?.insuranceCompleted ?? false,
        legalCompleted: currentCompany?.legalCompleted ?? false,
        vatCompleted: currentCompany?.vatCompleted ?? false,
        stripeCompleted: currentCompany?.stripeCompleted ?? false,
        faresCompleted: faresByCompany,
      },
      profile: {
        ...prevState.profile,
        identityCompleted: currentVet?.identityCompleted ?? false,
        insuranceCompleted: currentVet?.insuranceCompleted ?? false,
        medicalExpertiseCompleted: currentVet?.medicalExpertiseCompleted ?? false,
        petCategoriesCompleted: currentVet?.petCategoriesCompleted ?? false,
        medicalProfileCompleted: currentVet?.medicalProfileCompleted ?? false,
      },
    }));
    const pathname = this.props.history.location.pathname;
    const index = this.state.mainMenuItems.map((e) => e.path).indexOf(pathname.toLowerCase());
    if (index > -1) {
      this.setState({ indexSelected: index });
    }
    this.setState({loading: false});
  }

  public handleCompletedForms = (item: any) => {
    if(item.path === '/organisation') {
      let object_length = Object.keys(this.state.organization).length - 1;
      let trueCount = 0;
      Object.entries(this.state.organization).forEach(([key, value]) => {
        if(value === true && key !== 'bankCompleted' && key !== 'stripeCompleted') {  
          trueCount++;
        }
      });
      if(this.state.organization['bankCompleted'] && this.state.organization['stripeCompleted']) {
        trueCount++;
      }
      if(!this.state.organizationModerationStatus) {
        return this.state.loading ?  <Loader className='loader-container' inline="centered" active={true} size={'small'} /> : <div className={`main-menu-connected__label-completed ${object_length === trueCount ? 'green' : 'red'}` }>{object_length === trueCount ? <IconCheck>{String.fromCharCode(10004)}</IconCheck> : <IconWarning >!</IconWarning> } {`${trueCount} / ${object_length }`}</div>
      } else {
        return <div className={`main-menu-connected__label-completed`} />
      } 
    }

    if(item.path === '/profile') {
      let object_length = Object.keys(this.state.profile).length;
      let object_boolean = 0;
      Object.entries(this.state.profile).forEach(([key, value]) => {
        if(value === true) {
          object_boolean += 1
        }
      });
      if(!this.state.profileModerationStatus){
        return this.state.loading ? <Loader className='loader-container' inline="centered" active={true} size={'small'} /> : <div className={`main-menu-connected__label-completed ${object_length === object_boolean ? 'green' : 'red'}` }>{object_length === object_boolean ? <IconCheck>{String.fromCharCode(10004)}</IconCheck> : <IconWarning >!</IconWarning> } {this.state.isCurrentVet ? `${object_boolean} / ${object_length}` : '0 / 5'}</div>
      } else {
        return <div className={`main-menu-connected__label-completed`} />
      }
    }
  }

  public render() {
    return (
      <MainMenuConnectedContainer>
        {this.state.mainMenuItems.map((item, i) => {
          const IconItemSelected = styled(item.iconSelected)``;
          const IconItemNotSelected = styled(item.iconNotSelected)``;
          return (
            <MainMenuConnectedItem
              key={i}
              endOfSection={item.endOfSection}
              onClick={() => {
                this.setState({ indexSelected: i });
                item.onClick();
              }}
              selected={this.state.indexSelected === i}
            >
              <SideMenuNotConnectedItemlLeft>
                {this.state.indexSelected === i ? <IconItemSelected /> : <IconItemNotSelected />}
                <MainMenuConnectedItemTitle><MainMenuConnectedH1>{item.title}</MainMenuConnectedH1> {this.handleCompletedForms(item)}</MainMenuConnectedItemTitle>
              </SideMenuNotConnectedItemlLeft>
              <MainMenuConnectedItemRight>
                <IconDisclosureRight />
              </MainMenuConnectedItemRight>
            </MainMenuConnectedItem>
          );
        })}
      </MainMenuConnectedContainer>
    );
  }
}

const MainMenuConnectedContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.darker};
  padding-top: 20px;
`;

const MainMenuConnectedItem = styled.div<{ selected: boolean; endOfSection?: boolean }>`
  width: 100%;
  padding: 10px 20px;
  border: none;
  /* background: ${(props) => props.theme.colors.white.dark}; */
  box-shadow: inset 0px -1px 0px rgba(23, 23, 23, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  margin-bottom: ${(props) => (props.endOfSection ? '20px' : 0)};
  color: ${(props) =>
    props.selected ? props.theme.colors.white.pure : props.theme.colors.black.pure};
  background: ${(props) =>
    props.selected ? props.theme.colors.primary.mainColor : props.theme.colors.white.dark};

  path {
    fill: ${(props) =>
      props.selected ? props.theme.colors.white.pure : props.theme.colors.black.light};
    color: ${(props) =>
      props.selected ? props.theme.colors.white.pure : props.theme.colors.black.light};
  }
  /* :focus {
    outline: none;
    color: ${(props) => props.theme.colors.white.pure};
    & > * {
      stroke: ${(props) => props.theme.colors.white.pure};
    }
  } */
`;

const SideMenuNotConnectedItemlLeft = styled.div`
  display: flex;
  align-items: center;
  width: 100%
`;

const MainMenuConnectedItemTitle = styled.div`
  margin-left: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const MainMenuConnectedItemRight = styled.div``;

const MainMenuConnectedH1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconCheck = styled.span`
  margin-right: 4px;
`;

const IconWarning = styled.span`
    margin-right: 4px;
    border-radius: 50%;
    width: 4px;
    font-size: 14px;
    font-weight: bolder;
    color: white;
  `;
