import React, { Component } from 'react';
import { Props } from './Organisation.container';
import styled from 'styled-components';
import { NavBarMaster } from '../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../components/UiNavigation/SubNavBar';
import { MasterBody } from '../../components/Layout/MasterBody';
import { MasterContainer } from '../../components/Layout/MasterContainer';
import { MasterSideMenu } from '../../components/Layout/MasterSideMenu';
import { MainMenuConnected } from '../../components/UiNavigation/MainMenuMaster/MainMenuConnected';
import { HeaderSectionForm } from '../../components/Form/HeaderSectionForm';
import { i18n } from '../../lib/i18n';
import { CustomFlatList } from '../../components/CustomFlatList';
import { ReactComponent as IconChevronRight24x24 } from '../../theme/icons/Icon-ChevronRight-24x24.svg';
import { OrganisationVat } from './OrganisationVat';
import { OrganisationProfessionalInsurance } from './OrganisationProfessionalInsurance';
import { OrganisationBankingInformation } from './OrganisationBankingInformation';
import { OrganisationContact } from './OrganisationContact';
import { OrganisationAddress } from './OrganisationAddress';
import { OrganisationLegalInformation } from './OrganisationLegalInformation';
import { OrganisationConsultationPrices } from './OrganisationConsultationPrices';
import { Account, Company, Veterinarian } from '../../types/DbInterface';
import { appContext } from '../../context/appContext';
import { theme } from '../../theme';
//import { client } from '../../graphql/client';
import { loader } from 'graphql.macro';
const getFareByCompany = loader('../../graphql/getFareByCompany.graphql');

//const getProfessionalAccountWithRelations = loader('../../graphql/getProfessionalAccountWithRelations.graphql');

export interface State {
  __test__companyName: string;
  hash: string;
  currentCompany?: Company;
  faresByCompany?: boolean;
}

export class Organisation extends Component<Props, State> {
  public state: State = {
    __test__companyName: 'Ventura Corp',
    hash: '',
    currentCompany: undefined,
    faresByCompany: false,
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  // public getParams() {
  //   const searchParams = new URLSearchParams(this.props.location.search);
  //   return {
  //     hash: searchParams.get('hash') || '',
  //   };
  // }

  /* public async getProfessionalAccountWithRelations() {
    try {
      const response = await client.query<{
        getProfessionalAccountWithRelations?: {
          account: Account;
          veterinarian?: Veterinarian;
          company?: Company;
          companyVeterinarians?: Veterinarian[];
        };
      }>({
        query: getProfessionalAccountWithRelations,
        fetchPolicy: 'network-only',
      });
      this.setState({
        currentCompany: response.data.getProfessionalAccountWithRelations?.company, 
      });
    } catch (error) {
      console.log(error);
    }
  } */

  public async componentDidMount() {
    this.setState({ hash: this.props.location.hash });
    this.context.updateContext();
    try {
      const response = await this.props.client.query({
        query: getFareByCompany,
        fetchPolicy: 'network-only',
      });
      if(response.data.getTareByCompany?.fare.length === 0) {
        this.setState({faresByCompany: false});
      } else {
        this.setState({faresByCompany: true});
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.location !== prevProps.location) {
      this.setState({ hash: this.props.location.hash });
      await this.context.updateContext();
      try {
        const response = await this.props.client.query({
          query: getFareByCompany,
          fetchPolicy: 'network-only',
        });
        if(response.data.getTareByCompany?.fare.length === 0) {
          this.setState({faresByCompany: false});
        } else {
          this.setState({faresByCompany: true});
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  public getCompanyModerationState(moderationStatus?: string, stripeCompleted?: boolean) {
    switch (moderationStatus) {
      case 'NOT_COMPLETED':
        return 'WARNING';
      case 'PENDING_VERIFICATION':
        if(this.state.faresByCompany && stripeCompleted) {
          return 'IN_REVIEW'
        } else {
          return 'WARNING'
        };
      case 'VERIFIED':
        return 'VERIFIED';
      default:
        return 'WARNING';
    }
  }

  renderDefault() {
    const company = this.context.currentCompany;
    return (
      <>
        <NavBarMaster />
        <SubNavBar moderationStatus={company?.moderationStatus} />
        <MasterBody>
          <MasterSideMenu>
            <MainMenuConnected />
          </MasterSideMenu>
          <MasterContainer>
            <OrganisationContainer>
              <OrganisationHeaderContainer>
                <OrganisationPath>Organisation / {company?.name}</OrganisationPath>
                <OrganisationName>{company?.name}</OrganisationName>
              </OrganisationHeaderContainer>

              <OrganisationSectionContainer>
                <HeaderSectionForm
                  title={i18n.t('dashboard.companyStatus.title', {
                    companyName: company?.name ? company?.name : '',
                  })}
                  subtitle={i18n.t('dashboard.companyStatus.subtitle')}
                  status={this.getCompanyModerationState(company?.moderationStatus, company?.stripeCompleted)}
                />
                <CustomFlatListContainer>
                  <CustomFlatList
                    data={[
                      {
                        leftLabel: `${i18n.t('dashboard.companyStatus.legalInformation')}`,
                        rightIcon: IconChevronRight24x24,
                        status: company?.legalCompleted ? 'REQUIRED_FIELD_FILLED' : 'WARNING',
                        onClick: () => this.props.history.push('/organisation#legal-information'),
                      },
                      {
                        leftLabel: `${i18n.t('dashboard.companyStatus.vat')}`,
                        rightIcon: IconChevronRight24x24,
                        status: company?.vatCompleted ? 'REQUIRED_FIELD_FILLED' : 'WARNING',
                        // onClick: () => this.props.history.push('/organisation?hash=vat'),
                        onClick: () => this.props.history.push('/organisation#vat'),
                      },
                      {
                        leftLabel: `${i18n.t('dashboard.companyStatus.address')}`,
                        rightIcon: IconChevronRight24x24,
                        status: company?.addressCompleted ? 'REQUIRED_FIELD_FILLED' : 'WARNING',
                        onClick: () => this.props.history.push('/organisation#address'),
                      },
                      {
                        leftLabel: `${i18n.t('dashboard.companyStatus.contact')}`,
                        rightIcon: IconChevronRight24x24,
                        status: company?.contactCompleted ? 'REQUIRED_FIELD_FILLED' : 'WARNING',
                        onClick: () => this.props.history.push('/organisation#contact'),
                      },
                      {
                        leftLabel: `${i18n.t('dashboard.companyStatus.bankingInformation')}`,
                        rightIcon: IconChevronRight24x24,
                        status:
                          company?.bankCompleted && company?.stripeCompleted
                            ? 'REQUIRED_FIELD_FILLED'
                            : 'WARNING',
                        leftLabelColor: this.context.getIsCompanyAdminByAccountId(
                          this.context.currentAccount?.id
                        )
                          ? undefined
                          : theme.colors.grey.main,
                        disabled: this.context.getIsCompanyAdminByAccountId(
                          this.context.currentAccount?.id
                        )
                          ? false
                          : true,
                        onClick: () => this.props.history.push('/organisation#banking-information'),
                      },
                      {
                        leftLabel: `${i18n.t('dashboard.companyStatus.professionalInsurance')}`,
                        rightIcon: IconChevronRight24x24,
                        status: company?.insuranceCompleted ? 'REQUIRED_FIELD_FILLED' : 'WARNING',
                        onClick: () =>
                          this.props.history.push('/organisation#professional-insurance'),
                      },
                    ]}
                  />
                </CustomFlatListContainer>
              </OrganisationSectionContainer>
              <OrganisationSectionContainer>
                <HeaderSectionForm
                  title={i18n.t('organisationConsultationPrices.mainTitle')}
                  status={ this.state.faresByCompany ? 'REQUIRED_FIELD_FILLED' : 'WARNING' }
                />
                <CustomFlatListContainer>
                  <CustomFlatList
                    data={[
                      {
                        leftLabel: `${i18n.t('organisationConsultationPrices.linkText')}`,
                        rightIcon: IconChevronRight24x24,
                        status: this.state.faresByCompany ? 'REQUIRED_FIELD_FILLED' : 'WARNING',
                        onClick: () =>
                          this.props.history.push('/organisation#consultation-prices'),
                      },
                    ]}
                  />
                </CustomFlatListContainer>
              </OrganisationSectionContainer>
            </OrganisationContainer>
          </MasterContainer>
        </MasterBody>
      </>
    );
  }

  render() {
    switch (this.state.hash) {
      case '#legal-information':
        return <OrganisationLegalInformation {...this.props} />;
      case '#vat':
        return <OrganisationVat {...this.props} />;
      case '#address':
        return <OrganisationAddress {...this.props} />;
      case '#contact':
        return <OrganisationContact {...this.props} />;
      case '#banking-information':
        return <OrganisationBankingInformation {...this.props} />;
      case '#professional-insurance':
        return <OrganisationProfessionalInsurance {...this.props} />;
      case '#consultation-prices':
        return <OrganisationConsultationPrices {...this.props} />
      default:
        return this.renderDefault();
    }
  }
}

const OrganisationContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.pure};
`;

const OrganisationHeaderContainer = styled.div`
  padding: 50px 30px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 50px 10px;
  }
`;

const OrganisationPath = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const OrganisationName = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.heading.h1.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
`;

const OrganisationSectionContainer = styled.div`
  padding: 0 30px 30px 30px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 0px 10px 30px 10px;
  }
`;

const CustomFlatListContainer = styled.div`
  margin-top: 20px;
`;
