import React, { Component } from 'react';
import { Props } from './OrganisationBankingInformation.container';
import styled from 'styled-components';
import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { MasterBody } from '../../../components/Layout/MasterBody';
import { MasterContainer } from '../../../components/Layout/MasterContainer';
import { HeaderSectionForm } from '../../../components/Form/HeaderSectionForm';
import { StripeIbanForm } from '../../../components/StripeIbanForm';
import { IbanForm } from '../../../components/IbanForm';
import { environment } from '../../../environment';
import { i18n } from '../../../lib/i18n';
import { Row, Col, Modal } from 'react-bootstrap';
import { Formik, Form } from 'formik';
// import { organisationBankingInformationValidationSchema } from './OrganisationBankingInformation.validation';
import { ThemeComponents } from '../../../theme/components';
import { ReactComponent as IconExternalLink24x24 } from '../../../theme/icons/Icon-ExternalLink-24x24.svg';
import { appContext } from '../../../context/appContext';
import { Loader } from '../../../components/Loader';
import { loader } from 'graphql.macro';
import { Elements } from '@stripe/react-stripe-js';
import { Account, Company, Veterinarian } from '../../../types/DbInterface';
import { ButtonsForm } from '../../../components/Form/ButtonsForm';
import { loadStripe } from '@stripe/stripe-js';

const getStripeCompanyOnboardingLink = loader(
  '../../../graphql/getStripeCompanyOnboardingLink.graphql'
);
const getStripeCompanyValidationInfo = loader(
  '../../../graphql/getStripeCompanyValidationInfo.graphql'
);

const stripePromise = loadStripe(environment.STRIPE_PUBLISHABLE_KEY);

interface StripeValidation {
  payout: boolean;
  charges: boolean;
  transfers: boolean;
  pending_transfers: boolean;
}

// interface ValuesForm {
//   nameAccountHolder: string;
//   ibanNumber: string;
//   bicNumber: string;
// }

export interface State {
  isLoading: boolean;
  currentVet?: Veterinarian;
  currentAccount: Account;
  currentCompany?: Company;
  stripeValidation: StripeValidation;
  stripeStatusLabel: string;
  stripeLinkLabel: string;
  stripeAccountIsValid: boolean;
  ibanStatusLabel: string;
  ibanLinkLabel: string;
  ibanIsValid: boolean;
  showIbanForm: boolean;
  showStripeIbanForm: boolean;
  ibanNumber: string;
  spinner?: boolean;
  isIbanUpdated?: boolean;
}

export class OrganisationBankingInformation extends Component<Props, State> {
  public state: State = {
    isLoading: true,
    currentVet: undefined,
    currentAccount: {},
    stripeValidation: {
      payout: true,
      charges: true,
      transfers: true,
      pending_transfers: false,
    },
    stripeAccountIsValid: false,
    stripeStatusLabel: '',
    stripeLinkLabel: '',
    ibanIsValid: false,
    ibanStatusLabel: '',
    ibanLinkLabel: '',
    showIbanForm: false,
    showStripeIbanForm: false,
    ibanNumber: '',
    spinner: false,
    isIbanUpdated: false,
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public async componentDidMount() {
    const contextValue = this.context;
    const currentAccount = contextValue.currentAccount;
    const currentVet = contextValue.currentVet;
    const currentCompany = contextValue.currentCompany;
    this.setState({ibanNumber: currentCompany?.ibanNumber ? `${currentCompany?.ibanNumber}` : '' });
    if (!contextValue.getIsCompanyAdminByAccountId(currentAccount?.id)) {
      this.props.history.goBack();
      return;
    }
    if (currentAccount && currentCompany) {
      await this.updateStripeInformation(currentCompany.id);
      this.setState({ currentVet, currentAccount, currentCompany, isLoading: false });
    } else this.setState({ isLoading: false });
  }

  public goToStripeOnboarding = async () => {
    const responseUrl = await this.props.client.query<{ getStripeCompanyOnboardingLink?: string }>({
      query: getStripeCompanyOnboardingLink,
      variables: { companyId: this.state.currentCompany?.id },
      fetchPolicy: 'network-only',
    });
    const stripeUrl = responseUrl.data.getStripeCompanyOnboardingLink;
    if (stripeUrl) {
      // console.log(stripeUrl);
      // TODO TEST IN STAGING ATLEAST,
      // popup is blocked on firefox and safari, chrome will just
      // open a new tab
      window.open(stripeUrl, '_blank', 'toolbar=0,location=0,menubar=0,modal=yes,alwaysRaised=yes'); //to open new page
    }
  };

  public formatString(code: string) {
    var formattedCode = code.slice(0, 4) + code.slice(4, -4).replace(/./g, "●") + code.slice(-4);
    return formattedCode;
  }

  public updateStripeInformation = async (companyId: string) => {
    const response = await this.props.client.query<{ getStripeCompanyValidationInfo?: boolean[] }>({
      query: getStripeCompanyValidationInfo,
      variables: { companyId: companyId },
      fetchPolicy: 'network-only',
    });
    // console.log('stripe validation = ', response.data.getStripeCompanyValidationInfo);
    const stripeValidationResult = response.data.getStripeCompanyValidationInfo;
    if (stripeValidationResult) {
      let newStripeStatusLabel = '';
      let newStripeLinkLabel = '';
      let newIbanStatusLabel = '';
      let newIbanLinkLabel = '';
      let stripeAccountIsValid = false;
      let ibanIsValid = false;

      if (!stripeValidationResult[1] || !stripeValidationResult[2]) {
        newStripeStatusLabel = `${i18n.t(
          'organisation.bankingInformation.form.stripe.status.noValidated'
        )}`;
        newStripeLinkLabel = `${i18n.t('organisation.bankingInformation.form.stripe.link.add')}`;
      } else if (
        stripeValidationResult[1] &&
        stripeValidationResult[2] &&
        !stripeValidationResult[3]
      ) {
        newStripeStatusLabel = `${i18n.t(
          'organisation.bankingInformation.form.stripe.status.validated'
        )}`;
        newStripeLinkLabel = `${i18n.t('organisation.bankingInformation.form.stripe.link.modify')}`;
        stripeAccountIsValid = true;
      } else if (
        stripeValidationResult[1] &&
        stripeValidationResult[2] &&
        stripeValidationResult[3]
      ) {
        newStripeStatusLabel = `${i18n.t(
          'organisation.bankingInformation.form.stripe.status.pending'
        )}`;
        newStripeLinkLabel = `${i18n.t('organisation.bankingInformation.form.stripe.link.modify')}`;
        stripeAccountIsValid = true;
      }
      if (stripeValidationResult[0]) {
        //newIbanStatusLabel = 'FR75 ●●●● ●●●● ●●●● 0000'; /* TODO replace with right value */
        newIbanStatusLabel = this.formatString(this.state?.ibanNumber);
        newIbanLinkLabel = `${i18n.t('organisation.bankingInformation.form.iban.link.modify')}`;
        ibanIsValid = true;
      } else if (!stripeValidationResult[0]) {
        newIbanStatusLabel = `${i18n.t('organisation.bankingInformation.form.iban.status.noIban')}`;
        newIbanLinkLabel = `${i18n.t('organisation.bankingInformation.form.iban.link.add')}`;
      }

      this.setState({
        stripeValidation: {
          payout: stripeValidationResult[0],
          charges: stripeValidationResult[1],
          transfers: stripeValidationResult[2],
          pending_transfers: stripeValidationResult[3],
        },
        stripeAccountIsValid,
        stripeStatusLabel: newStripeStatusLabel,
        stripeLinkLabel: newStripeLinkLabel,
        ibanIsValid,
        ibanStatusLabel: newIbanStatusLabel,
        ibanLinkLabel: newIbanLinkLabel,
      });
    }
  };

  private onSubmit = async () => {
    this.setState({spinner: true});
    this.context.updateContext();
    if (this.state.showStripeIbanForm) this.setState({ showStripeIbanForm: false });
    if (this.state.showIbanForm) this.setState({ showIbanForm: false });
    if (this.state.currentCompany) {
      await this.updateStripeInformation(this.state.currentCompany.id);
      this.setState({spinner: false});
      this.props.history.goBack();
    }
  };

  // private onSubmitIbanForm = async () => {
  //   console.log(values);
  //   this.setState({ showIbanForm: false });
  // };

  render() {
    return (
      <>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <MasterContainer noSideMenu={true}>
            <Container>
              <HeaderContainer>
                <Path>{i18n.t('organisation.bankingInformation.path')}</Path>
                <Title>{i18n.t('organisation.bankingInformation.title')}</Title>
              </HeaderContainer>
              {this.state.isLoading && (
                <LoaderContainer>
                  <Loader inline="centered" active={true} size={'big'} />;
                </LoaderContainer>
              )}
              {!this.state.isLoading && (
                <SectionContainer>
                  <Formik
                    initialValues={{}}
                    onSubmit={(values) => this.onSubmit()}
                    // validationSchema={organisationBankingInformationValidationSchema}
                  >
                    {(formikBag) => (
                      <OrganisationForm>
                        <OrganisationField>
                          <StripeContainer>
                            <HeaderSectionForm
                              title={i18n.t('organisation.bankingInformation.form.stripe.title')}
                              subtitle={i18n.t(
                                'organisation.bankingInformation.form.stripe.subtitle'
                              )}
                            />
                            <StripeField noGutters>
                              <Col xs={12} sm={4}>
                                <StripeLabelContainer>
                                  <StripeLabel>
                                    {i18n.t('organisation.bankingInformation.form.stripe.label')}
                                  </StripeLabel>
                                </StripeLabelContainer>
                              </Col>
                              <Col xs={12} sm={8}>
                                <StatusContainer isValid={this.state.stripeAccountIsValid}>
                                  <StatusLabel isValid={this.state.stripeAccountIsValid}>
                                    {this.state.stripeStatusLabel}
                                  </StatusLabel>
                                </StatusContainer>
                                <LinkContainer onClick={() => this.goToStripeOnboarding()}>
                                  <LinkLabel>{this.state.stripeLinkLabel}</LinkLabel>
                                  <LinkIcon />
                                </LinkContainer>
                              </Col>
                            </StripeField>
                            { 
                              <IbanField noGutters>
                                <Col xs={12} sm={4}>
                                  <IbanLabelContainer>
                                    <IbanLabel>
                                      {/* {i18n.t('organisation.bankingInformation.form.iban.label111')} */}
                                      { !this.state.ibanNumber 
                                        ?
                                        i18n.t('ibanForm.ibanModalLabelCreate')
                                        :
                                        i18n.t('ibanForm.ibanModalLabelEdit')
                                      }
                                    </IbanLabel>
                                  </IbanLabelContainer>
                                </Col>
                                <Col xs={12} sm={8}>
                                  <StatusContainer
                                    isValid={this.state.ibanNumber ? true : false}
                                  >
                                    <StatusLabel
                                      isValid={this.state.ibanNumber ? true : false}
                                    >
                                      {this.state.ibanNumber
                                        ? this.formatString(this.state.ibanNumber)
                                        : i18n.t(
                                            'organisation.bankingInformation.form.iban.status.noIban'
                                          )}
                                    </StatusLabel>
                                  </StatusContainer>
                                  <LinkContainer
                                    onClick={() => this.setState({ showIbanForm: true })}
                                  >
                                    <LinkLabel>
                                      { this.state.ibanNumber
                                        ? i18n.t(
                                            'organisation.bankingInformation.form.iban.link.modify'
                                          )
                                        : i18n.t(
                                            'organisation.bankingInformation.form.iban.link.add'
                                          )}
                                    </LinkLabel>
                                  </LinkContainer>
                                </Col>
                              </IbanField>
                            }
                            {/*  { 
                              <IbanField noGutters>
                                <Col xs={12} sm={4}>
                                  <IbanLabelContainer>
                                    <IbanLabel>
                                      {i18n.t('organisation.bankingInformation.form.iban.label')}
                                    </IbanLabel>
                                  </IbanLabelContainer>
                                </Col>
                                <Col xs={12} sm={8}>
                                  <StatusContainer isValid={this.state.ibanIsValid}>
                                    <StatusLabel isValid={this.state.ibanIsValid}>
                                      {this.state.ibanStatusLabel}
                                    </StatusLabel>
                                  </StatusContainer>
                                  <LinkContainer
                                    onClick={() => this.setState({ showStripeIbanForm: true })}
                                  >
                                    <LinkLabel>{this.state.ibanLinkLabel}</LinkLabel>
                                  </LinkContainer>
                                </Col>
                              </IbanField> 
                            } */}
                          </StripeContainer>
                        </OrganisationField>
                        <ButtonsForm
                          cancelContent={i18n.t('organisation.form.button.cancel')}
                          validateContent={i18n.t('organisation.form.button.save')}
                          onCancel={() => {
                            this.context.updateContext();
                            this.props.history.goBack();
                          }}
                          type={'submit'}
                          spinner={this.state.spinner}
                          disabled={this.state.spinner ? true : false}
                        />
                        <IbanModal
                          show={this.state.showStripeIbanForm}
                          onHide={() => null}
                          size="lg"
                          centered
                        >
                          <Elements stripe={stripePromise}>
                            <StripeIbanForm
                              {...this.props}
                              closeForm={() => {
                                if (this.state.currentCompany)
                                  this.updateStripeInformation(this.state.currentCompany.id);
                                this.setState({ showStripeIbanForm: false });
                              }}
                              currentVet={this.state.currentVet}
                              nationality={
                                this.state.currentCompany?.nationality
                                  ? this.state.currentCompany.nationality
                                  : ''
                              }
                              companyId={
                                this.state.currentCompany?.id ? this.state.currentCompany.id : ''
                              }
                              currentCompany={this.state.currentCompany}
                              handleIbanNumber={(ibanNumber: string) => this.setState({ibanNumber: ibanNumber})}
                              ibanNumber={this.state.ibanNumber}
                              onSuccess={() => {this.setState({ibanIsValid: true})}}
                            />
                          </Elements>
                        </IbanModal>
                        {/* <IbanModal
                          show={false}
                          onHide={() => null}
                          size="lg"
                          centered
                        >
                          <IbanForm
                            {...this.props}
                            closeForm={async (isCompleted?: boolean) => {
                              if (isCompleted) {
                                await this.context.updateContext();
                                this.setState({
                                  currentCompany: this.context.currentCompany,
                                  showIbanForm: false,
                                });
                              } else {
                                this.setState({ showIbanForm: false });
                              }
                            }}
                            currentVet={this.state.currentVet}
                            nationality={
                              this.state.currentCompany?.nationality
                                ? this.state.currentCompany.nationality
                                : ''
                            }
                            currentCompany={this.state.currentCompany}
                            handleIbanNumber={(ibanNumber: string) => {
                              this.context.updateContext();
                              this.setState({ibanNumber: ibanNumber}); 
                              this.setState({ showIbanForm: false });}
                            }
                            ibanNumber={this.state.ibanNumber ? this.state.ibanNumber : ''}
                            
                          />
                        </IbanModal> */}
                        <IbanModal
                          show={this.state.showIbanForm}
                          onHide={() => null}
                          size="lg"
                          centered
                          className='organisation-banking-info__iban-stripe'
                        >
                          <IbanForm
                            {...this.props}
                            closeForm={async (isCompleted?: boolean) => {
                              if (isCompleted) {
                                await this.context.updateContext();
                                this.setState({
                                  currentCompany: this.context.currentCompany,
                                  //showIbanForm: false,
                                });
                              } /* else {
                                this.setState({ showIbanForm: false });
                              } */
                            }}
                            currentVet={this.state.currentVet}
                            nationality={
                              this.state.currentCompany?.nationality
                                ? this.state.currentCompany.nationality
                                : ''
                            }
                            currentCompany={this.state.currentCompany}
                            handleIbanNumber={(ibanNumber: string) => {
                              this.context.updateContext();
                              this.setState({
                                ibanNumber: ibanNumber,
                                isIbanUpdated: true,
                              }); 
                              //this.setState({ showIbanForm: false });
                            }}
                            ibanNumber={this.state.ibanNumber ? this.state.ibanNumber : ''}
                            className='organisation-banking-info__iban'
                            isIbanUpdated={this.state.isIbanUpdated}
                          />
                          <Elements stripe={stripePromise}>
                            <StripeIbanForm
                              {...this.props}
                              closeForm={() => {
                                if (this.state.currentCompany)
                                  this.updateStripeInformation(this.state.currentCompany.id);
                                this.setState({ showIbanForm: false });
                              }}
                              currentVet={this.state.currentVet}
                              nationality={
                                this.state.currentCompany?.nationality
                                  ? this.state.currentCompany.nationality
                                  : ''
                              }
                              companyId={
                                this.state.currentCompany?.id ? this.state.currentCompany.id : ''
                              }
                              currentCompany={this.state.currentCompany}
                              handleIbanNumber={(ibanNumber: string) => this.setState({ibanNumber: ibanNumber})}
                              ibanNumber={this.state.ibanNumber}
                              onSuccess={() => {this.setState({ibanIsValid: true})}}
                              className='organisation-banking-info__stripe'
                              isIbanUpdated={this.state.isIbanUpdated}
                              handleStripeIbanSuccess={() => {
                                this.setState({
                                  isIbanUpdated: false,
                                }); 
                              }}
                            />
                          </Elements>
                        </IbanModal>
                      </OrganisationForm>
                    )}
                  </Formik>
                </SectionContainer>
              )}
            </Container>
          </MasterContainer>
        </MasterBody>
      </>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.pure};
  padding-left: 10px;
  padding-right: 10px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 200px;
    padding-right: 200px;
  }
`;

const HeaderContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;

const Path = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.body.small.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.body.small.fontSize};
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrganisationForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 35px;
`;

const OrganisationField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 0;
  }
`;

const StripeField = styled(Row)`
  margin-top: 20px;
`;

const IbanField = styled(Row)`
  margin-top: 20px;
`;

const StripeLabelContainer = styled.div`
  margin: 5px 15px 5px 0;
`;

const IbanLabelContainer = styled.div`
  margin: 5px 15px 5px 0;
`;

const StripeLabel = styled(ThemeComponents.FormLabel)``;

const IbanLabel = styled(ThemeComponents.FormLabel)``;

const StripeContainer = styled.div``;

const IbanContainer = styled.div`
  margin-top: 30px;
`;

const StatusContainer = styled.div<{ isValid: boolean }>`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.isValid ? props.theme.colors.white.pure : props.theme.colors.red.subtle};
  border: 1px solid
    ${(props) => (props.isValid ? props.theme.colors.white.darker : props.theme.colors.red.lighter)};
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) =>
    props.isValid ? props.theme.colors.black.lighter : props.theme.colors.red.dark};
`;

const StatusLabel = styled.div<{ isValid: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: ${(props) => (props.isValid ? `left` : `center`)};
  padding-left: 10px;
  padding-right: 10px;
`;

const LinkContainer = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.white.pure};
  border: 1px solid ${(props) => props.theme.colors.black.lighter};
  box-sizing: border-box;
  border-radius: 5px;
  color: ${(props) => props.theme.colors.black.lighter};
  padding: 8px 22px;
  margin-top: 10px;
  cursor: pointer;
`;

const LinkLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const LinkIcon = styled(IconExternalLink24x24)``;

const IbanModal = styled(Modal)`
  background-color: ${(props) => props.theme.colors.black.lighter} !important;

  .modal-dialog {
    min-width: ${(props) => props.theme.breakpoints.mobilePortraitMin} !important;
  }

  .modal-content {
    border-radius: 20px;
  }
`;

const LoaderContainer = styled.div`
  height: 70%;
  display: flex;
  align-items: center;
`;
