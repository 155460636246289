import React, { Component } from 'react';
import styled from 'styled-components';
// import { FieldProps, FormikProps } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import { ReactComponent as IconImport } from '../../../theme/icons/IconImport.svg';
import { ReactComponent as IconCrossDelete } from '../../../theme/icons/IconCrossDelete.svg';
import { ThemeComponents } from '../../../theme/components';
import { Props } from './ImportFileForm.container';

interface State {
  files: Array<{ name: string; file: File }>;
}

const MAX_NUMBER_FILES = 1;

export class ImportFileForm extends Component<Props, State> {
  public state = {
    files: [],
  };

  public handleClickImportFile(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    const importFileInput: HTMLInputElement = document.getElementById(
      `${this.props.id}`
    ) as HTMLInputElement;
    if (importFileInput && this.state.files.length < MAX_NUMBER_FILES) {
      importFileInput.click();
    }
  }

  public handleChangeImportFile(event: React.FormEvent<HTMLInputElement>) {
    const target = event.target as HTMLInputElement;
    // console.log(target.files);
    if (target.files && target.files.length > 0) {
      const file: { name: string; file: File } = {
        name: (target.files as FileList)[0].name,
        file: (target.files as FileList)[0],
      };
      const newFiles = [...this.state.files, file];
      this.setState({ files: newFiles });
      this.props.form.setFieldValue(this.props.field.name, newFiles);
    }
  }

  public handleDeleteFile(index: number) {
    const newFiles = [...this.state.files];
    newFiles.splice(index, 1);
    this.setState({ files: newFiles });
    this.props.form.setFieldValue(this.props.field.name, newFiles);
  }

  public render() {
    const { form, label, sublabel, placeholder, field, required, id } = this.props;
    const error = form.errors[field.name];
    const touched = form.touched[field.name];
    const filesLength = this.state.files.length;
    return (
      <ImportFileContainer fluid>
        <ImportFileRow noGutters>
          <Col xs={12} sm={4}>
            <ImportFileLabelContainer>
              <ImportFileLabel>{label}</ImportFileLabel>
              <ImportFileSubLabel>{sublabel}</ImportFileSubLabel>
            </ImportFileLabelContainer>
          </Col>
          <Col xs={12} sm={8}>
            {this.state.files.map(({ name }, i) => (
              <ImportFileName key={i}>
                {name}
                <ImportFileNameDeleteIcon onClick={() => this.handleDeleteFile(i)} />
              </ImportFileName>
            ))}
            <ImportFileInput
              id={id}
              hidden
              onChange={(e: React.FormEvent<HTMLInputElement>) => this.handleChangeImportFile(e)}
            ></ImportFileInput>
            {filesLength < MAX_NUMBER_FILES && (
              <ImportFileButton
                {...field}
                error={error}
                touched={touched}
                filesNumber={filesLength}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  this.handleClickImportFile(event)
                }
              >
                {filesLength > 0 && 'Importer un autre fichier'} {/* TODO translation */}
                {filesLength === 0 &&
                  (placeholder && required ? `${placeholder + ' *'}` : placeholder)}
                <ImportFileIcon />
              </ImportFileButton>
            )}
            {touched && error && <ImportFileError>{error}</ImportFileError>}
          </Col>
        </ImportFileRow>
      </ImportFileContainer>
    );
  }
}

const ImportFileContainer = styled(Container)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 16px 0;
  padding: 0;
`;

const ImportFileRow = styled(Row)`
  width: 100%;
`;

const ImportFileInput = styled.input.attrs({ type: 'file' })``;

const ImportFileButton = styled(ThemeComponents.FormImport)<{
  error: string;
  touched: boolean;
  filesNumber: number;
}>`
  position: relative;
  border-color: ${(props) => {
    const colorType =
      !!props.error && props.touched
        ? props.theme.colors.red.dark
        : props.theme.colors.white.darker;
    return colorType;
  }};
  color: ${(props) =>
    props.filesNumber > 0 ? props.theme.colors.greyDark : props.theme.colors.grey.main} !important;
`;

const ImportFileLabelContainer = styled.div`
  margin: 5px 15px 5px 0;
`;

const ImportFileLabel = styled(ThemeComponents.FormLabel)``;

const ImportFileSubLabel = styled(ThemeComponents.FormSubLabel)``;

const ImportFileError = styled(ThemeComponents.FormErrorLabel)``;

const ImportFileIcon = styled(IconImport)`
  position: absolute;
  top: 10px;
  right: 15px;
`;

const ImportFileName = styled(ThemeComponents.FormImportNameFile)`
  text-decoration: underline;
  margin: 10px 0;
  color: ${(props) => props.theme.colors.blackLight};
`;

const ImportFileNameDeleteIcon = styled(IconCrossDelete)`
  position: absolute;
  top: 10px;
  right: 15px;
`;
