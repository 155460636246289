import React, { Component } from 'react';
import { Props } from './ProfileInsurance.container';
import styled from 'styled-components';
import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { MasterBody } from '../../../components/Layout/MasterBody';
import { MasterContainer } from '../../../components/Layout/MasterContainer';
import { HeaderSectionForm } from '../../../components/Form/HeaderSectionForm';
import { i18n } from '../../../lib/i18n';
import { Formik, Field, Form } from 'formik';
import { TextInputForm, SimpleCheckForm } from '../../../components/Form';
import { profileInsuranceValidationSchema } from './ProfileInsurance.validation';
import { ButtonsForm } from '../../../components/Form/ButtonsForm';
import { loader } from 'graphql.macro';
import { appContext } from '../../../context/appContext';
// import { Company, Veterinarian } from '../../../types/DbInterface';

import { Loader } from '../../../components/Loader';
import { client } from '../../../graphql/client';
import { Account, Company, Veterinarian } from '../../../types/DbInterface';

const getProfessionalAccountWithRelations = loader('../../../graphql/getProfessionalAccountWithRelations.graphql');

const updateVeterinarianInsurance = loader('../../../graphql/updateVeterinarianInsurance.graphql');

interface ValuesForm {
  insuredByCompany: boolean;
  insuranceCompany: string;
  contractNumber: string;
  customerNumber: string;
}

export interface State {
  insuranceCompany: string; //insurerName..
  contractNumber: string;
  currentVet?: Veterinarian;
  currentCompany?: Company;
  loading?: boolean;
  isInsuredByCompany: boolean;
}

export class ProfileInsurance extends Component<Props, State> {
  public state: State = {
    insuranceCompany: '',
    contractNumber: '',
    currentVet: undefined,
    currentCompany: undefined,
    loading: true,
    isInsuredByCompany: false,
  };

  public async componentDidMount() {
    try {
      const response = await client.query<{
        getProfessionalAccountWithRelations?: {
          account: Account;
          veterinarian?: Veterinarian;
          company?: Company;
          companyVeterinarians?: Veterinarian[];
        };
      }>({
        query: getProfessionalAccountWithRelations,
        fetchPolicy: 'network-only',
      });
      if (
          response.data.getProfessionalAccountWithRelations?.veterinarian &&
          response.data.getProfessionalAccountWithRelations.company?.insurerName === response.data.getProfessionalAccountWithRelations?.veterinarian.insurerName &&
          response.data.getProfessionalAccountWithRelations.company?.insurerContractNumber === response.data.getProfessionalAccountWithRelations?.veterinarian.insurerContractNumber
          ) {
            this.setState({ isInsuredByCompany: true });
          }
      this.setState({
        currentVet: response.data.getProfessionalAccountWithRelations?.veterinarian, 
        currentCompany: response.data.getProfessionalAccountWithRelations?.company,
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  private onSubmit = async (values: ValuesForm) => {
    // console.log(values);

    try {
      await this.props.client.mutate({
        mutation: updateVeterinarianInsurance,
        variables: {
          insurerName: values.insuranceCompany,
          insurerContractNumber: values.contractNumber,
          insurerClientNumber: values.customerNumber,
        },
      });
      this.context.updateContext();
      this.props.history.goBack();
    } catch (error) {
      throw new Error(error);
    }
  };

  render() {
    const account = this.context.currentAccount;
    const company = this.state.currentCompany;
    const veterinarian = this.state.currentVet;
    if (this.state.loading) {
      return <LoaderContainer><Loader inline="centered" active={true} size={'big'} /></LoaderContainer>;
    }
    let insuredByCompany = false;
    if (
      veterinarian &&
      company?.insurerName === veterinarian.insurerName &&
      company?.insurerContractNumber === veterinarian.insurerContractNumber
    ) {
      insuredByCompany = true;
    }
    return (
      <>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <MasterContainer noSideMenu={true}>
            <Container>
              <HeaderContainer>
                <Subtitle>
                  {i18n.t('profile.veterinarianProfile.insurance.subtitle', {
                    firstName: account?.firstName ? account.firstName : '',
                    lastName: account?.lastName ? account.lastName : '',
                  })}
                </Subtitle>
                <Title>{i18n.t('profile.veterinarianProfile.insurance.title')}</Title>
              </HeaderContainer>

              <SectionContainer>
                <HeaderSectionForm
                  title={i18n.t('profile.veterinarianProfile.insurance.form.title')}
                  subtitle={i18n.t('profile.veterinarianProfile.insurance.form.subtitle')}
                />

                <Formik
                  initialValues={{
                    insuredByCompany: insuredByCompany,
                    insuranceCompany:
                      veterinarian && veterinarian.insurerName ? veterinarian.insurerName : '',
                    contractNumber:
                      veterinarian && veterinarian.insurerContractNumber
                        ? veterinarian.insurerContractNumber
                        : '',
                    customerNumber:
                      veterinarian && veterinarian.insurerClientNumber
                        ? veterinarian.insurerClientNumber
                        : '',
                  }}
                  onSubmit={this.onSubmit}
                  validationSchema={profileInsuranceValidationSchema}
                >
                  {({ values, setFieldValue }) => (
                    <ProfileInsuranceForm>
                      {/* {console.log(values)} */}
                      <ProfileInsuranceField>
                        <InsuredByCompanyContainer
                          onClick={() => {
                            if (!values.insuredByCompany) {
                              if (
                                this.state.insuranceCompany !== values.insuranceCompany ||
                                this.state.contractNumber !== values.contractNumber
                              )
                                this.setState({
                                  insuranceCompany: values.insuranceCompany,
                                  contractNumber: values.contractNumber,
                                });
                              setFieldValue('insuranceCompany', company?.insurerName);
                              setFieldValue('contractNumber', company?.insurerContractNumber);
                            } else {
                              this.setState({ isInsuredByCompany: false });
                              setFieldValue('insuranceCompany', this.state.insuranceCompany);
                              setFieldValue('contractNumber', this.state.contractNumber);
                            }
                          }}
                        >
                          <Field
                            name={'insuredByCompany'}
                            label={i18n.t(
                              'profile.veterinarianProfile.insurance.form.insuredByCompany.label'
                            )}
                            component={SimpleCheckForm}
                            placeholderComponent={
                              <>
                                {i18n.t(
                                  'profile.veterinarianProfile.insurance.form.insuredByCompany.placeholder'
                                )}
                              </>
                            }
                          />
                        </InsuredByCompanyContainer>
                        <Field
                          name={'insuranceCompany'}
                          label={i18n.t(
                            'profile.veterinarianProfile.insurance.form.insuranceCompany.label'
                          )}
                          placeholder={i18n.t(
                            'profile.veterinarianProfile.insurance.form.insuranceCompany.placeholder'
                          )}
                          component={TextInputForm}
                          disabled={this.state.isInsuredByCompany}
                        />
                        <Field
                          name={'contractNumber'}
                          label={i18n.t(
                            'profile.veterinarianProfile.insurance.form.contractNumber.label'
                          )}
                          placeholder={i18n.t(
                            'profile.veterinarianProfile.insurance.form.contractNumber.placeholder'
                          )}
                          component={TextInputForm}
                          disabled={this.state.isInsuredByCompany}
                        />
                        <Field
                          name={'customerNumber'}
                          label={i18n.t(
                            'profile.veterinarianProfile.insurance.form.customerNumber.label'
                          )}
                          placeholder={i18n.t(
                            'profile.veterinarianProfile.insurance.form.customerNumber.placeholder'
                          )}
                          component={TextInputForm}
                        />
                      </ProfileInsuranceField>
                      <ButtonsForm
                        cancelContent={i18n.t('profile.form.button.cancel')}
                        validateContent={i18n.t('profile.form.button.save')}
                        onCancel={() => this.props.history.goBack()}
                        type={'submit'}
                      />
                    </ProfileInsuranceForm>
                  )}
                </Formik>
              </SectionContainer>
            </Container>
          </MasterContainer>
        </MasterBody>
      </>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.pure};
  padding-left: 10px;
  padding-right: 10px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 200px;
    padding-right: 200px;
  }
`;

const HeaderContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;

const Subtitle = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.body.small.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.body.small.fontSize};
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileInsuranceForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 35px;
`;

const ProfileInsuranceField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 0;
  }
`;

const InsuredByCompanyContainer = styled.div``;

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;
