import { ProfileMenuNotConnected as ProfileMenuNotConnectedComponent } from './ProfileMenuNotConnected.component';

import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../../../lib/withApolloClient';
import { RouteComponentProps } from 'react-router-dom';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';

export type ContainerProps = unknown;

export type Props = WithApolloClient<RouteComponentProps & ContainerProps>;

export const ProfileMenuNotConnected = compose<Props, ContainerProps>(
  withApollo,
  withRouter
)(ProfileMenuNotConnectedComponent);
