import { ModalCmv as ModalCmvComponent } from './ModalCmv.component';

export type Props = {
  show: boolean;
  onClickOutsideCloseModal: () => void;
  headerTitle?: string;
  headerLabelLeft?: string;
  headerLabelRight?: string;
  headerIconLeft?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  headerIconRight?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  headerOnClickLeft?: () => void;
  headerOnClickRight?: () => void;
  contentViewMainTitle?: string;
  contentViewPreTitle?: string;
  contentViewSubTitle?: string;
  contentViewShowCmvLogo?: boolean;
  contentViewImage?: string;
  contentViewImageRetina?: string;
  contentViewBodyContent?: string;
  contentViewShowLoader?: boolean;
  contentViewCustomComponent?: React.ReactNode;
  footerMainButton?: React.ReactNode;
  footerSecondaryButton?: React.ReactNode;
  footerSubTitle?: string;
  footerHidden?: boolean;
  footerCustomComponent?: React.ReactNode;
};

export const ModalCmv = ModalCmvComponent;
