import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../lib/withApolloClient';
import { ResetPasswordForm as ResetPasswordFormComponent } from './ResetPasswordForm.component';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
import { withAppContext } from '../../lib/withContext';

type ContainerProps = RouteComponentProps<
  any,
  any,
  {
    oobCode: string;
    mode: string;
  }
>;

export type Props = WithApolloClient<RouteComponentProps & ContainerProps>;

export const ResetPasswordForm = compose<Props, ContainerProps>(
  withApollo,
  withAppContext
)(ResetPasswordFormComponent);
