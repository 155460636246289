import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './PasswordValidator.container';

interface ValidatorsInterface {
  label: string;
  isValid: (value: any) => void;
}

const validators = [
  { label: '1 Chiffre', isValid: (value: string) => /(\d{1,})/.test(value) },
  { label: '1 MAJ.', isValid: (value: string) => /([A-Z]{1,})/.test(value) },
  { label: '1 min', isValid: (value: string) => /([a-z]{1,})/.test(value) },
  {
    label: '1 sp&cial',
    isValid: (value: string) => /([ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{1,})/.test(value),
  },
  { label: '8 Carac. min.', isValid: (value: string) => /.{8,}/.test(value) },
];

export class PasswordValidator extends Component<Props> {
  passwordValidatorItem = (validator: ValidatorsInterface, value: string, index: number) => {
    return (
      <PasswordValidatorItem key={index}>
        <PasswordValidatorItemStatus isValid={validator.isValid(value)} />
        <PasswordValidatorItemLabel>{validator.label}</PasswordValidatorItemLabel>
      </PasswordValidatorItem>
    );
  };

  public render() {
    const { content } = this.props;
    return (
      <PasswordValidatorContainer>
        {validators.map((validator, i) => this.passwordValidatorItem(validator, content, i))}
      </PasswordValidatorContainer>
    );
  }
}

const PasswordValidatorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const PasswordValidatorItem = styled.div`
  display: flex;
  align-items: center;
`;

const PasswordValidatorItemStatus = styled.div.attrs((props) => ({
  style: {
    borderColor: `${
      props.isValid ? props.theme.colors.green.darker : props.theme.colors.grey.lighter
    }`,
    backgroundColor: props.isValid
      ? props.theme.colors.green.main
      : props.theme.colors.white.darker,
  },
}))`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border-width: 1px;
`;

const PasswordValidatorItemLabel = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.lead.tiny.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.small.fontWeight};
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  margin-left: 5px;
  color: ${(props) => props.theme.colors.black.light};
`;
