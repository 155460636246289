import { ButtonsForm as ButtonsFormComponent } from './ButtonsForm.component';

export type Props = {
  cancelContent: string;
  validateContent: string;
  onCancel: () => void;
  type?: 'submit' | null;
  spinner?: boolean;
  disabled?: boolean;
};

export const ButtonsForm = ButtonsFormComponent;
