import { DoubleBoxForm as DoubleBoxFormComponent } from './DoubleBoxForm.component';

export type Props = {
  label?: string;
  sublabel?: string;
  contentFirstBox?: string;
  contentSecondBox?: string;
  onClickSecondBox?: () => void;
  direction?: 'column' | 'auto';
};

export const DoubleBoxForm = DoubleBoxFormComponent;
