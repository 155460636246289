import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './MasterSideMenu.container';

export class MasterSideMenu extends Component<Props> {
  public render() {
    return <MainContainer>{this.props.children}</MainContainer>;
  }
}

const MainContainer = styled.div`
  width: 240px;
  height: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tabletMax}) {
    display: none;
  }
`;
