import { ImportFileForm as ImportFileFormComponent } from './ImportFileForm.component';

export type Props = {
  label?: string;
  sublabel?: string;
  placeholder?: string;
  required?: boolean;
  form: any;
  field: any;
  id: string /* Id is used for getElementById and must be unique across all the app */;
};

export const ImportFileForm = ImportFileFormComponent;
