import { ButtonCmv as ButtonCmvComponent } from './ButtonCmv.component';
import { FunctionComponent } from 'react';

export type BtnSizeProps = 'giant' | 'large' | 'medium' | 'small' | 'tiny';
export type BtnStyleProps = 'primary' | 'primaryHollow' | 'alert' | 'alertHollow';

export type Props = {
  btnSize: BtnSizeProps;
  btnStyle: BtnStyleProps;
  content?: string;
  disabled?: boolean;
  leftIcon?: FunctionComponent;
  rightIcon?: FunctionComponent;
  onClick?: () => void;
  type?: string;
  spinner?: boolean;
};

export const ButtonCmv = ButtonCmvComponent;
