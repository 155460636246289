import React, { Component } from 'react';
import styled from 'styled-components';
import { loader } from 'graphql.macro';
import { i18n } from '../../../lib/i18n';
// import { ReactComponent as RefreshIcon } from '../../../theme/icons/Refresh.svg';
import { ReactComponent as NoPicturesIcon } from '../../../theme/icons/NoPictures.svg';
import { Typo } from '../../../theme/typo';
import { Props } from './PicturesList.container';
import { Loader } from '../../../components/Loader';
// import { ButtonWithIcon } from '../../../components/ButtonWithIcon';
// import { theme } from '../../../theme';

const getVideoCallCustomerUploadedFilesUrl = loader(
  '../../../graphql/getVideoCallCustomerUploadedFilesUrl.graphql'
);

const newVideoCallPicture = loader('../../../graphql/newVideoCallPicture.graphql');

const PICTURE_MAX_HEIGHT = 190;
const PICTURE_MAX_WIDTH = 130;

interface State {
  isLoading: boolean;
  picturesUrl: string[];
}

export class PicturesList extends Component<Props, State> {
  public state: State = {
    isLoading: false,
    picturesUrl: [],
  };

  private videoCallPictureSubscription: ZenObservable.Subscription | null = null;

  public componentDidMount() {
    const videoCallId = this.props.videoCallId;
    if (videoCallId) {
      this.subscribeToNewVideoCallPicture(videoCallId);
      window.addEventListener('beforeunload', this.unsubscribeToNewVideoCallPicture);
    } else {
      this.unsubscribeToNewVideoCallPicture();
    }
    // this.refreshPictures();
  }

  public componentWillUnmount() {
    this.unsubscribeToNewVideoCallPicture();
    window.addEventListener('beforeunload', this.unsubscribeToNewVideoCallPicture);
  }

  private subscribeToNewVideoCallPicture = (videoCallId: number) => {
    if (this.videoCallPictureSubscription && !this.videoCallPictureSubscription.closed) {
      this.unsubscribeToNewVideoCallPicture();
    }
    this.videoCallPictureSubscription = this.props.client
      .subscribe({
        query: newVideoCallPicture,
        variables: {
          videoCallId,
        },
      })
      .subscribe({
        next: ({ data }) => {
          const newPicturesUrl = [...this.state.picturesUrl];
          newPicturesUrl.push(data.newVideoCallPicture.url);
          this.setState({
            picturesUrl: newPicturesUrl,
          });
        },
      });
  };

  private unsubscribeToNewVideoCallPicture = () => {
    this.videoCallPictureSubscription && this.videoCallPictureSubscription.unsubscribe();
  };

  private renderList() {
    if (this.state.isLoading) {
      return (
        <LoaderContainer>
          <Loader active inline="centered" size="massive" />
        </LoaderContainer>
      );
    }
    if (!this.state.picturesUrl.length) {
      return (
        <EmptyListContainer>
          <NoPicturesIcon />
          <EmptyListLabel>{i18n.t('twilio.picturesList.emptyListLabel')}</EmptyListLabel>
        </EmptyListContainer>
      );
    }

    return (
      <PicturesContainer>
        {this.state.picturesUrl.map((url, index) => (
          <StyledPicture key={index} src={url} onClick={this.openPictureInNewTab(url)} />
        ))}
      </PicturesContainer>
    );
  }

  private openPictureInNewTab = (pictureUrl: string) => () => {
    window.open(pictureUrl, '_blank');
  };

  private refreshPictures = async () => {
    this.setState({ isLoading: true });
    const response = await this.props.client.query<{
      getVideoCallCustomerUploadedFilesUrl: string[];
    }>({
      query: getVideoCallCustomerUploadedFilesUrl,
      variables: {
        videoCallId: this.props.videoCallId,
      },
      fetchPolicy: 'network-only',
    });
    this.setState({
      isLoading: false,
      picturesUrl: response.data.getVideoCallCustomerUploadedFilesUrl,
    });
  };

  public render() {
    return (
      <Container>
        {/* <Header>
          <HeaderLabel>
            {i18n.t('twilio.picturesList.headerLabel', {
              petName: this.props.petName.toUpperCase(),
            })}
          </HeaderLabel>
          <RefreshButtonContainer onClick={this.refreshPictures}>
            <StyledRefreshIcon />
            <Label>{i18n.t('twilio.picturesList.refreshButtonLabel')}</Label>
          </RefreshButtonContainer>
        </Header> */}
        <List>{this.renderList()}</List>
        {/* <RefreshButtonContainer onClick={this.refreshPictures}>
          <ButtonWithIcon
            onClick={this.refreshPictures}
            label={i18n.t('twilio.picturesList.refreshButtonLabel')}
            icon={RefreshIcon}
            color={theme.colors.primary.mainColor}
            iconClassName="white_icon"
            buttonStyle={{ borderRadius: 0, height: '100px' }}
          />
        </RefreshButtonContainer> */}
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

// const Header = styled.div`
//   display: flex;
//   justify-content: space-between;
//   padding: ${(props) => props.theme.spacing.unit * 2}px 0;
//   align-items: center;
// `;

// const HeaderLabel = styled(Typo.FormTitle)`
//   color: ${(props) => props.theme.colors.primary.mainColor};
// `;

// const RefreshButtonContainer = styled.div`
//   display: flex;
// `;

// const StyledRefreshIcon = styled(RefreshIcon)`
//   path {
//     fill: ${(props) => props.theme.colors.primary.mainColor};
//   }
// `;

// const Label = styled(Typo.ButtonLabel)`
//   color: ${(props) => props.theme.colors.blue.main};
//   margin-left: ${(props) => props.theme.spacing.unit}px;
// `;

const List = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const EmptyListContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const EmptyListLabel = styled(Typo.ButtonLabel)`
  color: ${(props) => props.theme.colors.blue.main};
`;

const StyledPicture = styled.img`
  max-height: ${PICTURE_MAX_HEIGHT}px;
  max-width: ${PICTURE_MAX_WIDTH}px;
  object-fit: contain;
  padding-left: ${(props) => props.theme.spacing.unit}px;
  padding-top: ${(props) => props.theme.spacing.unit}px;
  cursor: pointer;
`;

const PicturesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: ${(props) => props.theme.spacing.unit}px;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;
