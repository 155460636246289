import * as Yup from 'yup';

export const organisationConsultationPricesValidationSchema = Yup.object().shape({
  'cat_GeneralPractitioner': Yup.number().required('Ce champ est requis'),
  'cat_Behaviourist': Yup.number().required('Ce champ est requis'),
  'cat_Osteopath': Yup.number().required('Ce champ est requis'),
  'cat_Ophthalmologist': Yup.number().required('Ce champ est requis'),
  'cat_Dentist': Yup.number().required('Ce champ est requis'),
  'cat_Nutritionist': Yup.number().required('Ce champ est requis'),
  'cat_Nursery': Yup.number().required('Ce champ est requis'),
  'cat_Ethologist': Yup.number().required('Ce champ est requis'),
  'cat_Parasitologist': Yup.number().required('Ce champ est requis'),
  'cat_Dermatologist': Yup.number().required('Ce champ est requis'),
  'cat_Gastrologue': Yup.number().required('Ce champ est requis'),
  'cat_Virologist': Yup.number().required('Ce champ est requis'),
  'cat_Urologist': Yup.number().required('Ce champ est requis'),
  'cat_Traumatologist': Yup.number().required('Ce champ est requis'),
  'cat_Orthopedist': Yup.number().required('Ce champ est requis'),
  'cat_Surgeon': Yup.number().required('Ce champ est requis'),
  'dog_GeneralPractitioner': Yup.number().required('Ce champ est requis'),
  'dog_Behaviourist': Yup.number().required('Ce champ est requis'),
  'dog_Osteopath': Yup.number().required('Ce champ est requis'),
  'dog_Ophthalmologist': Yup.number().required('Ce champ est requis'),
  'dog_Dentist': Yup.number().required('Ce champ est requis'),
  'dog_Nutritionist': Yup.number().required('Ce champ est requis'),
  'dog_Nursery': Yup.number().required('Ce champ est requis'),
  'dog_Ethologist': Yup.number().required('Ce champ est requis'),
  'dog_Parasitologist': Yup.number().required('Ce champ est requis'),
  'dog_Dermatologist': Yup.number().required('Ce champ est requis'),
  'dog_Gastrologue': Yup.number().required('Ce champ est requis'),
  'dog_Virologist': Yup.number().required('Ce champ est requis'),
  'dog_Urologist': Yup.number().required('Ce champ est requis'),
  'dog_Traumatologist': Yup.number().required('Ce champ est requis'),
  'dog_Orthopedist': Yup.number().required('Ce champ est requis'),
  'dog_Surgeon': Yup.number().required('Ce champ est requis'),
  'unusual_GeneralPractitioner': Yup.number().required('Ce champ est requis'),
  'unusual_Behaviourist': Yup.number().required('Ce champ est requis'),
  'unusual_Osteopath': Yup.number().required('Ce champ est requis'),
  'unusual_Ophthalmologist': Yup.number().required('Ce champ est requis'),
  'unusual_Dentist': Yup.number().required('Ce champ est requis'),
  'unusual_Nutritionist': Yup.number().required('Ce champ est requis'),
  'unusual_Nursery': Yup.number().required('Ce champ est requis'),
  'unusual_Ethologist': Yup.number().required('Ce champ est requis'),
  'unusual_Parasitologist': Yup.number().required('Ce champ est requis'),
  'unusual_Dermatologist': Yup.number().required('Ce champ est requis'),
  'unusual_Gastrologue': Yup.number().required('Ce champ est requis'),
  'unusual_Virologist': Yup.number().required('Ce champ est requis'),
  'unusual_Urologist': Yup.number().required('Ce champ est requis'),
  'unusual_Traumatologist': Yup.number().required('Ce champ est requis'),
  'unusual_Orthopedist': Yup.number().required('Ce champ est requis'),
  'unusual_Surgeon': Yup.number().required('Ce champ est requis'),
  'horse_GeneralPractitioner': Yup.number().required('Ce champ est requis'),
  'horse_Behaviourist': Yup.number().required('Ce champ est requis'),
  'horse_Osteopath': Yup.number().required('Ce champ est requis'),
  'horse_Ophthalmologist': Yup.number().required('Ce champ est requis'),
  'horse_Dentist': Yup.number().required('Ce champ est requis'),
  'horse_Nutritionist': Yup.number().required('Ce champ est requis'),
  'horse_Nursery': Yup.number().required('Ce champ est requis'),
  'horse_Ethologist': Yup.number().required('Ce champ est requis'),
  'horse_Parasitologist': Yup.number().required('Ce champ est requis'),
  'horse_Dermatologist': Yup.number().required('Ce champ est requis'),
  'horse_Gastrologue': Yup.number().required('Ce champ est requis'),
  'horse_Virologist': Yup.number().required('Ce champ est requis'),
  'horse_Urologist': Yup.number().required('Ce champ est requis'),
  'horse_Traumatologist': Yup.number().required('Ce champ est requis'),
  'horse_Orthopedist': Yup.number().required('Ce champ est requis'),
  'horse_Surgeon': Yup.number().required('Ce champ est requis')
}
);