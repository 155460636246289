import { Footer as FooterComponent } from './Footer.component';

export type Props = {
  footerMainButton?: React.ReactNode;
  footerSecondaryButton?: React.ReactNode;
  footerSubTitle?: string;
  footerHidden?: boolean;
  footerCustomComponent?: React.ReactNode;
};

export const Footer = FooterComponent;
