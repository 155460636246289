export default {
  // signUp: {
  //   title: 'Vous aussi faites partie de la team Callmyvet',
  //   navToLandingConfirmWarning:
  //     "Êtes-vous sûr de quitter l'inscription? L'ensemble des données renseignées seront perdues",
  //   form: {
  //     title: 'Étape {{currentStep}}/3',
  //     previousButton: 'Précédent',
  //     fields: {
  //       firstName: {
  //         label: 'Prénom',
  //         placeholder: 'Prénom',
  //       },
  //       lastName: {
  //         label: 'Nom',
  //         placeholder: 'Nom',
  //       },
  //       address: {
  //         label: 'Adresse postale',
  //         placeholder: 'Adresse postale',
  //       },
  //       establishment: {
  //         label: 'Établissement',
  //         placeholder: 'Établissement',
  //       },
  //       orderNumber: {
  //         label: "N° d'ordre",
  //         placeholder: "N'ordre",
  //       },
  //       insurerName: {
  //         label: 'Nom de votre assureur',
  //         placeholder: 'Nom de votre assureur',
  //       },
  //       insuredNumber: {
  //         label: "Numéro d'assuré",
  //         placeholder: "Numéro d'assuré",
  //       },
  //       legalStatus: {
  //         label: 'Statut Juridique',
  //         placeholder: 'SARL',
  //       },
  //       orderCounty: {
  //         label: "Département d'inscription à l'ordre",
  //         placeholder: "Département d'inscription",
  //       },
  //       speciality: {
  //         label: 'Votre spécialité',
  //         placeholder: 'Ex : ophtalmologie, cardiologie, orthopédie, dermatologie',
  //       },
  //       siret: {
  //         label: 'SIRET',
  //         placeholder: 'Indiquez votre N° de siret',
  //       },
  //       schoolName: {
  //         label: "Nom de l'école",
  //         placeholder: 'Nom de votre école',
  //       },
  //       graduationYear: {
  //         label: 'Obtention du diplôme',
  //         placeholder: 'Année',
  //       },
  //       uploadFileInput: {
  //         backSideLabel: "(si carte d'identité)",
  //         placeholder: 'Choisir un fichier',
  //         placeholderDescription: '.jpg, .png, .pdf',
  //       },
  //       idCardFrontSide: {
  //         label: 'RECTO',
  //       },
  //       idCardBackSide: {
  //         label: 'VERSO',
  //       },
  //       phoneNumber: {
  //         label: 'Tél. fixe',
  //         placeholder: '00 00 00 00 00',
  //       },
  //       mobilePhoneNumber: {
  //         label: 'Tél. portable',
  //         placeholder: '00 00 00 00 00',
  //       },
  //       email: {
  //         label: 'Adresse e-mail',
  //         placeholder: 'E-mail',
  //       },
  //       bankAccountName: {
  //         label: 'Nom/raison sociale',
  //         placeholder: 'Nom du bénéficiaire du compte',
  //       },
  //       iban: {
  //         label: 'iban',
  //         placeholder: 'Ex: FR95 3000 2005 0000 1578 4590 P29',
  //       },
  //     },
  //     step1: {
  //       nextButton: 'Suivant',
  //       subtitle: 'Informations générales',
  //       subpartTitle: {
  //         practicePlace: "Votre lieu d'exercice actuel",
  //       },
  //     },
  //     step2: {
  //       nextButton: 'Suivant',
  //       subtitle: 'Informations professionnelles',
  //       subpartTitle: {
  //         expertiseArea: 'Vos domaines de compétences',
  //         businessInformations: 'Vos coordonnées commerciales',
  //         vetDiploma: 'Votre diplôme vétérinaire',
  //       },
  //     },
  //     step3: {
  //       nextButton: 'Finaliser',
  //       subtitle: 'Informations personnelles',
  //       cgu: {
  //         preLink: 'J’accepte les ',
  //         cguLink: 'Conditions d’utilisation et la Politique de confidentialité',
  //         postLink: ' du service Callmyvet.',
  //       },
  //       subpartTitle: {
  //         idCard: "Votre pièce d'identité",
  //         idCardDescription: "(carte d'identité ou passeport)",
  //         contactInformation: 'Vos coordonnées',
  //         bankingInformation: 'Vos coordonnées bancaires',
  //         bankingInformationDescription: '(pour le virement des appels pris)',
  //       },
  //     },
  //     step4: {
  //       title: 'Merci !\nVotre pré-inscription a bien été prise en compte.',
  //       content:
  //         'Notre équipe se charge de vérifier les informations renseignées. Nous vous confirmerons votre inscription finale par e-mail dans les 48 prochaines heures.',
  //       button: "Retour à l'accueil",
  //     },
  //     errors: {
  //       required: 'Ce champ est obligatoire',
  //       requiredExpertiseArea: 'Veuillez sélectionner au moins un domaine',
  //       requiredCgu: 'Veuillez accepter les conditions générales ',
  //       requiredFile: 'Veuillez uploader un document pour continuer',
  //       rejectedFile: 'Veuillez uploader un fichier de 5 MB ou moins',
  //       duplicateEmail: "L'adresse e-mail renseignée est déjà utilisée",
  //       invalidEmail: 'E-mail invalide',
  //     },
  //   },
  // },
  signIn: {
    title: 'Vétérinaires, connectez-vous pour accéder à votre dashboard.',
    form: {
      fields: {
        email: {
          label: 'Adresse e-mail',
          placeholder: 'Saisissez votre adresse e-mail',
        },
        password: {
          label: 'Mot de passe',
          placeholder: 'Mot de passe',
        },
      },
      error: {
        wrongEmailPassword: 'La combinaison e-mail/mot de passe ne correspond pas',
      },
    },
    forgottenPassword: 'Mot de passe oublié ?',
    noAccount: 'Pas encore de compte ?\nPour en créer un, ',
    signUpLink: 'rendez-vous par ici !',
    backLabel: 'Retour',
  },
  // account: {
  //   title: 'Compte',
  //   subTitle: 'Vos informations de paiement',
  //   validationText:
  //     'Validation de votre compte Stripe obligatoire pour pouvoir recevoir des paiements. Pensez à préparer une pièce d’identité, un Kbis ainsi qu’un justificatif de domicile. Vous pouvez utiliser la camera de votre ordinateur ou de votre téléphone pour faire les scans de ces documents.',
  //   ibanText: 'Renseignez/modifiez votre IBAN.',
  //   infoTips: 'Nota: Pensez à rafraîchir votre page une fois toutes les informations fournies.',
  //   buttons: {
  //     onBoardingInvalide: 'Finaliser votre compte',
  //     onBoardingValide: 'Compte validé',
  //     onBoardingPending: 'En cours de validation',
  //     ibanInvalide: 'Renseigner un IBAN',
  //     ibanValide: 'Modifier',
  //   },
  //   ibanForm: {
  //     title: 'IBAN',
  //     subTitle:
  //       'Ajoutez ou modifiez votre IBAN pour recevoir des paiements et effectuer de virements automatiques pour le compte de Call My Vet.',
  //     form: {
  //       fields: {
  //         name: {
  //           label: 'Nom du titulaire du compte',
  //           placeholder: 'Jenny Rosen',
  //         },
  //         iban: {
  //           label: 'Numéro IBAN',
  //         },
  //       },
  //     },
  //     stripeText:
  //       'En fournissant votre IBAN et en confirmant ce moyen de paiement, vous autorisez Call  My Vet SAS et Stripe, notre prestataire de services de paiement, à envoyer des instructions à votre banque pour débiter votre compte et à votre banque pour débiter votre compte conformément à ces instructions. Vous avez droit à un remboursement de votre banque selon les termes et conditions de votre accord avec votre banque. Un remboursement doit être demandé dans les 8 semaines à compter de la date de débit de votre compte.',
  //     popup: {
  //       valid: '✅ Votre IBAN a bien été enregistré par Stripe',
  //       errorLeftButton: 'Contacter le service client',
  //       error:
  //         '⚠️ Votre IBAN est erroné, veuillez vérifier votre saisie. Si le problème persiste, veuillez contacter nos équipes.',
  //     },
  //   },
  // },
  admin: {
    vet: {
      moderationStatus: {
        pending: 'En attente de modération',
        refused: 'Informations manquantes / fausses',
        validated: 'Compte validé',
      },
      nationality: {
        french: 'FRENCH',
        belgian: 'BELGIAN',
      },
      speciality: {
        generalPractitioner: 'GENERAL_PRACTITIONER',
        behaviourist: 'BEHAVIOURIST',
        osteopath: 'OSTEOPATH',
        ophthalmologist: 'OPHTHALMOLOGIST',
        dentist: 'DENTIST',
        nutritionist: 'NUTRITIONIST',
        nursery: 'NURSERY',
        ethologist: 'ETHOLOGIST',
        parasitologiste: 'PARASITOLOGISTE',
        dermatologist: 'DERMATOLOGIST',
        gastrologue: 'GASTROLOGUE',
        virologist: 'VIROLOGIST',
        urologist: 'UROLOGIST',
        traumatologist: 'TRAUMATOLOGIST',
        orthopedist: 'ORTHOPEDIST',
        surgeon: 'SURGEON',
      },
      expertiseArea: {
        catAndDog: 'catAndDog',
        cat: 'cat',
        dog: 'dog',
        NAC: 'NAC',
        horse: 'horse',
      },
    },
  },
  shared: {
    optionalField: '(optionnel)',
    nextButton: 'Continuer',
    raceItem: {
      catAndDog: 'Chats & chiens',
      cat: 'Chats',
      dog: 'Chiens',
      horse: 'Equin',
      NAC: 'N.A.C.',
    },
    speciesItem: {
      Cameleon: 'Caméléon',
      Chevre: 'Chèvre',
      Cochon: 'Cochon',
      CochondInde: "Cochon d'Inde",
      Dinde: 'Dinde',
      Furet: 'Furet',
      Hamster: 'Hamster',
      Herisson: 'Hérisson',
      Lama: 'Lama',
      Lapin: 'Lapin',
      Lezard: 'Lézard',
      Mouton: 'Mouton',
      Perroquet: 'Perroquet',
      Poisson: 'Poisson',
      Poule: 'Poule',
      Rat: 'Rat',
      Scorpion: 'Scorpion',
      Serpent: 'Serpent',
      Souris: 'Souris',
      Tortue: 'Tortue',
      Autre: 'Autre espèce',
    },
  },
  navBar: {
    connected: {
      accueil: 'Accueil',
      compte: 'Compte',
      deconnexion: 'Déconnexion',
    },
    // informationBar: {
    //   document: {
    //     text: 'IMPORTANT : Vous devez finaliser votre compte Stripe pour recevoir des paiements',
    //     link: 'Valider mon compte Stripe',
    //   },
    //   iban: {
    //     text: 'IMPORTANT : Vous devez indiquer votre IBAN pour recevoir des paiements',
    //     link: 'Ajouter mon IBAN',
    //   },
    // },
    modal: {
      mainMenu: {
        title: 'MENU',
      },
      profileMenu: {
        title: 'PROFIL MENU',
      },
    },
  },
  dashboard: {
    // signOutLabel: 'Déconnexion',
    // toggleAvailability: {
    //   nameTitle: 'Bienvenue,',
    //   blockContentTitle: 'Votre Disponibilité',
    //   blockContentSubtitle:
    //     'Pour recevoir des appels, actionnez le bouton ci-contre pour qu’il ait une couleur verte.',
    //   informationBlock: {
    //     title: 'Informations pratiques',
    //     link: {
    //       guide: 'Guide pratique',
    //       tarif: 'Tarification ?',
    //       parainage: 'Parainez un confrère',
    //       estimation: "Outils d'estimation",
    //       satisfaction: 'Enquête de satisfaction',
    //       contacte: 'Contactez-nous',
    //       suggestion: 'Faire une suggestion',
    //       info: 'À propos',
    //     },
    //   },
    // },
    title: 'Bienvenue',
    availabeVets: {
      title: 'Vétérinaires disponibles',
      subtitle: 'Vous devez avoir au moins un profil vétérinaire vérifié pour recevoir des appels.',
      noVetAvailable: {
        text: 'Renseignez au moins un vétérinaire dans les ',
        link: ' membres de votre équipe',
      },
      companyNotVerified: {
        text: 'Vous devez ',
        link: 'terminez l’inscription de votre société',
        textEnd: ' pour pouvoir affecter des vétérinaires.',
      },
      currentVetNotVerified: {
        text: 'Vous devez ',
        link: 'terminer votre profil vétérinaire',
        textEnd: ' pour pouvoir recevoir des appels.',
      },
    },
    companyStatus: {
      title: 'Statut de la société {{companyName}}',
      subtitle:
        'Vous devez renseigner toutes les informations ci-dessous pour recevoir des appels.',

      legalInformation: 'Informations légales',
      vat: 'Siret et TVA',
      address: 'Adresse',
      contact: 'Contact',
      bankingInformation: 'Informations bancaires, Stripe',
      professionalInsurance: 'Assurance professionnelle',
      teamMembers: 'Membres de l’équipe',
      status: {
        verified: '',
        inReview: 'En cours de vérification',
        warning: 'Informations manquantes',
        criticalWarning: 'Informations critiques manquantes',
      },
    },
    information: {
      title: 'Informations pratiques',
      subtitle:
        ' Pour recevoir des appels, actionnez le bouton ci-contre pour qu’il ai une couleur verte.',
      links: {
        howItWorks: 'Comment ça marche ?',
        pricing: 'Tarification',
        estimationTools: "Outils d'estimation",
        satisfactionSurvey: 'Enquête de satisfaction',
        contactUs: 'Contactez-nous',
        suggestion: 'Faire une suggestion',
      },
    },

    cardText: {
      available: 'Vous êtes actif',
      unavailable: 'Vous êtes inactif',
    },
    codeAlert: {
      codeText: 'Le code de votre clinique est :',
      codeHelpText: 'C’est ce code que vous devez communiquer à vos clients pour qu’ils vous retrouvent sur l’app CallMyVet'
    },
  },
  videoCallHandler: {
    videoCallRequest: {
      title: 'Demande en cours...',
      subtitle: '{{customerGender}} {{customerFirstName}},\n Pour: {{petName}}',
      informations: "Raison de l'appel: ",
      buttons: {
        acceptCall: "Accepter l'appel vidéo",
        declineCall: "Décliner l'appel vidéo",
      },
      chooseVeterinarian: {
        title: 'Sélectionnez le vétérinaire pour repondre',
      },
      petTrouble: {
        GeneralPractitioner: 'Médecine Générale',
        Behaviourist: 'Comportement',
        Osteopath: 'Ostéopathie',
        Ophthalmologist: 'Ophtalmologie',
        Dentist: 'Odontologie',
        Nutritionist: 'Nutrition',
        Nursery: 'Gynécologie',
        Ethologist: 'Éthologie',
        Parasitologist: 'Parasitologie',
        Dermatologist: 'Dermatologie',
        Gastrologue: 'Gastrologie',
        Virologist: 'Maladie Contagieuse',
        Urologist: 'Urologie',
        Traumatologist: 'Traumatologie',
        Orthopedist: 'Orthopédie',
        Surgeon: 'Chirurgie',
      },
    },
    videoCallStatusCard: {
      alreadyAcceptedCall: {
        title: 'Demande prise en charge',
        subtitle: "La demande a été prise en charge par l'un de vos confrères.",
      },
      cancelledCall: {
        title: 'Demande annulée',
        subtitle: 'La demande a été annulée par l’utilisateur.',
      },
      timedOutCall: {
        title: 'Appel manqué !',
        subtitle: 'Aucun vétérinaire n’a répondu à la demande.',
      },
      enableGestureForSound: {
        title: 'Votre navigateur a mis la sonnerie en veille',
        subtitle:
          "Pour entendre de nouveau la sonnerie de votre navigateur, veuillez cliquer n'importe où sur la page.\n\nSi vous ne souhaitez plus voir cette alerte, veuillez modifier les privilèges d’accès de pro.callmyvet.io dans les préférences de votre navigateur.",
        buttonContent: 'Réactiver le son du navigateur',
        procedure: {
          chrome: `
          <title>CHROME</title><br/>
          <bold>Cliquez sur l’icône des “3 points verticaux”, puis sélectionnez “Préférences” :</bold>
          <br/>
          <br/>
          <leftAlignText>
            / Préférences<br/>
            / Confidentialité et sécurité<br/>
            / Paramêtre des sites<br/>
            / Micro<br/>
            / Cliquez sur la ligne https://pro.callmyvet.io
            </leftAlignText>
            <br/>
          -> Changez le <bold>paramètre SON</bold> :  “Automatique” pour <bold>“Autoriser”</bold>`,
          firefox: `
          <title>FIREFOX</title><br/>
          <bold>La page pro.callmyvet.io doit être ouverte dans Firefox. Cliquez sur l’icône “cadena” à gauche de l’url dans la barre de navigation :</bold>
          <br/>
          <br/>
          <leftAlignText>
          / Cliquer sur la flèche qui se trouve à droite de “Connexion sécurisée”<br/>
          / Puis cliquer sur “Plus d’information” en bas du popup.<br/>
          / Cliquer sur “Permission”<br/>
          / Sur la ligne “Lire automatiquement des médias”<br/>
          </leftAlignText>
          <br/>
          -> Décocher <bold>“Permission par défaut”</bold>
          -> Sélectionner <bold>“Autoriser l’audio et la video”</bold>`,
          safari: `
          <title>SAFARI</title><br/>
          <bold>La page pro.callmyvet.io doit être ouverte sur Safari. Cliquez sur Safari dans le menu en haut à gauche, puis sélectionnez “Préférences :</bold>
          <br/>
          <br/>
          <leftAlignText>
          / Préférences<br/>
          / Site Webs<br/>
          / Lecture automatique<br/>
          / Trouver “pro.callmyvet.io” dans la liste des sites web<br/>
          </leftAlignText>
          <br/>
          -> Changez le paramètre <bold>“Ne pas lire le contenu sonore”</bold> pour <bold>“Toujour autoriser la lecture automatique”</bold>`,
        },
      },
    },
  },
  surveyEndCall: {
    headerTitle: 'Engagement qualité de service',
    headerLabelRight: 'En savoir plus',
    preTitle: 'Formulaire règlementaire',
    mainTitle: 'Fin de l’appel',
    body:
      'Il y a eu un problème ? Rappelez {{ownerName}} au {{phoneNumber}} \nTous les champs avec * sont obligatoires',
    form: {
      callStatus: {
        label: 'Validation du paiement *',
        sublabel:
          'La qualité du service repose sur l’expertise de chaque vétérinaire, dont vous. Nous faisons confiance à votre jugement pour facturer ou non cet appel. Notez que notre commission sera prélevée en fin de mois, quelle que soit votre décision. Cette mesure vise à maintenir la qualité du service tout en évitant les abus.',
        validated: 'Valider le paiement',
        notValidated: 'Décliner le paiement',
      },
      submit: 'Envoyer le formulaire',
      error: {
        required: 'Séléctionnez une option',
      },
    },
  },
  // choosingPassword: {
  //   errors: {
  //     setPasswordFailed: "Une erreur est survenue, votre mot de passe n'a pas été créé",
  //     missingParams: "Une erreur est survenue, le lien n'est pas valide",
  //     passwordTooShort: 'Le mot de passe doit être de 8 caractères minimum',
  //   },
  //   password: {
  //     label: 'Mot de passe (8 caractères min.)',
  //     placeholder: 'Votre mot de passe',
  //   },
  //   content:
  //     "Afin d'accéder à votre compte et de commencer à reçevoir des appels, veuillez définir votre mot de passe personnel.",
  //   title: 'Définissez votre mot de passe',
  // },
  resetPaswordRequestEmail: {
    subject: 'Call My Vet - Vétérinaire - Mot de passe oublié',
    body:
      "Bonjour, Je vous écris ce jour car j'ai oublié mon mot de passe. Pouvez-vous me transmettre un lien de réinitialisation s.v.p. ? Je vous en remercie par avance.",
  },
  twilio: {
    callHeader: '🟢 Appel vidéo en cours',
    hangUpModalMessage: "Vous êtes sur le point de mettre fin à l'appel. Êtes vous sûr?",
    picturesList: {
      headerLabel: 'PHOTOS DE {{petName}}',
      refreshButtonLabel: 'Rafraîchir les médias reçus',
      emptyListLabel: 'Aucun média reçu',
    },
    videoStatus: {
      tryToReconnect: "L'utilisateur tente de se reconnecter",
      reconnecting: "Veuillez patienter, l'utilisateur se connecte...",
      videoDisabled: "L'utilisateur a désactivé sa vidéo",
      audioDisabled: "L'utilisateur a désactivé son micro",
      disconnect: "L'utilisateur a été déconnecté",
      cameraNotFound: "Caméra non disponible ou introuvable",
      cameraNotAccess: "Autorisation d'accès à la caméra refusée ou utilisée par une autre application",
      cameraFailure: "La caméra n'est pas accessible ou est endommagée",
    },
    videoTabItems: {
      call: 'Appel',
      documents: 'Documents',
      animalCard: "Fiche de l'animal",
    },
    callInfoDetails: {
      pet: 'Animal',
      petName: 'Nom',
      specieAndRace: 'Espèce / Race',
      ageAndSex: 'Âge / Sexe',
      male: 'Mâle',
      female: 'Femelle',
      sterilized: 'Stérilisé',
      freeCall: 'Appel offert',
      paidCall: 'Appel payant',
      owner: 'Propriétaire',
      firstNameAndLastName: 'Prénom Nom',
      phoneNumber: 'Téléphone',
      email: 'Email',
      cityAndCountry: 'Ville / Pays',
      infos: '📋 Infos',
      callPurpose: 'Objet de l’appel',
      tarification: 'Tarif',
      regularTarification: 'Tarif de base',
      slightlyIncreasedTarification: 'Tarif majoré',
      increasedTarification: 'Tarif de garde',
      callType: "Type d'appel",
      callDuration: 'Durée de l’appel',
      yes: 'oui',
      no: 'non',
      unknown: 'N.C',
    },
    deconnexionAlert:
      "Nous espérons que votre téléconseil s'est bien déroulé ! Vous pouvez rappeler {{petName}} au {{customerPhoneNumber}} si vous le souhaitez. L'équipe de Callmyvet",
  },
  login: {
    title: 'Connexion',
    subtitle: 'La solution fiable et légale pour la télérégulation vétérinaire.',
    form: {
      fields: {
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
        password: {
          label: 'Mot de passe',
          placeholder: 'Mot de passe',
        },
      },
      submit: 'Connexion',
    },
    link: {
      forgotPassword: 'Mot de passe oublié',
      noAccount: 'Vous n’avez pas de compte ? ',
      noAccountLink: 'Inscrivez-vous maintenant.',
    },
  },
  resetPasswordRequest: {
    title: 'Réinitialiser le mot de passe',
    subtitle: 'Vous avez oublié votre mot de passe ?',
    form: {
      fields: {
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
      },
      indication:
        ' Un mail va vous être envoyé avec un lien pour réinitialiser votre mot de passe.',
      submit: 'Envoyer',
      errors: {
        emailNotFound: "Nous n'avons pas trouvé de compte avec cette adresse email",
        tooManyRequest:
          'Votre compte a atteint la limite de demande,\nveuillez patienter avant de réessayer',
      },
    },
    link: {
      login: 'Connexion',
    },
    confirmationModal: {
      subtitle: 'Votre demande à bien été prise en compte, veuillez vérifier votre boite mail.',
      backToLogin: 'Revenir à l’accueil',
    },
  },
  resetPasswordRequestEmailSentConfirmation: {
    title: 'Réinitialiser le mot de passe',
    subtitle:
      'Votre demande à bien été prise en compte, veuillez vérifier votre boite mail. Vous pouvez fermer cette fenêtre.',
  },
  createAccount: {
    title: 'Créez votre compte',
    description:
      'Veuillez utiliser votre email professionnel, il ne sera pas visible publiquement et nous allons être amné à vous transmettre des informations importantes. Vous pourrez communiquer plus tard un email de contact “publique” si vous le souhaitez. Vous avez déjà un compte ?',
    linkLogin: 'Connectez-vous',
    subnavbar: {
      title: 'La communauté qui respecte les vétérinaires.',
    },
    form: {
      fields: {
        firstName: {
          label: 'Prénom *',
          placeholder: 'Votre prénom',
        },
        lastName: {
          label: 'Nom de famille *',
          placeholder: 'Votre nom de famille',
        },
        email: {
          label: 'Email *',
          placeholder: 'Votre email',
        },
        password: {
          label: 'Mot de passe *',
          placeholder: 'Créez un mot de passe',
        },
        cgu: {
          label: 'Conditions générales *',
          placeholder: {
            firstPart: 'J’accepte les ',
            firstLink: 'Conditions d’utilisation ',
            and: 'et la ',
            secondLink: 'Politique de confidentialité ',
            secondPart: 'du service de téléconseil proposé par la société Call My Vet SAS. *',
          },
        },
      },
      submit: 'Envoyer',
      informations: {
        security:
          'Vous êtes entre de bonnes mains. Vos données sont sécurisées avec les standards les plus élevés.',
        required: 'Les champs * sont obligatoires.',
      },
    },
    confirmation: {
      headerTitle: 'Nous venons de vous envoyer un email',
      subtitle: 'Rendez-vous dans votre boite mail...',
      emailSend: "L'email a été envoyé à : ",
      recipient: ' {{email}}',
      description:
        'Ce mail contient avec un lien d’activation. Veuillez cliquer sur ce lien. Nous devons vérifier votre compte avant de vous ouvrir nos portes. Vous pouvez fermer cette page.',

      emailNotReceived: {
        description:
          'L’email n’aparait pas dans votre boite ? Vérifiez votre dossier Spam. L’email a été envoyé depuis l’adresse noreply@callmyvet.io',
        resendEmailLink: {
          label: 'Vous n’avez pas reçu d’email ?',
          link: 'Renvoyer un code',
        },
        cancelLink: {
          label: 'Vous ne voulez plus vous inscrire ?',
          link: 'Annuler mon inscription',
        },
      },
    },
    deleteAccount: {
      preTitle: 'Votre compte à été supprimé',
      bodyContent: 'Votre compte à bien été supprimé',
      buttonLabel: "Retourner à l'accueil",
    },
  },
  createAccountVerification: {
    title: 'Vérification de votre compte',
    firstSection: 'Notre système est en train de vérifier votre compte.\nVeuillez patienter.',
    errors: {
      title: 'Une erreur est survenue',
      argumentError: "Une erreur est survenue, le lien n'est pas valide",

      invalidActionCode: 'Votre requête est invalide ou a déjà été utilisée.',
      expiredActionCode: 'Votre requête a expirée.',
      userDisabled: 'Votre compte utilisateur firebase est désactivé',
      userNotFound: "Votre compte utilisateur firebase n'a pas été trouvé",
      missingParams:
        'Vous devez cliquer sur le lien envoyer par mail pour pouvoir verifier votre adresse mail.',
      default: "Aucun code d'erreur ne correspond",
      buttonConnected: 'Envoyer une nouvelle demande',
      buttonNotConnected: 'Connectez vous pour envoyer une nouvelle demande',
    },
  },
  createAccountValidated: {
    title: 'Votre compte est activé',
    firstSection:
      'Merci de nous accorder votre confiance. La transparence, le respect des règles et les valeurs de la profession sont au coeur de notre démarche. Callmyvet s’adresse aux vétérinaires convaincus que la télémédecine apporte un réel plus dans la prise en charge des animaux de compagnie. Pour les autres, essayez de vous laisser séduire un peu.',
    continue: 'Continuer',
  },
  onboardingAccount: {
    title: 'Bienvenue {{accountFirstName}},',
    description:
      'Avant de pouvoir recevoir des appels, vous devez obligatoirement être rattaché à une société qui est autorisée à exercer une activité vétérinaire en France. Vous allez entrer dans un processus qui demande un certain nombre de d’information nécessaire pour recevoir des appel. Si vous n’avez pas toutes les informations sur vous, passez à l’écran suivant, vous pouvez revenir plus tard pour compléter.',
    firstSection: {
      title: 'Vétérinaire indépendant ou associé',
      row: {
        leftLabel: 'Ajouter une société sur Callmyvet',
        rightLabelCreate: 'Créer',
        rightLabel: 'Sélectionner',
      },
    },
    secondSection: {
      title: 'Vétérinaire salarié',
      row: {
        leftLabel: 'Rejoindre une équipe',
        rightLabel: 'Rejoindre',
      },
    },
  },
  onboardingVetCompany: {
    title: 'Création d’une société',
    description:
      'Toutes les informations anotées d’un * sont obligatoires pour être en règle avec l’Ordre. Vous pouvez néanmoins passer cette étape et terminer votre profil plus tard.',
    form: {
      fields: {
        country: {
          label: 'Pays de la société *',
          placeholder: 'Sélectionnez un pays',
        },
        legalStatus: {
          label: 'Statut juridique de la société *',
          placeholder: 'Sélectionnez un statut',
        },
        type: {
          label: 'Type de la société *',
          placeholder: 'Sélectionnez un type',
        },
        companyName: {
          label: 'Nom de la société *',
          placeholder: 'Nom de la société',
        },
      },
      button: {
        goBack: 'Précédent',
        continue: 'Continuer',
      },
    },
  },
  onboardingVeterinarian: {
    title: 'Je suis salarié',
    description:
      'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam id dolor id nibh ultricies vehicula ut id elit. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Aenean lacinia bibendum nulla sed consectetur. Vestibulum id ligula porta felis euismod semper.',
    form: {
      fields: {
        email: {
          label: 'Email de votre employeur *',
          placeholder: 'Email de l’employeur',
        },
      },
      button: {
        goBack: 'Précédent',
        send: 'Envoyer',
      },
    },
  },
  onboardingVeterinarianConfirmation: {
    title: 'Invitation de votre employeur',
    subtitle: 'Votre demande à bien été prise en compte.',
    backToLogin: 'Revenir à l’accueil',
  },
  onboardingSideMenu: {
    items: {
      first: 'Sans engagement',
      second: 'Profils vérifiés',
      third: 'Données sécurisées',
      fourth: 'Approuvé par l’Ordre',
    },
  },
  organisation: {
    form: {
      button: {
        cancel: 'Annuler',
        save: 'Enregistrer',
      },
    },
    legalInformations: {
      title: 'Informations légales',
      path: 'Organisation / Informations légales',
      form: {
        title: 'Information de la société',
        subtitle: '',
        country: {
          label: 'Pays de la société *',
          placeholder: 'Pays de la société',
        },
        legalStatus: {
          label: 'Statut juridique de la société *',
          placeholder: 'Statut juridique de la société',
        },
        type: {
          label: 'Type de la société *',
          placeholder: 'Type de la société',
        },
        companyName: {
          label: 'Nom de la société *',
          placeholder: 'Nom de la société',
        },
      },
    },
    vat: {
      title: 'N° Siret et TVA',
      path: 'Organisation / Siret et TVA',
      form: {
        title: 'Information de la société',
        subtitle:
          'Le numéro de SIRET et le numéro de TVA sont nécessaires pour pouvoir éditer des factures en votre nom à l’attention des propriétaires d’animaux.',
        siretNumber: {
          label: 'N° Siret de la société *',
          sublabel: '(Numéro BCE pour les soociétés Belges)',
          placeholder: 'N° Siret de la société',
        },
        vatNumber: {
          label: 'N° de TVA de la société *',
          placeholder: 'N° de TVA de la société',
        },
      },
    },
    address: {
      title: 'Adresse',
      path: 'Organisation / adresse',
      form: {
        title: 'Adresse de la société',
        subtitle: 'Renseignez l’adresse du siège de la société',
        addressMain: {
          label: 'Adresse ligne 1 *',
          placeholder: 'Adresse ligne 1',
        },
        addressComplement: {
          label: 'Adresse ligne 2',
          placeholder: 'Adresse ligne 2',
        },
        city: {
          label: 'Ville *',
          placeholder: 'Ville',
        },
        zipCode: {
          label: 'Code postal *',
          placeholder: 'Code postal',
        },
      },
    },
    contact: {
      title: 'Contact',
      path: 'Organisation / contact',
      form: {
        title: 'Information de contact de la société',
        subtitle: '',
        email: {
          label: 'Email de contact de la société',
          placeholder: 'Email de contact de la société',
        },
        phoneNumber: {
          label: 'Téléphone de la société',
          placeholder: 'Téléphone de la société',
        },
      },
    },
    bankingInformation: {
      title: 'Informations bancaires',
      path: 'Organisation / Informations bancaires',
      form: {
        stripe: {
          title: 'Informations "Stripe" pour les appels payants',
          subtitle:
            'Vous aurez besoin de votre K-Bis et de répondre à quelques questions. Pour procéder aux virements vers votre compte en banque, vous devez nous fournir un IBAN. En fournissant votre IBAN et en confirmant ce moyen de paiement, vous autorisez Call  My Vet SAS et Stripe, notre prestataire de services de paiement, à envoyer des instructions à votre banque pour débiter votre compte et à votre banque pour débiter votre compte conformément à ces instructions. Vous avez droit à un remboursement de votre banque selon les termes et conditions de votre accord avec votre banque. Un remboursement doit être demandé dans les 8 semaines à compter de la date de débit de votre compte.',
          label: 'Statut  de votre compte Stripe',
          status: {
            validated: 'Compte validé',
            noValidated: 'Compte limité',
            pending: 'En cours de validation',
          },
          link: {
            add: 'Complétez votre profil sur Stripe',
            modify: 'Modifier les informations Stripe',
          },
        },
        iban: {
          title: 'Informations pour les appels offerts',
          subtitle:
            'Pour recevoir les paiements des appels offerts, vous devez nous transmettre vos coordonnées bancaires.',
          label: 'Statut  de votre IBAN',
          status: {
            noIban: 'Aucun IBAN',
            ibanFilled: 'Renseigné',
          },
          link: {
            add: 'Ajouter un IBAN',
            modify: 'Modifier votre IBAN',
          },
        },
        email: {
          label: 'Email de contact de la société',
          placeholder: 'Email de contact de la société',
        },
        phoneNumber: {
          label: 'Téléphone de la société',
          placeholder: 'Téléphone de la société',
        },
      },
    },
    professionalInsurance: {
      title: 'Assurance Professionnelle',
      path: 'Organisation / assurance professionelle',
      form: {
        title: 'Police d’assurance',
        subtitle:
          'Votre société ou vous-même devez être assuré pour être en règle avec les obligations règlementaires édictées par l’ordre et toute la profession.',
        insuranceCompany: {
          label: 'Compagnie d’assurance *',
          placeholder: 'Compagnie d’assurance',
        },
        contractNumber: {
          label: 'Numéro de contrat *',
          placeholder: 'N° de contrat',
        },
        partnership: {
          title: 'bénéficiez de conditions spéciales',
          subtitle: 'Bilan personnalisé, gratuit et confidentiel avec Xavier Guisnet',
        },
      },
    },
  },
  ibanForm: {
    title: 'Enregistrer IBAN',
    titleCMV: 'Enregistrer IBAN (1/2 - Callmyvet)',
    titleStripe: 'Enregistrer IBAN (2/2 - Stripe)',
    btnSave: 'Enregistrer sur Callmyvet',
    btnSaveStripe: 'Enregistrer sur Stripe',
    ibanModalLabelCreate: 'Enregistrez le numéro IBAN et le numéro BIC/SWIFT',
    ibanModalLabelEdit: 'Modifier le numéro IBAN et le numéro BIC/SWIFT',
    subTitleStripe: 'Veuillez de nouveau renseigner votre IBAN afin de valider et confirmer la création de votre compte Stripe Connect. Cette action vous permettra de percevoir le montant de vos consultations.',
    subtitle:
      'En fournissant votre IBAN et en confirmant ce moyen de paiement, vous autorisez Call My Vet SAS et Stripe, notre prestataire de services de paiement, à envoyer des instructions à votre banque pour débiter votre compte conformément à ces instructions. Vous avez droit à un remboursement de votre banque selon les termes et conditions de votre accord avec votre banque. Un remboursement doit être demandé dans les 8 semaines à compter de la date de débit de votre compte.',
    form: {
      name: {
        label: 'Nom du titulaire *',
        placeholder: 'Nom du titulaire du compte',
      },
      ibanNumber: {
        label: 'Numéro IBAN *',
        valid: 'Ce champ est requis',
      },
      bicNumber: {
        label: 'Numéro BIC',
        placeholder: 'Numéro BIC / SWIFT',
      },
    },
    popup: {
      valid: '✅ Votre IBAN a bien été enregistré par Stripe',
      errorLeftButton: 'Contacter le service client',
      error:
        '⚠️ Votre IBAN est erroné, veuillez vérifier votre saisie. Si le problème persiste, veuillez contacter nos équipes.',
    },
  },
  account: {
    title: 'Votre Compte',
    subtitle: 'Informations liées à votre activité',
    form: {
      button: {
        cancel: 'Annuler',
        save: 'Enregistrer  les modifications',
      },
      nameSection: {
        title: 'Nom prénom',
        subtitle:
          'Pour recevoir des appels, actionnez le bouton ci-contre pour qu’il ait une couleur verte.',
        label: 'Nom prénom',
        LinkModifyName: 'Modifier le nom et le prénom',
        modal: {
          title: 'Modifier votre nom et prénom',
          subtitle:
            'Etiam porta sem malesuada magna mollis euismod. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
          firstName: {
            label: 'Nom *',
            placeholder: 'Nom',
          },
          lastName: {
            label: 'Prénom *',
            placeholder: 'Prénom',
          },
        },
      },
      emailSection: {
        title: 'Email du compte',
        subtitle: "Votre compte est lié à votre email, vous ne pouvez pas changer d'email.",
        label: 'Email du compte',
        LinkModifyEmail: 'Modifier l’email',
        modal: {
          title: 'Modifier l’email du compte',
          subtitle:
            'Etiam porta sem malesuada magna mollis euismod. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
          oldEmail: {
            label: 'Ancien email',
            placeholder: '',
          },
          newEmail: {
            label: 'Nouvel email *',
            placeholder: 'Email',
          },
          cancel: 'Annuler',
        },
      },
      passwordSection: {
        title: 'Mot de passe du compte',
        subtitle: "Sécurisez l'accès à votre compte avec un mot de passe.",
        label: 'Mot de passe du compte',
        LinkModifyPassword: 'Modifier le mot de passe',
      },
    },
  },
  resetPasswordForm: {
    title: 'Réinitialiser le mot de passe',
    subtitle: 'Créez un nouveau mot de passe',
    form: {
      fields: {
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
        password: {
          label: 'Saisissez un mot de passe',
          placeholder: 'Mot de passe',
        },
        passwordConfirmation: {
          label: 'Confirmez le mot de passe',
          placeholder: 'Mot de passe',
        },
      },
      errors: {
        setPasswordFailed: "Une erreur est survenue, votre mot de passe n'a pas été créé",
        missingParams: "Une erreur est survenue, le lien n'est pas valide",
        // passwordTooShort: 'Le mot de passe doit être de 8 caractères minimum',
      },
      indication: 'Afin d’accéder à votre compte vous devez réinitialiser votre mot de passe.',
      submit: 'Envoyer',
    },
    link: {
      login: 'Continuer',
    },
  },
  resetPasswordError: {
    title: 'Votre demande à expirée ou est invalide',
    subtitle:
      'Afin de garantir la sécurité de votre compte, nous vous invitons à renouveler votre demande de changement de mot de passe.',
    backToLogin: 'Revenir à l’accueil',
  },
  resetPasswordLoader: {
    title: 'Veuillez patienter',
    subtitle: 'Vérification de l’intégrité de votre demande....',
  },
  resetPasswordSuccess: {
    title: 'Mot de passe réinitialisé',
    subtitle:
      "Votre mot de passe à bien été modifié. Vous allez être redirigé vers l'accueil. Redirection dans 5 secondes...",
  },
  profile: {
    title: 'Profil: {{firstName}} {{lastName}}',
    subtitle: 'Profil personnel et vétérinaire',
    form: {
      button: {
        cancel: 'Annuler',
        save: 'Enregistrer  les modifications',
      },
    },
    personalProfile: {
      title: 'Informations du profil personnel',
      subtitle:
        "Informations liées à votre profil personnel ainsi qu'à vos droits d'accès au sein des organisations.",
      name: {
        title: 'Nom, Prénom',
        subtitle: 'Profil / {{firstName}} {{lastName}} / nom et prénom',
        form: {
          title: 'Votre nom et prénom',
          subtitle: '',
          firstName: {
            label: 'Nom *',
            placeholder: 'Nom',
          },
          lastName: {
            label: 'Prénom *',
            placeholder: 'Prénom',
          },
        },
      },
      rights: {
        title: 'Rôle, accès et droits',
      },
    },
    veterinarianProfile: {
      title: 'Informations du profil vétérinaire',
      subtitle:
        'Vous devez renseigner toutes les informations ci-dessous pour recevoir des appels.',
      proofOfIdentity: {
        title: 'Preuve d’identité',
        subtitle: 'Profil / {{firstName}} {{lastName}} / Preuve d’identité',
        form: {
          title: 'Preuve d’identité',
          subtitle:
            'Afin de vous authentifier auprès de Stripe et de l’ordre nous avons besoin d’une pièce d’identité. Vous pouvez utiliser une carte d’identité (recto + verso) ou bien un passeport (recto uniquement). Document en couleur. Formats acceptés : PNG, JPEG. Faites une photo propre. Contraintes : Pas de PDF, Pas de noir et blanc.',
          nationality: {
            label: 'Nationalité *',
            placeholder: 'Nationalité',
          },
          birthDate: {
            label: 'Date de naissance *',
            placeholder: 'JJ/MM/AAAA',
          },
          idCardFront: {
            label: 'Carte d’identité / Passeport (Recto)*',
            placeholder: 'Importer un fichier',
          },
          idCardBack: {
            label: 'Carte d’identité (Verso)',
            placeholder: 'Importer un fichier',
          },
        },
      },
      profileMedical: {
        title: 'Profil médical',
        subtitle: 'Profil / {{firstName}} {{lastName}} / Profil médical',
        form: {
          title: 'Profil médical',
          subtitle:
            'Nos équipes vérifient à interval régulier la validité des numéros ordinaux renseignés par tous les vétérinaires. C’est pour nous un gage de sérieux et de transparence.',
          ordinalNumber: {
            label: 'Numéro ordinal *',
            placeholder: 'Numéro ordinal',
          },
          registrationDepartment: {
            label: 'Département de votre inscription',
            placeholder: 'Sélectionnez un département',
          },
          school: {
            label: 'Ecole *',
            placeholder: 'Sélectionnez votre école',
          },
          otherSchool: {
            placeholder: "Nom de l'école",
          },
          diplomaGraduationYear: {
            label: 'Année d’obtention du diplôme *',
            placeholder: 'Année d’obtention du diplôme *',
          },
          diplomaIsValidInFrance: {
            label: 'Diplôme valide en France *',
            placeholder: 'Je certifie que j’ai un diplôme valide en France',
          },
        },
      },
      animalsInCare: {
        title: 'Animaux pris en charge',
        subtitle: 'Profil / {{firstName}} {{lastName}} / Animaux pris en charge',
        form: {
          title: 'Animaux pris en charge',
          subtitle: 'Sélectionnez au moins 1 espèce animal',
        },
      },
      skills: {
        title: 'Compétences',
        subtitle: 'Profil / {{firstName}} {{lastName}} / Compétences',
        form: {
          title: 'Compétences préférées',
          subtitle: 'Sélectionnez au moins 1 compétence.',
        },
      },
      insurance: {
        title: 'Assurance',
        subtitle: 'Profil / {{firstName}} {{lastName}} / Assurance',
        form: {
          title: "Police d'assurance",
          subtitle:
            'Vous devez avoir une assurance professionnelle pour pouvoir répondre à des appels sur la plateforme Callmyvet.',
          insuredByCompany: {
            label: 'Assuré par la société ?',
            placeholder: 'L’assurance de la société couvre le vétérinaire qui s’inscrit.',
          },
          insuranceCompany: {
            label: 'Compagnie d’assurance *',
            placeholder: 'Compagnie d’assurance',
          },
          contractNumber: {
            label: 'Numéro de Contrat *',
            placeholder: 'N° de Contrat',
          },
          customerNumber: {
            label: 'Numéro de Client/Assuré',
            placeholder: 'N° de Client/Assuré',
          },
        },
      },
    },
  },
  teams: {
    title: 'Equipe',
    subtitle: 'Organisation / {{companyName}} / Equipe',
    role: {
      admin: 'Admin',
      veterinarian: 'Vétérinaire',
    },
    veterinarianModerationStatus: {
      notCompleted: 'Profil incomplet',
      pendingVerification: 'Vérification',
      verified: 'Profil vérifié',
    },
    companyAccountsModerationStatus: {
      pendingVeterinarian: 'Invitation envoyée',
      pendingCompany: 'Modérer l’invitation',
    },
    members: {
      title: 'Membres de l’équipe',
      subtitle:
        'Complétez votre profil ou invitez un nouveau vétérinaire pour recevoir des appels.',
    },
    invitation: {
      title: 'Inviter un vétérinaire',
      subtitle:
        "Renseignez l'adresse mail du vétérinaire que vous souhaitez inviter dans votre organisation.",
      form: {
        email: {
          placeholder: 'Email du vétérinaire',
        },
        button: {
          submit: 'Envoyer',
        },
      },
      pendingVetModal: {
        header: {
          title: 'Le vétérinaire n’a pas encore répondu à l’invitation',
        },
        content:
          'L’invitation a été envoyée, le vétérinaire n’a pas encore accepté ou n’a pas encore créé son compte.',
        footer: {
          cancelInvitation: 'Annuler l’invitation',
          backButton: 'Fermer la fenêtre',
        },
      },
      pendingCompanyModal: {
        header: {
          title: 'Invitation demandée',
        },
        content:
          'Un vétérinaire que vous n’avez pas invité vous demande d’accéder à votre société par le biais d’un email.',
        footer: {
          declineInvitation: 'Refuser',
          acceptInvitation: 'Accepter',
        },
      },
      deleteVeterinarianModal: {
        header: {
          title: 'Supprimer le membre',
        },
        content:
          'Vous allez couper l’accès au service à {{firstName}} {{lastName}}. Ses données sont sauvegardées en base pour tous les appels effectués. \n\nÊtes-vous sur de vouloir supprimer la relation ?',
        footer: {
          backButton: 'Annuler',
          delete: 'Supprimer',
        },
      },
    },
  },
  videoCalls: {
    title: 'Appels en visio',
    subtitle: 'Informations liées à votre activité',
    firstCallDate: 'Vous avez reçu votre premier appel le {{firstCallDate}}',
    noCall: "Vous n'avez pas encore effectué d'appel en visio",
    currentWeek: {
      title: 'Appels en visio cette semaine',
      see: 'Consulter',
    },
    all: {
      title: 'Appels en visio',
      see: 'Voir les appels',
    },
    detailsCallView: {
      subtitle: 'Détails Appel en visio',
      commitmentQuality: {
        title: '❇️  Engagement qualité',
      },
      observation: {
        title: '✍🏻  Observations',
      },
    },
    annualCallsView: {
      title: 'Année {{year}}',
      subtitle: 'Informations liées à votre activité',
      annualCallsListTitle: 'Liste des appels par mois',
      monthlyCallsListTitle: 'Liste des appels reçus',
    },
  },
  clients: {
    title: 'Liste des clients',
    subtitle: 'Clients de votre clinique',
    allClientsBtn: 'Tous les clients',
    pendingClientsBtn: 'En attente de validation ({{pending_count}})',
    approveBtn: 'Valider',
    rejectBtn: 'Rejeter',
    rejectedState: 'Rejeté',
    approvedState: 'Validé',
    totalClientsCounter: 'Total {{counter}} clienns'
  },
  organisationConsultationPrices: {
    mainTitle: 'Paramétrage des consultations',
    route: 'organisation#Tarifs-des-consultations',
    title: 'TARIFS DES CONSULTATIONS',
    needHelpWatch: 'Besoin d’aide ? Visionnez le',
    needHelpLink: 'tutoriel vidéo',
    needHelpText: 'pour savoir comment paramétrer les prix de vos consultations',
    needHelpNotAdmin : "Les données présentes sur cette page ont été configurées par l’administrateur de votre société. Si vous souhaitez apporter des changements , veuillez le/la contacter.",
    catLabel: 'Cat',
    dogLabel: 'Dog',
    unusualLabel: 'Unusual',
    horseLabel: 'Horse',
    generalLabel: 'General',
    behaviorLabel: 'Behavior',
    ophtalmoLabel: 'Ophtalmo',
    teethLabel: 'Teeth',
    surgeonLabel: 'Surgeon',
    applyAll: 'Appliquer ce prix à tous',
    eveningText: 'SOIRÉE : Multiplier x1.5 le prix, en soirée (20:00 - 22:00)',
    nightText: 'NUIT : Multiplier x2 le prix, la nuit (22:00 - 08:00)',
    weekendText: 'WEEKEND : Multiplier x2 le prix, les weekends ( Ven. 20:00 - Lun. 08:00)',
    saveBtn: 'Enregister',
    cancelBtn: 'Annuler',
    linkText: 'Tarifs des consultations',
    hoursTitle: 'HORAIRES/JOURS SPECIFIQUES',
    tableTitle: 'GESTION DES TARIFS',
    errorTextSave : 'Veuillez renseigner tous les prix manquants avant d’enregistrer.',
    defaultPricesWarning: 'Les prix affichés sont définis par défaut par Callmyvet. Si vous souhaitez modifier les montants, vous pouvez les personnaliser avec vos propres prix',
    defaultPricesTooltip: "Il s'agit d'un prix par défaut défini par CMV"
  },
  mainMenu: {
    overview: 'Vue d´ensemble',
    calls: 'Appels',
    profile: 'Profil',
    team: 'Équipe',
    organization: 'Organisation',
    account: 'Compte'
  },
  organisationAddress: {
    path: 'Organisation / Adresse',
    title: 'Adresse',
    headerTitle: 'Adresse de la société',
    headerSubtitle: 'Renseignez l’adresse du siège de la société',
    mainAdress: 'Adresse ligne 1 *',
    mainPlaceholder: 'Adresse ligne 1',
    secondAdress: 'Adresse ligne 2',
    secondPlaceholderAdress: 'Adresse ligne 2',
    city: 'Ville *',
    cityPlaceholder: 'Ville',
    zipCode: 'Code postal *',
    zipCodePlaceholder: 'Code postal',
  },
  companyLegalStatus: {
    CL: 'Collaborateur libéral',
    EI: 'Entreprise individuelle (Officie en nom propre)',
    EIRL: 'Entreprise individuelle à responsabilité limitée',
    EURL: 'Entreprise unipersonnelle à responsabilité limitée',
    SA: 'Société anonyme',
    SARL: 'Société anonyme à responsabilité limitée',
    SAS: 'Société par actions simplifié',
    SASU: 'Société par actions simplifiée unipersonnelle',
    SC: 'Société civile',
    SCP: 'Société Civile Professionnelle',
    SCRL: 'Société coopérative à responsabilité limitée',
    SELARL: "Société d'exercice libéral à responsabilité limitée",
    SLP: 'Société de libre partenariat',
    SNC: 'Société en nom collectif',
    SPFPL: 'Société de participations financières de professions libérales',
    SPFPL_VRL: 'Société de participations financières de professions libérales de vétérinaire à responsabilité limitée',
    SPFPL_SAS: 'Société de participations financières de professions libérales à forme de S.A.S.',
    SPFPL_RL: 'Société de participations financières de professions libérales à responsabilité limitée',
    SE: 'Société européenne',
    SDE: 'Société de droit étranger',
    I: 'Indépendant',
    AS: 'Associés en Société',
    GE: 'Groupement d’établissements',
  },
  profileMedical: {
    alfort: 'Ecole Nationale Vétérinaire d Alfort',
    toulouse: 'Ecole Nationale Vétérinaire de Toulouse',
    atlantique: 'Ecole Nationale Vétérinaire Nantes Atlantique',
    lyon: 'Ecole Nationale Vétérinaire de Lyon',
    rouen: 'Ecole vétérinaire UniLaSalle Rouen',
    other: 'Autre'
  },
  moderationStatusWarning : {
    missingVerification: 'Des informations obligatoires sont manquantes pour la validation de votre profil/entreprise. Veuillez les compléter afin de pouvoir effectuer vos premières consultations.',
    pendingVerification: 'La validation de votre profil est en cours, cette action peut prendre jusqu´à 24 heures. Un email de confirmation vous sera envoyé afin que vous puissiez effectuer votre première consultation .'
  }
};
