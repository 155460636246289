import React, { Component } from 'react';
import styled from 'styled-components';
// import { FieldProps, FormikProps } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import { ThemeComponents } from '../../../theme/components';
import { CheckBoxForm } from '../CheckBoxForm/CheckBoxForm.component';
import { Props } from './SimpleCheckForm.container';

export class SimpleCheckForm extends Component<Props> {
  public handleChange(checked: boolean, fieldName: string) {
    this.props.form.setFieldValue(fieldName, !checked);
    this.props.form.setFieldTouched(fieldName, true);
  }

  public render() {
    const { form, label, sublabel, placeholderComponent, field } = this.props;
    const error = form.errors[field.name];
    const touched = form.touched[field.name];
    return (
      <SimpleCheckContainer fluid>
        <SimpleCheckRow noGutters>
          <Col xs={12} sm={4}>
            <SimpleCheckLabelContainer>
              <SimpleCheckLabel>{label}</SimpleCheckLabel>
              <SimpleCheckSubLabel>{sublabel}</SimpleCheckSubLabel>
            </SimpleCheckLabelContainer>
          </Col>
          <Col xs={12} sm={8}>
            <SimpleCheck
              {...field}
              error={error}
              touched={touched}
              onClick={() => this.handleChange(field.value, field.name)}
            >
              <SimpleCheckInput
                checked={field.value}
                onChange={() => this.handleChange(field.value, field.name)}
                // label={placeholder && required ? `${placeholder + ' *'}` : placeholder}
                labelComponent={placeholderComponent}
                width={24}
                height={24}
              />
            </SimpleCheck>
            {touched && error && <SimpleCheckError>{error}</SimpleCheckError>}
          </Col>
        </SimpleCheckRow>
      </SimpleCheckContainer>
    );
  }
}

const SimpleCheckContainer = styled(Container)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 16px 0;
  padding: 0;
`;

const SimpleCheckRow = styled(Row)`
  width: 100%;
`;

const SimpleCheck = styled(ThemeComponents.FormSimpleCheck)<{
  error: string;
  touched: boolean;
}>`
  display: flex;
  flex-direction: row;
  color: ${(props) => props.theme.colors.black.lighter};
  border-color: ${(props) => {
    const colorType =
      !!props.error && props.touched
        ? props.theme.colors.red.dark
        : props.theme.colors.white.darker;
    return colorType;
  }};
  font-weight: ${(props) => props.theme.componentObjects.formSimpleCheck.fontWeight};
  font-size: ${(props) => props.theme.componentObjects.formSimpleCheck.fontSize};
  line-height: ${(props) => props.theme.componentObjects.formSimpleCheck.lineHeight};
`;

const SimpleCheckLabelContainer = styled.div`
  margin: 5px 15px 5px 0;
`;

const SimpleCheckLabel = styled(ThemeComponents.FormLabel)``;

const SimpleCheckSubLabel = styled(ThemeComponents.FormSubLabel)``;

const SimpleCheckError = styled(ThemeComponents.FormErrorLabel)``;

const SimpleCheckInput = styled(CheckBoxForm)``;
