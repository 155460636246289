import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../lib/withApolloClient';
import { VideoCalls as VideoCallsComponent } from './VideoCalls.component';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
import { withAppContext } from '../../lib/withContext';

export interface VideoCallListItem {
  id?: number;
  createdAt: Date;
  name: string;
  petCategory: string;
  specie?: string;
  petTrouble: string;
  isFreecall: boolean;
}

export type Props = WithApolloClient<RouteComponentProps>;

export const VideoCalls = compose<Props, RouteComponentProps>(
  withApollo,
  withAppContext
)(VideoCallsComponent);
