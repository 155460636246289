import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { Props } from './ButtonsForm.container';
import { ButtonCmv } from '../../ButtonCmv';
import { ReactComponent as IconSearch } from '../../../theme/icons/IconSearch.svg';

export class ButtonsForm extends Component<Props> {
  render() {
    const { cancelContent, validateContent, onCancel, spinner, disabled } = this.props;
    return (
      <ButtonsRow noGutters>
        <Col xs={6} md={1} className="d-none d-lg-block">
          <BtnContainer>
            <ButtonCmv
              btnSize={'large'}
              btnStyle={'primaryHollow'}
              content={cancelContent}
              onClick={() => onCancel()}
            />
          </BtnContainer>
        </Col>
        <Col xs={12} md={{ span: 5, offset: 6 }}>
          <BtnContainer>
            <ButtonCmv
              type={'submit'}
              btnSize={'large'}
              btnStyle={'primary'}
              content={validateContent}
              spinner={spinner ? spinner : false}
              disabled={disabled ? disabled : false}
            />
          </BtnContainer>
        </Col>
      </ButtonsRow>
    );
  }
}

const ButtonsRow = styled(Row)`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    margin-top: 92px;
    margin-bottom: 92px;
  }
`;

const BtnContainer = styled.div`
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tabletMax}) {
    margin-bottom: 50px;
    margin-top: 50px;
  }
`;
