import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../../lib/withApolloClient';
import { Login as LoginComponent } from './Login.component';
import { RouteComponentProps } from 'react-router-dom';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';

export type ContainerProps = {
  isAdmin?: boolean;
};

export type Props = WithApolloClient<RouteComponentProps & ContainerProps>;

export const Login = compose<Props, ContainerProps>(withApollo, withRouter)(LoginComponent);
