import { SimpleCheckForm as SimpleCheckFormComponent } from './SimpleCheckForm.component';

export type Props = {
  label?: string;
  sublabel?: string;
  placeholderComponent?: React.FunctionComponent;
  required?: boolean;
  form: any;
  field: any;
};

export const SimpleCheckForm = SimpleCheckFormComponent;
