import React, { Component } from 'react';
import styled from 'styled-components';
import { ReactComponent as CmvLogo } from '../../theme/icons/CmvProLogoWhiteTransparent.svg';
import { ReactComponent as CheckIcon } from '../../theme/icons/IconCheckboxTickWhite.svg';
import { i18n } from '../../lib/i18n';
import { Props } from './OnBoardingSideMenu.container';

export class OnBoardingSideMenu extends Component<Props> {
  public render() {
    return (
      <OnBoardingSideMenuContainer>
        <CmvLogoContainer>
          <CmvLogo width={'100%'} height={'100%'} />
          <CmvLogoUnderline />
        </CmvLogoContainer>
        <OnboardingSideItemsContainer>
          <OnboardingSideItemsCol>
            <OnboardingSideItem>
              <OnboardingSideItemText>
                {i18n.t('onboardingSideMenu.items.first')}
              </OnboardingSideItemText>
              <CheckBoxIcon />
            </OnboardingSideItem>
            <OnboardingSideItem>
              <OnboardingSideItemText>
                {i18n.t('onboardingSideMenu.items.second')}
              </OnboardingSideItemText>
              <CheckBoxIcon />
            </OnboardingSideItem>
          </OnboardingSideItemsCol>
          <OnboardingSideItemsCol>
            <OnboardingSideItem>
              <OnboardingSideItemText>
                {i18n.t('onboardingSideMenu.items.third')}
              </OnboardingSideItemText>
              <CheckBoxIcon />
            </OnboardingSideItem>
            <OnboardingSideItem>
              <OnboardingSideItemText>
                {i18n.t('onboardingSideMenu.items.fourth')}
              </OnboardingSideItemText>
              <CheckBoxIcon />
            </OnboardingSideItem>
          </OnboardingSideItemsCol>
        </OnboardingSideItemsContainer>
      </OnBoardingSideMenuContainer>
    );
  }
}

const OnBoardingSideMenuContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.primary.mainColor};
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tabletMax}) {
    display: flex;
    justify-content: center;
  }
`;

const CmvLogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary.subtle};
  padding: 20px 0 40px 0;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tabletMax}) {
    display: none;
  }
`;

const CmvLogoUnderline = styled.div`
  width: 60%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.primary.darker};
`;

const OnboardingSideItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tabletMax}) {
    width: 60%;
    margin: 0;
    padding-top: 28px;
    padding-bottom: 8px;
    flex-direction: row;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    width: 90%;
    & > :nth-child(1) > div > :nth-child(2) {
      margin-right: 20px;
    }
  }
`;

const OnboardingSideItemsCol = styled.div`
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 0;
  }
`;

const OnboardingSideItem = styled.div`
  margin-bottom: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const OnboardingSideItemText = styled.p`
  color: ${(props) => props.theme.colors.white.pure};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.tiny.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.lead.small.fontSize};
  font-family: ${(props) => props.theme.textStyles.typography.lead.small.fontFamily};
  line-height: 15px;
  margin: 0;
  white-space: nowrap;
`;

const CheckBoxIcon = styled(CheckIcon)`
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tabletMax}) {
    margin-left: 10px;
  }
`;
