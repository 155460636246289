import React, { Component } from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

interface Props {
  color: string;
  onClick?: () => void;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconColor?: string;
}

export class CallActionButton extends Component<Props> {
  public render() {
    const Icon = this.props.icon;
    return (
      <StyledButton onClick={this.props.onClick} backgroundcolor={this.props.color}>
        <IconContainer iconColor={this.props.iconColor}>
          <Icon height={20} />
        </IconContainer>
      </StyledButton>
    );
  }
}

const StyledButton = styled(Button)<{ backgroundcolor: string }>`
  &&& {
    height: ${(props) => props.theme.spacing.unit * 6}px;
    width: ${(props) => props.theme.spacing.unit * 6}px;
    border-radius: ${(props) => props.theme.spacing.unit * 3}px;
    padding: 0px;
    margin-left: ${(props) => props.theme.spacing.unit}px;
    margin-right: ${(props) => props.theme.spacing.unit}px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.backgroundcolor};

    :hover,
    :active,
    :focus {
      background-color: ${(props) =>
        props.theme.colors[
          `hover${props.backgroundcolor.charAt(0).toUpperCase() + props.backgroundcolor.slice(1)}`
        ]};
    }
  }
`;

const IconContainer = styled.div<{ iconColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  & path {
    fill: ${(props) => props.iconColor};
  }
`;
