import React, { Component } from 'react';
import { Props } from './DetailsCallView.container';
import styled from 'styled-components';
import { HeaderSectionForm } from '../../../components/Form/HeaderSectionForm';
import { i18n } from '../../../lib/i18n';
import { CallInfoDetailsBox } from '../../Twilio/CallInfoDetailsBox';
import { raceIdItems } from '../../../constants/petRaceAndSpecies';
import { differenceInYears, differenceInMonths } from 'date-fns';
import { loader } from 'graphql.macro';
import { Loader } from '../../../components/Loader';
import { ButtonCmv } from '../../../components/ButtonCmv';

const getAccountVideoCallById = loader('../../../graphql/getAccountVideoCallById.graphql');

const callDateOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

type TarificationProps = 'increased' | 'slightlyIncreased' | 'regular';

// TODO creer les types des entity manquantes ?
interface AccountVideoCall {
  tarification: TarificationProps;
  videoCall: {
    id: string;
    createdAt: Date;
    duration?: string;
    isDeclined?: boolean;
    veterinarianComment?: string;
    uploadedInvoicePdf?: { url: string };
    videoCallAnsweredSurveys?: {
      veterinarianAnsweredSurvey: {
        surveyAnswer: Array<{
          answer: string;
          surveyQuestion: {
            text: string;
          };
        }>;
      };
    };
    videoCallRequest: {
      videoCallIntent: {
        petTrouble: string;
        paymentIntent: {
          price: number;
        };
        freecallIntent: {
          price: number;
        };
        isFreeCall: boolean;
        customer: {
          id: string;
          gender: string;
          account: {
            firstName?: string;
            lastName?: string;
            phoneNumber?: string;
            email?: string;
          };
          billingAddress?: {
            city: string;
            country: string;
          };
        };
        customerPet?: {
          id: string;
          name: string;
          petCategory: string;
          specie?: string;
          race?: string;
          birthDate?: string;
          isFemale?: boolean;
          isSterilized?: boolean;
        };
      };
    };
  };
}

interface State {
  accountVideoCall: AccountVideoCall | undefined;
  isLoading: boolean;
}

export class DetailsCallView extends Component<Props, State> {
  public state: State = {
    accountVideoCall: undefined,
    isLoading: true,
  };

  public async componentDidMount() {
    try {
      const response = await this.props.client.query({
        query: getAccountVideoCallById,
        variables: {
          videoCallId: this.props.videoCallId,
        },
        fetchPolicy: 'network-only',
      });
      // console.log(response);
      this.setState({
        accountVideoCall: response.data.getAccountVideoCallById,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.props.history.push('/video-calls');
      //setError if id doesn t exist ?
    }
  }

  private genderCustomerEmoji(gender?: string) {
    if (gender === 'Madame') {
      return '👩🏼';
    } else if (gender === 'Monsieur') {
      return '👨🏻';
    } else {
      return '🧑🏼';
    }
  }

  private getPetSpecie(
    petCategory?: string,
    specie?: string
  ): { label: string; value: string; emoji: string } | null {
    if (petCategory && petCategory !== 'NAC') {
      const res = raceIdItems.filter((e) => e.value === petCategory);
      if (!res.length) return null;
      return res[0];
    }
    if (!specie) return null;
    const res = raceIdItems.filter((e) => e.value === specie);
    if (!res.length) return null;
    return res[0];
  }

  private getCallType(type?: boolean) {
    switch (type) {
      case true:
        return i18n.t('twilio.callInfoDetails.freeCall');
      case false:
        return i18n.t('twilio.callInfoDetails.paidCall');
      default:
        return '';
    }
  }

  private getCallTarification(tarification?: TarificationProps) {
    if (tarification === 'regular') {
      return i18n.t('twilio.callInfoDetails.regularTarification');
    } else if (tarification === 'slightlyIncreased') {
      return i18n.t('twilio.callInfoDetails.slightlyIncreasedTarification');
    } else if (tarification === 'increased') {
      return i18n.t('twilio.callInfoDetails.increasedTarification');
    } else {
      return i18n.t('twilio.callInfoDetails.unknown');
    }
  }

  private getCallPrice(
    isFreeCall?: boolean,
    paymentIntentPrice?: number,
    freecallIntentPrice?: number
  ) {
    if (isFreeCall) {
      if (freecallIntentPrice)
        return (freecallIntentPrice / 100).toString().replace('.', ',') + '€';
      else return '';
    } else {
      if (paymentIntentPrice) return (paymentIntentPrice / 100).toString().replace('.', ',') + '€';
      else return '';
    }
  }

  private getPetAge(birthDate: string) {
    const date = new Date(birthDate);
    const year = differenceInYears(date, Date.now()) * -1;
    if (year < 1) return differenceInMonths(date, Date.now()) * -1 + ' mois';
    return year + (year === 1 ? ' an' : ' ans');
  }

  private onInvoiceClick() {
    if (this.state.accountVideoCall?.videoCall.uploadedInvoicePdf?.url) {
      window.open(
        this.state.accountVideoCall?.videoCall.uploadedInvoicePdf.url,
        'Facture callmyvet',
        'height=595,width=842,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no'
      );
    }
  }

  public render() {
    if (this.state.isLoading) {
      return (
        <LoaderContainer>
          <Loader inline="centered" active={true} size={'big'} />;
        </LoaderContainer>
      );
    }
    const { petCategory, specie, race, name, birthDate, isSterilized, isFemale } =
      this.state.accountVideoCall?.videoCall.videoCallRequest.videoCallIntent.customerPet || {};

    const petSpecie = this.getPetSpecie(petCategory, specie);

    const { firstName, lastName, phoneNumber, email } =
      this.state.accountVideoCall?.videoCall.videoCallRequest.videoCallIntent.customer.account ||
      {};
    const { gender } =
      this.state.accountVideoCall?.videoCall.videoCallRequest.videoCallIntent.customer || {};
    const { city, country } =
      this.state.accountVideoCall?.videoCall.videoCallRequest.videoCallIntent.customer
        .billingAddress || {};
    const { isFreeCall, petTrouble, paymentIntent, freecallIntent } =
      this.state.accountVideoCall?.videoCall.videoCallRequest.videoCallIntent || {};
    const { duration, veterinarianComment, createdAt, isDeclined } =
      this.state.accountVideoCall?.videoCall || {};

    const { surveyAnswer } =
      this.state.accountVideoCall?.videoCall.videoCallAnsweredSurveys?.veterinarianAnsweredSurvey ||
      {};

    const surveyAnswerCallIsDeclined = [
      {
        label: 'Statut du paiement',
        value: isDeclined ? 'Accepté' : 'Décliné',
      },
    ];

    return (
      <>
        <HeaderContainer>
          <Subtitle>{i18n.t('videoCalls.detailsCallView.subtitle')}</Subtitle>
          <Title>
            {new Date(createdAt ? createdAt : '').toLocaleDateString('fr-FR', callDateOptions)}
          </Title>
          {this.state.accountVideoCall?.videoCall.uploadedInvoicePdf && (
            <InvoiceButton>
              <ButtonCmv
                onClick={() => this.onInvoiceClick()}
                btnSize={'small'}
                btnStyle={'primaryHollow'}
                content={'Facture client'}
              />
            </InvoiceButton>
          )}
        </HeaderContainer>

        <SectionContainer>
          <CallInfoDetailsContainer>
            <CallInfoDetailsBox
              title={`${petSpecie ? petSpecie.emoji : ''} ${i18n.t('twilio.callInfoDetails.pet')}`}
              data={[
                {
                  label: i18n.t('twilio.callInfoDetails.petName'),
                  value: `${name}`,
                },
                {
                  label: i18n.t('twilio.callInfoDetails.specieAndRace'),
                  value: `${
                    petSpecie ? petSpecie.label : i18n.t('twilio.callInfoDetails.unknown')
                  } / ${race ? race : i18n.t('twilio.callInfoDetails.unknown')}`,
                },
                {
                  label: i18n.t('twilio.callInfoDetails.ageAndSex'),
                  value: `${
                    birthDate ? this.getPetAge(birthDate) : i18n.t('twilio.callInfoDetails.unknown')
                  } / ${
                    isFemale
                      ? i18n.t('twilio.callInfoDetails.female')
                      : i18n.t('twilio.callInfoDetails.male')
                  }`,
                },
                {
                  label: i18n.t('twilio.callInfoDetails.sterilized'),
                  value: `${
                    isSterilized
                      ? i18n.t('twilio.callInfoDetails.yes')
                      : i18n.t('twilio.callInfoDetails.no')
                  }`,
                },
              ]}
            />
          </CallInfoDetailsContainer>

          <CallInfoDetailsContainer>
            <CallInfoDetailsBox
              title={`${this.genderCustomerEmoji(gender)} ${i18n.t(
                'twilio.callInfoDetails.owner'
              )}`}
              data={[
                {
                  label: i18n.t('twilio.callInfoDetails.firstNameAndLastName'),
                  value: `${firstName} ${lastName}`,
                },
                {
                  label: i18n.t('twilio.callInfoDetails.phoneNumber'),
                  value: `${phoneNumber}`,
                },
                { label: i18n.t('twilio.callInfoDetails.email'), value: `${email}` },
                {
                  label: i18n.t('twilio.callInfoDetails.cityAndCountry'),
                  value: `${city} / ${country}`,
                },
              ]}
            />
          </CallInfoDetailsContainer>

          <CallInfoDetailsContainer>
            <CallInfoDetailsBox
              title={i18n.t('twilio.callInfoDetails.infos')}
              data={[
                {
                  label: i18n.t('twilio.callInfoDetails.callPurpose'),
                  value: i18n.t('videoCallHandler.videoCallRequest.petTrouble.' + petTrouble),
                },
                {
                  label: this.getCallTarification(this.state.accountVideoCall?.tarification),
                  value: this.getCallPrice(isFreeCall, paymentIntent?.price, freecallIntent?.price),
                },
                {
                  label: i18n.t('twilio.callInfoDetails.callType'),
                  value: this.getCallType(isFreeCall),
                },
                {
                  label: i18n.t('twilio.callInfoDetails.callDuration'),
                  value: duration
                    ? new Date(parseInt(duration) * 1000).toISOString().substr(11, 8)
                    : i18n.t('twilio.callInfoDetails.unknown'),
                },
              ]}
            />
          </CallInfoDetailsContainer>
          <CallInfoDetailsContainer>
            <CallInfoDetailsBox
              title={i18n.t('videoCalls.detailsCallView.commitmentQuality.title')}
              data={
                surveyAnswer
                  ? surveyAnswer
                      .map((e) => {
                        return { label: e.surveyQuestion.text, value: e.answer };
                      })
                      .concat(surveyAnswerCallIsDeclined)
                  : []
              }
            />
          </CallInfoDetailsContainer>
          <ObservationContainer>
            <HeaderSectionForm title={i18n.t('videoCalls.detailsCallView.observation.title')} />
            <ObservationText>{veterinarianComment}</ObservationText>
          </ObservationContainer>
        </SectionContainer>
      </>
    );
  }
}

const HeaderContainer = styled.div`
  padding: 50px 20px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 50px 10px;
  }
`;

const Subtitle = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontSize};
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: ${(props) =>
    props.theme.textStyles.typography.lead.capitalize.regular.letterSpacing};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.heading.h1.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
  ::first-letter {
  }
`;

const SectionContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding: 30px 10px;
  }
`;

const CallInfoDetailsContainer = styled.div`
  margin: 30px 0;
`;

const ObservationContainer = styled.div`
  margin: 30px 0 60px 0;
`;

const ObservationText = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.lead.main.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.lead.main.fontSize};
`;

const LoaderContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const InvoiceButton = styled.div`
  width: 200px;
  margin-top: 27px;
`;
