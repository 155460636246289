import { DatePickerForm as DatePickerFormComponent } from './DatePickerForm.component';

export type Props = {
  name: string;
  label?: string;
  sublabel?: string;
  placeholder?: string;
  required?: boolean;
  form: any;
  field: any;
};

export const DatePickerForm = DatePickerFormComponent;
