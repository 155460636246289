// import memoize from 'lodash.memoize';
// import I18nJS from 'i18n-js';
// import translations from '../translations';

// const translate = memoize(
//   (key, config): string => I18nJS.translate(key, config),
//   (key, config): string => (config ? key + JSON.stringify(config) : key)
// );
// I18nJS.t = translate;

// export const setI18nConfig = (): void => {
//   const languageTag = 'fr';

//   // clear translation cache
//   if (translate.cache.clear) {
//     translate.cache.clear();
//   }

//   // set i18n-js config
//   I18nJS.translations = { [languageTag]: translations[languageTag] };
//   I18nJS.locale = languageTag;
// };

// export const i18n = I18nJS;

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from '../translations';
const browserLanguage = window.navigator.languages ? window.navigator.languages[0] : window.navigator.language;
const languageTag = browserLanguage == ('fr-FR' || 'fr') ? 'fr'
  : browserLanguage == ('en-US' || 'en') ? 'en'
    : 'es';

console.log(languageTag);

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      resources: { [languageTag]: { translation: translations[languageTag] } },
      lng: languageTag,
      fallbackLng: languageTag,

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      // debug: true,
      react: {
        wait: true,
        transSupportBasicHtmlNodes: true,
      },
    },
    (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      // t('key'); // -> same as i18next.t
    }
  );

export const i18n = i18next;
