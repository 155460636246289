import React, { Component } from 'react';
import styled from 'styled-components';
import { Field, Form, Formik } from 'formik';
import { Props } from './SurveyEndCall.container';
import { i18n } from '../../../lib/i18n';
import { MultipleRadioButtonForm } from '../../../components/Form';
import { ButtonCmv } from '../../../components/ButtonCmv';
import { loader } from 'graphql.macro';
import { LongTextInputForm } from '../../../components/Form/LongTextInputForm/LongTextInputForm.component';
import { Loader } from '../../../components/Loader';
import { appContext } from '../../../context/appContext';

const getVeterinarianEndCallSurvey = loader(
  '../../../graphql/getVeterinarianEndCallSurvey.graphql'
);
const endCallProcessPaymentValidationByVeterinarian = loader(
  '../../../graphql/endCallProcessPaymentValidationByVeterinarian.graphql'
);

interface DataForm {
  label: string;
  checked: boolean;
}

interface SurveyQuestion {
  choices: Array<string>;
  id: string;
  order: number;
  subText: string;
  text: string;
  type: string;
}

interface State {
  callValidatedData: Array<DataForm & { value: boolean }>;
  surveyQuestions: Array<SurveyQuestion>;
  surveyId: string;
  formikInitialValues: { [name: string]: Array<DataForm> | string };
  loading: boolean;
}

// interface ValuesForm {
//   callValidated?: Array<DataForm & { value: boolean }>;
//   [name: string]: Array<DataForm> | string;
// }

export class SurveyEndCall extends Component<Props, State> {
  public state: State = {
    callValidatedData: [
      {
        label: 'Valider le paiement',
        checked: false,
        value: true,
      },
      {
        label: 'Décliner le paiement',
        checked: false,
        value: false,
      },
    ],
    surveyQuestions: [],
    surveyId: '',
    formikInitialValues: {},
    loading: true,
  };

  public componentDidMount = async () => {
    try {
      const response = await this.props.client.query({
        query: getVeterinarianEndCallSurvey,
        fetchPolicy: 'network-only',
      });
      // since apollo update, inMemoryCache freez responses
      // so we need to "clone" it before trying to modify
      const survey = JSON.parse(JSON.stringify(response.data.getVeterinarianEndCallSurvey));

      //sort by order
      const newSurveyQuestions = survey.surveyQuestion.sort(
        (a: SurveyQuestion, b: SurveyQuestion) => (a.order < b.order ? -1 : 1)
      );
      const newFormikInitialValues: any = {};

      newSurveyQuestions.forEach((question: SurveyQuestion) => {
        const tmpChoices: Array<DataForm> = [];
        if (question.type !== 'Comment') {
          question.choices.forEach((choice: string) => {
            tmpChoices.push({ label: choice, checked: false });
          });
        }

        if (question.type === 'Comment') {
          newFormikInitialValues[`question${question.id}`] = '';
        } else {
          newFormikInitialValues[`question${question.id}`] = [...tmpChoices];
        }
      });

      this.setState({
        surveyId: survey.id,
        surveyQuestions: newSurveyQuestions,
        formikInitialValues: newFormikInitialValues,
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  validateMMultipleRadioButtonForm = (data: Array<DataForm>): string => {
    if (!data) {
      return i18n.t('surveyEndCall.form.error.required'); /* TODO translation with formik ?? */
    }
    const found = data.find((e) => e.checked);
    if (!found) {
      return i18n.t('surveyEndCall.form.error.required'); //'Séléctionnez une option';
    } else {
      return '';
    }
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public onSubmit = async (values: any) => {
    /* TODO VALUES */
    // console.log(values);
    this.setState({ loading: true });
    const surveyAnswerInput = [];
    for (const [key, value] of Object.entries(values)) {
      if (key !== 'callValidated' && key !== 'veterinarianComment') {
        let surveyQuestion = this.state.surveyQuestions.find(
          (question) => `question${question.id}` === key
        );

        if (Array.isArray(value)) {
          const answer = value.find((v) => v.checked);
          surveyAnswerInput.push({ surveyQuestionId: surveyQuestion?.id, answer: answer?.label });
        } else {
          surveyAnswerInput.push({ surveyQuestionId: surveyQuestion?.id, answer: value });
        }
      }
    }
    const callValidated = values['callValidated']?.find(
      (e: DataForm & { value: boolean }) => e.value === true && e.checked === true
    );

    /* TODO object for clement */
    const payload = {
      videoCallId: Number(this.props.videoCallId),
      callValidated: callValidated ? true : false,
      surveyId: this.state.surveyId,
      surveyAnswerInput,
      veterinarianComment: values.veterinarianComment,
    };
     // console.log('payload = ', payload);
    try {
       await this.props.client.mutate({
        mutation: endCallProcessPaymentValidationByVeterinarian,
        variables: payload,
      });
      // console.log('response = ', response);
      localStorage.setItem('callInProgress', 'false');
      localStorage.setItem('showEndCallModal', 'false');
      localStorage.removeItem('isRemoteParticipantDisconnected')
      this.setState({ loading: false });
      this.context.updateContext();
      if (this.props.onSubmit) this.props.onSubmit();
      else this.props.history.push('Dashboard');
    } catch (error) {
      localStorage.setItem('callInProgress', 'false');
      localStorage.setItem('showEndCallModal', 'false');
      localStorage.removeItem('isRemoteParticipantDisconnected')
      if (this.props.onSubmit) this.props.onSubmit();
      else this.props.history.push('Dashboard');
      //throw new Error(error);
    }
  };

  public render() {
    const surveyQuestions = this.state.surveyQuestions;
    let formikInitialValues = this.state.formikInitialValues;
    formikInitialValues['callValidated'] = this.state.callValidatedData;
    // console.log(formikInitialValues);
    if (this.state.loading) {
      return (
        <LoaderContainer>
          <Loader inline="centered" active={true} size={'big'} />
        </LoaderContainer>
      );
    } else {
      return (
        <SurveyEndCallFormContainer>
          <Formik initialValues={formikInitialValues} onSubmit={this.onSubmit}>
            {() => (
              <Form>
                <FieldContainer>
                  <Field
                    name={'callValidated'}
                    label={i18n.t('surveyEndCall.form.callStatus.label')}
                    sublabel={i18n.t('surveyEndCall.form.callStatus.sublabel')}
                    component={MultipleRadioButtonForm}
                    data={this.state.callValidatedData}
                    validate={this.validateMMultipleRadioButtonForm}
                  />
                </FieldContainer>
                {surveyQuestions &&
                  surveyQuestions.map((question, i) => (
                    <FieldContainer key={i}>
                      {question.type === 'SingleSelect' && (
                        <Field
                          name={`question${question.id}`}
                          label={question.text + ' *'}
                          sublabel={question.subText}
                          component={MultipleRadioButtonForm}
                          data={question.choices.map((choice) => {
                            return { label: choice, checked: false };
                          })}
                          validate={this.validateMMultipleRadioButtonForm}
                        />
                      )}
                      {/* {question.type === 'Comment' && (
                        <Field
                          name={`question${question.id}`}
                          label={question.text}
                          sublabel={question.subText}
                          component={LongTextInputForm}
                          direction={'column'}
                        />
                      )} */}
                    </FieldContainer>
                  ))}
                <FieldContainer>
                  <Field
                    name={`veterinarianComment`}
                    //TODO i18n
                    label={'Message au propriétaire / Observations'}
                    sublabel={
                      'Partagées avec le propriétaire via email, accessible depuis le détail d’un appel, pour tous les furturs vétérinaires.'
                    }
                    component={LongTextInputForm}
                    direction={'column'}
                  />
                </FieldContainer>

                <ButtonContainer>
                  <ButtonCmvContainer>
                    <ButtonCmv
                      type={'submit'}
                      btnSize={'medium'}
                      btnStyle={'primaryHollow'}
                      content={i18n.t('surveyEndCall.form.submit')}
                    />
                  </ButtonCmvContainer>
                </ButtonContainer>
              </Form>
            )}
          </Formik>
        </SurveyEndCallFormContainer>
      );
    }
  }
}

const SurveyEndCallFormContainer = styled.div``;

const FieldContainer = styled.div`
  margin: 45px 0;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
`;

const ButtonCmvContainer = styled.div`
  width: 70%;
  align-self: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const LoaderContainer = styled.div`
  margin: 15px 0;
`;
