import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './MainMenu.container';
import { MainMenuConnected } from './MainMenuConnected';
import { MainMenuNotConnected } from './MainMenuNotConnected';

export class MainMenu extends Component<Props> {
  public render() {
    const { isConnected } = this.props;
    return (
      <MainMenuContainer>
        {isConnected && <MainMenuConnected />}
        {!isConnected && <MainMenuNotConnected />}
      </MainMenuContainer>
    );
  }
}

const MainMenuContainer = styled.div`
  height: 100%;
`;
