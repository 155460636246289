import React, { Component, Fragment } from 'react';
import { Props } from './CreateAccountVerification.container';
import styled from 'styled-components';
import images from '../../../theme/images';
import { MasterBody } from '../../../components/Layout/MasterBody';
import { MasterContainer } from '../../../components/Layout/MasterContainer';
import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { Loader } from '../../../components/Loader';
import { i18n } from '../../../lib/i18n';
import { ImageCmv } from '../../../components/ImageCmv';
import { Firebase } from '../../../lib/firebase';
// import { parse } from 'query-string';
import { ButtonCmv } from '../../../components/ButtonCmv';
import { loader } from 'graphql.macro';
import { ModalCmv } from '../../../components/ModalCmv';
import {
  AccountEmailSentConfirmationBody,
  AccountEmailSentConfirmationFooter,
} from '../../../components/AccountEmailSentConfirmation';

const firebaseAccountEmailVerificationRequest = loader(
  '../../../graphql/firebaseAccountEmailVerificationRequest.graphql'
);
const deleteAccount = loader('../../../graphql/deleteAccount.graphql');

interface State {
  isLoading: boolean;
  status?: boolean;
  errorCode?: string;
  showRequestEmailVerificationSent: boolean;
  showDeleteAccountModal: boolean;
  email?: string;
}

export class CreateAccountVerification extends Component<Props, State> {
  public state: State = {
    isLoading: false,
    status: undefined, // email verified or not
    errorCode: undefined,
    showRequestEmailVerificationSent: false,
    showDeleteAccountModal: false,
    email: undefined,
  };

  public translateErrorToUser = (errorCode: string) => {
    switch (errorCode) {
      case 'auth/argument-error':
        return i18n.t('createAccountVerification.errors.argumentError');
      case 'auth/invalid-action-code':
        return i18n.t('createAccountVerification.errors.invalidActionCode');
      case 'auth/expired-action-code':
        return i18n.t('createAccountVerification.errors.expiredActionCode');
      case 'auth/user-disabled':
        return i18n.t('createAccountVerification.errors.userDisabled');
      case 'auth/user-not-found':
        return i18n.t('createAccountVerification.errors.userNotFound');
      case 'missingParams':
        return i18n.t('createAccountVerification.errors.missingParams');
      default:
        return i18n.t('createAccountVerification.errors.default');
    }
  };

  // private getUrlParams = () => {
  //   const url = this.props.location.search;
  //   const params = parse(url);
  //   return params;
  // };

  public async componentDidMount() {
    // const params: any = this.getUrlParams();
    if (!this.props.location.state?.oobCode || this.props.location.state?.mode !== 'verifyEmail') {
      this.setState({ errorCode: 'missingParams' });
      return;
    }

    try {
      await Firebase.applyActionCode(this.props.location.state?.oobCode);
      // Email address has been verified.

      this.setState({ status: true });
      setTimeout(() => this.props.history.push('create-account-validated'), 5000);
      return;
    } catch (error) {
      // console.log(error);
      const firebaseUser = Firebase.getCurrentUser();
      this.setState({
        status: false,
        errorCode: error.code,
        email: firebaseUser && firebaseUser.email ? firebaseUser.email : undefined,
      });

      return;
      // Code is invalid or expired. Ask the user to verify their email address
      // again.
    }
  }

  private async sendFirebaseAccountEmailVerificationRequest() {
    if (!Firebase.getCurrentUser()) {
      this.props.history.push('SignIn');
      return;
    }

    try {
      await this.props.client.query({
        query: firebaseAccountEmailVerificationRequest,
      });
      this.setState({ showRequestEmailVerificationSent: true });
    } catch (error) {
      console.log(error);
    }
  }

  private async cancelSignUpDeleteAccount() {
    if (!Firebase.getCurrentUser()) {
      this.props.history.push('SignIn');
      return;
    }

    try {
      await this.props.client.mutate({
        mutation: deleteAccount,
      });
      await Firebase.signOut();
      this.setState({ showRequestEmailVerificationSent: false, showDeleteAccountModal: true });
      // this.props.history.push('SignIn');
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }

  public render() {
    return (
      <Fragment>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <MasterContainer noSideMenu={true}>
            {this.state.errorCode && (
              <AccountCreationConfirmationContainer>
                <ImageCmvContainer>
                  <ImageCmv
                    source={images.OnboardingIllustrationAccountError}
                    sourceRetina={images.OnboardingIllustrationAccountError2x}
                  />
                </ImageCmvContainer>
                <AccountCreationConfirmationTitle>
                  {i18n.t('createAccountVerification.errors.title')}
                </AccountCreationConfirmationTitle>
                <AccountCreationConfirmationFirstSection>
                  {this.translateErrorToUser(this.state.errorCode ? this.state.errorCode : '')}
                </AccountCreationConfirmationFirstSection>

                <AccountCreationConfirmationLoader>
                  {this.state.errorCode !== 'auth/user-disabled' &&
                    this.state.errorCode !== 'auth/user-not-found' && (
                      <ButtonCmvContainer>
                        <ButtonCmv
                          btnSize={'giant'}
                          btnStyle={'primary'}
                          content={
                            Firebase.getCurrentUser()
                              ? i18n.t('createAccountVerification.errors.buttonConnected')
                              : i18n.t('createAccountVerification.errors.buttonNotConnected')
                          }
                          onClick={() => this.sendFirebaseAccountEmailVerificationRequest()}
                        />
                      </ButtonCmvContainer>
                    )}
                </AccountCreationConfirmationLoader>
              </AccountCreationConfirmationContainer>
            )}
            {!this.state.errorCode && (
              <AccountCreationConfirmationContainer>
                <ImageCmvContainer>
                  <ImageCmv
                    source={images.OnboardingIllustrationAccountVerification}
                    sourceRetina={images.OnboardingIllustrationAccountVerification2x}
                  />
                </ImageCmvContainer>
                <AccountCreationConfirmationTitle>
                  {i18n.t('createAccountVerification.title')}
                </AccountCreationConfirmationTitle>
                <AccountCreationConfirmationFirstSection>
                  {i18n.t('createAccountVerification.firstSection')}
                </AccountCreationConfirmationFirstSection>
                <AccountCreationConfirmationLoader>
                  <Loader inline="centered" active={true} size={'big'} />
                </AccountCreationConfirmationLoader>
              </AccountCreationConfirmationContainer>
            )}
            <ModalCmv
              show={this.state.showRequestEmailVerificationSent}
              onClickOutsideCloseModal={() => null}
              // headerIconLeft={IconCrossDelete}
              headerTitle={i18n.t('createAccount.confirmation.headerTitle')}
              headerOnClickLeft={() => this.props.history.push('/SignIn')}
              contentViewSubTitle={i18n.t('createAccount.confirmation.subtitle')}
              contentViewImage={images.ModaleIllustrationEmailSent}
              contentViewImageRetina={images.ModaleIllustrationEmailSent2x}
              contentViewCustomComponent={
                <AccountEmailSentConfirmationBody
                  email={this.state.email ? this.state.email : ''}
                />
              }
              footerCustomComponent={
                <AccountEmailSentConfirmationFooter
                  resendEmailLink={() => this.sendFirebaseAccountEmailVerificationRequest()}
                  cancelSignUp={() => this.cancelSignUpDeleteAccount()}
                />
              }
            />
            <ModalCmv
              show={this.state.showDeleteAccountModal}
              onClickOutsideCloseModal={() => null}
              contentViewPreTitle={i18n.t('createAccount.deleteAccount.preTitle')}
              contentViewBodyContent={i18n.t('createAccount.deleteAccount.bodyContent')}
              contentViewImage={images.HugoPageNotFound}
              contentViewImageRetina={images.HugoPageNotFound2x}
              contentViewCustomComponent={
                <ButtonCmvContainer>
                  <ButtonCmv
                    btnSize={'giant'}
                    btnStyle={'primary'}
                    content={i18n.t('createAccount.deleteAccount.buttonLabel')}
                    onClick={() => this.props.history.push('/SignIn')}
                  />
                </ButtonCmvContainer>
              }
              footerHidden={true}
            />
          </MasterContainer>
        </MasterBody>
      </Fragment>
    );
  }
}

const ButtonCmvContainer = styled.div`
  width: 100%;
  margin-top: 20px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 100px;
    padding-right: 100px;
  }
`;

const AccountCreationConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white.pure};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.grey.lighter};
  box-sizing: border-box;
  margin-top: 38px;
  padding-left: 10px;
  padding-right: 10px;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
    padding-left: 180px;
    padding-right: 180px;
  }
`;

const ImageCmvContainer = styled.div`
  width: 120px;
  height: 120px;
  margin-top: 40px;
`;

const AccountCreationConfirmationTitle = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.heading.h4.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h4.fontWeight};
  line-height: 32px;
  text-align: center;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 20px;
`;

const AccountCreationConfirmationFirstSection = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.body.main.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.body.main.fontWeight};
  line-height: 24px;
  color: ${(props) => props.theme.colors.black.lighter};
  text-align: center;
  margin-top: 27px;
  white-space: pre-wrap;
`;

const AccountCreationConfirmationLoader = styled.div`
  margin: 123px 0;
`;
