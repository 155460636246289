import { Header as HeaderComponent } from './Header.component';

export type Props = {
  headerTitle?: string;
  headerLabelLeft?: string;
  headerLabelRight?: string;
  headerIconLeft?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> /* TODO type of icon */;
  headerIconRight?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> /* TODO type of icon */;
  headerOnClickLeft?: () => void;
  headerOnClickRight?: () => void;
};

export const Header = HeaderComponent;
