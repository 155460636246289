import React from 'react';
import { AppContextConsumer, AppContextInterface } from '../context/appContext';

////////
// DO NOT TOUCH THIS FILE, to modify context structure go to src/context/
////////

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export function withAppContext<
  P extends { appContext?: AppContextInterface },
  R = Omit<P, 'appContext'>
>(Component: React.ComponentClass<P> | React.StatelessComponent<P>): React.SFC<R> {
  return function BoundComponent(props: R) {
    return (
      <AppContextConsumer>
        {(value) => <Component {...((props as unknown) as P)} appContext={value} />}
      </AppContextConsumer>
    );
  };
}
