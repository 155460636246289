const colors = {
  //Primary
  //TODO remplacer primary
  primaryDarker: '#224675',
  primaryMainColor: '#1BA3C1',
  primaryLighter: '#5DCDE6',
  primarySubtle: '#DDF3F6',
  //Green
  greenDarker: '#0B8C3A',
  greenDark: '#349F5A',
  greenMain: '#1BC157',
  greenLighter: '#60DD8D',
  greenSubtle: '#DDF6E6',
  //Blue
  blueDarker: '#224675',
  blueDark: '#046697',
  blueMain: '#1BA3C1',
  blueLighter: '#5DCDE6',
  blueSubtle: '#DDF3F6',
  //Yellow
  yellowDarker: '#DD6F0D',
  yellowDark: '#F2994A',
  yellowMain: '#F2C94C',
  yellowLighter: '#FCE396',
  yellowSubtle: '#FCF1D1',
  //Red
  redDarker: '#CB3434',
  redDark: '#EB5757',
  redMain: '#F38A7C',
  redLighter: '#F5BAB1',
  redSubtle: '#FEF4F4',
  //Purple
  purpleDarker: '#4D0099',
  purpleDark: '#6600CC',
  purpleMain: '#AC5DD9',
  purpleLighter: '#DDA5E9',
  purpleSubtle: '#FFE6FF',
  //Pink
  pinkDarker: '#E35E76',
  pinkDark: '#EF5CA3',
  pinkMain: '#EF85B8',
  pinkLighter: '#F9BBD5',
  pinkSubtle: '#FEDEE6',
  //Teal
  tealDarker: '#00B7C4',
  tealDark: '#00CFDE',
  tealMain: '#73DFE7',
  tealLighter: '#A9EFF2',
  tealSubtle: '#E6FFFF',
  //Black
  blackPure: '#000000',
  blackLight: '#262626', //151515
  blackLighter: '#404040',
  //Gray
  greyDarker: '#787878',
  greyDark: '#999999',
  greyMain: '#BFBFBF',
  greyLighter: '#D9D9D9',
  //White
  whiteDarker: '#F0F0F0',
  whiteDark: '#FAFAFA',
  whitePure: '#FFFFFF',
};

const _mainTheme = {
  colors: {
    // hoverPrimary: colors.blueDarker,  TODO delete dependencies
    primary: {
      darker: colors.primaryDarker,
      mainColor: colors.primaryMainColor,
      lighter: colors.primaryLighter,
      subtle: colors.primarySubtle,
    },
    green: {
      darker: colors.greenDarker,
      dark: colors.greenDark,
      main: colors.greenMain,
      lighter: colors.greenLighter,
      subtle: colors.greenSubtle,
    },
    blue: {
      darker: colors.blueDarker,
      dark: colors.blueDark,
      main: colors.blueMain,
      lighter: colors.blueLighter,
      subtle: colors.blueSubtle,
    },
    yellow: {
      darker: colors.yellowDarker,
      dark: colors.yellowDark,
      main: colors.yellowMain,
      lighter: colors.yellowLighter,
      subtle: colors.yellowSubtle,
    },
    red: {
      darker: colors.redDarker,
      dark: colors.redDark,
      main: colors.redMain,
      lighter: colors.redLighter,
      subtle: colors.redSubtle,
    },
    purple: {
      darker: colors.purpleDarker,
      dark: colors.purpleDark,
      main: colors.purpleMain,
      lighter: colors.purpleLighter,
      subtle: colors.purpleSubtle,
    },
    pink: {
      darker: colors.pinkDarker,
      dark: colors.pinkDark,
      main: colors.pinkMain,
      lighter: colors.pinkLighter,
      subtle: colors.pinkSubtle,
    },
    teal: {
      darker: colors.tealDarker,
      dark: colors.tealDark,
      main: colors.tealMain,
      lighter: colors.tealLighter,
      subtle: colors.tealSubtle,
    },
    black: {
      pure: colors.blackPure,
      light: colors.blackLight,
      lighter: colors.blackLighter,
    },
    grey: {
      darker: colors.greyDarker,
      dark: colors.greyDark,
      main: colors.greyMain,
      lighter: colors.greyLighter,
    },
    white: {
      darker: colors.whiteDarker,
      dark: colors.whiteDark,
      pure: colors.whitePure,
    },
  },
  spacing: {
    unit: 8,
    borderRadius: 3,
  },
  //TODO delete in future
  typo: {
    title: {
      fontSize: '40px',
      fontWeight: 600,
      lineHeight: '48px',
    },
    fieldLabel: {
      fontSize: '12px',
      fontWeight: '600',
    },
    optionalFieldLabel: {
      fontSize: '12px',
      fontWeight: '500',
    },
    input: {
      fontSize: '14px',
      fontWeight: '600',
    },
    smallText: {
      fontSize: '12px',
      fontWeight: '600',
    },
    largeText: {
      fontSize: '18px',
      fontWeight: '600',
      lineHeight: '28px',
    },
    veryLargeText: {
      fontSize: '28px',
      fontWeight: '600',
      lineHeight: '36px',
    },
    formSubpartTitle: {
      fontSize: '18px',
      fontWeight: '600',
    },
    formSubpartTitleDescription: {
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '22px',
    },
    checkboxDescription: {
      fontSize: '12px',
      fontWeight: '500',
    },
    formTitle: {
      fontSize: '16px',
      fontWeight: '700',
    },
    formSubtitle: {
      fontSize: '50px',
      fontWeight: '600',
    },
    buttonLabel: {
      fontSize: '16px;',
      fontWeight: '600',
    },
    errorMessage: {
      fontSize: '12px;',
      fontWeight: '600',
    },
    fileUploadPlaceholder: {
      fontSize: '10px',
      fontWeight: '600',
    },
    fileUploadFormatRequirements: {
      fontSize: '8px',
      fontWeight: '600',
    },
  },
  /* New objects for new app design */
  textStyles: {
    typography: {
      display: {
        big: {
          fontFamily: 'Montserrat',
          fontWeight: 700,
          fontSize: '64px',
        },
        main: {
          fontFamily: 'Montserrat',
          fontWeight: 500,
          fontSize: '56px',
        },
        small: {
          fontFamily: 'Montserrat',
          fontWeight: 400,
          fontSize: '48px',
        },
      },
      heading: {
        h1: {
          fontFamily: 'Inter',
          fontWeight: 700,
          fontSize: '40px',
        },
        h2: {
          fontFamily: 'Inter',
          fontWeight: 700,
          fontSize: '32px',
        },
        h3: {
          fontFamily: 'Inter',
          fontWeight: 700,
          fontSize: '28px',
        },
        h4: {
          fontFamily: 'Inter',
          fontWeight: 700,
          fontSize: '24px',
        },
        h5: {
          fontFamily: 'Inter',
          fontWeight: 700,
          fontSize: '20px',
        },
        h6: {
          fontFamily: 'Inter',
          fontWeight: 700,
          fontSize: '16px',
        },
      },
      lead: {
        capitalize: {
          regular: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '14px',
            letterSpacing: '1px',
          },
          bold: {
            fontFamily: 'Inter',
            fontWeight: 700,
            fontSize: '20px',
            letterSpacing: '1px',
          },
        },
        main: {
          fontFamily: 'Inter',
          fontWeight: 700,
          fontSize: '14px',
        },
        small: {
          fontFamily: 'Inter',
          fontWeight: 600,
          fontSize: '12px',
        },
        tiny: {
          fontFamily: 'Inter',
          fontWeight: 600,
          fontSize: '10px',
        },
      },
      body: {
        big: {
          fontFamily: 'Inter',
          fontWeight: 400,
          fontSize: '21px',
        },
        main: {
          fontFamily: 'Inter',
          fontWeight: 400,
          fontSize: '16px',
        },
        small: {
          fontFamily: 'Inter',
          fontWeight: 400,
          fontSize: '14px',
        },
        tiny: {
          fontFamily: 'Inter',
          fontWeight: 400,
          fontSize: '12px',
        },
        quote: {
          fontFamily: 'Inter',
          fontWeight: 600,
          fontSize: '20px',
        },
      },
    },
  },
  componentObjects: {
    formContainer: {
      background: colors.whitePure,
      border: `1px solid ${colors.whiteDarker}`,
      boxSizing: 'border-box',
      borderRadius: '5px',
      padding: '12px 45px 12px 15px',
      fontWeight: '600',
      fontSize: '15px',
      lineHeight: '16px',
      color: colors.blackLight,
      borderFocus: colors.blackLighter,
    },
    formLabel: {
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: colors.blackLight,
    },
    formSubLabel: {
      fontWeight: '500',
      fontSize: '11px',
      lineHeight: '16px',
      color: colors.greyDarker,
    },
    formErrorLabel: {
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '14px',
      color: colors.redDark,
    },
    formSimpleCheck: {
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: '24px',
    },
    formMultipleCheck: {
      fontWeight: '500',
      fontSize: '13px',
      lineHeight: '20px',
      color: colors.blackLight,
      padding: '5px 45px 5px 15px',
      borderChecked: `1px solid ${colors.greenMain}`,
      borderNotChecked: `1px solid ${colors.whiteDarker}`,
      textChecked: colors.greenMain,
      textNotChecked: colors.blackLight,
    },
    formMultipleRadioButton: {
      padding: '20px 0px 20px 15px',
    },
  },
  breakpoints: {
    mobilePortraitMin: '320px',
    mobilePortraitMax: '479px',
    mobileLandscapeMin: '480px',
    mobileLandscapeMax: '767px',
    tabletMin: '768px',
    tabletMax: '991px',
    laptop13Min: '992px',
    laptop13Max: '1199px',
    laptop15Min: '1200px',
    laptop15Max: '1599px',
    desktop21Min: '1600px',
    desktop21Max: '1999px',
    desktop27Min: '2000px',
  },
};

/* Breakpoints */

export interface Theme {
  theme: typeof _mainTheme;
}

export const theme = _mainTheme;
