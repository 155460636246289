import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../lib/withApolloClient';
import { OnboardingAccount as OnboardingAccountComponent } from './OnboardingAccount.component';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

export type ContainerProps = WithApolloClient<RouteComponentProps>;

export type Props = WithApolloClient<RouteComponentProps & ContainerProps>;

export const OnboardingAccount = compose<Props, ContainerProps>(
  withApollo,
  withRouter
)(OnboardingAccountComponent);
