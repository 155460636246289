import React, { Component } from 'react';
import { Props } from './ProfileSkills.container';
import styled from 'styled-components';
import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { MasterBody } from '../../../components/Layout/MasterBody';
import { MasterContainer } from '../../../components/Layout/MasterContainer';
import { HeaderSectionForm } from '../../../components/Form/HeaderSectionForm';
import { i18n } from '../../../lib/i18n';
import { Formik, Field, Form } from 'formik';
import { MultipleCheckForm } from '../../../components/Form';
import { profileSkillsValidationSchema } from './ProfileSkills.validation';
import { ButtonsForm } from '../../../components/Form/ButtonsForm';
import { specialities } from '../../../constants/vetSpecialities';
import { appContext } from '../../../context/appContext';
import { loader } from 'graphql.macro';
// import { Veterinarian, Account } from '../../../types/DbInterface';

import { Loader } from '../../../components/Loader';
import { client } from '../../../graphql/client';
import { Account, Company, Veterinarian } from '../../../types/DbInterface';

const getProfessionalAccountWithRelations = loader('../../../graphql/getProfessionalAccountWithRelations.graphql');

const updateVeterinarianMedicalExpertise = loader(
  '../../../graphql/updateVeterinarianMedicalExpertise.graphql'
);

interface ValuesForm {
  specialities: Array<{ label: string; value: string; checked: boolean }>;
}

export interface State {
  dropdownDataSpecialities: Array<{ label: string; value: string; checked: boolean }>;
  currentVet?: Veterinarian;
  loading?: boolean;
}

export class ProfileSkills extends Component<Props, State> {
  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public state: State = {
    dropdownDataSpecialities: [
      ...specialities.map((e) => {
        return {
          label: i18n.t('videoCallHandler.videoCallRequest.petTrouble.' + e),
          value: e,
          checked: false,
        };
      }),
    ],
    currentVet: undefined,
    loading: true,
  };

  public async componentDidMount() {
    try {
      const response = await client.query<{
        getProfessionalAccountWithRelations?: {
          account: Account;
          veterinarian?: Veterinarian;
          company?: Company;
          companyVeterinarians?: Veterinarian[];
        };
      }>({
        query: getProfessionalAccountWithRelations,
        fetchPolicy: 'network-only',
      });
      this.setState({
        currentVet: response.data.getProfessionalAccountWithRelations?.veterinarian, 
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  private onSubmit = async (values: ValuesForm) => {
    // console.log(values);

    let medicalExpertiseAreas: Array<string> = [];
    values.specialities.forEach((data) => {
      if (data.checked === true) {
        if (!medicalExpertiseAreas) medicalExpertiseAreas = [data.value];
        else medicalExpertiseAreas.push(data.value);
      }
    });

    if (!medicalExpertiseAreas) return; //ERROR

    // console.log(medicalExpertiseAreas);
    try {
      await this.props.client.mutate({
        mutation: updateVeterinarianMedicalExpertise,
        variables: {
          medicalExpertiseAreas,
        },
      });
      this.context.updateContext();
      this.props.history.goBack();
    } catch (error) {
      throw new Error(error);
    }
  };

  render() {
    const account = this.context.currentAccount;
    const currentVet = this.state.currentVet;
    if (this.state.loading) {
      return <LoaderContainer><Loader inline="centered" active={true} size={'big'} /></LoaderContainer>;
    }
    let medicalExpertiseAreas;
    // console.log(currentVet);
    if (currentVet && currentVet.medicalExpertiseAreas) {
      medicalExpertiseAreas = [
        ...this.state.dropdownDataSpecialities.map((e) => {
          return {
            label: e.label,
            value: e.value,
            checked: currentVet?.medicalExpertiseAreas?.find((data) => data === e.value)
              ? true
              : false,
          };
        }),
      ];
    }
    return (
      <>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <MasterContainer noSideMenu={true}>
            <Container>
              <HeaderContainer>
                <Subtitle>
                  {i18n.t('profile.veterinarianProfile.skills.subtitle', {
                    firstName: account?.firstName ? account.firstName : '',
                    lastName: account?.lastName ? account.lastName : '',
                  })}
                </Subtitle>
                <Title>{i18n.t('profile.veterinarianProfile.skills.title')}</Title>
              </HeaderContainer>

              <SectionContainer>
                <HeaderSectionForm
                  title={i18n.t('profile.veterinarianProfile.skills.form.title')}
                  subtitle={i18n.t('profile.veterinarianProfile.skills.form.subtitle')}
                />

                <Formik
                  initialValues={{
                    specialities: medicalExpertiseAreas
                      ? medicalExpertiseAreas
                      : [
                          ...specialities.map((e) => {
                            return {
                              label: `${i18n.t(
                                'videoCallHandler.videoCallRequest.petTrouble.' + e
                              )}`,
                              value: e,
                              checked: false,
                            };
                          }),
                        ],
                  }}
                  onSubmit={this.onSubmit}
                  validationSchema={profileSkillsValidationSchema}
                >
                  {() => (
                    <ProfileSkillsForm>
                      <ProfileSkillsField>
                        <Field
                          name={'specialities'}
                          component={MultipleCheckForm}
                          data={this.state.dropdownDataSpecialities}
                        />
                      </ProfileSkillsField>
                      <ButtonsForm
                        cancelContent={i18n.t('profile.form.button.cancel')}
                        validateContent={i18n.t('profile.form.button.save')}
                        onCancel={() => this.props.history.goBack()}
                        type={'submit'}
                      />
                    </ProfileSkillsForm>
                  )}
                </Formik>
              </SectionContainer>
            </Container>
          </MasterContainer>
        </MasterBody>
      </>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.pure};
  padding-left: 10px;
  padding-right: 10px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 200px;
    padding-right: 200px;
  }
`;

const HeaderContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;

const Subtitle = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.body.small.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.body.small.fontSize};
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileSkillsForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 35px;
`;

const ProfileSkillsField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 0;
  }
`;

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;
