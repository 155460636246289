import { withApollo } from '@apollo/react-hoc';
import { WithApolloClient } from '../../../lib/withApolloClient';
import { AnnualCallsView as AnnualCallsViewComponent } from './AnnualCallsView.component';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
import { withAppContext } from '../../../lib/withContext';
import { VideoCallListItem } from '../VideoCalls.container';
import { ItemsProps } from '../../../components/CustomFlatList/CustomFlatList.container';

type ContainerProps = {
  year: string;
  getCallsListFormated: (videoCallListItems: VideoCallListItem[]) => ItemsProps[];
};

export type Props = WithApolloClient<RouteComponentProps & ContainerProps>;

export const AnnualCallsView = compose<Props, ContainerProps>(
  withApollo,
  withAppContext
)(AnnualCallsViewComponent);
