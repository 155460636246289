import React, { Component } from 'react';
import styled from 'styled-components';
import { Props } from './MasterBody.container';

export class MasterBody extends Component<Props> {
  public render() {
    return (
      <MasterBodyPage noNavBar={this.props.noNavBar}>
        <MainBody>{this.props.children}</MainBody>
      </MasterBodyPage>
    );
  }
}

const MasterBodyPage = styled.div<{ noNavBar: boolean }>`
  width: 100%;
  min-height: 100vh;
  padding-top: ${(props) =>
    props.noNavBar ? '0px' : '90px'}; /* Navbar height + subnavbar height */
  display: flex;
  background-color: ${(props) => props.theme.colors.black.lighter};
  justify-content: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    padding-top: ${(props) => (props.noNavBar ? '0px' : '60px')};
  }
`;

const MainBody = styled.div`
  width: auto;
  display: flex;
  background-color: ${(props) => props.theme.colors.white.dark};
  justify-content: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.laptop13Max}) {
    width: 100%;
  }
`;
