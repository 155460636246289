import React, { Component } from 'react';
import styled from 'styled-components';
import { Props, MainMenuProps } from './SideMenu.container';
import { ReactComponent as IconChevronRightDisclosure } from '../../../theme/icons/IconChevronRightDisclosure.svg';
import { ReactComponent as IconDashboardEnabled } from '../../../theme/icons/IconTabBar-Dashboard-Enabled-30x26.svg';
import { ReactComponent as IconHomeEnabled } from '../../../theme/icons/IconTabBar-HomeEnabled-30x26.svg';
// import { MasterBody } from '../../../components/Layout/MasterBody';

interface State {
  indexSelected: number | null;
  mainMenuItems: Array<MainMenuProps>;
  hash: string;
}
export class SideMenu extends Component<Props, State> {
  public state: State = {
    indexSelected: null,
    hash: '',
    mainMenuItems: [
      {
        title: 'Dashboard',
        endOfSection: true,
        onClick: () => this.props.history.push('/Admin'),
        icon: IconDashboardEnabled,
        selected: false,
        path: '/admin',
      },
      {
        title: 'Vétérinaire',
        onClick: () => this.props.history.push('/Admin#veterinarian-moderation'),
        icon: IconHomeEnabled,
        selected: false,
        path: '/admin#veterinarian-moderation',
      },
      {
        title: 'Compagnie',
        onClick: () => this.props.history.push('/Admin#company-moderation'),
        icon: IconHomeEnabled,
        selected: false,
        path: '/admin#company-moderation',
      },
      {
        title: 'Écoles vétérinaires',
        onClick: () => this.props.history.push('/Admin#veterinary-school'),
        icon: IconHomeEnabled,
        selected: false,
        path: '/admin#veterinary-school',
      },
    ],
  };

  public componentDidMount() {
    const pathname = this.props.history.location.pathname + this.props.history.location.hash;
    // console.log('pathname = ', pathname);
    const index = this.state.mainMenuItems.map((e) => e.path).indexOf(pathname.toLowerCase());
    if (index > -1) {
      this.setState({ indexSelected: index });
    }
  }

  public render() {
    return (
      <MainContainer>
        <MainMenuConnectedContainer>
          {this.state.mainMenuItems.map((item, i) => {
            // const IconItem = styled(item.icon)``;
            return (
              <MainMenuConnectedItem
                key={i}
                endOfSection={item.endOfSection}
                onClick={() => {
                  this.setState({ indexSelected: i });
                  item.onClick();
                }}
                selected={this.state.indexSelected === i}
              >
                <SideMenuNotConnectedItemlLeft>
                  {/* <IconItem /> */}
                  <MainMenuConnectedItemTitle>{item.title}</MainMenuConnectedItemTitle>
                </SideMenuNotConnectedItemlLeft>
                <MainMenuConnectedItemRight>
                  <IconChevronRightDisclosure />
                </MainMenuConnectedItemRight>
              </MainMenuConnectedItem>
            );
          })}
        </MainMenuConnectedContainer>
      </MainContainer>
    );
  }
}

const MainContainer = styled.div`
  height: 100%;
`;

const MainMenuConnectedContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.darker};
  padding-top: 20px;
`;

const MainMenuConnectedItem = styled.div<{ selected: boolean; endOfSection?: boolean }>`
  width: 100%;
  padding: 10px 20px;
  border: none;
  /* background: ${(props) => props.theme.colors.white.dark}; */
  box-shadow: inset 0px -1px 0px rgba(23, 23, 23, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  margin-bottom: ${(props) => (props.endOfSection ? '20px' : 0)};
  color: ${(props) =>
    props.selected ? props.theme.colors.white.pure : props.theme.colors.black.pure};
  background: ${(props) =>
    props.selected ? props.theme.colors.primary.mainColor : props.theme.colors.white.dark};

  & > * {
    stroke: ${(props) =>
      props.selected ? props.theme.colors.white.pure : props.theme.colors.black.light};
    color: ${(props) =>
      props.selected ? props.theme.colors.white.pure : props.theme.colors.black.light};
  }
  /* :focus {
    outline: none;
    color: ${(props) => props.theme.colors.white.pure};
    & > * {
      stroke: ${(props) => props.theme.colors.white.pure};
    }
  } */
`;

const SideMenuNotConnectedItemlLeft = styled.div`
  display: flex;
  align-items: center;
`;

const MainMenuConnectedItemTitle = styled.div`
  margin-left: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

const MainMenuConnectedItemRight = styled.div``;
