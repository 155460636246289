import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { loader } from 'graphql.macro';
import { client } from '../../../graphql/client';
import { Props } from './VeterinarySchoolModeration.container';
import styled from 'styled-components';
import { VeterinarySchool } from '../../../types/DbInterface';
import { Formik, Field, Form } from 'formik';
import {
  TextInputForm,
  ImportFileForm,
  DatePickerForm,
  DropdownForm,
} from '../../../components/Form';
import { ButtonsForm } from '../../../components/Form/ButtonsForm';
import { i18n } from '../../../lib/i18n';
import { Loader } from '../../../components/Loader';

const findAllVeterinarySchool = loader('../../../graphql/findAllVeterinarySchool.graphql');
const updateVeterinarySchool = loader('../../../graphql/updateVeterinarySchool.graphql');

interface ValuesForm {
  veterinarianSchoolInput: {
    id: string;
    name: string;
    acronym: string;

    foundationDate: Date | string;
    country: string;
    city: string;
    websiteUrl: string;
    moderationStatus: 'INVALID' | 'PENDING' | 'VALID';
  };
  logoFile: any;
}

interface State {
  veterinarySchoolVerifiedModerationList: VeterinarySchool[];
  veterinarySchoolNotCompletedModerationList: VeterinarySchool[];
  veterinarySchoolPendingModerationList: VeterinarySchool[];
  selectedVeterinarySchool?: VeterinarySchool;
  loading: boolean;
  showVeterinarySchoolPendingList: boolean;
  showVeterinarySchoolVerifiedList: boolean;
  showVeterinarySchoolNotCompletedList: boolean;
}

export class VeterinarySchoolModeration extends Component<Props, State> {
  public state: State = {
    veterinarySchoolVerifiedModerationList: [],
    veterinarySchoolNotCompletedModerationList: [],
    veterinarySchoolPendingModerationList: [],
    selectedVeterinarySchool: undefined,
    loading: false,
    showVeterinarySchoolPendingList: true,
    showVeterinarySchoolVerifiedList: true,
    showVeterinarySchoolNotCompletedList: true,
  };

  private formikRef: any;

  async componentDidMount() {
    await this.refreshVeterinarySchoolModerationList();
  }

  private storeVeterinarySchool = (veterinarySchool: VeterinarySchool) => {
    const newVeterinarySchoolPendingModerationList = this.state
      .veterinarySchoolPendingModerationList;
    const newVeterinarySchoolVerifiedModerationList = this.state
      .veterinarySchoolVerifiedModerationList;
    const newVeterinarySchoolNotCompletedModerationList = this.state
      .veterinarySchoolNotCompletedModerationList;
    switch (veterinarySchool.moderationStatus) {
      case 'PENDING':
        newVeterinarySchoolPendingModerationList.push(veterinarySchool);
        this.setState({
          veterinarySchoolPendingModerationList: newVeterinarySchoolPendingModerationList,
        });
        break;
      case 'VALID':
        newVeterinarySchoolVerifiedModerationList.push(veterinarySchool);
        this.setState({
          veterinarySchoolVerifiedModerationList: newVeterinarySchoolVerifiedModerationList,
        });
        break;
      case 'INVALID':
        newVeterinarySchoolNotCompletedModerationList.push(veterinarySchool);
        this.setState({
          veterinarySchoolNotCompletedModerationList: newVeterinarySchoolNotCompletedModerationList,
        });
        break;
    }
  };

  public refreshVeterinarySchoolModerationList = async () => {
    let result;
    try {
      result = await client.query({
        query: findAllVeterinarySchool,
        fetchPolicy: 'network-only',
      });
      if (result && result.data && result.data.findAllVeterinarySchool)
        result.data.findAllVeterinarySchool.forEach((element: VeterinarySchool) =>
          this.storeVeterinarySchool(element)
        );
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  private onSubmit = async (values: ValuesForm) => {
    try {
      const response = await this.props.client.mutate({
        mutation: updateVeterinarySchool,
        variables: {
          veterinarianSchoolInput: values.veterinarianSchoolInput,
          logoFile: values.logoFile[0].file,
        },
      });
      if (response.data) {
        this.setState({
          loading: false,
          selectedVeterinarySchool: undefined,
          veterinarySchoolVerifiedModerationList: [],
          veterinarySchoolNotCompletedModerationList: [],
          veterinarySchoolPendingModerationList: [],
        });
        this.refreshVeterinarySchoolModerationList();
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  public renderVeterinarySchoolRowItem(data: VeterinarySchool, i: number) {
    return (
      <ConfigContainer key={data.id}>
        <ItemLeftLabel>{data.name}</ItemLeftLabel>
        <button onClick={() => this.setState({ selectedVeterinarySchool: data })}>Go To</button>
      </ConfigContainer>
    );
  }

  public renderVeterinarySchool(veterinarySchool: VeterinarySchool) {
    return (
      <>
        <button onClick={() => this.setState({ selectedVeterinarySchool: undefined })}>
          Go back
        </button>
        <Formik
          ref={(formikComponent) => (this.formikRef = formikComponent)}
          initialValues={{
            veterinarianSchoolInput: {
              id: veterinarySchool.id ? veterinarySchool.id : '',
              name: veterinarySchool.name ? veterinarySchool.name : '',
              acronym: veterinarySchool.acronym ? veterinarySchool.acronym : '',

              foundationDate: veterinarySchool.foundationDate
                ? new Date(veterinarySchool.foundationDate)
                : '',
              country: veterinarySchool.country ? veterinarySchool.country : '',
              city: veterinarySchool.city ? veterinarySchool.city : '',
              websiteUrl: veterinarySchool.websiteUrl ? veterinarySchool.websiteUrl : '',
              moderationStatus: veterinarySchool.moderationStatus
                ? veterinarySchool.moderationStatus
                : 'INVALID',
            },
            logoFile: '', // replace by client file upload
          }}
          onSubmit={this.onSubmit}
          // validationSchema={profileSkillsValidationSchema}
        >
          {({values, setFieldValue}) => (
            <ModerationForm>
              <CategoryContainer>
                <CategoryTitle>{'legal Infos'}</CategoryTitle>
              </CategoryContainer>
              <ModerationField>
                <Field
                  label={'id'}
                  disabled
                  name={'veterinarianSchoolInput.id'}
                  component={TextInputForm}
                  // data={}
                  placeholder={'id'}
                />
                <Field
                  label={'Nom'}
                  name={'veterinarianSchoolInput.name'}
                  component={TextInputForm}
                  // data={false}
                  placeholder={'name'}
                />
                <Field
                  label={'Acronym'}
                  name={'veterinarianSchoolInput.acronym'}
                  component={TextInputForm}
                  // data={false}
                  placeholder={'acronym'}
                />
                <Field
                  name={'logoFile'}
                  label={'logo'}
                  placeholder={i18n.t(
                    'profile.veterinarianProfile.proofOfIdentity.form.idCardFront.placeholder'
                  )}
                  component={ImportFileForm}
                  id="veterinarySchool-logoFile"
                />
                {veterinarySchool.logoFile && <Image src={veterinarySchool.logoFile.url} />}
                <Field
                  name={'veterinarianSchoolInput.foundationDate'}
                  label={'foundation Date'}
                  placeholder={'dd/mm/yyyy'}
                  component={DatePickerForm}
                />
                <Field
                  label={'Country'}
                  name={'veterinarianSchoolInput.country'}
                  component={TextInputForm}
                  // data={false}
                  placeholder={'country'}
                />
                <Field
                  label={'City'}
                  name={'veterinarianSchoolInput.city'}
                  component={TextInputForm}
                  // data={false}
                  placeholder={'city'}
                />
                <Field
                  label={'Website Url'}
                  name={'veterinarianSchoolInput.websiteUrl'}
                  component={TextInputForm}
                  // data={false}
                  placeholder={'websiteUrl'}
                />
                <Field
                  name={'veterinarianSchoolInput.moderationStatus'}
                  label={'Moderation status'}
                  placeholder={'choisissez un status'}
                  component={DropdownForm}
                  data={[
                    { label: 'INVALID', value: 'INVALID' },
                    { label: 'PENDING', value: 'PENDING' },
                    { label: 'VALID', value: 'VALID' },
                  ]}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    // Do the logic needed for each case
                    setFieldValue('veterinarianSchoolInput.moderationStatus', event.target.value);
                  }}
                  value={veterinarySchool.moderationStatus ? veterinarySchool.moderationStatus : ''}
                />
              </ModerationField>
              <ButtonsForm
                cancelContent={i18n.t('profile.form.button.cancel')}
                validateContent={i18n.t('profile.form.button.save')}
                onCancel={() => this.setState({ selectedVeterinarySchool: undefined })}
                type={'submit'}
              />
            </ModerationForm>
          )}
        </Formik>
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader inline="centered" active={true} size={'big'} />;
    return (
      <>
        <Card>
          <CardHeader title="Company Moderation" />
        </Card>
        {this.state.selectedVeterinarySchool &&
          this.renderVeterinarySchool(this.state.selectedVeterinarySchool)}
        {!this.state.selectedVeterinarySchool && (
          <>
            <StyledCard>
              <CardHeader
                title={
                  (this.state.showVeterinarySchoolPendingList ? '▾ ' : '▸ ') +
                  `🟡  VeterinarySchools Pending : ${
                    this.state.veterinarySchoolPendingModerationList &&
                    this.state.veterinarySchoolPendingModerationList.length
                  }`
                }
                onClick={() =>
                  this.setState({
                    showVeterinarySchoolPendingList: !this.state.showVeterinarySchoolPendingList,
                  })
                }
              />
              {this.state.showVeterinarySchoolPendingList &&
                this.state.veterinarySchoolPendingModerationList.map((data, i) =>
                  this.renderVeterinarySchoolRowItem(data, i + 1)
                )}
            </StyledCard>
            <StyledCard>
              <CardHeader
                title={
                  (this.state.showVeterinarySchoolVerifiedList ? '▾ ' : '▸ ') +
                  `🟢 VeterinarySchools Valid : ${
                    this.state.veterinarySchoolVerifiedModerationList &&
                    this.state.veterinarySchoolVerifiedModerationList.length
                  }`
                }
                onClick={() =>
                  this.setState({
                    showVeterinarySchoolVerifiedList: !this.state.showVeterinarySchoolVerifiedList,
                  })
                }
              />
              {this.state.showVeterinarySchoolVerifiedList &&
                this.state.veterinarySchoolVerifiedModerationList.map((data, i) =>
                  this.renderVeterinarySchoolRowItem(data, i + 1)
                )}
            </StyledCard>
            <StyledCard>
              <CardHeader
                title={
                  (this.state.showVeterinarySchoolNotCompletedList ? '▾ ' : '▸ ') +
                  `🔴 VeterinarySchools Invalid : ${
                    this.state.veterinarySchoolNotCompletedModerationList &&
                    this.state.veterinarySchoolNotCompletedModerationList.length
                  }`
                }
                onClick={() =>
                  this.setState({
                    showVeterinarySchoolNotCompletedList: !this.state
                      .showVeterinarySchoolNotCompletedList,
                  })
                }
              />
              {this.state.showVeterinarySchoolNotCompletedList &&
                this.state.veterinarySchoolNotCompletedModerationList.map((data, i) =>
                  this.renderVeterinarySchoolRowItem(data, i + 1)
                )}
            </StyledCard>
          </>
        )}
      </>
    );
  }
}

const StyledCard = styled(Card)`
  && {
    margin-top: 25px;
  }
`;

const Image = styled.img`
  height: 50%;
  width: 50%;

  @media screen and (max-width: 479px) {
    max-width: 320px;
  }
`;

const ModerationField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
`;

const ModerationForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  margin-top: 35px;
`;

// const Value = styled.div``;

const CategoryContainer = styled.div`
  flex-direction: row;
  display: flex;
`;

const CategoryTitle = styled.div`
  padding-left: 10px;
  font-family: ${(props) => props.theme.textStyles.typography.heading.h1.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 22px;
  color: ${(props) =>
    props.leftLabelColor ? props.leftLabelColor : props.theme.colors.black.light};
  padding-top: 24px;
  padding-bottom: 24px;
`;

const ItemLeftLabel = styled.div<{ leftLabelColor?: string }>`
  font-family: ${(props) => props.theme.textStyles.typography.heading.h6.fontFamily};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h6.fontWeight};
  line-height: 22px;
  color: ${(props) =>
    props.leftLabelColor ? props.leftLabelColor : props.theme.colors.black.light};
  padding-left: 18px;
  width: calc(100% - 400px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ConfigContainer = styled.div`
  flex-direction: row;
  width: 100%;
  height: 60px;
  background-color: ${(props) => props.theme.colors.white.pure};
  border: 1px solid ${(props) => props.theme.colors.white.darker};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 22px;
  cursor: pointer;
`;
