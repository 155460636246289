import React, { Component } from 'react';
import { Firebase } from './lib/firebase';
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql/client';
import * as pages from './pages/';
import { MuiThemeProvider, getMuiTheme, lightBaseTheme } from 'material-ui/styles';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { i18n } from './lib/i18n';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
// import { RESPONSIVE_NOT_AVAILABLE_URL } from './constants';
// import { withTracker } from './lib/withTracker.jsx';
import { Account, Veterinarian, Company, PendingVideoCall } from './types/DbInterface';
import { appContext, AppContextProvider } from './context/appContext';
import { loader } from 'graphql.macro';
import { VideoCallHandler } from './components/VideoCallHandler';
import * as Sentry from '@sentry/browser';
import { ModalCmv } from './components/ModalCmv';
import { SurveyEndCall } from './pages/Twilio/SurveyEndCall';

// const getVeterinarian = loader('./graphql/getVeterinarian.graphql');
// should be rename to getAccount ? byFirebasUid ?
const getProfessionalAccountWithRelations = loader(
  './graphql/getProfessionalAccountWithRelations.graphql'
);

const isAdmin = loader('./graphql/isAdmin.graphql');

interface State {
  isFirebaseInitialized: boolean;
  firebaseUser?: any;
  // veterinarianAvailability: boolean; //attempt to use it through context for subscription
  currentVet?: Veterinarian;
  currentAccount?: Account;
  currentCompany?: Company;
  companyVeterinarians?: Veterinarian[];
  pendingVideoCall?: PendingVideoCall;
  companyCode?: Company;
}

export default class App extends Component<unknown, State> {
  public constructor(props: any) {
    super(props);
    // setI18nConfig();
  }

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public state: State = {
    firebaseUser: undefined,
    isFirebaseInitialized: false,
  };

  public updateCurrentVet(currentVet: Veterinarian) {
    this.setState({ currentVet });
  }

  public updateCurrentAccount(currentAccount: Account) {
    this.setState({ currentAccount });
  }

  public updateCompanyCode(companyCode: Company) {
    this.setState({ companyCode });
  }

  public updateCurrentCompany(currentCompany: Company) {
    this.setState({ currentCompany });
  }

  public getIsCompanyAdminByAccountId(accountId?: string) {
    if (this.state.currentCompany?.companyRepresentative.id === accountId) {
      return true;
    }
    return false;
  }

  public getVeterinarianFromListById(accountId?: string) {
    return this.state.companyVeterinarians?.find((vet) => vet.account.id === accountId);
  }

  private async accountIsAdmin() {
    try {
      const response = await client.query<{ isAdmin?: boolean }>({ query: isAdmin });

      // console.log('response.data.isAdmin = ', response.data.isAdmin);
      return response.data.isAdmin;
    } catch (error) {
      return false;
    }
  }
  // public accountHasAnyVet() {
  //   if (this.state.currentCompany?.) {
  //     return true;
  //   }
  //   return false;
  // }

  private redirectToSignIn = () => <Redirect to="/SignIn" />;
  private redirectToEmailVerification = () => <Redirect to="/create-account-verification" />;
  private redirectToDashboard = () => <Redirect to="/Dashboard" />;
  private redirectToOnBoarding = () => <Redirect to="/OnBoarding" />;

  public async componentDidMount() {
    // Firebase.onAuthStateChanged(() => this.setState({ isFirebaseInitialized: true }));
    Firebase.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const getAccountWithRelation = await this.getAccountWithRelation();
          if (getAccountWithRelation) {
            // const currentAccount = await this.getAccount();
            this.setState({
              isFirebaseInitialized: true,
              firebaseUser: user,
              currentAccount: getAccountWithRelation.account,
              currentVet: getAccountWithRelation.veterinarian,
              currentCompany: getAccountWithRelation.company,
              companyVeterinarians: getAccountWithRelation.companyVeterinarians,
              pendingVideoCall: getAccountWithRelation.pendingVideoCall,
              companyCode: getAccountWithRelation.company,
            });
          } else {
            this.setState({
              isFirebaseInitialized: true,
              firebaseUser: user,
              currentAccount: undefined,
              currentVet: undefined,
              currentCompany: undefined,
              companyVeterinarians: undefined,
              pendingVideoCall: undefined,
              companyCode: undefined,
            });
            await Firebase.signOut();
          }
        } catch (error) {
          console.log(error);
          Sentry.captureMessage(error);
          await Firebase.signOut();
        }
      } else {
        this.setState({
          isFirebaseInitialized: true,
          firebaseUser: undefined,
          currentAccount: undefined,
          currentVet: undefined,
          currentCompany: undefined,
          companyVeterinarians: undefined,
          pendingVideoCall: undefined,
          companyCode: undefined,
        });
      }
    });
  }

  private async getAccountWithRelation() {
    try {
      const response = await client.query<{
        getProfessionalAccountWithRelations?: {
          account: Account;
          veterinarian?: Veterinarian;
          company?: Company;
          companyVeterinarians?: Veterinarian[];
          pendingVideoCall?: PendingVideoCall;
        };
      }>({
        query: getProfessionalAccountWithRelations,
        fetchPolicy: 'network-only',
      });
      // console.log(
      //   'response.data.getProfessionalAccountWithRelations = ',
      //   response.data.getProfessionalAccountWithRelations
      // );
      return response.data.getProfessionalAccountWithRelations;
    } catch (error) {
      console.log(error);
      Sentry.captureMessage(error);
      // throw new Error(error);
    }
  }

  private async updateContext() {
    try {
      const getAccountWithRelation = await this.getAccountWithRelation();
      if (getAccountWithRelation) {
        // const currentAccount = await this.getAccount();
        this.setState({
          isFirebaseInitialized: true,
          currentAccount: getAccountWithRelation.account,
          currentVet: getAccountWithRelation.veterinarian,
          currentCompany: getAccountWithRelation.company,
          companyVeterinarians: getAccountWithRelation.companyVeterinarians,
          companyCode: getAccountWithRelation.company,
        });
      } else {
        this.setState({
          isFirebaseInitialized: true,
          currentAccount: undefined,
          currentVet: undefined,
          currentCompany: undefined,
          companyVeterinarians: undefined,
          companyCode: undefined,
        });
        // await Firebase.signOut();
      }
    } catch (error) {
      console.log(error);
    }
  }

  private renderNotConnectedRoutes = () => {
    return (
      <Switch>
        {/* rename login component to SignIn */}
        <Route exact path="/SignIn" component={pages.Login} />
        <Route exact path={'/create-account'} component={pages.CreateAccount} />
        <Route exact path="/firebase-account" component={pages.FirebaseAccount} />
        <Route path={'/create-account-verification'} component={pages.CreateAccountVerification} />
        <Route exact path={'/create-account-validated'} component={pages.CreateAccountValidated} />
        <Route exact path="/reset-password-request" component={pages.ResetPasswordRequest} />
        <Route exact path="/reset-password-form" component={pages.ResetPasswordForm} />

        {/* For test purpose - form input components */}
        {/* <Route exact path="/test" component={pages.Test} /> */}

        <Route path="/*" render={this.redirectToSignIn} />
      </Switch>
    );
  };

  private renderEmailNotVerifiedRoutes = () => {
    return (
      <Switch>
        <Route exact path={'/create-account'} component={pages.CreateAccount} />
        <Route exact path="/firebase-account" component={pages.FirebaseAccount} />
        <Route
          exact
          path={'/create-account-verification'}
          component={pages.CreateAccountVerification}
        />
        <Route exact path={'/create-account-validated'} component={pages.CreateAccountValidated} />
        {/* Maybe not needed */}
        <Route exact path="/reset-password-request" component={pages.ResetPasswordRequest} />
        <Route exact path="/reset-password-form" component={pages.ResetPasswordForm} />
        <Route path={'/*'} render={this.redirectToEmailVerification} />
      </Switch>
    );
  };

  private renderOnBoardingRoutes = () => {
    return (
      <Switch>
        <Route exact path="/onboarding/vet-company" component={pages.OnboardingVetCompany} />
        <Route exact path="/onboarding/veterinarian" component={pages.OnboardingVeterinarian} />
        <Route exact path="/onboarding" component={pages.OnboardingAccount} />
        <Route path={'/*'} render={this.redirectToOnBoarding} />
        {/* Maybe needed */}
        <Route exact path="/firebase-account" component={pages.FirebaseAccount} />
        <Route exact path="/reset-password-request" component={pages.ResetPasswordRequest} />
        <Route exact path="/reset-password-form" component={pages.ResetPasswordForm} />
      </Switch>
    );
  };

  // TODO modify url for same typo (min, maj, - , _ , ...)
  private renderRoutes = () => {
    // let firebaseUser = Fireba;
    if (!this.state.firebaseUser) {
      // console.log('no account, no firebaseUser');
      return this.renderNotConnectedRoutes();
    }

    // const firebaseUser = Firebase.getCurrentUser();

    if (!this.state.firebaseUser.emailVerified) {
      // console.log('no firebaseUser email verified');
      return this.renderEmailNotVerifiedRoutes();
    }

    if (this.state.currentAccount && !this.state.currentCompany) {
      // console.log('account but no company');
      return this.renderOnBoardingRoutes();
    }

    // console.log('ok account have company');
    return (
      <Switch>
        <Route exact path="/Twilio" component={pages.Twilio} />
        {this.state.currentAccount?.permissions?.find(
          (permission) => permission === 'CMV_ADMIN'
        ) ? (
          <Route exact path="/Admin" component={pages.Admin} />
        ) : undefined}
        <Route exact path="/Dashboard" component={pages.Dashboard} />
        <Route exact path="/organisation" component={pages.Organisation} />
        <Route exact path="/teams" component={pages.Teams} />
        {/* <Route
          exact
          path="/organisation/legal-information"
          component={pages.OrganisationLegalInformation}
        />
        <Route exact path="/organisation/vat" component={pages.OrganisationVat} />
        <Route exact path="/organisation/address" component={pages.OrganisationAddress} />
        <Route exact path="/organisation/contact" component={pages.OrganisationContact} />
        <Route
          exact
          path="/organisation/professional-insurance"
          component={pages.OrganisationProfessionalInsurance}
        />
        <Route
          exact
          path="/organisation/banking-information"
          component={pages.OrganisationBankingInformation}
        /> */}
        <Route exact path="/firebase-account" component={pages.FirebaseAccount} />
        <Route exact path="/reset-password-request" component={pages.ResetPasswordRequest} />
        <Route exact path="/reset-password-form" component={pages.ResetPasswordForm} />
        <Route exact path="/account" component={pages.Account} />
        <Route exact path="/profile" component={pages.Profile} />
        <Route exact path="/video-calls" component={pages.VideoCalls} />
        <Route exact path="/clients" component={pages.Clients} />
        {/* <Route exact path="/profile/name" component={pages.ProfileName} />
        <Route exact path="/profile/proof-of-identity" component={pages.ProfileProofOfIdentity} />
        <Route exact path="/profile/medical-profile" component={pages.ProfileMedical} />
        <Route exact path="/profile/animals-in-care" component={pages.ProfileAnimalsInCare} />
        <Route exact path="/profile/skills" component={pages.ProfileSkills} />
        <Route exact path="/profile/insurance" component={pages.ProfileInsurance} /> */}
        <Route path="/*" render={this.redirectToDashboard} />
      </Switch>
    );
  };

  render() {
    if (!this.state.isFirebaseInitialized) return null;
    const value = {
      currentVet: this.state.currentVet,
      currentAccount: this.state.currentAccount,
      currentCompany: this.state.currentCompany,
      companyVeterinarians: this.state.companyVeterinarians,
      companyCode: this.state.companyCode,
      updateCurrentVet: (currentVet: Veterinarian) => this.updateCurrentVet(currentVet),
      updateCurrentAccount: (currentAccount: Account) => this.updateCurrentAccount(currentAccount),
      updateCurrentCompany: (currentCompany: Company) => this.updateCurrentCompany(currentCompany),
      updateContext: () => this.updateContext(),
      // updateContext: () => null,
      getIsCompanyAdminByAccountId: (accountId?: string) =>
        this.getIsCompanyAdminByAccountId(accountId),
      getVeterinarianFromListById: (accountId?: string) =>
        this.getVeterinarianFromListById(accountId),
      // accountHasAnyVet: () => this.accountHasAnyVet(),
      updateCompanyCode: (companyCode: Company) => this.updateCompanyCode(companyCode),
    };
    return (
      <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme)}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <AppContextProvider value={value}>
              <BrowserRouter>
                {Firebase.getCurrentUser() && (
                  <VideoCallHandler
                    currentVet={this.state.currentVet}
                    currentAccount={this.state.currentAccount}
                    currentCompany={this.state.currentCompany}
                    companyVeterinarians={this.state.companyVeterinarians}
                  ></VideoCallHandler>
                )}
                {this.renderRoutes()}
                {
                  <ModalCmv
                    show={this.state.pendingVideoCall ? true : false}
                    onClickOutsideCloseModal={() => null}
                    headerTitle={i18n.t('surveyEndCall.headerTitle')}
                    // headerLabelRight={i18n.t('surveyEndCall.headerLabelRight')}
                    // headerIconRight={IconInfoHollow}
                    contentViewPreTitle={i18n.t('surveyEndCall.preTitle')}
                    contentViewMainTitle={i18n.t('surveyEndCall.mainTitle')}
                    contentViewBodyContent={i18n.t('surveyEndCall.body', {
                      ownerName: `${this.state.pendingVideoCall?.customerFirstName}  ${this.state.pendingVideoCall?.customerLastName}`,
                      phoneNumber: this.state.pendingVideoCall?.customerPhoneNumber
                        ? this.state.pendingVideoCall?.customerPhoneNumber
                        : '',
                    })}
                    contentViewCustomComponent={
                      <SurveyEndCall
                        {...this.props}
                        videoCallId={
                          this.state.pendingVideoCall?.videoCallId
                            ? this.state.pendingVideoCall.videoCallId
                            : ''
                        }
                        onSubmit={() => this.setState({ pendingVideoCall: undefined })}
                      />
                    }
                  />
                }
              </BrowserRouter>
            </AppContextProvider>
          </ThemeProvider>
        </ApolloProvider>
      </MuiThemeProvider>
    );
  }
}
