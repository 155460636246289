import * as Yup from 'yup';

export const createAccountValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Ce champ est requis'),
  lastName: Yup.string().required('Ce champ est requis'),
  email: Yup.string().required('Ce champ est requis').email('Email invalide'),
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])^(?=.*[a-z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
      'Ce champ est requis'
    )
    .required('Ce champ est requis'),
  cguAccepted: Yup.boolean().oneOf([true], 'Ce champ est requis'),
});
