import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Props } from './Footer.container';

export class Footer extends Component<Props> {
  render() {
    const {
      footerMainButton,
      footerSecondaryButton,
      footerSubTitle,
      footerHidden,
      footerCustomComponent,
    } = this.props;

    // const hidden = !footerMainButton && !footerSecondaryButton && !footerSubTitle;
    return (
      <Container hidden={footerHidden}>
        {footerMainButton && footerSecondaryButton && (
          <DoubleButtonsContainer>
            <ButtonCmvContainer>{footerSecondaryButton}</ButtonCmvContainer>
            <ButtonCmvContainer>{footerMainButton}</ButtonCmvContainer>
          </DoubleButtonsContainer>
        )}
        {footerMainButton && !footerSecondaryButton && (
          <SimpleButtonContainer>{footerMainButton}</SimpleButtonContainer>
        )}
        {footerSubTitle && (
          <SubTitleContainer>
            <SubTitle>{footerSubTitle}</SubTitle>
          </SubTitleContainer>
        )}
        {footerCustomComponent && <Fragment>{footerCustomComponent}</Fragment>}
      </Container>
    );
  }
}

const Container = styled.div<{ hidden: boolean }>`
  width: 100%;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  flex-direction: column;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.white.dark};
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top: 1px solid ${(props) => props.theme.colors.white.darker};
`;

const DoubleButtonsContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    flex-direction: column;
  }
`;

const ButtonCmvContainer = styled.div`
  width: 50%;

  &:nth-child(1) {
    margin-right: 10px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    width: 100%;
    &:nth-child(1) {
      margin-bottom: 10px;
      margin-right: 0;
    }
  }
`;

const SimpleButtonContainer = styled.div`
  display: flex;
  margin-bottom: 10px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 100px;
    padding-right: 100px;
  }
`;

const SubTitleContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SubTitle = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontFamily};
  font-size: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.lead.capitalize.regular.fontWeight};
  line-height: 24px;
  color: ${(props) => props.theme.colors.black.light};
  text-align: center;
`;
