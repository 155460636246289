import React, { Component } from 'react';
import { Props } from './OnboardingAccount.container';
import styled from 'styled-components';
import { MasterBody } from '../../components/Layout/MasterBody';
import { NavBarMaster } from '../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../components/UiNavigation/SubNavBar';
import { i18n } from '../../lib/i18n';
import { MasterContainer } from '../../components/Layout/MasterContainer';
import { CustomFlatList } from '../../components/CustomFlatList';
import { ReactComponent as IconChevronRightDisclosure } from '../../theme/icons/IconChevronRightDisclosure.svg';
import { ReactComponent as IconPlus } from '../../theme/icons/Icon-Plus-24x24.svg';
import { theme } from '../../theme';
import { appContext } from '../../context/appContext';
import { Company, Account, CompanyAccounts, Veterinarian } from '../../types/DbInterface';
import { loader } from 'graphql.macro';
import { ReactComponent as IconChevronRight24x24 } from '../../theme/icons/Icon-ChevronRight-24x24.svg';
import { client } from '../../graphql/client';

import { ButtonCmv } from '../../components/ButtonCmv';

const findCompanyAccountsByAccount = loader('../../graphql/findCompanyAccountsByAccount.graphql');
const acceptCompanyAccountsInvitationById = loader(
  '../../graphql/acceptCompanyAccountsInvitationById.graphql'
);

const getProfessionalAccountWithRelations = loader(
  '../../graphql/getProfessionalAccountWithRelations.graphql'
);

interface State {
  currentVet?: Veterinarian;
  currentAccount?: Account;
  currentCompany?: Company;
  companyAccountsList: CompanyAccounts[];
}

export class OnboardingAccount extends Component<Props, State> {
  public state: State = {
    currentVet: undefined,
    currentAccount: undefined,
    currentCompany: undefined,
    companyAccountsList: [],
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public async componentDidMount() {
    let updateCurrentCompany= undefined;
    try {
      const response = await client.query<{
        getProfessionalAccountWithRelations?: {
          account: Account;
          veterinarian?: Veterinarian;
          company?: Company;
          companyVeterinarians?: Veterinarian[];
        };
      }>({
        query: getProfessionalAccountWithRelations,
        fetchPolicy: 'network-only',
      });
      updateCurrentCompany = response.data.getProfessionalAccountWithRelations?.company
    } catch (error) {
      console.log(error);
    }
    const contextValue = this.context;
    const currentAccount = contextValue.currentAccount;
    const currentVet = contextValue.currentVet;
    const currentCompany = updateCurrentCompany;
    this.setState({ currentAccount, currentVet, currentCompany });
    this.refreshCompanyModerationList();
  }

  private storeCompanyAccounts = (companyAccounts: CompanyAccounts) => {
    const newCompanyAccountsList = this.state.companyAccountsList;
    newCompanyAccountsList.push(companyAccounts);
    this.setState({ companyAccountsList: newCompanyAccountsList });
  };

  public refreshCompanyModerationList = async () => {
    let result;
    try {
      result = await this.props.client.query({
        query: findCompanyAccountsByAccount,
        fetchPolicy: 'network-only',
      });
      if (result && result.data && result.data.findCompanyAccountsByAccount) {
        result.data.findCompanyAccountsByAccount.forEach((element: CompanyAccounts) =>
          this.storeCompanyAccounts(element)
        );
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  // handle validating companyAccounts moderationStatus
  private async onClickCompanyAccounts(companyAccountsId: string) {
    try {
      await this.props.client.mutate({
        mutation: acceptCompanyAccountsInvitationById,
        variables: { companyAccountsId },
      });
      this.context.updateContext();
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public getCompanyAccountsListFormatted() {
    let companyAccountsList: any;
    if (this.state.companyAccountsList && this.state.companyAccountsList?.length > 0) {
      this.state.companyAccountsList.map((data) => {
        if (!companyAccountsList)
          companyAccountsList = [
            {
              onClick: () => this.onClickCompanyAccounts(data.id),
              leftLabel: `${data.company.name}`,
              rightLabel: i18n.t('onboardingAccount.secondSection.row.rightLabel'),
              rightIcon: IconChevronRight24x24,
            },
          ];
        else
          companyAccountsList.push({
            //TODO onClick: go to profile not form
            onClick: () => this.onClickCompanyAccounts(data.company.id),
            leftLabel: `${data.company.name}`,
            rightLabel: i18n.t('onboardingAccount.secondSection.row.rightLabel'),
            rightIcon: IconChevronRight24x24,
          });
        return companyAccountsList;
      });
      if (companyAccountsList)
        companyAccountsList = companyAccountsList.concat({
          leftLabel: i18n.t('onboardingAccount.secondSection.row.leftLabel'),
          rightLabel: i18n.t('onboardingAccount.secondSection.row.rightLabel'),
          leftLabelColor: theme.colors.grey.main,
          rightIcon: IconPlus,
          onClick: () => this.props.history.push('/onboarding/veterinarian'),
        });
      return companyAccountsList;
    } else
      return [
        {
          leftLabel: i18n.t('onboardingAccount.secondSection.row.leftLabel'),
          rightLabel: i18n.t('onboardingAccount.secondSection.row.rightLabel'),
          leftLabelColor: theme.colors.grey.main,
          rightIcon: IconPlus,
          onClick: () => this.props.history.push('/onboarding/veterinarian'),
        },
      ];
  }

  public render() {
    const companyAccounts = this.getCompanyAccountsListFormatted();
    const companies = [
      {
        leftLabel: i18n.t('onboardingAccount.secondSection.row.leftLabel'),
        rightLabel: i18n.t('onboardingAccount.secondSection.row.rightLabelCreate'),
        leftLabelColor: theme.colors.grey.main,
        rightIcon: IconPlus,
        onClick: () => this.props.history.push('/onboarding/veterinarian'),
      },
    ];
    if (this.state.currentCompany) {
      companies.push({
        leftLabel: this.state.currentCompany.name ? this.state.currentCompany.name : '',
        rightLabel: i18n.t('onboardingAccount.secondSection.row.rightLabel'),
        leftLabelColor: theme.colors.black.light,
        rightIcon: IconChevronRightDisclosure,
        onClick: () =>
          this.props.history.push('/onboarding/veterinarian', {
            company: this.state.currentCompany,
          }),
      });
    }
    return (
      <Page>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <MasterContainer noSideMenu={true}>
            <OnboardingAccountContainer>
              <OnboardingAccountTitle>
                {i18n.t('onboardingAccount.title', {
                  accountFirstName: this.state.currentAccount?.firstName,
                })}
              </OnboardingAccountTitle>
              <OnboardingAccountDescription>
                {i18n.t('onboardingAccount.description')}
              </OnboardingAccountDescription>
              <OnboardingAcccountSectionTitle>
                {i18n.t('onboardingAccount.firstSection.title')}
              </OnboardingAcccountSectionTitle>
              <CustomFlatListContainer>
                <CustomFlatList
                  data={[
                    {
                      leftLabel: this.state.currentCompany?.name ? this.state.currentCompany?.name : i18n.t('onboardingAccount.firstSection.row.leftLabel'),
                      leftLabelColor: theme.colors.grey.main,
                      rightLabel: this.state.currentCompany?.legalCompleted ? '' : i18n.t('onboardingAccount.firstSection.row.rightLabel'),
                      onClick: () => this.state.currentCompany?.legalCompleted ? null : this.props.history.push('/onboarding/vet-company'),
                      rightIcon: this.state.currentCompany?.legalCompleted ? undefined : IconChevronRight24x24,
                      status: this.state.currentCompany?.legalCompleted
                          ? 'REQUIRED_FIELD_FILLED'
                          : undefined, 
                    },
                  ]}
                />
              </CustomFlatListContainer>
              <OnboardingAcccountSectionTitle>
                {i18n.t('onboardingAccount.secondSection.title')}
              </OnboardingAcccountSectionTitle>
              <CustomFlatListContainer>
                <CustomFlatList data={companyAccounts} />
              </CustomFlatListContainer>
              <ButtonCmvContainer>
                <ButtonBox>
                  <ButtonCmv
                    onClick={() => window.location.reload() }
                    type={'button'}
                    btnSize={'large'}
                    btnStyle={'primary'}
                    content={i18n.t('onboardingVetCompany.form.button.continue')} 
                  />
                </ButtonBox>
            </ButtonCmvContainer>
            </OnboardingAccountContainer>
          </MasterContainer>
        </MasterBody>
      </Page>
    );
  }
}

const Page = styled.div`
  width: 100%;
`;
const OnboardingAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.tabletMin}) {
    padding-left: 160px;
    padding-right: 160px;
  }
`;

const OnboardingAccountTitle = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.heading.h4.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h4.fontWeight};
  line-height: 32px;
  text-align: left;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 48px;
`;

const OnboardingAccountDescription = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.body.main.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.body.main.fontWeight};
  line-height: 24px;
  color: ${(props) => props.theme.colors.black.lighter};
  text-align: left;
  margin-top: 21px;
  white-space: pre-wrap;
`;

const OnboardingAcccountSectionTitle = styled.div`
  font-size: ${(props) => props.theme.textStyles.typography.heading.h6.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h6.fontWeight};
  line-height: 22px;
  border-bottom: 2px solid ${(props) => props.theme.colors.blue.dark};
  padding: 9px 0;
  margin-top: 40px;
  color: ${(props) => props.theme.colors.primary.mainColor};
`;

const CustomFlatListContainer = styled.div`
  margin-top: 17px;
`;

const ButtonCmvContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 48px 0;
`;

const ButtonBox = styled.div`
  width: 50%
`;