import React, { Component } from 'react';
import { Props } from './OrganisationAddress.container';
import styled from 'styled-components';
import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { MasterBody } from '../../../components/Layout/MasterBody';
import { MasterContainer } from '../../../components/Layout/MasterContainer';
import { HeaderSectionForm } from '../../../components/Form/HeaderSectionForm';
import { i18n } from '../../../lib/i18n';
import { Formik, Field, Form } from 'formik';
import { organisationAddressValidationSchema } from './OrganisationAddress.validation';
import { DropdownForm, TextInputForm } from '../../../components/Form';
import { ButtonsForm } from '../../../components/Form/ButtonsForm';
import { Account, Company, Veterinarian } from '../../../types/DbInterface';
import { appContext } from '../../../context/appContext';
import { loader } from 'graphql.macro';
import { addressCountries } from '../../../constants/company';

import { Loader } from '../../../components/Loader';
import { client } from '../../../graphql/client';

const getProfessionalAccountWithRelations = loader('../../../graphql/getProfessionalAccountWithRelations.graphql');
const updateCompanyAddress = loader('../../../graphql/updateCompanyAddress.graphql');

interface ValuesForm {
  addressMain: string;
  addressComplement: string;
  city: string;
  zipCode: string;
  country: string;
}

export interface State {
  __test__companyName: string;
  currentCompany?: Company;
  loading?: boolean;
}

export class OrganisationAddress extends Component<Props, State> {
  public state: State = {
    __test__companyName: 'Ventura Corp',
    currentCompany: undefined,
    loading: true,
  };

  static contextType = appContext;
  context: React.ContextType<typeof appContext> = this.context;

  public async componentDidMount() {
    try {
      const response = await client.query<{
        getProfessionalAccountWithRelations?: {
          account: Account;
          veterinarian?: Veterinarian;
          company?: Company;
          companyVeterinarians?: Veterinarian[];
        };
      }>({
        query: getProfessionalAccountWithRelations,
        fetchPolicy: 'network-only',
      });      
      this.setState({
        currentCompany: response.data.getProfessionalAccountWithRelations?.company, 
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  private onSubmit = async (values: ValuesForm) => {
    if (this.state.currentCompany) {
      try {
        await this.props.client.mutate({
          mutation: updateCompanyAddress,
          variables: {
            companyId: this.state.currentCompany.id,
            address: {
              country: values.country,
              city: values.city,
              postalCode: values.zipCode,
              addressMain: values.addressMain,
              complement: values.addressComplement,
            },
          },
        });
        this.context.updateContext();
        this.props.history.goBack();
      } catch (error) {
        throw new Error(error);
      }
    } else {
      throw new Error('no company');
    }
  };

  render() {
    const company = this.state.currentCompany;
    if (this.state.loading) {
      return <LoaderContainer><Loader inline="centered" active={true} size={'big'} /></LoaderContainer>;
    }

    return (
      <>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <MasterContainer noSideMenu={true}>
            <Container>
              <HeaderContainer>
                <Path>{i18n.t('organisationAddress.path')}</Path>
                <Title>{i18n.t('organisationAddress.title')}</Title>
              </HeaderContainer>

              <SectionContainer>
                <HeaderSectionForm
                  title={i18n.t('organisationAddress.headerTitle')}
                  subtitle={i18n.t('organisationAddress.headerSubtitle')}
                />

                <Formik
                  initialValues={{
                    addressMain: company?.companyAddress?.addressMain
                      ? company?.companyAddress?.addressMain
                      : '',
                    addressComplement: company?.companyAddress?.complement
                      ? company?.companyAddress?.complement
                      : '',
                    city: company?.companyAddress?.city ? company?.companyAddress?.city : '',
                    zipCode: company?.companyAddress?.postalCode
                      ? company?.companyAddress?.postalCode
                      : '',
                    country: company?.companyAddress?.country
                      ? company?.companyAddress?.country
                      : '',
                  }}
                  onSubmit={this.onSubmit}
                  validationSchema={organisationAddressValidationSchema}
                >
                  {({ values, touched, errors, setFieldValue }) => (
                    <OrganisationForm>
                      <OrganisationField>
                        <Field
                          name={'addressMain'}
                          label={i18n.t('organisationAddress.mainAdress')}
                          placeholder={i18n.t('organisationAddress.mainPlaceholder')}
                          component={TextInputForm}
                        />
                        <Field
                          name={'addressComplement'}
                          label={i18n.t('organisationAddress.secondAdress')}
                          placeholder={i18n.t('organisationAddress.secondPlaceholderAdress')}
                          component={TextInputForm}
                        />
                        <Field
                          name={'city'}
                          label={i18n.t('organisationAddress.city')}
                          placeholder={i18n.t('organisationAddress.cityPlaceholder')}
                          component={TextInputForm}
                        />
                        <Field
                          name={'zipCode'}
                          label={i18n.t('organisationAddress.zipCode')}
                          placeholder={i18n.t('organisationAddress.zipCodePlaceholder')}
                          component={TextInputForm}
                        />
                        <Field
                          name={'country'}
                          label={i18n.t('organisation.legalInformations.form.country.label')}
                          placeholder={i18n.t(
                            'organisation.legalInformations.form.country.placeholder'
                          )}
                          component={DropdownForm}
                          data={addressCountries}
                          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            // Do the logic needed for each case
                            setFieldValue('country', event.target.value);
                          }}
                          value={company?.companyAddress?.country ? company?.companyAddress?.country : ''}
                        />
                      </OrganisationField>
                      <ButtonsForm
                        cancelContent={i18n.t('organisation.form.button.cancel')}
                        validateContent={i18n.t('organisation.form.button.save')}
                        onCancel={() => this.props.history.goBack()}
                        type={'submit'}
                      />
                    </OrganisationForm>
                  )}
                </Formik>
              </SectionContainer>
            </Container>
          </MasterContainer>
        </MasterBody>
      </>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.pure};
  padding-left: 10px;
  padding-right: 10px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop13Min}) {
    padding-left: 200px;
    padding-right: 200px;
  }
`;

const HeaderContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;

const Path = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.body.small.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.body.small.fontSize};
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black.light};
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.textStyles.typography.heading.h1.fontWeight};
  font-size: ${(props) => props.theme.textStyles.typography.heading.h1.fontSize};
  line-height: 54px;
  color: ${(props) => props.theme.colors.black.light};
  margin-top: 27px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrganisationForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 35px;
`;

const OrganisationField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 0;
  }
`;

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;
