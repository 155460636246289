import { DropdownForm as DropdownFormComponent } from './DropdownForm.component';

export type Props = {
  label?: string;
  sublabel?: string;
  placeholder?: string;
  required?: boolean;
  form: any;
  field: any;
  data: Array<{ label: string; value: string }>;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
};

export const DropdownForm = DropdownFormComponent;
