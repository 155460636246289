import React, { Component, Fragment } from 'react';
import { Props } from './Login.container';
import styled from 'styled-components';
import { ButtonCmv } from '../../../components/ButtonCmv';
import { Formik, Field, Form } from 'formik';
import { loginValidationSchema } from './Login.validation';
import { TextInputForm } from '../../../components/Form';
import { MasterBody } from '../../../components/Layout/MasterBody';
import { NavBarMaster } from '../../../components/UiNavigation/NavBar';
import { SubNavBar } from '../../../components/UiNavigation/SubNavBar';
import { SignInFormValues } from '../../../types/SignInForm';
import { loader } from 'graphql.macro';
import { Firebase } from '../../../lib/firebase';
import { i18n } from '../../../lib/i18n';
import { ModalCmv } from '../../../components/ModalCmv';
// import { ReactComponent as IconCrossDelete } from '../../../theme/icons/IconCrossDelete.svg';

const getAccount = loader('../../../graphql/getAccount.graphql');
const isAdmin = loader('../../../graphql/isAdmin.graphql');

interface State {
  isLoading: boolean;
}

export class Login extends Component<Props, State> {
  public state: State = {
    isLoading: false,
  };

  private formikRef: Formik<SignInFormValues> | null | undefined;

  private onSubmit = async (values: SignInFormValues) => {
    this.setState({ isLoading: true }, async () => {
      try {
        await Firebase.signIn(values.email.trim(), values.password);
        if (Firebase.getCurrentUser())
          if (!this.props.isAdmin) {
            // We call this query just to make sure the vet also exists in the database
            const response = await this.props.client.query<{ getAccount?: Account }>({
              query: getAccount,
              fetchPolicy: 'network-only',
            });
            if (!response.data.getAccount) {
              await Firebase.signOut();
              throw new Error();
            }
            return this.props.history.push('/Dashboard');
          } else {
            const response = await this.props.client.query<{ isAdmin?: boolean }>({
              query: isAdmin,
              fetchPolicy: 'network-only',
            });
            if (!response.data.isAdmin) {
              await Firebase.signOut();
              throw new Error();
            }
            return this.props.history.push('/Admin');
          }
      } catch (error) {
        this.setState({ isLoading: false });
        await Firebase.signOut();
        console.log(error);
        if (this.formikRef) {
          this.formikRef.setFieldError('email', i18n.t('signIn.form.error.wrongEmailPassword'));
        }
      }
    });
  };

  public render() {
    return (
      <Fragment>
        <NavBarMaster />
        <SubNavBar />
        <MasterBody>
          <Formik
            ref={(formikComponent) => (this.formikRef = formikComponent)}
            initialValues={{
              email: '',
              password: '',
            }}
            onSubmit={this.onSubmit}
            validationSchema={loginValidationSchema}
          >
            {() => (
              <ModalCmv
                show={true}
                onClickOutsideCloseModal={() => null}
                // headerIconLeft={IconCrossDelete}
                headerTitle={i18n.t('login.title')}
                contentViewShowCmvLogo={true}
                contentViewCustomComponent={
                  <LoginForm>
                    <LoginField>
                      <Field
                        name={'email'}
                        label={i18n.t('login.form.fields.email.label')}
                        placeholder={i18n.t('login.form.fields.email.placeholder')}
                        component={TextInputForm}
                        direction={'column'}
                      />
                      <Field
                        name={'password'}
                        label={i18n.t('login.form.fields.password.label')}
                        placeholder={i18n.t('login.form.fields.password.placeholder')}
                        component={TextInputForm}
                        direction={'column'}
                        type={'password'}
                        autoComplete={'current-password'}
                      />
                      <LinkForgotPasswordContainer>
                        <LinkForgotPassword href={'/reset-password-request'}>
                          {i18n.t('login.link.forgotPassword')}
                        </LinkForgotPassword>
                      </LinkForgotPasswordContainer>
                    </LoginField>
                    <BtnContainer>
                      <ButtonCmv
                        type={'submit'}
                        btnSize={'large'}
                        btnStyle={'primary'}
                        content={i18n.t('login.form.submit')}
                      />
                    </BtnContainer>
                  </LoginForm>
                }
                footerCustomComponent={
                  <NoAccountContainer>
                    {i18n.t('login.link.noAccount')}

                    <NoAccountLink href={'/create-account'}>
                      {i18n.t('login.link.noAccountLink')}
                    </NoAccountLink>
                  </NoAccountContainer>
                }
              />
            )}
          </Formik>
        </MasterBody>
      </Fragment>
    );
  }
}

const LoginForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LoginField = styled.div`
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 60px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    & {
      padding: 0;
    }
  }
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobilePortraitMax}) {
    & {
      padding: 0;
    }
  }
`;

const NoAccountContainer = styled.div`
  font-family: ${(props) => props.theme.textStyles.typography.body.small.fontFamily};
  font-size: ${(props) => props.theme.textStyles.typography.body.small.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.body.small.fontWeight};
  color: ${(props) => props.theme.colors.black.light};
  text-align: center;
`;

const NoAccountLink = styled.a`
  font-weight: bold;
  color: ${(props) => props.theme.colors.black.light};
`;

const LinkForgotPasswordContainer = styled.div`
  width: 100%;
  text-align: right;
`;

const LinkForgotPassword = styled.a`
  font-family: ${(props) => props.theme.textStyles.typography.body.small.fontFamily};
  font-size: ${(props) => props.theme.textStyles.typography.body.small.fontSize};
  font-weight: ${(props) => props.theme.textStyles.typography.body.small.fontWeight};
  line-height: 18px;
  color: ${(props) => props.theme.colors.black.light};
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
