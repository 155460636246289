export interface County {
  name: string;
  country: string;
}
export const counties: County[] = [
  { name: 'Ain', country: 'FR' },
  { name: 'Aisne', country: 'FR' },
  { name: 'Allier', country: 'FR' },
  { name: 'Alpes-de-Haute-Provence', country: 'FR' },
  { name: 'Alpes-Maritimes', country: 'FR' },
  { name: 'Ardèche', country: 'FR' },
  { name: 'Ardennes', country: 'FR' },
  { name: 'Ariège', country: 'FR' },
  { name: 'Aube', country: 'FR' },
  { name: 'Aude', country: 'FR' },
  { name: 'Aveyron', country: 'FR' },
  { name: 'Bas-Rhin', country: 'FR' },
  { name: 'Bouches-du-Rhône', country: 'FR' },
  { name: 'Calvados', country: 'FR' },
  { name: 'Cantal', country: 'FR' },
  { name: 'Charente', country: 'FR' },
  { name: 'Charente-Maritime', country: 'FR' },
  { name: 'Cher', country: 'FR' },
  { name: 'Corrèze', country: 'FR' },
  { name: 'Corse-du-Nord', country: 'FR' },
  { name: 'Corse-du-Sud', country: 'FR' },
  { name: "Côte-d'Or", country: 'FR' },
  { name: "Côtes d'Armor", country: 'FR' },
  { name: 'Creuse', country: 'FR' },
  { name: 'Deux-Sèvres', country: 'FR' },
  { name: 'Dordogne', country: 'FR' },
  { name: 'Doubs', country: 'FR' },
  { name: 'Drôme', country: 'FR' },
  { name: 'Essonne', country: 'FR' },
  { name: 'Eure', country: 'FR' },
  { name: 'Eure-et-Loir', country: 'FR' },
  { name: 'Finistère', country: 'FR' },
  { name: 'Gard', country: 'FR' },
  { name: 'Gers', country: 'FR' },
  { name: 'Gironde', country: 'FR' },
  { name: 'Guadeloupe', country: 'FR' },
  { name: 'Guyane', country: 'FR' },
  { name: 'Haut-Rhin', country: 'FR' },
  { name: 'Haute-Corse', country: 'FR' },
  { name: 'Haute-Garonne', country: 'FR' },
  { name: 'Haute-Loire', country: 'FR' },
  { name: 'Haute-Marne', country: 'FR' },
  { name: 'Haute-Saône', country: 'FR' },
  { name: 'Haute-Savoie', country: 'FR' },
  { name: 'Haute-Vienne', country: 'FR' },
  { name: 'Hautes-Alpes', country: 'FR' },
  { name: 'Hautes-Pyrénées', country: 'FR' },
  { name: 'Hauts-de-Seine', country: 'FR' },
  { name: 'Hérault', country: 'FR' },
  { name: 'Ille-et-Vilaine', country: 'FR' },
  { name: 'Indre', country: 'FR' },
  { name: 'Indre-et-Loire', country: 'FR' },
  { name: 'Isère', country: 'FR' },
  { name: 'Jura', country: 'FR' },
  { name: 'La Réunion', country: 'FR' },
  { name: 'Landes', country: 'FR' },
  { name: 'Loir-et-Cher', country: 'FR' },
  { name: 'Loire', country: 'FR' },
  { name: 'Loire-Atlantique', country: 'FR' },
  { name: 'Loiret', country: 'FR' },
  { name: 'Lot', country: 'FR' },
  { name: 'Lot et Garonne', country: 'FR' },
  { name: 'Lozère', country: 'FR' },
  { name: 'Maine-et-Loire', country: 'FR' },
  { name: 'Manche', country: 'FR' },
  { name: 'Marne', country: 'FR' },
  { name: 'Martinique', country: 'FR' },
  { name: 'Mayenne', country: 'FR' },
  { name: 'Mayotte', country: 'FR' },
  { name: 'Meurthe-et-Moselle', country: 'FR' },
  { name: 'Meuse', country: 'FR' },
  { name: 'Morbihan', country: 'FR' },
  { name: 'Moselle', country: 'FR' },
  { name: 'Nièvre', country: 'FR' },
  { name: 'Nord', country: 'FR' },
  { name: 'Oise', country: 'FR' },
  { name: 'Orne', country: 'FR' },
  { name: 'Paris', country: 'FR' },
  { name: 'Pas-de-Calais', country: 'FR' },
  { name: 'Puy-de-Dôme', country: 'FR' },
  { name: 'Pyrénées-Atlantiques', country: 'FR' },
  { name: 'Pyrénées-Orientales', country: 'FR' },
  { name: 'Rhône', country: 'FR' },
  { name: 'Saône-et-Loire', country: 'FR' },
  { name: 'Sarthe', country: 'FR' },
  { name: 'Savoie', country: 'FR' },
  { name: 'Seine-et-Marne', country: 'FR' },
  { name: 'Seine-Maritime', country: 'FR' },
  { name: 'Seine-St-Denis', country: 'FR' },
  { name: 'Somme', country: 'FR' },
  { name: 'Tarn', country: 'FR' },
  { name: 'Tarn-et-Garonne', country: 'FR' },
  { name: 'Territoire de Belfort', country: 'FR' },
  { name: "Val-D'Oise", country: 'FR' },
  { name: 'Val-de-Marne', country: 'FR' },
  { name: 'Var', country: 'FR' },
  { name: 'Vaucluse', country: 'FR' },
  { name: 'Vendée', country: 'FR' },
  { name: 'Vienne', country: 'FR' },
  { name: 'Vosges', country: 'FR' },
  { name: 'Yonne', country: 'FR' },
  { name: 'Yvelines', country: 'FR' },
  { name: 'Anvers', country: 'BE' },
  { name: 'Brabant Flamand', country: 'BE' },
  { name: 'Brabant Flamand', country: 'BE' },
  { name: 'Brabant Wallon', country: 'BE' },
  { name: 'Bruxelles', country: 'BE' },
  { name: 'Flandre-Occidentale', country: 'BE' },
  { name: 'Flandre-Orientale', country: 'BE' },
  { name: 'Hainaut', country: 'BE' },
  { name: 'Hainaut', country: 'BE' },
  { name: 'Liège', country: 'BE' },
  { name: 'Limbourg', country: 'BE' },
  { name: 'Luxembourg', country: 'BE' },
  { name: 'Namur', country: 'BE' },
];
export interface County_test {
  name: string;
}
export const frCounties: County_test[] = [
  { name: 'Ain' },
  { name: 'Aisne' },
  { name: 'Allier' },
  { name: 'Alpes-de-Haute-Provence' },
  { name: 'Alpes-Maritimes' },
  { name: 'Ardèche' },
  { name: 'Ardennes' },
  { name: 'Ariège' },
  { name: 'Aube' },
  { name: 'Aude' },
  { name: 'Aveyron' },
  { name: 'Bas-Rhin' },
  { name: 'Bouches-du-Rhône' },
  { name: 'Calvados' },
  { name: 'Cantal' },
  { name: 'Charente' },
  { name: 'Charente-Maritime' },
  { name: 'Cher' },
  { name: 'Corrèze' },
  { name: 'Corse-du-Sud' },
  { name: "Côte-d'Or" },
  { name: "Côtes d'Armor" },
  { name: 'Creuse' },
  { name: 'Deux-Sèvres' },
  { name: 'Dordogne' },
  { name: 'Doubs' },
  { name: 'Drôme' },
  { name: 'Essonne' },
  { name: 'Eure' },
  { name: 'Eure-et-Loir' },
  { name: 'Finistère' },
  { name: 'Gard' },
  { name: 'Gers' },
  { name: 'Gironde' },
  { name: 'Guadeloupe' },
  { name: 'Guyane' },
  { name: 'Haut-Rhin' },
  { name: 'Haute-Corse' },
  { name: 'Haute-Garonne' },
  { name: 'Haute-Loire' },
  { name: 'Haute-Marne' },
  { name: 'Haute-Saône' },
  { name: 'Haute-Savoie' },
  { name: 'Haute-Vienne' },
  { name: 'Hautes-Alpes' },
  { name: 'Hautes-Pyrénées' },
  { name: 'Hauts-de-Seine' },
  { name: 'Hérault' },
  { name: 'Ille-et-Vilaine' },
  { name: 'Indre' },
  { name: 'Indre-et-Loire' },
  { name: 'Isère' },
  { name: 'Jura' },
  { name: 'La Réunion' },
  { name: 'Landes' },
  { name: 'Loir-et-Cher' },
  { name: 'Loire' },
  { name: 'Loire-Atlantique' },
  { name: 'Loiret' },
  { name: 'Lot' },
  { name: 'Lot et Garonne' },
  { name: 'Lozère' },
  { name: 'Maine-et-Loire' },
  { name: 'Manche' },
  { name: 'Marne' },
  { name: 'Martinique' },
  { name: 'Mayenne' },
  { name: 'Mayotte' },
  { name: 'Meurthe-et-Moselle' },
  { name: 'Meuse' },
  { name: 'Morbihan' },
  { name: 'Moselle' },
  { name: 'Nièvre' },
  { name: 'Nord' },
  { name: 'Oise' },
  { name: 'Orne' },
  { name: 'Paris' },
  { name: 'Pas-de-Calais' },
  { name: 'Puy-de-Dôme' },
  { name: 'Pyrénées-Atlantiques' },
  { name: 'Pyrénées-Orientales' },
  { name: 'Rhône' },
  { name: 'Saône-et-Loire' },
  { name: 'Sarthe' },
  { name: 'Savoie' },
  { name: 'Seine-et-Marne' },
  { name: 'Seine-Maritime' },
  { name: 'Seine-St-Denis' },
  { name: 'Somme' },
  { name: 'Tarn' },
  { name: 'Tarn-et-Garonne' },
  { name: 'Territoire de Belfort' },
  { name: "Val-D'Oise" },
  { name: 'Val-de-Marne' },
  { name: 'Var' },
  { name: 'Vaucluse' },
  { name: 'Vendée' },
  { name: 'Vienne' },
  { name: 'Vosges' },
  { name: 'Yonne' },
  { name: 'Yvelines' },
];
export const beCounties: County_test[] = [
  { name: 'Anvers' },
  { name: 'Brabant Flamand' },
  { name: 'Brabant Wallon' },
  { name: 'Bruxelles' },
  { name: 'Flandre-Occidentale' },
  { name: 'Flandre-Orientale' },
  { name: 'Hainaut' },
  { name: 'Liège' },
  { name: 'Limbourg' },
  { name: 'Luxembourg' },
  { name: 'Namur' },
];
